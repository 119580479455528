import React, {PropsWithChildren} from 'react';
import ReactModal from 'react-modal';

import {buttonRole, buttonText, cssClassName} from '../../util';
import {
	ButtonRole,
	StandardButton,
	ButtonRoleOrder,
	UNIVERSAL_APP_ROOT_SELECTOR,
} from '../../constants';

interface IProps {
	buttons?: StandardButton;
	className?: string;
	dense?: boolean;
	hideButtons?: boolean;
	informativeText?: string | string[];
	isOpen: boolean;
	maximized?: boolean;
	onAfterClose?: () => any;
	onAfterOpen?: () => any;
	onResult?: (result: StandardButton) => any;
	text?: string;
}

type Props = PropsWithChildren<IProps>;

interface State {
	buttonList: Map<ButtonRole, StandardButton[]>;
}

const possiblyAnAppRootElement = document.querySelector<HTMLDivElement>(UNIVERSAL_APP_ROOT_SELECTOR);
if (possiblyAnAppRootElement) {
	ReactModal.setAppElement(possiblyAnAppRootElement);
}

export default class Modal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.afterClose = this.afterClose.bind(this);
		this.afterOpen = this.afterOpen.bind(this);
		this.closeRequest = this.closeRequest.bind(this);
		this.state = {
			buttonList: new Map<ButtonRole, StandardButton[]>(),
		};
	}

	addButton(button: StandardButton, role: ButtonRole): void {
		const {buttonList} = this.state;
		const lst = buttonList.get(role);
		const list: StandardButton[] = lst ? [...lst] : [];
		if (list.indexOf(button) >= 0) {
			return;
		}
		list.push(button);
		buttonList.set(role, list);
		this.setState({buttonList: new Map(buttonList)});
	}

	afterClose(): void {
		const {onAfterClose} = this.props;
		if (onAfterClose) {
			onAfterClose();
		}
	}

	afterOpen(): void {
		const {onAfterOpen} = this.props;
		if (onAfterOpen) {
			onAfterOpen();
		}
	}

	buttonClick(button: StandardButton): void {
		this.close(button);
	}

	buttonParts(): [StandardButton, string][] {
		const {buttonList} = this.state;
		const rv: [StandardButton, string][] = [];
		for (let i = 0; i < ButtonRoleOrder.length; ++i) {
			const role = ButtonRoleOrder[i];
			const list = buttonList.get(role);
			if (!list || (list.length < 1)) {
				continue;
			}
			for (let k = 0; k < list.length; ++k) {
				const button = list[k];
				const text = buttonText(button);
				rv.push([button, text]);
			}
		}
		// If no buttons were specified, default to a single "OK" button
		if (rv.length < 1) {
			rv.push([StandardButton.Ok, buttonText(StandardButton.Ok)]);
		}
		return rv;
	}

	close(code: number): void {
		const {onResult} = this.props;
		if (onResult) {
			onResult(code);
		}
	}

	closeRequest(event: React.MouseEvent | React.KeyboardEvent): void {
		// From the look of things within this component's source code, the
		// only event types given here are `click` and `keydown`. There is
		// nothing in the documentation describing this event's details,
		// however.
		switch (event.type) {
			case 'click':
				this.mouseEvent(event as React.MouseEvent);
				break;
			case 'keydown':
				this.keyEvent(event as React.KeyboardEvent);
				break;
			default:
				console.log(`components::modal::Modal No handler defined for DOM event type "${event.type}"`);
				break;
		}
	}

	componentDidMount(): void {
		const {buttons} = this.props;
		this.setStandardButtons(buttons);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const {buttons} = this.props;
		const {buttons: prevButtons} = prevProps;
		if (buttons !== prevButtons) {
			this.setStandardButtons(buttons);
		}
	}

	createButton(button: StandardButton) {
		const role = buttonRole(button);
		if (role === ButtonRole.InvalidRole) {
			console.log('reactui::modal::Modal::createButton: Invalid ButtonRole, button not added');
			return;
		}
		this.addButton(button, role);
	}

	createStandardButtons(buttons: StandardButton): void {
		let i = StandardButton.FirstButton;
		while (i <= StandardButton.LastButton) {
			if (i & buttons) {
				this.createButton(i);
			}
			i = i << 1;
		}
	}

	informativeText(): string[] {
		const {informativeText} = this.props;
		return informativeText ?
			Array.isArray(informativeText) ?
				informativeText :
				informativeText.split('\n') :
			[];
	}

	keyEvent(event: React.KeyboardEvent): void {
		if (event.key === 'Escape') {
			this.close(StandardButton.Close);
		}
	}

	mouseEvent(event: React.MouseEvent): void {
		// The source code of this component indicates that the only mouse
		// event sent here is the DOM `click` event as a result of the user
		// clicking within the overlay, but outside the "content", area of the
		// modal pop-up. If the modal component has its
		// `shouldCloseOnOverlayClick` property set TRUE, the software will
		// call this method when such an action occurs. Conversely, if
		// `shouldCloseOnOverlayClick` is set FALSE, no call is made.
		if (event.type === 'click') {
			this.close(StandardButton.Close);
		}
	}

	render(): React.ReactNode {
		const {children, className, dense, hideButtons, isOpen, maximized} = this.props;
		const text = this.text();
		const informativeText = this.informativeText();
		const lastInfoTxtIdx = informativeText.length - 1;
		return (
			<ReactModal
				className="pbr-dialog__container"
				isOpen={isOpen}
				onAfterClose={this.afterClose}
				onAfterOpen={this.afterOpen}
				onRequestClose={this.closeRequest}
				overlayClassName={cssClassName('pbr-dialog', 'the-reactui-one', className, {'pbr-dialog--maximized': maximized, 'pbr-dialog--dense': dense})}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={false}>
				<div className="pbr-dialog__surface">
					<Title text={text}/>
					<Content>
						{informativeText.map((txt, idx) =>
							<React.Fragment key={idx}>
								{txt}{(idx === lastInfoTxtIdx) ? null : <br/>}
							</React.Fragment>)}
						{children}
					</Content>
					{hideButtons ?
						null :
						<ButtonBox>
							{this.buttonParts().map(([button, text], idx) =>
								<Button key={idx} onClick={this.buttonClick.bind(this, button)}>
									{text}
								</Button>)}
						</ButtonBox>}
				</div>
			</ReactModal>
		);
	}

	setStandardButtons(buttons?: StandardButton): void {
		// Clear old buttons first
		const {buttonList} = this.state;
		buttonList.forEach(list => list.length = 0);
		buttonList.clear();
		this.setState(
			{buttonList: new Map()},
			() => buttons && this.createStandardButtons(buttons));
	}

	text(): string {
		return this.props.text || '';
	}
}

function Title({text}: PropsWithChildren<{text: string;}>) {
	return text ?
		<h2 className="pbr-dialog__title">{text}</h2> :
		null;
}

function Content({children}: PropsWithChildren<{}>) {
	return (
		<div className="pbr-dialog__content">
			{children}
		</div>
	);
}

function Button({children, onClick}: PropsWithChildren<{onClick: () => any}>) {
	return (
		<button className="mdc-button pbr-dialog__button" onClick={onClick} type="button">
			<div className="mdc-button__ripple"/>
			<span className="mdc-button__label">{children}</span>
		</button>
	);
}

function ButtonBox({children}: PropsWithChildren<{}>) {
	return (
		<div className="pbr-dialog__actions">
			{children}
		</div>
	);
}

import React, {PropsWithChildren} from 'react';
import {
	MDCSwitchAdapter,
	MDCSwitchFoundation,
} from '@material/switch';

import {assert, cssClassName} from '../../util';

interface IProps {
	checked: boolean;
	className?: string;
	disabled?: boolean;
	id?: string;
	name?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
	title?: string;
}

interface IState {
	classNames: Set<string>;
	inputChecked: boolean;
	inputDisabled: boolean;
}

type Props = PropsWithChildren<IProps>;
type State = IState;

export default class Switch extends React.Component<Props, State> {
	private ctrl: MDCSwitchFoundation | null;
	private _inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: Props) {
		super(props);
		this._changeEvent = this._changeEvent.bind(this);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdcSetNativeControlAttr = this._mdcSetNativeControlAttr.bind(this);
		this._mdcSetNativeControlChecked = this._mdcSetNativeControlChecked.bind(this);
		this._mdcSetNativeControlDisabled = this._mdcSetNativeControlDisabled.bind(this);
		this.state = {
			classNames: new Set(['mdc-switch']),
			inputChecked: false,
			inputDisabled: false,
		};
		this._inputRef = React.createRef();
		this.ctrl = null;
	}

	componentDidMount(): void {
		const {checked, disabled} = this.props;
		this.ctrl = new MDCSwitchFoundation(this._mdcAdapter());
		this.ctrl.init();
		this.ctrl.setChecked(checked);
		if (disabled) {
			this.ctrl.setDisabled(disabled);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (!this.ctrl) {
			return;
		}
		const {checked: checkedPrev, disabled: disabledPrev} = prevProps;
		const {checked, disabled} = this.props;
		if (checked !== checkedPrev) {
			this.ctrl.setChecked(checked);
		}
		if (disabled !== disabledPrev) {
			this.ctrl.setDisabled(Boolean(disabled));
		}
	}

	componentWillUnmount(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
	}

	render(): React.ReactNode {
		const {className, id, name, title} = this.props;
		const {classNames, inputChecked, inputDisabled} = this.state;
		return (
			<div className={cssClassName(...classNames, className)}>
				<div className="mdc-switch__track"/>
				<div className="mdc-switch__thumb-underlay">
					<div className="mdc-switch__thumb"/>
					<input
						aria-checked={inputChecked}
						checked={inputChecked}
						className="mdc-switch__native-control"
						disabled={inputDisabled}
						id={id}
						name={name}
						onChange={this._changeEvent}
						ref={this._inputRef}
						role="switch"
						title={title}
						type="checkbox"/>
				</div>
			</div>
		);
	}

	private _changeEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(event);
		}
	}

	private _mdcAdapter(): MDCSwitchAdapter {
		return {
			addClass: this._mdcAddClass,
			removeClass: this._mdcRemoveClass,
			setNativeControlAttr: this._mdcSetNativeControlAttr,
			setNativeControlChecked: this._mdcSetNativeControlChecked,
			setNativeControlDisabled: this._mdcSetNativeControlDisabled,
		};
	}

	private _mdcAddClass(className: string): void {
		const {classNames} = this.state;
		classNames.add(className);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcRemoveClass(className: string): void {
		const {classNames} = this.state;
		classNames.delete(className);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcSetNativeControlAttr(attr: string, value: string): void {
		this._inputOrDie().setAttribute(attr, value);
	}

	private _mdcSetNativeControlChecked(checked: boolean): void {
		this.setState({inputChecked: checked});
	}

	private _mdcSetNativeControlDisabled(disabled: boolean): void {
		this.setState({inputDisabled: disabled});
	}

	private _inputOrDie(): HTMLInputElement {
		const elem = this._inputRef.current;
		assert(elem, 'Input ref current is null');
		return elem;
	}
}

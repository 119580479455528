import {MDCComponent, MDCFoundation, SpecificEventListener} from '@material/base';
import {MDCCheckbox, MDCCheckboxFactory} from '@material/checkbox';
import {closest} from '@material/dom/ponyfill';
import {MDCLinearProgress} from '@material/linear-progress';
import {MDCDataTableAdapter, MDCDataTableRowSelectionChangedEventDetail, ProgressIndicatorStyles} from '@material/data-table';
import {El} from '../../el';
import {SortOrder} from '../../constants';

export interface BetterSortActionEventDetail {
	columnId?: string | null;
	columnIndex: number;
	sortValue: SortValue;
}

interface BetterMDCDataTableAdapter {
	addClass(className: string): void;
	addClassAtRowIndex(rowIndex: number, cssClasses: string): void;
	getAttributeByHeaderCellIndex(columnIndex: number, attribute: string): string | null;
	getHeaderCellCount(): number;
	getHeaderCellElements(): Element[];
	getRowCount(): number;
	getRowElements(): Element[];
	getRowIdAtIndex(rowIndex: number): string | null;
	getRowIndexByChildElement(el: Element): number;
	getSelectedRowCount(): number;
	getTableContainerHeight(): number;
	getTableHeaderHeight(): number;
	isCheckboxAtRowIndexChecked(rowIndex: number): boolean;
	isHeaderRowCheckboxChecked(): boolean;
	isRowsSelectable(): boolean;
	notifyRowSelectionChanged(data: MDCDataTableRowSelectionChangedEventDetail): void;
	notifySelectedAll(): void;
	notifySortAction(data: BetterSortActionEventDetail): void;
	notifyUnselectedAll(): void;
	registerHeaderRowCheckbox(): Promise<void> | void;
	registerRowCheckboxes(): Promise<void> | void;
	removeClass(className: string): void;
	removeClassAtRowIndex(rowIndex: number, cssClasses: string): void;
	removeClassNameByHeaderCellIndex(columnIndex: number, className: string): void;
	setAttributeAtRowIndex(rowIndex: number, attr: string, value: string): void;
	setAttributeByHeaderCellIndex(columnIndex: number, attribute: string, value: string): void;
	setClassNameByHeaderCellIndex(columnIndex: number, className: string): void;
	setHeaderRowCheckboxChecked(checked: boolean): void;
	setHeaderRowCheckboxIndeterminate(indeterminate: boolean): void;
	setProgressIndicatorStyles(styles: ProgressIndicatorStyles): void;
	setRowCheckboxCheckedAtIndex(rowIndex: number, checked: boolean): void;
	setSortStatusLabelByHeaderCellIndex(columnIndex: number, sortValue: SortValue): void;
}

const cssClasses = {
	CELL: 'mdc-data-table__cell',
	CELL_NUMERIC: 'mdc-data-table__cell--numeric',
	CONTENT: 'mdc-data-table__content',
	HEADER_CELL: 'mdc-data-table__header-cell',
	HEADER_CELL_LABEL: 'mdc-data-table__header-cell-label',
	HEADER_CELL_SORTED: 'mdc-data-table__header-cell--sorted',
	HEADER_CELL_SORTED_DESCENDING:
		'mdc-data-table__header-cell--sorted-descending',
	HEADER_CELL_WITH_SORT: 'mdc-data-table__header-cell--with-sort',
	HEADER_CELL_WRAPPER: 'mdc-data-table__header-cell-wrapper',
	HEADER_ROW: 'mdc-data-table__header-row',
	HEADER_ROW_CHECKBOX: 'mdc-data-table__header-row-checkbox',
	IN_PROGRESS: 'mdc-data-table--in-progress',
	LINEAR_PROGRESS: 'mdc-data-table__linear-progress',
	PAGINATION_ROWS_PER_PAGE_LABEL:
		'mdc-data-table__pagination-rows-per-page-label',
	PAGINATION_ROWS_PER_PAGE_SELECT:
		'mdc-data-table__pagination-rows-per-page-select',
	PROGRESS_INDICATOR: 'mdc-data-table__progress-indicator',
	ROOT: 'mdc-data-table',
	ROW: 'mdc-data-table__row',
	ROW_CHECKBOX: 'mdc-data-table__row-checkbox',
	ROW_SELECTED: 'mdc-data-table__row--selected',
	SORT_ICON_BUTTON: 'mdc-data-table__sort-icon-button',
	SORT_STATUS_LABEL: 'mdc-data-table__sort-status-label',
	TABLE_CONTAINER: 'mdc-data-table__table-container',
};

/**
 * DOM attributes used in component.
 */
const attributes = {
	ARIA_SELECTED: 'aria-selected',
	ARIA_SORT: 'aria-sort',
};

/**
 * List of data attributes used in component.
 */
const dataAttributes = {
	COLUMN_ID: 'data-column-id',
	ROW_ID: 'data-row-id',
};

/**
 * CSS selectors used in component.
 */
const selectors = {
	CONTENT: `.${cssClasses.CONTENT}`,
	HEADER_CELL: `.${cssClasses.HEADER_CELL}`,
	HEADER_CELL_WITH_SORT: `.${cssClasses.HEADER_CELL_WITH_SORT}`,
	HEADER_ROW: `.${cssClasses.HEADER_ROW}`,
	HEADER_ROW_CHECKBOX: `.${cssClasses.HEADER_ROW_CHECKBOX}`,
	PROGRESS_INDICATOR: `.${cssClasses.PROGRESS_INDICATOR}`,
	ROW: `.${cssClasses.ROW}`,
	ROW_CHECKBOX: `.${cssClasses.ROW_CHECKBOX}`,
	ROW_SELECTED: `.${cssClasses.ROW_SELECTED}`,
	SORT_ICON_BUTTON: `.${cssClasses.SORT_ICON_BUTTON}`,
	SORT_STATUS_LABEL: `.${cssClasses.SORT_STATUS_LABEL}`,
};

/**
 * Messages used in component.
 */
const messages = {
	SORTED_IN_DESCENDING: 'Sorted in descending order',
	SORTED_IN_ASCENDING: 'Sorted in ascending order',
};

/**
 * Attributes and selectors used in component.
 * @deprecated Use `attributes`, `dataAttributes` and `selectors` instead.
 */
const strings = {
	ARIA_SELECTED: attributes.ARIA_SELECTED,
	ARIA_SORT: attributes.ARIA_SORT,
	DATA_ROW_ID_ATTR: dataAttributes.ROW_ID,
	HEADER_ROW_CHECKBOX_SELECTOR: selectors.HEADER_ROW_CHECKBOX,
	ROW_CHECKBOX_SELECTOR: selectors.ROW_CHECKBOX,
	ROW_SELECTED_SELECTOR: selectors.ROW_SELECTED,
	ROW_SELECTOR: selectors.ROW,
};

/**
 * Sort values defined by ARIA.
 * See https://www.w3.org/WAI/PF/aria/states_and_properties#aria-sort
 */
export enum SortValue {
	// Items are sorted in ascending order by this column.
	ASCENDING = 'ascending',

	// Items are sorted in descending order by this column.
	DESCENDING = 'descending',

	// There is no defined sort applied to the column.
	NONE = 'none',

	// A sort algorithm other than ascending or descending has been applied.
	OTHER = 'other',
}

/**
 * Event names used in component.
 */
export const events = {
	ROW_SELECTION_CHANGED: 'MDCDataTable:rowSelectionChanged',
	SELECTED_ALL: 'MDCDataTable:selectedAll',
	UNSELECTED_ALL: 'MDCDataTable:unselectedAll',
	SORTED: 'MDCDataTable:sorted',
};

class MDCDataTableFoundation extends MDCFoundation<BetterMDCDataTableAdapter> {
	static get defaultAdapter(): BetterMDCDataTableAdapter {
		return {
			addClass: () => undefined,
			addClassAtRowIndex: () => undefined,
			getAttributeByHeaderCellIndex: () => '',
			getHeaderCellCount: () => 0,
			getHeaderCellElements: () => [],
			getRowCount: () => 0,
			getRowElements: () => [],
			getRowIdAtIndex: () => '',
			getRowIndexByChildElement: () => 0,
			getSelectedRowCount: () => 0,
			getTableContainerHeight: () => 0,
			getTableHeaderHeight: () => 0,
			isCheckboxAtRowIndexChecked: () => false,
			isHeaderRowCheckboxChecked: () => false,
			isRowsSelectable: () => false,
			notifyRowSelectionChanged: () => undefined,
			notifySelectedAll: () => undefined,
			notifySortAction: () => undefined,
			notifyUnselectedAll: () => undefined,
			registerHeaderRowCheckbox: () => undefined,
			registerRowCheckboxes: () => undefined,
			removeClass: () => undefined,
			removeClassAtRowIndex: () => undefined,
			removeClassNameByHeaderCellIndex: () => undefined,
			setAttributeAtRowIndex: () => undefined,
			setAttributeByHeaderCellIndex: () => undefined,
			setClassNameByHeaderCellIndex: () => undefined,
			setHeaderRowCheckboxChecked: () => undefined,
			setHeaderRowCheckboxIndeterminate: () => undefined,
			setProgressIndicatorStyles: () => undefined,
			setRowCheckboxCheckedAtIndex: () => undefined,
			setSortStatusLabelByHeaderCellIndex: () => undefined,
		};
	}

	constructor(adapter?: Partial<MDCDataTableAdapter>) {
		super({...MDCDataTableFoundation.defaultAdapter, ...adapter});
	}

	/**
	 * Re-initializes header row checkbox and row checkboxes when selectable rows are added or removed from table.
	 * Use this if registering checkbox is synchronous.
	 */
	layout() {
		if (this.adapter.isRowsSelectable()) {
			this.adapter.registerHeaderRowCheckbox();
			this.adapter.registerRowCheckboxes();

			this.setHeaderRowCheckboxState();
		}
	}

	/**
	 * Re-initializes header row checkbox and row checkboxes when selectable rows are added or removed from table.
	 * Use this if registering checkbox is asynchronous.
	 */
	async layoutAsync(): Promise<void> {
		if (this.adapter.isRowsSelectable()) {
			await this.adapter.registerHeaderRowCheckbox();
			await this.adapter.registerRowCheckboxes();

			this.setHeaderRowCheckboxState();
		}
	}

	/**
	 * @return Returns array of row elements.
	 */
	getRows(): Element[] {
		return this.adapter.getRowElements();
	}

	/**
	 * @return Array of header cell elements.
	 */
	getHeaderCells(): Element[] {
		return this.adapter.getHeaderCellElements();
	}

	/**
	 * Sets selected row ids. Overwrites previously selected rows.
	 * @param rowIds Array of row ids that needs to be selected.
	 */
	setSelectedRowIds(rowIds: string[]) {
		for (let rowIndex = 0; rowIndex < this.adapter.getRowCount(); rowIndex++) {
			const rowId = this.adapter.getRowIdAtIndex(rowIndex);

			let isSelected = false;
			if (rowId && rowIds.indexOf(rowId) >= 0) {
				isSelected = true;
			}

			this.adapter.setRowCheckboxCheckedAtIndex(rowIndex, isSelected);
			this.selectRowAtIndex(rowIndex, isSelected);
		}

		this.setHeaderRowCheckboxState();
	}

	/**
	 * @return Returns array of all row ids.
	 */
	getRowIds(): Array<string | null> {
		const rowIds = [];
		for (let rowIndex = 0; rowIndex < this.adapter.getRowCount(); rowIndex++) {
			rowIds.push(this.adapter.getRowIdAtIndex(rowIndex));
		}

		return rowIds;
	}

	/**
	 * @return Returns array of selected row ids.
	 */
	getSelectedRowIds(): Array<string | null> {
		const selectedRowIds: Array<string | null> = [];
		for (let rowIndex = 0; rowIndex < this.adapter.getRowCount(); rowIndex++) {
			if (this.adapter.isCheckboxAtRowIndexChecked(rowIndex)) {
				selectedRowIds.push(this.adapter.getRowIdAtIndex(rowIndex));
			}
		}

		return selectedRowIds;
	}

	/**
	 * Handles header row checkbox change event.
	 */
	handleHeaderRowCheckboxChange() {
		const isHeaderChecked = this.adapter.isHeaderRowCheckboxChecked();

		for (let rowIndex = 0; rowIndex < this.adapter.getRowCount(); rowIndex++) {
			this.adapter.setRowCheckboxCheckedAtIndex(rowIndex, isHeaderChecked);
			this.selectRowAtIndex(rowIndex, isHeaderChecked);
		}

		if (isHeaderChecked) {
			this.adapter.notifySelectedAll();
		} else {
			this.adapter.notifyUnselectedAll();
		}
	}

	/**
	 * Handles change event originated from row checkboxes.
	 */
	handleRowCheckboxChange(event: Event) {
		const rowIndex = this.adapter.getRowIndexByChildElement(
			event.target as HTMLInputElement);

		if (rowIndex === -1) {
			return;
		}

		const selected = this.adapter.isCheckboxAtRowIndexChecked(rowIndex);

		this.selectRowAtIndex(rowIndex, selected);
		this.setHeaderRowCheckboxState();

		const rowId = this.adapter.getRowIdAtIndex(rowIndex);
		this.adapter.notifyRowSelectionChanged({rowId, rowIndex, selected});
	}

	resetHeaderCellState(excludeIndex: number): void {
		for (let index = 0; index < this.adapter.getHeaderCellCount(); index++) {
			if (index === excludeIndex) {
				continue;
			}
			this.adapter.removeClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED);
			this.adapter.removeClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED_DESCENDING);
			this.adapter.setAttributeByHeaderCellIndex(index, strings.ARIA_SORT, SortValue.NONE);
			this.adapter.setSortStatusLabelByHeaderCellIndex(index, SortValue.NONE);
		}
	}

	// setHeaderCellSortState(columnIndex: number): SortValue {
	// 	this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED);
	// 	const currentSortValue = this.adapter.getAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT);
	// 	let sortValue: SortValue;
	// 	// Set to descending if sorted on ascending order.
	// 	if (currentSortValue === SortValue.ASCENDING) {
	// 		this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
	// 		this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.DESCENDING);
	// 		sortValue = SortValue.DESCENDING;
	// 		// Set to ascending if sorted on descending order.
	// 	} else if (currentSortValue === SortValue.DESCENDING) {
	// 		this.adapter.removeClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
	// 		this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.ASCENDING);
	// 		sortValue = SortValue.ASCENDING;
	// 	} else {
	// 		// Set to ascending by default when not sorted.
	// 		this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.ASCENDING);
	// 		sortValue = SortValue.ASCENDING;
	// 	}
	// 	this.adapter.setSortStatusLabelByHeaderCellIndex(columnIndex, sortValue);
	// 	return sortValue;
	// }

	// setHeaderCellSortState(columnIndex: number): SortValue {
	// 	this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED);
	// 	const currentSortValue = this.adapter.getAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT);
	// 	let sortValue: SortValue;
	// 	if (currentSortValue === SortValue.ASCENDING) {
	// 		this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
	// 		this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.DESCENDING);
	// 		sortValue = SortValue.DESCENDING;
	// 	} else {
	// 		this.adapter.removeClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
	// 		this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.ASCENDING);
	// 		sortValue = SortValue.ASCENDING;
	// 	}
	// 	this.adapter.setSortStatusLabelByHeaderCellIndex(columnIndex, sortValue);
	// 	return sortValue;
	// }

	setHeaderCellSortState(columnIndex: number, sortValue: SortValue): void {
		this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED);
		if (sortValue === SortValue.DESCENDING) {
			this.adapter.setClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
			this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.DESCENDING);
			sortValue = SortValue.DESCENDING;
		} else {
			this.adapter.removeClassNameByHeaderCellIndex(columnIndex, cssClasses.HEADER_CELL_SORTED_DESCENDING);
			this.adapter.setAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT, SortValue.ASCENDING);
			sortValue = SortValue.ASCENDING;
		}
		this.adapter.setSortStatusLabelByHeaderCellIndex(columnIndex, sortValue);
	}

	nextSortValue(current?: string | null): SortValue {
		return (current === SortValue.ASCENDING) ?
			SortValue.DESCENDING :
			SortValue.ASCENDING;
	}

	setHeaderSortState(sortColumnIndex: number, sortValue: SortValue): void {
		this.resetHeaderCellState(sortColumnIndex);
		this.setHeaderCellSortState(sortColumnIndex, sortValue);
	}

	// handleSortAction(eventData: SortActionEventData) {
	// 	const {columnId, columnIndex, headerCell} = eventData;
	// 	const sortValue = this.nextSortValue(this.adapter.getAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT));
	// 	this.setHeaderSortState(columnIndex, sortValue);
	// 	this.adapter.notifySortAction({
	// 		columnId,
	// 		columnIndex,
	// 		headerCell,
	// 		sortValue,
	// 	});
	// }

	handleSortAction(columnIndex: number, columnId?: string | null) {
		const sortValue = this.nextSortValue(this.adapter.getAttributeByHeaderCellIndex(columnIndex, strings.ARIA_SORT));
		this.setHeaderSortState(columnIndex, sortValue);
		this.adapter.notifySortAction({
			columnId,
			columnIndex,
			sortValue,
		});
	}

	/**
	 * Shows progress indicator blocking only the table body content when in
	 * loading state.
	 */
	showProgress() {
		const tableHeaderHeight = this.adapter.getTableHeaderHeight();
		// Calculate the height of table content (Not scroll content) excluding
		// header row height.
		const height = this.adapter.getTableContainerHeight() - tableHeaderHeight;
		this.adapter.setProgressIndicatorStyles({
			height: `${height}px`,
			top: `${tableHeaderHeight - 2}px`,
		});
		this.adapter.addClass(cssClasses.IN_PROGRESS);
	}

	/**
	 * Hides progress indicator when data table is finished loading.
	 */
	hideProgress() {
		this.adapter.removeClass(cssClasses.IN_PROGRESS);
	}

	/**
	 * Updates header row checkbox state based on number of rows selected.
	 */
	private setHeaderRowCheckboxState() {
		if (this.adapter.getSelectedRowCount() === 0) {
			this.adapter.setHeaderRowCheckboxChecked(false);
			this.adapter.setHeaderRowCheckboxIndeterminate(false);
		} else if (
			this.adapter.getSelectedRowCount() === this.adapter.getRowCount()) {
			this.adapter.setHeaderRowCheckboxChecked(true);
			this.adapter.setHeaderRowCheckboxIndeterminate(false);
		} else {
			this.adapter.setHeaderRowCheckboxIndeterminate(true);
			this.adapter.setHeaderRowCheckboxChecked(false);
		}
	}

	/**
	 * Sets the attributes of row element based on selection state.
	 */
	private selectRowAtIndex(rowIndex: number, selected: boolean) {
		if (selected) {
			this.adapter.addClassAtRowIndex(rowIndex, cssClasses.ROW_SELECTED);
			this.adapter.setAttributeAtRowIndex(
				rowIndex, strings.ARIA_SELECTED, 'true');
		} else {
			this.adapter.removeClassAtRowIndex(rowIndex, cssClasses.ROW_SELECTED);
			this.adapter.setAttributeAtRowIndex(
				rowIndex, strings.ARIA_SELECTED, 'false');
		}
	}
}

export class MDCDataTable extends MDCComponent<MDCDataTableFoundation> {
	static attachTo(root: Element): MDCDataTable {
		return new MDCDataTable(root);
	}

	// private headerRowCheckbox!: MDCCheckbox;
	// private rowCheckboxList: MDCCheckbox[] = [];
	// private checkboxFactory!: MDCCheckboxFactory;
	private linearProgress!: MDCLinearProgress;
	private headerRow!: HTMLElement;
	private content!: HTMLElement;
	private handleHeaderRowCheckboxChange!: SpecificEventListener<'change'>;
	private handleRowCheckboxChange!: SpecificEventListener<'change'>;
	// private headerRowClickListener!:
	// 	SpecificEventListener<'click'>;  // Assigned in `initialSyncWithDOM()`

	// private get headerRowCheckbox() {
	// 	// selectors.HEADER_ROW
	// }

	// noinspection JSUnusedLocalSymbols
	initialize(checkboxFactory: MDCCheckboxFactory = (el: Element) => new MDCCheckbox(el)) {
		// this.checkboxFactory = checkboxFactory;
	}

	initialSyncWithDOM() {
		this.headerRow = this.root.querySelector(`.${cssClasses.HEADER_ROW}`) as HTMLElement;
		this.handleHeaderRowCheckboxChange = () => this.foundation.handleHeaderRowCheckboxChange();
		this.headerRow.addEventListener('change', this.handleHeaderRowCheckboxChange);
		// this.headerRowClickListener = event => this.handleHeaderRowClick(event);
		// this.headerRow.addEventListener('click', this.headerRowClickListener);
		this.content = this.root.querySelector(`.${cssClasses.CONTENT}`) as HTMLElement;
		this.handleRowCheckboxChange = event => {
			const el = El.fromEvent(event);
			if (el.closestMatchingAncestor(selectors.ROW_CHECKBOX) || el.closestMatchingAncestor(selectors.HEADER_ROW_CHECKBOX)) {
				this.foundation.handleRowCheckboxChange(event);
			}
		};
		this.content.addEventListener('change', this.handleRowCheckboxChange);
		this.layout();
	}

	/**
	 * Re-initializes header row checkbox and row checkboxes when selectable rows are added or removed from table.
	 */
	layout() {
		this.foundation.layout();
	}

	/**
	 * @return Returns array of header row cell elements.
	 */
	getHeaderCells(): Element[] {
		return [].slice.call(this.root.querySelectorAll(selectors.HEADER_CELL));
	}

	/**
	 * @return Returns array of row elements.
	 */
	getRows(): Element[] {
		return this.foundation.getRows();
	}

	/**
	 * @return Returns array of selected row ids.
	 */
	getSelectedRowIds(): Array<string | null> {
		return this.foundation.getSelectedRowIds();
	}

	/**
	 * Sets selected row ids. Overwrites previously selected rows.
	 * @param rowIds Array of row ids that needs to be selected.
	 */
	setSelectedRowIds(rowIds: string[]) {
		this.foundation.setSelectedRowIds(rowIds);
	}

	/**
	 * Shows progress indicator when data table is in loading state.
	 */
	showProgress() {
		this.getLinearProgress().open();
		this.foundation.showProgress();
	}

	/**
	 * Hides progress indicator after data table is finished loading.
	 */
	hideProgress() {
		this.foundation.hideProgress();
		this.getLinearProgress().close();
	}

	destroy() {
		if (this.handleHeaderRowCheckboxChange) {
			this.headerRow.removeEventListener(
				'change', this.handleHeaderRowCheckboxChange);
		}
		// if (this.headerRowClickListener) {
		// 	this.headerRow.removeEventListener('click', this.headerRowClickListener);
		// }
		if (this.handleRowCheckboxChange) {
			this.content.removeEventListener('change', this.handleRowCheckboxChange);
		}
		// if (this.headerRowCheckbox) {
		// 	this.headerRowCheckbox.destroy();
		// }
		// if (this.rowCheckboxList) {
		// 	for (const checkbox of this.rowCheckboxList) {
		// 		checkbox.destroy();
		// 	}
		// }
	}

	private headerRowCheckboxInput(): HTMLInputElement | null {
		const headerCells = this.getHeaderCells();
		if (headerCells.length > 0) {
			const cell = headerCells[0];
			const rowCheckbox = cell.querySelector(selectors.HEADER_ROW_CHECKBOX);
			if (rowCheckbox) {
				return rowCheckbox.querySelector<HTMLInputElement>('input[type="checkbox"]');
			}
		}
		return null;
	}

	private rowCheckboxInput(rowIndex: number): HTMLInputElement | null {
		const rows = this.getRows();
		if ((rowIndex >= 0) && (rowIndex < rows.length)) {
			const row = rows[rowIndex];
			const rowCheckbox = row.querySelector(selectors.ROW_CHECKBOX);
			if (rowCheckbox) {
				return rowCheckbox.querySelector<HTMLInputElement>('input[type="checkbox"]');
			}
		}
		return null;
	}

	getDefaultFoundation() {
		// DO NOT INLINE this variable. For backward compatibility, foundations take a Partial<MDCFooAdapter>.
		// To ensure we don't accidentally omit any methods, we need a separate, strongly typed adapter variable.
		// tslint:disable:object-literal-sort-keys Methods should be in the same order as the adapter interface.
		const adapter: BetterMDCDataTableAdapter = {
			addClass: (className) => {
				this.root.classList.add(className);
			},
			removeClass: (className) => {
				this.root.classList.remove(className);
			},
			getHeaderCellElements: () => this.getHeaderCells(),
			getHeaderCellCount: () => this.getHeaderCells().length,
			getAttributeByHeaderCellIndex: (index, attribute) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					return cells[index].getAttribute(attribute);
				}
				return null;
			},
			setAttributeByHeaderCellIndex: (index, attribute, value) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].setAttribute(attribute, value);
				}
			},
			setClassNameByHeaderCellIndex: (index, className) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].classList.add(className);
				}
			},
			removeClassNameByHeaderCellIndex: (index, className) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].classList.remove(className);
				}
			},
			notifySortAction: (data: BetterSortActionEventDetail) => {
				this.emit(events.SORTED, data, /** shouldBubble */ true);
			},
			getTableContainerHeight: () => {
				const tableContainer = this.root.querySelector<HTMLElement>(
					`.${cssClasses.TABLE_CONTAINER}`);

				if (!tableContainer) {
					throw new Error('MDCDataTable: Table container element not found.');
				}

				return tableContainer.getBoundingClientRect().height;
			},
			getTableHeaderHeight: () => {
				const tableHeader =
					this.root.querySelector<HTMLElement>(selectors.HEADER_ROW);

				if (!tableHeader) {
					throw new Error('MDCDataTable: Table header element not found.');
				}

				return tableHeader.getBoundingClientRect().height;
			},
			setProgressIndicatorStyles: (styles) => {
				const progressIndicator =
					this.root.querySelector<HTMLElement>(selectors.PROGRESS_INDICATOR);

				if (!progressIndicator) {
					throw new Error(
						'MDCDataTable: Progress indicator element not found.');
				}

				progressIndicator.style.setProperty('height', styles.height);
				progressIndicator.style.setProperty('top', styles.top);
			},
			addClassAtRowIndex: (rowIndex: number, className: string) => {
				const rows = this.getRows();
				if ((rowIndex >= 0) && (rowIndex < rows.length)) {
					rows[rowIndex].classList.add(className);
				}
			},
			getRowCount: () => this.getRows().length,
			getRowElements:
				() => [].slice.call(this.root.querySelectorAll(selectors.ROW)),
			getRowIdAtIndex: (rowIndex: number) => {
				const rows = this.getRows();
				if ((rowIndex >= 0) && (rowIndex < rows.length)) {
					return rows[rowIndex].getAttribute(dataAttributes.ROW_ID);
				}
				return null;
			},
			getRowIndexByChildElement: (el: Element) => {
				return this.getRows().indexOf(
					(closest(el, selectors.ROW) as HTMLElement));
			},
			getSelectedRowCount: () =>
				this.root.querySelectorAll(selectors.ROW_SELECTED).length,
			isCheckboxAtRowIndexChecked: (rowIndex: number) => {
				const el = this.rowCheckboxInput(rowIndex);
				return el ? el.checked : false;
			},
			isHeaderRowCheckboxChecked: () => {
				const el = this.headerRowCheckboxInput();
				return el ? el.checked : false;
			},
			isRowsSelectable: () =>
				!!this.root.querySelector(selectors.ROW_CHECKBOX) ||
				!!this.root.querySelector(selectors.HEADER_ROW_CHECKBOX),
			notifyRowSelectionChanged:
				(data: MDCDataTableRowSelectionChangedEventDetail) => {
					this.emit(
						events.ROW_SELECTION_CHANGED, {
							row: this.getRowByIndex(data.rowIndex),
							rowId: this.getRowIdByIndex(data.rowIndex),
							rowIndex: data.rowIndex,
							selected: data.selected,
						},
						/** shouldBubble */ true);
				},
			notifySelectedAll: () => {
				this.emit(events.SELECTED_ALL, {}, /** shouldBubble */ true);
			},
			notifyUnselectedAll: () => {
				this.emit(events.UNSELECTED_ALL, {}, /** shouldBubble */ true);
			},
			registerHeaderRowCheckbox: () => {
				// if (this.headerRowCheckbox) {
				// 	this.headerRowCheckbox.destroy();
				// }
				// const checkboxEl = (this.root.querySelector(selectors.HEADER_ROW_CHECKBOX) as HTMLElement);
				// this.headerRowCheckbox = this.checkboxFactory(checkboxEl);
			},
			registerRowCheckboxes: () => {
				// if (this.rowCheckboxList) {
				// 	this.rowCheckboxList.forEach((checkbox) => {
				// 		checkbox.destroy();
				// 	});
				// }
				//
				// this.rowCheckboxList = [];
				// this.getRows().forEach((rowEl) => {
				// 	const checkbox = this.checkboxFactory(
				// 		(rowEl.querySelector(selectors.ROW_CHECKBOX) as HTMLElement));
				// 	this.rowCheckboxList.push(checkbox);
				// });
			},
			removeClassAtRowIndex: (rowIndex: number, className: string) => {
				const rows = this.getRows();
				if ((rowIndex >= 0) && (rowIndex < rows.length)) {
					rows[rowIndex].classList.remove(className);
				}
			},
			setAttributeAtRowIndex:
				(rowIndex: number, attr: string, value: string) => {
					const rows = this.getRows();
					if ((rowIndex >= 0) && (rowIndex < rows.length)) {
						rows[rowIndex].setAttribute(attr, value);
					}
				},
			setHeaderRowCheckboxChecked: (checked: boolean) => {
				const el = this.headerRowCheckboxInput();
				if (el) {
					el.checked = checked;
				}
			},
			setHeaderRowCheckboxIndeterminate: (indeterminate: boolean) => {
				const el = this.headerRowCheckboxInput();
				if (el) {
					el.indeterminate = indeterminate;
				}
			},
			setRowCheckboxCheckedAtIndex: (rowIndex: number, checked: boolean) => {
				const el = this.rowCheckboxInput(rowIndex);
				if (el) {
					el.checked = checked;
				}
			},
			setSortStatusLabelByHeaderCellIndex: (
				columnIndex: number, sortValue: SortValue) => {
				const cells = this.getHeaderCells();
				if ((columnIndex >= 0) && (columnIndex < cells.length)) {
					const headerCell = cells[columnIndex];
					const sortStatusLabel = headerCell.querySelector<HTMLElement>(selectors.SORT_STATUS_LABEL);
					if (!sortStatusLabel) {
						return;
					}
					sortStatusLabel.textContent = this.getSortStatusMessageBySortValue(sortValue);
				}
			},
		};
		return new MDCDataTableFoundation(adapter);
	}

	private getRowByIndex(index: number): Element {
		return this.getRows()[index];
	}

	private getRowIdByIndex(index: number): string | null {
		const row = this.getRowByIndex(index);
		if (row) {
			return row.getAttribute(dataAttributes.ROW_ID);
		}
		return null;
	}

	private handleHeaderRowClick(event: Event): void {
		const headerCell = closest(event.target as Element, selectors.HEADER_CELL_WITH_SORT) as HTMLElement;
		if (!headerCell) {
			return;
		}
		const columnId = headerCell.getAttribute(dataAttributes.COLUMN_ID);
		const columnIndex = this.getHeaderCells().indexOf(headerCell);
		if (columnIndex === -1) {
			return;
		}
		this.foundation.handleSortAction(columnIndex, columnId);
	}

	doHideIndicator(): void {
		this.foundation.resetHeaderCellState(-33);
	}

	doSort(columnIndex: number, order: SortOrder): void {
		this.foundation.setHeaderSortState(
			columnIndex,
			(order === SortOrder.AscendingOrder) ?
				SortValue.ASCENDING :
				SortValue.DESCENDING);
	}

	private getSortStatusMessageBySortValue(sortValue: SortValue): string {
		switch (sortValue) {
			case SortValue.ASCENDING:
				return messages.SORTED_IN_ASCENDING;
			case SortValue.DESCENDING:
				return messages.SORTED_IN_DESCENDING;
			default:
				return '';
		}
	}

	private getLinearProgressElement(): HTMLElement {
		const el =
			this.root.querySelector<HTMLElement>(`.${cssClasses.LINEAR_PROGRESS}`);
		if (!el) {
			throw new Error('MDCDataTable: linear progress element is not found.');
		}

		return el;
	}

	private getLinearProgress(): MDCLinearProgress {
		if (!this.linearProgress) {
			const el = this.getLinearProgressElement();
			this.linearProgress = new MDCLinearProgress(el);
		}

		return this.linearProgress;
	}
}

import {Form} from '../ui/form';
import {TextInput} from '../ui/textinput';
import {ButtonRole, StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {closestMatchingElement, isNumber, stringRepeat} from '../util';
import {Dialog} from '../ui/dialog';
import {Button} from '../ui/button';
import {VBoxLayout} from '../ui/layout';
import {El} from '../el';
import {ComboBox} from '../ui/combobox';

class PriceGroupSelect extends ComboBox {
	priceGroupId(): number | null {
		const rv = Number.parseInt(this.value());
		return isNumber(rv) ? rv : null;
	}

	setPricegroupId(value?: number | string | null): void {
		this.setValue((value || isNumber(value)) ? String(value) : '');
	}
}

class ObjectForm extends Form {
	nameInput: TextInput;
	priceGroupSelect: PriceGroupSelect;

	constructor() {
		super();
		const box = new VBoxLayout(this);
		box.setSpacing(16);
		this.nameInput = new TextInput({
			inputId: 'id_client-type-name-label',
			name: 'name',
			labelText: 'Name',
		});
		box.addEl(this.nameInput);
		this.priceGroupSelect = new PriceGroupSelect({emptyItem: true, labelText: 'Price group', parent: this});
		this.priceGroupSelect.setName('priceGroupId');
		this.priceGroupSelect.setFixPosition(true);
		box.addEl(this.priceGroupSelect);
		this.setMethod('POST');
	}

	setPriceGroups(objs: IPriceGroup[]): void {
		const ctrl = this.priceGroupSelect;
		ctrl.clearItems();
		for (let i = 0; i < objs.length; ++i) {
			const obj = objs[i];
			ctrl.addItem({value: obj.id, listItemOptions: {text: `${stringRepeat('-', obj.level)} ${obj.name}`}});
		}
	}
}

class Dia extends Dialog {
	theForm: ObjectForm;

	constructor(theForm: ObjectForm) {
		super();
		this.theForm = theForm;
		this.setContent(this.theForm);
	}

	protected buttonClicked(button: Button): void {
		if (this.buttonRole(button) === ButtonRole.AcceptRole && !this.theForm.isValid()) {
			return;
		}
		super.buttonClicked(button);
	}
}

class DocumentObject {
	_objectID: number;
	_primaryText: El | null;
	_root: El;

	constructor(objectID: number) {
		this._objectID = objectID;
		this._root = El.fromSelector(`[data-id="${this._objectID}"].pb-list-item`);
		this._primaryText = this._root.querySelector('.mdc-list-item__text');
	}

	remove(): void {
		this._root.remove();
	}

	setName(value: string): void {
		if (this._primaryText) {
			this._primaryText.setText(value);
		}
	}
}

class View {
	static CREATE_CTA_SELECTOR = '#id_object-create-button';
	static DELETE_SELECTOR = 'button[name="delete"]';
	static OBJECT_LIST_SELECTOR = '.pb-list';
	static OBJECT_SELECTOR = '[data-id].pb-list-item';

	_form: ObjectForm;
	_objectID: number | null;
	dia: Dia;

	constructor() {
		this._createCTAClickEvent = this._createCTAClickEvent.bind(this);
		this._deleteClickEvent = this._deleteClickEvent.bind(this);
		this._deleteFormSubmitEvent = this._deleteFormSubmitEvent.bind(this);
		this._editFormSubmitEvent = this._editFormSubmitEvent.bind(this);
		this._objectClickEvent = this._objectClickEvent.bind(this);
		this._form = new ObjectForm();
		this._objectID = null;
		this.dia = new Dia(this._form);
	}

	init(): void {
		setTimeout(async () => this._form.setPriceGroups(await svc.catalog.priceGroup.list()), 39);
		this._form.setId('id_client-type-form');
		let elem: Element | null = document.querySelector(View.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this._createCTAClickEvent);
		}
		document
			.querySelectorAll(View.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this._objectClickEvent));
		document
			.querySelectorAll(View.DELETE_SELECTOR)
			.forEach(el => el.addEventListener('click', this._deleteClickEvent));
		if (this._objectListIsEmpty()) {
			this.openCreateDialog();
		}
	}

	objectData(): Promise<IClientUserType> {
		return svc.group.client.type.get(this.objectID());
	}

	objectID(): number {
		if (isNumber(this._objectID)) {
			return this._objectID;
		}
		throw new Error('View::objectID object ID is null');
	}

	openCreateDialog(): void {
		this._form.onSubmit();
		this._form.setAction(window.pburls.group.clientType.list);
		this._form.nameInput.setValue('');
		this._form.priceGroupSelect.setPricegroupId(null);
		this._form.show();
		this.dia.setTitle('Create type');
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this._form.id());
		}
		this.dia.open();
	}

	openDeleteDialog(data: IClientUserType): void {
		this._form.hide();
		this._form.onSubmit(this._deleteFormSubmitEvent);
		this._form.setAction();
		this._form.nameInput.setValue(data.name);
		this._form.priceGroupSelect.setPricegroupId(data.priceGroupId);
		this.dia.setTitle(`Delete ${data.name}?`);
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			// this.dia.setDefaultButton(noBtn);
			noBtn.setRaised(true);
		}
		const yesBtn = this.dia.button(StandardButton.Yes);
		if (yesBtn) {
			yesBtn.setType('submit');
			yesBtn.setAttribute('form', this._form.id());
		}
		this.dia.open();
	}

	openDetailDialog(data: IClientUserType): void {
		this._form.onSubmit(this._editFormSubmitEvent);
		this._form.setAction();
		this._form.nameInput.setValue(data.name);
		this._form.priceGroupSelect.setPricegroupId(data.priceGroupId);
		this._form.show();
		this.dia.setTitle('Edit type');
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this._form.id());
		}
		this.dia.open();
	}

	private _createCTAClickEvent(): void {
		this.openCreateDialog();
	}

	private async _deleteClickEvent(event: Event): Promise<void> {
		event.stopPropagation();
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::_deleteClickEvent event target is null');
			return;
		}
		const elem = new El(null, closestMatchingElement(tgt, View.OBJECT_SELECTOR));
		const id = Number(elem.attribute('data-id'));
		if (Number.isNaN(id)) {
			console.log('View::_deleteClickEvent event target data-id attribute did not yield valid value');
			return;
		}
		this._objectID = id;
		const data = await this.objectData();
		this.openDeleteDialog(data);
	}

	private async _deleteFormSubmitEvent(): Promise<void> {
		const data = await this.objectData();
		const objectID = data.id;
		await svc.group.client.type.delete(objectID);
		this._removeDocumentObject(objectID);
		this.dia.accept();
		this._objectID = null;
	}

	private async _editFormSubmitEvent(): Promise<void> {
		let data = await this.objectData();
		const payload = Object.assign<object, IClientUserType, Pick<IClientUserType, 'name' | 'priceGroupId'>>(
			{},
			data,
			{
				name: this._form.nameInput.value(),
				priceGroupId: this._form.priceGroupSelect.priceGroupId(),
			});
		data = await svc.group.client.type.update(payload.id, payload);
		this._syncDocumentObject(data);
		this.dia.accept();
		this._objectID = null;
	}

	private async _objectClickEvent(event: Event): Promise<void> {
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::_objectClickEvent event target is null');
			return;
		}
		const elem = new El(null, closestMatchingElement(tgt, View.OBJECT_SELECTOR));
		const id = Number(elem.attribute('data-id'));
		if (Number.isNaN(id)) {
			console.log('View::_objectClickEvent event target data-id attribute did not yield valid value');
			return;
		}
		this._objectID = id;
		const data = await this.objectData();
		this.openDetailDialog(data);
	}

	private _objectListIsEmpty(): boolean {
		const list = document.querySelector(View.OBJECT_LIST_SELECTOR);
		if (!list) {
			console.log(`View::_objectListIsEmpty element was not found using selector "${View.OBJECT_LIST_SELECTOR}"`);
			return true;
		}
		return list.querySelectorAll(View.OBJECT_SELECTOR).length < 1;
	}

	private _removeDocumentObject(objectID: number): void {
		const docObj = new DocumentObject(objectID);
		docObj.remove();
		const list = document.querySelector(View.OBJECT_LIST_SELECTOR);
		if (list) {
			if (list.querySelectorAll(View.OBJECT_SELECTOR).length < 1) {
				list.remove();
			}
		}
	}

	private _syncDocumentObject(data: IClientUserType): void {
		const docObj = new DocumentObject(data.id);
		docObj.setName(data.name);
	}
}

export function init(): void {
	const comp = new View();
	comp.init();
}

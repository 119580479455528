import React from 'react';

import LabeledSwitch from './labeledswitch';
import {bind} from '../../../../util';

interface IProps {
	onChange: (value: boolean, name: 'isPublic') => any;
	value: boolean;
}

export default class IsPublic extends React.Component<IProps, {}> {
	static Name: 'isPublic' = 'isPublic';

	@bind
	changeEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		this.props.onChange(
			event.target.checked,
			this.name(),
		);
	}

	name(): 'isPublic' {
		return IsPublic.Name;
	}

	render(): React.ReactNode {
		return <LabeledSwitch
			htmlFor="id_isPublic"
			id="id_isPublic"
			isChecked={this.props.value}
			label="Visible to clients"
			name={this.name()}
			onChange={this.changeEvent}/>;
	}
}

import React from 'react';

import {TextField} from '../../../components';

interface IProps {
	onChange: (value: string, name: 'icon') => any;
	value: string;
}

export default class Icon extends React.Component<IProps, {}> {
	static Name: 'icon' = 'icon';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, this.name());
	}

	name(): 'icon' {
		return Icon.Name;
	}

	render(): React.ReactNode {
		const {value} = this.props;
		return <TextField
			className="width--100-percent"
			helpText="e.g.: photo_camera"
			label="Icon"
			name={this.name()}
			onChange={this.changeEvent}
			type="text"
			value={value}/>;
	}
}

import React from 'react';
import {cssClasses as _mdcCssClasses} from '@material/list';

import {bind, closestMatchingElement, cssClassName} from '../../../util';
import {ListItemContext, IListItemContext} from './index';

interface IProps extends IListItemContext {
	activated?: boolean;
	checked?: boolean;
	className?: string;
	dataValue?: string;
	disabled?: boolean;
	leadingChild?: React.ReactNode;
	noRipple?: boolean;
	role?: string;
	selected?: boolean;
	tabIndex?: number;
	trailingChild?: React.ReactNode;
}

interface IState {
	tabIndex?: number;
}

class ListItemBase extends React.Component<IProps, IState> {
	private rootRef: React.RefObject<any>;

	constructor(props: IProps) {
		super(props);
		this.rootRef = React.createRef<any>();
		this.state = {
			tabIndex: props.tabIndex,
		};
	}

	@bind
	private blurEvent(event: React.FocusEvent): void {
		const {blurEvent} = this.props;
		if (blurEvent) {
			blurEvent(event, this.siblingElementIndex(event.currentTarget));
		}
	}

	@bind
	private clickEvent(event: React.MouseEvent): void {
		const {clickEvent} = this.props;
		if (clickEvent) {
			clickEvent(event, this.siblingElementIndex(event.currentTarget));
		}
	}

	componentDidMount(): void {
		if (this.rootRef.current && this.props.initialTabIndex) {
			this.setState({tabIndex: this.props.initialTabIndex(this.siblingElementIndex(this.rootRef.current))});
		}
	}

	componentDidUpdate(prevProps: IProps): void {
		if (prevProps.tabIndex !== this.props.tabIndex) {
			this.setState({tabIndex: this.props.tabIndex});
		}
	}

	componentWillUnmount(): void {
		if (this.props.destroyed && this.rootRef.current) {
			this.props.destroyed(this.siblingElementIndex(this.rootRef.current));
		}
	}

	@bind
	private focusEvent(event: React.FocusEvent): void {
		const {focusEvent} = this.props;
		if (focusEvent) {
			focusEvent(event, this.siblingElementIndex(event.currentTarget));
		}
	}

	@bind
	private keyDownEvent(event: React.KeyboardEvent): void {
		const {keyDownEvent} = this.props;
		if (keyDownEvent) {
			keyDownEvent(event, this.siblingElementIndex(event.currentTarget));
		}
	}

	render(): React.ReactNode {
		const {
			activated,
			checked,
			children,
			className,
			classNames,
			dataValue,
			disabled,
			leadingChild,
			noRipple,
			role,
			selected,
			trailingChild,
		} = this.props;
		const {tabIndex} = this.state;
		let names: Array<string> = [];
		if (classNames && this.rootRef.current) {
			const s = classNames().get(this.siblingElementIndex(this.rootRef.current));
			if (s) {
				names = s.toArray();
			}
		}
		const clsName = cssClassName(
			_mdcCssClasses.LIST_ITEM_CLASS,
			'pb-list-item',
			className,
			names,
			{
				[_mdcCssClasses.LIST_ITEM_DISABLED_CLASS]: disabled,
				[_mdcCssClasses.LIST_ITEM_SELECTED_CLASS]: selected,
				[_mdcCssClasses.LIST_ITEM_ACTIVATED_CLASS]: activated,
			},
		);
		return (
			<li
				aria-checked={checked}
				aria-disabled={disabled}
				aria-selected={selected}
				className={clsName}
				data-value={dataValue}
				onBlur={this.blurEvent}
				onClick={this.clickEvent}
				onFocus={this.focusEvent}
				onKeyDown={this.keyDownEvent}
				ref={this.rootRef}
				role={role}
				tabIndex={tabIndex}>
				{noRipple ? null : <div className="mdc-list-item__ripple"/>}
				{leadingChild}
				{children}
				{trailingChild}
			</li>
		);
	}

	private siblingElementIndex(elem: Element): number {
		return this.siblingElements().indexOf(elem);
	}

	private siblingElements(): Array<Element> {
		if (this.rootRef.current) {
			const parentListRoot = closestMatchingElement(
				this.rootRef.current,
				`.${_mdcCssClasses.ROOT}`);
			if (parentListRoot) {
				return Array.from(parentListRoot.querySelectorAll(`.${_mdcCssClasses.LIST_ITEM_CLASS}`));
			}
		}
		return [];
	}
}

export const ListItem = (props: React.PropsWithChildren<IProps>) => {
	return (
		<ListItemContext.Consumer>
			{(context) => <ListItemBase {...context} {...props} />}
		</ListItemContext.Consumer>
	);
};

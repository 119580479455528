import React from 'react';

import Subheading from './subheading';
import {IconButton} from '../../../components';

interface IProps {
	icon: string;
	onClick: () => any;
	text: string;
	title?: string;
}

export default class IconButtonHeading extends React.Component<IProps, {}> {
	render(): React.ReactNode {
		const {
			icon,
			onClick,
			text,
			title,
		} = this.props;
		return (
			<Subheading className="pb-catalog-item-form__toggleable-view-label">
				{text}
				<IconButton className="pb-icon-button" icon={icon} onClick={onClick} title={title}/>
			</Subheading>
		);
	}
}

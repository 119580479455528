import {AlignmentFlag, CheckState, ItemDataRole, ItemFlag, MetaType} from '../../constants';
import {ModelIndex} from '../../itemmodel';
import {Variant} from '../../variant';
import {El} from '../../el';
import {assert, padStart, testFlag, to12Hour, toPeriod} from '../../util';
import {date, datetime, time} from '../../datetime';
import type {TableEl} from './el';

enum Position {
	Left,
	Right,
	Top,
	Bottom,
}

enum StateFlag {
	None = 0x00000000,
	Enabled = 0x00000001,
	Raised = 0x00000002,
	Off = 0x00000008,
	NoChange = 0x00000010,
	On = 0x00000020,
	HasFocus = 0x00000100,
	MouseOver = 0x00002000,
	Selected = 0x00008000,
	Active = 0x00010000,
	Open = 0x00040000,
	Sibling = 0x00200000,
	Editing = 0x00400000,
	HasEditFocus = 0x01000000,
	ReadOnly = 0x02000000,
	Small = 0x04000000,
	Mini = 0x08000000,
}

export enum ViewItemFeature {
	None = 0x00000000,
	WrapText = 0x00000001,
	HyperLink = 0x00000002,
	HasCheckIndicator = 0x00000004,
	HasDisplay = 0x00000008,
	HasDecoration = 0x00000010,
	HasSortIndicator = 0x00000020,
	IsSelectable = 0x00000040,
	HasToolTip = 0x00000080,
}

enum ViewItemPosition {
	Invalid,
	Beginning,
	Middle,
	End,
	OnlyOne,
}

export class StyleOption {
	background: string = '';
	checkState: CheckState = CheckState.Unchecked;
	decorationAlignment: AlignmentFlag = AlignmentFlag.AlignLeft;
	decorationPosition: Position = Position.Left;
	displayAlignment: AlignmentFlag = AlignmentFlag.AlignLeft;
	el: El | null = null;
	features: ViewItemFeature = ViewItemFeature.None;
	foreground: string = '';
	icon: string = '';
	index: ModelIndex = new ModelIndex();
	state: StateFlag = StateFlag.None;
	text: string = '';
	toolTip: string = '';
	url: string = '';
	viewItemPosition: ViewItemPosition = ViewItemPosition.Invalid;

	toString(): string {
		return `${this.constructor.name}()`;
	}
}

export class TableElStyleOption extends StyleOption {
	el: TableEl | null = null;
}

function formatDate(dt: date): string {
	const parts: Array<string> = [
		String(dt.month),
		String(dt.day),
	];
	const year = String(dt.year);
	if (year.length === 4) {
		parts.push(year.slice(2));
	} else {
		console.log('formatDate: Something wrong with date.');
	}
	return parts.join('/');
}

function formatDateTime(dt: datetime): string {
	return `${formatDate(dt.date())} at ${formatTime(dt.time())}`;
}

function formatTime(t: time): string {
	const hour12 = to12Hour(t.hour);
	const minute = padStart(t.minute, 2, '0');
	const period = toPeriod(t.hour);
	return `${hour12}:${minute} ${period}`;
}

export class AbstractItemDelegate {
	paint(option: StyleOption, index: ModelIndex): void {
	}
}

export class StyledItemDelegate extends AbstractItemDelegate {
	initStyleOption(opt: StyleOption, index: ModelIndex): void {
		opt.index = index;
		let value: Variant = index.data(ItemDataRole.TextAlignmentRole);
		if (value.isValid() && !value.isNull()) {
			opt.displayAlignment = value.toNumber();
		}
		value = index.data(ItemDataRole.BackgroundRole);
		if (value.isValid() && !value.isNull()) {
			opt.background = value.toString();
		}
		value = index.data(ItemDataRole.ForegroundRole);
		if (value.isValid() && !value.isNull()) {
			opt.foreground = value.toString();
		}
		value = index.data(ItemDataRole.CheckStateRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasCheckIndicator;
			opt.checkState = value.toNumber();
		}
		value = index.data(ItemDataRole.DisplayRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasDisplay;
			opt.text = textForRole(ItemDataRole.DisplayRole, value);
		}
		value = index.data(ItemDataRole.ToolTipRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasToolTip;
			opt.toolTip = textForRole(ItemDataRole.ToolTipRole, value);
		}
		value = index.data(ItemDataRole.UserRole);
		if (value.isValid() && !value.isNull()) {
			if (value.type() === MetaType.Url) {
				opt.features |= ViewItemFeature.HyperLink;
				opt.url = value.toString();
			}
		}
		value = index.data(ItemDataRole.DecorationRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasDecoration;
			opt.icon = value.toString();
		}
		const flags = index.flags();
		if (testFlag(flags, ItemFlag.ItemIsSelectable)) {
			opt.features |= ViewItemFeature.IsSelectable;
		}
	}

	paint(option: StyleOption, index: ModelIndex): void {
		assert(index.isValid());
		this.initStyleOption(option, index);
	}
}

export class TableElDelegate extends StyledItemDelegate {
	paint(option: TableElStyleOption, index: ModelIndex): void {
		super.paint(option, index);
		if (option.el) {
			option.el.paint(option);
		}
	}
}

export function textForRole(role: ItemDataRole, value: Variant): string {
	let text: string;
	switch (value.type()) {
		case MetaType.Number:
			text = value.toNumber().toLocaleString();
			break;
		case MetaType.DateTime:
			text = formatDateTime(value.toDateTime());
			break;
		case MetaType.Date:
			text = formatDate(value.toDate());
			break;
		case MetaType.Time:
			text = formatTime(value.toTime());
			break;
		default:
			text = value.toString();
			if (role === ItemDataRole.DisplayRole) {
				text = text.replace(/\n/g, '\u2028');
			}
			break;
	}
	return text;
}

import React, {PropsWithChildren} from 'react';
import {
	SortValue,
	MDCDataTableAdapter,
	SortActionEventDetail,
	MDCDataTableFoundation,
	ProgressIndicatorStyles,
	messages as _mdcMessages,
	cssClasses as _mdcCssClasses,
	dataAttributes as _mdcDataAttributes,
	MDCDataTableRowSelectionChangedEventDetail,
} from '@material/data-table';

import {
	bind,
	assert,
	cssClassName, closestMatchingElement,
} from '../../../util';

export interface SortRequestData {
	columnId: string | null;
	columnIndex: number;
	type: SortType;
}

export enum SelectType {
	None,
	All,
}

export enum SortType {
	None,
	Asc,
	Desc,
	Other,
}

export interface RowSelectData {
	rowId: string | null;
	rowIndex: number;
	selected: boolean;
}

interface IProps extends React.HTMLAttributes<any> {
	onManySelect?: (type: SelectType) => any;
	onRowSelect?: (data: RowSelectData) => any;
	onSortRequest?: (data: SortRequestData) => any;
}

type Props = PropsWithChildren<IProps>;

interface State {
	classNames: Set<string>;
	columnIndexClassNames: Map<number, Set<string>>;
	rowIndexClassNames: Map<number, Set<string>>;
}

export default class DataTable extends React.Component<Props, State> {
	private _mdc: MDCDataTableFoundation | null;
	private _rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this._mdc = null;
		this._rootRef = React.createRef();
		this.state = {
			classNames: new Set(['mdc-data-table']),
			columnIndexClassNames: new Map(),
			rowIndexClassNames: new Map(),
		};
	}

	componentDidMount(): void {
		this._mdc = new MDCDataTableFoundation(this._mdcAdapter());
		this._mdc.init();
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	render(): React.ReactNode {
		const {children, className} = this.props;
		const {classNames} = this.state;
		return (
			<div className={cssClassName(...classNames, className)} ref={this._rootRef}>
				{children}
			</div>
		);
	}

	private _mdcAdapter(): MDCDataTableAdapter {
		return {
			addClass: this._mdcAddClass,
			addClassAtRowIndex: this._mdcAddClassAtRowIndex,
			getAttributeByHeaderCellIndex: this._mdcGetAttributeByHeaderCellIndex,
			getHeaderCellCount: this._mdcGetHeaderCellCount,
			getHeaderCellElements: this._mdcGetHeaderCellElements,
			getRowCount: this._mdcGetRowCount,
			getRowElements: this._mdcGetRowElements,
			getRowIdAtIndex: this._mdcGetRowIdAtIndex,
			getRowIndexByChildElement: this._mdcGetRowIndexByChildElement,
			getSelectedRowCount: this._mdcGetSelectedRowCount,
			getTableContainerHeight: this._mdcGetTableContainerHeight,
			getTableHeaderHeight: this._mdcGetTableHeaderHeight,
			isCheckboxAtRowIndexChecked: this._mdcIsCheckboxAtRowIndexChecked,
			isHeaderRowCheckboxChecked: this._mdcIsHeaderRowCheckboxChecked,
			isRowsSelectable: this._mdcIsRowsSelectable,
			notifyRowSelectionChanged: this._mdcNotifyRowSelectionChanged,
			notifySelectedAll: this._mdcNotifySelectedAll,
			notifySortAction: this._mdcNotifySortAction,
			notifyUnselectedAll: this._mdcNotifyUnselectedAll,
			registerHeaderRowCheckbox: this._mdcRegisterHeaderRowCheckbox,
			registerRowCheckboxes: this._mdcRegisterRowCheckboxes,
			removeClass: this._mdcRemoveClass,
			removeClassAtRowIndex: this._mdcRemoveClassAtRowIndex,
			removeClassNameByHeaderCellIndex: this._mdcRemoveClassNameByHeaderCellIndex,
			setAttributeAtRowIndex: this._mdcSetAttributeAtRowIndex,
			setAttributeByHeaderCellIndex: this._mdcSetAttributeByHeaderCellIndex,
			setClassNameByHeaderCellIndex: this._mdcSetClassNameByHeaderCellIndex,
			setHeaderRowCheckboxChecked: this._mdcSetHeaderRowCheckboxChecked,
			setHeaderRowCheckboxIndeterminate: this._mdcSetHeaderRowCheckboxIndeterminate,
			setProgressIndicatorStyles: this._mdcSetProgressIndicatorStyles,
			setRowCheckboxCheckedAtIndex: this._mdcSetRowCheckboxCheckedAtIndex,
			setSortStatusLabelByHeaderCellIndex: this._mdcSetSortStatusLabelByHeaderCellIndex,
		};
	}

	private _headerCellElementAtIndex(index: number): HTMLTableHeaderCellElement | null {
		const rv = this._headerCellElements()[index];
		return rv || null;
	}

	private _headerCellElements(): HTMLTableHeaderCellElement[] {
		return Array.from(this._rootElementOrDie().querySelectorAll(`.${_mdcCssClasses.HEADER_CELL}`));
	}

	private _headerRowCheckboxContainerElement(): HTMLElement | null {
		return this._rootElementOrDie().querySelector(`.${_mdcCssClasses.HEADER_ROW_CHECKBOX}`);
	}

	@bind
	private _mdcAddClass(className: string): void {
		const {classNames} = this.state;
		classNames.add(className);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcAddClassAtRowIndex(rowIndex: number, className: string): void {
		const {rowIndexClassNames} = this.state;
		const elem = this._rowElementAtIndex(rowIndex);
		if (elem) {
			let classNames = rowIndexClassNames.get(rowIndex);
			if (!classNames) {
				classNames = new Set();
				rowIndexClassNames.set(rowIndex, classNames);
			}
			classNames.add(className);
			elem.classList.add(className);
		} else {
			rowIndexClassNames.delete(rowIndex);
		}
		this.setState({rowIndexClassNames: new Map(rowIndexClassNames)});
	}

	@bind
	private _mdcGetAttributeByHeaderCellIndex(columnIndex: number, attribute: string): string | null {
		const elem = this._headerCellElementAtIndex(columnIndex);
		if (elem) {
			return elem.getAttribute(attribute);
		}
		return null;
	}

	@bind
	private _mdcGetHeaderCellCount(): number {
		return this._headerCellElements().length;
	}

	@bind
	private _mdcGetHeaderCellElements(): Element[] {
		return this._headerCellElements();
	}

	@bind
	private _mdcGetRowCount(): number {
		return this._rowElements().length;
	}

	@bind
	private _mdcGetRowElements(): Element[] {
		return this._rowElements();
	}

	@bind
	private _mdcGetRowIdAtIndex(rowIndex: number): string | null {
		const elem = this._rowElementAtIndex(rowIndex);
		if (elem) {
			return elem.getAttribute(_mdcDataAttributes.ROW_ID);
		}
		return null;
	}

	@bind
	private _mdcGetRowIndexByChildElement(child: Element): number {
		const row = closestMatchingElement<HTMLTableRowElement>(child, _mdcCssClasses.ROW);
		if (row) {
			return this._rowElements().indexOf(row);
		}
		return -1;
	}

	@bind
	private _mdcGetSelectedRowCount(): number {
		return this._selectedRowElements().length;
	}

	@bind
	private _mdcGetTableContainerHeight(): number {
		const elem = this._rootElementOrDie().querySelector(`.${_mdcCssClasses.TABLE_CONTAINER}`);
		if (elem) {
			return elem.getBoundingClientRect().height;
		}
		return 0;
	}

	@bind
	private _mdcGetTableHeaderHeight(): number {
		const tbody = this._rootElementOrDie().querySelector<HTMLTableSectionElement>(`.${_mdcCssClasses.HEADER_ROW}`);
		if (tbody) {
			return tbody.getBoundingClientRect().height;
		}
		return 0;
	}

	@bind
	private _mdcIsCheckboxAtRowIndexChecked(rowIndex: number): boolean {
		const containerElem = this._rowCheckboxContainerElementAtIndex(rowIndex);
		if (containerElem) {
			const inputElem = containerElem.querySelector<HTMLInputElement>('input[type="checkbox"]');
			if (inputElem) {
				return inputElem.checked;
			}
		}
		return false;
	}

	@bind
	private _mdcIsHeaderRowCheckboxChecked(): boolean {
		const containerElem = this._headerRowCheckboxContainerElement();
		if (containerElem) {
			const inputElem = containerElem.querySelector<HTMLInputElement>('input[type="checkbox"]');
			if (inputElem) {
				return inputElem.checked;
			}
		}
		return false;
	}

	@bind
	private _mdcIsRowsSelectable(): boolean {
		return this._rowCheckboxContainerElements().length > 0;
	}

	@bind
	private _mdcNotifyRowSelectionChanged(data: MDCDataTableRowSelectionChangedEventDetail): void {
		const {onRowSelect} = this.props;
		if (onRowSelect) {
			onRowSelect(data);
		}
	}

	@bind
	private _mdcNotifySelectedAll(): void {
		const {onManySelect} = this.props;
		if (onManySelect) {
			onManySelect(SelectType.All);
		}
	}

	@bind
	private _mdcNotifySortAction(data: SortActionEventDetail): void {
		const {onSortRequest} = this.props;
		if (!onSortRequest) {
			return;
		}
		let sortType: SortType;
		switch (data.sortValue) {
			case SortValue.ASCENDING:
				sortType = SortType.Asc;
				break;
			case SortValue.DESCENDING:
				sortType = SortType.Desc;
				break;
			case SortValue.NONE:
				sortType = SortType.None;
				break;
			case SortValue.OTHER:
				sortType = SortType.Other;
				break;
		}
		onSortRequest({
			columnId: data.columnId,
			columnIndex: data.columnIndex,
			type: sortType,
		});
	}

	@bind
	private _mdcNotifyUnselectedAll(): void {
		const {onManySelect} = this.props;
		if (onManySelect) {
			onManySelect(SelectType.None);
		}
	}

	@bind
	private _mdcRegisterHeaderRowCheckbox(): Promise<void> | void {
		console.log(`_mdcRegisterHeaderRowCheckbox() NotImplemented`);
	}

	@bind
	private _mdcRegisterRowCheckboxes(): Promise<void> | void {
		console.log(`_mdcRegisterRowCheckboxes() NotImplemented`);
	}

	@bind
	private _mdcRemoveClass(className: string): void {
		const {classNames} = this.state;
		classNames.delete(className);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcRemoveClassAtRowIndex(rowIndex: number, className: string): void {
		const {rowIndexClassNames} = this.state;
		const elem = this._rowElementAtIndex(rowIndex);
		if (elem) {
			elem.classList.remove(className);
		}
		const classNames = rowIndexClassNames.get(rowIndex);
		if (classNames) {
			classNames.delete(className);
		}
		this.setState({rowIndexClassNames: new Map(rowIndexClassNames)});
	}

	@bind
	private _mdcRemoveClassNameByHeaderCellIndex(columnIndex: number, className: string): void {
		const {columnIndexClassNames} = this.state;
		const elem = this._headerCellElementAtIndex(columnIndex);
		if (elem) {
			elem.classList.remove(className);
		}
		const classNames = columnIndexClassNames.get(columnIndex);
		if (classNames) {
			classNames.delete(className);
		}
		this.setState({columnIndexClassNames: new Map(columnIndexClassNames)});
	}

	@bind
	private _mdcSetAttributeAtRowIndex(rowIndex: number, name: string, value: string): void {
		const elem = this._rowElementAtIndex(rowIndex);
		if (elem) {
			elem.setAttribute(name, value);
			this.forceUpdate();
		}
	}

	@bind
	private _mdcSetAttributeByHeaderCellIndex(columnIndex: number, name: string, value: string): void {
		const elem = this._headerCellElementAtIndex(columnIndex);
		if (elem) {
			elem.setAttribute(name, value);
			this.forceUpdate();
		}
	}

	@bind
	private _mdcSetClassNameByHeaderCellIndex(columnIndex: number, className: string): void {
		const {columnIndexClassNames} = this.state;
		const elem = this._headerCellElementAtIndex(columnIndex);
		if (elem) {
			elem.classList.add(className);
		}
		let classNames = columnIndexClassNames.get(columnIndex);
		if (!classNames) {
			classNames = new Set();
			columnIndexClassNames.set(columnIndex, classNames);
		}
		classNames.add(className);
		this.setState({columnIndexClassNames: new Map(columnIndexClassNames)});
	}

	@bind
	private _mdcSetHeaderRowCheckboxChecked(checked: boolean): void {
		console.log(`_mdcSetHeaderRowCheckboxChecked(${checked}) NotImplemented`);
	}

	@bind
	private _mdcSetHeaderRowCheckboxIndeterminate(indeterminate: boolean): void {
		console.log(`_mdcSetHeaderRowCheckboxIndeterminate(${indeterminate}) NotImplemented`);
	}

	@bind
	private _mdcSetProgressIndicatorStyles(styles: ProgressIndicatorStyles): void {
		console.log(`_mdcSetProgressIndicatorStyles(%o) NotImplemented`, styles);
	}

	@bind
	private _mdcSetRowCheckboxCheckedAtIndex(rowIndex: number, checked: boolean): void {
		console.log(`_mdcSetRowCheckboxCheckedAtIndex(${rowIndex}, ${checked}) NotImplemented`);
	}

	@bind
	private _mdcSetSortStatusLabelByHeaderCellIndex(columnIndex: number, sortValue: SortValue): void {
		const cellElem = this._headerCellElementAtIndex(columnIndex);
		if (cellElem) {
			const labelElem = cellElem.querySelector<HTMLElement>(`.${_mdcCssClasses.SORT_STATUS_LABEL}`);
			if (labelElem) {
				let msg: string = '';
				switch (sortValue) {
					case SortValue.ASCENDING:
						msg = _mdcMessages.SORTED_IN_ASCENDING;
						break;
					case SortValue.DESCENDING:
						msg = _mdcMessages.SORTED_IN_DESCENDING;
						break;
				}
				labelElem.textContent = msg;
			}
		}
	}

	private _rootElementOrDie(): HTMLDivElement {
		const rv = this._rootRef.current;
		assert(rv, 'Root ref current is null');
		return rv;
	}

	private _rowCheckboxContainerElementAtIndex(index: number): HTMLElement | null {
		const rv = this._rowCheckboxContainerElements()[index];
		return rv || null;
	}

	private _rowCheckboxContainerElements(): HTMLElement[] {
		return Array.from(this._rootElementOrDie().querySelectorAll(`.${_mdcCssClasses.ROW_CHECKBOX}`));
	}

	private _rowElementAtIndex(index: number): HTMLTableRowElement | null {
		const rv = this._rowElements()[index];
		return rv || null;
	}

	private _rowElements(): HTMLTableRowElement[] {
		return Array.from(this._rootElementOrDie().querySelectorAll(`.${_mdcCssClasses.ROW}`));
	}

	private _selectedRowElements(): HTMLTableRowElement[] {
		return Array.from(this._rootElementOrDie().querySelectorAll(`.${_mdcCssClasses.ROW_SELECTED}`));
	}
}

import React, {PropsWithChildren} from 'react';
import {
	Corner,
	MDCMenuAdapter,
	MDCMenuFoundation,
	MDCMenuItemEventDetail,
} from '@material/menu';

import List from '../list';
import MenuSurface from './surface';
import {assert, bind, closestMatchingElement, cssClassName} from '../../../util';

interface IProps {
	anchorElement?: Element | null;
	className?: string;
	corner?: Corner;
	isOpen: boolean;
	listRole?: string;
	margins?: Partial<IMargins>;
	onClose?: () => any;
	onOpen?: () => any;
	onSelect?: (index: number) => any;
	typeAhead?: boolean;
	wrapFocus?: boolean;
}

type Props = PropsWithChildren<IProps>;
export type WickedCorner = Corner;

export default class Menu extends React.Component<Props, {}> {
	private _listRef: React.RefObject<List>;
	private _surfaceRef: React.RefObject<MenuSurface>;
	private _mdc: MDCMenuFoundation | null;

	constructor(props: Props) {
		super(props);
		this._listRef = React.createRef();
		this._surfaceRef = React.createRef();
		this._mdc = null;
	}

	componentDidMount(): void {
		this._mdc = new MDCMenuFoundation(this._mdcAdapter());
		this._mdc.init();
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	count(): number {
		return this._listOrDie().count();
	}

	focusItemAtIndex(index: number): void {
		this._listOrDie().focusItemAtIndex(index);
	}

	itemAtIndex(index: number): Element | null {
		return this._listOrDie().itemAtIndex(index);
	}

	items(): Element[] {
		return this._listOrDie().itemElements();
	}

	render(): React.ReactNode {
		const {anchorElement, children, className, corner, isOpen, listRole, margins, typeAhead, wrapFocus} = this.props;
		return (
			<MenuSurface anchorElement={anchorElement} className={cssClassName('mdc-menu', className)} corner={corner} isOpen={isOpen} margins={margins} onClose={this._surfaceClosed} onOpen={this._surfaceOpened} ref={this._surfaceRef}>
				<List onSelect={this._listSelect} ref={this._listRef} role={listRole} typeAhead={typeAhead} wrapFocus={wrapFocus}>
					{children}
				</List>
			</MenuSurface>
		);
	}

	selectedIndex(): number | number[] {
		return this._listOrDie().selectedIndex();
	}

	setSelectedIndex(index: number | number[]): void {
		this._listOrDie().setSelectedIndex(index);
	}

	setWrapFocus(wrap: boolean): void {
		this._listOrDie().setWrapFocus(wrap);
	}

	typeaheadInProgress(): boolean {
		return this._listOrDie().typeaheadInProgress();
	}

	typeaheadMatchItem(nextChar: string, startingIndex: number): number {
		return this._listOrDie().typeaheadMatchItem(nextChar, startingIndex);
	}

	private _listOrDie(): List {
		const comp = this._listRef.current;
		assert(comp, 'List ref current is null');
		return comp;
	}

	@bind
	private _listSelect(index: number): void {
		const mdc = this._mdc;
		if (mdc) {
			const elem = this._listOrDie().itemElements()[index];
			if (elem) {
				mdc.handleItemAction(elem);
			}
		}
	}

	private _mdcAdapter(): MDCMenuAdapter {
		return {
			addAttributeToElementAtIndex: this._mdcAddAttributeToElementAtIndex,
			addClassToElementAtIndex: this._mdcAddClassToElementAtIndex,
			closeSurface: this._mdcCloseSurface,
			elementContainsClass: this._mdcElementContainsClass,
			focusItemAtIndex: this._mdcFocusItemAtIndex,
			focusListRoot: this._mdcFocusListRoot,
			getElementIndex: this._mdcGetElementIndex,
			getMenuItemCount: this._mdcGetMenuItemCount,
			getSelectedSiblingOfItemAtIndex: this._mdcGetSelectedSiblingOfItemAtIndex,
			isSelectableItemAtIndex: this._mdcIsSelectableItemAtIndex,
			notifySelected: this._mdcNotifySelected,
			removeAttributeFromElementAtIndex: this._mdcRemoveAttributeFromElementAtIndex,
			removeClassFromElementAtIndex: this._mdcRemoveClassFromElementAtIndex,
		};
	}

	@bind
	private _mdcAddAttributeToElementAtIndex(index: number, attr: string, value: string): void {
		this._listOrDie().setAttributeForItemAtIndex(index, attr, value);
	}

	@bind
	private _mdcAddClassToElementAtIndex(index: number, className: string): void {
		this._listOrDie().addClassNameToItemAtIndex(index, className);
	}

	@bind
	private _mdcCloseSurface(skipRestoreFocus?: boolean): void {
		this._surfaceOrDie().close(skipRestoreFocus);
	}

	@bind
	private _mdcElementContainsClass(element: Element, className: string): boolean {
		return element.classList.contains(className);
	}

	@bind
	private _mdcFocusItemAtIndex(index: number): void {
		this.focusItemAtIndex(index);
	}

	@bind
	private _mdcFocusListRoot(): void {
		this._listOrDie().focus();
	}

	@bind
	private _mdcGetElementIndex(element: Element): number {
		return this._listOrDie().indexOfListItemElement(element);
	}

	@bind
	private _mdcGetMenuItemCount(): number {
		return this.count();
	}

	@bind
	private _mdcGetSelectedSiblingOfItemAtIndex(index: number): number {
		const elem = this._listOrDie().itemElements()[index];
		if (elem) {
			const selectionGroupElem = closestMatchingElement<HTMLElement>(elem, `.${MDCMenuFoundation.cssClasses.MENU_SELECTION_GROUP}`);
			if (selectionGroupElem) {
				const selectedItemElem = selectionGroupElem.querySelector<HTMLElement>(`.${MDCMenuFoundation.cssClasses.MENU_SELECTED_LIST_ITEM}`);
				if (selectedItemElem) {
					return this._listOrDie().itemElements().indexOf(selectedItemElem);
				}
			}
		}
		return -1;
	}

	@bind
	private _mdcIsSelectableItemAtIndex(index: number): boolean {
		const elem = this._listOrDie().itemElements()[index];
		if (elem) {
			return Boolean(closestMatchingElement(elem, `.${MDCMenuFoundation.cssClasses.MENU_SELECTION_GROUP}`));
		}
		return false;
	}

	@bind
	private _mdcNotifySelected(evtData: MDCMenuItemEventDetail): void {
		const {onSelect} = this.props;
		if (onSelect) {
			onSelect(evtData.index);
		}
	}

	@bind
	private _mdcRemoveAttributeFromElementAtIndex(index: number, attr: string): void {
		this._listOrDie().removeAttributeFromItemAtIndex(index, attr);
	}

	@bind
	private _mdcRemoveClassFromElementAtIndex(index: number, className: string): void {
		this._listOrDie().removeClassNameFromItemAtIndex(index, className);
	}

	@bind
	private _surfaceClosed(): void {
		const {onClose} = this.props;
		if (onClose) {
			onClose();
		}
	}

	@bind
	private _surfaceOpened(): void {
		const {onOpen} = this.props;
		const mdc = this._mdc;
		if (mdc) {
			mdc.handleMenuSurfaceOpened();
		}
		if (onOpen) {
			onOpen();
		}
	}

	private _surfaceOrDie(): MenuSurface {
		const comp = this._surfaceRef.current;
		assert(comp, 'Surface ref current is null');
		return comp;
	}
}

import {path} from '../urls';
import {RequestHandler} from '../urls/handlers';
import {init as initPhone} from './phone';
import {init as initButton} from './button';
import {init as initDrawer} from './drawer';
import {init as initTabNav} from './tabnav';
import {init as initTopBar} from './topbar';
import {init as initPolicy} from './policy';
import {init as initMarkets} from './markets';
import {init as initClientUser} from './clientuserlist';
import {init as initQuickBooks} from './quickbooks';
import {init as initTeamMember} from './teammember';
import {init as initPriceGroups} from './pricegroups';
import {init as initClientTypes} from './clienttypes';
import {init as initClientGroup} from './clientgroup';
import {init as initProjectList} from './projectlist';
import {init as initSuperSecret} from './supersecret';
import {init as initPaymentMethod} from './paymentmethod';
import {init as initProjectDetail} from './projectdetail';
import {init as initAlternateEmail} from './alternateemail';
import {init as initCatalogItemList} from './catalogitemlist';
import {init as initCatalogItemDetail} from './catalogitemdetail';
import {init as initGroupSubscriptions} from './groupsubscriptions';
import {init as initAccountNotifications} from './accountnotifications';
import {ResolverMatch} from '../urls/resolvers';

const urls = window.pburls;
const urlPatterns = [
	path(`^${urls.project.list.slice(1)}$`, initProjectList),
	path(`^${urls.account.notifications.list.slice(1)}$`, initAccountNotifications),
	path(`^${urls.account.alternateEmail.list.slice(1)}$`, initAlternateEmail),
	path(`^${urls.account.paymentMethod.list.slice(1)}$`, initPaymentMethod),
	path(`^${urls.account.phoneNumber.list.slice(1)}$`, initPhone),
	path(`^${urls.group.catalog.item.list.slice(1)}$`, initCatalogItemList),
	path(`^${urls.group.clientUser.list.slice(1)}$`, initClientUser),
	path(`^${urls.group.clientGroup.list.slice(1)}$`, initClientGroup),
	path(`^${urls.group.notifications.list.slice(1)}$`, initGroupSubscriptions),
	path(`^${urls.group.catalog.priceGroup.list.slice(1)}$`, initPriceGroups),
	path(`^${urls.group.clientType.list.slice(1)}$`, initClientTypes),
	path(`^${urls.group.teamMember.list.slice(1)}$`, initTeamMember),
	path(`^${urls.group.market.list.slice(1)}$`, initMarkets),
	path(`^${urls.group.policy.create.slice(1)}$`, initPolicy),
	path(/^group\/policies\/(\d+)\/$/, initPolicy),
	path(`^${urls.group.quickbooks.detail.slice(1)}$`, initQuickBooks),
	path(/^group\/catalog\/items\/(\d+)\/$/, initCatalogItemDetail),
	path(/^([A-Z0-9]+)\//, initProjectDetail),
];
if (urls.supersecret) {
	urlPatterns.push(path(`^${urls.supersecret.slice(1)}`, initSuperSecret));
}
const handler = new RequestHandler();
const req = {pathname: window.location.pathname, urlPatterns};
let match: ResolverMatch | undefined = undefined;
try {
	match = handler.resolveRequest(req);
} catch (exc) {
	console.error(exc);
}
if (match) {
	match.func.call(this, ...match.args);
}

initButton();
initTopBar();
initDrawer();
initTabNav();

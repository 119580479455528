import {El, elOpts, ElOpts} from '../../el';
import {IconButton, IconButtonOpts} from '../iconbutton';
import {bind} from '../../util';

export class CCardSubtleLabel extends El {
	static Prefix: string = '\u00A0\u00A0\u2022\u00A0';

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-subtle-label');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'span';
		}
		super(opts);
	}

	setText(text?: string | null): void {
		if (text) {
			text = `${CCardSubtleLabel.Prefix}${text}`;
		}
		super.setText(text);
	}

	text(): string {
		return super.text().replace(CCardSubtleLabel.Prefix, '');
	}
}

export class CCardRoot extends El {
	static Selector: string = '.pb-cc-root';

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-root', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

export class CCardHeader extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-header');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

export class CCardHeaderInner extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-header__inner', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

export class CCardToolButton extends IconButton {
	constructor(opts: Partial<IconButtonOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts>, parent?: El | null);
	constructor(opts?: Partial<IconButtonOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<IconButtonOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<IconButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-tool-button');
		opts.classNames = classNames;
		super(opts);
	}

	setOn(on: boolean): void {
		this.setClass(on, 'pb-cc-tool-button--on');
		this.setClass(!on, 'pb-cc-tool-button--off');
	}
}

export class CCardScrollableContainer extends El {
	private lastScrollTop: number;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-scrollable-container');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.lastScrollTop = 0;
		this.addEventListener('scroll', this.event);
	}

	destroy(): void {
		this.removeEventListener('scroll', this.event);
		super.destroy();
	}

	@bind
	private event(event: Event): void {
		if (event.type === 'scroll') {
			this.scrollEvent();
		}
	}

	private scrollEvent(): void {
		const scrollTop = this.elem.scrollTop;
		if (!((scrollTop === this.lastScrollTop) || ((scrollTop > 0) && (this.lastScrollTop > 0)))) {
			const root = this.closestMatchingAncestor(CCardRoot.Selector);
			if (root) {
				root.setClass(scrollTop > 0, 'pb-cc-header--scrollable-scrolled');
			}
		}
		this.lastScrollTop = scrollTop;
	}
}

export class CCardScrollableContainerInner extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-scrollable-container__inner');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

export interface CCardSectionHeaderOpts extends ElOpts {
	text: string;
}

export class CCardSectionHeader extends El {
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardSectionHeaderOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardSectionHeaderOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardSectionHeaderOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardSectionHeaderOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-section__header',
			...classNames,
		];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		if (opts.text) {
			this.setText(opts.text);
		}
	}
}

export interface CCardSectionOpts extends ElOpts {
	header: string;
}

export class CCardSection extends El {
	private hdr: CCardSectionHeader | null;

	constructor(opts: Partial<CCardSectionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardSectionOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardSectionOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardSectionOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardSectionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardSectionOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-section', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.hdr = null;
		if (opts.header) {
			this.setHeader(opts.header);
		}
	}

	clear(): void {
		this.setHeader();
	}

	protected createHeader(): CCardSectionHeader {
		return new CCardSectionHeader({
			parent: this,
			placementIndex: 0,
		});
	}

	destroy(): void {
		if (this.hdr) {
			this.hdr.clear();
			this.hdr.destroy();
		}
		this.hdr = null;
		super.destroy();
	}

	header(): string {
		if (this.hdr) {
			return this.hdr.text();
		}
		return '';
	}

	setHeader(text?: string | null): void {
		if (!text) {
			if (this.hdr) {
				this.hdr.clear();
				this.hdr.destroy();
			}
			this.hdr = null;
			return;
		}
		if (!this.hdr) {
			this.hdr = this.createHeader();
		}
		this.hdr.setText(text);
	}
}

export class CCardSectionRow extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-section__row', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

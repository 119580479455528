import {Resource, ResourceService} from './resource';

class AlternateEmail extends Resource<IEmailAddress> {
	static uri: string = 'alternate-email';
}

class EmailNotification extends Resource<IEmailNotification> {
	static uri: string = 'notifications';
}

class PaymentMethod extends Resource<IPaymentMethod> {
	static uri: string = 'payment-methods';
}

class PhoneNumber extends Resource<IPhoneNumber> {
	static uri: string = 'phone-numbers';
}

class TermsConditionsAgreement extends Resource<ITermsConditionsAgreement> {
	static uri: string = 'terms-conditions-agreements';

	async agreeTo(termsConditions: ITermsConditions): Promise<ITermsConditionsAgreement> {
		return this.request({data: termsConditions, method: 'POST'});
	}
}

export class AccountService extends ResourceService {
	static uri: string = '/api/account';

	alternateEmail: AlternateEmail;
	emailNotification: EmailNotification;
	paymentMethod: PaymentMethod;
	phoneNumber: PhoneNumber;
	termsConditionsAgreement: TermsConditionsAgreement;

	constructor() {
		super();
		this.alternateEmail = new AlternateEmail(this);
		this.emailNotification = new EmailNotification(this);
		this.paymentMethod = new PaymentMethod(this);
		this.phoneNumber = new PhoneNumber(this);
		this.termsConditionsAgreement = new TermsConditionsAgreement(this);
	}

	async get(): Promise<IUser> {
		const url = this.url();
		return (await this.GET<IUser>(url)).data;
	}
}

export const accountService: AccountService = new AccountService();

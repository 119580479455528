import React from 'react';

import {IconButton, TextField} from '../../../components';

interface IProps {
	id: number;
	label: string;
	onChange: (value: string, id: number) => any;
	onDelete: (id: number) => any;
	takeFocus?: boolean;
	value: string;
}

export default class ItemPrice extends React.Component<IProps, {}> {
	static Name: string = 'priceGroupItemPrice';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
	}

	changeEvent(event: React.ChangeEvent): void {
		const {id, onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, id);
	}

	deleteClicked(): void {
		const {id, onDelete} = this.props;
		onDelete(id);
	}

	name(): string {
		return ItemPrice.Name;
	}

	render(): React.ReactNode {
		const {id, label, takeFocus, value} = this.props;
		return (
			<div className="display--flex flex-direction--row align-items--center pb-item-price">
				<div>
					<TextField
						helpText="e.g.: 55.99"
						label={label}
						min={0}
						name={`${this.name()}__${id}`}
						onChange={this.changeEvent}
						step={0.01}
						takeFocus={takeFocus}
						type="number"
						value={value}/>
				</div>
				<div className="margin-left--16">
					<IconButton
						icon="remove_circle_outline"
						onClick={this.deleteClicked}
						onHoverClassNames={['pb-icon-button--color-danger']}
						title="Delete this price"/>
				</div>
			</div>
		);
	}
}

import React from 'react';

import {cssClassName} from '../../../../util';
import {TextField} from '../../../components';

interface IProps {
	className?: string;
	disabled?: boolean;
	helpText?: string;
	id?: string;
	label?: string;
	max?: number;
	min?: number;
	name?: string;
	noLabel?: boolean;
	onChange: (event: React.ChangeEvent) => any;
	persistHelpText?: boolean;
	step?: number;
	takeFocus?: boolean;
	type?: string;
	value: number | string;
}

export default class DenseTextInput extends React.Component<IProps, {}> {
	render(): React.ReactNode {
		const {
			className,
			disabled,
			helpText,
			id,
			label,
			max,
			min,
			name,
			noLabel,
			onChange,
			persistHelpText,
			step,
			takeFocus,
			type,
			value,
		} = this.props;
		return <TextField
			className={cssClassName('width--100-percent pb-text-field--filled-transparent pb-text-field--filled-dense', className)}
			disabled={disabled}
			helpText={helpText}
			helpTextIsPersistent={persistHelpText}
			id={id}
			label={label}
			max={max}
			min={min}
			name={name}
			noLabel={noLabel}
			onChange={onChange}
			step={step}
			takeFocus={takeFocus}
			type={type}
			value={value}/>;
	}
}

import React from 'react';

import {WTF} from '../catalog/item';
import {isNumber} from '../../util';
import {apiService as svc} from '../../services';

interface State {
	clientUserTypes: IClientUserType[];
	initializing: boolean;
	itemID: number | null,
	items: IItem[];
	priceGroups: IPriceGroup[];
	quickBooksItems: IQuickBooksItem[];
}

export default class Producer extends React.Component<{}, State> {
	constructor(props: {}) {
		super(props);
		this.state = {
			clientUserTypes: [],
			initializing: true,
			itemID: null,
			items: [],
			priceGroups: [],
			quickBooksItems: [],
		};
	}

	componentDidMount(): void {
		const detail = window.pburls.group.catalog.item.detail;
		const itemID = detail ? Number.parseInt(detail.id) : null;
		this.init(isNumber(itemID) ? itemID : null);
	}

	async init(itemID: number | null): Promise<void> {
		this.setState({
			clientUserTypes: await svc.group.client.type.list(),
			initializing: false,
			itemID: itemID,
			items: (await svc.catalog.item.list()).objects,
			priceGroups: await svc.catalog.priceGroup.list(),
			quickBooksItems: await svc.group.app.quickbooks.item.list(),
		});
	}

	render(): React.ReactNode {
		if (this.state.initializing) {
			return null;
		}
		const {
			clientUserTypes,
			itemID,
			priceGroups,
			quickBooksItems,
		} = this.state;
		return <WTF
			clientUserTypes={clientUserTypes}
			itemID={itemID}
			priceGroups={priceGroups}
			quickBooksItems={quickBooksItems}/>;
	}
}

import {CaseSensitivity} from '../constants';

export function assert(condition: any, message?: string): asserts condition {
	if (!condition) {
		throw new Error(message);
	}
}

export function bind<T extends Function>(target: any, name: string, desc: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> {
	if (!desc || (typeof desc.value !== 'function')) {
		throw new Error(`"${name}" is not a method.`);
	}
	return {
		configurable: true,
		get(this: T): T {
			const bound: T = (<T>desc.value).bind(this);
			Object.defineProperty(this, name, {
				value: bound,
				configurable: true,
				writable: true,
			});
			return bound;
		},
	};
}

export function capitalize(s: string): string {
	if (s.length > 0) {
		return `${s[0].toLocaleUpperCase()}${s.slice(1)}`;
	}
	return '';
}

export function chunk<T>(arr: Array<T>, size: number = 1): Array<Array<T>> {
	const sz: number = Math.max(size, 0);
	const length = (Array.isArray(arr) && arr)
		? arr.length :
		0;
	if ((length < 1) || (sz < 1)) {
		return [];
	}
	let idx = 0;
	let resultIdx = 0;
	const result = new Array<Array<T>>(Math.ceil(length / sz));
	while (idx < length) {
		result[resultIdx++] = arr.slice(idx, (idx += sz));
	}
	return result;
}

export function clamp(val: number, lo: number, hi: number): number {
	return Math.min(hi, Math.max(lo, val));
}

function _cssClassNameFromAny(obj: any): string[] {
	if (typeof obj === 'string') {
		return obj ? [obj] : [];
	}
	if (isPlainObject(obj)) {
		const rv: Array<string> = [];
		const keys = Object.keys(obj);
		for (let i = 0; i < keys.length; ++i) {
			const key = keys[i];
			if (key && (key in obj) && (obj[key] === true)) {
				rv.push(key);
			}
		}
		return rv;
	}
	return [];
}

export function cssClassName(...objs: any[]): string | undefined {
	const rv = Array.from(new Set(Array.from(flatten(objs.map(obj => _cssClassNameFromAny(obj)))))).join(' ').trim();
	return (rv.length > 0) ? rv : undefined;
}

export function divmod(x: number, y: number): [number, number] {
	// Return the tuple (x//y, x%y).  Invariant: div*y + mod == x
	return [Math.floor(x / y), x % y];
}

export function *enumerate<T>(objs: IterableIterator<T>, start?: number): IterableIterator<[number, T]> {
	if (!isNumber(start)) {
		start = 0;
	}
	for (const obj of objs) {
		yield [start, obj];
		++start;
	}
}

export function *flatten<T>(objs: Iterable<T> | Iterable<Iterable<T>>): IterableIterator<T> {
	for (const obj of objs) {
		if (isIterable(obj)) {
			if (typeof obj === 'string') {
				yield obj;
			} else {
				yield *flatten<T>(obj);
			}
		} else {
			yield obj;
		}
	}
}

// export function isIterable(value: any): value is Iterable<any> {
// 	if (value === null || value === undefined) {
// 		return false;
// 	}
// 	return (typeof value[Symbol.iterator]) === 'function';
// }

export function isIterable(value: any): value is Iterable<any> {
	return Boolean(value) && (typeof value[Symbol.iterator]) === 'function';
}

export function isNumber(value: any): value is number {
	return (typeof value === 'number') && !Number.isNaN(value);
}

export function isObject(value: any): value is object {
	return (value !== null) && ((typeof value === 'object') || (typeof value === 'function'));
}

export function isObjectLike(value: any): value is object {
	return (value !== null) && (typeof value === 'object');
}

function _disregardSymbolToStringTagStringTag(value: any): string {
	const wellKnown = Symbol.toStringTag;
	const hasWellKnown = Object.prototype.hasOwnProperty.call(value, wellKnown);
	const valueStringTagViaWellKnown = value[wellKnown];
	let wasUnset = false;
	try {
		value[wellKnown] = undefined;
		wasUnset = true;
	} catch (e) {
	}
	const valueStringTag = Object.prototype.toString.call(value);
	if (wasUnset) {
		if (hasWellKnown) {
			value[wellKnown] = valueStringTagViaWellKnown;
		} else {
			delete value[wellKnown];
		}
	}
	return valueStringTag;
}

function _stringTag(value: any): string {
	if (value === null) {
		return '[object Null]';
	}
	if (value === undefined) {
		return '[object Undefined]';
	}
	return Symbol.toStringTag in Object(value)
		? _disregardSymbolToStringTagStringTag(value)
		: Object.prototype.toString.call(value);
}

export function isPlainObject(value: any): boolean {
	if (!isObjectLike(value) || (_stringTag(value) !== '[object Object]')) {
		return false;
	}
	const proto = Object.getPrototypeOf(Object(value));
	if (proto === null) {
		return true;
	}
	const Ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
	const funcToString = Function.prototype.toString;
	return (typeof Ctor === 'function') && (Ctor instanceof Ctor) && (funcToString.call(Ctor) === funcToString.call(Object));
}

export function modf(n: number): [number, number] {
	const int = Math.trunc(n);
	const frac = (Math.round(n * 100) / 100) - int;
	return [frac, int];
}

export function numberFormat(num: number | string, curr?: string): string {
	let parts = String(num).split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	const rv = parts.join('.');
	return curr ? (curr + rv) : rv;
}

export function numberStringCmp(a: string, b: string): number {
	const aSplit = splitDecimal(a);
	const bSplit = splitDecimal(b);
	if (aSplit === bSplit) {
		return 0;
	}
	if (!aSplit) {
		return -1;
	}
	if (!bSplit) {
		return 1;
	}
	const [aA, aB] = aSplit;
	const [bA, bB] = bSplit;
	if (aA < bA) {
		return -1;
	}
	if (aA > bA) {
		return 1;
	}
	if (aB < bB) {
		return -1;
	}
	if (aB > bB) {
		return 1;
	}
	return 0;
}

export function overlaps(a: [number, number], b: [number, number]): boolean {
	let aStart: number;
	let aEnd: number;
	let bStart: number;
	let bEnd: number;
	if (a[0] <= a[1]) {
		[aStart, aEnd] = a;
	} else {
		[aEnd, aStart] = a;
	}
	if (b[0] <= b[1]) {
		[bStart, bEnd] = b;
	} else {
		[bEnd, bStart] = b;
	}
	if (aStart > bStart) {
		// aStart < bEnd   ||   aEnd < bEnd
		if (aStart < bEnd) {
			// aStart <= aEnd
			return true;
		}
		// aStart >= bEnd, aEnd >= bEnd
		return false;
	} else if (aStart < bStart) {
		// bStart < aEnd   ||   bEnd < aEnd
		if (bStart < aEnd) {
			// bStart <= bEnd
			return true;
		}
		// bStart >= aEnd, bEnd >= aEnd
		return false;
	} else {
		// aEnd === bEnd
		return true;
	}
}

export function lowerBound<T>(collection: Array<T>, value: T, cmp?: (a: T, b: T) => boolean): number {
	let start = 0;
	let end = collection.length;
	cmp = cmp || ((a, b) => (a < b));
	while (start < end) {
		const mid = Math.floor((start + end) / 2);
		if (cmp(collection[mid], value)) {
			start = mid + 1;
		} else {
			end = mid;
		}
	}
	return start;
}

export function numberArraySortKey(ascending: boolean = true): (a: number, b: number) => number {
	const bit = ascending ? 1 : -1;
	return function (a: number, b: number): number {
		return ((a > b) ? 1 : (a < b) ? -1 : 0) * bit;
	};
}

export function padEnd(obj: any, targetLength: number, padString: string = ' '): string {
	const s: string = (typeof obj === 'string') ? obj : String(obj);
	let pad: string = padString;
	let tgtLen: number = (targetLength >> 0);
	const strLen = s.length;
	if (strLen > tgtLen) {
		return s;
	}
	tgtLen -= strLen;
	if (tgtLen > pad.length) {
		pad += pad.repeat(tgtLen / pad.length);
	}
	return s + pad.slice(0, tgtLen);
}

export function padStart(obj: any, targetLength: number, padString: string = ' '): string {
	const s: string = (typeof obj === 'string') ? obj : String(obj);
	let tgtLen: number = (targetLength >> 0);
	let pad: string = padString;
	const strLen = s.length;
	if (strLen > tgtLen) {
		return s;
	}
	tgtLen -= strLen;
	if (tgtLen > pad.length) {
		pad += pad.repeat(tgtLen / pad.length);
	}
	return pad.slice(0, tgtLen) + s;
}

export function range(stop: number): number[];
export function range(start: number, stop: number): number[];
export function range(start: number, stop: number, step: number): number[];
export function range(...args: [number] | [number, number] | [number, number, number]): number[] {
	let start: number;
	let stop: number;
	let step: number;
	if (args.length === 1) {
		[stop] = args;
		start = 0;
		step = 1;
	} else if (args.length === 2) {
		[start, stop] = args;
		step = 1;
	} else {
		[start, stop, step] = args;
	}
	const rv: number[] = [];
	for (let i = start; i < stop; i += step) {
		rv.push(i);
	}
	return rv;
}

export function *repeat<T>(obj: T, times?: number): IterableIterator<T> {
	if (isNumber(times)) {
		for (const _ of range(times)) {
			yield obj;
		}
	} else {
		while (true) {
			yield obj;
		}
	}
}

export function splitDecimal(value: string): [number, number] | null {
	if (value) {
		const [a, b] = value.split('.', 2);
		if (a) {
			const aNum = Number.parseInt(a);
			if (isNumber(aNum)) {
				if (b) {
					const bNum = Number.parseInt(b);
					if (isNumber(bNum)) {
						return [aNum, bNum];
					}
				}
				return [aNum, 0];
			}
		}
	}
	return null;
}

export function iterableToArray<T>(it: Iterable<T>): Array<T> {
	return Array.isArray(it) ?
		it :
		Array.from(it);
}

export function stringIterableToStringArray(it: Iterable<string>): Array<string> {
	return (typeof it === 'string') ?
		[it] :
		iterableToArray(it);
}

function _lstripSlash(s: string): string {
	while (s[0] === '/') {
		s = s.slice(1);
	}
	return s;
}

function _rstripSlash(s: string): string {
	while (s[s.length - 1] === '/') {
		s = s.slice(0, s.length - 1);
	}
	return s;
}

function _stripSlash(s: string): string {
	return _rstripSlash(_lstripSlash(s));
}

export function urljoin(base: string, ...parts: string[]): string {
	if (parts.length < 1) {
		return base;
	}
	base = _rstripSlash(base);
	const last = _lstripSlash(parts[parts.length - 1]);
	const keep: string[] = parts.slice(0, parts.length - 1)
		.map(s => _stripSlash(s).trim())
		.filter(s => Boolean(s));
	return [base, ...keep, last].join('/');
}

export function trailingslashurljoin(base: string, ...parts: string[]): string {
	const rv = urljoin(base, ...parts);
	return (rv && (rv[rv.length - 1] === '/')) ? rv : `${rv}/`;
}

export function roundFloat(number: number, decimalPoints: number): number {
	const decimal = Math.pow(10, decimalPoints);
	return Math.round(number * decimal) / decimal;
}

export function setFlag(flags: number, flag: number, on: boolean = true): number {
	return on ? (flags | flag) : (flags & ~flag);
}

export function stringRepeat(str: string, count: number): string {
	let rv = '' + (str || '');
	count = +count;
	if (Number.isNaN(count)) {
		count = 0;
	}
	if (count < 0) {
		throw new Error('Repeat count must be non-negative');
	}
	if (count === Infinity) {
		throw new Error('Repeat count must be less than infinity');
	}
	count = Math.floor(count);
	if ((rv.length === 0) || (count === 0)) {
		return '';
	}
	if ((rv.length * count) >= (1 << 28)) {
		throw new Error('Repeat count must not overflow maximum string size');
	}
	const maxCount = rv.length * count;
	count = Math.floor(Math.log(count) / Math.log(2));
	while (count) {
		rv += rv;
		count--;
	}
	rv += rv.substring(0, maxCount - rv.length);
	return rv;
}

export function stringCmp(a: string, b: string, cs: CaseSensitivity = CaseSensitivity.CaseSensitive, localeAware: boolean = false): number {
	if (cs === CaseSensitivity.CaseInsensitive) {
		if (localeAware) {
			a = a.toLocaleLowerCase();
			b = b.toLocaleLowerCase();
		} else {
			a = a.toLowerCase();
			b = b.toLowerCase();
		}
	}
	if (localeAware) {
		return a.localeCompare(b);
	}
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	return 0;
}

export function testFlag(flags: number, flag: number): boolean {
	return ((flags & flag) === flag) && ((flag !== 0) || (flags === flag));
}

export function upperBound<T>(collection: Array<T>, value: T, cmp?: (a: T, b: T) => boolean): number {
	let start = 0;
	let end = collection.length;
	cmp = cmp || ((a, b) => (a <= b));
	while (start < end) {
		const mid = Math.floor((start + end + 1) / 2);
		if (cmp(collection[mid], value)) {
			start = mid;
		} else {
			end = mid - 1;
		}
	}
	return end + 1;
}

import {MDCFoundation} from '@material/base/foundation';
import {MDCComponent} from '@material/base/component';
import {MDCDialogAdapter, MDCDialogCloseEventDetail} from '@material/dialog';
import {MDCDialogFocusTrapFactory, createFocusTrapInstance, areTopsMisaligned, isScrollable} from '@material/dialog/util';
import {SpecificEventListener} from '@material/base/types';
import {FocusTrap, FocusOptions} from '@material/dom/focus-trap';
import {closest, matches} from '@material/dom/ponyfill';
import {MDCRipple} from '@material/ripple/component';

import {El, elOpts, ElOpts} from '../el';
import {ButtonRole, ButtonRoleOrder, DialogCode, StandardButton} from '../constants';
import {bind, buttonRole, buttonText} from '../util';
import {Button, ButtonClickEvt} from './button';
import {list} from '../tools';
import {Evt} from '../evt';

type Obs = (result: number) => any;

interface DialogOpts extends ElOpts {
	message: string;
	newHotness: boolean;
	standardButtons: StandardButton;
	title: string;
}

export class Dialog extends El {
	// protected static self: Dialog | null = null;

	// static instance(): Dialog {
	// 	if (!this.self) {
	// 		this.self = new this();
	// 	}
	// 	return this.self;
	// }

	// static isOpen(): boolean {
	// 	if (this.self && this.self.isOpen()) {
	// 		return true;
	// 	}
	// 	return false;
	// }

	static open(title: string, message: string, standardButtons?: StandardButton): void {
		// const instance = this.instance();
		const instance = new this();
		instance.setTitle(title);
		instance.setMessage(message);
		if (standardButtons === undefined) {
			standardButtons = StandardButton.Ok;
		}
		instance.setStandardButtons(standardButtons);
		instance.destroyOnClose = true;
		instance.open(console.log);
	}

	private ctrl: MDCDialog | null;
	private buttonMap: Map<Button, StandardButton>;
	private buttonRoleLists: list<list<Button>>;
	private container: El | null;
	private destroyOnClose: boolean;
	private destroyingOnClose: boolean;
	private footer: El | null;
	private fullScreen: boolean;
	private inDestructor: boolean;
	private newHotness: boolean;
	private obs: Obs | null;
	private surface: El | null;

	constructor(opts: Partial<DialogOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<DialogOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<DialogOpts> | null, tagName?: TagName);
	constructor(opts: Partial<DialogOpts> | null, root?: Element | null);
	constructor(opts: Partial<DialogOpts>, parent?: El | null);
	constructor(opts?: Partial<DialogOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<DialogOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<DialogOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		if (opts.newHotness) {
			classNames.push('pbr-new-hotness-dialog');
		}
		opts.classNames = ['pbr-dialog', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.inDestructor = false;
		this.fullScreen = false;
		this.newHotness = Boolean(opts.newHotness);
		// const other = (<typeof Dialog>this.constructor).self;
		// if (other) {
		// 	other.destroy();
		// }
		// (<typeof Dialog>this.constructor).self = this;
		this.buttonMap = new Map<Button, StandardButton>();
		this.buttonRoleLists = new list<list<Button>>();
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			this.buttonRoleLists.insert(i, new list<Button>());
		}
		this.ctrl = null;
		this.destroyOnClose = false;
		this.destroyingOnClose = false;
		this.obs = null;
		this.container = El.div({
			classNames: 'pbr-dialog__container',
			parent: this,
		});
		this.surface = El.div({
			classNames: 'pbr-dialog__surface',
			parent: this.container,
		});
		El.div({
			classNames: 'pbr-dialog__content',
			parent: this.surface,
		});
		this.footer = null;
		El.div({
			classNames: 'pbr-dialog__scrim',
			parent: this,
		});
		if (opts.title) {
			this.setTitle(opts.title);
		}
		if (opts.message) {
			this.setMessage(opts.message);
		}
		if (opts.standardButtons !== undefined) {
			this.setStandardButtons(opts.standardButtons);
		}
	}

	accept(): void {
		this.done(DialogCode.Accepted);
	}

	addButton(button: Button, role: ButtonRole, doLayout: boolean = true): void {
		button.onEvt(this.buttonEvt);
		this.buttonRoleLists.at(role).append(button);
		if (doLayout) {
			this.layoutButtons();
		}
	}

	protected addButtonsToLayout(buttonList: list<Button>, reverse: boolean): void {
		if (!this.footer) {
			return;
		}
		const start = reverse ? (buttonList.size() - 1) : 0;
		const end = reverse ? -1 : buttonList.size();
		const step = reverse ? -1 : 1;
		for (let i = start; i !== end; i += step) {
			const button = buttonList.at(i);
			this.footer.appendChild(button);
			button.show();
		}
	}

	appendContent(obj?: El | Element | number | string | null): void {
		const el = this.contentEl();
		if (!el) {
			return;
		}
		if (obj === undefined || obj === null) {
			return;
		}
		if ((obj instanceof El) || (obj instanceof Element)) {
			el.appendChild(obj);
		} else {
			el.setText(`${el.text()} ${obj}`);
		}
	}

	button(sbutton: StandardButton): Button | null {
		for (const [btn, sbtn] of this.buttonMap) {
			if (sbtn === sbutton) {
				return btn;
			}
		}
		return null;
	}

	@bind
	protected buttonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof ButtonClickEvt)) {
			this.buttonClicked(evt.obj());
		}
	}

	protected buttonClicked(button: Button): void {
		this.finalize(this.returnCode(button));
	}

	buttonRole(button: Button): ButtonRole {
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const buttonList = this.buttonRoleLists.at(i);
			for (let k = 0; k < buttonList.size(); ++k) {
				if (buttonList.at(k) === button) {
					return i;
				}
			}
		}
		return ButtonRole.InvalidRole;
	}

	close(): void {
		if (!this.isOpen()) {
			return;
		}
		if (this.ctrl) {
			this.ctrl.close();
		}
		document.removeEventListener('keydown', this.keyDownEvent, true);
		window.removeEventListener('click', this.mouseClickEvent, true);
		if (this.destroyOnClose && !this.destroyingOnClose) {
			this.destroyingOnClose = true;
			this.destroy();
		}
	}

	protected contentEl(): El | null {
		return this.querySelector('.pbr-dialog__content');
	}

	createButton(sbutton: StandardButton, doLayout: boolean = true): Button {
		const button = new Button({text: buttonText(sbutton)});
		button.addClass('pbr-dialog__button');
		this.buttonMap.set(button, sbutton);
		const role = buttonRole(sbutton);
		if (role === ButtonRole.InvalidRole) {
			console.log('DialogButtonBox::createButton: Invalid ButtonRole, button not added.');
		} else {
			this.addButton(button, role, doLayout);
		}
		return button;
	}

	destroy(): void {
		if (this.inDestructor) {
			return;
		}
		this.inDestructor = true;
		window.removeEventListener('click', this.mouseClickEvent, true);
		document.removeEventListener('keydown', this.keyDownEvent, true);
		if (this.isOpen() && !this.destroyingOnClose) {
			try {
				this.reject();
			} catch {
			}
		}
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
		this.obs = null;
		if (this.footer) {
			this.footer.destroy();
		}
		this.footer = null;
		// (<typeof Dialog>this.constructor).self = null;
		this.destroyingOnClose = false;
		super.destroy();
	}

	protected dialogCodeForButton(button: Button): number {
		const sbtn = this.buttonMap.get(button);
		switch (buttonRole((sbtn === undefined) ? StandardButton.NoButton : sbtn)) {
			case ButtonRole.AcceptRole:
			case ButtonRole.YesRole:
				return DialogCode.Accepted;
			case ButtonRole.RejectRole:
			case ButtonRole.NoRole:
				return DialogCode.Rejected;
			default:
				return -1;
		}
	}

	done(result: number): void {
		this.finalize(result);
	}

	finalize(resultCode: number): void {
		this.finished(resultCode);
		this.close();
	}

	finished(result: number): void {
		if (this.obs) {
			this.obs(result);
		}
	}

	hideFooter(): void {
		this.setFooterVisible(false);
	}

	isOpen(): boolean {
		if (this.ctrl) {
			return this.ctrl.isOpen;
		}
		return false;
	}

	@bind
	protected keyDownEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		const isEscape = (event.key === 'Escape') || (event.keyCode === 27);
		if (isEscape && !this.tryRejectButton()) {
			this.reject();
		}
	}

	protected layoutButtons(): void {
		if (!this.footer) {
			this.footer = El.div({
				classNames: 'pbr-dialog__actions',
				parent: this.surface,
			});
		}
		const acceptRoleList = this.buttonRoleLists.at(ButtonRole.AcceptRole);
		let currentLayout: number = 0;
		const EOL = ButtonRoleOrder.length;
		while (currentLayout !== EOL) {
			const role = ButtonRoleOrder[currentLayout];
			switch (role) {
				case ButtonRole.AcceptRole:
					if (acceptRoleList.isEmpty()) {
						break;
					}
					// Only the first one
					const button = acceptRoleList.first();
					this.footer.appendChild(button);
					break;
				case ButtonRole.RejectRole:
				case ButtonRole.NoRole:
				case ButtonRole.DestructiveRole:
				case ButtonRole.ResetRole:
				case ButtonRole.HelpRole:
				case ButtonRole.ActionRole:
				case ButtonRole.ApplyRole:
				case ButtonRole.YesRole:
					this.addButtonsToLayout(this.buttonRoleLists.at(role), false);
					break;
			}
			++currentLayout;
		}
	}

	@bind
	protected mouseClickEvent(event: MouseEvent): void {
		const el = El.fromEvent(event);
		if (el.matchesSelector('.pbr-dialog__scrim')) {
			event.stopImmediatePropagation();
			if (!this.tryRejectButton()) {
				this.reject();
			}
		}
	}

	open(cb?: Obs): void {
		if (this.isOpen()) {
			return;
		}
		if (cb) {
			this.obs = cb;
		}
		if (!this.ctrl) {
			this.appendToBody();
			this.ctrl = new MDCDialog(this.elem);
		}
		this.ctrl.open();
		window.addEventListener('click', this.mouseClickEvent, true);
		document.addEventListener('keydown', this.keyDownEvent, true);
	}

	reject(): void {
		this.done(DialogCode.Rejected);
	}

	removeButton(button: Button): void {
		this.buttonMap.delete(button);
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const buttonList = this.buttonRoleLists.at(i);
			for (let k = 0; k < buttonList.size(); ++k) {
				if (buttonList.at(k) === button) {
					buttonList.removeAt(k);
					button.offEvt(this.buttonEvt);
					break;
				}
			}
		}
		button.setParent(null);
	}

	protected returnCode(button: Button): number {
		const sbtn = this.buttonMap.get(button);
		return (sbtn === undefined) ? StandardButton.NoButton : sbtn;
	}

	setContent(obj?: El | Element | number | string | null): void {
		const el = this.contentEl();
		if (!el) {
			return;
		}
		el.clear();
		if (obj === undefined || obj === null) {
			return;
		}
		if ((obj instanceof El) || (obj instanceof Element)) {
			el.appendChild(obj);
		} else {
			el.setText(String(obj));
		}
	}

	setFooterVisible(visible: boolean): void {
		if (this.footer) {
			this.footer.setVisible(visible);
		}
	}

	setFullScreen(enable: boolean): void {
		if ((enable === this.fullScreen) || !this.newHotness) {
			return;
		}
		this.fullScreen = enable;
		this.setClass(this.fullScreen, 'pbr-new-hotness-dialog--full-screen');
	}

	setMaximized(maximized: boolean): void {
		this.setClass(maximized, 'pbr-dialog--maximized');
	}

	setMessage(text: string): void {
		const el = this.contentEl();
		if (el) {
			el.setText(text);
		}
	}

	setStandardButtons(buttons: StandardButton): void {
		if (!this.footer) {
			this.footer = El.div({
				classNames: 'pbr-dialog__actions',
				parent: this.surface,
			});
		}
		for (const btn of this.buttonMap.keys()) {
			this.removeButton(btn);
			btn.destroy();
		}
		this.buttonMap.clear();
		this.footer.clear();
		let i = StandardButton.FirstButton;
		while (i <= StandardButton.LastButton) {
			if (i & buttons) {
				this.createButton(i, false);
			}
			i = i << 1;
		}
		this.layoutButtons();
	}

	setTitle(title: string): void {
		title = title.trim();
		let el = this.titleEl();
		if (!title) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			el = new El({classNames: 'pbr-dialog__title'}, 'h2');
			const surface = this.surfaceEl();
			if (surface) {
				surface.insertAdjacentElement('afterbegin', el);
			} else {
				el.destroy();
				return;
			}
		}
		el.setText(title);
	}

	showFooter(): void {
		this.setFooterVisible(true);
	}

	protected surfaceEl(): El | null {
		return this.querySelector('.pbr-dialog__surface');
	}

	protected titleEl(): El | null {
		return this.querySelector('.pbr-dialog__title');
	}

	protected tryRejectButton(): boolean {
		const rejectBtns = this.buttonRoleLists.at(ButtonRole.RejectRole);
		if (rejectBtns.size() === 1) {
			rejectBtns.first().click();
			return true;
		}
		return false;
	}
}

const cssClasses = {
	CLOSING: 'pbr-dialog--closing',
	OPEN: 'pbr-dialog--open',
	OPENING: 'pbr-dialog--opening',
	SCROLLABLE: 'pbr-dialog--scrollable',
	SCROLL_LOCK: 'pbr-dialog-scroll-lock',
	STACKED: 'pbr-dialog--stacked',
};

const strings = {
	ACTION_ATTRIBUTE: 'data-pbr-dialog-action',
	BUTTON_DEFAULT_ATTRIBUTE: 'data-pbr-dialog-button-default',
	BUTTON_SELECTOR: '.pbr-dialog__button',
	CLOSED_EVENT: 'MDCDialog:closed',
	CLOSE_ACTION: 'close',
	CLOSING_EVENT: 'MDCDialog:closing',
	CONTAINER_SELECTOR: '.pbr-dialog__container',
	CONTENT_SELECTOR: '.pbr-dialog__content',
	DESTROY_ACTION: 'destroy',
	INITIAL_FOCUS_ATTRIBUTE: 'data-pbr-dialog-initial-focus',
	OPENED_EVENT: 'MDCDialog:opened',
	OPENING_EVENT: 'MDCDialog:opening',
	SCRIM_SELECTOR: '.pbr-dialog__scrim',
	SUPPRESS_DEFAULT_PRESS_SELECTOR: [
		'textarea',
		'.mdc-menu .mdc-list-item',
	].join(', '),
	SURFACE_SELECTOR: '.pbr-dialog__surface',
};

const numbers = {
	DIALOG_ANIMATION_CLOSE_TIME_MS: 75,
	DIALOG_ANIMATION_OPEN_TIME_MS: 150,
};

class MDCDialogFoundation extends MDCFoundation<MDCDialogAdapter> {
	static get cssClasses() {
		return cssClasses;
	}

	static get strings() {
		return strings;
	}

	static get numbers() {
		return numbers;
	}

	static get defaultAdapter(): MDCDialogAdapter {
		return {
			addBodyClass: () => undefined,
			addClass: () => undefined,
			areButtonsStacked: () => false,
			clickDefaultButton: () => undefined,
			eventTargetMatches: () => false,
			getActionFromEvent: () => '',
			getInitialFocusEl: () => null,
			hasClass: () => false,
			isContentScrollable: () => false,
			notifyClosed: () => undefined,
			notifyClosing: () => undefined,
			notifyOpened: () => undefined,
			notifyOpening: () => undefined,
			releaseFocus: () => undefined,
			removeBodyClass: () => undefined,
			removeClass: () => undefined,
			reverseButtons: () => undefined,
			trapFocus: () => undefined,
		};
	}

	private isOpen_ = false;
	private animationFrame_ = 0;
	private animationTimer_ = 0;
	private layoutFrame_ = 0;
	private escapeKeyAction_ = strings.CLOSE_ACTION;
	private scrimClickAction_ = strings.CLOSE_ACTION;
	private autoStackButtons_ = true;
	private areButtonsStacked_ = false;
	private suppressDefaultPressSelector = strings.SUPPRESS_DEFAULT_PRESS_SELECTOR;

	constructor(adapter?: Partial<MDCDialogAdapter>) {
		super({...MDCDialogFoundation.defaultAdapter, ...adapter});
	}

	init() {
		if (this.adapter.hasClass(cssClasses.STACKED)) {
			this.setAutoStackButtons(false);
		}
	}

	destroy() {
		if (this.isOpen_) {
			this.close(strings.DESTROY_ACTION);
		}

		if (this.animationTimer_) {
			clearTimeout(this.animationTimer_);
			this.handleAnimationTimerEnd_();
		}

		if (this.layoutFrame_) {
			cancelAnimationFrame(this.layoutFrame_);
			this.layoutFrame_ = 0;
		}
	}

	open() {
		this.isOpen_ = true;
		this.adapter.notifyOpening();
		this.adapter.addClass(cssClasses.OPENING);

		// Wait a frame once display is no longer "none", to establish basis for animation
		this.runNextAnimationFrame_(() => {
			this.adapter.addClass(cssClasses.OPEN);
			this.adapter.addBodyClass(cssClasses.SCROLL_LOCK);

			this.layout();

			this.animationTimer_ = setTimeout(() => {
				this.handleAnimationTimerEnd_();
				this.adapter.trapFocus(this.adapter.getInitialFocusEl());
				this.adapter.notifyOpened();
			}, numbers.DIALOG_ANIMATION_OPEN_TIME_MS);
		});
	}

	close(action = '') {
		if (!this.isOpen_) {
			// Avoid redundant close calls (and events), e.g. from keydown on elements that inherently emit click
			return;
		}

		this.isOpen_ = false;
		this.adapter.notifyClosing(action);
		this.adapter.addClass(cssClasses.CLOSING);
		this.adapter.removeClass(cssClasses.OPEN);
		this.adapter.removeBodyClass(cssClasses.SCROLL_LOCK);

		cancelAnimationFrame(this.animationFrame_);
		this.animationFrame_ = 0;

		clearTimeout(this.animationTimer_);
		this.animationTimer_ = setTimeout(() => {
			this.adapter.releaseFocus();
			this.handleAnimationTimerEnd_();
			this.adapter.notifyClosed(action);
		}, numbers.DIALOG_ANIMATION_CLOSE_TIME_MS);
	}

	isOpen() {
		return this.isOpen_;
	}

	getEscapeKeyAction(): string {
		return this.escapeKeyAction_;
	}

	setEscapeKeyAction(action: string) {
		this.escapeKeyAction_ = action;
	}

	getScrimClickAction(): string {
		return this.scrimClickAction_;
	}

	setScrimClickAction(action: string) {
		this.scrimClickAction_ = action;
	}

	getAutoStackButtons(): boolean {
		return this.autoStackButtons_;
	}

	setAutoStackButtons(autoStack: boolean) {
		this.autoStackButtons_ = autoStack;
	}

	getSuppressDefaultPressSelector(): string {
		return this.suppressDefaultPressSelector;
	}

	setSuppressDefaultPressSelector(selector: string) {
		this.suppressDefaultPressSelector = selector;
	}

	layout() {
		if (this.layoutFrame_) {
			cancelAnimationFrame(this.layoutFrame_);
		}
		this.layoutFrame_ = requestAnimationFrame(() => {
			this.layoutInternal_();
			this.layoutFrame_ = 0;
		});
	}

	/** Handles click on the dialog root element. */
	handleClick(evt: MouseEvent) {
		const isScrim =
			this.adapter.eventTargetMatches(evt.target, strings.SCRIM_SELECTOR);
		// Check for scrim click first since it doesn't require querying ancestors.
		if (isScrim && this.scrimClickAction_ !== '') {
			this.close(this.scrimClickAction_);
		} else {
			const action = this.adapter.getActionFromEvent(evt);
			if (action) {
				this.close(action);
			}
		}
	}

	/** Handles keydown on the dialog root element. */
	handleKeydown(evt: KeyboardEvent) {
		const isEnter = evt.key === 'Enter' || evt.keyCode === 13;
		if (!isEnter) {
			return;
		}
		const action = this.adapter.getActionFromEvent(evt);
		if (action) {
			// Action button callback is handled in `handleClick`,
			// since space/enter keydowns on buttons trigger click events.
			return;
		}

		// `composedPath` is used here, when available, to account for use cases
		// where a target meant to suppress the default press behaviour
		// may exist in a shadow root.
		// For example, a textarea inside a web component:
		// <mwc-dialog>
		//   <horizontal-layout>
		//     #shadow-root (open)
		//       <mwc-textarea>
		//         #shadow-root (open)
		//           <textarea></textarea>
		//       </mwc-textarea>
		//   </horizontal-layout>
		// </mwc-dialog>
		const target = evt.composedPath ? evt.composedPath()[0] : evt.target;
		const isDefault = !this.adapter.eventTargetMatches(
			target, this.suppressDefaultPressSelector);
		if (isEnter && isDefault) {
			this.adapter.clickDefaultButton();
		}
	}

	/** Handles keydown on the document. */
	handleDocumentKeydown(evt: KeyboardEvent) {
		const isEscape = evt.key === 'Escape' || evt.keyCode === 27;
		if (isEscape && this.escapeKeyAction_ !== '') {
			this.close(this.escapeKeyAction_);
		}
	}

	private layoutInternal_() {
		if (this.autoStackButtons_) {
			this.detectStackedButtons_();
		}
		this.detectScrollableContent_();
	}

	private handleAnimationTimerEnd_() {
		this.animationTimer_ = 0;
		this.adapter.removeClass(cssClasses.OPENING);
		this.adapter.removeClass(cssClasses.CLOSING);
	}

	/**
	 * Runs the given logic on the next animation frame, using setTimeout to factor in Firefox reflow behavior.
	 */
	private runNextAnimationFrame_(callback: () => void) {
		cancelAnimationFrame(this.animationFrame_);
		this.animationFrame_ = requestAnimationFrame(() => {
			this.animationFrame_ = 0;
			clearTimeout(this.animationTimer_);
			this.animationTimer_ = setTimeout(callback, 0);
		});
	}

	private detectStackedButtons_() {
		// Remove the class first to let us measure the buttons' natural positions.
		this.adapter.removeClass(cssClasses.STACKED);

		const areButtonsStacked = this.adapter.areButtonsStacked();

		if (areButtonsStacked) {
			this.adapter.addClass(cssClasses.STACKED);
		}

		if (areButtonsStacked !== this.areButtonsStacked_) {
			this.adapter.reverseButtons();
			this.areButtonsStacked_ = areButtonsStacked;
		}
	}

	private detectScrollableContent_() {
		// Remove the class first to let us measure the natural height of the content.
		this.adapter.removeClass(cssClasses.SCROLLABLE);
		if (this.adapter.isContentScrollable()) {
			this.adapter.addClass(cssClasses.SCROLLABLE);
		}
	}
}

export class MDCDialog extends MDCComponent<MDCDialogFoundation> {
	get isOpen() {
		return this.foundation.isOpen();
	}

	get escapeKeyAction() {
		return this.foundation.getEscapeKeyAction();
	}

	set escapeKeyAction(action) {
		this.foundation.setEscapeKeyAction(action);
	}

	get scrimClickAction() {
		return this.foundation.getScrimClickAction();
	}

	set scrimClickAction(action) {
		this.foundation.setScrimClickAction(action);
	}

	get autoStackButtons() {
		return this.foundation.getAutoStackButtons();
	}

	set autoStackButtons(autoStack) {
		this.foundation.setAutoStackButtons(autoStack);
	}

	static attachTo(root: Element) {
		return new MDCDialog(root);
	}

	private buttonRipples_!: MDCRipple[];
	private buttons_!: HTMLElement[];
	private container_!: HTMLElement;
	private content_!: HTMLElement | null;
	private defaultButton_!: HTMLElement | null;
	private focusTrap_!: FocusTrap;
	private focusTrapFactory_!: MDCDialogFocusTrapFactory;
	private handleClick_!: SpecificEventListener<'click'>;
	private handleClosing_!: () => void;
	private handleDocumentKeydown_!: SpecificEventListener<'keydown'>;
	private handleKeydown_!: SpecificEventListener<'keydown'>;
	private handleLayout_!: EventListener;
	private handleOpening_!: EventListener;

	initialize() {
		const container = this.root.querySelector<HTMLElement>(strings.CONTAINER_SELECTOR);
		if (!container) {
			throw new Error(`Dialog component requires a ${strings.CONTAINER_SELECTOR} container element`);
		}
		this.container_ = container;
		this.content_ = this.root.querySelector<HTMLElement>(strings.CONTENT_SELECTOR);
		this.buttons_ = Array.from(this.root.querySelectorAll<HTMLElement>(strings.BUTTON_SELECTOR));
		this.defaultButton_ = this.root.querySelector<HTMLElement>(`[${strings.BUTTON_DEFAULT_ATTRIBUTE}]`);
		this.focusTrapFactory_ = focusTrapFactoryBs;
		this.buttonRipples_ = [];
		for (const buttonEl of this.buttons_) {
			this.buttonRipples_.push(new MDCRipple(buttonEl));
		}
	}

	initialSyncWithDOM() {
		this.focusTrap_ = createFocusTrapInstance(
			this.container_,
			this.focusTrapFactory_,
			this.getInitialFocusEl_() || undefined);
		this.handleClick_ = this.foundation.handleClick.bind(this.foundation);
		this.handleKeydown_ = this.foundation.handleKeydown.bind(this.foundation);
		this.handleDocumentKeydown_ = this.foundation.handleDocumentKeydown.bind(this.foundation);
		this.handleLayout_ = this.layout.bind(this);
		const LAYOUT_EVENTS = ['resize', 'orientationchange'];
		this.handleOpening_ = () => {
			LAYOUT_EVENTS.forEach((evtType) => window.addEventListener(evtType, this.handleLayout_));
			document.addEventListener('keydown', this.handleDocumentKeydown_);
		};
		this.handleClosing_ = () => {
			LAYOUT_EVENTS.forEach((evtType) => window.removeEventListener(evtType, this.handleLayout_));
			document.removeEventListener('keydown', this.handleDocumentKeydown_);
		};
		this.listen('click', this.handleClick_);
		this.listen('keydown', this.handleKeydown_);
		this.listen(strings.OPENING_EVENT, this.handleOpening_);
		this.listen(strings.CLOSING_EVENT, this.handleClosing_);
	}

	destroy() {
		this.unlisten('click', this.handleClick_);
		this.unlisten('keydown', this.handleKeydown_);
		this.unlisten(strings.OPENING_EVENT, this.handleOpening_);
		this.unlisten(strings.CLOSING_EVENT, this.handleClosing_);
		this.handleClosing_();
		this.buttonRipples_.forEach((ripple) => ripple.destroy());
		super.destroy();
	}

	layout() {
		this.foundation.layout();
	}

	open() {
		this.foundation.open();
	}

	close(action = '') {
		this.foundation.close(action);
	}

	getDefaultFoundation() {
		// DO NOT INLINE this variable. For backward compatibility, foundations take a Partial<MDCFooAdapter>.
		// To ensure we don't accidentally omit any methods, we need a separate, strongly typed adapter variable.
		const adapter: MDCDialogAdapter = {
			addBodyClass: (className) => document.body.classList.add(className),
			addClass: (className) => this.root.classList.add(className),
			areButtonsStacked: () => areTopsMisaligned(this.buttons_),
			clickDefaultButton: () => this.defaultButton_ && this.defaultButton_.click(),
			eventTargetMatches: (target, selector) => target ? matches(target as Element, selector) : false,
			getActionFromEvent: (evt: Event) => {
				if (!evt.target) {
					return '';
				}
				const element = closest(evt.target as Element, `[${strings.ACTION_ATTRIBUTE}]`);
				return element && element.getAttribute(strings.ACTION_ATTRIBUTE);
			},
			getInitialFocusEl: () => this.getInitialFocusEl_(),
			hasClass: (className) => this.root.classList.contains(className),
			isContentScrollable: () => isScrollable(this.content_),
			notifyClosed: (action) => this.emit<MDCDialogCloseEventDetail>(strings.CLOSED_EVENT, action ? {action} : {}),
			notifyClosing: (action) => this.emit<MDCDialogCloseEventDetail>(strings.CLOSING_EVENT, action ? {action} : {}),
			notifyOpened: () => this.emit(strings.OPENED_EVENT, {}),
			notifyOpening: () => this.emit(strings.OPENING_EVENT, {}),
			releaseFocus: () => this.focusTrap_.releaseFocus(),
			removeBodyClass: (className) => document.body.classList.remove(className),
			removeClass: (className) => this.root.classList.remove(className),
			reverseButtons: () => {
				this.buttons_.reverse();
				this.buttons_.forEach((button) => button.parentElement!.appendChild(button));
			},
			trapFocus: () => this.focusTrap_.trapFocus(),
		};
		return new MDCDialogFoundation(adapter);
	}

	private getInitialFocusEl_(): HTMLElement | null {
		return this.root.querySelector(`[${strings.INITIAL_FOCUS_ATTRIBUTE}]`);
	}
}

function focusTrapFactoryBs(elem: HTMLElement, opts: FocusOptions): FocusTrap {
	opts.skipInitialFocus = true;
	return new FocusTrap(elem, opts);
}

import React, {PropsWithChildren} from 'react';
import {cssClassName} from '../../util';

interface IProps {
	align?: 'start' | 'center' | 'end';
	className?: string;
}

export default function Subheading({align, children, className}: PropsWithChildren<IProps>) {
	align = align ? align : 'center';
	const cc = cssClassName({
		'justify-content--center': align === 'center',
		'justify-content--flex-start': align === 'start',
		'justify-content--flex-end': align === 'end',
		'display--flex': true,
		'color--grayish': true,
		[typeof className === 'string' ? className : '']: !!className,
	})
	return (
		<div className={cc}>
			{children}
		</div>
	);
}

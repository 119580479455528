import {list} from './list';

export class multihash<K, V> {
	data: Map<K, Array<V>>;

	constructor(other?: multihash<K, V>);
	constructor(iterable?: Iterable<[K, V]>);
	constructor(a?: multihash<K, V> | Iterable<[K, V]>) {
		this.data = new Map<K, Array<V>>();
		if (a) {
			if (a instanceof multihash) {
				for (const [k, v] of a.data) {
					this.data.set(k, [...v]);
				}
			} else {
				for (const [k, v] of a) {
					this.insert(k, v);
				}
			}
		}
	}

	add(other: multihash<K, V>): multihash<K, V> {
		// Returns a hash that contains all the items in this hash in addition
		// to all the items in other. If a key is common to both hashes, the
		// resulting hash will contain the key multiple times.
		const rv = new multihash<K, V>(this);
		for (const [k, v] of other.data) {
			for (let i = 0; i < v.length; ++i) {
				rv.insert(k, v[i]);
			}
		}
		return rv;
	}

	begin(): KeyValueIterator<K, list<V>> {
		const it = new KeyValueIterator<K, list<V>>(this);
		it.begin()
		return it;
	}

	clear(): void {
		this.data.clear();
		this.data = new Map<K, Array<V>>();
	}

	contains(key: K, value: V): boolean;
	contains(key: K): boolean;
	contains(key: K, value?: V): boolean {
		if (this.data.has(key)) {
			if (value === undefined) {
				return true;
			}
			for (const v of this._reversedValuesIterator(<Array<V>>this.data.get(key))) {
				if (v === value) {
					return true;
				}
			}
		}
		return false;
	}

	count(key: K, value: V): number;
	count(key?: K): number;
	count(key?: K, value?: V): number {
		// If no arguments, this function behaves same as `size()`.
		//
		// If key is given as argument, returns the number of items associated
		// with the key.
		//
		// If key AND value given as arguments, returns the number of items
		// with the key and value.
		if (key === undefined) {
			return this.size();
		}
		const v = this.data.get(key);
		if (!v) {
			return 0;
		}
		if (value === undefined) {
			return v.length;
		}
		let n = 0;
		for (let i = 0; i < v.length; ++i) {
			if (v[i] === value) {
				++n;
			}
		}
		return n;
	}

	destroy(): void {
		this.clear();
	}

	eq(other: multihash<K, V>): boolean {
		if (this === other) {
			return true;
		}
		if (this.data === other.data) {
			return true;
		}
		if (this.size() !== other.size()) {
			return false;
		}
		if (this.keys().eq(other.keys())) {
			return this.values().eq(other.values());
		}
		return false;
	}

	find(key: K, value: V): IterableIterator<V>;
	find(key: K): IterableIterator<V>;
	*find(key: K, value?: V): IterableIterator<V> {
		// If key is only argument, returns an iterator pointing to the item
		// with the key in the hash.
		//
		// If key AND value given, returns an iterator pointing to the item
		// with the key and value.
		if (value === undefined) {
			yield *this._reversedValuesIterator(this.data.get(key) || []);
		} else {
			yield *this.findKeyAndValue(key, value);
		}
	}

	protected *findKeyAndValue(key: K, value: V): IterableIterator<V> {
		for (const v of this._reversedValuesIterator(this.data.get(key) || [])) {
			if (v === value) {
				yield v;
			}
		}
	}

	iadd(other: multihash<K, V>): this {
		// Inserts all the items in the other hash into this hash and returns
		// a reference to this hash.
		for (const [k, v] of other.data) {
			for (let i = 0; i < v.length; ++i) {
				this.insert(k, v[i]);
			}
		}
		return this;
	}

	insert(key: K, value: V): void {
		// Inserts a new item with the key and a value of value.
		//
		// If there is already an item with the same key in the hash, this
		// function will simply create a new one. (This behavior is different
		// from replace(), which overwrites the value of an existing item.)
		let v: Array<V> | undefined = this.data.get(key);
		if (v === undefined) {
			v = [];
			this.data.set(key, v);
		}
		v.push(value);
	}

	insertEnd(key: K, value: V): void {
		// Inserts a new item with the key and a value of value.
		//
		// If there is already an item with the same key in the hash, this
		// function will simply create a new one. (This behavior is different
		// from replace(), which overwrites the value of an existing item.).
		//
		// The placement of value will be "behind" any currently existing
		// values mapped by the same key. That is, during access and
		// iteration, this value will be emitted behind those occurring at the
		// time of insertion. This differs from normal behavior of `insert`
		// where the last inserted value is the first emitted during access.
		let v: Array<V> | undefined = this.data.get(key);
		if (v === undefined) {
			v = [];
			this.data.set(key, v);
		}
		v.unshift(value);
	}

	isEmpty(): boolean {
		return this.size() < 1;
	}

	key(value: V, defaultKey: K): K {
		// Returns the first key mapped to value, or defaultKey if the hash
		// contains no item mapped to value.
		//
		// This function can be slow (linear time), because the internal data
		// structure is optimized for fast lookup by key, not by value.
		for (const [k, v] of this.data) {
			for (let i = 0; i < v.length; ++i) {
				if (v[i] === value) {
					return k;
				}
			}
		}
		return defaultKey;
	}

	keys(): list<K> {
		// Returns a list containing all the keys in the hash, in an arbitrary
		// order. Keys that occur multiple times in the hash also occur
		// multiple times in the list.
		const arr: Array<K> = [];
		for (const [k, v] of this.data) {
			arr.push(k);
			for (let i = 0; i < v.length; ++i) {
				arr.push(k);
			}
		}
		return new list<K>(arr);
	}

	ne(other: multihash<K, V>): boolean {
		return !this.eq(other);
	}

	remove(key: K, value: V): number;
	remove(key: K): number;
	remove(key: K, value?: V): number {
		// Removes all the items that have the key (or key AND value if value
		// is given) from the hash. Returns the number of items removed.
		let n: number = 0;
		const v = this.data.get(key);
		if (v !== undefined) {
			if (value === undefined) {
				n = v.length;
			} else {
				let i = v.indexOf(value);
				while (i >= 0) {
					v.splice(i, 1);
					++n;
					i = v.indexOf(value);
				}
			}
			if (v.length < 1) {
				this.data.delete(key);
			}
		}
		return n;
	}

	replace(key: K, value: V): void {
		// Inserts a new item with the key and a value of value.
		//
		// If there is already an item with the key, that item's value is
		// replaced with value.
		//
		// If there are multiple items with the key, the most recently
		// inserted item's value is replaced with value.
		const v = this.data.get(key);
		if (v && v.length > 0) {
			v[v.length - 1] = value;
		} else {
			this.insert(key, value);
		}
	}

	size(): number {
		// Returns the number of items in the hash.
		let n = 0;
		for (const v of this.data.values()) {
			n += v.length;
		}
		return n;
	}

	take(key: K, defaultConstructor: () => V): V {
		// Removes the item with the key from the hash and returns the value
		// associated with it.
		//
		// If the item does not exist in the hash, the function returns a
		// default-constructed value. If there are multiple items for key in
		// the hash, only the most recently inserted one is removed.
		const v = this.data.get(key);
		const rv = (v && (v.length > 0)) ?
			v[v.length - 1] :
			defaultConstructor();
		this.data.delete(key);
		return rv;
	}

	uniqueKeys(): list<K> {
		// Returns a list containing all the keys in the map. Keys that occur
		// multiple times in the map occur only once in the returned list.
		return new list<K>(this.data.keys());
	}

	value(key: K, defaultConstructor: () => V): V {
		// Returns the value associated with the key.
		//
		// If the hash contains no item with the key, the function returns a
		// default-constructed value. If there are multiple items for the key
		// in the hash, the value of the most recently inserted one is
		// returned.
		const v = this.data.get(key);
		if ((v === undefined) || (v.length < 1)) {
			return defaultConstructor();
		}
		return v[v.length - 1];
	}

	values(key: K): list<V>;
	values(): list<V>
	values(key?: K): list<V> {
		// If no key argument is given, return a list containing all the
		// values in the hash, in an arbitrary order. If a key is associated
		// with multiple values, all of its values will be in the list, and
		// not just the most recently inserted one.
		//
		// If key argument is given, return a list of all the values
		// associated with the key, from the most recently inserted to the
		// least recently inserted.
		const arr: Array<V> = [];
		if (key === undefined) {
			for (const v of this.data.values()) {
				arr.push(...this._reversedValuesIterator(v));
			}
		} else {
			const v = this.data.get(key);
			if (v !== undefined) {
				arr.push(...this._reversedValuesIterator(v));
			}
		}
		return this._valuesList(arr);
	}

	get [Symbol.toStringTag](): string {
		return 'multihash';
	}

	*[Symbol.iterator](): IterableIterator<[K, list<V>]> {
		for (const [k, v] of this.data) {
			yield [k, this._valuesList(this._reversedValuesIterator(v))];
		}
	}

	private *_reversedValuesIterator(values: Array<V>): IterableIterator<V> {
		for (let i = values.length - 1; i >= 0; --i) {
			yield values[i];
		}
	}

	private _valuesList(values: Iterable<V>): list<V> {
		return new list<V>(values);
	}
}

class KeyValueIterator<K, V> {
	private container: Iterable<[K, V]>;
	private curr: IteratorResult<[K, V]>;
	private it: Iterator<[K, V]> | null;

	constructor(it: Iterable<[K, V]>) {
		this.container = it;
		this.curr = {done: true, value: undefined};
		this.it = null;
	}

	advance(): void {
		if (this.it) {
			this.curr = this.it.next();
		}
	}

	begin(): void {
		this.it = this.container[Symbol.iterator]();
		this.advance();
	}

	done(): boolean {
		return this.curr.done || true;
	}

	hasNext(): boolean {
		return !this.done();
	}

	key(): K {
		if (!this.curr.done) {
			return this.curr.value[0];
		}
		throw new Error('Iterator exhausted');
	}

	value(): V {
		if (!this.curr.done) {
			return this.curr.value[1];
		}
		throw new Error('Iterator exhausted');
	}
}

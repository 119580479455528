import {RequestService} from './request';
import {isNumber, trailingslashurljoin} from '../util';

export class ResourceService extends RequestService {
	static uri: string = '';

	buildUrl(base: string, parts?: Array<string>): string {
		return trailingslashurljoin(base, ...(parts || []));
	}

	url(...parts: Array<number | string>): string {
		const strings = parts
			.filter(p => ((typeof p === 'string') || isNumber(p)))
			.map(x => String(x));
		return this.buildUrl(
			(<typeof ResourceService>this.constructor).uri,
			strings);
	}
}

export class Resource<T> {
	static uri: string = '';

	protected svc: ResourceService;

	constructor(svc: ResourceService) {
		this.svc = svc;
	}

	async create(data: T, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<T> {
		const url = this.url();
		return (await this.svc.POST<T>(url, data, cfg)).data;
	}

	async delete(id: number | string, data?: any, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<void> {
		const url = this.url(id);
		return (await this.svc.DELETE<void>(url, data, cfg)).data;
	}

	async get(id: number | string, params?: any, cfg?: Omit<IRequestConfig, 'params' | 'method' | 'url'>): Promise<T> {
		const url = this.url(id);
		return (await this.svc.GET<T>(url, params, cfg)).data;
	}

	async list(params?: any, cfg?: Omit<IRequestConfig, 'params' | 'method' | 'url'>): Promise<T[]> {
		const url = this.url();
		return (await this.svc.GET<T[]>(url, params, cfg)).data;
	}

	async patch(id: number | string, data: Partial<T>, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<T> {
		const url = this.url(id);
		return (await this.svc.PATCH<T>(url, data, cfg)).data;
	}

	async request(cfg: Omit<IRequestConfig, 'url' | 'wellKnown'>, ...urlParts: Array<number | string>): Promise<T> {
		const url = this.url(...urlParts);
		return (await this.svc.request<T>(Object.assign({}, cfg, {url}))).data;
	}

	async update(id: number | string, data: T, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<T> {
		const url = this.url(id);
		return (await this.svc.PUT<T>(url, data, cfg)).data;
	}

	url(...parts: Array<number | string>): string {
		return this.svc.url(
			(<typeof Resource>this.constructor).uri,
			...parts);
	}
}

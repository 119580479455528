import {setError} from './error';
import {RECEIVE_USER_DATA} from './types';
import {apiService as svc} from '../../services';

export function receiveUserData(data: IUser): ReceiveUserDataAction {
	return {
		data,
		type: RECEIVE_USER_DATA,
	};
}

export function userData(): FuncAction<Promise<ReceiveUserDataAction>, ReceiveUserDataAction> {
	return function (dispatch) {
		return svc.account.get()
			.then(data => dispatch(receiveUserData(data)))
			.catch(err => dispatch(setError(err)));
	};
}

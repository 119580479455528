import React from 'react';
import {connect} from 'react-redux';

import Duration from './duration';
import {datetime} from '../../../datetime';
import {apiService as svc} from '../../../services';
import {Header} from '../create/components';
import {makeTime, bind, isNumber} from '../../../util';
import {Card, Grid, GridCell, Scheduler} from '../../components';
import {weather as wxAxns} from '../../actions';
import {DEFAULT_WX_DATE_COUNT} from '../../../constants';
import {isoDateToIDate, isoTimeToITime} from '../../components/scheduler';

interface IDispatchProps {
	marketWeatherData: (opts: IMarketWeatherRequest) => any;
}

interface IProps {
	marketWeather: IMarketWeather;
}

type Props = IDispatchProps & IProps;

interface State {
	currentSchedulerDate: string;
	event: IEvent;
	eventInitial: IEvent;
	marketId: number | null;
	otherGroupEvents: IEvent[];
	selectedSchedulerAnyTime: boolean;
	selectedSchedulerDate: string;
	selectedSchedulerTime: string;
}

class Reschedule extends React.Component<Props, State> {
	timeRange: ITimeRange;
	slug: string;

	constructor(props: Props) {
		super(props);
		this.timeRange = {
			start: makeTime(0, 0, 0),
			stop: makeTime(24, 0, 0),
		};
		this.slug = '';
		const event = {
			allDay: false,
			duration: 0,
			end: (new datetime(1970, 1, 2)).toIDateTime(),
			id: 0,
			projectId: null,
			start: (new datetime(1970, 1, 1)).toIDateTime(),
		};
		this.state = {
			currentSchedulerDate: '',
			event: {...event},
			eventInitial: {...event},
			marketId: null,
			otherGroupEvents: [],
			selectedSchedulerAnyTime: false,
			selectedSchedulerDate: '',
			selectedSchedulerTime: '',
		};
	}

	@bind
	backButtonClicked(): void {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			this.setState({currentSchedulerDate: ''});
		} else {
			this.backToProject();
		}
	}

	backButtonTitle(): string {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			return 'Back to Dates';
		}
		return 'Back to Project';
	}

	backToProject(): void {
		const detail = window.pburls.project.detail;
		const uri = (detail && detail.uri) ? detail.uri : '';
		if (uri) {
			window.location.assign(uri);
		}
	}

	componentDidMount(): void {
		const loc = window.pburls.project.reschedule;
		if (loc) {
			this.slug = loc.slug;
			this.init(this.slug);
		}
	}

	@bind
	durationChanged(totalSeconds: number | null): void {
		const {event} = this.state;
		const seconds = isNumber(totalSeconds) ? totalSeconds : 0;
		event.duration = seconds;
		this.setState({event: {...event}});
		this.setEventData({duration: seconds});
	}

	eventDiff(evt1: IEvent, evt2: IEvent): boolean {
		if (evt1.allDay !== evt2.allDay) {
			return true;
		}
		if (evt1.duration !== evt2.duration) {
			return true;
		}
		const initStart = evt1.start;
		const start = evt2.start;
		if (initStart.time.isoformat !== start.time.isoformat) {
			return true;
		}
		return initStart.date.isoformat !== start.date.isoformat;
	}

	hasChanges(): boolean {
		const {event, eventInitial} = this.state;
		return this.eventDiff(event, eventInitial);
	}

	headerText(): string {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			return 'Choose a Time';
		}
		return 'Pick a Date';
	}

	async init(slug: string): Promise<void> {
		const project = await svc.project.get(slug);
		const eventId = project.eventId;
		if (isNumber(eventId)) {
			const {marketWeatherData} = this.props;
			const event = await svc.event.get(eventId);
			const dt = datetime.fromIDateTime(event.start);
			const loc = await svc.project.location(slug);
			const otherGroupEvents = await svc.event.list({dateCount: DEFAULT_WX_DATE_COUNT, fromDate: 'tomorrow'});
			const marketId = loc.marketId;
			this.setState({
				event,
				eventInitial: {...event},
				marketId: marketId,
				otherGroupEvents,
				selectedSchedulerAnyTime: event.allDay,
				selectedSchedulerDate: dt.date().isoformat(),
				selectedSchedulerTime: dt.time().isoformat(),
			});
			if (isNumber(marketId)) {
				marketWeatherData({
					dateCount: DEFAULT_WX_DATE_COUNT,
					fromDate: 'tomorrow',
					marketID: marketId,
				});
			}
		} else {
			console.log('Reschedule::init no Project Event ID');
		}
	}

	render(): React.ReactNode {
		const {currentSchedulerDate, event} = this.state;
		return (
			<React.Fragment>
				<Card>
					<Header
						onBackClick={this.backButtonClicked}
						primaryText={this.headerText()}
						title={this.backButtonTitle()}/>
					<Grid>
						<GridCell>
							<Scheduler
								currentDate={currentSchedulerDate}
								events={this.schedulerEvents()}
								onAnyTimeChange={this.schedulerAnyTimeChanged}
								onDateChange={this.schedulerDateChanged}
								onTimeChange={this.schedulerTimeChanged}
								selectedEvent={this.schedulerSelectedEvent()}
								timeRangeForWeekday={this.timeRangeForWeekday}
								timeSegmentStep={15}
								weather={this.weather()}/>
						</GridCell>
					</Grid>
				</Card>
				<Card>
					<Header primaryText="Adjust Duration"/>
					<Grid>
						<GridCell>
							<Duration
								onChange={this.durationChanged}
								totalSeconds={event.duration}/>
						</GridCell>
					</Grid>
				</Card>
				<Card>
					<Grid>
						<GridCell>
							<button
								className="mdc-button mdc-button--raised width--100-percent"
								disabled={!this.hasChanges()}
								onClick={this.updateRequest}
								title="Save changes & return to Project"
								type="button">
								<span>SAVE</span>
							</button>
						</GridCell>
					</Grid>
				</Card>
			</React.Fragment>
		);
	}

	@bind
	schedulerAnyTimeChanged(isChecked: boolean): void {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			const d = isoDateToIDate(currentSchedulerDate);
			const t = this.state.event.start.time;
			const start = {date: d, time: t};
			this.setEventData({allDay: isChecked, start});
		}
		this.setState({
			selectedSchedulerAnyTime: isChecked,
			selectedSchedulerDate: currentSchedulerDate,
			selectedSchedulerTime: isChecked ? '' : this.state.selectedSchedulerTime,
		});
	}

	@bind
	schedulerDateChanged(isoDate: string | null): void {
		this.setState({currentSchedulerDate: isoDate || ''});
	}

	schedulerEvents(): ISchedulerBlock[] {
		const {event, otherGroupEvents} = this.state;
		return otherGroupEvents
			.filter(e => (e.id !== event.id))
			.map(e => ({...e, interactive: true}));
	}

	schedulerSelectedEvent(): ISchedulerSelectedBlock | null {
		const {event} = this.state;
		return {anyTime: event.allDay, duration: event.duration, start: event.start};
	}

	@bind
	schedulerTimeChanged(isoTime: string | null): void {
		const {currentSchedulerDate} = this.state;
		if (isoTime && currentSchedulerDate) {
			const d = isoDateToIDate(currentSchedulerDate);
			const t = isoTimeToITime(isoTime);
			this.setEventData({allDay: false, start: {date: d, time: t}});
		}
		this.setState({selectedSchedulerTime: isoTime || ''});
	}

	setEventData(data: Partial<IEvent>): void {
		const {event} = this.state;
		this.setState({event: {...event, ...data}});
	}

	@bind
	timeRangeForWeekday(): ITimeRange | null {
		return this.timeRange;
	}

	@bind
	timeSelectRequest(): boolean {
		return true;
	}

	@bind
	async updateRequest(): Promise<void> {
		if (!this.hasChanges()) {
			return;
		}
		const {event} = this.state;
		await svc.event.update(event.id, event);
		this.backToProject();
	}

	weather(): IDateWeather[] {
		const {marketId} = this.state;
		const wx = isNumber(marketId) ? this.props.marketWeather[marketId] : undefined;
		return wx ? wx : [];
	}
}

const dispatchProps: IDispatchProps = {
	marketWeatherData: wxAxns.marketWeatherData,
};

function mapStateToProps(state: IState): IProps {
	return {
		marketWeather: state.global.marketWeather,
	};
}

export default connect(mapStateToProps, dispatchProps)(Reschedule);

import {El, elOpts, ElOpts} from '../el';
import {TextInput} from './textinput';

export class AdditionalEmailTextInput extends El {
	private input: TextInput | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		opts.tagName = 'details';
		super(opts);
		this.input = null;
		this.init();
	}

	clear(): void {
		if (this.input) {
			this.input.clear();
		}
	}

	close(): void {
		this.setOpen(false);
	}

	destroy(): void {
		if (this.input) {
			this.input.destroy();
		}
		this.input = null;
		super.destroy();
	}

	private init(): void {
		this.setStyleProperty('margin-top', '8px');
		const summary = new El({
			parent: this,
			styles: [['font-size', '0.75rem']],
			tagName: 'summary',
		});
		summary.setText('Optional message');
		this.input = new TextInput({
			columns: 30,
			labelText: 'Message',
			outlined: true,
			parent: this,
			resizable: true,
			rows: 5,
			textarea: true,
		});
	}

	open(): void {
		this.setOpen(true);
	}

	setOpen(open: boolean): void {
		const root = this.element();
		if (root && (root instanceof HTMLDetailsElement)) {
			root.open = open;
		}
	}

	setText(text?: string | null): void {
		this.setValue(text || '');
	}

	setValue(value: string): void {
		if (this.input) {
			this.input.setValue(value);
		}
	}

	text(): string {
		return this.value();
	}

	value(): string {
		if (this.input) {
			return this.input.value();
		}
		return '';
	}
}

import {Resource, ResourceService} from './resource';

class AccessOptionExt extends Resource<IAccessOption> {
	static uri: string = 'access-options';
}

class DataLocationOptionExt extends Resource<IDataLocationOption> {
	static uri: string = 'data-location-options';
}

class IconCollectionExt extends Resource<IIconCollection> {
	static uri: string = 'icon-collections';
}

class OccupancyOptionExt extends Resource<IOccupancyOption> {
	static uri: string = 'occupancy-options';
}

class PaymentProcessorExt extends Resource<IPaymentProcessor> {
	static uri: string = 'payment-processor';

	async get(): Promise<IPaymentProcessor> {
		return this.request({method: 'GET'});
	}
}

export class EtcService extends ResourceService {
	static uri: string = '/api/etc';

	accessOptions: AccessOptionExt;
	dataLocationOptions: DataLocationOptionExt;
	iconCollections: IconCollectionExt;
	occupancyOption: OccupancyOptionExt;
	paymentProcessor: PaymentProcessorExt;

	constructor() {
		super();
		this.accessOptions = new AccessOptionExt(this);
		this.dataLocationOptions = new DataLocationOptionExt(this);
		this.iconCollections = new IconCollectionExt(this);
		this.occupancyOption = new OccupancyOptionExt(this);
		this.paymentProcessor = new PaymentProcessorExt(this);
	}
}

export const etcService: EtcService = new EtcService();

import React, {PropsWithChildren} from 'react';

import {DurationInput} from './index';
import {bind, isNumber} from '../../../../util';
import IconButtonHeading from './iconbuttonheading';

interface IProps {
	duration: number | null;
	// onChange: (value: number, name: 'duration') => any;
	onChange: (value: number) => any;
	iconButtonHeadingText: string;
}

type Props = PropsWithChildren<IProps>;

interface State {
	renderTextInput: boolean;
}

export default class DurationView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {renderTextInput: false};
	}

	@bind
	buttonClick(): void {
		const {renderTextInput} = this.state;
		this.setState({renderTextInput: !renderTextInput});
	}

	render(): React.ReactNode {
		const {renderTextInput} = this.state;
		const {duration, onChange, iconButtonHeadingText} = this.props;
		return (
			<React.Fragment>
				<IconButtonHeading
					icon={renderTextInput ? 'text_fields' : 'linear_scale'}
					onClick={this.buttonClick}
					// text="Duration"
					text={iconButtonHeadingText}
					title={renderTextInput ? 'Switch to slider' : 'Switch to text input'}/>
				<DurationInput
					onChange={onChange}
					renderTextInput={renderTextInput}
					value={isNumber(duration) ? duration : 0}/>
			</React.Fragment>
		);
	}
}

import {MDCSegmentedButton} from '@material/segmented-button';
import {MDCSegmentedButtonEvent, SegmentDetail} from '@material/segmented-button/types';

import {ProjectStatus} from '../../constants';
import {El, elOpts, ElOpts} from '../../el';
import {list} from '../../tools';
import {bind, isNumber} from '../../util';
import {Evt} from '../../evt';

export interface ProjStatSegBtnSegData {
	index: number;
	projectStatus: ProjectStatus;
	selected: boolean;
}

export class ProjectStatusSegmentedButtonEvt extends Evt {
	private d: ProjStatSegBtnSegData;

	constructor(type: number, data: ProjStatSegBtnSegData) {
		super(type);
		this.d = data;
	}

	data(): ProjStatSegBtnSegData {
		return this.d;
	}
}

export class ProjectStatusSegmentedButton extends El {
	ctrl: MDCSegmentedButton | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		if (!opts.root) {
			opts.root = document.querySelector('.mdc-segmented-button');
		}
		super(opts);
		this.ctrl = null;
		this.initializeCtrl();
	}

	protected changedEvent(event: MDCSegmentedButtonEvent): void {
		const d = this.makeSegData(event.detail);
		if (d) {
			this.notifyEvt(new ProjectStatusSegmentedButtonEvt(Evt.Change, d));
			this.selectionChanged(d.projectStatus, d.selected);
		}
	}

	destroy(): void {
		this.destroyCtrl();
		super.destroy();
	}

	destroyCtrl(): void {
		this.removeEventListener('change', this.event);
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'change':
				this.changedEvent(<MDCSegmentedButtonEvent>event);
				break;
			case 'selected':
				break;
		}
	}

	initializeCtrl(): void {
		if (!this.ctrl) {
			const root = this.element();
			if (root) {
				this.addEventListener('change', this.event);
				this.ctrl = new MDCSegmentedButton(root);
			}
		}
	}

	protected makeSegData(mdcData: SegmentDetail): ProjStatSegBtnSegData | null {
		const {index, selected, segmentId} = mdcData;
		if (segmentId) {
			const projectStatus = Number.parseInt(segmentId);
			if (isNumber(projectStatus) && (projectStatus in ProjectStatus)) {
				return {index, projectStatus, selected};
			}
		}
		return null;
	}

	selectedSegments(): list<ProjStatSegBtnSegData> {
		const rv = new list<ProjStatSegBtnSegData>();
		if (this.ctrl) {
			this.ctrl.getSelectedSegments().forEach(d => {
				const dd = this.makeSegData(d);
				if (dd) {
					rv.append(dd);
				}
			});
		}
		return rv;
	}

	private selectionChanged(projectStatus: ProjectStatus | number | string, selected: boolean): void {
		const btnEl = this.querySelector(`[data-segment-id="${projectStatus}"]`);
		if (btnEl) {
			const iconEl = btnEl.querySelector('.mdc-segmented-button__icon');
			if (iconEl) {
				iconEl.setText(selected ? 'check_circle_outline' : 'radio_button_unchecked');
			}
		}
	}

	setStatusSelected(projectStatus: ProjectStatus | number | string, selected: boolean): void {
		if (this.ctrl) {
			const segId = String(projectStatus);
			if (selected) {
				this.ctrl.selectSegment(segId);
			} else {
				this.ctrl.unselectSegment(segId);
			}
			this.selectionChanged(projectStatus, selected);
		}
	}

	statusIndex(status: ProjectStatus): number {
		if (this.ctrl) {
			const segs = this.ctrl.segments;
			for (let i = 0; i < segs.length; ++i) {
				const seg = segs[i];
				const segId = seg.getSegmentId();
				const projectStatus = (segId === undefined) ? Number.NaN : Number.parseInt(segId);
				if (isNumber(projectStatus) && (projectStatus === status)) {
					return i;
				}
			}
		}
		return -1;
	}
}

import {initialState} from './state';
import {RECEIVE_USER_DATA} from '../actions/types';

const initial: IUser = initialState('user');

export default function (state: IUser = initial, action: Action): IUser {
	switch (action.type) {
		case RECEIVE_USER_DATA:
			return {
				...state,
				...(<ReceiveUserDataAction>action).data,
			};
		default:
			return state;
	}
}

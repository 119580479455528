import {ResourceService} from './resource';

export class PriceGroupService extends ResourceService {
	static uri: string = '/api/group/catalog/price-groups/';

	async create(data: IPriceGroup, cfg?: ResourceDataRequestConfig): Promise<IPriceGroup> {
		return (await this.POST<IPriceGroup>(this.url(), data, cfg)).data;
	}

	async delete(id: number, cfg?: ResourceDataRequestConfig): Promise<void> {
		return (await this.DELETE<void>(this.url(id), undefined, cfg)).data;
	}

	async get(id: number, params?: any, cfg?: ResourceQueryRequestConfig): Promise<IPriceGroup> {
		return (await this.GET<IPriceGroup>(this.url(id), params, cfg)).data;
	}

	async list(params?: any, cfg?: ResourceQueryRequestConfig): Promise<IPriceGroup[]> {
		return (await this.GET<IPriceGroup[]>(this.url(), params, cfg)).data;
	}

	async standard(params?: any, cfg?: ResourceQueryRequestConfig): Promise<IPriceGroup> {
		return (await this.GET<IPriceGroup>(this.url('standard'), params, cfg)).data;
	}

	async update(id: number, data: IPriceGroup, cfg?: ResourceDataRequestConfig): Promise<IPriceGroup> {
		return (await this.PUT<IPriceGroup>(this.url(id), data, cfg)).data;
	}
}

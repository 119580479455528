import React, {PropsWithChildren} from 'react';

interface IProps {
	className?: string;
	disabled?: boolean;
	multiple?: boolean;
	name?: string;
	nullOption?: boolean;
	nullOptionLabel?: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
	options: Array<ISelectOption | string>;
	required?: boolean;
	size?: number;
	value?: number | string | number[] | string[];
}

type Props = PropsWithChildren<IProps>;

export default class Select extends React.Component<Props, {}> {
	options(): ISelectOption[] {
		const {options} = this.props;
		if (options.length < 1) {
			return [];
		}
		if (typeof options[0] === 'string') {
			return options.map(s => ({value: s, text: s})) as ISelectOption[];
		}
		return options as ISelectOption[];
	}

	render(): React.ReactNode {
		const {
			className,
			disabled,
			multiple,
			name,
			nullOption,
			nullOptionLabel,
			onChange,
			required,
			size,
			value,
		} = this.props;
		return (
			<select
				className={className}
				disabled={disabled}
				multiple={multiple}
				name={name}
				onChange={onChange}
				required={required}
				size={size}
				value={value as number | ReadonlyArray<string> | string}>
				{nullOption ? <option value="">{nullOptionLabel ? nullOptionLabel : '------'}</option> : null}
				{this.options().map((opt, idx) =>
					<option disabled={opt.disabled} key={idx} label={opt.label} title={opt.title} value={opt.value}>
						{opt.text}
					</option>)}
			</select>
		);
	}

	value(): number | ReadonlyArray<string> | string {
		const {value} = this.props;
		if (value) {
			if (Array.isArray(value)) {
				if (value.length > 0) {
					if (typeof value[0] === 'string') {
						return value as string[];
					}
					return (value as number[]).map(v => String(v));
				}
				return [];
			}
			return value;
		}
		return '';
	}
}

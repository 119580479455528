import {ERROR} from './types';

function extractError(err: any): any {
	if (!err) {
		return null;
	}
	if (('response' in err) && err.response && ('data' in err.response) && err.response.data && ('detail' in err.response.data)) {
		return err.response.data.detail;
	}
	return err;
}

export function setError(data?: any): SetErrorAction {
	return {
		data: extractError(data),
		type: ERROR,
	};
}

import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.TableHTMLAttributes<any> {
}

type Props = PropsWithChildren<IProps>;

export default class DataTableTable extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, className, ...attrs} = this.props;
		return (
			<table className={cssClassName('mdc-data-table__table', className)} {...attrs}>
				{children}
			</table>
		);
	}
}

import React, {PropsWithChildren} from 'react';
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	ResponsiveContainer,
	YAxis,
} from 'recharts';

interface IChartProps {
	data: IChartData[];
}

export function CloudChart(props: PropsWithChildren<IChartProps>) {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<AreaChart margin={{top: 0, right: 0, bottom: 0, left: 0}} data={props.data} style={{cursor: undefined}}>
				<defs>
					<linearGradient id="colorCloudCov" x1="0" y1="0" x2="0" y2="1">
						<stop offset="0%" stopColor="#FFFFFF" stopOpacity={.93}/>
						<stop offset="100%" stopColor="#FFFFFF" stopOpacity={.93}/>
					</linearGradient>
				</defs>
				<YAxis width={0} axisLine={false} tickLine={false} tick={false} type="number" domain={[0, 100]}/>
				<Area type="basis" dataKey="cloud_cov" fillOpacity={1} fill="url(#colorCloudCov)" stroke="0"/>
			</AreaChart>
		</ResponsiveContainer>
	);
}

export function PrecipChart(props: PropsWithChildren<IChartProps>) {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart margin={{top: 0, right: 0, bottom: 0, left: 0}} data={props.data} style={{cursor: undefined}}>
				<defs>
					<linearGradient id="colorProbPrecip" x1="0" y1="0" x2="0" y2="1">
						<stop offset="0%" stopColor="#0093FF" stopOpacity={1}/>
						<stop offset="100%" stopColor="#0093FF" stopOpacity={1}/>
					</linearGradient>
				</defs>
				<YAxis width={0} axisLine={false} tickLine={false} tick={false} type="number" domain={[0, 100]}/>
				<Bar dataKey="prob_precip" fillOpacity={1} fill="url(#colorProbPrecip)"/>
			</BarChart>
		</ResponsiveContainer>
	);
}

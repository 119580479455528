import {Form} from '../ui/form';
import {TextInput} from '../ui/textinput';
import {ButtonRole, StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {bind, isNumber} from '../util';
import {Dialog} from '../ui/dialog';
import {Button} from '../ui/button';
import {El} from '../el';
import {VBoxLayout} from '../ui/layout';

class EmailForm extends Form {
	labelInput: TextInput;
	addressInput: TextInput;

	constructor(parent: El | null = null) {
		super(parent);
		const box = new VBoxLayout(this);
		box.setSpacing(16);
		this.addressInput = new TextInput({
			inputId: 'id_alternate-email-address-label',
			labelText: 'Enter email',
			leadingIcon: 'mail',
			name: 'address',
			required: true,
			type: 'email',
		});
		box.addEl(this.addressInput);
		this.labelInput = new TextInput({
			inputId: 'id_alternate-email-address-label-label',
			labelText: 'Label',
			leadingIcon: 'label',
			name: 'label',
		});
		box.addEl(this.labelInput);
		this.setMethod('POST');
	}

	initCreate(): void {
		this.onSubmit();
		this.setAction(window.pburls.account.alternateEmail.list);
		this.addressInput.setValue('');
		this.labelInput.setValue('');
	}

	initDelete(address: string, label: string, cb?: (() => any) | null): void {
		this.onSubmit(cb);
		this.setAction();
		this.addressInput.setValue(address);
		this.labelInput.setValue(label);
	}

	initEdit(address: string, label: string, cb?: (() => any) | null): void {
		this.onSubmit(cb);
		this.setAction();
		this.addressInput.setValue(address);
		this.labelInput.setValue(label);
	}
}

class EmailDialog extends Dialog {
	_form: EmailForm | null;

	constructor(parent: El | null = null) {
		super(parent);
		this._form = null;
	}

	protected buttonClicked(button: Button): void {
		if (this.buttonRole(button) === ButtonRole.AcceptRole) {
			if (this._form && !this._form.isValid()) {
				return;
			}
		}
		super.buttonClicked(button);
	}

	form(): EmailForm | null {
		return this._form;
	}

	openCreate(): void {
		this.setTitle('Add email');
		this.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const form = this._form;
		if (form) {
			form.initCreate();
			form.show();
			const saveBtn = this.button(StandardButton.Save);
			if (saveBtn) {
				saveBtn.setRaised(true);
				saveBtn.setType('submit');
				saveBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	openDelete(address: string, label: string, cb?: (() => any) | null): void {
		const lbl = label ? ` (${label})` : '';
		this.setTitle(`Delete ${address}${lbl}?`);
		this.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.button(StandardButton.No);
		if (noBtn) {
			noBtn.setRaised(true);
			// this.setDefaultButton(noBtn);
		}
		const form = this._form;
		if (form) {
			form.hide();
			form.initDelete(address, label, cb);
			const yesBtn = this.button(StandardButton.Yes);
			if (yesBtn) {
				yesBtn.setType('submit');
				yesBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	openEdit(address: string, label: string, cb?: (() => any) | null): void {
		this.setTitle('Edit email');
		this.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const form = this._form;
		if (form) {
			form.initEdit(address, label, cb);
			form.show();
			const saveBtn = this.button(StandardButton.Save);
			if (saveBtn) {
				saveBtn.setRaised(true);
				saveBtn.setType('submit');
				saveBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	setForm(form: EmailForm | null): void {
		if (form === this._form) {
			return;
		}
		this._form = form;
		this.setContent(this._form);
	}
}

class DocumentObject {
	primaryText: El | null;
	root: El;
	secondaryText: El | null;

	constructor(objectID: number) {
		this.root = El.fromSelector(`[data-id="${objectID}"].pb-list-item`);
		this.primaryText = this.root.querySelector('.mdc-list-item__primary-text');
		this.secondaryText = this.root.querySelector('.mdc-list-item__secondary-text');
	}

	remove(): void {
		this.root.remove();
	}

	setLabelText(value: string): void {
		if (!this.secondaryText) {
			return;
		}
		this.secondaryText.clear();
		if (value) {
			const icon = new El({
				classNames: ['material-icons', 'font-size--16px', 'vertical-align--middle', 'padding-right--8'],
				parent: this.secondaryText,
				tagName: 'i',
			});
			icon.setText('label');
		}
		this.secondaryText.setText(value);
	}

	setAddress(value: string): void {
		if (this.primaryText) {
			this.primaryText.setText(value);
		}
	}
}

class AlternateEmail {
	static CREATE_CTA_SELECTOR = '#add-email-button-id';
	static DELETE_SELECTOR = 'button[name="delete"]';
	static OBJECT_LIST_SELECTOR = '.pb-list';
	static OBJECT_SELECTOR = '[data-id].pb-list-item';

	form: EmailForm;
	_objectID: number | null;
	_dia: EmailDialog;

	constructor() {
		this.form = new EmailForm();
		this._objectID = null;
		this._dia = new EmailDialog();
	}

	@bind
	createClickEvent(): void {
		this.openCreateDialog();
	}

	@bind
	async deleteClickEvent(event: Event): Promise<void> {
		event.stopPropagation();
		const elem = El.fromEvent(event).closestMatchingAncestor(AlternateEmail.OBJECT_SELECTOR);
		if (elem) {
			const id = Number(elem.attribute('data-id'));
			if (isNumber(id)) {
				this._objectID = id;
				const data = await this.objectData();
				this.openDeleteDialog(data);
				return;
			}
		}
		console.log('AlternateEmail::deleteClickEvent undefined behavior stemming from document event');
	}

	@bind
	async deleteFormSubmitEvent(): Promise<void> {
		const data = await this.objectData();
		await svc.account.alternateEmail.delete(data.id);
		this.removeFromDocument(data.id);
		this._dia.accept();
		this._objectID = null;
	}

	documentEmpty(): boolean {
		const list = document.querySelector(AlternateEmail.OBJECT_LIST_SELECTOR);
		return list ? list.querySelectorAll(AlternateEmail.OBJECT_SELECTOR).length < 1 : true;
	}

	@bind
	async editFormSubmitEvent(): Promise<void> {
		const data = await this.objectData();
		data.address = this.form.addressInput.value();
		data.label = this.form.labelInput.value();
		this.syncDocument(await svc.account.alternateEmail.update(data.id, data));
		this._dia.accept();
		this._objectID = null;
	}

	init(): void {
		this.form.setId('id_alternate-email-form');
		this._dia.setForm(this.form);
		let elem: Element | null = document.querySelector(AlternateEmail.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this.createClickEvent);
		}
		document
			.querySelectorAll(AlternateEmail.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectClickEvent));
		document
			.querySelectorAll(AlternateEmail.DELETE_SELECTOR)
			.forEach(el => el.addEventListener('click', this.deleteClickEvent));
		if (this.documentEmpty()) {
			this.openCreateDialog();
		}
	}

	@bind
	async objectClickEvent(event: Event): Promise<void> {
		const elem = El.fromEvent(event).closestMatchingAncestor(AlternateEmail.OBJECT_SELECTOR);
		if (elem) {
			const id = Number(elem.attribute('data-id'));
			if (isNumber(id)) {
				this._objectID = id;
				const data = await this.objectData();
				this.openEditDialog(data);
				return;
			}
		}
		console.log('AlternateEmail::objectClickEvent undefined behavior stemming from document event');
	}

	objectData(): Promise<IEmailAddress> {
		return svc.account.alternateEmail.get(this.objectID());
	}

	objectID(): number {
		if (isNumber(this._objectID)) {
			return this._objectID;
		}
		throw new Error('AlternateEmail::objectID object ID is null');
	}

	openCreateDialog(): void {
		this._dia.openCreate();
	}

	openDeleteDialog(data: IEmailAddress): void {
		this._dia.openDelete(data.address, data.label, this.deleteFormSubmitEvent);
	}

	openEditDialog(data: IEmailAddress): void {
		this._dia.openEdit(data.address, data.label, this.editFormSubmitEvent);
	}

	removeFromDocument(objectID: number): void {
		const docObj = new DocumentObject(objectID);
		docObj.remove();
		const list = document.querySelector(AlternateEmail.OBJECT_LIST_SELECTOR);
		if (list) {
			if (list.querySelectorAll(AlternateEmail.OBJECT_SELECTOR).length < 1) {
				list.remove();
			}
		}
	}

	syncDocument(data: IEmailAddress): void {
		const docObj = new DocumentObject(data.id);
		docObj.setAddress(data.address);
		docObj.setLabelText(data.label);
	}
}

export function init(): void {
	const comp = new AlternateEmail();
	comp.init();
}

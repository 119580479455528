import React, {PropsWithChildren} from 'react';
import {Corner} from '@material/menu-surface';
import {MDCSelectAdapter, MDCSelectFoundation, strings as ctrlStrings} from '@material/select';

import {assert, bind, cssClassName, isNumber} from '../../util';
import {set} from '../../tools';
import Menu from './menu';
import Outline from './textfield/outline';
import LineRipple from './textfield/lineripple';
import FloatingLabel from './textfield/floatinglabel';
import {CSS_CLASS_FULL_WIDTH} from '../../constants';
import {ListItem, ListItemText} from './list';

export interface ComboBoxOption {
	disabled?: boolean;
	key?: number | string;
	selected?: boolean;
	text?: number | string | null;
	value?: number | string;
}

interface IProps {
	disabled?: boolean;
	labelText?: string;
	leadingIcon?: {iconName: string; interactive?: boolean;} | string;
	fullWidth?: boolean;
	noLabel?: boolean;
	onChange?: (data: {index: number; value: string;}) => any;
	options: Array<ComboBoxOption>;
	outlined?: boolean;
	required?: boolean;
	typeAhead?: boolean;
	wrapFocus?: boolean;
}

type Props = PropsWithChildren<IProps>;

interface IState {
	anchorElement: Element | null;
	labelId: string;
	menuClassNames: set<string>;
	menuCorner?: Corner;
	menuIsOpen: boolean;
	rootClassNames: set<string>;
	selectedTextId: string;
}

export default class ComboBox extends React.Component<Props, IState> {
	static instanceCount: number = 0;

	private _anchorRef: React.RefObject<HTMLDivElement>;
	private _ctrl: MDCSelectFoundation | null;
	private _floatingLabelRef: React.RefObject<FloatingLabel>;
	private _lineRippleRef: React.RefObject<LineRipple>;
	private _menuRef: React.RefObject<Menu>;
	private _notifyEnabled: boolean;
	private _outlineRef: React.RefObject<Outline>;
	private _rootRef: React.RefObject<HTMLDivElement>;
	private _selectedTextRef: React.RefObject<HTMLSpanElement>;

	constructor(props: Props) {
		super(props);
		const instanceNumber = ++ComboBox.instanceCount;
		const classNames: Array<string> = [
			'mdc-select',
		];
		if (props.outlined) {
			classNames.push('mdc-select--outlined');
		} else {
			classNames.push('mdc-select--filled');
		}
		if (props.fullWidth) {
			classNames.push(CSS_CLASS_FULL_WIDTH);
		}
		this._anchorRef = React.createRef();
		this._ctrl = null;
		this._floatingLabelRef = React.createRef();
		this._lineRippleRef = React.createRef();
		this._menuRef = React.createRef();
		this._notifyEnabled = true;
		this._outlineRef = React.createRef();
		this._rootRef = React.createRef();
		this._selectedTextRef = React.createRef();
		this.state = {
			anchorElement: null,
			labelId: `id_pb-combo-box-label-${instanceNumber}`,
			menuClassNames: new set<string>(),
			menuCorner: undefined,
			menuIsOpen: false,
			rootClassNames: new set<string>(classNames),
			selectedTextId: `id_pb-combo-box-selected-text-${instanceNumber}`,
		};
	}

	@bind
	anchorEvent(event: React.SyntheticEvent): void {
		if (!this._ctrl) {
			return;
		}
		switch (event.type) {
			case 'keydown':
				this._ctrl.handleKeydown((event as React.KeyboardEvent).nativeEvent);
				break;
			case 'blur':
				this._ctrl.handleBlur();
				break;
			case 'click':
				const el = this._anchorOrDie();
				el.focus();
				this._ctrl.handleClick(normalizedXCoord(event as React.MouseEvent | React.TouchEvent, el.getBoundingClientRect()));
				break;
			case 'focus':
				this._ctrl.handleFocus();
				break;
		}
	}

	componentDidMount(): void {
		const {options} = this.props;
		this._ctrl = new MDCSelectFoundation(this._ctrlAdapter());
		this._ctrl.init();
		const selectedIndex = options.findIndex(o => o.selected);
		if (selectedIndex >= 0) {
			this._notifyEnabled = false;
			this._ctrl.setSelectedIndex(selectedIndex);
			this._notifyEnabled = true;
		}
	}

	componentWillUnmount(): void {
		if (this._ctrl) {
			this._ctrl.destroy();
			this._ctrl = null;
		}
	}

	@bind
	menuClosed(): void {
		if (this._ctrl) {
			this._ctrl.handleMenuClosed();
		}
		this.setState({menuIsOpen: false});
	}

	@bind
	menuOpened(): void {
		if (this._ctrl) {
			this._ctrl.handleMenuOpened();
		}
	}

	@bind
	menuSelection(index: number): void {
		if (this._ctrl) {
			this._ctrl.handleMenuItemAction(index);
		}
	}

	render(): React.ReactNode {
		const {disabled, labelText, leadingIcon, noLabel, options, outlined, required, typeAhead, wrapFocus} = this.props;
		const {anchorElement, labelId, menuCorner, menuIsOpen, rootClassNames, selectedTextId} = this.state;
		let icon: {iconName: string; interactive: boolean} | null = null;
		if (leadingIcon) {
			if (typeof leadingIcon === 'string') {
				icon = {iconName: leadingIcon, interactive: false};
			} else {
				icon = {iconName: leadingIcon.iconName, interactive: Boolean(leadingIcon.interactive)};
			}
		}
		const hasIcon = Boolean(icon);
		const selectedOpt = (options || []).find(opt => opt.selected);
		const selectedText = (selectedOpt === undefined) ?
			'' :
			(selectedOpt.text || isNumber(selectedOpt.text) ?
				String(selectedOpt.text) :
				'');
		return (
			<div className={cssClassName({'mdc-select--disabled': disabled, 'mdc-select--no-label': noLabel, 'mdc-select--required': required, 'mdc-select--with-leading-icon': hasIcon}, ...rootClassNames)} ref={this._rootRef}>
				<div aria-disabled={Boolean(disabled)} aria-expanded={false} aria-haspopup="listbox" aria-labelledby={`${labelId} ${selectedTextId}`} aria-required={Boolean(required)} className="mdc-select__anchor" onBlur={this.anchorEvent} onClick={this.anchorEvent} onFocus={this.anchorEvent} onKeyDown={this.anchorEvent} ref={this._anchorRef} role="button">
					{outlined ? null : <span className="mdc-select__ripple"/>}
					{(outlined || noLabel) ? null : <FloatingLabel ref={this._floatingLabelRef} id={labelId}>{labelText}</FloatingLabel>}
					{outlined ?
						<Outline noLabel={noLabel} ref={this._outlineRef}>
							<FloatingLabel id={labelId} ref={this._floatingLabelRef}>{labelText}</FloatingLabel>
						</Outline> :
						null}
					{icon ?
						<i className="material-icons mdc-select__icon" role={icon.interactive ? 'button' : undefined} tabIndex={icon.interactive ? 0 : undefined}>{icon.iconName}</i> :
						null}
					<div className="mdc-select__selected-text-container">
						<span className="mdc-select__selected-text" id={selectedTextId} ref={this._selectedTextRef}>{selectedText}</span>
					</div>
					<div className="mdc-select__dropdown-icon">
						<svg className="mdc-select__dropdown-icon-graphic" focusable="false" viewBox="7 10 10 5">
							<polygon className="mdc-select__dropdown-icon-inactive" fillRule="evenodd" points="7 10 12 15 17 10" stroke="none"/>
							<polygon className="mdc-select__dropdown-icon-active" fillRule="evenodd" points="7 15 12 10 17 15" stroke="none"/>
						</svg>
					</div>
					{outlined ? null : <LineRipple ref={this._lineRippleRef}/>}
				</div>
				<Menu anchorElement={anchorElement} className="mdc-select__menu mdc-menu-surface--fullwidth" corner={menuCorner} isOpen={menuIsOpen} listRole="listbox" onClose={this.menuClosed} onOpen={this.menuOpened} onSelect={this.menuSelection} ref={this._menuRef} typeAhead={typeAhead} wrapFocus={wrapFocus}>
					{(options || []).map((opt, idx) =>
						<ListItem
							dataValue={(opt.value || isNumber(opt.value)) ? String(opt.value) : ''}
							disabled={opt.disabled}
							key={(opt.key === undefined) ? idx : opt.key}
							role="option"
							selected={opt.selected}
							tabIndex={(idx === 0) ? 0 : undefined}>
							<ListItemText primaryText={isNumber(opt.text) ? String(opt.text) : opt.text || ''}/>
						</ListItem>)}
				</Menu>
			</div>
		);
	}

	selectedIndex(): number | number[] {
		return this._menuOrDie().selectedIndex();
	}

	private _anchorOrDie(): HTMLDivElement {
		const comp = this._anchorRef.current;
		assert(comp, 'anchor ref current is null');
		return comp;
	}

	@bind
	private _ctrlActivateBottomLine(): void {
	}

	private _ctrlAdapter(): MDCSelectAdapter {
		return {
			activateBottomLine: this._ctrlActivateBottomLine,
			addClass: this._ctrlAddClass,
			addMenuClass: this._ctrlAddMenuClass,
			closeMenu: this._ctrlCloseMenu,
			closeOutline: this._ctrlCloseOutline,
			deactivateBottomLine: this._ctrlDeactivateBottomLine,
			floatLabel: this._ctrlFloatLabel,
			focusMenuItemAtIndex: this._ctrlFocusMenuItemAtIndex,
			getAnchorElement: this._ctrlGetAnchorElement,
			getLabelWidth: this._ctrlGetLabelWidth,
			getMenuItemCount: this._ctrlGetMenuItemCount,
			getMenuItemTextAtIndex: this._ctrlGetMenuItemTextAtIndex,
			getMenuItemValues: this._ctrlGetMenuItemValues,
			getSelectAnchorAttr: this._ctrlGetSelectAnchorAttr,
			getSelectedIndex: this._ctrlGetSelectedIndex,
			hasClass: this._ctrlHasClass,
			hasLabel: this._ctrlHasLabel,
			hasOutline: this._ctrlHasOutline,
			isSelectAnchorFocused: this._ctrlIsSelectAnchorFocused,
			isTypeaheadInProgress: this._ctrlIsTypeaheadInProgress,
			notchOutline: this._ctrlNotchOutline,
			notifyChange: this._ctrlNotifyChange,
			openMenu: this._ctrlOpenMenu,
			removeClass: this._ctrlRemoveClass,
			removeMenuClass: this._ctrlRemoveMenuClass,
			removeSelectAnchorAttr: this._ctrlRemoveSelectAnchorAttr,
			setLabelRequired: this._ctrlSetLabelRequired,
			setMenuAnchorCorner: this._ctrlSetMenuAnchorCorner,
			setMenuAnchorElement: this._ctrlSetMenuAnchorElement,
			setMenuWrapFocus: this._ctrlSetMenuWrapFocus,
			setRippleCenter: this._ctrlSetRippleCenter,
			setSelectAnchorAttr: this._ctrlSetSelectAnchorAttr,
			setSelectedIndex: this._ctrlSetSelectedIndex,
			setSelectedText: this._ctrlSetSelectedText,
			typeaheadMatchItem: this._ctrlTypeaheadMatchItem,
		};
	}

	@bind
	private _ctrlAddClass(className: string): void {
		const {rootClassNames} = this.state;
		rootClassNames.add(className);
		this.setState({rootClassNames: new set(rootClassNames)});
	}

	@bind
	private _ctrlAddMenuClass(className: string): void {
		const {menuClassNames} = this.state;
		menuClassNames.add(className);
		this.setState({menuClassNames: new set(menuClassNames)});
	}

	@bind
	private _ctrlCloseMenu(): void {
		this.setState({menuIsOpen: false});
	}

	@bind
	private _ctrlCloseOutline(): void {
		this._outlineOrDie().closeNotch();
	}

	@bind
	private _ctrlDeactivateBottomLine(): void {
		this._lineRippleOrDie().deactivate();
	}

	@bind
	private _ctrlFloatLabel(shouldFloat: boolean): void {
		this._floatingLabelOrDie().setFloat(shouldFloat);
	}

	@bind
	private _ctrlFocusMenuItemAtIndex(index: number): void {
		this._menuOrDie().focusItemAtIndex(index);
	}

	@bind
	private _ctrlGetAnchorElement(): Element | null {
		return this._anchorRef.current;
	}

	@bind
	private _ctrlGetLabelWidth(): number {
		return this._floatingLabelOrDie().width();
	}

	@bind
	private _ctrlGetMenuItemCount(): number {
		return this._menuOrDie().count();
	}

	@bind
	private _ctrlGetMenuItemTextAtIndex(index: number): string {
		const item = this._menuOrDie().itemAtIndex(index);
		return (item && item.textContent) || '';
	}

	@bind
	private _ctrlGetMenuItemValues(): string[] {
		return this._menuOrDie()
			.items()
			.map(item => (item.getAttribute(ctrlStrings.VALUE_ATTR) || ''));
	}

	@bind
	private _ctrlGetSelectAnchorAttr(attr: string): string | null {
		return this._anchorOrDie().getAttribute(attr);
	}

	@bind
	private _ctrlGetSelectedIndex(): number {
		const index = this.selectedIndex();
		return Array.isArray(index) ? index[0] : index;
	}

	@bind
	private _ctrlHasClass(className: string): boolean {
		return this.state.rootClassNames.has(className);
	}

	@bind
	private _ctrlHasLabel(): boolean {
		return Boolean(this._floatingLabelRef.current);
	}

	@bind
	private _ctrlHasOutline(): boolean {
		return Boolean(this._outlineRef.current);
	}

	@bind
	private _ctrlIsSelectAnchorFocused(): boolean {
		return Boolean(this._anchorRef.current) && (this._anchorRef.current === document.activeElement);
	}

	@bind
	private _ctrlIsTypeaheadInProgress(): boolean {
		return this._menuOrDie().typeaheadInProgress();
	}

	@bind
	private _ctrlNotchOutline(labelWidth: number): void {
		this._outlineOrDie().setNotch(labelWidth);
	}

	@bind
	private _ctrlNotifyChange(value: string): void {
		const {onChange} = this.props;
		if (this._notifyEnabled && onChange) {
			const index = this.selectedIndex();
			onChange({index: Array.isArray(index) ? index[0] : index, value});
		}
	}

	@bind
	private _ctrlOpenMenu(): void {
		this.setState({menuIsOpen: true});
	}

	@bind
	private _ctrlRemoveClass(className: string): void {
		const {rootClassNames} = this.state;
		rootClassNames.discard(className);
		this.setState({rootClassNames: new set(rootClassNames)});
	}

	@bind
	private _ctrlRemoveMenuClass(className: string): void {
		const {menuClassNames} = this.state;
		menuClassNames.discard(className);
		this.setState({menuClassNames: new set(menuClassNames)});
	}

	@bind
	private _ctrlRemoveSelectAnchorAttr(attr: string): void {
		this._anchorOrDie().removeAttribute(attr);
	}

	@bind
	private _ctrlSetLabelRequired(isRequired: boolean): void {
		this._floatingLabelOrDie().setRequired(isRequired);
	}

	@bind
	private _ctrlSetMenuAnchorCorner(anchorCorner: Corner): void {
		this.setState({menuCorner: anchorCorner});
	}

	@bind
	private _ctrlSetMenuAnchorElement(anchorElement: Element): void {
	}

	@bind
	private _ctrlSetMenuWrapFocus(wrapFocus: boolean): void {
		this._menuOrDie().setWrapFocus(wrapFocus);
	}

	@bind
	private _ctrlSetRippleCenter(normalizedX: number): void {
		this._lineRippleOrDie().setRippleCenter(normalizedX);
	}

	@bind
	private _ctrlSetSelectAnchorAttr(attr: string, value: string): void {
		this._anchorOrDie().setAttribute(attr, value);
	}

	@bind
	private _ctrlSetSelectedIndex(index: number): void {
		this._menuOrDie().setSelectedIndex(index);
	}

	@bind
	private _ctrlSetSelectedText(text: string): void {
		const elem = this._selectedTextRef.current;
		if (elem) {
			elem.textContent = text;
		}
	}

	@bind
	private _ctrlTypeaheadMatchItem(nextChar: string, startingIndex: number): number {
		return this._menuOrDie().typeaheadMatchItem(nextChar, startingIndex);
	}

	private _floatingLabelOrDie(): FloatingLabel {
		const comp = this._floatingLabelRef.current;
		assert(comp, 'FloatingLabel ref current is null');
		return comp;
	}

	private _lineRippleOrDie(): LineRipple {
		const comp = this._lineRippleRef.current;
		assert(comp, 'LineRipple ref current is null');
		return comp;
	}

	private _menuOrDie(): Menu {
		const comp = this._menuRef.current;
		assert(comp, 'Menu ref current is null');
		return comp;
	}

	private _outlineOrDie(): Outline {
		const comp = this._outlineRef.current;
		assert(comp, 'Outline ref current is null');
		return comp;
	}
}

function normalizedXCoord(event: React.MouseEvent | React.TouchEvent, targetRect: ClientRect): number {
	const xCoord = isTouchEvent(event) ? event.touches[0].clientX : event.clientX;
	return xCoord - targetRect.left;
}

function isTouchEvent(event: React.SyntheticEvent): event is React.TouchEvent {
	return 'touches' in event;
}

import {isNumber} from '../util';
import {ResourceService} from './resource';

type ObjParams = {[key: string]: string | Array<string>;};

export class ProjectService extends ResourceService {
	static uri: string = '/api/projects';
	static newUri: string = '/api/new-project';

	static paginatedRequestParams(params?: Partial<IPaginatedProjectRequest>): URLSearchParams | ObjParams {
		params = params || {};
		const pairs: Array<[string, Array<string> | string]> = [];
		if (params.q) {
			pairs.push(['q', params.q]);
		}
		if ((params.group !== undefined) && (params.group.length > 0)) {
			pairs.push(['group', params.group.join(',')]);
		}
		if ((params.user !== undefined) && (params.user.length > 0)) {
			pairs.push(['user', params.user.join(',')]);
		}
		if (params.page !== undefined) {
			pairs.push(['page', String(params.page)]);
		}
		if (params.show !== undefined) {
			pairs.push(['show', String(params.show)]);
		}
		if (params.status !== undefined) {
			pairs.push(['status', params.status.map(s => String(s))]);
		}
		if (params.filters !== undefined) {
			for (const f of params.filters) {
				pairs.push(['filter', `${f.field},${f.value}`]);
			}
		}
		let p: URLSearchParams | ObjParams;
		if (window.pbdomsupport.urlSearchParams) {
			const tups: Array<[string, Array<string>]> = [];
			const noTups: Array<[string, string]> = [];
			for (const [k, v] of pairs) {
				if (Array.isArray(v)) {
					tups.push([k, v]);
				} else {
					noTups.push([k, v]);
				}
			}
			p = new URLSearchParams(noTups);
			tups.forEach(([k, v]) => v.forEach(s => (p instanceof URLSearchParams) && p.append(k, s)));
		} else {
			p = {};
			for (const [k, v] of pairs) {
				if (k in p) {
					if (!Array.isArray(p[k])) {
						p[k] = [<string>p[k]];
					}
					if (Array.isArray(v)) {
						(<Array<string>>p[k]).push(...v);
					} else {
						(<Array<string>>p[k]).push(v);
					}
				} else {
					p[k] = v;
				}
			}
		}
		return p;
	}

	async assignable(slug: string): Promise<IUserAssignment> {
		const url = this.url(slug, 'assignable');
		return (await this.GET<IUserAssignment>(url)).data;
	}

	async assigned(slug: string): Promise<IUserAssignment> {
		const url = this.url(slug, 'assigned');
		return (await this.GET<IUserAssignment>(url)).data;
	}

	async batch(slugs: string[]): Promise<IProjectBulkBalance> {
		const params = {slug: slugs};
		const url = this.url('batch');
		return (await this.GET<IProjectBulkBalance>(url, params)).data;
	}

	async cancel(slug: string): Promise<void> {
		const url = this.url(slug, 'cancel');
		return (await this.PUT<void>(url)).data;
	}

	async create(data: INewProjectData): Promise<INewProjectResponse> {
		const url = this.buildUrl(ProjectService.newUri);
		return (await this.POST<INewProjectResponse>(url, data)).data;
	}

	async createLineItem(slug: string, data: ILineItem): Promise<ILineItem> {
		const url = this.url(slug, 'line-items');
		return (await this.POST<ILineItem>(url, data)).data;
	}

	async createUrl(slug: string, data: IProjectURL): Promise<IProjectURL> {
		const url = this.url(slug, 'urls');
		return (await this.POST<IProjectURL>(url, data)).data;
	}

	async delete(slug: string, cfg?: ResourceDataRequestConfig): Promise<void> {
		return (await this.DELETE<void>(this.url(slug), undefined, cfg)).data;
	}

	async deleteLineItem(slug: string, lineItemId: number | string): Promise<void> {
		const url = this.url(slug, 'line-items', lineItemId);
		return (await this.DELETE<void>(url)).data;
	}

	async deleteUrl(slug: string, pk: number | string): Promise<void> {
		const url = this.url(slug, 'urls', pk);
		return (await this.DELETE<void>(url)).data;
	}

	async duration(query: IDurationRequest): Promise<number> {
		const params: any = {i: query.itemIDs, a: query.itemAddOnIDs};
		if (isNumber(query.size)) {
			params['s'] = query.size;
		}
		if (isNumber(query.clientUserID)) {
			params['cuid'] = query.clientUserID;
		}
		const url = this.buildUrl(ProjectService.newUri, ['duration']);
		return (await this.GET<number>(url, params)).data;
	}

	async emailList(slug: string): Promise<Array<IProjectEmail>> {
		const url = this.url(slug, 'email');
		return (await this.GET<Array<IProjectEmail>>(url)).data;
	}

	async emailObject(slug: string, emailType: number): Promise<IProjectEmail> {
		const url = this.url(slug, 'email', emailType);
		return (await this.GET<IProjectEmail>(url)).data;
	}

	async get(slug: string, params?: any, cfg?: ResourceQueryRequestConfig): Promise<IProject> {
		return (await this.GET<IProject>(this.url(slug), params, cfg)).data;
	}

	async lineItems(slug: string): Promise<Array<ILineItem>> {
		const url = this.url(slug, 'line-items');
		return (await this.GET<Array<ILineItem>>(url)).data;
	}

	async list(params?: Partial<IPaginatedProjectRequest> | URLSearchParams, cfg?: ResourceQueryRequestConfig): Promise<IPaginatedProject> {
		let p: URLSearchParams | ObjParams | undefined = undefined;
		if (params) {
			try {
				if (params instanceof URLSearchParams) {
					p = params;
				}
			} catch {
			}
			if (!p) {
				p = ProjectService.paginatedRequestParams(<Partial<IPaginatedProjectRequest>>params);
			}
		}
		return (await this.GET<IPaginatedObject<IProject>>(this.url(), p, cfg)).data;
	}

	async location(slug: string): Promise<ILocation> {
		const url = this.url(slug, 'location');
		return (await this.GET<ILocation>(url)).data;
	}

	async markPaid(slug: string, data?: {additionalEmailText: string;}): Promise<IProject> {
		const url = this.url(slug, 'mark-paid');
		return (await this.PUT<IProject>(url, data)).data;
	}

	async options(slug: string): Promise<Array<Array<IProjectOption>>> {
		const url = this.url(slug, 'options');
		return (await this.GET<Array<Array<IProjectOption>>>(url)).data;
	}

	async sendEmail(slug: string, data: IProjectEmail): Promise<Array<IProjectEmail>> {
		const url = this.url(slug, 'email');
		return (await this.POST<Array<IProjectEmail>>(url, data)).data;
	}

	async summary(query: ISummaryRequest): Promise<ISummaryData> {
		const params: any = {
			dt: query.dateTime,
			i: query.itemIDs,
			a: query.itemAddOnIDs,
		};
		if (isNumber(query.size)) {
			params['s'] = query.size;
		}
		if (isNumber(query.clientUserID)) {
			params['cuid'] = query.clientUserID;
		}
		const url = this.buildUrl(ProjectService.newUri, ['summary']);
		return (await this.GET<ISummaryData>(url, params)).data;
	}

	async tasks(slug: string, params?: any, cfg?: ResourceQueryRequestConfig): Promise<Array<IProjectTask>> {
		return (await this.GET<Array<IProjectTask>>(this.url(slug, 'tasks'), params, cfg)).data;
	}

	async update(slug: string, data: IProjectUpdateRequest, cfg?: ResourceDataRequestConfig): Promise<IProject> {
		return (await this.PUT<IProject>(this.url(slug), data, cfg)).data;
	}

	async updateAssigned(slug: string, data: Partial<{photography: Array<number | string>; editing: Array<number | string>;}>): Promise<IProject> {
		const url = this.url(slug, 'assigned');
		return (await this.PUT<IProject>(url, data)).data;
	}

	async updateLineItem(slug: string, lineItemId: number | string, data: ILineItem): Promise<ILineItem> {
		const url = this.url(slug, 'line-items', lineItemId);
		return (await this.PUT<ILineItem>(url, data)).data;
	}

	async updateDataLocation(slug: string, data: IDataLocation): Promise<IProject> {
		const url = this.url(slug, 'data-location');
		return (await this.PUT<IProject>(url, data)).data;
	}

	async updateTask(slug: string, data: IProjectTask): Promise<IProject> {
		const url = this.url(slug, 'tasks', data.id);
		return (await this.PUT<IProject>(url, data)).data;
	}

	async updateUrl(slug: string, data: IProjectURL): Promise<IProjectURL> {
		const url = this.url(slug, 'urls', data.id);
		return (await this.PUT<IProjectURL>(url, data)).data;
	}

	async urlObject(slug: string, pk: number): Promise<IProjectURL> {
		const url = this.url(slug, 'urls', pk);
		return (await this.GET<IProjectURL>(url)).data;
	}

	async urlList(slug: string): Promise<Array<IProjectURL>> {
		const url = this.url(slug, 'urls');
		return (await this.GET<Array<IProjectURL>>(url)).data;
	}

	async users(slug: string): Promise<Array<IUser>> {
		const url = this.url(slug, 'users');
		return (await this.GET<Array<IUser>>(url)).data;
	}
}

import {list} from '../../tools';
import {Variant} from '../../variant';
import {bind, isNumber} from '../../util';
import {ItemDataRole, MatchFlag, Orientation, SortOrder} from '../../constants';
import {AbstractItemModel, ItemModelEvtType, ModelIndex, ModelIndexList} from '../../itemmodel';
import {TableCellMouseEvt, TableEl} from './el';
import {Evt, EvtDispatcher} from '../../evt';
import {DataTableEl, IDataTableElOpts} from './dtel';
import {DataTableEvt, DataTableSortEvt, HeaderEvtType, HeaderSectionMoveEvt, HeaderSortIndicatorChangeEvt} from './evt';
import {TableItem, TableModel} from './model';
import {TableElDelegate} from './delegate';

export interface DataTableOpts {
	dimensions: {rows: number; columns: number;};
	elOpts: Partial<IDataTableElOpts>;
	model: AbstractItemModel;
}

export class DataTable extends EvtDispatcher {
	private el: DataTableEl | null;
	model: AbstractItemModel;
	private progressIndicatorEnabled: boolean;
	private sortingEnabled: boolean;

	constructor(opts?: Partial<DataTableOpts>) {
		super();
		this.el = null;
		this.model = AbstractItemModel.staticEmptyModel();
		this.progressIndicatorEnabled = true;
		this.sortingEnabled = false;
		this.init(opts);
	}

	beginFetchData(): void {
		this.el && this.el.showProgress();
	}

	clear(): void {
		// Removes all items in the view. This will also remove all selections
		// and headers. If you don't want to remove the headers, use
		// DataTable::clearContents(). The table dimensions stay the same.
		const mdl = this.tableModel();
		if (mdl) {
			mdl.clear();
		}
	}

	clearContents(): void {
		// Removes all items not in the headers from the view. This will also
		// remove all selections. The table dimensions stay the same.
		const mdl = this.tableModel();
		if (mdl) {
			mdl.clearContents();
		}
	}

	column(item: TableItem): number {
		const mdl = this.tableModel();
		if (mdl) {
			return mdl.index(item).column;
		}
		return -1;
	}

	columnCount(): number {
		return this.model.columnCount();
	}

	protected dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex): void {
		const mdl = this.tableModel();
		if (mdl && this.sortingEnabled && topLeft.isValid() && bottomRight.isValid()) {
			const column = this.sortIndicatorSection();
			if (column >= topLeft.column && column <= bottomRight.column) {
				mdl.ensureSorted(
					column,
					this.sortIndicatorOrder(),
					topLeft.row,
					bottomRight.row);
			}
		}
	}

	destroy(): void {
		if (this.el) {
			this.el.offEvt(this.elEvt);
			this.el.destroy();
		}
		this.el = null;
		this.setModel(null);
		this.sortingEnabled = false;
		super.destroy();
	}

	@bind
	private elEvt(evt: Evt): void {
		switch (evt.type()) {
			case DataTableEvt.ItemClick: {
				this.notifyEvt(evt);
				break;
			}
			case DataTableEvt.SectionSelectionChange: {
				this.notifyEvt(evt);
				break;
			}
			case DataTableEvt.SectionMove: {
				this.notifyEvt(evt);
				break;
			}
			case Evt.MouseButtonClick:
			case Evt.ContextMenu: {
				if (evt instanceof TableCellMouseEvt) {
					this._tableCellMouseEvt(evt);
				}
				break;
			}
			case HeaderEvtType.SortIndicatorChanged: {
				if (evt instanceof HeaderSortIndicatorChangeEvt) {
					this._sortIndicatorChanged(evt.visualIndex(), evt.order());
				}
				break;
			}
			case HeaderEvtType.SectionMoved: {
				const e = <HeaderSectionMoveEvt>evt;
				this._sectionMoved(e.oldVisualIndex(), e.newVisualIndex());
				break;
			}
		}
	}

	endFetchData(): void {
		this.el && this.el.hideProgress();
	}

	findItems(text: string, flags: MatchFlag): list<TableItem> {
		const mdl = this.tableModel();
		if (!mdl) {
			return new list<TableItem>();
		}
		const indexes = new ModelIndexList();
		for (let column = 0; column < this.columnCount(); ++column) {
			indexes.append(this.model.match(this.model.index(0, column, new ModelIndex()), ItemDataRole.DisplayRole, new Variant(text), -1, flags));
		}
		const items = new list<TableItem>();
		const indexCount = indexes.size();
		for (let i = 0; i < indexCount; ++i) {
			const index = mdl.item(indexes.at(i));
			if (index) {
				items.append(index);
			}
		}
		return items;
	}

	horizontalHeaderItem(column: number): TableItem | null {
		const mdl = this.tableModel();
		return mdl ? mdl.horizontalHeaderItem(column) : null;
	}

	protected init(opts?: Partial<DataTableOpts>): void {
		opts = opts || {};
		this.el = new DataTableEl(opts.elOpts);
		this.el.onEvt(this.elEvt);
		this.setModel(opts.model || null);
		if (opts.dimensions) {
			this.setColumnCount(opts.dimensions.columns);
			this.setRowCount(opts.dimensions.rows);
		}
	}

	insertColumn(column: number): void {
		this.model.insertColumns(column, 1);
	}

	insertRow(row: number): void {
		this.model.insertRows(row, 1);
	}

	protected isIndexValid(index: ModelIndex): boolean {
		return (index.row >= 0)
			&& (index.column >= 0)
			&& (Boolean(index.model)
				&& (index.model === this.model));
	}

	isRowSelectionEnabled(): boolean {
		return this.el ?
			this.el.isRowsCheckable() :
			false;
	}

	isSortingEnabled(): boolean {
		return this.sortingEnabled;
	}

	item(row: number, column: number): TableItem | null;
	item(index: ModelIndex): TableItem | null;
	item(a: ModelIndex | number, b?: number): TableItem | null {
		const mdl = this.tableModel();
		if (mdl) {
			if (isNumber(a) && isNumber(b)) {
				return mdl.item(a, b);
			}
			if (a instanceof ModelIndex) {
				return mdl.item(a);
			}
		}
		return null;
	}

	itemPrototype(): TableItem | null {
		const mdl = this.tableModel();
		return mdl ? mdl.itemPrototype() : null;
	}

	@bind
	private modelEvt(evt: Evt): void {
		switch (evt.type()) {
			case ItemModelEvtType.ModelReset:
				this.modelResetAction();
				break;
		}
	}

	protected modelResetAction(): void {
		let clearHeader = true;
		const columnCount = this.model.columnCount();
		const mdl = this.tableModel();
		if (mdl) {
			for (let column = 0; column < columnCount; ++column) {
				if (mdl.horizontalHeaderItem(column)) {
					clearHeader = false;
					break;
				}
			}
		}
	}

	removeColumn(column: number): void {
		this.model.removeColumns(column, 1);
	}

	removeRow(row: number): void {
		this.model.removeRows(row, 1);
	}

	row(item: TableItem): number {
		const mdl = this.tableModel();
		return mdl ? mdl.index(item).row : -1;
	}

	rowCount(): number {
		return this.model.rowCount();
	}

	selectedRows(): list<ModelIndex> {
		const rv = new list<ModelIndex>();
		if (this.el && this.isRowSelectionEnabled()) {
			for (let row = 0; row < this.model.rowCount(); ++row) {
				const index = this.model.index(row, 0);
				if (index.isValid()) {
					if (this.el.isRowChecked(row)) {
						rv.append(index);
					}
				}
			}
		}
		return rv;
	}

	setColumnCount(count: number): void {
		const mdl = this.tableModel();
		if (mdl) {
			mdl.setColumnCount(count);
		}
	}

	setElForColumn(column: number, el: typeof TableEl | null): void {
		if (this.el) {
			this.el.setColumnElCls(column, el);
		}
	}

	setHorizontalHeaderItem(column: number, item: TableItem | null): void {
		if (item) {
			item.view = this;
			const mdl = this.tableModel();
			if (mdl) {
				mdl.setHorizontalHeaderItem(column, item);
			}
		} else {
			const item = this.takeHorizontalHeaderItem(column);
			if (item) {
				item.destroy();
			}
		}
	}

	setHorizontalHeaderLabels(labels: list<string> | Array<string>): void {
		const mdl = this.tableModel();
		if (mdl) {
			const lbls = new list<string>(labels);
			for (let i = 0; (i < mdl.columnCount()) && (i < lbls.size()); ++i) {
				let item = mdl.horizontalHeaderItem(i);
				if (!item) {
					item = mdl.createItem();
					this.setHorizontalHeaderItem(i, item);
				}
				item.setText(lbls.at(i));
			}
		}
	}

	setItem(row: number, column: number, item: TableItem | null): void {
		if (item) {
			if (item.view) {
				console.log('DataTable: Cannot insert an item that is already owned by another DataTable.');
			} else {
				item.view = this;
				const mdl = this.tableModel();
				if (mdl) {
					mdl.setItem(row, column, item);
				}
			}
		} else {
			item = this.takeItem(row, column);
			if (item) {
				item.destroy();
			}
		}
	}

	setItemDelegateForColumn(column: number, delegate: typeof TableElDelegate | null): void {
		if (this.el) {
			this.el.setItemDelegateForColumn(column, delegate);
		}
	}

	setItemPrototype(item: TableItem | null): void {
		const mdl = this.tableModel();
		if (mdl) {
			mdl.setItemPrototype(item);
		}
	}

	setModel(model: AbstractItemModel | null): void {
		if ((model === this.model) || ((this.model === AbstractItemModel.staticEmptyModel()) && !model)) {
			return;
		}
		if (this.model !== AbstractItemModel.staticEmptyModel()) {
			this.model.offEvt(this.modelEvt);
		}
		this.model = model ?
			model :
			AbstractItemModel.staticEmptyModel();
		if (this.model !== AbstractItemModel.staticEmptyModel()) {
			if (this.model instanceof TableModel) {
				this.model.view = this;
			}
			this.model.onEvt(this.modelEvt);
		}
		if (this.el) {
			this.el.setModel(this.model);
		}
	}

	setProgressIndicatorEnabled(enable: boolean): void {
		this.progressIndicatorEnabled = enable;
	}

	setRowCount(count: number): void {
		const mdl = this.tableModel();
		if (mdl) {
			mdl.setRowCount(count);
		}
	}

	setRowSelectionEnabled(enable: boolean): void {
		this.el && this.el.setRowsCheckable(enable);
	}

	setSectionsMovable(movable: boolean, orientation: Orientation): void {
		if (this.el) {
			if (orientation === Orientation.Horizontal) {
				this.el.setColumnsMovable(movable);
			} else {
				this.el.setRowsMovable(movable);
			}
		}
	}

	setSortIndicatorShown(show: boolean): void {
		if (this.el) {
			this.el.setSortIndicatorShown(show);
		}
	}

	setSortingEnabled(enable: boolean): void {
		if (this.el) {
			this.el.setSortIndicatorShown(enable);
		}
		if (enable) {
			this.sortByColumn(this.sortIndicatorSection(), this.sortIndicatorOrder());
		}
		this.sortingEnabled = enable;
	}

	setVerticalHeaderItem(row: number, item: TableItem | null): void {
		if (item) {
			item.view = this;
			const mdl = this.tableModel();
			if (mdl) {
				mdl.setVerticalHeaderItem(row, item);
			}
		} else {
			item = this.takeVerticalHeaderItem(row);
			if (item) {
				item.destroy();
			}
		}
	}

	sortByColumn(column: number, order: SortOrder): void {
		if (column < 0) {
			return;
		}
		// If sorting is enabled it will emit an event which then actually
		// sorts
		// this.horzHeader.setSortIndicator(column, order);
		if (this.el) {
			this.el.setSortIndicator(column, order);
		}
		// If sorting is not enabled, force to sort now
		if (!this.sortingEnabled) {
			this.model.sort(column, order);
		}
	}

	sortIndicatorOrder(): SortOrder {
		if (this.el) {
			return this.el.sortIndicatorOrder();
		}
		return -1;
	}

	sortIndicatorSection(): number {
		if (this.el) {
			return this.el.sortIndicatorSection();
		}
		return -1;
	}

	private tableModel(): TableModel | null {
		if (this.model instanceof TableModel) {
			return this.model;
		}
		return null;
	}

	takeHorizontalHeaderItem(column: number): TableItem | null {
		const mdl = this.tableModel();
		if (mdl) {
			const item = mdl.takeHorizontalHeaderItem(column);
			if (item) {
				item.view = null;
			}
			return item;
		}
		return null;
	}

	takeItem(row: number, column: number): TableItem | null {
		const mdl = this.tableModel();
		if (mdl) {
			const item = mdl.takeItem(row, column);
			if (item) {
				item.view = null;
			}
			return item;
		}
		return null;
	}

	takeVerticalHeaderItem(row: number): TableItem | null {
		let item: TableItem | null = null;
		const mdl = this.tableModel();
		if (mdl) {
			item = mdl.takeVerticalHeaderItem(row);
		}
		if (item) {
			item.view = null;
		}
		return item;
	}

	verticalHeaderItem(row: number): TableItem | null {
		const mdl = this.tableModel();
		return mdl ? mdl.verticalHeaderItem(row) : null;
	}

	private _tableCellMouseEvt(evt: TableCellMouseEvt): void {
		this.notifyEvt(evt);
	}

	private _sectionMoved(oldVisualIndex: number, newVisualIndex: number): void {
		this.notifyEvt(new DataTableEvt(
			DataTableEvt.SectionMove,
			{
				move: {fromSection: oldVisualIndex, toSection: newVisualIndex},
				orientation: Orientation.Horizontal,
			}));
	}

	private _sortIndicatorChanged(column: number, order: SortOrder): void {
		if (this.sortingEnabled && this.model) {
			this.model.sort(column, order);
			this.notifyEvt(new DataTableSortEvt(column, order));
		}
	}
}

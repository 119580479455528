import React from 'react';

import {bind, isNumber} from '../../../../util';
import {Select} from '../../../components';
import {set} from '../../../../tools';

type Option = {id: number; name: string;}

interface IProps<T extends Option = Option> {
	onChange: (value: number[], name: 'userExclusions') => any;
	options: T[];
	value: number[];
}

export default class UserSelect extends React.Component<IProps, {}> {
	@bind
	changeEvent(event: React.ChangeEvent<HTMLSelectElement>): void {
		const {onChange, options} = this.props;
		const selectedOpIds = new set<number>();
		const opts = event.target.selectedOptions;
		for (let i = 0; i < opts.length; ++i) {
			const val = Number.parseInt(opts[i].value);
			if (isNumber(val)) {
				selectedOpIds.add(val);
			}
		}
		const opIds = new set(options.map(op => op.id));
		onChange(opIds.difference(selectedOpIds).toArray(), 'userExclusions');
	}

	options(): {text: string; value: number;}[] {
		return this.props.options.map(opt => ({text: opt.name, value: opt.id}));
	}

	render(): React.ReactNode {
		return <Select
			className="width--100-percent pb-select--filled"
			multiple={true}
			name="userExclusions"
			onChange={this.changeEvent}
			options={this.options()}
			size={4}
			value={this.value()}/>;
	}

	value(): number[] {
		const {options, value} = this.props;
		const vals = new set(value);
		const rv: number[] = [];
		for (const obj of options) {
			if (!vals.has(obj.id)) {
				rv.push(obj.id);
			}
		}
		return rv;
	}
}

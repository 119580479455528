import {initialState} from './state';
import {BuggyActionTypes} from '../actions/buggy';
import {
	EMPTY_BUGGY,
	ADD_ITEM_TO_BUGGY,
	REMOVE_ITEM_FROM_BUGGY,
	ADD_ITEM_ADDON_TO_BUGGY,
	ADD_ITEM_CHOICE_TO_BUGGY,
	REMOVE_ITEM_ADDON_FROM_BUGGY,
	REMOVE_ITEM_CHOICE_FROM_BUGGY,
} from '../actions/types';

const initial: IBuggyState = initialState('buggy');

export default function (state: IBuggyState = initial, action: BuggyActionTypes): IBuggyState {
	switch (action.type) {
		case ADD_ITEM_CHOICE_TO_BUGGY:
			if (state.choiceIDs.indexOf(action.data.choiceID) >= 0) {
				return state;
			}
			if (!action.data.isDefaultAction && state.interactedItemIDs.indexOf(action.data.itemID) === -1) {
				state.interactedItemIDs = [...state.interactedItemIDs, action.data.itemID];
			}
			return {
				...state,
				choiceIDs: [...state.choiceIDs, action.data.choiceID],
			};
		case REMOVE_ITEM_CHOICE_FROM_BUGGY:
			if (!action.data.isDefaultAction && state.interactedItemIDs.indexOf(action.data.itemID) === -1) {
				state.interactedItemIDs = [...state.interactedItemIDs, action.data.itemID];
			}
			return {
				...state,
				choiceIDs: [...state.choiceIDs.filter(id => (id !== action.data.choiceID))],
			};
		case ADD_ITEM_TO_BUGGY:
			if (state.itemIDs.indexOf(action.data) >= 0) {
				return state;
			}
			return {
				...state,
				itemIDs: [...state.itemIDs, action.data],
			};
		case REMOVE_ITEM_FROM_BUGGY:
			return {
				...state,
				itemIDs: [...state.itemIDs.filter(id => (id !== action.data))],
			};
		case ADD_ITEM_ADDON_TO_BUGGY:
			if (state.addonIDs.indexOf(action.data.addOnID) >= 0) {
				return state;
			}
			return {
				...state,
				addonIDs: [...state.addonIDs, action.data.addOnID],
			};
		case REMOVE_ITEM_ADDON_FROM_BUGGY:
			if (state.addonIDs.indexOf(action.data.addOnID) === -1) {
				return state;
			}
			return {
				...state,
				addonIDs: [...state.addonIDs.filter(addOnID => (addOnID !== action.data.addOnID))],
			};
		case EMPTY_BUGGY:
			return {
				addonIDs: [],
				choiceIDs: [],
				interactedItemIDs: [],
				itemIDs: [],
			};
		default:
			return state;
	}
}

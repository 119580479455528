import {apiService as svc} from '../../services';
import {El, elOpts, ElOpts} from '../../el';
import {list, Url} from '../../tools';
import {TextInput, TextInputEvt} from '../../ui/textinput';
import {AbstractItemModel, ModelIndex} from '../../itemmodel';
import {Evt} from '../../evt';
import {assert, bind, isNumber, numberArraySortKey, numberFormat} from '../../util';
import {TableItem, TableModel} from '../../ui/datatable/model';
import {AlignmentFlag, CheckState, ItemColumnName, ItemDataRole, ItemRole, Orientation} from '../../constants';
import {Variant} from '../../variant';
import {TableEl, TableElOpts, TableElState} from '../../ui/datatable/el';
import {CurrentDecimal} from '../../decimal';
import {timedelta} from '../../datetime';
import {DataTableEvt} from '../../ui/datatable/evt';
import {TableElDelegate, TableElStyleOption} from '../../ui/datatable/delegate';
import {AbstractTableView} from '../../views/abstracttableview';
import {Switch} from '../../ui/switch';
import {ComboBox, ComboBoxOpts} from '../../ui/combobox';

export class CatalogItemListView extends AbstractTableView {
	static UITableName: string = 'item_list';

	private items: list<IItem>;
	private priceGroupComboBox: PriceGroupComboBox | null;
	private priceGroups: list<IPriceGroup>;
	private sizeInput: TextInput | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		super({
			...opts,
			model: new TableModel(0, 0),
			tableElColumnMap: elForColumnMap,
			tableElDelegateColumnMap: delegateForColumnMap,
			tableItemPrototype: ItemTableItem,
		});
		this.items = new list<IItem>();
		this.priceGroupComboBox = null;
		this.priceGroups = new list<IPriceGroup>();
		this.sizeInput = null;
	}

	protected dataTableItem(index: ModelIndex): ItemTableItem | null {
		return <ItemTableItem | null>super.dataTableItem(index);
	}

	protected async dataTableItemClickEvt(evt: DataTableEvt): Promise<void> {
		super.dataTableItemClickEvt(evt);
		const col = this.visibleUiTableColumnAtVisibleIndex(this.currentIndex.column);
		if (col && (col.name === ItemColumnName.Public)) {
			const tableItem = this.dataTableItem(this.currentIndex);
			if (tableItem) {
				const item = this.item(tableItem.itemId);
				if (item) {
					const oldIsPublic = tableItem.checkState() === CheckState.Checked;
					const newIsPublic = !oldIsPublic;
					if (item.isPublic !== newIsPublic) {
						this.replaceItem(
							await this.updateItem(
								{...item, isPublic: newIsPublic}));
					}
				}
			}
		}
	}

	destroy(): void {
		this.items.clear();
		if (this.priceGroupComboBox) {
			this.priceGroupComboBox.offEvt(this.priceGroupComboBoxEvt);
			this.priceGroupComboBox.destroy();
		}
		this.priceGroupComboBox = null;
		this.priceGroups.clear();
		if (this.sizeInput) {
			this.sizeInput.offEvt(this.sizeInputEvt);
			this.sizeInput.destroy();
		}
		this.sizeInput = null;
		super.destroy();
	}

	protected async fetchData(params?: Partial<IPaginatedItemRequest>): Promise<void> {
		this.beginFetchData();
		const rv = await svc.catalog.item.list(this.fetchDataRequestParams(params));
		this.setData(rv.objects);
		this.endFetchData();
	}

	private fetchDataRequestParams(params?: Partial<IPaginatedItemRequest>): Partial<IPaginatedItemRequest> {
		params = params ? {...params} : {};
		if ((params.minSize === undefined) && this.sizeInput) {
			params.minSize = this.sizeInput.value();
		}
		if (!params.priceGroupId && this.priceGroupComboBox) {
			params.priceGroupId = this.priceGroupComboBox.value();
		}
		return params;
	}

	protected async init(model?: AbstractItemModel | null): Promise<void> {
		await super.init(model);
		this.dataTable.setSectionsMovable(true, Orientation.Vertical);
		const ctrlCont = El.div({
			classNames: ['display--flex', 'flex-direction--row', 'align-items--center', 'margin-right--32'],
			parent: El.fromSelector('#id_pb-pre-item-table'),
			placementIndex: 0,
		});
		this.priceGroupComboBox = new PriceGroupComboBox({parent: ctrlCont});
		const sizeCont = El.div({
			classNames: ['display--flex', 'flex-direction--column'],
			parent: ctrlCont,
			styles: [['padding-left', '32px']],
		});
		this.sizeInput = new TextInput({
			labelText: 'Size',
			outlined: true,
			parent: sizeCont,
			type: 'number',
		});
		this.priceGroups = new list<IPriceGroup>(await svc.catalog.priceGroup.list());
		this.priceGroupComboBox.setPriceGroups(this.priceGroups);
		const std = await svc.catalog.priceGroup.standard();
		this.priceGroupComboBox.setValue(String(std.id));
		// AFTER you set the above value. Otherwise we get a change event
		// which triggers another API call.
		this.sizeInput.onEvt(this.sizeInputEvt);
		this.priceGroupComboBox.onEvt(this.priceGroupComboBoxEvt);
		await this.fetchData();
	}

	private item(itemId: number): IItem | null {
		if (isNumber(itemId)) {
			for (const obj of this.items) {
				if (obj.id === itemId) {
					return obj;
				}
			}
		}
		return null;
	}

	private *itemChildren(parentId: number): IterableIterator<IItem> {
		for (const obj of this.items) {
			if ((obj.parentId === parentId) && (obj.role === ItemRole.Child)) {
				yield obj;
			}
		}
	}

	private itemDurationRangeDisplay(item: IItem): string {
		if (item.role === ItemRole.Parent) {
			const deltas: Array<timedelta> = [];
			for (const obj of this.itemChildren(item.id)) {
				if (isNumber(obj.duration)) {
					deltas.push(new timedelta(undefined, obj.duration));
				}
			}
			deltas.sort(timedelta.cmp);
			if (deltas.length === 1) {
				return deltas[0].toString();
			}
			if (deltas.length > 1) {
				const lo = deltas[0];
				const hi = deltas[deltas.length - 1];
				if (lo.eq(hi)) {
					return lo.toString();
				}
				return `${lo} - ${hi}`;
			}
		} else if (isNumber(item.duration)) {
			return (new timedelta(undefined, item.duration)).toString();
		}
		return '';
	}

	private itemPriceRangeDisplay(item: IItem): string {
		if (item.role === ItemRole.Parent) {
			const priceStrings: Array<string> = [];
			for (const obj of this.itemChildren(item.id)) {
				if (obj.price) {
					priceStrings.push(obj.price);
				}
			}
			priceStrings.sort(priceStringSortKey);
			if (priceStrings.length === 1) {
				return `$${numberFormat(priceStrings[0])}`;
			}
			if (priceStrings.length > 1) {
				const lo = priceStrings[0];
				const hi = priceStrings[priceStrings.length - 1];
				if (lo === hi) {
					return `$${numberFormat(lo)}`;
				}
				return `$${numberFormat(lo)} - $${numberFormat(hi)}`;
			}
		} else if (item.price) {
			return `$${numberFormat(item.price)}`;
		}
		return '';
	}

	private itemProxy(proxyId: number): IItem | null {
		for (const item of this.items) {
			if (item.id === proxyId) {
				return item;
			}
		}
		return null;
	}

	private itemSizes(): list<number> {
		const rv = new list<number>();
		for (const obj of this.items) {
			if (isNumber(obj.size)) {
				rv.append(obj.size);
			}
		}
		return rv;
	}

	protected async moveVerticalSection(fromVisualIndex: number, toVisualIndex: number): Promise<void> {
		super.moveVerticalSection(fromVisualIndex, toVisualIndex);
		const fromTblItem = this.dataTableItem(this.model.index(fromVisualIndex, 0));
		const toTblItem = this.dataTableItem(this.model.index(toVisualIndex, 0));
		if (fromTblItem && toTblItem) {
			const fromItem = this.item(fromTblItem.itemId);
			const fromItemIdx = fromItem ?
				this.items.indexOf(fromItem) :
				-1;
			const toItem = this.item(toTblItem.itemId);
			const toItemIdx = toItem ?
				this.items.indexOf(toItem) :
				-1;
			if ((fromItemIdx >= 0) && (fromItemIdx < this.items.size()) && (toItemIdx >= 0) && (toItemIdx < this.items.size())) {
				const rv: Array<{id: number; placement: number;}> = [];
				const items = new list(this.items);
				items.move(fromItemIdx, toItemIdx);
				let i = 1;
				for (const obj of items) {
					if (obj.role === ItemRole.Child) {
						rv.push({id: obj.id, placement: obj.placement});
					} else {
						rv.push({id: obj.id, placement: i});
						++i;
					}
				}
				await this.updatePlacement(rv);
				await this.fetchData();
			} else {
				console.log('One or more index out of range: %s, %s', fromItemIdx, toItemIdx);
			}
		}
	}

	@bind
	private priceGroupComboBoxEvt(evt: Evt): void {
		if (evt.type() === Evt.Change) {
			this.fetchData();
		}
	}

	private replaceItem(item: IItem): void {
		let replaced = false;
		for (let i = 0; i < this.items.size(); ++i) {
			if (this.items.at(i).id === item.id) {
				this.items.replace(i, item);
				replaced = true;
				break;
			}
		}
		if (replaced) {
			this.setData(this.items);
		}
	}

	private setData(items: Array<IItem> | list<IItem>): void {
		this.beginSetData();
		this.items = Array.isArray(items) ?
			new list(items) :
			items;
		const columnCount = this.dataTable.columnCount();
		const visibleItems = this.visibleItems();
		const rowCount = visibleItems.size();
		this.dataTable.clearContents();
		this.dataTable.setRowCount(rowCount);
		const visibleUserUiColumns = this.visibleUserUiTableColumns();
		for (let row = 0; row < rowCount; ++row) {
			const item = visibleItems.at(row);
			for (let column = 0; column < columnCount; ++column) {
				const uiCol = this.uiTableColumn(visibleUserUiColumns[column].uiTableColumnId);
				const itemData: Array<[ItemDataRole, Variant]> = [];
				let useUrl = true;
				switch (uiCol ? uiCol.name : '') {
					case ItemColumnName.Name:
						itemData.push([ItemDataRole.DisplayRole, new Variant(item.name)]);
						break;
					case ItemColumnName.Price: {
						const objProxy = (item.role === ItemRole.Parent) ?
							isNumber(item.proxyId) ?
								this.itemProxy(item.proxyId) :
								null :
							item;
						let v: Variant;
						if (objProxy && objProxy.price) {
							v = new Variant(new CurrentDecimal(objProxy.price, 2));
						} else {
							v = new Variant(null);
						}
						itemData.push(
							[ItemDataRole.DisplayRole, v],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					}
					case ItemColumnName.Public: {
						const checkState = item.isPublic ?
							CheckState.Checked :
							CheckState.Unchecked;
						itemData.push(
							[ItemDataRole.CheckStateRole, new Variant(checkState)],
							[ItemDataRole.DisplayRole, new Variant(checkState)]);
						useUrl = false;
						break;
					}
					case ItemColumnName.Size: {
						const objProxy = (item.role === ItemRole.Parent) ?
							isNumber(item.proxyId) ?
								this.itemProxy(item.proxyId) :
								null :
							item;
						let v: Variant;
						if (objProxy && isNumber(objProxy.size)) {
							v = new Variant(objProxy.size);
						} else {
							v = new Variant(null);
						}
						itemData.push(
							[ItemDataRole.DisplayRole, v],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					}
					case ItemColumnName.Color:
						itemData.push(
							[ItemDataRole.BackgroundRole, new Variant(item.color)],
							[ItemDataRole.DisplayRole, new Variant(item.color)]);
						break;
					case ItemColumnName.Description:
						itemData.push([ItemDataRole.DisplayRole, new Variant(item.description)]);
						break;
					case ItemColumnName.Duration: {
						const objProxy = (item.role === ItemRole.Parent) ?
							isNumber(item.proxyId) ?
								this.itemProxy(item.proxyId) :
								null :
							item;
						let v: Variant;
						if (objProxy && isNumber(objProxy.duration)) {
							v = new Variant(new timedelta(undefined, objProxy.duration));
						} else {
							v = new Variant(null);
						}
						itemData.push(
							[ItemDataRole.DisplayRole, v],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					}
					case ItemColumnName.Exclusive: {
						const checkState = item.exclusive ?
							CheckState.Checked :
							CheckState.Unchecked;
						itemData.push(
							[ItemDataRole.CheckStateRole, new Variant(checkState)],
							[ItemDataRole.DisplayRole, new Variant(checkState)]);
						break;
					}
					case ItemColumnName.Icon:
						itemData.push(
							[ItemDataRole.DecorationRole, new Variant(item.icon)],
							[ItemDataRole.DisplayRole, new Variant(item.icon)]);
						break;
					case ItemColumnName.ChoiceLimit:
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(item.maxChoices)],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					case ItemColumnName.EnforceChoiceLimit: {
						const checkState = item.limitChoiceCount ?
							CheckState.Checked :
							CheckState.Unchecked;
						itemData.push(
							[ItemDataRole.CheckStateRole, new Variant(checkState)],
							[ItemDataRole.DisplayRole, new Variant(checkState)]);
						break;
					}
					case ItemColumnName.Ordering:
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(item.placement)],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					case ItemColumnName.PriceRange:
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(this.itemPriceRangeDisplay(item))],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
					case ItemColumnName.DurationRange:
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(this.itemDurationRangeDisplay(item))],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						break;
				}
				if (useUrl) {
					const url = `${window.location.protocol}//${window.location.host}${item.absoluteUrl}`;
					itemData.push([ItemDataRole.UserRole, new Variant(new Url(url))]);
				}
				const tableItem = new ItemTableItem();
				tableItem.itemId = item.id;
				for (const [role, val] of itemData) {
					tableItem.setData(role, val);
				}
				this.dataTable.setItem(row, column, tableItem);
			}
		}
		this.syncSizeInput();
		this.endSetData();
	}

	@bind
	private sizeInputEvt(evt: Evt): void {
		if ((evt.type() === Evt.Change) && (evt instanceof TextInputEvt)) {
			const t = evt.text().trim();
			if (t.length > 0) {
				const tt = t.split('.')[0].replace(/[^\d]/g, '');
				const num = Number.parseInt(tt);
				if (isNumber(num)) {
					this.fetchData({minSize: num});
				} else {
					console.log('Invalid input for integer: %s', t);
				}
			}
		}
	}

	private syncSizeInput(): void {
		if (this.sizeInput && !this.sizeInput.isDisabled()) {
			let min = 0;
			let max = 0;
			const sizes = this.itemSizes();
			if (sizes.size() > 1) {
				const arr = sizes.toArray();
				arr.sort(numberArraySortKey());
				min = arr[0];
				max = arr[arr.length - 1];
			} else if (sizes.size() === 1) {
				min = sizes.first();
				max = min;
			}
			this.sizeInput.setMax(max);
			this.sizeInput.setMin(min);
		}
	}

	private async updateItem(item: IItem): Promise<IItem> {
		return await svc.catalog.item.update(item.id, item);
	}

	private async updatePlacement(data: Array<{id: number; placement: number;}>): Promise<void> {
		return await svc.catalog.item.setPlacement(data);
	}

	protected userUiTableChanged(): void {
		super.userUiTableChanged();
		if (this.items.size() > 0) {
			this.setData(this.items);
		}
	}

	private visibleItems(): list<IItem> {
		return this.items.filter(item => (item.role !== ItemRole.Child));
	}
}

class SwitchTableCell extends TableEl {
	private switch: Switch | null;

	constructor(opts: Partial<TableElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableElOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableElOpts>, parent?: El | null);
	constructor(opts?: Partial<TableElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<TableElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<TableElOpts>(a, b, c);
		super(opts);
		this.switch = new Switch(this);
	}

	checkState(): CheckState {
		if (this.switch) {
			return this.switch.checkState();
		}
		return super.checkState();
	}

	destroy(): void {
		if (this.switch) {
			this.switch.destroy();
		}
		this.switch = null;
		super.destroy();
	}

	protected enterState(state: TableElState): void {
		switch (state) {
			case TableElState.Anchor:
			case TableElState.Checkbox:
				return;
		}
		super.enterState(state);
	}

	protected exitState(state: TableElState): void {
		switch (state) {
			case TableElState.Anchor:
			case TableElState.Checkbox:
				return;
		}
		super.exitState(state);
	}

	setCheckState(state: CheckState): void {
		if (this.switch) {
			this.switch.setCheckState(state);
		}
	}
}

class CheckIconTableCell extends TableEl {
	setCheckState(state: CheckState): void {
		const icon = (state === CheckState.Checked) ?
			'check' :
			'';
		this.setIcon(icon);
	}
}

class PriceGroupComboBox extends ComboBox {
	constructor(opts: Partial<ComboBoxOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts>, parent?: El | null);
	constructor(opts?: Partial<ComboBoxOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ComboBoxOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ComboBoxOpts>(a, b, c);
		if (opts.labelText === undefined) {
			opts.labelText = 'Price Group';
		}
		if (opts.outlined === undefined) {
			opts.outlined = true;
		}
		super(opts);
	}

	setPriceGroups(objs: list<IPriceGroup>): void {
		for (const obj of objs) {
			this.addItem({
				listItemOptions: {text: obj.name},
				value: obj.id,
			});
		}
	}
}

class NoTextItemDelegate extends TableElDelegate {
	paint(option: TableElStyleOption, index: ModelIndex): void {
		assert(index.isValid());
		if (option.el) {
			this.initStyleOption(option, index);
			option.text = '';
			option.el.paint(option);
		}
	}
}

const delegateForColumnMap = new Map<string, typeof TableElDelegate>([
	[ItemColumnName.Color, NoTextItemDelegate],
	[ItemColumnName.Public, NoTextItemDelegate],
]);

const elForColumnMap = new Map<string, typeof TableEl>([
	[ItemColumnName.EnforceChoiceLimit, CheckIconTableCell],
	[ItemColumnName.Exclusive, CheckIconTableCell],
	[ItemColumnName.Public, SwitchTableCell],
]);

function priceStringSortKey(a: string, b: string): number {
	const aN = Number.parseFloat(a);
	if (!isNumber(aN)) {
		return -1;
	}
	const bN = Number.parseFloat(b);
	if (!isNumber(bN)) {
		return 1;
	}
	if (aN > bN) {
		return 1;
	}
	if (aN < bN) {
		return -1;
	}
	return 0;
}

export class ItemTableItem extends TableItem {
	itemId: number = 0;

	debugData(): string {
		return `${super.debugData()}+${this.itemId}`;
	}

	destroy(): void {
		this.itemId = -1;
		super.destroy();
	}
}


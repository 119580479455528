import React, {PropsWithChildren} from 'react';
import {isNumber} from '../../util';

interface IProps {
	z?: 0;
}

export default function Card(props: PropsWithChildren<IProps>) {
	const className = isNumber(props.z) ?
		'base-card--z0' :
		'base-card';
	return (
		<section className={className}>
			{props.children}
		</section>
	);
}

import React, {PropsWithChildren} from 'react';

import {bind, numberFormat} from '../../../../util';
import {Icon, IconButton} from '../../../components';
import {itemPriceThing} from '../../../../util/price';
import {
	Label,
	CheckBox,
	FormField,
	Background,
	InputContainer,
} from './checktable';
import {COLOR_PB_DARK_BLUE} from '../../../../constants';

export function AddOnInput({checked, addOn, onChange, itemID}: PropsWithChildren<{itemID: number; checked: boolean; addOn: ICatalogItem; onChange: (value: string | number, checked: boolean) => any;}>) {
	const id = `item-${itemID}-addOn-${addOn.id}`;
	const [price, altPrice] = itemPriceThing(addOn, []);
	return (
		<FormField>
			<InputContainer>
				<CheckBox
					checked={checked}
					disabled={false}
					id={id}
					onChange={onChange}
					title={addOn.name}
					value={addOn.id}/>
				<Background/>
			</InputContainer>
			<Label className="display--flex flex-direction--column justify-content--center align-items--flex-start" htmlFor={id}>
				{addOn.name}
				<div className="display--flex flex-direction--row padding-top--4">
					{altPrice ? <span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--light-grayish">{altPrice}</span> : null}
					<span className="color--white">{price}</span>
				</div>
			</Label>
		</FormField>
	);
}

interface IListItemProps {
	atSize?: number | string;
	checked?: boolean;
	childItems?: ICatalogItem[];
	className?: string;
	item: ICatalogItem;
	onChange: (id: number, checked: boolean) => any;
	twoLine?: boolean;
}

interface IListItemState {
	showMoreInfo: boolean;
}

export class CatalogListItem extends React.Component<IListItemProps, IListItemState> {
	constructor(props: IListItemProps) {
		super(props);
		this.state = {
			showMoreInfo: false,
		};
	}

	className(): string {
		const {
			checked,
			className,
		} = this.props;
		const parts = [
			'mdc-list-item',
			'pb-catalog-item',
		];
		if (className) {
			parts.push(className);
		}
		if (checked) {
			parts.push('pb-catalog-item--selected');
		}
		return parts.join(' ');
	}

	@bind
	click(): void {
		const {checked, item, onChange} = this.props;
		onChange(item.id, !checked);
	}

	@bind
	infoButtonClick(event: React.MouseEvent): void {
		event.stopPropagation();
		this.toggleShowInfo();
	}

	render(): React.ReactNode {
		const {atSize, checked, item, childItems, twoLine} = this.props;
		const {showMoreInfo} = this.state;
		const [price, altPrice] = itemPriceThing(item, (childItems === undefined) ? [] : childItems);
		const nfo =
			(item.longDescription.length > 0)
				? <IconButton onClick={this.infoButtonClick} icon={showMoreInfo ? 'help' : 'help_outline'} smaller={true} style={{marginLeft: '8px'}}/>
				: null;

		return (
			<React.Fragment>
				<li className={this.className()} onClick={this.click} style={this.style()}>
					<ItemIcon checked={checked} name={item.icon}/>
					{
						twoLine ?
							<React.Fragment>
								<div className="mdc-list-item__text">
									<span className="mdc-list-item__primary-text">
										{item.name}{(atSize === undefined) ? null : <AtSize size={atSize}/>}
									</span>
									<Price isSecondaryText={twoLine}>
										{altPrice ? <AltPrice>{altPrice}</AltPrice> : null}
										{price}
									</Price>
								</div>
								{nfo}
							</React.Fragment> :
							<React.Fragment>
								<span className="mdc-list-item__text">
									{item.name}{(atSize === undefined) ? null : <AtSize size={atSize}/>}
								</span>
								{nfo}
								<Price isSecondaryText={twoLine}>
									{altPrice ? <AltPrice>{altPrice}</AltPrice> : null}
									{price}
								</Price>
							</React.Fragment>
					}
				</li>
				{
					showMoreInfo ?
						<li className="mdc-list-item pb-catalog-item" style={{backgroundColor: COLOR_PB_DARK_BLUE, color: 'white', cursor: 'default', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '48px', borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: 0, height: 'auto', borderTop: '1px solid #304FA5'}}>
							{item.longDescription}
						</li> :
						null
				}
			</React.Fragment>
		);
	}

	style(): React.CSSProperties {
		const {item} = this.props;
		const rv: React.CSSProperties = {
			backgroundColor: item.color || COLOR_PB_DARK_BLUE,
		};
		if (this.state.showMoreInfo) {
			rv.borderBottomLeftRadius = 0;
			rv.borderBottomRightRadius = 0;
		}
		return rv;
	}

	toggleShowInfo(): void {
		this.setState({
			showMoreInfo: !this.state.showMoreInfo,
		});
	}
}

function ItemIcon({checked, name}: PropsWithChildren<{checked?: boolean; name: string;}>) {
	return (
		<Icon
			className="mdc-list-item__graphic pb-color--white pb-margin-right--8"
			name={checked ? 'check_circle' : name}/>
	);
}

function AltPrice({children}: PropsWithChildren<{}>) {
	return (
		<span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--light-grayish">
            {children}
        </span>
	);
}

function Price({children, isSecondaryText}: PropsWithChildren<{isSecondaryText?: boolean;}>) {
	return (
		<span className={`${isSecondaryText ? 'mdc-list-item__secondary-text' : 'mdc-list-item__meta'} pb-catalog-item__secondary`}>
            {children}
        </span>
	);
}

function AtSize({size}: {size: number | string;}) {
	return (
		<span className="catalog-item-at-size">@ {numberFormat(size)} ft<sup>2</sup></span>
	);
}

import {ResourceService} from './resource';

export class ChoiceService extends ResourceService {
	static uri: string = '/api/group/catalog/item-choices';

	async create(data: IChoice, cfg?: ResourceDataRequestConfig): Promise<IChoice> {
		return (await this.POST<IChoice>(this.url(), data, cfg)).data;
	}

	async delete(id: number, cfg?: ResourceDataRequestConfig): Promise<void> {
		return (await this.DELETE<void>(this.url(id), undefined, cfg)).data;
	}

	async get(id: number, params?: any, cfg?: ResourceQueryRequestConfig): Promise<IChoice> {
		return (await this.GET<IChoice>(this.url(id), params, cfg)).data;
	}

	async list(params?: any, cfg?: ResourceQueryRequestConfig): Promise<IChoice[]> {
		return (await this.GET<IChoice[]>(this.url(), params, cfg)).data;
	}

	async update(id: number, data: IChoice, cfg?: ResourceDataRequestConfig): Promise<IChoice> {
		return (await this.PUT<IChoice>(this.url(id), data, cfg)).data;
	}
}

import {Form} from '../ui/form';
import {TextInput} from '../ui/textinput';
import {ButtonRole, StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {bind, isNumber} from '../util';
import {Dialog} from '../ui/dialog';
import {El} from '../el';
import {Button} from '../ui/button';
import {VBoxLayout} from '../ui/layout';

class ObjectForm extends Form {
	labelInput: TextInput;
	numberInput: TextInput;

	constructor(parent: El | null = null) {
		super(parent);
		const box = new VBoxLayout(this);
		box.setSpacing(16);
		this.numberInput = new TextInput({
			inputId: 'id_phone-number-input-label',
			labelText: 'Enter number',
			leadingIcon: 'phone',
			name: 'number',
			required: true,
		});
		box.addEl(this.numberInput);
		this.labelInput = new TextInput({
			inputId: 'id_phone-number-label-label',
			labelText: 'Label',
			leadingIcon: 'label',
			name: 'label',
		});
		box.addEl(this.labelInput);
		this.setMethod('POST');
	}

	initCreate(): void {
		this.onSubmit();
		this.setAction(window.pburls.account.phoneNumber.list);
		this.numberInput.setValue('');
		this.labelInput.setValue('');
	}

	initDelete(number: string, label: string, cb?: (() => any) | null): void {
		this.onSubmit(cb);
		this.setAction();
		this.numberInput.setValue(number);
		this.labelInput.setValue(label);
	}

	initEdit(number: string, label: string, cb?: (() => any) | null): void {
		this.onSubmit(cb);
		this.setAction();
		this.numberInput.setValue(number);
		this.labelInput.setValue(label);
	}
}

class ObjectDialog extends Dialog {
	_form: ObjectForm | null;

	constructor(parent: El | null = null) {
		super(parent);
		this._form = null;
	}

	protected buttonClicked(button: Button): void {
		if (this._form && this.buttonRole(button) === ButtonRole.AcceptRole && !this._form.isValid()) {
			return;
		}
		super.buttonClicked(button);
	}

	form(): ObjectForm | null {
		return this._form;
	}

	openCreate(): void {
		this.setTitle('Add number');
		this.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const form = this._form;
		if (form) {
			form.initCreate();
			form.show();
			const saveBtn = this.button(StandardButton.Save);
			if (saveBtn) {
				saveBtn.setRaised(true);
				saveBtn.setType('submit');
				saveBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	openDelete(number: string, label: string, cb?: (() => any) | null): void {
		const lbl = label ? ` (${label})` : '';
		this.setTitle(`Delete ${number}${lbl}?`);
		this.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.button(StandardButton.No);
		if (noBtn) {
			noBtn.setRaised(true);
			// this.setDefaultButton(noBtn);
		}
		const form = this._form;
		if (form) {
			form.hide();
			form.initDelete(number, label, cb);
			const yesBtn = this.button(StandardButton.Yes);
			if (yesBtn) {
				yesBtn.setType('submit');
				yesBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	openEdit(number: string, label: string, cb?: (() => any) | null): void {
		this.setTitle('Edit number');
		this.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const form = this._form;
		if (form) {
			form.initEdit(number, label, cb);
			form.show();
			const saveBtn = this.button(StandardButton.Save);
			if (saveBtn) {
				saveBtn.setRaised(true);
				saveBtn.setType('submit');
				saveBtn.setAttribute('form', form.id());
			}
		}
		this.open();
	}

	setForm(form: ObjectForm | null): void {
		if (form === this._form) {
			return;
		}
		this._form = form;
		this.setContent(this._form && this._form);
	}
}

class DocumentObject {
	primaryText: El | null;
	root: El;
	secondaryText: El | null;

	constructor(objectID: number) {
		this.root = El.fromSelector(`[data-id="${objectID}"].pb-list-item`);
		this.primaryText = this.root.querySelector('.mdc-list-item__primary-text');
		this.secondaryText = this.root.querySelector('.mdc-list-item__secondary-text');
	}

	remove(): void {
		this.root.remove();
	}

	setPrimaryText(value: string): void {
		if (this.primaryText) {
			this.primaryText.setText(value);
		}
	}

	setSecondaryText(value: string): void {
		if (!this.secondaryText) {
			return;
		}
		this.secondaryText.clear();
		if (value) {
			const icon = new El({
				classNames: ['material-icons', 'font-size--16px', 'vertical-align--middle', 'padding-right--8'],
				parent: this.secondaryText,
				tagName: 'i',
			});
			icon.setText('label');
		}
		this.secondaryText.setText(value);
	}
}

class Phone {
	static CREATE_CTA_SELECTOR = '#object-create-button-id';
	static DELETE_SELECTOR = 'button[name="delete"]';
	static OBJECT_LIST_SELECTOR = '.pb-list';
	static OBJECT_SELECTOR = '[data-id].pb-list-item';

	form: ObjectForm;
	_objectID: number | null;
	_dia: ObjectDialog;

	constructor() {
		this.form = new ObjectForm();
		this._objectID = null;
		this._dia = new ObjectDialog();
	}

	@bind
	createClickEvent(): void {
		this.openCreateDialog();
	}

	@bind
	async deleteClickEvent(event: Event): Promise<void> {
		event.stopPropagation();
		const elem = El.fromEvent(event).closestMatchingAncestor(Phone.OBJECT_SELECTOR);
		if (elem) {
			const id = Number(elem.attribute('data-id'));
			if (isNumber(id)) {
				this._objectID = id;
				const data = await this.objectData();
				this.openDeleteDialog(data);
				return;
			}
		}
		console.log('Phone::deleteClickEvent undefined behavior stemming from document event');
	}

	@bind
	async deleteFormSubmitEvent(): Promise<void> {
		const data = await this.objectData();
		await svc.account.phoneNumber.delete(data.id);
		this.removeFromDocument(data.id);
		this._dia.accept();
		this._objectID = null;
	}

	documentEmpty(): boolean {
		const list = document.querySelector(Phone.OBJECT_LIST_SELECTOR);
		return list ? list.querySelectorAll(Phone.OBJECT_SELECTOR).length < 1 : true;
	}

	@bind
	async editFormSubmitEvent(): Promise<void> {
		const data = await this.objectData();
		data.number = this.form.numberInput.value();
		data.label = this.form.labelInput.value();
		this.syncDocument(await svc.account.phoneNumber.update(data.id, data));
		this._dia.accept();
		this._objectID = null;
	}

	init(): void {
		this.form.setId('id_phone-form');
		this._dia.setForm(this.form);
		let elem: Element | null = document.querySelector(Phone.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this.createClickEvent);
		}
		document
			.querySelectorAll(Phone.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectClickEvent));
		document
			.querySelectorAll(Phone.DELETE_SELECTOR)
			.forEach(el => el.addEventListener('click', this.deleteClickEvent));
		if (this.documentEmpty()) {
			this.openCreateDialog();
		}
	}

	@bind
	async objectClickEvent(event: Event): Promise<void> {
		const elem = El.fromEvent(event).closestMatchingAncestor(Phone.OBJECT_SELECTOR);
		if (elem) {
			const id = Number(elem.attribute('data-id'));
			if (isNumber(id)) {
				this._objectID = id;
				const data = await this.objectData();
				this.openEditDialog(data);
				return;
			}
		}
		console.log('Phone::objectClickEvent undefined behavior stemming from document event');
	}

	objectData(): Promise<IPhoneNumber> {
		return svc.account.phoneNumber.get(this.objectID());
	}

	objectID(): number {
		if (isNumber(this._objectID)) {
			return this._objectID;
		}
		throw new Error('Phone::objectID object ID is null');
	}

	openCreateDialog(): void {
		this._dia.openCreate();
	}

	openDeleteDialog(data: IPhoneNumber): void {
		this._dia.openDelete(data.number, data.label, this.deleteFormSubmitEvent);
	}

	openEditDialog(data: IPhoneNumber): void {
		this._dia.openEdit(data.number, data.label, this.editFormSubmitEvent);
	}

	removeFromDocument(objectID: number): void {
		const docObj = new DocumentObject(objectID);
		docObj.remove();
		const list = document.querySelector(Phone.OBJECT_LIST_SELECTOR);
		if (list) {
			if (list.querySelectorAll(Phone.OBJECT_SELECTOR).length < 1) {
				list.remove();
			}
		}
	}

	syncDocument(data: IPhoneNumber): void {
		const docObj = new DocumentObject(data.id);
		docObj.setPrimaryText(data.number);
		docObj.setSecondaryText(data.label);
	}
}

export function init(): void {
	const comp = new Phone();
	comp.init();
}

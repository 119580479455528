import React from 'react';

import {cssClassName} from '../../../../util';

interface IProps {
	className?: string;
	onClick: (event: React.MouseEvent) => any;
	text: string;
}

export default class CTA extends React.Component<IProps, {}> {
	render(): React.ReactNode {
		const {children, className, onClick, text} = this.props;
		return (
			<div className={cssClassName('pb-cta', className)}>
				<button className="mdc-button" onClick={onClick} type="button">
					<span className="mdc-button__ripple"/>
					<span className="mdc-button__label">{text}</span>
				</button>
				{children}
			</div>
		);
	}
}

import {setError} from './error';
import {apiService as svc} from '../../services';
import {
	RECEIVE_ACCESS_OPTIONS,
	RECEIVE_CLIENT_USERS_DATA,
	RECEIVE_GROUP_PRODUCER_MARKET_LIST,
	RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST, RECEIVE_OCCUPANCY_OPTIONS,
} from './types';

export function groupProducerBusinessHoursList(): FuncAction<Promise<ReceiveGroupProducerBusinessHourListAction>, ReceiveGroupProducerBusinessHourListAction> {
	return function (dispatch) {
		return svc.group.producer.hours()
			.then(data => dispatch(receiveGroupProducerBusinessHoursList(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function clientUsersData(): FuncAction<Promise<ReceiveClientUsersAction>, ReceiveClientUsersAction> {
	return function (dispatch) {
		return svc.group.client.user.list()
			.then(data => dispatch(receiveClientUsersData(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function groupProducerMarketList(): FuncAction<Promise<ReceiveGroupProducerMarketListAction>, ReceiveGroupProducerMarketListAction> {
	return function (dispatch) {
		return svc.group.producer.market.list()
			.then(data => dispatch(receiveGroupProducerMarketList(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function accessOptions(): FuncAction<Promise<ReceiveAccessOptionsAction>, ReceiveAccessOptionsAction> {
	return function (dispatch) {
		return svc.etc.accessOptions.list()
			.then(data => dispatch(receiveAccessOptions(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function occupancyOptions(): FuncAction<Promise<ReceiveOccupancyOptionsAction>, ReceiveOccupancyOptionsAction> {
	return function (dispatch) {
		return svc.etc.occupancyOption.list()
			.then(data => dispatch(receiveOccupancyOptions(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function receiveGroupProducerBusinessHoursList(data: IBusinessHours[]): ReceiveGroupProducerBusinessHourListAction {
	return {
		data,
		type: RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST,
	};
}

export function receiveClientUsersData(data: IUser[]): ReceiveClientUsersAction {
	return {
		data,
		type: RECEIVE_CLIENT_USERS_DATA,
	};
}

export function receiveGroupProducerMarketList(data: IMarket[]): ReceiveGroupProducerMarketListAction {
	return {
		data,
		type: RECEIVE_GROUP_PRODUCER_MARKET_LIST,
	};
}

export function receiveAccessOptions(data: IAccessOption[]): ReceiveAccessOptionsAction {
	return {
		data,
		type: RECEIVE_ACCESS_OPTIONS,
	};
}

export function receiveOccupancyOptions(data: IOccupancyOption[]): ReceiveOccupancyOptionsAction {
	return {
		data,
		type: RECEIVE_OCCUPANCY_OPTIONS,
	};
}

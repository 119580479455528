import React, {PropsWithChildren} from 'react';

// import Segment from './segment';
import {padStart, to12Hour, toPeriod} from '../../../util';

interface IProps {
	onClick: (index: number) => any;
	segments: ITime[];
	yOffset: number;
}

type Props = PropsWithChildren<IProps>;

export default class SegmentSelect extends React.Component<Props, {}> {
	primaryText(seg: ITime): string {
		return `${to12Hour(seg.hour)}:${padStart(seg.minute, 2, '0')}`;
	}

	render(): React.ReactNode {
		const style = {top: this.topPx()};
		return (
			<div className="pb-timeline-segment-select" style={style}>
				{this.props.segments.map((seg, idx) =>
					<Segment
						key={seg.isoformat}
						secondaryText={this.secondaryText(seg)}
						primaryText={this.primaryText(seg)}
						onClick={this.segmentClicked.bind(this, idx)}/>)}
			</div>
		);
	}

	secondaryText(seg: ITime): string {
		return toPeriod(seg.hour);
	}

	segmentClicked(index: number): void {
		this.props.onClick(index);
	}

	topPx(): string {
		return `${this.props.yOffset}px`;
	}
}

function Segment({onClick, primaryText, secondaryText}: {onClick: (event: React.MouseEvent) => any; primaryText: string; secondaryText: string;}) {
	return (
		<button className="mdc-button pb-timeline-segment display--block" onClick={onClick} type="button">
			<span className="mdc-button__ripple"/>
			{primaryText ? <span className="mdc-button__label">{primaryText}</span> : null}
			{secondaryText ? <span className="padding-left--4 font-weight--300">{secondaryText}</span> : null}
		</button>
	);
}

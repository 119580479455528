import React from 'react';

import {Slider} from '../../../components';
import DenseTextInput from './densetextinput';
import {bind, isNumber, numberFormat} from '../../../../util';

interface IProps {
	onChange: (value: number | null, name: 'size') => any;
	renderTextInput?: boolean;
	value: number | null;
}

export default class SizeInput extends React.Component<IProps, IState> {
	static Name: 'size' = 'size';

	name(): 'size' {
		return SizeInput.Name;
	}

	notify(value: number): void {
		const {onChange} = this.props;
		onChange(isNumber(value) ? value : null, this.name());
	}

	render(): React.ReactNode {
		const {renderTextInput, value} = this.props;
		const val = isNumber(value) ? value : 0;
		if (renderTextInput) {
			return <DenseTextInput
				helpText="Sq ft"
				label="Size"
				min={0}
				name={this.name()}
				onChange={this.textInputChanged}
				persistHelpText={true}
				step={1}
				type="number"
				value={val}/>;
		}
		return (
			<React.Fragment>
				<Slider min={0} max={15000} onInput={this.sliderChanged} step={500} value={val}/>
				<div className="display--flex flex-direction--row">
					<div className="pb-catalog-item-form__subsection-input-label">
						<div>
							{numberFormat(val)}
						</div>
						<div>Sq ft</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	@bind
	sliderChanged(value: number): void {
		this.notify(value);
	}

	@bind
	textInputChanged(event: React.ChangeEvent): void {
		this.notify(Number.parseInt((event as React.ChangeEvent<HTMLInputElement>).target.value));
	}
}

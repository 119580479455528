import {list, Point} from './tools';
import {KeyboardModifier, MouseButton} from './constants';
import {El} from './el';

export enum EvtType {
	None,
	ActionRequest,
	Cancel,
	Change,
	ChildAdded,
	ChildRemoved,
	Close,
	ContextMenu,
	Delete,
	Destroy,
	Edit,
	Input,
	KeyPress,
	KeyRelease,
	MouseButtonClick,
	MouseButtonDoubleClick,
	MouseButtonPress,
	MouseButtonRelease,
	Move,
	Save,
	Select,
	StateChange,
	Submit,
}

export class Evt {
	static ActionRequest: EvtType.ActionRequest = EvtType.ActionRequest;
	static Cancel: EvtType.Cancel = EvtType.Cancel;
	static Change: EvtType.Change = EvtType.Change;
	static ChildAdded: EvtType.ChildAdded = EvtType.ChildAdded;
	static ChildRemoved: EvtType.ChildRemoved = EvtType.ChildRemoved;
	static Close: EvtType.Close = EvtType.Close;
	static ContextMenu: EvtType.ContextMenu = EvtType.ContextMenu;
	static Delete: EvtType.Delete = EvtType.Delete;
	static Destroy: EvtType.Destroy = EvtType.Destroy;
	static Edit: EvtType.Edit = EvtType.Edit;
	static Input: EvtType.Input = EvtType.Input;
	static KeyPress: EvtType.KeyPress = EvtType.KeyPress;
	static KeyRelease: EvtType.KeyRelease = EvtType.KeyRelease;
	static MouseButtonClick: EvtType.MouseButtonClick = EvtType.MouseButtonClick;
	static MouseButtonDoubleClick: EvtType.MouseButtonDoubleClick = EvtType.MouseButtonDoubleClick;
	static MouseButtonPress: EvtType.MouseButtonPress = EvtType.MouseButtonPress;
	static MouseButtonRelease: EvtType.MouseButtonRelease = EvtType.MouseButtonRelease;
	static Move: EvtType.Move = EvtType.Move;
	static None: EvtType.None = EvtType.None;
	static Save: EvtType.Save = EvtType.Save;
	static Select: EvtType.Select = EvtType.Select;
	static StateChange: EvtType.StateChange = EvtType.StateChange;
	static Submit: EvtType.Submit = EvtType.Submit;

	private accepted: boolean;
	private typ: number;

	constructor(type: number = EvtType.None) {
		this.accepted = true;
		this.typ = type;
	}

	accept(): void {
		this.accepted = true;
	}

	ignore(): void {
		this.accepted = false;
	}

	isAccepted(): boolean {
		return this.accepted;
	}

	setAccepted(accepted: boolean): void {
		this.accepted = accepted;
	}

	toString(): string {
		const ts = EvtType[this.typ];
		const tt = (ts === undefined) ? String(this.typ) : ts;
		return `${this.constructor.name}(${tt})`;
	}

	type(): number {
		return this.typ;
	}
}

export class ElEvt extends Evt {
	private e: El | null;

	constructor(type: number, el: El | null) {
		super(type);
		this.e = el;
	}

	el(): El | null {
		return this.e;
	}
}

export class InputEvt extends Evt {
	private mods: KeyboardModifier;

	constructor(type: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type);
		this.mods = modifiers;
	}

	modifiers(): KeyboardModifier {
		return this.mods;
	}
}

export class KeyboardEvt extends InputEvt {
	static fromEvent(event: KeyboardEventish): KeyboardEvt {
		let type: EvtType | undefined = keyboardEventTypeMap.get(event.type);
		if (type === undefined) {
			console.log('Unsupported KeyboardEvent::type "%s"', event.type);
			type = EvtType.None;
		}
		return new this(
			type,
			event.key,
			translateKeyboardModifier(event));
	}

	private k: string;

	constructor(type: number, key: string, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, modifiers);
		this.k = key;
	}

	key(): string {
		return this.k;
	}
}

interface IMouseEvtParts {
	button: MouseButton;
	buttons: number;
	modifiers: KeyboardModifier;
	pos: Point;
	type: EvtType;
}

export class MouseEvt extends InputEvt {
	static fromEvent(event?: MouseEventish): MouseEvt {
		const parts = this.fromEventParts(event);
		return new this(
			parts.type,
			parts.pos,
			parts.button,
			parts.buttons,
			parts.modifiers);
	}

	static fromEventParts(event?: MouseEventish): IMouseEvtParts {
		let type: EvtType | undefined = event ? mouseEventTypeMap.get(event.type) : undefined;
		if (type === undefined) {
			console.log('Unsupported MouseEvent::type: "%s"', event ? event.type : '<No Event Given>');
			type = EvtType.None;
		}
		let button: MouseButton | undefined = event ? mouseBtnMap.get(event.button) : undefined;
		if (button === undefined) {
			console.log('Unsupported MouseEvent::button %s', event ? event.button : '<No Event Given>');
			button = MouseButton.NoButton;
		}
		return {
			button,
			buttons: event ? event.buttons : -1,
			modifiers: event ? translateKeyboardModifier(event) : 0,
			pos: event ? new Point(event.clientX, event.clientY) : new Point(),
			type,
		};
	}

	private btn: MouseButton;
	private btns: number;
	private cPos: Point;

	constructor(type: number, clientPos: Point, button: MouseButton, buttons: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, modifiers);
		this.btn = button;
		this.btns = buttons;
		this.cPos = clientPos;
	}

	button(): MouseButton {
		return this.btn;
	}

	buttons(): number {
		return this.btns;
	}

	pos(): Point {
		return this.cPos;
	}

	x(): number {
		return this.cPos.x();
	}

	y(): number {
		return this.cPos.y();
	}
}

export interface EvtListener {
	(evt: Evt): any;
}

export class EvtDispatcher {
	protected evtListeners: list<EvtListener>;

	constructor() {
		this.evtListeners = new list<EvtListener>();
	}

	destroy(): void {
		this.notifyEvt(new Evt(EvtType.Destroy));
		this.evtListeners.clear();
	}

	protected evtListenerAdded(): void {
	}

	protected evtListenerRemoved(): void {
	}

	protected notifyEvt(evt: Evt): void {
		for (const cb of this.evtListeners) {
			cb(evt);
		}
	}

	offEvt(cb: EvtListener): void {
		this.evtListeners.removeAll(cb);
		this.evtListenerRemoved();
	}

	onEvt(cb: EvtListener): void {
		if (this.evtListeners.indexOf(cb) === -1) {
			this.evtListeners.append(cb);
			this.evtListenerAdded();
		}
	}
}

const keyboardEventTypeMap = new Map<string, EvtType>([
	['keydown', EvtType.KeyPress],
	['keyup', EvtType.KeyRelease],
]);
const mouseBtnMap = new Map<number, MouseButton>([
	[0, MouseButton.LeftButton],
	[1, MouseButton.MiddleButton],
	[2, MouseButton.RightButton],
	[3, MouseButton.BackButton],
	[4, MouseButton.ForwardButton],
]);
const mouseEventTypeMap = new Map<string, EvtType>([
	['click', EvtType.MouseButtonClick],
	['dblclick', EvtType.MouseButtonDoubleClick],
	['mouseup', EvtType.MouseButtonRelease],
	['mousedown', EvtType.MouseButtonPress],
	['contextmenu', EvtType.ContextMenu],
]);

function translateKeyboardModifier(event: InputEventish): KeyboardModifier {
	let rv: KeyboardModifier = KeyboardModifier.NoModifier;
	if (event.altKey) {
		rv |= KeyboardModifier.AltModifier;
	}
	if (event.ctrlKey) {
		rv |= KeyboardModifier.ControlModifier;
	}
	if (event.metaKey) {
		rv |= KeyboardModifier.MetaModifier;
	}
	if (event.shiftKey) {
		rv |= KeyboardModifier.ShiftModifier;
	}
	return rv;
}

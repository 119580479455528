import {ResourceService} from './resource';

export class UIService extends ResourceService {
	static uri: string = '/api/ui';

	async batchUpdateColumns(tablePk: string, data: Array<IUserUITableColumn>): Promise<Array<IUserUITableColumn>> {
		const url = this.url('tables', tablePk, 'columns');
		return (await this.PUT<Array<IUserUITableColumn>>(url, data)).data;
	}

	async createColumn(tablePk: string, data: IUserUITableColumn): Promise<IUserUITableColumn> {
		const url = this.url('tables', tablePk, 'columns');
		return (await this.POST<IUserUITableColumn>(url, data)).data;
	}

	async createFilter(tablePk: string, data: IUIFilter): Promise<IUIFilter> {
		const url = this.url('tables', tablePk, 'filters');
		return (await this.POST<IUIFilter>(url, data)).data;
	}

	async deleteFilter(tablePk: string, filterPk: number | string): Promise<void> {
		const url = this.url('tables', tablePk, 'filters', filterPk);
		return (await this.DELETE<void>(url)).data;
	}

	async filter(tablePk: string, filterPk: number | string): Promise<IUIFilter> {
		const url = this.url('tables', tablePk, 'filters', filterPk);
		return (await this.GET<IUIFilter>(url)).data;
	}

	async filters(tablePk: string): Promise<Array<IUIFilter>> {
		const url = this.url('tables', tablePk, 'filters');
		return (await this.GET<Array<IUIFilter>>(url)).data;
	}

	async setSort(tablePk: string, data: IUITableColumnSort): Promise<IUITableColumnSort> {
		const url = this.url('tables', tablePk, 'sort');
		return (await this.POST<IUITableColumnSort>(url, data)).data;
	}

	async table(tablePk: string): Promise<IUserUITable> {
		const url = this.url('tables', tablePk);
		return (await this.GET<IUserUITable>(url)).data;
	}

	async updateColumn(tablePk: string, columnPk: number, data: IUserUITableColumn): Promise<IUserUITableColumn> {
		const url = this.url('tables', tablePk, 'columns', columnPk);
		return (await this.PUT<IUserUITableColumn>(url, data)).data;
	}

	async updateFilter(tablePk: string, filterPk: number | string, data: IUIFilter): Promise<IUIFilter> {
		const url = this.url('tables', tablePk, 'filters', filterPk);
		return (await this.PUT<IUIFilter>(url, data)).data;
	}
}

export const uiService: UIService = new UIService();

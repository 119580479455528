import React, {PropsWithChildren} from 'react';
import {
	EventType,
	SpecificEventListener,
} from '@material/base/types';
import {
	MDCFloatingLabelAdapter,
	MDCFloatingLabelFoundation,
} from '@material/floating-label';

import {cssClassName} from '../../../util';

interface IProps {
	float?: boolean;
	id?: string;
	required?: boolean;
	shake?: boolean;
}

type Props = PropsWithChildren<IProps>;
type State = {classNames: Set<string>;};

export default class FloatingLabel extends React.Component<Props, State> {
	private _mdc: MDCFloatingLabelFoundation | null;
	private _rootRef: React.RefObject<HTMLLabelElement>;

	constructor(props: Props) {
		super(props);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcDeregisterInteractionHandler = this._mdcDeregisterInteractionHandler.bind(this);
		this._mdcGetWidth = this._mdcGetWidth.bind(this);
		this._mdcRegisterInteractionHandler = this._mdcRegisterInteractionHandler.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdc = null;
		this._rootRef = React.createRef();
		this.state = {classNames: new Set(['mdc-floating-label'])};
	}

	componentDidMount(): void {
		const {float, required, shake} = this.props;
		this._mdc = new MDCFloatingLabelFoundation(this._mdcAdapter());
		this._mdc.init();
		if (float !== undefined) {
			this._mdc.float(float);
		}
		if (required !== undefined) {
			this._mdc.setRequired(required);
		}
		if (shake !== undefined) {
			this._mdc.shake(shake);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {float: floatPrev, required: requiredPrev, shake: shakePrev} = prevProps;
		const {float, required, shake} = this.props;
		if (floatPrev !== float) {
			this.setFloat(Boolean(float));
		}
		if (requiredPrev !== required) {
			this.setRequired(Boolean(required));
		}
		if (shakePrev !== shake) {
			this.setShake(Boolean(shake));
		}
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	render(): React.ReactNode {
		const {children, id} = this.props;
		const {classNames} = this.state;
		return <label className={cssClassName(...classNames)} id={id} ref={this._rootRef}>{children}</label>;
	}

	setFloat(float: boolean): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.float(float);
		}
	}

	setRequired(required: boolean): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.setRequired(required);
		}
	}

	setShake(shake: boolean): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.shake(shake);
		}
	}

	width(): number {
		const mdc = this._mdc;
		if (mdc) {
			return mdc.getWidth();
		}
		return 0;
	}

	private _mdcAdapter(): MDCFloatingLabelAdapter {
		return {
			addClass: this._mdcAddClass,
			deregisterInteractionHandler: this._mdcDeregisterInteractionHandler,
			getWidth: this._mdcGetWidth,
			registerInteractionHandler: this._mdcRegisterInteractionHandler,
			removeClass: this._mdcRemoveClass,
		};
	}

	private _mdcAddClass(value: string): void {
		const {classNames} = this.state;
		classNames.add(value);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcDeregisterInteractionHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.removeEventListener(type, listener);
		}
	}

	private _mdcGetWidth(): number {
		const elem = this._rootRef.current;
		if (elem) {
			return estimateScrollWidth(elem);
		}
		return 0;
	}

	private _mdcRegisterInteractionHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.addEventListener(type, listener);
		}
	}

	private _mdcRemoveClass(value: string): void {
		const {classNames} = this.state;
		classNames.delete(value);
		this.setState({classNames: new Set(classNames)});
	}
}

function estimateScrollWidth(elem: Element): number {
	// Check the offsetParent. If the element inherits display: none from any
	// parent, the offsetParent property will be null (see
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent).
	// This check ensures we only clone the node when necessary.
	const htmlElem = elem as HTMLElement;
	if (htmlElem.offsetParent) {
		return htmlElem.scrollWidth;
	}
	const clone = htmlElem.cloneNode(true) as HTMLElement;
	clone.style.setProperty('position', 'absolute');
	clone.style.setProperty('transform', 'translate(-9999px, -9999px)');
	document.documentElement.appendChild(clone);
	const scrollWidth = clone.scrollWidth;
	document.documentElement.removeChild(clone);
	return scrollWidth;
}

import React from 'react';
import {connect} from 'react-redux';

import {genTimes, isNumber} from '../../util';
import {apiService as svc} from '../../services';
import {date, genDates, timedelta} from '../../datetime';
import {user as userAxns, weather as wxAxns} from '../actions';
import {Calendar, Grid, GridCell, Header, Select} from '../components';

interface IDispatchProps {
	marketWeatherData: (data: IMarketWeatherRequest) => any;
	userData: () => any;
}

interface IProps {
	defaultMarketID: number | null;
	marketWeather: IMarketWeather;
}

type Props = IDispatchProps & IProps;

interface State {
	activatedMarketID: number | null;
	fetching: boolean;
	markets: IMarket[];
}

class MarketWx extends React.Component<Props, State> {
	zeros: IDateWeather[];
	calendarDates: IDate[];

	constructor(props: Props) {
		super(props);
		this.onChange = this.onChange.bind(this);

		const today = date.today();
		const tomorrow = today.add(new timedelta(1));
		this.calendarDates = genDates(9, tomorrow);
		this.zeros = wxZeros(this.calendarDates, genTimes());
		this.state = {activatedMarketID: null, fetching: true, markets: []};
	}

	activatedMarketIDChanged(newID: number | null): void {
		if (isNumber(newID)) {
			this.fetchWeather(newID);
		}
	}

	activeMarketWx(): IDateWeather[] {
		const {activatedMarketID, fetching} = this.state;
		if (isNumber(activatedMarketID) && !fetching) {
			return this.props.marketWeather[activatedMarketID] || [];
		}
		return this.zeros;
	}

	componentDidMount(): void {
		this.init();
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		const {defaultMarketID} = this.props;
		const {activatedMarketID} = this.state;
		if (!isNumber(prevProps.defaultMarketID) && isNumber(defaultMarketID) && !isNumber(activatedMarketID)) {
			this.setActivatedMarketID(defaultMarketID);
		}
	}

	async fetchWeather(marketID: number): Promise<void> {
		this.setState({fetching: true});
		await this.props.marketWeatherData({marketID, fromDate: 'tomorrow', dateCount: 9});
		this.setState({fetching: false});
	}

	async init(): Promise<void> {
		const {userData} = this.props;
		userData();
		const markets = await svc.group.market.list();
		this.setState({markets});
	}

	marketOptions(): {text: string; value: number;}[] {
		const {markets} = this.state;
		return markets.map(m => ({text: m.city, value: m.id}));
	}

	onChange(event: React.ChangeEvent<HTMLSelectElement>): void {
		const v = event.target.value;
		const vv = (v === '') ? null : Number.parseInt(v);
		const id = Number.isNaN(vv) ? null : vv;
		this.setActivatedMarketID(id);
	}

	render(): React.ReactNode {
		const {activatedMarketID} = this.state;
		return (
			<React.Fragment>
				<Header>
					<Select
						nullOption={true}
						onChange={this.onChange}
						options={this.marketOptions()}
						value={activatedMarketID || ''}/>
				</Header>
				<Grid>
					<GridCell>
						<Calendar
							dates={this.calendarDates}
							weather={this.activeMarketWx()}/>
					</GridCell>
				</Grid>
			</React.Fragment>
		);
	}

	setActivatedMarketID(marketID: number | null): void {
		const {activatedMarketID} = this.state;
		if (marketID === activatedMarketID) {
			return;
		}
		this.setState({activatedMarketID: marketID});
		this.activatedMarketIDChanged(marketID);
	}
}

const dispatchProps: IDispatchProps = {
	userData: userAxns.userData,
	marketWeatherData: wxAxns.marketWeatherData,
};

function mapStateToProps(state: IState): IProps {
	return {
		marketWeather: state.global.marketWeather,
		defaultMarketID: state.user.defaultMarketId,
	};
}

export default connect(mapStateToProps, dispatchProps)(MarketWx);

function wxZeros(dates: IDate[], times: ITime[]): IDateWeather[] {
	const rv: IDateWeather[] = [];
	for (let i = 0; i < dates.length; ++i) {
		const date = dates[i];
		const wx: IWeather[] = [];
		for (let k = 0; k < times.length; ++k) {
			const time = times[k];
			wx.push({
				probPrecip: 0.0,
				cloudCov: 0.0,
				time: time.isoformat,
			});
		}
		rv.push({
			date: date.isoformat,
			weather: wx,
		});
	}
	return rv;
}

import {Menu, MenuOpts} from '../../ui/menu';
import {ProjectStatus, ProjectStatusDisplay} from '../../constants';
import {bind, isNumber} from '../../util';
import {Radio} from '../../ui/radio';
import {El, ElOpts, elOpts} from '../../el';
import {ListItem, ListItemSelectEvt} from '../../ui/list';
import {Evt} from '../../evt';
import {Point} from '../../tools';

export class StatusMenuSelectEvt extends Evt {
	private s: ProjectStatus;

	constructor(type: number, status: ProjectStatus) {
		super(type);
		this.s = status;
	}

	status(): ProjectStatus {
		return this.s;
	}
}

interface StatusMenuOpts extends MenuOpts {
	currentStatus: ProjectStatus;
}

export class StatusMenu extends Menu {
	private currentStatus: ProjectStatus | null;
	private itemStatusMap: Map<ListItem, ProjectStatus>;

	constructor(opts: Partial<StatusMenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<StatusMenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<StatusMenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<StatusMenuOpts>, parent?: El | null);
	constructor(opts?: Partial<StatusMenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<StatusMenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<StatusMenuOpts>(a, b, c);
		super(opts);
		this.currentStatus = null;
		this.itemStatusMap = new Map<ListItem, ProjectStatus>();
		this.initialize(opts.currentStatus);
	}

	destroy(): void {
		this.offEvt(this.evt);
		this.itemStatusMap.clear();
		super.destroy();
	}

	@bind
	private evt(evt: Evt): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const item = this.item(evt.index());
			if (item) {
				const status = this.itemStatusMap.get(item);
				if (status === undefined) {
					console.log('StatusMenu: undefined returned for index %s', evt.index());
				} else if (status !== this.currentStatus) {
					this.notifyEvt(new StatusMenuSelectEvt(Evt.Select, status));
					this.close();
				}
			}
		}
	}

	private initialize(currentStatus?: ProjectStatus): void {
		this.currentStatus = (currentStatus === undefined) ? null : currentStatus;
		this.itemStatusMap.clear();
		for (const k in ProjectStatus) {
			if (ProjectStatus.hasOwnProperty(k)) {
				const key = Number.parseInt(k);
				if (!isNumber(key)) {
					continue;
				}
				const radio = new Radio();
				const radioId = `id_project-status-${key}`;
				radio.setInputId(radioId);
				radio.setName(`project-status-${this.instanceNumber}`);
				radio.setChecked(this.currentStatus === key);
				const item = this.addItem({
					classNames: 'pb-check-list-item',
					leadingEl: radio,
					text: ProjectStatusDisplay[<ProjectStatus>key],
					textIsLabel: true,
				});
				this.itemStatusMap.set(item, key);
				radio.setBuddy(item.textEl());
			}
		}
		this.onEvt(this.evt);
	}

	protected listItemSelectEvt(evt: ListItemSelectEvt): void {
		this.evt(evt);
	}
}

export class StatusMenuButtonEvt extends Evt {
	private p: Point;

	constructor(type: number, pos: Point) {
		super(type);
		this.p = pos;
	}

	pos(): Point {
		return this.p;
	}
}

export class StatusMenuButton extends El {
	static RootSelector: string = '#id_pb-project-detail-status-menu-button';

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		super(opts);
		this.initialize();
	}

	destroy(): void {
		this.destroyEventListeners();
		super.destroy();
	}

	private destroyEventListeners(): void {
		this.removeEventListener('click', this.event);
	}

	@bind
	private event(event: Event): void {
		if (event.type === 'click') {
			const {clientX, clientY} = <MouseEvent>event;
			this.notifyEvt(new StatusMenuButtonEvt(Evt.MouseButtonClick, new Point(clientX, clientY)));
		}
	}

	private initialize(): void {
		this.initializeEventListeners();
	}

	private initializeEventListeners(): void {
		this.addEventListener('click', this.event);
	}

	label(): string {
		const el = this.labelEl();
		return el ? el.text() : '';
	}

	private labelEl(): El | null {
		return this.querySelector('.pb-project-detail-status-menu-button-label');
	}

	setLabel(label?: string | null): void {
		const el = this.labelEl();
		if (el) {
			el.setText(label);
		}
	}

	setText(text?: string | null): void {
		this.setLabel(text);
	}

	text(): string {
		return this.label();
	}
}

import React from 'react';

import {Select} from '../../../components';

type Option = {id: string; name: string;}

interface IProps<T extends Option = Option> {
	onChange: (value: string, name: 'qbitemId') => any;
	options: T[];
	value: string | null;
}

export default class QuickBooksItemSelect extends React.Component<IProps, {}> {
	static Name: 'qbitemId' = 'qbitemId';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent<HTMLSelectElement>): void {
		const {onChange} = this.props;
		onChange(event.target.value, this.name());
	}

	name(): 'qbitemId' {
		return QuickBooksItemSelect.Name;
	}

	render(): React.ReactNode {
		const {options, value} = this.props;
		return <Select
			className="width--100-percent pb-select--filled"
			name={this.name()}
			nullOption={true}
			nullOptionLabel="---"
			onChange={this.changeEvent}
			options={options.map(obj => ({value: obj.id, text: obj.name}))}
			size={4}
			value={value ? value : ''}/>;
	}
}

import React from 'react';

import {cssClassName} from '../../../../util';

export default function Subheading({children, className}: React.PropsWithChildren<{className?: string}>) {
	return (
		<h4 className={cssClassName('pb-catalog-item-form__subsection-subtitle', className)}>
			{children}
		</h4>
	);
}

import {ClientUserCCardEdit, ClientUserCCardEditOpts} from './ccardedit';
import {El, elOpts} from '../../el';
import {TextInput} from '../../ui/textinput';

enum ClientUserCCardCreateFieldName {
	Password = 'Password',
	ConfirmPassword = 'Confirm password',
}

enum ClientUserCCardCreateRowType {
	Password = 'Password',
}

export class ClientUserCCardCreate extends ClientUserCCardEdit {
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, root?: Element | null);
	constructor(opts: Partial<ClientUserCCardEditOpts>, parent?: El | null);
	constructor(opts?: Partial<ClientUserCCardEditOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ClientUserCCardEditOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ClientUserCCardEditOpts>(a, b, c);
		super(opts);
		this.registerRowType(
			ClientUserCCardCreateRowType.Password,
			'password',
			false,
			0);
		this.addRow(ClientUserCCardCreateRowType.Password);
		const passwordField = new TextInput({
			classNames: 'pb-text-input--underlined-less-dense-no-padding',
			inputId: `id_row-${this.instanceNumber}-input-${this.instanceNumber}`,
			labelText: 'Password',
			required: true,
			type: 'password',
		});
		const passwordConfirmField = new TextInput({
			classNames: 'pb-text-input--underlined-less-dense-no-padding',
			inputId: `id_row-${this.instanceNumber}-input-${this.instanceNumber}-02`,
			labelText: 'Confirm password',
			required: true,
			type: 'password',
		});
		this.addField(
			ClientUserCCardCreateFieldName.Password,
			ClientUserCCardCreateRowType.Password,
			0,
			passwordField);
		this.addField(
			ClientUserCCardCreateFieldName.ConfirmPassword,
			ClientUserCCardCreateRowType.Password,
			0,
			passwordConfirmField);
	}

	password(): string {
		return this.fieldValue(
			ClientUserCCardCreateFieldName.Password,
			ClientUserCCardCreateRowType.Password);
	}

	passwordConfirm(): string {
		return this.fieldValue(
			ClientUserCCardCreateFieldName.ConfirmPassword,
			ClientUserCCardCreateRowType.Password);
	}
}

import {initialState} from './state';
import {
	RECEIVE_ITEMS_DATA,
	RECEIVE_ACCESS_OPTIONS,
	RECEIVE_CLIENT_USERS_DATA,
	RECEIVE_OCCUPANCY_OPTIONS,
	RECEIVE_GROUP_PRODUCER_MARKET_LIST,
	RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST,
} from '../actions/types';

const initial: IProducerState = initialState('producer');

export default function (state: IProducerState = initial, action: Action): IProducerState {
	switch (action.type) {
		case RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST:
			return {
				...state,
				hours: [...(<ReceiveGroupProducerBusinessHourListAction>action).data],
			};
		case RECEIVE_GROUP_PRODUCER_MARKET_LIST:
			return {
				...state,
				markets: [...(<ReceiveGroupProducerMarketListAction>action).data],
			};
		case RECEIVE_ACCESS_OPTIONS:
			return {
				...state,
				accessOptions: (<ReceiveAccessOptionsAction>action).data,
			};
		case RECEIVE_OCCUPANCY_OPTIONS:
			return {
				...state,
				occupancyOptions: (<ReceiveOccupancyOptionsAction>action).data,
			};
		case RECEIVE_ITEMS_DATA:
			return {
				...state,
				items: [...(<ReceiveItemsDataAction>action).data],
			};
		case RECEIVE_CLIENT_USERS_DATA:
			return {
				...state,
				clientUsers: [...(<ReceiveClientUsersAction>action).data],
			};
		default:
			return state;
	}
}

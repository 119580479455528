import {apiService as svc} from '../../services';
import {El, elOpts} from '../../el';
import {CCardDetail, CCardDetailNotificationChangeEvt, QuickBooksDetailSection, ToolbarAction} from '../../ui/ccard/detail';
import {Evt} from '../../evt';
import {bind, isNumber} from '../../util';
import {Dialog} from '../../ui/dialog';
import {TableItem, TableModel} from '../../ui/datatable/model';
import {list, set} from '../../tools';
import {ClientUserListColumnName, ItemDataRole, ItemFlag, StandardButton} from '../../constants';
import {Variant} from '../../variant';
import {DataTableEvt} from '../../ui/datatable/evt';
import {AbstractTableView, AbstractTableViewOpts} from '../../views/abstracttableview';
import {AbstractItemModel, ModelIndex} from '../../itemmodel';
import {ClientUserCCardEdit} from './ccardedit';
import {getLogger} from '../../logging';
import {isNetworkErrorObject} from '../../services/request';
import {ClientUserCCardCreate} from './ccardcreate';

const logger = getLogger('site::clientuserlist::view');

class ClientUserListViewTableModel extends TableModel {
	flags(index: ModelIndex): ItemFlag {
		return super.flags(index) | ItemFlag.ItemIsSelectable;
	}
}

export class ClientUserListView extends AbstractTableView {
	static UITableName: string = 'client_user_list';

	private cardCreate: ClientUserCCardCreate | null;
	private cardDetail: CCardDetail | null;
	private cardEdit: ClientUserCCardEdit | null;
	private clientGroups: list<IGroup>;
	private clientUserTypes: list<IClientUserType>;
	private confirmationCallBack: ((result: number) => any) | null;
	private confirmDialog: Dialog | null;
	private currentDataObject: IUser | null;
	private data: list<IUser>;
	private dialog: Dialog | null;
	private mql: MediaQueryList;
	private qbCustomers: list<IQuickBooksCustomer>;

	constructor(opts: Partial<AbstractTableViewOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<AbstractTableViewOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<AbstractTableViewOpts> | null, tagName?: TagName);
	constructor(opts: Partial<AbstractTableViewOpts> | null, root?: Element | null);
	constructor(opts: Partial<AbstractTableViewOpts>, parent?: El | null);
	constructor(opts?: Partial<AbstractTableViewOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<AbstractTableViewOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<AbstractTableViewOpts>(a, b, c);
		const dtParent = document.getElementById('id_pb-dt-parent');
		opts.dtElOpts = {
			classNames: 'width--100-percent',
			ensureSettingsBtn: true,
			parent: dtParent ?
				new El(dtParent) :
				undefined,
		};
		opts.model = new ClientUserListViewTableModel(0, 0);
		opts.userUiTablePk = ClientUserListView.UITableName;
		opts.tableItemPrototype = ClientUserTableItem;
		super(opts);
		this.cardCreate = null;
		this.cardDetail = null;
		this.cardEdit = null;
		this.clientGroups = new list<IGroup>();
		this.clientUserTypes = new list<IClientUserType>();
		this.confirmationCallBack = null;
		this.confirmDialog = null;
		this.currentDataObject = null;
		this.data = new list<IUser>();
		this.dialog = null;
		this.mql = window.matchMedia('(max-width: 704px)');
		this.mql.addEventListener('change', this.mqlChangeEvent);
		this.qbCustomers = new list<IQuickBooksCustomer>();
	}

	@bind
	private async confirmDeleteCallBack(result: number): Promise<void> {
		if (result === StandardButton.Yes) {
			if (this.currentDataObject) {
				if (await this.deleteDataObject(this.currentDataObject)) {
					this.removeDataObject(this.currentDataObject);
					this.destroyAllDialogs();
				}
			} else {
				logger.warning('confirmDeleteCallBack: current object is not defined.');
				return;
			}
		}
	}

	@bind
	private confirmDialogCallBack(result: number): void {
		if (this.confirmationCallBack) {
			this.confirmationCallBack(result);
		}
		this.confirmationCallBack = null;
		this.destroyConfirmDialog();
	}

	private async create(): Promise<boolean> {
		if (!this.cardCreate) {
			logger.error('create: cardCreate is not defined.');
			return true;
		}
		const password01 = this.cardCreate.password();
		const password02 = this.cardCreate.passwordConfirm();
		if (!password01 || (password01 !== password02)) {
			this.openConfirmDialog(
				null,
				'Something\'s missing...',
				StandardButton.Ok,
				'Please enter and confirm a password.');
			return false;
		}
		const emailAddresses = this.cardCreate.emailAddresses()
			.filter(x => (x.address.trim().length > 0));
		if (emailAddresses.isEmpty()) {
			this.openConfirmDialog(
				null,
				'Something\'s missing...',
				StandardButton.Ok,
				'Please enter an email address.');
			return false;
		}
		const groupId = this.cardCreate.groupId();
		if (!groupId) {
			this.openConfirmDialog(
				null,
				'Something\'s missing...',
				StandardButton.Ok,
				'Please choose a group for this client.');
			return false;
		}
		const [
			accountEmailAddress,
			...otherEmailAddresses
		] = emailAddresses;
		const notes = this.cardCreate.notes()
			.filter(x => (x.text.trim().length > 0));
		const phoneNumbers = this.cardCreate.phoneNumbers()
			.filter(x => (x.number.trim().length > 0));
		const obj: INewUserData = {
			clientUserTypeId: this.cardCreate.clientUserTypeId(),
			defaultMarketId: null,
			email: accountEmailAddress.address,
			emailAddresses: otherEmailAddresses.map(x => ({address: x.address, receiveNotifications: x.receiveNotifications, label: x.label})),
			firstName: this.cardCreate.firstName(),
			groupId,
			isAdmin: this.cardCreate.isAdmin(),
			lastName: this.cardCreate.lastName(),
			notes: notes.toArray().map(x => ({text: x.text, label: x.label})),
			password: password01,
			passwordConfirm: password02,
			phoneNumbers: phoneNumbers.toArray().map(x => ({number: x.number, label: x.label})),
			priceGroupId: null,
			qbCustomer: this.cardCreate.quickBooksCustomerId().trim() || null,
			receiveNotifications: accountEmailAddress.receiveNotifications,
		};
		let errorMsg: string = '';
		let newDataObject: IUser;
		try {
			newDataObject = await this.createDataObject(obj);
		} catch (err) {
			if (isNetworkErrorObject(err) && err.response && err.response.data.error.message.trim().length > 0) {
				errorMsg = err.response.data.error.message;
			} else {
				errorMsg = err.message;
			}
			this.openConfirmDialog(
				null,
				'There Was an Error',
				StandardButton.Ok,
				errorMsg);
			return false;
		}
		if (errorMsg.length > 0) {
			this.openConfirmDialog(
				null,
				'There Was an Error',
				StandardButton.Ok,
				errorMsg);
			return false;
		}
		const newData = new list(await this.fetchData());
		const idx = newData.findIndex(x => (x.id === newDataObject.id));
		if (idx >= 0) {
			this.currentDataObject = newData.at(idx);
		} else {
			logger.error('create: New object not found in data collection.');
		}
		this.setData(newData);
		return true;
	}

	private async createDataObject(data: INewUserData): Promise<IUser> {
		return await svc.group.client.user.createClientUser(data);
	}

	private async createEmailAddress(userPk: number, obj: IEmailAddress): Promise<IEmailAddress> {
		return await svc.group.client.user.createAlternateEmailAddress(userPk, obj);
	}

	@bind
	private createEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Cancel:
				this.destroyDialog();
				this.destroyCardCreate();
				break;
			case Evt.Save:
				this.create().then(ok => {
					if (ok) {
						if (!this.currentDataObject) {
							this.destroyDialog();
						}
						this.destroyCardCreate();
						if (this.currentDataObject) {
							this.openCardDetail(this.currentDataObject);
						}
					}
				});
				break;
		}
	}

	private async createNote(userPk: number, obj: INote): Promise<INote> {
		return await svc.group.client.user.createNote(userPk, obj);
	}

	private async createPhoneNumber(userPk: number, obj: IPhoneNumber): Promise<IPhoneNumber> {
		return await svc.group.client.user.createPhoneNumber(userPk, obj);
	}

	private dataObject(id: number): IUser | null {
		for (const obj of this.data) {
			if (obj.id === id) {
				return obj;
			}
		}
		return null;
	}

	protected dataTableItem(index: ModelIndex): ClientUserTableItem | null {
		return <ClientUserTableItem | null>super.dataTableItem(index);
	}

	protected dataTableItemClickEvt(evt: DataTableEvt): void {
		super.dataTableItemClickEvt(evt);
		const tableItem = this.dataTableItem(evt.index());
		this.currentDataObject = tableItem ?
			this.dataObject(tableItem.objectId) :
			null;
		if (this.currentDataObject) {
			this.openCardDetail(this.currentDataObject);
		}
	}

	private async deleteDataObject(obj: IUser): Promise<boolean> {
		let ok: boolean;
		let res: Error | null = null;
		try {
			await svc.group.client.user.delete(obj.id);
			ok = true;
		} catch (exc) {
			res = exc;
			ok = false;
		}
		if (res) {
			let message: string;
			if (isNetworkErrorObject(res) && res.response && res.response.data.error.message.trim().length > 0) {
				message = res.response.data.error.message;
			} else {
				message = res.message;
			}
			this.openConfirmDialog(null, 'There Was an Error', undefined, message);
		}
		return ok;
	}

	destroy(): void {
		const btn = document.getElementById('id_object-create-button');
		if (btn) {
			btn.removeEventListener('click', this.objectCreateButtonClickEvent);
		}
		this.confirmationCallBack = null;
		this.currentDataObject = null;
		this.destroyAllDialogs();
		this.destroyCardCreate();
		this.destroyCardDetail();
		this.destroyCardEdit();
		this.data.clear();
		this.mql.removeEventListener('change', this.mqlChangeEvent);
		super.destroy();
	}

	private async cardDetailNotificationChangeEvt(evt: CCardDetailNotificationChangeEvt): Promise<void> {
		if (!this.currentDataObject) {
			logger.warning('cardDetailNotificationChangeEvt: Current object is not defined.');
			return;
		}
		const address = evt.emailAddress();
		const res = await this.updateEmailReceivesNotifications(
			this.currentDataObject,
			address);
		if (res) {
			this.replaceDataObject(res);
			if (this.cardDetail) {
				this.cardDetail.setEmailReceivesNotifications(
					address,
					this.emailAddressReceivesNotifications(res, address));
			}
		}
	}

	private async deleteEmailAddress(userPk: number, pk: number): Promise<void> {
		return await svc.group.client.user.deleteAlternateEmailAddress(userPk, pk);
	}

	private async deleteNote(userPk: number, pk: number): Promise<void> {
		return await svc.group.client.user.deleteNote(userPk, pk);
	}

	private async deletePhoneNumber(userPk: number, pk: number): Promise<void> {
		return await svc.group.client.user.deletePhoneNumber(userPk, pk);
	}

	private destroyAllDialogs(): void {
		this.destroyConfirmDialog();
		this.destroyDialog();
	}

	private destroyCardCreate(): void {
		if (this.cardCreate) {
			this.cardCreate.offEvt(this.createEvt);
			this.cardCreate.destroy();
		}
		this.cardCreate = null;
	}

	private destroyCardDetail(): void {
		if (this.cardDetail) {
			this.cardDetail.offEvt(this.detailEvt);
			this.cardDetail.destroy();
		}
		this.cardDetail = null;
	}

	private destroyCardEdit(): void {
		if (this.cardEdit) {
			this.cardEdit.offEvt(this.editEvt);
			this.cardEdit.destroy();
		}
		this.cardEdit = null;
	}

	private destroyConfirmDialog(): void {
		if (this.confirmDialog) {
			this.confirmDialog.destroy();
		}
		this.confirmDialog = null;
	}

	private destroyDialog(): void {
		if (this.dialog) {
			this.dialog.destroy();
		}
		this.dialog = null;
	}

	@bind
	private detailEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Close:
				this.destroyDialog();
				this.destroyCardDetail();
				break;
			case Evt.Change:
				if (evt instanceof CCardDetailNotificationChangeEvt) {
					this.cardDetailNotificationChangeEvt(evt);
				}
				break;
			case Evt.Edit:
				this.destroyCardDetail();
				if (this.currentDataObject) {
					this.openCardEdit(this.currentDataObject);
				} else {
					this.destroyDialog();
				}
				break;
			case Evt.Delete:
				this.openConfirmDeleteDialog();
				break;
		}
	}

	@bind
	private dialogCallBack(result: number): void {
		this.destroyDialog();
		this.destroyCardDetail();
		this.destroyCardEdit();
		this.currentDataObject = null;
	}

	@bind
	private editEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Cancel:
				if (!this.currentDataObject) {
					this.destroyDialog();
				}
				this.destroyCardEdit();
				if (this.currentDataObject) {
					this.openCardDetail(this.currentDataObject);
				}
				break;
			case Evt.Save:
				this.save().then(ok => {
					if (ok) {
						if (!this.currentDataObject) {
							this.destroyDialog();
						}
						this.destroyCardEdit();
						if (this.currentDataObject) {
							this.openCardDetail(this.currentDataObject);
						}
					}
				});
				break;
		}
	}

	private emailAddressReceivesNotifications(obj: IUser, address: string): boolean {
		if (obj.email === address) {
			return obj.receiveNotifications;
		}
		for (const altEmail of obj.emailAddresses) {
			if (altEmail.address === address) {
				return altEmail.receiveNotifications;
			}
		}
		return false;
	}

	private async fetchClientGroups(): Promise<Array<IGroup>> {
		return svc.group.client.group.list();
	}

	private async fetchClientUserTypes(): Promise<Array<IClientUserType>> {
		return svc.group.client.type.list();
	}

	protected async fetchData(): Promise<Array<IUser>> {
		return await svc.group.client.user.list();
	}

	private async fetchQuickBooksCustomers(): Promise<Array<IQuickBooksCustomer>> {
		return svc.group.app.quickbooks.customer.list();
	}

	protected async init(model?: AbstractItemModel | null): Promise<void> {
		await super.init(model);
		const btn = document.getElementById('id_object-create-button');
		if (btn) {
			btn.addEventListener('click', this.objectCreateButtonClickEvent);
		}
		await this.refreshDataTable();
		this.clientGroups = new list<IGroup>(await this.fetchClientGroups());
		this.clientUserTypes = new list<IClientUserType>(await this.fetchClientUserTypes());
		this.qbCustomers = new list<IQuickBooksCustomer>(await this.fetchQuickBooksCustomers());
		const u = new URL(window.location.href);
		const pks = u.searchParams.get('pk');
		const pk = pks ? Number.parseInt(pks) : Number.NaN;
		if (isNumber(pk)) {
			this.currentDataObject = this.dataObject(pk);
			if (this.currentDataObject) {
				this.openCardDetail(this.currentDataObject);
			}
		}
	}

	@bind
	private mqlChangeEvent(): void {
		if (this.dialog) {
			this.dialog.setFullScreen(this.mql.matches);
		}
	}

	@bind
	private objectCreateButtonClickEvent(): void {
		this.openCardCreate();
	}

	private openCardCreate(): void {
		if (!this.cardCreate) {
			this.cardCreate = new ClientUserCCardCreate({
				clientUserTypes: this.clientUserTypes,
				emailAddressFactory: () => emailAddressFactory(-1),
				header: 'Create client',
				groups: this.clientGroups,
				isAdmin: false,
				noteFactory,
				phoneNumberFactory,
				qbCustomers: this.qbCustomers,
			});
			this.cardCreate.setOtherEmail(emailAddressFactory(-1));
			this.cardCreate.setNote(noteFactory());
			this.cardCreate.setPhoneNumber(phoneNumberFactory());
			this.cardCreate.onEvt(this.createEvt);
			this.destroyCardDetail();
			this.destroyCardEdit();
			if (this.dialog) {
				this.dialog.setContent(this.cardCreate);
			} else {
				this.openDialog(this.cardCreate);
			}
		}
	}

	private openCardDetail(obj: IUser): void {
		if (!this.cardDetail) {
			this.cardDetail = new CCardDetail(
				obj.isAdmin ?
					{avatar: 'admin_panel_settings', avatarCaption: 'Admin'} :
					undefined);
			this.cardDetail.setName(obj.name);
			if (obj.groupDisplay.trim().length > 0) {
				this.cardDetail.setTitle(obj.groupDisplay);
			}
			if (obj.clientUserTypeDisplay.trim().length > 0) {
				this.cardDetail.addLabel(
					obj.clientUserTypeDisplay,
					'Client user type');
			}
			this.cardDetail.addEmail(obj.email, obj.receiveNotifications, 'Account');
			obj.emailAddresses
				.forEach(x => this.cardDetail!.addEmail(x.address, x.receiveNotifications, x.label));
			obj.phoneNumbers
				.forEach(x => this.cardDetail!.addPhone(x.number, x.label));
			obj.notes
				.forEach(x => this.cardDetail!.addNote(x.text, x.label));
			if (obj.qbCustomerId.trim().length > 0) {
				this.cardDetail.addIntegration(
					new QuickBooksDetailSection(
						{customerId: obj.qbCustomerId}));
			}
			this.cardDetail.onEvt(this.detailEvt);
			if (this.dialog) {
				this.dialog.setContent(this.cardDetail);
			} else {
				this.openDialog(this.cardDetail);
			}
			const toolbar = this.cardDetail.toolBar();
			if (toolbar) {
				const btn = toolbar.button(ToolbarAction.Close);
				if (btn) {
					btn.focus();
				}
			}
		}
	}

	private openCardEdit(obj: IUser): void {
		if (!this.cardEdit) {
			this.cardEdit = new ClientUserCCardEdit({
				clientUserTypes: this.clientUserTypes,
				emailAddressFactory: () => emailAddressFactory(obj.id),
				groups: this.clientGroups,
				isAdmin: obj.isAdmin,
				noteFactory,
				phoneNumberFactory,
				qbCustomers: this.qbCustomers,
			});
			this.cardEdit.setClientUserType(obj.clientUserTypeId);
			this.cardEdit.setName(obj.firstName, obj.lastName);
			const email = (obj.emailAddresses.length > 0) ?
				obj.emailAddresses :
				[emailAddressFactory(obj.id)];
			this.cardEdit.addEmailRow({
				address: obj.email,
				id: 0,
				label: 'Account',
				receiveNotifications: obj.receiveNotifications,
				userId: obj.id,
			}, false);
			this.cardEdit.setOtherEmail(email);
			const phone = (obj.phoneNumbers.length > 0) ?
				obj.phoneNumbers :
				[phoneNumberFactory()];
			this.cardEdit.setPhoneNumber(phone);
			const note = (obj.notes.length > 0) ?
				obj.notes :
				[noteFactory()];
			this.cardEdit.setNote(note);
			this.cardEdit.setGroup(obj.groupId);
			this.cardEdit.setQuickBooksCustomerId(obj.qbCustomerId);
			this.cardEdit.onEvt(this.editEvt);
			if (this.dialog) {
				this.dialog.setContent(this.cardEdit);
			} else {
				this.openDialog(this.cardEdit);
			}
		}
	}

	private openConfirmDeleteDialog(): void {
		this.openConfirmDialog(
			this.confirmDeleteCallBack,
			'Delete this client?',
			StandardButton.Cancel | StandardButton.Yes);
	}

	private openConfirmDialog(callBack: ((result: number) => any) | null, title: string, buttons: StandardButton = StandardButton.Ok, message?: string): void {
		this.destroyConfirmDialog();
		this.confirmDialog = new Dialog({
			standardButtons: buttons,
			message,
			title,
		});
		this.confirmationCallBack = callBack;
		this.confirmDialog.open(this.confirmDialogCallBack);
	}

	private openDialog(el: El | null): void {
		this.destroyDialog();
		this.dialog = new Dialog({newHotness: true});
		this.dialog.setFullScreen(this.mql.matches);
		this.dialog.setContent(el);
		this.dialog.open(this.dialogCallBack);
	}

	private async refreshDataTable(): Promise<void> {
		this.beginFetchData();
		this.setData(await this.fetchData());
		this.endFetchData();
	}

	private removeDataObject(obj: IUser): void {
		const idx = this.data.findIndex(x => (x.id === obj.id));
		if (idx >= 0) {
			this.data.remove(idx);
			if (this.currentDataObject && (this.currentDataObject.id === obj.id)) {
				this.currentDataObject = null;
			}
			this.setData(this.data);
		} else {
			logger.error('removeDataObject: Given object not found within current object collection.');
		}
	}

	private replaceDataObject(obj: IUser): void {
		const idx = this.data.findIndex(x => (x.id === obj.id));
		if (idx >= 0) {
			this.data.replace(idx, obj);
			if (this.currentDataObject && (this.currentDataObject.id === obj.id)) {
				this.currentDataObject = obj;
			}
			this.setData(this.data);
		} else {
			logger.error('replaceDataObject: Given object not found within current object collection.');
		}
	}

	private async save(): Promise<boolean> {
		if (!this.cardEdit) {
			logger.error('cardEditSaveEvt: cardEdit is not defined.');
			return true;
		}
		if (!this.currentDataObject) {
			logger.error('cardEditSaveEvt: Current data object is not defined.');
			return true;
		}
		const clientUserTypeId = this.cardEdit.clientUserTypeId();
		const firstName = this.cardEdit.firstName();
		const isAdmin = this.cardEdit.isAdmin();
		const lastName = this.cardEdit.lastName();
		const emailAddresses = this.cardEdit.emailAddresses()
			.filter(x => (x.address.trim().length > 0));
		const emailAddressIds = new set(emailAddresses.map(x => x.id));
		const notes = this.cardEdit.notes()
			.filter(x => (x.text.trim().length > 0));
		const noteIds = new set(notes.map(x => x.id));
		const phoneNumbers = this.cardEdit.phoneNumbers()
			.filter(x => (x.number.trim().length > 0));
		const phoneNumberIds = new set(phoneNumbers.map(x => x.id));
		const qbCustId = this.cardEdit.quickBooksCustomerId().trim();
		const groupId = this.cardEdit.groupId();
		const obj = deepCopy(this.currentDataObject);
		obj.clientUserTypeId = clientUserTypeId;
		obj.firstName = firstName;
		obj.isAdmin = isAdmin;
		obj.lastName = lastName;
		if (!groupId) {
			this.openConfirmDialog(
				null,
				'Something\'s missing...',
				StandardButton.Ok,
				'Please choose a group for this client.');
			return false;
		}
		obj.groupId = groupId;
		const oldEmailAddresses = [...obj.emailAddresses];
		const oldEmailAddressIds = new set(oldEmailAddresses.map(x => x.id));
		const oldNotes = [...obj.notes];
		const oldNoteIds = new set(oldNotes.map(x => x.id));
		const oldPhoneNumbers = [...obj.phoneNumbers];
		const oldPhoneNumberIds = new set(oldPhoneNumbers.map(x => x.id));
		// Object IDs present on the user object, but NOT present within
		// editor objects, are deleted.
		const emailAddressIdsToDelete = oldEmailAddressIds
			.difference(emailAddressIds);
		const noteIdsToDelete = oldNoteIds
			.difference(noteIds);
		const phoneNumberIdsToDelete = oldPhoneNumberIds
			.difference(phoneNumberIds);
		// Object IDs present within editor objects, but NOT on the user
		// object, are created.
		const emailAddressIdsToCreate = emailAddressIds
			.difference(oldEmailAddressIds);
		const emailAddressesToCreate = emailAddresses
			.filter(x => emailAddressIdsToCreate.has(x.id));
		const noteIdsToCreate = noteIds
			.difference(oldNoteIds);
		const notesToCreate = notes
			.filter(x => noteIdsToCreate.has(x.id));
		const phoneNumberIdsToCreate = phoneNumberIds
			.difference(oldPhoneNumberIds);
		const phoneNumbersToCreate = phoneNumbers
			.filter(x => phoneNumberIdsToCreate.has(x.id));
		// Object IDs present for both the user object and editor objects are
		// updated.
		const emailAddressIdsToUpdate = emailAddressIds
			.difference(emailAddressIdsToDelete
				.union(emailAddressIdsToCreate));
		const emailAddressesToUpdate = oldEmailAddresses
			.filter(x => emailAddressIdsToUpdate.has(x.id));
		const noteIdsToUpdate = noteIds
			.difference(noteIdsToDelete
				.union(noteIdsToCreate));
		const notesToUpdate = oldNotes
			.filter(x => noteIdsToUpdate.has(x.id));
		const phoneNumberIdsToUpdate = phoneNumberIds
			.difference(phoneNumberIdsToDelete
				.union(phoneNumberIdsToCreate));
		const phoneNumbersToUpdate = oldPhoneNumbers
			.filter(x => phoneNumberIdsToUpdate.has(x.id));
		// Delete
		for (const objId of emailAddressIdsToDelete) {
			await this.deleteEmailAddress(
				this.currentDataObject.id,
				objId);
		}
		for (const objId of noteIdsToDelete) {
			await this.deleteNote(
				this.currentDataObject.id,
				objId);
		}
		for (const objId of phoneNumberIdsToDelete) {
			await this.deletePhoneNumber(
				this.currentDataObject.id,
				objId);
		}
		// Update
		for (const obj of emailAddressesToUpdate) {
			await this.updateEmailAddress(
				this.currentDataObject.id,
				obj.id,
				obj);
		}
		for (const obj of notesToUpdate) {
			await this.updateNote(
				this.currentDataObject.id,
				obj.id,
				obj);
		}
		for (const obj of phoneNumbersToUpdate) {
			await this.updatePhoneNumber(
				this.currentDataObject.id,
				obj.id,
				obj);
		}
		// Create
		for (const obj of emailAddressesToCreate) {
			await this.createEmailAddress(
				this.currentDataObject.id,
				obj);
		}
		for (const obj of notesToCreate) {
			await this.createNote(
				this.currentDataObject.id,
				obj);
		}
		for (const obj of phoneNumbersToCreate) {
			await this.createPhoneNumber(
				this.currentDataObject.id,
				obj);
		}
		let errorMsg: string = '';
		if (obj.qbCustomerId !== qbCustId) {
			let qbPk: string;
			let userPk: number | null;
			if (qbCustId.length > 0) {
				qbPk = qbCustId;
				userPk = obj.id;
			} else {
				qbPk = obj.qbCustomerId;
				userPk = null;
			}
			let err: Error | undefined = undefined;
			try {
				await this.updateQuickBooksCustomerId(qbPk, userPk);
			} catch (exc) {
				err = exc;
			}
			if (err) {
				if (isNetworkErrorObject(err) && err.response && err.response.data.error.message.trim().length > 0) {
					errorMsg = err.response.data.error.message;
				} else {
					errorMsg = err.message;
				}
			}
		}
		const updatedDataObject = await this.updateDataObject(obj.id, obj);
		this.replaceDataObject(updatedDataObject);
		if (errorMsg.length > 0) {
			this.openConfirmDialog(
				null,
				'There Was an Error',
				StandardButton.Ok,
				errorMsg);
			return false;
		}
		return true;
	}

	private setData(data: Array<IUser> | list<IUser>): void {
		this.beginSetData();
		this.data = Array.isArray(data) ?
			new list<IUser>(data) :
			data;
		this.dataTable.clearContents();
		const rowCount = this.data.size();
		const columnCount = this.dataTable.columnCount();
		this.dataTable.setRowCount(rowCount);
		const visibleUserUiColumns = this.visibleUserUiTableColumns();
		for (let row = 0; row < rowCount; ++row) {
			const obj = this.data.at(row);
			for (let column = 0; column < columnCount; ++column) {
				const userCol = visibleUserUiColumns[column];
				const uiCol = this.uiTableColumn(userCol.uiTableColumnId);
				const uiColName = uiCol ? uiCol.name : '';
				const itemData: Array<[ItemDataRole, Variant]> = [];
				switch (uiColName) {
					case ClientUserListColumnName.Name:
						itemData.push([
							ItemDataRole.DisplayRole,
							new Variant(obj.name),
						]);
						break;
					case ClientUserListColumnName.Email:
						itemData.push([
							ItemDataRole.DisplayRole,
							new Variant(obj.email),
						]);
						break;
					case ClientUserListColumnName.Group:
						itemData.push([
							ItemDataRole.DisplayRole,
							new Variant(obj.groupDisplay || '\u2014'),
						]);
						break;
					case ClientUserListColumnName.Type:
						itemData.push([
							ItemDataRole.DisplayRole,
							new Variant(obj.clientUserTypeDisplay || '\u2014'),
						]);
						break;
				}
				const tableItem = new ClientUserTableItem();
				tableItem.objectId = obj.id;
				for (const [role, val] of itemData) {
					tableItem.setData(role, val);
				}
				this.dataTable.setItem(row, column, tableItem);
			}
		}
		this.endSetData();
	}

	private async updateDataObject(pk: number | string, obj: IUser): Promise<IUser> {
		return await svc.group.client.user.update(pk, obj);
	}

	private async updateEmailAddress(userPk: number, pk: number, obj: IEmailAddress): Promise<IEmailAddress> {
		return await svc.group.client.user.updateAlternateEmailAddress(userPk, pk, obj);
	}

	private async updateEmailReceivesNotifications(obj: IUser, address: string): Promise<IUser | null> {
		if (address === obj.email) {
			return await svc.group.client.user.update(
				obj.id,
				{
					...obj,
					receiveNotifications: !obj.receiveNotifications,
				});
		}
		let altEmail: IEmailAddress | null = null;
		for (const emailObj of obj.emailAddresses) {
			if (emailObj.address === address) {
				altEmail = emailObj;
				break;
			}
		}
		if (altEmail) {
			await svc.group.client.user.updateAlternateEmailAddress(
				obj.id,
				altEmail.id,
				{
					...altEmail,
					receiveNotifications: !altEmail.receiveNotifications,
				});
			return await svc.group.client.user.get(obj.id);
		} else {
			logger.warning('updateEmailReceivesNotifications: Give address does not match any known addresses.');
			return null;
		}
	}

	private async updateNote(userPk: number, pk: number, obj: INote): Promise<INote> {
		return await svc.group.client.user.updateNote(userPk, pk, obj);
	}

	private async updatePhoneNumber(userPk: number, pk: number, obj: IPhoneNumber): Promise<IPhoneNumber> {
		return await svc.group.client.user.updatePhoneNumber(userPk, pk, obj);
	}

	private async updateQuickBooksCustomerId(pk: string, userPk: number | null): Promise<IQuickBooksCustomer> {
		const obj = await svc.group.app.quickbooks.customer.get(pk);
		obj.userId = userPk;
		return await svc.group.app.quickbooks.customer.update(obj.id, obj);
	}

	protected userUiTableChanged(): void {
		super.userUiTableChanged();
		this.setData(this.data);
	}
}

class ClientUserTableItem extends TableItem {
	objectId: number = -1;
}

function deepCopy(obj: IUser): IUser {
	const emailAddresses = obj.emailAddresses.map(x => ({...x}));
	const itemExclusions = [...obj.itemExclusions];
	const markets = [...obj.markets];
	const phoneNumbers = obj.phoneNumbers.map(x => ({...x}));
	const notes = obj.notes.map(x => ({...x}));
	return {
		...obj,
		emailAddresses,
		itemExclusions,
		markets,
		notes,
		phoneNumbers,
	};
}

function emailAddressFactory(userId: number): IEmailAddress {
	return {
		address: '',
		id: -1,
		label: '',
		receiveNotifications: true,
		userId: userId,
	};
}

function noteFactory(): INote {
	return {
		id: -1,
		label: '',
		text: '',
	};
}

function phoneNumberFactory(): IPhoneNumber {
	return {
		id: -1,
		label: '',
		number: '',
	};
}

import {getLogger} from '../../logging';
import {El} from '../../el';
import {list} from '../../tools';
import {IconButton, IconButtonClickEvt} from '../iconbutton';
import {TextInput, TextInputEvt} from '../textinput';
import {bind, isNumber} from '../../util';
import {Evt, EvtDispatcher} from '../../evt';
import {CCardToolButton} from './common';
import {CCardEdit, CCardEditSectionRow} from './edit';

const logger = getLogger('ui::ccard::rowctrl');

enum RowCtrlEvtType {
	RowAddButtonPressed = 4321,
	RowAdded,
	RowRemoveButtonPressed,
	RowRemoved,
}

export class RowCtrlEvt extends Evt {
	static RowAddButtonPressed: RowCtrlEvtType.RowAddButtonPressed = RowCtrlEvtType.RowAddButtonPressed;
	static RowAdded: RowCtrlEvtType.RowAdded = RowCtrlEvtType.RowAdded;
	static RowRemoveButtonPressed: RowCtrlEvtType.RowRemoveButtonPressed = RowCtrlEvtType.RowRemoveButtonPressed;
	static RowRemoved: RowCtrlEvtType.RowRemoved = RowCtrlEvtType.RowRemoved;

	private i: number;
	private r: CCardEditSectionRow | null;
	private t: string;

	constructor(type: number, row: CCardEditSectionRow | null = null, rowType: string = '', rowIndex: number = -1) {
		super(type);
		this.i = rowIndex;
		this.r = row;
		this.t = rowType;
	}

	row(): CCardEditSectionRow | null {
		return this.r;
	}

	rowIndex(): number {
		return this.i;
	}

	rowType(): string {
		return this.t;
	}
}

enum RowToolButtonType {
	Add,
	Remove,
}

interface FieldInfo {
	field: El;
	name: string;
}

interface RowData {
	el: CCardEditSectionRow | null;
	enabled: boolean;
	fields: list<FieldInfo>;
	toolButtons: Map<RowToolButtonType, IconButton>;
}

interface RowInfo {
	data: list<RowData>;
	iconName: string;
	index: number;
	many: boolean;
	sectionIndex: number;
	type: string;
}

interface InfoDump {
	rows: Array<{fields: Array<{name: string; value: string;}>}>;
	type: string;
}

export class CCardEditRowCtrl extends EvtDispatcher {
	edit: CCardEdit | null;
	private typeMap: Map<string, RowInfo>;

	constructor(edit: CCardEdit | null) {
		super();
		this.edit = edit;
		this.typeMap = new Map<string, RowInfo>();
	}

	addField(fieldName: string, rowType: string, rowIndex: number = -1, field: El | null = null): void {
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return;
		}
		const rowData = this.data(rowInfo, rowIndex);
		if (!rowData) {
			return;
		}
		if (!rowData.el) {
			logger.warning('addField: The parent row element is not defined.');
			return;
		}
		const idx = rowData.fields.findIndex(field => (field.name === fieldName));
		if (idx >= 0) {
			logger.warning('addField: Field with name "%s" already added.');
			return;
		}
		if (!field) {
			field = new TextInput({
				classNames: 'pb-text-input--underlined-less-dense-no-padding',
				inputId: `id_row-${rowInfo.index}-input-${rowData.fields.size()}`,
				labelText: fieldName,
			});
		}
		field.setEnabled(rowData.enabled);
		rowData.el.appendControl(field);
		rowData.fields.append({field, name: fieldName});
		field.onEvt(this.fieldEvt);
	}

	addRow(rowType: string, enabled: boolean = true): CCardEditSectionRow | null {
		if (!this.edit) {
			logger.warning('addRow: edit is not defined.');
			return null;
		}
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return null;
		}
		if (!rowInfo.many && !rowInfo.data.isEmpty()) {
			logger.warning('addRow: Trying to add additional row where many = false for type "%s".', rowType);
			return null;
		}
		const row = new CCardEditSectionRow({
			disabled: !enabled,
			leadingIconName: rowInfo.data.isEmpty() ?
				rowInfo.iconName :
				'',
		});
		const removeBtn = new CCardToolButton({
			classNames: ['some-rip', 'pb-icon-button--size-20-5'],
			iconName: 'close',
			smaller: true,
		});
		removeBtn.onEvt(this.toolButtonEvt);
		row.appendToolButton(removeBtn);
		const addBtn = new CCardToolButton({
			classNames: ['some-rip', 'pb-icon-button--size-20-5'],
			iconName: 'add_circle_outline',
			smaller: true,
		});
		addBtn.onEvt(this.toolButtonEvt);
		row.appendToolButton(addBtn);
		// Unset any row of this type which may have its "has data" flag set.
		for (const otherRow of rowInfo.data) {
			this.setRowHasData(otherRow, false);
		}
		const insertIndex = this.rowInsertIndex(rowInfo);
		rowInfo.data.append({
			toolButtons: new Map<RowToolButtonType, IconButton>([
				[RowToolButtonType.Add, addBtn],
				[RowToolButtonType.Remove, removeBtn],
			]),
			el: row,
			enabled,
			fields: new list<FieldInfo>(),
		});
		this.edit.insertSectionRow(
			insertIndex,
			row,
			rowInfo.sectionIndex);
		this.notifyEvt(
			new RowCtrlEvt(
				RowCtrlEvtType.RowAdded,
				row,
				rowInfo.type,
				rowInfo.data.size() - 1));
		return row;
	}

	clear(): void {
		for (const rowInfo of this.typeMap.values()) {
			for (const rowData of rowInfo.data) {
				this.destroyRow(rowData);
			}
			rowInfo.data.clear();
			rowInfo.iconName = '';
			rowInfo.index = -1;
			rowInfo.many = false;
			rowInfo.type = '';
		}
		this.typeMap.clear();
	}

	cloneRow(rowType: string, rowIndex: number): CCardEditSectionRow | null {
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return null;
		}
		const rowData = this.data(rowInfo, rowIndex);
		if (!rowData) {
			return null;
		}
		const row = this.addRow(rowType);
		if (!row) {
			return null;
		}
		for (let i = 0; i < rowData.fields.size(); ++i) {
			const fieldInfo = rowData.fields.at(i);
			this.addField(fieldInfo.name, rowType, -1, fieldInfo.field.clone());
			if (i === 0) {
				this.focusField(fieldInfo.name, rowType);
			}
		}
		return row;
	}

	private data(info: RowInfo, rowIndex: number): RowData | null {
		rowIndex = this.normRowIndex(rowIndex, info);
		if (rowIndex >= 0) {
			return info.data.at(rowIndex);
		}
		logger.warning('data: Invalid row index (%s) for type "%s"', rowIndex, info.type);
		return null;
	}

	destroy(): void {
		this.clear();
		this.edit = null;
	}

	private destroyRow(data: RowData): void {
		// DOES NOT REMOVE FROM ROW DATA COLLECTION
		for (const btn of data.toolButtons.values()) {
			btn.offEvt(this.toolButtonEvt);
			btn.destroy();
		}
		data.toolButtons.clear();
		for (const fieldInfo of data.fields) {
			fieldInfo.name = '';
			fieldInfo.field.offEvt(this.fieldEvt);
			fieldInfo.field.clear();
			fieldInfo.field.destroy();
		}
		data.fields.clear();
		if (data.el) {
			data.el.destroy();
		}
		data.el = null;
	}

	@bind
	private fieldEvt(evt: Evt): void {
		if ((evt.type() === Evt.Input) && (evt instanceof TextInputEvt)) {
			this.fieldInputEvt(evt);
		}
	}

	private fieldInfo(fieldName: string, rowType: string, rowIndex?: number): FieldInfo | null;
	private fieldInfo(fieldName: string, rowData: RowData): FieldInfo | null;
	private fieldInfo(a: string, b: RowData | string, c?: number): FieldInfo | null {
		const fieldName = a;
		let rowData: RowData;
		if (typeof b === 'string') {
			const rowType = b;
			const rowIndex = isNumber(c) ? c : -1;
			const info = this.info(rowType);
			if (!info) {
				return null;
			}
			const data = this.data(info, this.normRowIndex(rowIndex, info));
			if (!data) {
				return null;
			}
			rowData = data;
		} else {
			rowData = b;
		}
		for (const fieldInfo of rowData.fields) {
			if (fieldInfo.name === fieldName) {
				return fieldInfo;
			}
		}
		return null;
	}

	private fieldInputEvt(evt: TextInputEvt): void {
		const obj = evt.textInput();
		for (const rowInfo of this.typeMap.values()) {
			if (!rowInfo.many) {
				continue;
			}
			for (const rowData of rowInfo.data) {
				if (!rowData.fields.isEmpty() && (rowData.fields.first().field === obj)) {
					this.setRowHasData(rowData, Boolean(evt.text().trim()));
					return;
				}
			}
		}
	}

	fieldValue(fieldName: string, rowType: string, rowIndex: number = -1): string {
		const rowInfo = this.info(rowType);
		if (rowInfo) {
			const fieldInfo = this.fieldInfo(fieldName, rowType, rowIndex);
			if (fieldInfo) {
				return fieldInfo.field.value();
			}
		}
		return '';
	}

	focusField(fieldName: string, rowType: string, rowIndex: number = -1): void {
		const info = this.info(rowType);
		if (!info) {
			return;
		}
		const fieldInfo = this.fieldInfo(fieldName, rowType, rowIndex);
		if (fieldInfo) {
			fieldInfo.field.focus();
		}
	}

	focusFieldDelayed(delay: number, fieldName: string, rowType: string, rowIndex: number = -1): void {
		setTimeout(() => this.focusField(fieldName, rowType, rowIndex), delay);
	}

	hasRowType(rowType: string): boolean {
		return this.typeMap.has(rowType);
	}

	private info(type: string): RowInfo | null {
		const rv = this.typeMap.get(type);
		if (rv) {
			return rv;
		}
		logger.warning('Row type "%s" not registered', type);
		return null;
	}

	isRowEnabled(rowType: string, rowIndex: number): boolean {
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return false;
		}
		const rowData = this.data(rowInfo, rowIndex);
		if (!rowData) {
			return false;
		}
		return rowData.enabled;
	}

	private normRowIndex(rowIndex: number, info: RowInfo): number {
		if (rowIndex < 0) {
			rowIndex = info.data.size() - 1;
		}
		if (rowIndex >= 0 && rowIndex < info.data.size()) {
			return rowIndex;
		}
		return -1;
	}

	registerRowType(type: string, iconName: string, many: boolean, sectionIndex: number): void {
		if (this.typeMap.has(type)) {
			logger.warning('registerRowType: type already registered');
			return;
		}
		this.typeMap.set(type, {
			data: new list<RowData>(),
			iconName,
			index: this.typeMap.size,
			many,
			sectionIndex,
			type,
		});
	}

	removeRow(type: string, index: number): void {
		const info = this.info(type);
		if (!info) {
			return;
		}
		const data = this.data(info, index);
		if (!data) {
			return;
		}
		// Check if this row has the designated row icon (for use at the end).
		// Do it now because the next routine destroys the element.
		const rowEl = data.el;
		const hadIcon = rowEl ?
			(rowEl.leadingIconName().length > 0) :
			false;
		this.destroyRow(data);
		// We had a RowData object returned to us which means the normalized
		// row index is a a valid index.
		const normIndex = this.normRowIndex(index, info);
		const wasLast = normIndex === (info.data.size() - 1);
		info.data.removeAt(normIndex);
		// If the row just removed was the row displaying the row icon, there
		// is now no row displaying the icon. Find the top-most row and set
		// the icon there.
		if (hadIcon && !info.data.isEmpty()) {
			const topRow = info.data.first();
			if (topRow.el) {
				topRow.el.setLeadingIconName(info.iconName);
			}
		}
		// If we just removed the last (as in index) row of this type, we
		// need to check the "new" last row to see if we need to mark it as
		// having data.
		if (wasLast && !info.data.isEmpty()) {
			const newLast = info.data.last();
			this.setRowHasData(
				newLast,
				newLast.fields.isEmpty() ?
					false :
					(newLast.fields.first().field.value().trim().length > 0));
		}
		this.notifyEvt(new RowCtrlEvt(RowCtrlEvt.RowRemoved, rowEl));
	}

	rowCount(rowType: string): number {
		const info = this.info(rowType);
		if (info) {
			return info.data.size();
		}
		return 0;
	}

	rowIndex(row: CCardEditSectionRow): number {
		for (const rowInfo of this.typeMap.values()) {
			for (let i = 0; i < rowInfo.data.size(); ++i) {
				if (rowInfo.data.at(i).el === row) {
					return i;
				}
			}
		}
		return -1;
	}

	private rowInsertIndex(info: RowInfo): number {
		let rv: number = 0;
		for (const rowInfo of this.typeMap.values()) {
			if ((rowInfo.sectionIndex === info.sectionIndex) && (rowInfo.index < info.index)) {
				rv += rowInfo.data.size();
			}
		}
		return rv + info.data.size();
	}

	setFieldValue(value: string, fieldName: string, rowType: string, rowIndex: number = -1): void {
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return;
		}
		const rowData = this.data(rowInfo, rowIndex);
		if (!rowData) {
			return;
		}
		const fieldInfo = this.fieldInfo(fieldName, rowData);
		if (!fieldInfo) {
			return;
		}
		fieldInfo.field.setValue(value);
		if (rowData.fields.first() === fieldInfo) {
			this.setRowHasData(
				rowData,
				rowInfo.many && Boolean(value.trim()));
		}
	}

	setRowEnabled(enabled: boolean, rowType: string, rowIndex: number): void {
		const rowInfo = this.info(rowType);
		if (!rowInfo) {
			return;
		}
		const rowData = this.data(rowInfo, rowIndex);
		if (!rowData) {
			return;
		}
		rowData.enabled = enabled;
		rowData.el && rowData.el.setEnabled(enabled);
		for (let i = 0; i < rowData.fields.size(); ++i) {
			const fieldData = rowData.fields.at(i);
			fieldData.field.setEnabled(enabled);
		}
	}

	private setRowHasData(rowData: RowData, hasData: boolean): void {
		if (rowData.el) {
			rowData.el.setClass(hasData, 'pb-cc-edit-section__row--with-data');
		} else {
			logger.warning('setRowHasData: RowData argument has no element defined.');
		}
	}

	private toolButtonMouseClickEvt(evt: IconButtonClickEvt): void {
		const btn = evt.iconButton();
		for (const rowInfo of this.typeMap.values()) {
			for (let i = 0; i < rowInfo.data.size(); ++i) {
				const rowData = rowInfo.data.at(i);
				for (const [btnType, toolBtn] of rowData.toolButtons) {
					if (toolBtn === btn) {
						switch (btnType) {
							case RowToolButtonType.Add: {
								this.notifyEvt(
									new RowCtrlEvt(
										RowCtrlEvtType.RowAddButtonPressed,
										rowData.el,
										rowInfo.type));
								break;
							}
							case RowToolButtonType.Remove: {
								if (rowInfo.data.size() === 1) {
									for (const fieldInfo of rowData.fields) {
										fieldInfo.field.clear();
									}
								} else if (rowInfo.data.size() > 1) {
									this.notifyEvt(
										new RowCtrlEvt(
											RowCtrlEvtType.RowRemoveButtonPressed,
											rowData.el,
											rowInfo.type,
											i));
								}
								break;
							}
						}
						return;
					}
				}
			}
		}
	}

	@bind
	private toolButtonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			this.toolButtonMouseClickEvt(evt);
		}
	}

	values(): Array<InfoDump> {
		const rv: Array<InfoDump> = [];
		for (const [type, rowInfo] of this.typeMap) {
			const dump: InfoDump = {
				rows: [],
				type,
			};
			rv.push(dump);
			for (const rowData of rowInfo.data) {
				const fieldDump: Array<{name: string; value: string;}> = [];
				for (const fieldInfo of rowData.fields) {
					fieldDump.push({
						name: fieldInfo.name,
						value: fieldInfo.field.value(),
					});
				}
				dump.rows.push({fields: fieldDump});
			}
		}
		return rv;
	}
}

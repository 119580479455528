import {Form} from '../ui/form';
import {ContactCard} from '../ui/contactcard';
import {TextInput} from '../ui/textinput';
import {StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {bind, closestMatchingElement, isNumber} from '../util';
import {DataTable} from '../ui/datatable';
import {CRUDView, ViewState} from './crudview';
import {Dialog} from '../ui/dialog';
import {HBoxLayout, VBoxLayout} from '../ui/layout';
import {El} from '../el';
import {Evt} from '../evt';
import {IconButtonClickEvt} from '../ui/iconbutton';

class NumField extends TextInput {
	floatValue(): number | null {
		const val = Number.parseFloat(this.value());
		return Number.isNaN(val) ? null : val;
	}

	setFloatValue(value: number | string | null): void {
		this.setValue((value === null) ? '' : String(value));
	}
}

class MarketForm extends Form {
	cityInput: TextInput;
	latitudeInput: NumField;
	longitudeInput: NumField;

	constructor() {
		super();
		const rootVBox = new VBoxLayout(this);
		rootVBox.setSpacing(16);
		this.cityInput = new TextInput({
			inputId: 'id_city-label',
			labelText: 'Name',
			name: 'city',
			required: true,
		});
		rootVBox.addEl(this.cityInput);
		const coordHBox = new HBoxLayout();
		coordHBox.setSpacing(8);
		this.latitudeInput = new NumField({
			inputId: 'id_latitude-label',
			labelText: 'Latitude',
			name: 'latitude',
		});
		coordHBox.addEl(this.latitudeInput);
		this.longitudeInput = new NumField({
			inputId: 'id_longitude-label',
			name: 'longitude',
			labelText: 'Longitude',
		});
		coordHBox.addEl(this.longitudeInput);
		rootVBox.addEl(coordHBox);
		this.setMethod('POST');
	}
}

class TableObject {
	_view: View;

	constructor(view: View) {
		this._view = view;
	}

	remove(id: number): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const el = tableEl.querySelector(`[data-row-id="${id}"]`);
			if (el) {
				el.destroy();
			}
		}
	}

	setData(data: IMarket): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const row = tableEl.querySelector(`[data-row-id="${data.id}"]`);
			if (row) {
				const [cityCell] = row.querySelectorAll('th,td');
				cityCell.setText(data.city);
			}
		}
	}
}

class View extends CRUDView {
	static CREATE_CTA_SELECTOR = '#id_object-create-button';
	static OBJECT_COLLECTION_SELECTOR = '#id_object-data-table tbody';
	static OBJECT_SELECTOR = 'tr[data-row-id] td, tr[data-row-id] th';

	contactCard: ContactCard;
	dia: Dialog;
	form: MarketForm;
	objectId: number | null;
	objectTable: DataTable;

	constructor() {
		super();
		this.contactCard = new ContactCard();
		this.contactCard.setIcon('pin_drop');
		this.form = new MarketForm();
		this.contactCard.section().appendChild(this.form);
		this.objectId = null;
		this.objectTable = new DataTable();
		this.dia = new Dialog();
		this.dia.setContent(this.contactCard);
	}

	init(): void {
		this.contactCard.onEvt(this.cardActionEvt);
		this.form.setId('id_object-form');
		let elem: Element | null = document.querySelector(View.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this.createCTAClickEvent);
		}
		document.querySelectorAll(View.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectClickEvent));
	}

	objectData(): Promise<IMarket> {
		return svc.group.market.get(this.objectIdOrFail());
	}

	private objectIdOrFail(): number {
		if (isNumber(this.objectId)) {
			return this.objectId;
		}
		throw new Error('View::objectID object ID is null');
	}

	protected async deleteObject(): Promise<void> {
		const data = await this.objectData();
		await svc.group.market.delete(data.id);
		this.removeDocumentObject(data.id);
	}

	protected async openCreateDialog(): Promise<void> {
		this.contactCard.setDetailVisible(true);
		this.form.onSubmit();
		this.form.setAction(window.pburls.group.market.list);
		this.form.cityInput.setValue('');
		this.form.latitudeInput.setValue('');
		this.form.longitudeInput.setValue('');
		this.form.show();
		this.dia.showFooter();
		this.contactCard.setHeading('Create market');
		this.contactCard.setToolbarButtonVisible('edit', false);
		this.contactCard.setToolbarButtonVisible('more', false);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.dia.setStandardButtons(StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	protected async openDeleteDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setHeading(`Delete "${data.city}"?`);
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			// this.dia.setDefaultButton(noBtn);
			noBtn.setRaised(true);
		}
		this.openDia();
	}

	protected async openDetailDialog(): Promise<void> {
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(false);
		this.dia.hideFooter();
		this.setCardData(await this.objectData());
		this.openDia();
	}

	protected async openEditDialog(): Promise<void> {
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(true);
		this.form.onSubmit(this.editFormSubmitEvent);
		this.form.setAction('');
		const data = await this.objectData();
		this.form.cityInput.setValue(data.city);
		this.form.latitudeInput.setFloatValue(data.latitude);
		this.form.longitudeInput.setFloatValue(data.longitude);
		this.form.show();
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	@bind
	private async cardActionEvt(evt: Evt): Promise<void> {
		if (evt instanceof IconButtonClickEvt) {
			switch (evt.iconButton().name()) {
				case 'edit':
					if (this.state === ViewState.EditState) {
						this.setState(ViewState.DetailState);
					} else {
						this.setState(ViewState.EditState);
					}
					break;
				case 'delete':
					this.setState(ViewState.DeleteState);
					break;
				case 'close':
					this.setState(ViewState.NoState);
					break;
			}
		} else if (evt.type() === Evt.Delete) {
			this.setState(ViewState.DeleteState);
		}
	}

	@bind
	private createCTAClickEvent(): void {
		this.setState(ViewState.CreateState);
	}

	@bind
	private async editFormSubmitEvent(): Promise<void> {
		let data = await this.objectData();
		const id = this.objectIdOrFail();
		const city = this.form.cityInput.value();
		const latitude = this.form.latitudeInput.floatValue();
		const longitude = this.form.longitudeInput.floatValue();
		data = await svc.group.market.update(id, {...data, city, latitude, longitude, id});
		this.syncDocumentObject(data);
		this.setState(ViewState.DetailState);
	}

	@bind
	private async objectClickEvent(event: Event): Promise<void> {
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::objectClickEvent event target is null');
			return;
		}
		const elem = new El(closestMatchingElement(tgt, 'tr[data-row-id]'));
		const id = Number(elem.attribute('data-row-id'));
		if (Number.isNaN(id)) {
			console.log('View::objectClickEvent event target data-row-id attribute did not yield valid value');
			return;
		}
		this.objectId = id;
		this.setState(ViewState.DetailState);
	}

	private removeDocumentObject(objectID: number): void {
		(new TableObject(this)).remove(objectID);
	}

	private setCardData(data: IMarket): void {
		this.contactCard.setHeading(data.city);
		this.contactCard.setSubheading((isNumber(data.latitude) && isNumber(data.longitude)) ? `${data.latitude}, ${data.longitude}` : '');
	}

	private syncDocumentObject(data: IMarket): void {
		(new TableObject(this)).setData(data);
	}
}

export function init(): void {
	const comp = new View();
	comp.init();
}

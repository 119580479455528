import React from 'react';

import ItemPrice from './itemprice';
import {set} from '../../../../tools';
import ButtonCTAMenu from './buttonctamenu';
import {cssClassName} from '../../../../util';
import {closestMatchingElement} from '../../../../util/dom';

interface IProps {
	objects: IPriceGroupItemPrice[];
	onAdd: (priceGroupID: number) => any;
	onChange: (object: IPriceGroupItemPrice) => any;
	onDelete: (object: IPriceGroupItemPrice) => any;
	priceGroups: IPriceGroup[];
	takeFocus?: boolean;
}

interface State {
	confirmDeleteObject: IPriceGroupItemPrice | null;
}

export default class ItemPriceList extends React.Component<IProps, State> {
	constructor(props: IProps) {
		super(props);
		this.menuSelect = this.menuSelect.bind(this);
		this.deletePrice = this.deletePrice.bind(this);
		this.priceChanged = this.priceChanged.bind(this);
		this.deleteConfirm = this.deleteConfirm.bind(this);
		this.deleteRequested = this.deleteRequested.bind(this);
		this.state = {
			confirmDeleteObject: null,
		};
	}

	addPrice(priceGroupID: number): void {
		const {onAdd} = this.props;
		onAdd(priceGroupID);
	}

	deletePrice(itemPriceID: number): void {
		const {objects, onDelete} = this.props;
		const idx = objects.findIndex(obj => (obj.id === itemPriceID));
		const obj = objects[idx];
		if (obj) {
			onDelete(obj);
		} else {
			console.log(`ItemPriceList::deletePrice object not found at index ${idx}`);
		}
	}

	deleteRequested(itemPriceID: number): void {
		const {objects} = this.props;
		const idx = objects.findIndex(obj => (obj.id === itemPriceID));
		const obj = objects[idx];
		if (obj) {
			this.setState({confirmDeleteObject: obj});
		} else {
			console.log(`ItemPriceList::deleteRequested object not found at index ${idx}`);
		}
	}

	deleteConfirm(event: React.MouseEvent): void {
		const {confirmDeleteObject} = this.state;
		if (confirmDeleteObject) {
			const elem = event.target as HTMLElement;
			const btn = closestMatchingElement<HTMLButtonElement>(elem, 'button');
			if (btn) {
				if (btn.value.trim().toLowerCase() === 'yes') {
					this.deletePrice(confirmDeleteObject.id);
				}
			} else {
				console.log(`ItemPriceList::deleteConfirm invalid event target`);
			}
			this.setState({confirmDeleteObject: null});
		} else {
			console.log(`ItemPriceList::deleteConfirm confirm object is null`);
		}
	}

	menuSelect(index: number): void {
		const objs = this.priceGroupsWithoutItemPrice();
		const obj = objs[index];
		if (obj) {
			this.addPrice(obj.id);
		} else {
			console.log(`ItemPriceList::menuSelect object not found at index ${index}`);
		}
	}

	priceChanged(value: string, objID: number): void {
		const {objects, onChange} = this.props;
		const idx = objects.findIndex(obj => (obj.id === objID));
		const obj = objects[idx];
		if (obj) {
			onChange(Object.assign<object, IPriceGroupItemPrice, Pick<IPriceGroupItemPrice, 'price'>>({}, obj, {price: value}));
		} else {
			console.log(`ItemPriceList::priceChanged object with ID ${objID} was not found`);
		}
	}

	priceGroupName(priceGroupID: number): string {
		const {priceGroups} = this.props;
		const idx = priceGroups.findIndex(obj => (obj.id === priceGroupID));
		const obj = priceGroups[idx];
		if (obj) {
			return obj.name;
		}
		console.log(`ItemForm::priceGroupName No object was found for ID ${priceGroupID}`);
		return '';
	}

	priceGroupsWithoutItemPrice(): IPriceGroup[] {
		const {objects, priceGroups} = this.props;
		const priceGroupIDsWithPrices = new set<number>(objects.map(obj => obj.priceGroupId));
		return priceGroups.filter(obj => !priceGroupIDsWithPrices.has(obj.id));
	}

	priceLabel(priceGroupID: number): string {
		const name = this.priceGroupName(priceGroupID);
		return name ? `${name} price` : 'Price';
	}

	render(): React.ReactNode {
		const {objects, takeFocus} = this.props;
		const {confirmDeleteObject} = this.state;
		const availPriceGroups = this.priceGroupsWithoutItemPrice();
		if (objects.length > 0) {
			const haveAvailPriceGroups = availPriceGroups.length > 0;
			return (
				<div className="display--flex flex-direction--column">
					{confirmDeleteObject ?
						<ConfirmDelete
							onClick={this.deleteConfirm}
							text={`Delete ${this.priceLabel(confirmDeleteObject.priceGroupId)}?`}/> :
						<React.Fragment>
							{objects.map(obj =>
								<ItemPrice
									id={obj.id}
									key={obj.id}
									label={this.priceLabel(obj.priceGroupId)}
									onChange={this.priceChanged}
									onDelete={this.deleteRequested}
									takeFocus={takeFocus}
									value={obj.price}/>)}
							{haveAvailPriceGroups ?
								<EmptyListButton
									alignment="start"
									className="margin-top--16"
									onSelect={this.menuSelect}
									priceGroups={availPriceGroups}/> :
								null}
						</React.Fragment>}
				</div>
			);
		} else {
			return <EmptyListButton
				onSelect={this.menuSelect}
				priceGroups={availPriceGroups}/>;
		}
	}
}

function Button({className, onClick, text, value}: {className?: string; onClick: React.MouseEventHandler; text: string; value: string;}) {
	return (
		<button className={cssClassName('mdc-button', className)} onClick={onClick} type="button" value={value}>
			<span className="mdc-button__ripple"/>
			<span className="mdc-button__label">{text}</span>
		</button>
	);
}

function ConfirmDelete({onClick, text}: {onClick: React.MouseEventHandler; text: string;}) {
	return (
		<div className="align-self--center">
			<div className="text-align--center">
				{text}
			</div>
			<div>
				<Button
					onClick={onClick}
					text="Keep"
					value="no"/>
				<Button
					className="pb-button--color-danger"
					onClick={onClick}
					text="Delete"
					value="yes"/>
			</div>
		</div>
	);
}

function EmptyListButton({alignment, className, onSelect, priceGroups}: {alignment?: 'start' | 'center' | 'end'; className?: string; onSelect: (index: number) => any; priceGroups: IPriceGroup[]}) {
	alignment = alignment ? alignment : 'center';
	const clsObj = {
		'justify-content--flex-start': (alignment === 'start'),
		'justify-content--center': (alignment === 'center'),
		'justify-content--flex-end': (alignment === 'end'),
	};
	return (
		<div className={cssClassName('display--flex', 'flex-direction--row', className, clsObj)}>
			<ButtonCTAMenu
				onSelect={onSelect}
				options={priceGroups.map(obj => ({id: obj.id, text: obj.name}))}
				text="Add price"/>
		</div>
	);
}

import {apiService as svc} from '../services';
import {isNumber} from '../util';
import {El} from '../el';

export function init() {
	document.querySelectorAll('.mdc-switch input[type="checkbox"]')
		.forEach(node => node.addEventListener('change', changeEvent));
}

async function changeEvent(event: Event): Promise<void> {
	const elem = El.fromEvent(event);
	const idStr = elem.attribute('value');
	const id = idStr && Number.parseInt(idStr);
	if (isNumber(id)) {
		const data = await svc.group.subscription.get(id);
		data.active = !data.active;
		await svc.group.subscription.update(id, data);
	} else {
		console.log('groupsubscriptions::changeEvent: Got invalid integer for object id');
	}
}

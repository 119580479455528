import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.AllHTMLAttributes<any> {
	primaryText?: string;
	secondaryText?: string;
	tag?: keyof React.ReactDOM;
}

type Props = PropsWithChildren<IProps>;

export class ListItemText extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {
			tag,
			children,
			className,
			primaryText,
			secondaryText,
			...htmlAttrs
		} = this.props;
		const inner = secondaryText ?
			[
				<div className="mdc-list-item__primary-text" key="primary-text">{primaryText}</div>,
				<div className="mdc-list-item__secondary-text" key="secondary-text">{secondaryText}</div>,
			] :
			[primaryText];
		return React.createElement(
			tag ? tag : 'div',
			{className: cssClassName('mdc-list-item__text', 'pb-list-item__text', className), ...htmlAttrs},
			inner, children);
	}
}

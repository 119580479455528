import {isNumber, numberFormat} from './util';

// Heads-up: This is not ever to be considered an accurate representation of
//           a decimal or floating point number. This is not an implementation
//           of decimal/floating-point logic or related mathematical
//           operations. This exists, more or less, to represent a type for
//           Variant.

export class Decimal {
	private v: number | string;
	private plcs: number;

	constructor(value: number | string | null, places: number);
	constructor(value?: number | string | null);
	constructor(a?: number | string | null, b?: number) {
		let places: number = 7;
		let value: number | string = '';
		if ((a !== undefined) && (a !== null)) {
			value = a;
		}
		if (b !== undefined) {
			places = b;
		}
		this.plcs = places;
		this.v = value;
	}

	cmp(other: Decimal): number {
		const a = Number(this);
		const b = Number(other);
		if (a > b) {
			return 1;
		}
		if (a < b) {
			return -1;
		}
		return 0;
	}

	eq(other: Decimal): boolean {
		return this.cmp(other) === 0;
	}

	gt(other: Decimal): boolean {
		return this.cmp(other) > 0;
	}

	lt(other: Decimal): boolean {
		return this.cmp(other) < 0;
	}

	places(): number {
		return this.plcs;
	}

	setPlaces(places: number): void {
		if (places >= 0) {
			this.plcs = places;
		} else {
			console.log('Decimal::setPlaces: Got invalid value <%s> %s', typeof places, places);
		}
	}

	setValue(value: number | string): void {
		this.v = value;
	}

	toString(): string {
		return this._toString();
	}

	private _toString(): string {
		return (isNumber(this.v) ? this.v : Number(this.v)).toFixed(this.plcs);
	}

	value(): string {
		return this._toString();
	}

	valueOf(): string {
		return this._toString();
	}

	[Symbol.toPrimitive](hint: string): number | string {
		if (hint === 'number') {
			return isNumber(this.v) ? this.v : Number(this.v);
		}
		return this.toString();
	}

	[Symbol.toStringTag](): string {
		return 'Decimal';
	}
}

export class CurrentDecimal extends Decimal {
	toString(): string {
		return `$${numberFormat(super.toString())}`;
	}
}

import {ResourceService} from './resource';

export class SuperSecretPreviewService extends ResourceService {
	static uri: string = window.pburls.supersecret || '';

	async preview(data?: any, cfg?: Omit<ResourceQueryRequestConfig, 'wellKnown'>): Promise<string> {
		cfg = cfg || {};
		cfg.responseType = 'text';
		let uri = SuperSecretPreviewService.uri;
		if (uri) {
			uri = this.url('preview');
		}
		return (await this.POST<string>(uri, data, cfg)).data;
	}
}

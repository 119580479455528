import {setError} from './error';
import {apiService as svc} from '../../services';
import {RECEIVE_GROUP_PRODUCER_CATALOG, RESET_CATALOG} from './types';

type CatalogAction = DataAction<ICatalog, typeof RECEIVE_GROUP_PRODUCER_CATALOG | typeof RESET_CATALOG>;
export type CatalogActionTypes = CatalogAction;

export function groupProducerCatalog(clientUserId?: number): FuncAction<Promise<CatalogAction>, CatalogAction> {
	return function (dispatch) {
		return svc.group.producer.catalog(clientUserId)
			.then(data => dispatch(receiveGroupProducerCatalog(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function receiveGroupProducerCatalog(data: ICatalog): CatalogActionTypes {
	return {
		data,
		type: RECEIVE_GROUP_PRODUCER_CATALOG,
	};
}

export function resetCatalog(): CatalogActionTypes {
	return {
		data: {choices: [], items: []},
		type: RESET_CATALOG,
	};
}

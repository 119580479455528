import React, {PropsWithChildren} from 'react';
import {
	MDCNotchedOutlineAdapter,
	MDCNotchedOutlineFoundation,
} from '@material/notched-outline';

import {cssClassName} from '../../../util';

type Props = PropsWithChildren<{noLabel?: boolean;}>;
type State = {classNames: Set<string>;};

export default class Outline extends React.Component<Props, State> {
	private _mdc: MDCNotchedOutlineFoundation | null;
	private _notchRef: React.RefObject<HTMLDivElement>;
	private _rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdcRemoveNotchWidthProperty = this._mdcRemoveNotchWidthProperty.bind(this);
		this._mdcSetNotchWidthProperty = this._mdcSetNotchWidthProperty.bind(this);
		this._mdc = null;
		this._notchRef = React.createRef();
		this._rootRef = React.createRef();
		this.state = {classNames: new Set(['mdc-notched-outline'])};
	}

	closeNotch(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.closeNotch();
		}
	}

	componentDidMount(): void {
		this._mdc = new MDCNotchedOutlineFoundation(this._mdcAdapter());
		this._mdc.init();
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	render(): React.ReactNode {
		const {children, noLabel} = this.props;
		const {classNames} = this.state;
		return (
			<div className={cssClassName(...classNames)} ref={this._rootRef}>
				<div className="mdc-notched-outline__leading"/>
				{noLabel ?
					null :
					<div className="mdc-notched-outline__notch" ref={this._notchRef}>
						{children}
					</div>}
				<div className="mdc-notched-outline__trailing"/>
			</div>
		);
	}

	setNotch(value: number): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.notch(value);
		}
	}

	private _mdcAdapter(): MDCNotchedOutlineAdapter {
		return {
			addClass: this._mdcAddClass,
			removeClass: this._mdcRemoveClass,
			removeNotchWidthProperty: this._mdcRemoveNotchWidthProperty,
			setNotchWidthProperty: this._mdcSetNotchWidthProperty,
		};
	}

	private _mdcAddClass(value: string): void {
		const {classNames} = this.state;
		classNames.add(value);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcRemoveClass(value: string): void {
		const {classNames} = this.state;
		classNames.delete(value);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcRemoveNotchWidthProperty(): void {
		const elem = this._notchRef.current;
		if (elem) {
			elem.style.removeProperty('width');
		}
	}

	private _mdcSetNotchWidthProperty(value: number): void {
		const elem = this._notchRef.current;
		if (elem) {
			elem.style.setProperty('width', `${value}px`);
		}
	}
}

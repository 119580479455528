import React from 'react';

interface IProps {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	focusOnMount?: boolean;
	placeholder?: string;
	id?: string;
	max?: number | string;
	min?: number | string;
	name?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
	onInput?: (event: React.FormEvent<HTMLInputElement>) => any;
	required?: boolean;
	step?: number | string;
	title?: string;
	type?: string;
	value?: string | number;
}

type Props = IProps;

export default class Input extends React.Component<Props, {}> {
	elem: HTMLInputElement | null;

	constructor(props: Props) {
		super(props);
		this.setRef = this.setRef.bind(this);
		this.elem = null;
	}

	componentDidMount(): void {
		if ((this.elem !== null) && (this.props.focusOnMount)) {
			this.elem.focus();
		}
	}

	render(): React.ReactNode {
		const {checked, className, disabled, id, max, min, name, onChange, onInput, placeholder, required, step, title, type, value} = this.props;
		return (
			<input
				checked={checked}
				className={className}
				disabled={disabled}
				id={id}
				max={max}
				min={min}
				name={name}
				onInput={onInput}
				onChange={onChange}
				placeholder={placeholder}
				ref={this.setRef}
				required={required}
				step={step}
				title={title}
				type={type || 'text'}
				value={value}/>
		);
	}

	setRef(elem: HTMLInputElement): void {
		this.elem = elem;
	}
}

import {El} from '../el';
import {pixelString} from '../util';

class LayoutItem extends El {
	constructor(parent: El | null = null) {
		super('div', parent);
		this.addClass('pb-layout__layout-item');
	}
}

class SpacerItem extends LayoutItem {
	constructor(width: number = 0, height: number = 0) {
		super();
		if (width) {
			this.setStyleProperty('width', pixelString(width));
		}
		if (height) {
			this.setStyleProperty('height', pixelString(height));
		}
	}
}

export class Layout extends LayoutItem {
	protected spacing: number;

	constructor(parent: El | null = null) {
		super(parent);
		this.spacing = -1;
		this.addClass('pb-layout__layout');
	}

	addEl(el: El): void {
		this.appendChild(el);
	}

	addSpacing(size: number): void {
	}

	setSpacing(spacing?: number): void {
		if (spacing === undefined) {
			spacing = -1;
		}
		this.spacing = Math.max(-1, spacing);
	}

	update(): void {
		const parent = this.parent();
		if (parent) {
			this.setVisible(parent.isVisible());
		}
	}
}

class BoxLayout extends Layout {
	constructor(parent: El | null = null) {
		super(parent);
		this.addClass('pb-layout__box-layout');
	}
}

export class HBoxLayout extends BoxLayout {
	constructor(parent: El | null = null) {
		super(parent);
		this.addClass('pb-layout__h-box-layout');
	}

	addEl(el: El): void {
		super.addEl(el);
		const count = this.children().size();
		if (count < 1) {
			return;
		}
		if (count === 1 || this.spacing < 0) {
			el.removeStyleProperty('margin-left');
			return;
		}
		if (this.spacing >= 0) {
			el.setStyleProperty('margin-left', pixelString(this.spacing));
		}
	}

	addSpacing(size: number): void {
		super.addSpacing(size);
		this.addEl(new SpacerItem(size));
	}

	update(): void {
		super.update();
		const count = this.children().size();
		if (count < 1) {
			return;
		}
		this.children().at(0).removeStyleProperty('margin-left');
		if (count === 1) {
			return;
		}
		for (let i = 1; i < this.children().size(); ++i) {
			const child = this.children().at(i);
			if (this.spacing >= 0) {
				child.setStyleProperty('margin-left', pixelString(this.spacing));
			} else {
				child.removeStyleProperty('margin-left');
			}
		}
	}
}

export class VBoxLayout extends BoxLayout {
	constructor(parent: El | null = null) {
		super(parent);
		this.addClass('pb-layout__v-box-layout');
	}

	addEl(el: El): void {
		super.addEl(el);
		const count = this.children().size();
		if (count < 1) {
			return;
		}
		if (count === 1 || this.spacing < 0) {
			el.removeStyleProperty('margin-top');
			return;
		}
		if (this.spacing >= 0) {
			el.setStyleProperty('margin-top', pixelString(this.spacing));
		}
	}

	addSpacing(size: number): void {
		super.addSpacing(size);
		this.addEl(new SpacerItem(1, size));
	}

	update(): void {
		super.update();
		const count = this.children().size();
		if (count < 1) {
			return;
		}
		this.children().at(0).removeStyleProperty('margin-top');
		if (count === 1) {
			return;
		}
		for (let i = 1; i < this.children().size(); ++i) {
			const child = this.children().at(i);
			if (this.spacing >= 0) {
				child.setStyleProperty('margin-top', pixelString(this.spacing));
			} else {
				child.removeStyleProperty('margin-top');
			}
		}
	}
}

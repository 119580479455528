import {ModelIndex} from '../../itemmodel';
import {list, Point} from '../../tools';
import {Orientation, SortOrder} from '../../constants';
import {Evt, EvtType} from '../../evt';

export enum DataTableEvtType {
	HeaderSectionChange = 5000,
	ItemChange,
	ItemClick,
	SectionMove,
	SectionSelectionChange,
	SectionClick,
	Sort,
}

type Move = {fromSection: number; toSection: number;};
type EvtArgs = {index: ModelIndex; move: Move; clientPos: Point; rightClick: boolean; orientation: Orientation;}

export class DataTableEvt extends Evt {
	static HeaderSectionChange: DataTableEvtType.HeaderSectionChange = DataTableEvtType.HeaderSectionChange;
	static ItemChange: DataTableEvtType.ItemChange = DataTableEvtType.ItemChange;
	static ItemClick: DataTableEvtType.ItemClick = DataTableEvtType.ItemClick;
	static SectionMove: DataTableEvtType.SectionMove = DataTableEvtType.SectionMove;
	static SectionSelectionChange: DataTableEvtType.SectionSelectionChange = DataTableEvtType.SectionSelectionChange;
	static SectionClick: DataTableEvtType.SectionClick = DataTableEvtType.SectionClick;
	static Sort: DataTableEvtType.Sort = DataTableEvtType.Sort;

	private cp: Point;
	private i: ModelIndex;
	private m: Move;
	private o: Orientation;
	private rc: boolean;

	constructor(type: DataTableEvtType, args?: Partial<EvtArgs>) {
		super(type);
		this.setAccepted(false);
		this.cp = new Point(-1, -1);
		this.i = new ModelIndex();
		this.m = {fromSection: -1, toSection: -1};
		this.o = -1;
		this.rc = false;
		if (args) {
			if (args.index) {
				this.i = args.index;
			}
			if (args.move) {
				this.m = args.move;
			}
			if (args.orientation !== undefined) {
				this.o = args.orientation;
			}
			if (args.clientPos) {
				this.cp = args.clientPos;
			}
			if (args.rightClick) {
				this.rc = true;
			}
		}
	}

	clientPos(): Point {
		return this.cp;
	}

	fromSection(): number {
		return this.m.fromSection;
	}

	index(): ModelIndex {
		return this.i;
	}

	orientation(): number {
		return this.o;
	}

	rightClick(): boolean {
		return this.rc;
	}

	toSection(): number {
		return this.m.toSection;
	}

	toString(): string {
		const typ = this.type();
		const ts = (typ in DataTableEvtType) ?
			DataTableEvtType[typ] :
			(typ in EvtType) ?
				EvtType[typ] :
				String(typ);
		return `${this.constructor.name}(${ts}, ${this.i})`;
	}
}

export class DataTableSortEvt extends Evt {
	private c: number;
	private s: SortOrder;

	constructor(column: number, order: SortOrder) {
		super(DataTableEvtType.Sort);
		this.c = column;
		this.s = order;
	}

	column(): number {
		return this.c;
	}

	order(): SortOrder {
		return this.s;
	}
}

export class DataTableHeaderSectionChangeEvt extends Evt {
	static HeaderSectionChange: DataTableEvtType.HeaderSectionChange = DataTableEvtType.HeaderSectionChange;

	private s: list<IUserUITableColumn>;

	constructor(sections: list<IUserUITableColumn>) {
		super(DataTableEvtType.HeaderSectionChange);
		this.s = sections;
	}

	sections(): list<IUserUITableColumn> {
		return this.s;
	}
}

export enum HeaderEvtType {
	SectionMoved = 401,
	SortIndicatorChanged,
}

export class HeaderSectionMoveEvt extends Evt {
	private n: number;
	private o: number;

	constructor(oldVisualIndex: number, newVisualIndex: number) {
		super(HeaderEvtType.SectionMoved);
		this.n = newVisualIndex;
		this.o = oldVisualIndex;
	}

	newVisualIndex(): number {
		return this.n;
	}

	oldVisualIndex(): number {
		return this.o;
	}
}

export class HeaderSortIndicatorChangeEvt extends Evt {
	private i: number;
	private o: SortOrder;

	constructor(visualIndex: number, order: SortOrder) {
		super(HeaderEvtType.SortIndicatorChanged);
		this.i = visualIndex;
		this.o = order;
	}

	order(): SortOrder {
		return this.o;
	}

	visualIndex(): number {
		return this.i;
	}
}

import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.HTMLAttributes<any> {
}

type Props = PropsWithChildren<IProps>;

export default class DataTableContainer extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, className, ...attrs} = this.props;
		return (
			<div className={cssClassName('mdc-data-table__table-container', className)} {...attrs}>
				{children}
			</div>
		);
	}
}

import {MDCSelect} from '@material/select';

import {El, elOpts, ElOpts} from '../el';
import {list} from '../tools';
import {bind, isNumber, numberFormat} from '../util';
import {Evt, EvtDispatcher} from '../evt';

type ParsedPageQueryString = {page: number | null; show: number | null};
type WhichPage = 'first' | 'prev' | 'next' | 'last';
type WhichPageStringMap = {
	[K in WhichPage]: string;
}

interface ButtonConversionResult {
	converted: boolean;
	el: El;
	href: string;
	which: WhichPage | null;
}

export interface PaginationOpts extends ElOpts {
	info: PageInfo;
}

export const DefaultPerPage = 10;
const staticPaginatedObject: IPaginatedObject<any> = {
	currentPageNumber: 1,
	currentPageSlice: [0, 0],
	hasNext: false,
	hasPrevious: false,
	nextPageNumber: 1,
	objects: [],
	pageCount: 1,
	perPageCount: DefaultPerPage,
	previousPageNumber: 1,
	totalCount: 0,
};
const pageBtnIconMap: WhichPageStringMap = {
	first: 'first_page',
	prev: 'chevron_left',
	next: 'chevron_right',
	last: 'last_page',
};

export enum PaginatorEvtType {
	PageChanged = 601,
	PerPageCountChanged,
}

export class PaginatorEvt extends Evt {
	static CountPerPageChanged: PaginatorEvtType.PerPageCountChanged = PaginatorEvtType.PerPageCountChanged;
	static PageChanged: PaginatorEvtType.PageChanged = PaginatorEvtType.PageChanged;

	private p: number;
	private pc: number;

	constructor(type: PaginatorEvtType, page: number, perPageCount: number) {
		super(type);
		this.p = page;
		this.pc = perPageCount;
	}

	page(): number {
		return this.p;
	}

	perPageCount(): number {
		return this.pc;
	}
}

export class PaginationClickEvt extends Evt {
	private b: PaginationButton;

	constructor(button: PaginationButton) {
		super(Evt.MouseButtonClick);
		this.b = button;
	}

	button(): PaginationButton {
		return this.b;
	}
}

class PaginationButton extends El {
	which: WhichPage;

	constructor(root: El | HTMLElement, which: WhichPage) {
		super((root instanceof El) ? root.element() : root);
		this.which = which;
		this.addEventListener('click', this.event);
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'click':
				this.notifyEvt(new PaginationClickEvt(this));
				break;
		}
	}

	update(info: PageInfo): void {
		switch (this.which) {
			case 'first':
				this.setDisabled(!info.hasPreviousPage());
				break;
			case 'prev':
				this.setDisabled(!info.hasPreviousPage());
				break;
			case 'next':
				this.setDisabled(!info.hasNextPage());
				break;
			case 'last':
				this.setDisabled(!info.hasNextPage());
				break;
		}
	}
}

class PaginationTotal extends El {
	currentPageSlice(): [number, number] {
		const s = this.text().trim();
		const patt = /(\d[\d,]*) *- *(\d[\d,]*)/;
		const match = patt.exec(s);
		if (match && (match.length === 3)) {
			const start = Number.parseInt(this.normStr(match[1]));
			const end = Number.parseInt(this.normStr(match[2]));
			if (isNumber(start) && isNumber(end)) {
				return [start, end];
			}
		}
		return [0, 0];
	}

	private normStr(s: string): string {
		const patt = /[ ,]+/g;
		return s.replace(patt, '');
	}

	setCurrentPageSlice(start: number, end: number): void;
	setCurrentPageSlice(slice: [number, number]): void;
	setCurrentPageSlice(a: number | [number, number], b?: number): void {
		let slice: [number, number];
		if (isNumber(a) && isNumber(b)) {
			slice = [a, b];
		} else {
			slice = <[number, number]>a;
		}
		this.setString(slice, this.totalCount());
	}

	private setString(slice: [number, number], total: number): void {
		this.setText(`${numberFormat(slice[0])}-${numberFormat(slice[1])} of ${numberFormat(total)}`);
	}

	setTotalCount(totalCount: number): void {
		this.setString(this.currentPageSlice(), totalCount);
	}

	totalCount(): number {
		const s = this.text().trim();
		const patt = /\d[\d,]*$/;
		const match = patt.exec(s);
		if (match && match.length === 1) {
			const num = Number.parseInt(this.normStr(match[0]));
			if (isNumber(num)) {
				return num;
			}
		}
		return 0;
	}
}

export class PageInfo extends EvtDispatcher {
	static DefaultPerPage: number = DefaultPerPage;

	private pageObj: IPaginatedObject<any>;

	constructor() {
		super();
		this.pageObj = {...staticPaginatedObject};
	}

	currentPageNumber(): number {
		return this.pageObj.currentPageNumber;
	}

	currentPageSlice(): [number, number] {
		return this.pageObj.currentPageSlice;
	}

	destroy(): void {
		this.pageObj = {...staticPaginatedObject};
		super.destroy();
	}

	firstPageNumber(): number {
		return 1;
	}

	hasNextPage(): boolean {
		return this.pageObj.hasNext;
	}

	hasPreviousPage(): boolean {
		return this.pageObj.hasPrevious;
	}

	lastPageNumber(): number {
		return this.pageObj.pageCount;
	}

	nextPageNumber(): number {
		return this.pageObj.nextPageNumber;
	}

	parsedQueryString(): ParsedPageQueryString {
		return parsePageQueryString(window.location.search);
	}

	perPageCount(): number {
		return this.pageObj.perPageCount;
	}

	previousPageNumber(): number {
		return this.pageObj.previousPageNumber;
	}

	setPaginatedObject(obj: Partial<IPaginatedObject<any>>): void {
		this.pageObj = {...this.pageObj, ...obj};
		this.notifyEvt(new Evt(Evt.Change));
	}

	totalCount(): number {
		return this.pageObj.totalCount;
	}
}

export class Pagination extends El {
	static DefaultPerPage: number = 10;

	private btns: list<PaginationButton>;
	private selectctrl: MDCSelect | null;
	private info: PageInfo;
	private total: PaginationTotal;

	constructor(opts: Partial<PaginationOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<PaginationOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<PaginationOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PaginationOpts> | null, root?: Element | null);
	constructor(opts: Partial<PaginationOpts>, parent?: El | null);
	constructor(opts?: Partial<PaginationOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<PaginationOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<PaginationOpts>(a, b, c);
		super(opts);
		this.btns = new list<PaginationButton>();
		this.info = opts.info ? opts.info : new PageInfo();
		this.selectctrl = null;
		this.total = new PaginationTotal({root: this.querySelector('.mdc-data-table__pagination-total')});
		const select = this.selectEl();
		const selectroot = select && select.element();
		if (selectroot) {
			this.addEventListener('MDCSelect:change', this.ctrlEvent);
			this.selectctrl = new MDCSelect(selectroot);
		}
		const hrefMap: WhichPageStringMap = {
			first: '',
			prev: '',
			next: '',
			last: '',
		};
		for (const btnEl of this.buttonEls()) {
			const res = convertToButton(btnEl);
			if (res.which) {
				hrefMap[res.which] = res.href;
				const btn = new PaginationButton(res.el, res.which);
				btn.setDisabled(!Boolean(res.href));
				this.btns.append(btn);
				btn.onEvt(this.buttonEvt);
			}
		}
		let currentPage: number | null = null;
		let perPage: number | null = null;
		let parsed = this.info.parsedQueryString();
		if (parsed.page) {
			currentPage = parsed.page;
		}
		if (parsed.show) {
			perPage = parsed.show;
		}
		if (!currentPage) {
			if (hrefMap.next) {
				parsed = parsePageQueryString(hrefMap.next);
				if (parsed.page) {
					currentPage = parsed.page - 1;
				}
				if (parsed.show) {
					perPage = parsed.show;
				}
			}
			if (!currentPage) {
				if (hrefMap.prev) {
					parsed = parsePageQueryString(hrefMap.prev);
					if (parsed.page) {
						currentPage = parsed.page + 1;
					}
					if (parsed.show) {
						perPage = parsed.show;
					}
				}
			}
		}
		if (isNumber(currentPage)) {
			currentPage = Math.max(1, currentPage);
		}
		if (this.selectctrl && !isNumber(perPage)) {
			perPage = Number(this.selectctrl.value);
		}
		if (isNumber(perPage)) {
			perPage = Math.max(Pagination.DefaultPerPage, perPage);
		}
		const infoObj: Partial<Omit<IPaginatedObject<any>, 'currentPageNumber'>> & {currentPageNumber: number;} = {
			currentPageNumber: isNumber(currentPage) ? currentPage : this.info.currentPageNumber(),
			perPageCount: isNumber(perPage) ? perPage : this.info.perPageCount(),
			currentPageSlice: this.total.currentPageSlice(),
			totalCount: this.total.totalCount(),
		};
		if (hrefMap.next) {
			const p = parsePageQueryString(hrefMap.next);
			if (isNumber(p.page)) {
				infoObj.nextPageNumber = p.page;
			}
		} else {
			infoObj.nextPageNumber = infoObj.currentPageNumber;
		}
		if (hrefMap.last) {
			const p = parsePageQueryString(hrefMap.last);
			if (isNumber(p.page)) {
				infoObj.pageCount = p.page;
			}
		} else {
			infoObj.pageCount = infoObj.currentPageNumber;
		}
		if (hrefMap.prev) {
			const p = parsePageQueryString(hrefMap.prev);
			if (isNumber(p.page)) {
				infoObj.previousPageNumber = p.page;
			}
		} else {
			infoObj.previousPageNumber = infoObj.currentPageNumber;
		}
		infoObj.hasNext = (infoObj.nextPageNumber === undefined) ?
			false :
			(infoObj.currentPageNumber < infoObj.nextPageNumber);
		infoObj.hasPrevious = (infoObj.previousPageNumber === undefined) ?
			false :
			(infoObj.currentPageNumber > infoObj.previousPageNumber);
		this.info.setPaginatedObject(infoObj);
		this.info.onEvt(this.pageInfoEvt);
	}

	@bind
	protected buttonEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick) {
			let goToPage: number;
			switch ((<PaginationClickEvt>evt).button().which) {
				case 'first':
					goToPage = 1;
					break;
				case 'prev':
					goToPage = this.previousPageNumber();
					break;
				case 'next':
					goToPage = this.nextPageNumber();
					break;
				case 'last':
					goToPage = this.lastPageNumber();
					break;
			}
			this.notifyEvt(new PaginatorEvt(PaginatorEvtType.PageChanged, goToPage, this.perPageCount()));
		}
	}

	private buttonEls(): list<El> {
		return this.querySelectorAll('.mdc-data-table__pagination-button');
	}

	@bind
	private ctrlEvent(event: Event): void {
		if ((event.type === 'MDCSelect:change') && this.selectctrl) {
			const num = Number.parseInt(this.selectctrl.value);
			if (isNumber(num)) {
				this.notifyEvt(new PaginatorEvt(PaginatorEvtType.PerPageCountChanged, this.currentPageNumber(), num));
			}
		}
	}

	currentPageNumber(): number {
		return this.info.currentPageNumber();
	}

	destroy(): void {
		this.info.offEvt(this.pageInfoEvt);
		for (const btn of this.btns) {
			btn.offEvt(this.buttonEvt);
			btn.destroy();
		}
		this.btns.clear();
		if (this.selectctrl) {
			this.selectctrl.destroy();
			this.selectctrl = null;
		}
		this.total.destroy();
		super.destroy();
	}

	firstPageNumber(): number {
		return this.info.firstPageNumber();
	}

	hasNextPage(): boolean {
		return this.info.hasNextPage();
	}

	hasPreviousPage(): boolean {
		return this.info.hasPreviousPage();
	}

	lastPageNumber(): number {
		return this.info.lastPageNumber();
	}

	nextPageNumber(): number {
		return this.info.nextPageNumber();
	}

	@bind
	private pageInfoEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Change: {
				this.total.setCurrentPageSlice(this.info.currentPageSlice());
				this.total.setTotalCount(this.info.totalCount());
				for (const btn of this.btns) {
					btn.update(this.info);
				}
				if (this.selectctrl) {
					this.removeEventListener('MDCSelect:change', this.ctrlEvent);
					this.selectctrl.value = String(this.info.perPageCount());
					this.addEventListener('MDCSelect:change', this.ctrlEvent);
				}
				break;
			}
		}
	}

	perPageCount(): number {
		return this.info.perPageCount();
	}

	previousPageNumber(): number {
		return this.info.previousPageNumber();
	}

	private selectEl(): El | null {
		return this.querySelector('.mdc-select');
	}

	setPaginatedResult(obj: Partial<IPaginatedObject<any>>): void {
		this.info.setPaginatedObject(obj);
	}
}

export class Paginator extends EvtDispatcher {
	private ctrls: list<Pagination>;
	private info: PageInfo;

	constructor() {
		super();
		this.ctrls = new list<Pagination>();
		this.info = new PageInfo();
	}

	private addCtrl(root: El | Element): void {
		const obj = new Pagination({info: this.info, root});
		obj.onEvt(this.paginationEvt);
		this.ctrls.append(obj);
	}

	destroy(): void {
		this.destroyCtrls();
		this.info.destroy();
		super.destroy();
	}

	private destroyCtrls(): void {
		for (const obj of this.ctrls) {
			obj.offEvt(this.paginationEvt);
			obj.destroy();
		}
		this.ctrls.clear();
	}

	initialize(): void {
		this.destroyCtrls();
		document.querySelectorAll('.mdc-data-table__pagination')
			.forEach(el => this.addCtrl(el));
	}

	pageInfo(): PageInfo {
		return this.info;
	}

	@bind
	private paginationEvt(evt: Evt): void {
		this.notifyEvt(evt);
	}
}

function pageButton(which: WhichPage): El {
	const btn = new El({classNames: ['mdc-icon-button', 'material-icons', 'mdc-data-table__pagination-button']}, 'button');
	btn.setAttribute(`data-${which}-page`, 'true');
	const iconEl = El.span({classNames: 'mdc-button__icon'}, btn);
	iconEl.setText(pageBtnIconMap[which]);
	return btn;
}

function whichPageBtn(btn: El): WhichPage | null {
	const attrs: Array<[string, WhichPage]> = [
		['data-first-page', 'first'],
		['data-prev-page', 'prev'],
		['data-next-page', 'next'],
		['data-last-page', 'last'],
	];
	for (let i = 0; i < attrs.length; ++i) {
		const at = attrs[i];
		const val = btn.attribute(at[0]);
		if (val && (val.trim() === 'true')) {
			return at[1];
		}
	}
	return null;
}

function convertToButton(toConvert: El): ButtonConversionResult {
	const which = whichPageBtn(toConvert);
	let converted: boolean = false;
	let el: El = toConvert;
	let href: string = '';
	if (toConvert.isA('a')) {
		href = toConvert.attribute('href') || '';
		const parent = toConvert.parent();
		if (parent && which) {
			const btn = pageButton(which);
			parent.replaceChild(btn, toConvert);
			converted = true;
			el = btn;
		}
	}
	return {converted, el, href, which};
}

function parsePageQueryString(s: string): ParsedPageQueryString {
	// ?page=2&show=10
	const rv: ParsedPageQueryString = {page: null, show: null};
	if (window.pbdomsupport.urlSearchParams) {
		const p = new URLSearchParams(s);
		const pg = p.get('page');
		if (pg) {
			const pgn = Number.parseInt(pg);
			rv.page = isNumber(pgn) ? pgn : null;
		}
		const sh = p.get('show');
		if (sh) {
			const shn = Number.parseInt(sh);
			rv.show = isNumber(shn) ? shn : null;
		}
	}
	return rv;
}

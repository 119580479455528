import {isNumber} from '../util';
import {ResourceService} from './resource';
import {commonDateTimeParams} from './common';

export class WeatherService extends ResourceService {
	static uri: string = '/api/weather';

	async get(opts?: Partial<IWeatherRequest>): Promise<IDateWeather[]> {
		const o = Object.assign<object, Partial<IWeatherRequest> | undefined>({}, opts);
		const params = commonDateTimeParams(opts);
		if (isNumber(o.marketID)) {
			params['m'] = o.marketID;
		}
		const url = this.url();
		return (await this.GET<IDateWeather[]>(url, params)).data;
	}
}

export const weatherService: WeatherService = new WeatherService();

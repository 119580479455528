import React, {PropsWithChildren} from 'react';
import ReactDOM from 'react-dom';
import {
	util,
	Corner,
	MDCMenuPoint,
	MDCMenuDistance,
	MDCMenuDimensions,
	MDCMenuSurfaceAdapter,
	MDCMenuSurfaceFoundation,
} from '@material/menu-surface';

import {assert, cssClassName, isNumber, pixelString} from '../../../util';

interface IProps {
	anchorElement?: Element | null;
	className?: string;
	corner?: Corner;
	isOpen?: boolean;
	margins?: Partial<IMargins>;
	onClose?: () => any;
	onOpen?: () => any;
}

interface IState {
	classNames: Set<string>;
}

type Props = PropsWithChildren<IProps>;
type State = IState;

export default class MenuSurface extends React.Component<Props, State> {
	static Corner: typeof Corner = MDCMenuSurfaceFoundation.Corner;

	private _mdc: MDCMenuSurfaceFoundation | null;
	private _prevFocus: HTMLElement | null;
	private _rootRef: React.RefObject<HTMLDivElement>;
	private _shuttingDown: boolean;

	constructor(props: Props) {
		super(props);
		this._mdcIsRtl = this._mdcIsRtl.bind(this);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcHasClass = this._mdcHasClass.bind(this);
		this._keyDownEvent = this._keyDownEvent.bind(this);
		this._mdcHasAnchor = this._mdcHasAnchor.bind(this);
		this._mdcIsFocused = this._mdcIsFocused.bind(this);
		this._mdcSaveFocus = this._mdcSaveFocus.bind(this);
		this._mdcNotifyOpen = this._mdcNotifyOpen.bind(this);
		this._bodyClickEvent = this._bodyClickEvent.bind(this);
		this._mdcNotifyClose = this._mdcNotifyClose.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdcSetPosition = this._mdcSetPosition.bind(this);
		this._mdcRestoreFocus = this._mdcRestoreFocus.bind(this);
		this._mdcSetMaxHeight = this._mdcSetMaxHeight.bind(this);
		this._mdcGetWindowScroll = this._mdcGetWindowScroll.bind(this);
		this._mdcGetBodyDimensions = this._mdcGetBodyDimensions.bind(this);
		this._mdcGetInnerDimensions = this._mdcGetInnerDimensions.bind(this);
		this._mdcSetTransformOrigin = this._mdcSetTransformOrigin.bind(this);
		this._mdcGetAnchorDimensions = this._mdcGetAnchorDimensions.bind(this);
		this._mdcGetWindowDimensions = this._mdcGetWindowDimensions.bind(this);
		this._mdcIsElementInContainer = this._mdcIsElementInContainer.bind(this);
		this.state = {
			classNames: new Set(['mdc-menu-surface']),
		};
		this._mdc = null;
		this._prevFocus = null;
		this._rootRef = React.createRef();
		this._shuttingDown = false;
	}

	close(skipRestoreFocus?: boolean): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.close(skipRestoreFocus);
		}
	}

	componentDidMount(): void {
		const {corner, isOpen, margins} = this.props;
		document.body.addEventListener('click', this._bodyClickEvent, {capture: true});
		this._mdc = new MDCMenuSurfaceFoundation(this._mdcAdapter());
		this._mdc.init();
		this.setOpen(Boolean(isOpen));
		if (isNumber(corner)) {
			this._mdc.setAnchorCorner(corner);
		}
		if (margins) {
			this._mdc.setAnchorMargin(margins);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {corner: cornerPrev, isOpen: isOpenPrev, margins: marginsPrev} = prevProps;
		const {corner, isOpen, margins} = this.props;
		const mdc = this._mdc;
		if (mdc) {
			if (corner !== cornerPrev) {
				mdc.setAnchorCorner(isNumber(corner) ? corner : MenuSurface.Corner.TOP_START);
			}
			if (margins !== marginsPrev) {
				mdc.setAnchorMargin(margins || {});
			}
		}
		if (isOpen !== isOpenPrev) {
			this.setOpen(Boolean(isOpen));
		}
	}

	componentWillUnmount(): void {
		this._shuttingDown = true;
		document.body.removeEventListener('click', this._bodyClickEvent, {capture: true});
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	open(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.open();
		}
	}

	render(): React.ReactNode {
		const {anchorElement, children, className} = this.props;
		const {classNames} = this.state;
		return anchorElement ?
			ReactDOM.createPortal(
				<div className={cssClassName(className, ...classNames)} onKeyDown={this._keyDownEvent} ref={this._rootRef}>{children}</div>,
				anchorElement) :
			<div className={cssClassName(className, ...classNames)} onKeyDown={this._keyDownEvent} ref={this._rootRef}>{children}</div>;
	}

	setOpen(isOpen: boolean, skipRestoreFocus?: boolean): void {
		if (isOpen) {
			this.open();
		} else {
			this.close(skipRestoreFocus);
		}
	}

	private _anchorElement(): HTMLElement | null {
		const parent = this._rootOrDie().parentElement;
		if (parent && parent.classList.contains(MDCMenuSurfaceFoundation.cssClasses.ANCHOR)) {
			return parent;
		}
		return null;
	}

	private _bodyClickEvent(event: MouseEvent): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.handleBodyClick(event);
		}
	}

	private _keyDownEvent(event: React.KeyboardEvent): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.handleKeydown(event.nativeEvent);
		}
	}

	private _mdcAdapter(): MDCMenuSurfaceAdapter {
		return {
			addClass: this._mdcAddClass,
			getAnchorDimensions: this._mdcGetAnchorDimensions,
			getBodyDimensions: this._mdcGetBodyDimensions,
			getInnerDimensions: this._mdcGetInnerDimensions,
			getWindowDimensions: this._mdcGetWindowDimensions,
			getWindowScroll: this._mdcGetWindowScroll,
			hasAnchor: this._mdcHasAnchor,
			hasClass: this._mdcHasClass,
			isElementInContainer: this._mdcIsElementInContainer,
			isFocused: this._mdcIsFocused,
			isRtl: this._mdcIsRtl,
			notifyClose: this._mdcNotifyClose,
			notifyOpen: this._mdcNotifyOpen,
			removeClass: this._mdcRemoveClass,
			restoreFocus: this._mdcRestoreFocus,
			saveFocus: this._mdcSaveFocus,
			setMaxHeight: this._mdcSetMaxHeight,
			setPosition: this._mdcSetPosition,
			setTransformOrigin: this._mdcSetTransformOrigin,
		};
	}

	private _mdcAddClass(className: string): void {
		const {classNames} = this.state;
		classNames.add(className);
		if (!this._shuttingDown) {
			this.setState({classNames: new Set(classNames)});
		}
	}

	private _mdcGetAnchorDimensions(): ClientRect | null {
		const elem = this._anchorElement();
		if (elem) {
			return elem.getBoundingClientRect();
		}
		return null;
	}

	private _mdcGetBodyDimensions(): MDCMenuDimensions {
		const elem = document.body;
		return {
			height: elem.clientHeight,
			width: elem.clientWidth,
		};
	}

	private _mdcGetInnerDimensions(): MDCMenuDimensions {
		const elem = this._rootOrDie();
		return {
			height: elem.offsetHeight,
			width: elem.offsetWidth,
		};
	}

	private _mdcGetWindowDimensions(): MDCMenuDimensions {
		return {
			height: window.innerHeight,
			width: window.innerWidth,
		};
	}

	private _mdcGetWindowScroll(): MDCMenuPoint {
		return {
			x: window.pageXOffset,
			y: window.pageYOffset,
		};
	}

	private _mdcHasAnchor(): boolean {
		return Boolean(this._anchorElement());
	}

	private _mdcHasClass(className: string): boolean {
		return this.state.classNames.has(className);
	}

	private _mdcIsElementInContainer(elem: Element): boolean {
		return this._rootOrDie().contains(elem);
	}

	private _mdcIsFocused(): boolean {
		const active = document.activeElement;
		return active ? (active === this._rootOrDie()) : false;
	}

	private _mdcIsRtl(): boolean {
		return getComputedStyle(this._rootOrDie())
			.getPropertyValue('direction') === 'rtl';
	}

	private _mdcNotifyClose(): void {
		const {onClose} = this.props;
		if (onClose && !this._shuttingDown) {
			onClose();
		}
	}

	private _mdcNotifyOpen(): void {
		const {onOpen} = this.props;
		if (onOpen && !this._shuttingDown) {
			onOpen();
		}
	}

	private _mdcRemoveClass(className: string): void {
		const {classNames} = this.state;
		classNames.delete(className);
		if (!this._shuttingDown) {
			this.setState({classNames: new Set(classNames)});
		}
	}

	private _mdcRestoreFocus(): void {
		const active = document.activeElement;
		const elem = this._prevFocus;
		if (elem && active && this._rootOrDie().contains(active)) {
			elem.focus();
		}
	}

	private _mdcSaveFocus(): void {
		this._prevFocus = document.activeElement as HTMLElement | null;
	}

	private _mdcSetMaxHeight(height: string): void {
		this._rootOrDie().style.setProperty('max-height', height);
	}

	private _mdcSetPosition(position: Partial<MDCMenuDistance>): void {
		const elem = this._rootOrDie();
		elem.style.setProperty('top', isNumber(position.top) ? pixelString(position.top) : '');
		elem.style.setProperty('right', isNumber(position.right) ? pixelString(position.right) : '');
		elem.style.setProperty('bottom', isNumber(position.bottom) ? pixelString(position.bottom) : '');
		elem.style.setProperty('left', isNumber(position.left) ? pixelString(position.left) : '');
	}

	private _mdcSetTransformOrigin(origin: string): void {
		const propertyName = `${util.getTransformPropertyName(window)}-origin`;
		this._rootOrDie().style.setProperty(propertyName, origin);
	}

	private _rootOrDie(): HTMLDivElement {
		const elem = this._rootRef.current;
		assert(elem, 'root ref current is null');
		return elem;
	}
}

import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.HTMLAttributes<any> {
	headerRow?: boolean;
}

type Props = PropsWithChildren<IProps>;

export default class DataTableRow extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, className, headerRow, ...attrs} = this.props;
		const clsName = headerRow ? 'mdc-data-table__header-row' : 'mdc-data-table__row';
		return (
			<tr className={cssClassName(clsName, className)} {...attrs}>
				{children}
			</tr>
		);
	}
}

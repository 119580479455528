import {StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {bind, isNumber} from '../util';
import {Dialog} from '../ui/dialog';
import {Menu} from '../ui/menu';
import {List, ListItemSelectEvt} from '../ui/list';
import {Evt} from '../evt';

export function init(itemID?: number | string): void {
	if (itemID || (itemID === 0)) {
		const objID = isNumber(itemID) ? itemID : Number.parseInt(itemID);
		if (isNumber(objID)) {
			return (new View(objID)).init();
		}
	}
	console.log('catalogitemdetail::init no argument or argument of invalid type');
}

enum MenuItemIndex {
	Clone = 0,
	Delete = 1,
}

class View {
	private dia: Dialog;
	private menu: Menu;
	private objectId: number;

	constructor(objectID: number) {
		let list: List | null = null;
		const menuRoot = document.querySelector('.pb-menu');
		if (menuRoot) {
			list = new List({root: menuRoot.querySelector('.mdc-list')});
		}
		this.menu = new Menu({list}, menuRoot);
		this.menu.onEvt(this._menuEvt);
		this.objectId = objectID;
		this.dia = new Dialog();
	}

	async cloneObject(): Promise<void> {
		const data = await this.objectData();
		const clone = await svc.catalog.item.clone(data.id);
		let url = window.location.href.replace(/\d+\/?$/, clone.id.toString());
		if (url[url.length - 1] !== '/') {
			url = url + '/';
		}
		window.location.assign(url);
	}

	init(): void {
		const menuButton = document.querySelector<HTMLButtonElement>('#id_menu-toggle-button');
		if (menuButton) {
			menuButton.addEventListener('click', this._menuButtonEvent);
		}
	}

	objectData(): Promise<IItem> {
		return svc.catalog.item.get(this.objectId);
	}

	openDeleteDialog(data: IItem): void {
		this.dia.setTitle(`Delete ${data.name}?`);
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			noBtn.setRaised(true);
		}
		this.dia.open(this._dialogResultCallback);
	}

	@bind
	private async _dialogResultCallback(resultCode: number): Promise<void> {
		if (resultCode === StandardButton.Yes) {
			const data = await this.objectData();
			await svc.catalog.item.delete(data.id);
			window.location.assign(window.pburls.group.catalog.item.list);
		}
	}

	@bind
	private _menuEvt(evt: Evt): void {
		if (evt.type() === Evt.Select) {
			const e = <ListItemSelectEvt>evt;
			switch (e.index()) {
				case MenuItemIndex.Clone:
					this.cloneObject();
					break;
				case MenuItemIndex.Delete:
					this._renderDeleteDialog();
					break;
			}
		}
	}

	@bind
	private _menuButtonEvent(event: MouseEvent): void {
		this.menu.anchorToBody(event.clientX, event.clientY);
		this.menu.open();
	}

	private async _renderDeleteDialog(): Promise<void> {
		this.openDeleteDialog(await this.objectData());
	}
}

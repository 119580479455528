import {List, ListItem, ListItemOpts, ListItemSelectEvt, ListOpts} from './list';
import {El, elOpts} from '../el';
import {bind} from '../util';
import {Menu, MenuOpts} from './menu';
import {Button} from './button';
import {list} from '../tools';
import {Checkbox} from './checkbox';
import {CSS_CLASS_FULL_WIDTH} from '../constants';
import {Evt} from '../evt';

class ProjectTaskMenuList extends List {
	constructor(opts: Partial<ListOpts> | null, parent?: El | null);
	constructor(opts?: Partial<ListOpts> | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ListOpts> | El | null, b?: El | null) {
		let opts: Partial<ListOpts> = {};
		let parent: El | null = null;
		if (a) {
			if (a instanceof El) {
				parent = a;
			} else {
				opts = a;
			}
		}
		if (b) {
			parent = b;
		}
		opts.listItemFactory = (opts?: Partial<ListItemOpts>) => new ProjectTaskMenuListItem(opts);
		super(opts, parent);
	}
}

class ProjectTaskMenuListItem extends ListItem {
	constructor(opts: Partial<ListItemOpts> | null, parent?: El | null);
	constructor(opts?: Partial<ListItemOpts> | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ListItemOpts> | El | null, b?: El | null) {
		let opts: Partial<ListItemOpts> = {};
		let parent: El | null = null;
		if (a) {
			if (a instanceof El) {
				parent = a;
			} else {
				opts = a;
			}
		}
		if (b) {
			parent = b;
		}
		super(opts, parent);
		this.addClass('pb-check-list-item');
		this.addEventListener('change', this.event);
	}

	protected changeEvent(event: Event): void {
		let el: El | null;
		try {
			el = El.fromEvent(event).closestMatchingAncestor('input');
		} catch {
			return;
		}
		if (el) {
			this.setClass(el.isChecked(), 'pb-check-list-item--checked');
		}
	}

	destroy(): void {
		this.removeEventListener('change', this.event);
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'change':
				this.changeEvent(event);
				break;
		}
	}

	setLeadingEl(obj: El | null): void {
		super.setLeadingEl(obj);
		let el = this.leadingEl();
		if (el) {
			const inp = el.querySelector('input[type="checkbox"]');
			if (inp) {
				this.setClass(inp.isChecked(), 'pb-check-list-item--checked');
			}
		}
	}
}

export class ProjectTaskChangeEvt extends Evt {
	private c: boolean;
	private t: IProjectTask;

	constructor(task: IProjectTask, checked: boolean) {
		super(Evt.Change);
		this.c = checked;
		this.t = task;
	}

	checked(): boolean {
		return this.c;
	}

	task(): IProjectTask {
		return this.t;
	}
}

export class ProjectTaskMenu extends Menu {
	private btn: Button | null;
	private checkboxes: list<Checkbox>;
	private indexTaskMap: Map<number, IProjectTask>;

	constructor(opts: Partial<MenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts>, parent?: El | null);
	constructor(opts?: Partial<MenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<MenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<MenuOpts>(a, b, c);
		opts.listFactory = () => new ProjectTaskMenuList();
		super(opts);
		this.btn = null;
		this.checkboxes = new list<Checkbox>();
		this.indexTaskMap = new Map<number, IProjectTask>();
		this.onEvt(this.evt);
	}

	private addTasks(tasks: Iterable<IProjectTask>): void {
		this.indexTaskMap.clear();
		const ts = Array.from(tasks);
		for (let i = 0; i < ts.length; ++i) {
			const t = ts[i];
			this.indexTaskMap.set(i, t);
			const it: Partial<ListItemOpts> = {text: t.taskTypeDisplay, textIsLabel: true};
			const checkbox = new Checkbox();
			checkbox.setChecked(t.isComplete);
			const checkboxId = String(t.id);
			checkbox.setInputId(checkboxId);
			it.leadingEl = checkbox;
			const item = this.addItem(it);
			checkbox.setBuddy(item.textEl());
			this.checkboxes.append(checkbox);
		}
		for (const task of tasks) {

		}
		const btnContainer = El.div(this);
		btnContainer.setStyleProperty('padding', '0 16px 8px');
		this.btn = new Button({classNames: CSS_CLASS_FULL_WIDTH, text: 'Done'}, btnContainer);
		this.btn.onEvt(this.buttonEvt);
	}

	@bind
	protected buttonEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick) {
			this.close();
		}
	}

	destroy(): void {
		this.offEvt(this.evt);
		if (this.btn) {
			this.btn.offEvt(this.buttonEvt);
			this.btn.destroy();
			this.btn = null;
		}
		for (const cb of this.checkboxes) {
			cb.destroy();
		}
		this.checkboxes.clear();
		this.indexTaskMap.clear();
		super.destroy();
	}

	@bind
	private evt(evt: Evt): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const task = this.indexTaskMap.get(evt.index());
			if (task) {
				const item = this.item(evt.index());
				if (item) {
					this.notifyEvt(new ProjectTaskChangeEvt(task, item.isChecked()));
				}
			}
		}
	}

	protected listItemSelectEvt(evt: ListItemSelectEvt): void {
		this.notifyEvt(evt);
	}

	setTasks(tasks: Iterable<IProjectTask>): void {
		this.addTasks(tasks);
	}

	// @bind
	// private taskCheckboxEvt(task: IProjectTask, evt: Evt): void {
	// 	if (evt.type() === Evt.Change) {
	// 		const e = <CheckboxChangeEvt>evt;
	// 		this.notifyEvt(new ProjectTaskChangeEvt(task, e.isChecked()));
	// 	}
	// }
}

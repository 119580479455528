import {MDCRadio} from '@material/radio';

import {El, ElOpts, elOpts} from '../el';
import {bind} from '../util';
import {CheckState} from '../constants';
import {Evt} from '../evt';

export interface RadioOpts extends ElOpts {
	checked: boolean;
	disabled: boolean;
	name: string;
	static: boolean;
	value: string;
}

export class RadioChangeEvt extends Evt {
	private r: Radio;

	constructor(radio: Radio) {
		super(Evt.Change);
		this.r = radio;
	}

	radio(): Radio {
		return this.r;
	}
}

export class Radio extends El {
	static RootSelector: string = '.mdc-radio';

	buddy: El | null;
	ctrl: MDCRadio | null;

	constructor(opts: Partial<RadioOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<RadioOpts> | null, parent?: El | null);
	constructor(opts: Partial<RadioOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<RadioOpts>, parent?: El | null);
	constructor(opts?: Partial<RadioOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<RadioOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<RadioOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.buddy = null;
		this.instanceNumber = Radio.instanceCount;
		this.addClass('mdc-radio');
		const inp = El.input({
			attributes: [['type', 'radio']],
			classNames: 'mdc-radio__native-control',
		}, this);
		inp.addEventListener('change', this.event);
		const bg = El.div({classNames: 'mdc-radio__background'}, this);
		El.div({classNames: 'mdc-radio__outer-circle'}, bg);
		El.div({classNames: 'mdc-radio__inner-circle'}, bg);
		El.div({classNames: 'mdc-radio__ripple'}, this);
		if (opts.disabled) {
			this.setDisabled(opts.disabled);
		}
		if (opts.name) {
			this.setName(opts.name);
		}
		if (opts.value) {
			this.setValue(opts.value);
		}
		if (opts.checked) {
			this.setChecked(opts.checked);
		}
		if (opts.static) {
			this.ctrl = null;
		} else {
			this.ctrl = new MDCRadio(this.elem);
		}
	}

	checkState(): CheckState {
		const inp = this.inputEl();
		if (inp) {
			return inp.checkState();
		}
		if (this.ctrl && this.ctrl.checked) {
			return CheckState.Checked;
		}
		return CheckState.Unchecked;
	}

	destroy(): void {
		this.buddy = null;
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
		const inp = this.inputEl();
		if (inp) {
			inp.removeEventListener('change', this.event);
		}
		super.destroy();
	}

	genericId(): string {
		return `id_pb-radio-${this.instanceNumber}`;
	}

	@bind
	protected event(event: Event): void {
		if (event.type === 'change') {
			this.notifyEvt(new RadioChangeEvt(this));
		}
	}

	protected inputEl(): El | null {
		return this.querySelector('input[type="radio"]');
	}

	inputId(): string {
		const el = this.inputEl();
		return el ? el.id() : '';
	}

	isChecked(): boolean {
		if (this.ctrl) {
			return this.ctrl.checked;
		} else {
			const el = this.inputEl();
			if (el) {
				return el.isChecked();
			}
		}
		return false;
	}

	isDisabled(): boolean {
		if (this.ctrl) {
			return this.ctrl.disabled;
		} else {
			const el = this.inputEl();
			if (el) {
				return el.isDisabled();
			}
		}
		return false;
	}

	isIndeterminate(): boolean {
		return false;
	}

	protected labelBuddy(): El | null {
		if (this.buddy && this.buddy.tagName() === 'LABEL') {
			return this.buddy;
		}
		return null;
	}

	private maybeRemoveLabelBuddyForAttr(): void {
		const bud = this.labelBuddy();
		if (bud && bud.attribute('for') === this.inputId()) {
			bud.removeAttribute('for');
		}
	}

	name(): string {
		const el = this.inputEl();
		return el ? el.name() : '';
	}

	setBuddy(buddy?: El | null): void {
		const bud = buddy || null;
		if (bud === this.buddy) {
			return;
		}
		this.maybeRemoveLabelBuddyForAttr();
		this.buddy = bud;
		this.setLabelBuddyForAttr(this.inputId());
	}

	setChecked(checked: boolean): void {
		if (this.ctrl) {
			this.ctrl.checked = checked;
		} else {
			const el = this.inputEl();
			if (el) {
				el.setChecked(checked);
			}
		}
	}

	setDisabled(disabled: boolean): void {
		if (this.ctrl) {
			this.ctrl.disabled = disabled;
		} else {
			const el = this.inputEl();
			if (el) {
				el.setDisabled(disabled);
			}
		}
		this.setClass(disabled, 'mdc-radio--disabled');
	}

	setIndeterminate(isIndeterminate?: boolean): void {
	}

	setInputId(id: string): void {
		const el = this.inputEl();
		if (el) {
			el.setId(id);
			this.setLabelBuddyForAttr(this.inputId());
		}
	}

	private setLabelBuddyForAttr(inputId: string): void {
		if (inputId) {
			const bud = this.labelBuddy();
			if (bud) {
				bud.setAttribute('for', inputId);
			}
		}
	}

	setName(name: string): void {
		const el = this.inputEl();
		if (el) {
			el.setName(name);
		}
	}

	setValue(value: string): void {
		const el = this.inputEl();
		if (el) {
			el.setValue(value);
		}
	}

	value(): string {
		const el = this.inputEl();
		return el ? el.value() : '';
	}
}

import {setError} from './error';
import {apiService as svc} from '../../services';
import {RECEIVE_MARKET_WEATHER_DATA} from './types';

export function marketWeatherData(opts: IMarketWeatherRequest): FuncAction<Promise<ReceiveMarketWeatherAction>, ReceiveMarketWeatherAction> {
	return function (dispatch, getState) {
		const {marketWeather} = getState().global;
		if (opts.marketID in marketWeather) {
			return Promise.resolve(dispatch(receiveMarketWeather(opts.marketID, marketWeather[opts.marketID])));
		}
		return svc.weather.get(opts)
			.then(data => dispatch(receiveMarketWeather(opts.marketID, data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function receiveMarketWeather(marketID: number, data: IDateWeather[]): ReceiveMarketWeatherAction {
	return {
		data: {marketID, weather: data},
		type: RECEIVE_MARKET_WEATHER_DATA,
	};
}

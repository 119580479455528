import {Dialog} from '../ui/dialog';
import {El} from '../el';
import {StandardButton} from '../constants';
import {SuperSecretPreviewService} from '../services/supersecret';

class IFrame extends El {
	constructor(parent: El | null = null) {
		super('iframe', parent);
		this.setStyleProperty('border', 'none');
		this.setStyleProperty('height', '100%');
		this.setStyleProperty('width', '85vw');
		const index = window.pburls.supersecret;
		if (index) {
			const formRoot = document.getElementById('id_object-form');
			if (formRoot) {
				const svc = new SuperSecretPreviewService();
				const formData = new FormData(<HTMLFormElement>formRoot);
				svc.preview(formData).then(doc => (<HTMLIFrameElement>this.elem).srcdoc = doc);
			}
		}
	}
}

export function init() {
	const previewBtnNode = document.getElementById('id_preview-button');
	if (previewBtnNode) {
		previewBtnNode.addEventListener('click', previewButtonClickEvent);
	}
}

function previewButtonClickEvent(): void {
	const dia = new Dialog();
	dia.setStandardButtons(StandardButton.Close);
	dia.setMaximized(true);
	dia.setContent(new IFrame());
	dia.open();
}

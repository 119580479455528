import {ResourceService} from './resource';
import {isNumber} from '../util';

export class ItemService extends ResourceService {
	static uri: string = '/api/group/catalog/items/';

	async clone(id: number): Promise<IItem> {
		return (await this.POST<IItem>(this.url(id))).data;
	}

	async create(data: IItem, cfg?: ResourceDataRequestConfig): Promise<IItem> {
		return (await this.POST<IItem>(this.url(), data, cfg)).data;
	}

	async delete(id: number, cfg?: ResourceDataRequestConfig): Promise<void> {
		return (await this.DELETE<void>(this.url(id), undefined, cfg)).data;
	}

	async get(id: number, params?: any, cfg?: ResourceQueryRequestConfig): Promise<IItem> {
		return (await this.GET<IItem>(this.url(id), params, cfg)).data;
	}

	async list(params?: Partial<IPaginatedItemRequest>, cfg?: ResourceQueryRequestConfig): Promise<IPaginatedItem> {
		let p: any = undefined;
		if (params) {
			p = {};
			if ((params.minSize !== undefined) && (isNumber(params.minSize) || (params.minSize.length > 0))) {
				p.size = params.minSize;
			}
			if (params.priceGroupId) {
				p.pgid = params.priceGroupId;
			}

		}
		return (await this.GET<IPaginatedItem>(this.url(), p, cfg)).data;
	}

	async setPlacement(data: Array<{id: number; placement: number;}>, cfg?: ResourceQueryRequestConfig): Promise<void> {
		return (await this.PUT<void>(this.url('placement'), data, cfg)).data;
	}

	async update(id: number, data: IItem, cfg?: ResourceDataRequestConfig): Promise<IItem> {
		return (await this.PUT<IItem>(this.url(id), data, cfg)).data;
	}
}

import {StandardButton} from '../constants';
import {bind} from '../util';
import {Dialog} from '../ui/dialog';
import {ContactCard} from '../ui/contactcard';

export enum ViewState {
	NoState,
	CreateState,
	DetailState,
	EditState,
	DeleteState,
}

export class CRUDView {
	contactCard: ContactCard | null = null;
	dia: Dialog | null = null;
	state: ViewState = ViewState.NoState;

	protected closeDia(): void {
		if (this.dia && this.dia.isOpen()) {
			this.dia.reject();
		}
	}

	protected async createStateDialogResult(result: number): Promise<void> {
		this.setState(ViewState.NoState);
	}

	protected async deleteObject(): Promise<void> {
	}

	protected async deleteStateDialogResult(result: number): Promise<void> {
		if (!result) {
			this.setState(ViewState.NoState);
		} else if (result === StandardButton.Yes) {
			await this.deleteObject();
			this.setState(ViewState.NoState);
		} else {
			this.setState(ViewState.DetailState);
		}
	}

	protected async detailStateDialogResult(result: number): Promise<void> {
		this.setState(ViewState.NoState);
	}

	@bind
	protected dialogFinishedCallback(result: number): void {
		switch (this.state) {
			case ViewState.NoState:
				break;
			case ViewState.CreateState:
				this.createStateDialogResult(result);
				break;
			case ViewState.DetailState:
				this.detailStateDialogResult(result);
				break;
			case ViewState.EditState:
				this.editStateDialogResult(result);
				break;
			case ViewState.DeleteState:
				this.deleteStateDialogResult(result);
				break;
		}
	}

	protected async editStateDialogResult(result: number): Promise<void> {
		if (!result) {
			this.setState(ViewState.NoState);
		} else if (result === StandardButton.Cancel) {
			this.setState(ViewState.DetailState);
		}
	}

	protected enterState(which: ViewState): void {
		switch (which) {
			case ViewState.NoState:
				this.closeDia();
				break;
			case ViewState.CreateState:
				this.openCreateDialog();
				break;
			case ViewState.DetailState:
				this.openDetailDialog();
				break;
			case ViewState.EditState:
				this.openEditDialog();
				break;
			case ViewState.DeleteState:
				this.openDeleteDialog();
				break;
		}
	}

	protected exitState(which: ViewState): void {
		if (this.contactCard) {
			this.contactCard.closeToolbarMenu();
		}
	}

	protected async openCreateDialog(): Promise<void> {
		this.openDia();
	}

	protected async openDeleteDialog(): Promise<void> {
		this.openDia();
	}

	protected async openDetailDialog(): Promise<void> {
		this.openDia();
	}

	protected openDia(): void {
		if (this.dia) {
			this.dia.open(this.dialogFinishedCallback);
		}
	}

	protected async openEditDialog(): Promise<void> {
		this.openDia();
	}

	protected setState(which: ViewState): void {
		if (this.state === which) {
			return;
		}
		this.exitState(this.state);
		this.state = which;
		this.enterState(this.state);
	}
}

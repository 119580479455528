import {El, elOpts, ElOpts} from '../../el';
import {Evt} from '../../evt';
import {bind} from '../../util';
import {IconButton, IconButtonClickEvt} from '../../ui/iconbutton';
import {list} from '../../tools';

class Form extends El {
	static RootSelector: string = '';

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		super(opts);
		this.addEventListener('submit', this.event);
	}

	destroy(): void {
		this.removeEventListener('submit', this.event);
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		if (event.type === 'submit') {
			event.preventDefault();
			this.notifyEvt(new Evt(Evt.Submit));
		}
	}
}

export class AssignableForm extends Form {
	static AssignableSelectRootSelector: string = '';

	protected selectEl: El | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		super(opts);
		this.selectEl = null;
		const selectSelector = (<typeof AssignableForm>this.constructor).AssignableSelectRootSelector;
		if (selectSelector.length > 0) {
			this.selectEl = new El({
				root: this.querySelector(selectSelector),
			});
		}
	}

	currentSelectValue(): string {
		return this.selectEl ?
			this.selectEl.value() :
			'';
	}
}

class AssignedUser extends El {
	private btn: IconButton;
	private lbl: El;
	private obj: IUser;

	constructor(obj: IUser, opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(obj: IUser, opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(obj: IUser, tagName: TagName, parent?: El | null);
	constructor(obj: IUser, root: Element | null, parent?: El | null);
	constructor(obj: IUser, opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(obj: IUser, opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(obj: IUser, opts: Partial<ElOpts>, parent?: El | null);
	constructor(obj: IUser, opts?: Partial<ElOpts>);
	constructor(obj: IUser, root?: Element | null);
	constructor(obj: IUser, tagName?: TagName);
	constructor(obj: IUser, parent?: El | null);
	constructor(obj: IUser, a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		const classNames: Array<string> = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--row',
			'align-items--center',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.obj = obj;
		this.btn = new IconButton({
			classNames: [
				'pb-smaller-tool-button',
			],
			iconName: 'remove_circle_outline',
			parent: this,
			smaller: true,
		});
		this.btn.onEvt(this.btnEvt);
		this.lbl = new El({
			parent: this,
			tagName: 'div',
		});
		this.lbl.setText(this.obj.name);
	}

	@bind
	private btnEvt(evt: Evt): void {
		if (window.isProducer && (evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			this.notifyEvt(new AssignedUserEvt(Evt.MouseButtonClick, this));
		}
	}

	destroy(): void {
		this.btn.destroy();
		this.lbl.destroy();
		super.destroy();
	}

	user(): IUser {
		return this.obj;
	}
}

class AssignedUserEvt extends Evt {
	private obj: AssignedUser;

	constructor(type: number, obj: AssignedUser) {
		super(type);
		this.obj = obj;
	}

	assignedUser(): AssignedUser {
		return this.obj;
	}
}

export class AssignedFormEvt extends Evt {
	private a: IUser;

	constructor(type: number, assigned: IUser) {
		super(type);
		this.a = assigned;
	}

	assignedUser(): IUser {
		return this.a;
	}
}

export class AssignedForm extends Form {
	private assignedUsers: list<AssignedUser>;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		super(opts);
		this.assignedUsers = new list();
	}

	addUser(obj: IUser): void {
		const assignedUser = new AssignedUser(
			obj,
			{parent: this},
		);
		this.assignedUsers.append(assignedUser);
		assignedUser.onEvt(this.assignedUserEvt);
		this.show();
	}

	@bind
	private assignedUserEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof AssignedUserEvt)) {
			this.notifyEvt(
				new AssignedFormEvt(
					Evt.MouseButtonClick,
					evt.assignedUser().user(),
				),
			);
		}
	}

	hasUser(pk: number): boolean {
		for (const obj of this.assignedUsers) {
			if (obj.user().id === pk) {
				return true;
			}
		}
		return false;
	}

	removeUser(pk: number): void {
		let assigned: AssignedUser | null = null;
		for (const obj of this.assignedUsers) {
			if (obj.user().id === pk) {
				assigned = obj;
				break;
			}
		}
		if (assigned) {
			assigned.offEvt(this.assignedUserEvt);
			this.assignedUsers.remove(
				this.assignedUsers.indexOf(assigned),
			);
			assigned.destroy();
		}
		if (this.assignedUsers.isEmpty()) {
			this.hide();
		}
	}

	users(): list<IUser> {
		const rv = new list<IUser>();
		for (const obj of this.assignedUsers) {
			rv.append(obj.user());
		}
		return rv;
	}
}

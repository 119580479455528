import React from 'react';

import {TextField} from '../../../components';

interface IProps {
	onChange: (value: string, name: 'name') => any;
	value: string;
}

export default class Name extends React.Component<IProps, {}> {
	static Name: 'name' = 'name';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, this.name());
	}

	name(): 'name' {
		return Name.Name;
	}

	render(): React.ReactNode {
		const {value} = this.props;
		return <TextField
			className="width--100-percent"
			helpText="e.g.: Interior Photos"
			label="Name"
			name={this.name()}
			onChange={this.changeEvent}
			value={value}/>;
	}
}

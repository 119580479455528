import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.HTMLAttributes<any> {
	numeric?: boolean;
}

type Props = PropsWithChildren<IProps>;

export default class DataTableColumn extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, className, numeric, ...attrs} = this.props;
		const clsName = `mdc-data-table__header-cell${numeric ? ' mdc-data-table__header-cell--numeric' : ''}`;
		return (
			<th className={cssClassName(clsName, className)} role="columnheader" scope="col" {...attrs}>
				{children}
			</th>
		);
	}
}

import {Menu} from '../menu';

export enum CurrentMenuType {
	NoMenu,
	TableColumn,
	Task,
	QBInvoice,
	Status,
	Assign,
	Editor,
	DataLocationOption,
}

export class CurrentMenu {
	static NoMenu: CurrentMenuType.NoMenu = CurrentMenuType.NoMenu;
	static TableColumn: CurrentMenuType.TableColumn = CurrentMenuType.TableColumn;
	static Task: CurrentMenuType.Task = CurrentMenuType.Task;
	static QBInvoice: CurrentMenuType.QBInvoice = CurrentMenuType.QBInvoice;
	static Status: CurrentMenuType.Status = CurrentMenuType.Status;
	static Assign: CurrentMenuType.Assign = CurrentMenuType.Assign;
	static Editor: CurrentMenuType.Editor = CurrentMenuType.Editor;
	static DataLocationOption: CurrentMenuType.DataLocationOption = CurrentMenuType.DataLocationOption;

	instance: Menu | null;
	type: number;

	constructor() {
		this.instance = null;
		this.type = 0;
	}

	close(): void {
		if (this.isOpen() && this.instance) {
			this.instance.close();
		}
	}

	destroy(): void {
		this.destroyInstance();
		this.type = 0;
	}

	private destroyInstance(): void {
		if (this.instance) {
			this.close();
			this.instance.destroy();
			this.instance = null;
		}
		this.type = 0;
	}

	isOpen(): boolean {
		if (this.instance) {
			return this.instance.isOpen();
		}
		return false;
	}

	open(x: number, y: number): void {
		if (this.isOpen()) {
			return;
		}
		if (this.instance) {
			this.instance.anchorToBody(x, y);
			this.instance.open();
		}
	}

	setCurrentMenu(instance: Menu | null, type: number = 0): void {
		if (instance === this.instance) {
			return;
		}
		this.destroyInstance();
		this.type = type;
		this.instance = instance;
	}
}

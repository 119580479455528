import {getLogger} from '../../logging';
import {El, elOpts, ElOpts} from '../../el';
import {list} from '../../tools';
import {IconButton} from '../iconbutton';
import {Button, ButtonClickEvt} from '../button';
import {StandardButton} from '../../constants';
import {bind, isNumber} from '../../util';
import {CCardEditRowCtrl, RowCtrlEvt} from './rowctrl';
import {Evt} from '../../evt';
import {Chip, ChipEvt, ChipOpts, ChipSet} from '../chipset';
import {
	CCardHeader,
	CCardHeaderInner,
	CCardRoot,
	CCardScrollableContainer,
	CCardSection,
	CCardSectionHeader,
	CCardSectionHeaderOpts,
	CCardSectionOpts,
	CCardSectionRow,
} from './common';

const logger = getLogger('ui::ccard::edit');

interface CCardEditHeaderOpts extends ElOpts {
	header: string;
}

class CCardEditHeader extends CCardHeader {
	private contentEl: CCardEditHeaderContent | null;
	private textContentEl: CCardEditHeaderTextContent | null;

	constructor(opts: Partial<CCardEditHeaderOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditHeaderOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditHeaderOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditHeaderOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditHeaderOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditHeaderOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditHeaderOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditHeaderOpts>(a, b, c);
		super(opts);
		const headerInner = new CCardEditHeaderInner({
			parent: this,
		});
		this.contentEl = new CCardEditHeaderContent({
			parent: headerInner,
		});
		this.textContentEl = new CCardEditHeaderTextContent({
			parent: this.contentEl,
		});
		if (opts.header) {
			this.setHeader(opts.header);
		}
	}

	appendContent(el: El): void {
		if (this.contentEl) {
			this.contentEl.appendChild(el);
		}
	}

	header(): string {
		return this.textContentEl ?
			this.textContentEl.text() :
			'';
	}

	setHeader(text?: string | null): void {
		if (this.textContentEl) {
			this.textContentEl.setText(text);
		}
	}
}

class CCardEditHeaderInner extends CCardHeaderInner {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-header__inner');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardEditHeaderContent extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-header__content');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardEditHeaderTextContent extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-header__text-content');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardEditSectionHeader extends CCardSectionHeader {
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardSectionHeaderOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardSectionHeaderOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardSectionHeaderOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardSectionHeaderOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardSectionHeaderOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-edit-section__header',
			...classNames,
		];
		super(opts);
	}
}

class CCardEditSection extends CCardSection {
	constructor(opts: Partial<CCardSectionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardSectionOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardSectionOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardSectionOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardSectionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardSectionOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-edit-section', ...classNames];
		super(opts);
	}

	protected createHeader(): CCardSectionHeader {
		return new CCardEditSectionHeader({
			parent: this,
			placementIndex: 0,
		});
	}
}

class CCardEditSectionInner extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-section__inner');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface CCardEditSectionRowOpts extends ElOpts {
	disabled: boolean;
	leadingIconName: string;
}

export class CCardEditSectionRow extends CCardSectionRow {
	private ctrlsEl: CCardEditSectionControlsContainer | null;
	private leadingIconEl: CCardEditSectionItemIcon | null;
	private toolBoxes: list<CCardEditSectionRowToolBox>;

	constructor(opts: Partial<CCardEditSectionRowOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditSectionRowOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditSectionRowOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditSectionRowOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditSectionRowOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditSectionRowOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = ['pb-cc-edit-section__row', ...classNames];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.ctrlsEl = null;
		this.leadingIconEl = null;
		this.toolBoxes = new list<CCardEditSectionRowToolBox>();
		this.setLeadingIconName(opts.leadingIconName);
		if (opts.disabled !== undefined) {
			this.setDisabled(opts.disabled);
		}
	}

	appendControl(ctrl: El): void {
		this.insertControl(-1, ctrl);
	}

	appendToolButton(btn: IconButton): void {
		this.insertToolButton(-1, btn);
	}

	insertControl(index: number, ctrl: El): void {
		if (!this.ctrlsEl) {
			this.ctrlsEl = new CCardEditSectionControlsContainer({
				parent: this,
				placementIndex: 1,
			});
		}
		if (index < 0) {
			index = this.ctrlsEl.children().size();
		}
		const container = new CCardEditSectionControlContainer({
			parent: this.ctrlsEl,
		});
		container.addControl(ctrl);
		this.ctrlsEl.insertChild(index, container);
	}

	insertToolButton(index: number, btn: IconButton): void {
		if (index < 0) {
			index = this.toolBoxes.size();
		}
		if (this.leadingIconEl) {
			++index;
		}
		if (this.ctrlsEl) {
			++index;
		}
		const toolBox = new CCardEditSectionRowToolBox();
		const toolBoxInner = new CCardEditSectionRowToolBoxInner({
			parent: toolBox,
		});
		btn.setParent(toolBoxInner);
		this.insertChild(index, toolBox);
		this.toolBoxes.append(toolBox);
	}

	leadingIconName(): string {
		return this.leadingIconEl ?
			this.leadingIconEl.iconName() :
			'';
	}

	setDisabled(disabled: boolean): void {
		this.setClass(disabled, 'pb-cc-edit-section__row--disabled');
	}

	setLeadingIconName(iconName?: string | null): void {
		if (!this.leadingIconEl) {
			this.leadingIconEl = new CCardEditSectionItemIcon({
				parent: this,
				placementIndex: 0,
			});
		}
		this.leadingIconEl.setIconName(iconName);
	}
}

interface CCardEditSectionItemIconOpts extends ElOpts {
	iconName: string;
}

class CCardEditSectionItemIcon extends El {
	private iconEl: El | null;

	constructor(opts: Partial<CCardEditSectionItemIconOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionItemIconOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionItemIconOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditSectionItemIconOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditSectionItemIconOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditSectionItemIconOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditSectionItemIconOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditSectionItemIconOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-edit-section__item-icon',
			...classNames,
		];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.iconEl = null;
		if (opts.iconName) {
			this.setIconName(opts.iconName);
		}
	}

	clear(): void {
		if (this.iconEl) {
			this.iconEl.clear();
			this.iconEl.destroy();
		}
		this.iconEl = null;
	}

	destroy(): void {
		if (this.iconEl) {
			this.iconEl.clear();
			this.iconEl.destroy();
		}
		this.iconEl = null;
		super.destroy();
	}

	iconName(): string {
		return this.iconEl ?
			this.iconEl.text() :
			'';
	}

	setIconName(icon?: string | null) {
		if (!this.iconEl) {
			this.iconEl = new El({
				classNames: 'material-icons',
				parent: this,
				tagName: 'span',
			});
		}
		this.iconEl.setText(icon);
	}
}

class CCardEditSectionControlsContainer extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-section__controls-container');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface CCardEditSectionControlContainerOpts extends ElOpts {
}

class CCardEditSectionControlContainer extends El {
	constructor(opts: Partial<CCardEditSectionControlContainerOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionControlContainerOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionControlContainerOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditSectionControlContainerOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditSectionControlContainerOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditSectionControlContainerOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditSectionControlContainerOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditSectionControlContainerOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-section__control-container');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}

	addControl(control: El): void {
		control.addClass('pb-cc-edit-section__control');
		control.setParent(this);
	}
}

interface CCardEditSectionRowToolBoxOpts extends ElOpts {
}

class CCardEditSectionRowToolBox extends El {
	constructor(opts: Partial<CCardEditSectionRowToolBoxOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditSectionRowToolBoxOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditSectionRowToolBoxOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditSectionRowToolBoxOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditSectionRowToolBoxOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-section__row__tool-box');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface CCardEditSectionRowToolBoxInnerOpts extends ElOpts {
}

export class CCardEditSectionRowToolBoxInner extends El {
	constructor(opts: Partial<CCardEditSectionRowToolBoxInnerOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxInnerOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxInnerOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditSectionRowToolBoxInnerOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditSectionRowToolBoxInnerOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditSectionRowToolBoxInnerOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditSectionRowToolBoxInnerOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditSectionRowToolBoxInnerOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-section__row__tool-box__inner');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardEditBottomSection extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-bottom-section');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardEditBottomSectionButtons extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-edit-bottom-section__buttons');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface RowTypeOpt {
	iconName: string;
	many: boolean;
	sectionIndex: number;
	type: string;
}

export interface CCardEditOpts extends ElOpts {
	header: string;
	rowTypes: Iterable<RowTypeOpt>;
}

export class CCardEdit extends CCardRoot {
	private btnMap: Map<Button, StandardButton>;
	private chipSet: ChipSet | null;
	private hdr: CCardEditHeader | null;
	private integrationsSectionInner: CCardEditSectionInner | null;
	protected labelChip: Chip | null;
	private primarySectionInner: CCardEditSectionInner | null;
	private rowCtrl: CCardEditRowCtrl;
	private scrollableContainer: CCardScrollableContainer | null;

	constructor(opts: Partial<CCardEditOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardEditOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardEditOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardEditOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardEditOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardEditOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardEditOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardEditOpts>(a, b, c);
		super(opts);
		this.integrationsSectionInner = null;
		this.labelChip = null;
		this.rowCtrl = new CCardEditRowCtrl(this);
		this.rowCtrl.onEvt(this.rowCtrlEvt);
		this.hdr = new CCardEditHeader({
			header: opts.header,
			parent: this,
		});
		this.chipSet = new ChipSet({
			classNames: ['pb-cc-chip-set', 'pb-cc-edit-header__chip-set'],
		});
		this.hdr.appendContent(this.chipSet);
		this.setLabel();
		this.scrollableContainer = new CCardScrollableContainer({
			parent: this,
		});
		const section = new CCardEditSection({
			parent: this.scrollableContainer,
		});
		this.primarySectionInner = new CCardEditSectionInner({
			parent: section,
		});
		const bottomSection = new CCardEditBottomSection({
			parent: this,
		});
		new El({
			parent: bottomSection,
			tagName: 'div',
		});
		const bottomSectionButtons = new CCardEditBottomSectionButtons({
			parent: bottomSection,
		});
		const cancelBtn = new Button({parent: bottomSectionButtons, text: 'Cancel'});
		cancelBtn.onEvt(this.buttonEvt);
		const saveBtn = new Button({parent: bottomSectionButtons, text: 'Save'});
		saveBtn.onEvt(this.buttonEvt);
		this.btnMap = new Map<Button, StandardButton>([
			[cancelBtn, StandardButton.Cancel],
			[saveBtn, StandardButton.Save],
		]);
		if (opts.rowTypes) {
			for (const obj of opts.rowTypes) {
				this.registerRowType(obj.type, obj.iconName, obj.many, obj.sectionIndex);
			}
		}
	}

	protected addChip(opts?: Partial<ChipOpts>, index: number = -1): Chip | null {
		if (!this.chipSet) {
			logger.info('addChip: chipSet not defined.');
			return null;
		}
		opts = opts || {};
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-chip',
			'pb-cc-edit-header__chip',
			...classNames,
		];
		const chip = this.chipSet.insertChip(index, opts);
		chip.onEvt(this.chipEvt);
		return chip;
	}

	protected addField(fieldName: string, rowType: string, rowIndex: number = -1, field: El | null = null): void {
		if (this.rowCtrl) {
			this.rowCtrl.addField(fieldName, rowType, rowIndex, field);
		}
	}

	protected addIntegrationsSection(title?: string): void {
		if (!this.integrationsSectionInner) {
			const section = new CCardEditSection({
				header: title,
				parent: this.scrollableContainer,
			});
			this.integrationsSectionInner = new CCardEditSectionInner({
				parent: section,
			});
		}
	}

	protected addRow(type: string, enabled: boolean = true): CCardEditSectionRow | null {
		return this.rowCtrl.addRow(type, enabled);
	}

	private buttonClickEvt(evt: ButtonClickEvt): void {
		const stdBtn = this.btnMap.get(evt.obj());
		const evtType = (stdBtn === StandardButton.Cancel) ?
			Evt.Cancel :
			(stdBtn === StandardButton.Save) ?
				Evt.Save :
				Evt.None;
		if (evtType !== Evt.None) {
			this.notifyEvt(new Evt(evtType));
		}
	}

	@bind
	private buttonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof ButtonClickEvt)) {
			this.buttonClickEvt(evt);
		}
	}

	@bind
	private chipEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.MouseButtonClick:
				if (evt instanceof ChipEvt) {
					this.chipMouseButtonClickEvt(evt);
				}
				break;
		}
	}

	protected chipMouseButtonClickEvt(evt: ChipEvt): void {
	}

	clear(): void {
		this.rowCtrl.clear();
	}

	protected cloneRow(rowType: string, rowIndex: number): CCardEditSectionRow | null {
		if (this.rowCtrl) {
			return this.rowCtrl.cloneRow(rowType, rowIndex);
		}
		return null;
	}

	protected defaultLabelText(): string {
		return 'No Label';
	}

	destroy(): void {
		if (this.labelChip) {
			this.labelChip.offEvt(this.chipEvt);
			this.labelChip.destroy();
		}
		this.labelChip = null;
		if (this.chipSet) {
			this.chipSet.destroy();
		}
		this.chipSet = null;
		for (const btn of this.btnMap.keys()) {
			btn.offEvt(this.buttonEvt);
			btn.destroy();
		}
		this.btnMap.clear();
		this.rowCtrl.offEvt(this.rowCtrlEvt);
		this.rowCtrl.destroy();
		if (this.primarySectionInner) {
			this.primarySectionInner.destroy();
		}
		this.primarySectionInner = null;
		if (this.integrationsSectionInner) {
			this.integrationsSectionInner.destroy();
		}
		this.integrationsSectionInner = null;
		if (this.scrollableContainer) {
			this.scrollableContainer.destroy();
		}
		this.scrollableContainer = null;
		super.destroy();
	}

	protected fieldValue(fieldName: string, rowType: string, rowIndex: number = -1): string {
		if (this.rowCtrl) {
			return this.rowCtrl.fieldValue(fieldName, rowType, rowIndex);
		}
		return '';
	}

	protected focusField(fieldName: string, rowType: string, rowIndex: number = -1, delay?: number): void {
		if (this.rowCtrl) {
			if (isNumber(delay)) {
				this.rowCtrl.focusFieldDelayed(delay, fieldName, rowType, rowIndex);
			} else {
				this.rowCtrl.focusField(fieldName, rowType, rowIndex);
			}
		}
	}

	protected hasRowType(rowType: string): boolean {
		if (this.rowCtrl) {
			return this.rowCtrl.hasRowType(rowType);
		}
		return false;
	}

	insertSectionRow(rowIndex: number, row: CCardEditSectionRow, sectionIndex: number = 0): void {
		if (sectionIndex === 0) {
			if (this.primarySectionInner) {
				this.primarySectionInner.insertChild(rowIndex, row);
			} else {
				logger.warning('insertSectionRow: primarySectionInner is not defined.');
			}
		} else if (sectionIndex === 1) {
			if (this.integrationsSectionInner) {
				this.integrationsSectionInner.insertChild(rowIndex, row);
			} else {
				logger.warning('insertSectionRow: integrationsSectionInner is not defined.');
			}
		} else {
			logger.warning('insertSectionRow: Invalid index for section %s.', sectionIndex);
		}
	}

	protected isRowEnabled(rowType: string, rowIndex: number): boolean {
		return this.rowCtrl.isRowEnabled(rowType, rowIndex);
	}

	protected registerRowType(type: string, iconName: string, many: boolean, sectionIndex: number): void {
		if (this.rowCtrl) {
			this.rowCtrl.registerRowType(type, iconName, many, sectionIndex);
		}
	}

	protected removeRow(rowType: string, rowIndex: number): void {
		if (this.rowCtrl) {
			this.rowCtrl.removeRow(rowType, rowIndex);
		}
	}

	protected rowCount(rowType: string): number {
		if (this.rowCtrl) {
			return this.rowCtrl.rowCount(rowType);
		}
		return 0;
	}

	@bind
	private rowCtrlEvt(evt: Evt): void {
		if (!(evt instanceof RowCtrlEvt)) {
			return;
		}
		switch (evt.type()) {
			case RowCtrlEvt.RowAddButtonPressed:
				this.rowCtrlRowAddButtonPressEvt(evt);
				break;
			case RowCtrlEvt.RowAdded:
				this.rowCtrlRowAddedEvt(evt);
				break;
			case RowCtrlEvt.RowRemoveButtonPressed:
				this.rowCtrlRowRemoveButtonPressEvt(evt);
				break;
			case RowCtrlEvt.RowRemoved:
				this.rowCtrlRowRemovedEvt(evt);
				break;
		}
	}

	protected rowCtrlRowAddButtonPressEvt(evt: RowCtrlEvt): void {
	}

	protected rowCtrlRowAddedEvt(evt: RowCtrlEvt): void {
	}

	protected rowCtrlRowRemoveButtonPressEvt(evt: RowCtrlEvt): void {
	}

	protected rowCtrlRowRemovedEvt(evt: RowCtrlEvt): void {
	}

	protected rowIndex(row: CCardEditSectionRow): number {
		if (this.rowCtrl) {
			return this.rowCtrl.rowIndex(row);
		}
		return -1;
	}

	protected setFieldValue(value: string, fieldName: string, rowType: string, rowIndex: number = -1): void {
		if (this.rowCtrl) {
			this.rowCtrl.setFieldValue(value, fieldName, rowType, rowIndex);
		}
	}

	setHeader(text?: string | null): void {
		if (this.hdr) {
			this.hdr.setHeader(text);
		}
	}

	setLabel(label?: string | null): void {
		if (!this.labelChip) {
			this.labelChip = this.addChip();
		}
		if (this.labelChip) {
			this.labelChip.setText(label || this.defaultLabelText());
			this.labelChip.setSelected(Boolean(label));
		}
	}

	setLabelToolTip(text: string): void {
		if (!this.labelChip) {
			this.labelChip = this.addChip();
		}
		if (this.labelChip) {
			this.labelChip.setTitle(text);
		}
	}

	protected setRowEnabled(enabled: boolean, rowType: string, rowIndex: number = -1): void {
		this.rowCtrl.setRowEnabled(enabled, rowType, rowIndex);
	}
}

import {MDCRipple} from '@material/ripple';

import {El, elOpts, ElOpts} from '../el';
import {bind} from '../util';
import {Evt, MouseEvt} from '../evt';
import {Point} from '../tools';
import {KeyboardModifier, MouseButton} from '../constants';

export interface ButtonOpts extends ElOpts {
	disabled: boolean;
	href: string | {href: string; opts?: Partial<{target: 'blank'; text: string;}>}
	isAnchor: boolean;
	isSubmit: boolean;
	leadingIcon: string;
	outlined: boolean;
	raised: boolean;
	text: string | null;
	trailingIcon: string;
	unelevated: boolean;
}

export class ButtonClickEvt extends MouseEvt {
	static fromClickEvent(instance: Button, event: MouseEventish): ButtonClickEvt {
		const {button, buttons, modifiers, pos} = this.fromEventParts(event);
		return new this(
			instance,
			pos,
			button,
			buttons,
			modifiers);
	}

	private b: Button;

	constructor(instance: Button, clientPos: Point, mouseButton: MouseButton, mouseButtons: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(Evt.MouseButtonClick, clientPos, mouseButton, mouseButtons, modifiers);
		this.b = instance;
	}

	obj(): Button {
		return this.b;
	}
}

export class Button extends El {
	static selector: string = '.mdc-button';

	private clickEventListenerAdded: boolean;
	private initOpts: Partial<ButtonOpts>;
	protected rip: MDCRipple | null;

	constructor(opts: Partial<ButtonOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts> | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts>, parent?: El | null);
	constructor(opts?: Partial<ButtonOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ButtonOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ButtonOpts>(a, b, c);
		if (!(opts.root || opts.tagName)) {
			opts.tagName = opts.isAnchor ?
				'a' :
				'button';
		}
		super(opts);
		this.clickEventListenerAdded = false;
		this.initOpts = {...opts};
		this.addClass('mdc-button');
		if (!opts.isAnchor) {
			this.setType(opts.isSubmit ? 'submit' : 'button');
		}
		El.span({classNames: 'mdc-button__ripple'}, this);
		El.span({classNames: 'mdc-button__label'}, this);
		if (opts.outlined) {
			this.addClass('mdc-button--outlined');
		} else if (opts.raised) {
			this.addClass('mdc-button--raised');
		} else if (opts.unelevated) {
			this.addClass('mdc-button--unelevated');
		}
		if (opts.text) {
			this.setText(opts.text);
		}
		if (opts.leadingIcon) {
			this.setLeadingIcon(opts.leadingIcon);
		} else if (opts.trailingIcon) {
			this.setTrailingIcon(opts.trailingIcon);
		}
		if (opts.disabled) {
			this.setDisabled(true);
		}
		if (opts.href) {
			if (typeof opts.href === 'string') {
				this.setHref(opts.href);
			} else {
				this.setHref(opts.href.href, opts.href.opts);
			}
		}
		this.rip = new MDCRipple(this.elem);
	}

	click(): void {
		const {height, width, x, y} = this.rect();
		this.notifyClick(makeMouseEventish({
			button: 0,
			buttons: 1,
			clientX: x + Math.floor(width / 2),
			clientY: y + Math.floor(height / 2),
			type: 'click',
		}));
	}

	clone(): Button {
		return new (<typeof Button>this.constructor)(this.initOpts);
	}

	destroy(): void {
		this.removeEventListeners();
		if (this.rip) {
			this.rip.destroy();
			this.rip = null;
		}
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'click':
				this.mouseClickEvent(<MouseEvent>event);
				break;
		}
	}

	protected evtListenerAdded(): void {
		if (!this.clickEventListenerAdded) {
			this.clickEventListenerAdded = true;
			this.addEventListener('click', this.event);
		}
	}

	protected evtListenerRemoved(): void {
		if (this.evtListeners.isEmpty()) {
			this.removeEventListeners();
		}
	}

	protected iconEl(): El | null {
		return this.querySelector('.mdc-button__icon');
	}

	isAnchor(): boolean {
		return this.tagName() === 'A';
	}

	isOutlined(): boolean {
		return this.hasClass('mdc-button--outlined');
	}

	isRaised(): boolean {
		return this.hasClass('mdc-button--raised');
	}

	isUnelevated(): boolean {
		return this.hasClass('mdc-button--unelevated');
	}

	labelEl(): El | null {
		return this.querySelector('.mdc-button__label');
	}

	@bind
	private mouseClickEvent(event: MouseEvent): void {
		if (!this.evtListeners.isEmpty()) {
			event.stopPropagation();
		}
		this.notifyClick(event);
	}

	private notifyClick(event: MouseEventish): void {
		this.notifyEvt(ButtonClickEvt.fromClickEvent(this, event));
	}

	protected removeEventListeners(): void {
		this.removeEventListener('click', this.event);
		this.clickEventListenerAdded = false;
	}

	setIcon(pos: 'leading' | 'trailing', iconName: string): void {
		if (!((pos === 'leading') || (pos === 'trailing'))) {
			return;
		}
		iconName = iconName.trim();
		let el = this.iconEl();
		if (!iconName) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			const labelEl = this.labelEl();
			if (!labelEl) {
				return;
			}
			el = new El({classNames: ['material-icons', 'mdc-button__icon'], attributes: [['aria-hidden', 'true']]}, 'i');
			if (pos === 'leading') {
				labelEl.insertAdjacentElement('beforebegin', el);
			} else {
				labelEl.insertAdjacentElement('afterend', el);
			}
		}
		el.setText(iconName);
	}

	setLeadingIcon(iconName: string): void {
		this.setIcon('leading', iconName);
	}

	setOutlined(outlined: boolean): void {
		this.setClass(outlined, 'mdc-button--outlined');
		if (outlined) {
			this.setRaised(false);
			this.setUnelevated(false);
		}
	}

	setRaised(raised: boolean): void {
		this.setClass(raised, 'mdc-button--raised');
		if (raised) {
			this.setOutlined(false);
			this.setUnelevated(false);
		}
	}

	setText(text: string): void {
		const el = this.labelEl();
		if (el) {
			el.setText(text);
		}
	}

	setTrailingIcon(iconName: string): void {
		this.setIcon('trailing', iconName);
	}

	setUnelevated(unelevated: boolean): void {
		this.setClass(unelevated, 'mdc-button--unelevated');
		if (unelevated) {
			this.setOutlined(false);
			this.setRaised(false);
		}
	}

	text(): string {
		const el = this.labelEl();
		return el ? el.text() : '';
	}
}

function setDefault<T>(value: T | undefined, defaultValue: T): T {
	return (value === undefined) ?
		defaultValue :
		value;
}

function makeMouseEventish(opts?: Partial<MouseEventish>): MouseEventish {
	opts = opts || {};
	return {
		altKey: setDefault(opts.altKey, false),
		button: setDefault(opts.button, 0),
		buttons: setDefault(opts.buttons, 0),
		clientX: setDefault(opts.clientX, 0),
		clientY: setDefault(opts.clientY, 0),
		ctrlKey: setDefault(opts.ctrlKey, false),
		metaKey: setDefault(opts.metaKey, false),
		shiftKey: setDefault(opts.shiftKey, false),
		type: setDefault(opts.type, ''),
	};
}

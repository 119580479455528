import React, {PropsWithChildren} from 'react';
import {
	EventType,
	SpecificEventListener,
} from '@material/base/types';
import {
	cssClasses,
	MDCSliderAdapter,
	MDCSliderFoundation,
	Thumb,
	TickMark as VendorTickMark,
} from '@material/slider';

import {set} from '../../tools';
import {bind, cssClassName, isNumber, numberFormat} from '../../util';

interface IProps {
	ariaLabel?: string;
	className?: string;
	disabled?: boolean;
	discrete?: boolean;
	displayMarkers?: boolean;
	id?: string;
	max: number;
	min: number;
	onChange?: (value: number) => any;
	onInput?: (value: number) => any;
	step: number;
	value: number;
}

type Props = PropsWithChildren<IProps>;

interface IState {
	rootClassNames: set<string>;
	thumbClassNames: set<string>;
	tickMarks: Array<boolean>;
	width: number;
}

type State = IState;
const noop = () => undefined;
export default class Slider extends React.Component<Props, State> {
	static defaultProps = {
		max: 100,
		min: 0,
	};

	private _mdc: MDCSliderFoundation | null;
	private _inputRef: React.RefObject<HTMLInputElement>;
	private _rootRef: React.RefObject<HTMLDivElement>;
	private _thumbRef: React.RefObject<HTMLDivElement>;
	private _trackRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		const classNames = ['mdc-slider'];
		if (props.disabled) {
			classNames.push('mdc-slider--disabled');
		}
		if (props.discrete) {
			classNames.push('mdc-slider--discrete');
		}
		if (props.displayMarkers) {
			classNames.push('mdc-slider--tick-marks');
		}
		this.state = {
			rootClassNames: new set(classNames),
			thumbClassNames: new set(['mdc-slider__thumb']),
			tickMarks: [true, true, true, true, true, true, false, false, false, false, false],
			width: 0,
		};
		this._inputRef = React.createRef();
		this._mdc = null;
		this._rootRef = React.createRef();
		this._thumbRef = React.createRef();
		this._trackRef = React.createRef();
	}

	componentDidMount(): void {
		const {disabled, value} = this.props;
		this._mdc = new MDCSliderFoundation(this._mdcAdapter());
		this._mdc.init();
		if (disabled) {
			this._mdc.setDisabled(true);
		}
		this._mdc.setValue(value);
		this._mdc.layout();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {disabled: disabledPrev, discrete: discretePrev, displayMarkers: displayMarkersPrev, max: maxPrev, min: minPrev, step: stepPrev, value: valuePrev} = prevProps;
		const {disabled, discrete, displayMarkers, max, min, step, value} = this.props;
		const {rootClassNames, width: widthPrev} = this.state;
		let classNamesChanged: boolean = false;
		if (disabledPrev !== disabled) {
			if (disabled) {
				rootClassNames.add('mdc-slider--disabled');
			} else {
				rootClassNames.discard('mdc-slider--disabled');
			}
			classNamesChanged = true;
		}
		if (discretePrev !== discrete) {
			if (discrete) {
				rootClassNames.add('mdc-slider--discrete');
			} else {
				rootClassNames.discard('mdc-slider--discrete');
			}
			classNamesChanged = true;
		}
		if (displayMarkersPrev !== displayMarkers) {
			if (displayMarkers) {
				rootClassNames.add('mdc-slider--tick-marks');
			} else {
				rootClassNames.discard('mdc-slider--tick-marks');
			}
			classNamesChanged = true;
		}
		if (this._mdc) {
			if ((max !== maxPrev) || (min !== minPrev) || (step !== stepPrev)) {
				this._mdc.destroy();
				this._mdc = new MDCSliderFoundation(this._mdcAdapter());
				this._mdc.init();
				this._mdc.setDisabled(Boolean(disabled));
				this._mdc.setValue(value);
				this._mdc.layout();
			} else {
				if (disabled !== disabledPrev) {
					this._mdc.setDisabled(Boolean(disabled));
				}
				if (value !== valuePrev) {
					this._mdc.setValue(value);
				}
			}
		}
		if (classNamesChanged) {
			this.setState({rootClassNames: new set(rootClassNames)});
		}
		if (this._mdc) {
			const width = this._rect().width;
			if (Math.round(widthPrev) !== Math.round(width)) {
				this._mdc.layout();
				this.setState({width});
			}
		}
	}

	componentWillUnmount(): void {
		if (this._mdc) {
			this._mdc.destroy();
		}
	}

	render(): React.ReactNode {
		const {
			max,
			min,
			step,
			value,
			disabled,
			discrete,
			ariaLabel,
			className,
			displayMarkers,
		} = this.props;
		const {rootClassNames, thumbClassNames, tickMarks} = this.state;
		return (
			<div className={cssClassName(className, ...rootClassNames)} ref={this._rootRef}>
				<input
					aria-label={ariaLabel}
					className="mdc-slider__input"
					disabled={disabled}
					max={max}
					min={min}
					onChange={noop}
					ref={this._inputRef}
					step={step}
					type="range"
					value={value}/>
				<div className="mdc-slider__track" ref={this._trackRef}>
					<div className="mdc-slider__track--inactive"/>
					<div className="mdc-slider__track--active">
						<div className="mdc-slider__track--active_fill"/>
					</div>
					{displayMarkers ?
						<div className="mdc-slider__tick-marks">
							{tickMarks.map((isActive, idx) => <TickMark active={isActive} key={idx}/>)}
						</div> :
						null}
				</div>
				<div className={cssClassName(...thumbClassNames)} ref={this._thumbRef}>
					{discrete ?
						<div aria-hidden={true} className="mdc-slider__value-indicator-container">
							<div className="mdc-slider__value-indicator">
								<div className="mdc-slider__value-indicator-text">
									{numberFormat(value)}
								</div>
							</div>
						</div> :
						null}
					<div className="mdc-slider__thumb-knob"/>
				</div>
			</div>
		);
	}

	private _mdcAdapter(): MDCSliderAdapter {
		return {
			addClass: this._mdcAddClass,
			addThumbClass: this._mdcAddThumbClass,
			deregisterBodyEventHandler: this._mdcDeregisterBodyEventHandler,
			deregisterEventHandler: this._mdcDeregisterEventHandler,
			deregisterInputEventHandler: this._mdcDeregisterInputEventHandler,
			deregisterThumbEventHandler: this._mdcDeregisterThumbEventHandler,
			deregisterWindowEventHandler: this._mdcDeregisterWindowEventHandler,
			emitChangeEvent: this._mdcEmitChangeEvent,
			emitDragEndEvent: this._mdcEmitDragEndEvent,
			emitDragStartEvent: this._mdcEmitDragStartEvent,
			emitInputEvent: this._mdcEmitInputEvent,
			focusInput: this._mdcFocusInput,
			getAttribute: this._mdcGetAttribute,
			getBoundingClientRect: this._mdcGetBoundingClientRect,
			getInputAttribute: this._mdcGetInputAttribute,
			getInputValue: this._mdcGetInputValue,
			getThumbBoundingClientRect: this._mdcGetThumbBoundingClientRect,
			getThumbKnobWidth: this._mdcGetThumbKnobWidth,
			getValueToAriaValueTextFn: this._mdcGetValueToAriaValueTextFn,
			hasClass: this._mdcHasClass,
			isInputFocused: this._mdcIsInputFocused,
			isRTL: this._mdcIsRTL,
			registerBodyEventHandler: this._mdcRegisterBodyEventHandler,
			registerEventHandler: this._mdcRegisterEventHandler,
			registerInputEventHandler: this._mdcRegisterInputEventHandler,
			registerThumbEventHandler: this._mdcRegisterThumbEventHandler,
			registerWindowEventHandler: this._mdcRegisterWindowEventHandler,
			removeClass: this._mdcRemoveClass,
			removeInputAttribute: this._mdcRemoveInputAttribute,
			removeThumbClass: this._mdcRemoveThumbClass,
			removeThumbStyleProperty: this._mdcRemoveThumbStyleProperty,
			removeTrackActiveStyleProperty: this._mdcRemoveTrackActiveStyleProperty,
			setInputAttribute: this._mdcSetInputAttribute,
			setInputValue: this._mdcSetInputValue,
			setPointerCapture: this._mdcSetPointerCapture,
			setThumbStyleProperty: this._mdcSetThumbStyleProperty,
			setTrackActiveStyleProperty: this._mdcSetTrackActiveStyleProperty,
			setValueIndicatorText: this._mdcSetValueIndicatorText,
			updateTickMarks: this._mdcUpdateTickMarks,
		};
	}

	@bind
	private _mdcAddClass(className: string): void {
		const {rootClassNames} = this.state;
		rootClassNames.add(className);
		this.setState({rootClassNames: new set(rootClassNames)});
	}

	@bind
	private _mdcAddThumbClass(className: string, thumb: Thumb): void {
		const {thumbClassNames} = this.state;
		thumbClassNames.add(className);
		this.setState({thumbClassNames: new set(thumbClassNames)});
	}

	@bind
	private _mdcDeregisterBodyEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		document.body.removeEventListener(type, listener);
	}

	@bind
	private _mdcDeregisterEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.removeEventListener(type, listener);
		}
	}

	@bind
	private _mdcDeregisterInputEventHandler<K extends EventType>(thumb: Thumb, type: K, listener: SpecificEventListener<K>): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.removeEventListener(type, listener);
		}
	}

	@bind
	private _mdcDeregisterThumbEventHandler<K extends EventType>(thumb: Thumb, type: K, listener: SpecificEventListener<K>): void {
		const elem = this._thumbRef.current;
		if (elem) {
			elem.removeEventListener(type, listener);
		}
	}

	@bind
	private _mdcDeregisterWindowEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		window.removeEventListener(type, listener);
	}

	@bind
	private _mdcEmitChangeEvent(value: number, thumb: Thumb): void {
		const {onChange} = this.props;
		if (onChange) {
			onChange(value);
		}
	}

	@bind
	private _mdcEmitDragEndEvent(value: number, thumb: Thumb): void {
		// Not implemented (yet?)
	}

	@bind
	private _mdcEmitDragStartEvent(value: number, thumb: Thumb): void {
		// Not implemented (yet?)
	}

	@bind
	private _mdcEmitInputEvent(value: number, thumb: Thumb): void {
		const {onInput} = this.props;
		if (onInput) {
			onInput(value);
		}
	}

	@bind
	private _mdcFocusInput(thumb: Thumb): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.focus();
		}
	}

	@bind
	private _mdcGetAttribute(name: string): string | null {
		const elem = this._rootRef.current;
		if (elem) {
			return elem.getAttribute(name);
		}
		return null;
	}

	@bind
	private _mdcGetBoundingClientRect(): ClientRect {
		const elem = this._rootRef.current;
		if (elem) {
			return elem.getBoundingClientRect();
		}
		return {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0};
	}

	@bind
	private _mdcGetInputAttribute(name: string, thumb: Thumb): string | null {
		const elem = this._inputRef.current;
		if (elem) {
			return elem.getAttribute(name);
		}
		return null;
	}

	@bind
	private _mdcGetInputValue(thumb: Thumb): string {
		return String(this.props.value);
	}

	@bind
	private _mdcGetThumbBoundingClientRect(thumb: Thumb): ClientRect {
		const elem = this._thumbRef.current;
		if (elem) {
			return elem.getBoundingClientRect();
		}
		return {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0};
	}

	@bind
	private _mdcGetThumbKnobWidth(thumb: Thumb): number {
		const elem = this._thumbRef.current;
		if (elem) {
			const knob = elem.querySelector(`.${cssClasses.THUMB_KNOB}`);
			if (knob) {
				return knob.getBoundingClientRect().width;
			}
		}
		return 0;
	}

	@bind
	private _mdcGetValueToAriaValueTextFn(): ((value: number) => string) | null {
		// Not implemented (yet?)
		return String;
	}

	@bind
	private _mdcHasClass(className: string): boolean {
		return this.state.rootClassNames.has(className);
	}

	@bind
	private _mdcIsRTL(): boolean {
		const elem = this._rootRef.current;
		if (elem) {
			return getComputedStyle(elem).direction === 'rtl';
		}
		return false;
	}

	@bind
	private _mdcIsInputFocused(thumb: Thumb): boolean {
		const elem = this._inputRef.current;
		if (elem) {
			return elem === document.activeElement;
		}
		return false;
	}

	@bind
	private _mdcRegisterBodyEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		document.body.addEventListener(type, listener);
	}

	@bind
	private _mdcRegisterEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.addEventListener(type, listener);
		}
	}

	@bind
	private _mdcRegisterInputEventHandler<K extends EventType>(thumb: Thumb, type: K, listener: SpecificEventListener<K>): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.addEventListener(type, listener);
		}
	}

	@bind
	private _mdcRegisterThumbEventHandler<K extends EventType>(thumb: Thumb, type: K, listener: SpecificEventListener<K>): void {
		const elem = this._thumbRef.current;
		if (elem) {
			elem.addEventListener(type, listener);
		}
	}

	@bind
	private _mdcRegisterWindowEventHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		window.addEventListener(type, listener);
	}

	@bind
	private _mdcRemoveClass(className: string): void {
		const {rootClassNames} = this.state;
		rootClassNames.discard(className);
		this.setState({rootClassNames: new set(rootClassNames)});
	}

	@bind
	private _mdcRemoveInputAttribute(name: string, thumb: Thumb): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.removeAttribute(name);
		}
	}

	@bind
	private _mdcRemoveThumbClass(className: string, thumb: Thumb): void {
		const {thumbClassNames} = this.state;
		thumbClassNames.discard(className);
		this.setState({thumbClassNames: new set(thumbClassNames)});
	}

	@bind
	private _mdcRemoveThumbStyleProperty(name: string, thumb: Thumb): void {
		const elem = this._thumbRef.current;
		if (elem) {
			elem.style.removeProperty(name);
		}
	}

	@bind
	private _mdcRemoveTrackActiveStyleProperty(name: string): void {
		const root = this._rootRef.current;
		if (root) {
			const elem = root.querySelector<HTMLElement>(`.${cssClasses.TRACK_ACTIVE}`);
			if (elem) {
				elem.style.removeProperty(name);
			}
		}
	}

	@bind
	private _mdcSetInputAttribute(name: string, value: string, thumb: Thumb): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.setAttribute(name, value);
		}
	}

	@bind
	private _mdcSetInputValue(value: string, thumb: Thumb): void {
	}

	@bind
	private _mdcSetPointerCapture(pointerId: number): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.setPointerCapture(pointerId);
		}
	}

	@bind
	private _mdcSetThumbStyleProperty(name: string, value: string, thumb: Thumb): void {
		const elem = this._thumbRef.current;
		if (elem) {
			elem.style.setProperty(name, value);
		}
	}

	@bind
	private _mdcSetTrackActiveStyleProperty(name: string, value: string): void {
		const root = this._rootRef.current;
		if (root) {
			const elem = root.querySelector<HTMLElement>(`.${cssClasses.TRACK_ACTIVE}`);
			if (elem) {
				elem.style.setProperty(name, value);
			}
		}
	}

	@bind
	private _mdcSetValueIndicatorText(value: number, thumb: Thumb): void {
		const elem = this._thumbRef.current;
		if (elem) {
			const indicator = elem.querySelector<HTMLElement>(`.${cssClasses.VALUE_INDICATOR_TEXT}`);
			if (indicator) {
				indicator.textContent = numberFormat(value);
			}
		}
	}

	@bind
	private _mdcUpdateTickMarks(tickMarks: VendorTickMark[]): void {
		this.setState({tickMarks: tickMarks.map(t => (t === VendorTickMark.ACTIVE))});
	}

	private _rect(): ClientRect {
		const elem = this._rootRef.current;
		return elem ? elem.getBoundingClientRect() : {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0};
	}
}

function TickMark({active}: {active: boolean}) {
	return (
		<div className={active ? 'mdc-slider__tick-mark--active' : 'mdc-slider__tick-mark--inactive'}/>
	);
}

export function decodeQBInvoiceLinkText(text: string): {qbDocNumber: string; qbHasFakeLink: boolean;} {
	let qbDocNumber: string = '';
	let qbHasFakeLink: boolean = false;
	let qbHasFakeLinkStr: string;
	const parts = text.split('__');
	if (parts.length === 2) {
		[qbDocNumber, qbHasFakeLinkStr] = parts;
		qbHasFakeLink = (qbHasFakeLinkStr === 'true');
	}
	return {qbDocNumber, qbHasFakeLink};
}

export function encodeQBInvoiceLinkText(docNumber: string, hasFakeLink: boolean): string {
	return `${docNumber}__${hasFakeLink}`;
}

import {Resource, ResourceService} from './resource';
import {isNumber} from '../util';

interface GroupProducerAvailabilityParams {
	marketId: number;
	startDateTime: string;
	endDateTime: string;
	delta: string;
	itemIds?: Array<number>;
}

class GroupProducerAvailability extends Resource<DateTimeAvailability> {
	static uri: string = 'availability';

	async list(params: GroupProducerAvailabilityParams, cfg?: Omit<IRequestConfig, 'params' | 'method' | 'url'>): Promise<Array<DateTimeAvailability>> {
		const p: any = {
			m: params.marketId,
			start: params.startDateTime,
			end: params.endDateTime,
			dx: params.delta,
			i: params.itemIds,
		};
		return super.list(p, cfg);
	}
}

class GroupProducerMarket extends Resource<IMarket> {
	static uri: string = 'markets';
}

class GroupProducerTermsConditions extends Resource<ITermsConditions> {
	static uri: string = 'terms-conditions';

	active(): Promise<ITermsConditions[]> {
		return this.list({active: 1});
	}
}

export class GroupProducerService extends ResourceService {
	static uri: string = '/api/group/producer';

	availability: GroupProducerAvailability;
	market: GroupProducerMarket;
	termsConditions: GroupProducerTermsConditions;

	constructor() {
		super();
		this.availability = new GroupProducerAvailability(this);
		this.market = new GroupProducerMarket(this);
		this.termsConditions = new GroupProducerTermsConditions(this);
	}

	async catalog(clientUserID?: number): Promise<ICatalog> {
		const params = isNumber(clientUserID) ? {cuid: clientUserID} : undefined;
		const url = this.url('catalog');
		return (await this.GET<ICatalog>(url, params)).data;
	}

	async hours(): Promise<IBusinessHours[]> {
		const url = this.url('hours');
		return (await this.GET<IBusinessHours[]>(url)).data;
	}
}

export const groupProducerService: GroupProducerService = new GroupProducerService();

import {assert} from '../util';

function fuzzyIsNull(d: number): boolean {
	return Math.abs(d) <= 0.000000000001;
}

export class Point implements Ieq {
	static add(a: Point, b: Point): Point {
		return new this(a.xp + b.xp, a.yp + b.yp);
	}

	static div(point: Point, divisor: number): Point {
		assert(!fuzzyIsNull(divisor));
		return new this(Math.round(point.xp / divisor), Math.round(point.yp / divisor));
	}

	static dotProduct(a: Point, b: Point): number {
		return (a.xp * b.xp) + (a.yp * b.yp);
	}

	static eq(a: Point, b: Point): boolean {
		return (a.xp === b.xp) && (a.yp === b.yp);
	}

	static mul(point: Point, factor: number): Point;
	static mul(factor: number, point: Point): Point;
	static mul(...args: [Point, number] | [number, Point]): Point {
		let factor: number;
		let point: Point;
		if (typeof args[0] === 'number') {
			[factor, point] = <[number, Point]>args;
		} else {
			[point, factor] = <[Point, number]>args;
		}
		return new this(Math.round(point.xp * factor), Math.round(point.yp * factor));
	}

	static ne(a: Point, b: Point): boolean {
		return (a.xp !== b.xp) || (a.yp !== b.yp);
	}

	static neg(point: Point): Point {
		return new this(-point.xp, -point.yp);
	}

	static sub(a: Point, b: Point): Point {
		return new this(a.xp - b.xp, a.yp - b.yp);
	}

	private xp: number;
	private yp: number;

	constructor(x: number, y: number);
	constructor(other: Point);
	constructor();
	constructor(...args: [number, number] | [Point] | []) {
		let xp: number = 0;
		let yp: number = 0;
		if (args.length === 2) {
			[xp, yp] = args;
		} else if (args.length === 1) {
			const other = args[0];
			xp = other.xp;
			yp = other.yp;
		}
		this.xp = Math.round(xp);
		this.yp = Math.round(yp);
	}

	eq(other: Point): boolean {
		return Point.eq(this, other);
	}

	iadd(other: Point): this {
		this.xp += other.xp;
		this.yp += other.yp;
		return this;
	}

	idiv(divisor: number): this {
		assert(!fuzzyIsNull(divisor));
		this.xp = Math.round(this.xp / divisor);
		this.yp = Math.round(this.yp / divisor);
		return this;
	}

	imul(factor: number): this {
		this.xp = Math.round(this.xp * factor);
		this.yp = Math.round(this.yp * factor);
		return this;
	}

	isNull(): boolean {
		return (this.xp === 0) && (this.yp === 0);
	}

	isub(other: Point): this {
		this.xp -= other.xp;
		this.yp -= other.yp;
		return this;
	}

	manhattanLength(): number {
		return Math.abs(this.xp) + Math.abs(this.yp);
	}

	ne(other: Point): boolean {
		return Point.ne(this, other);
	}

	setX(value: number): void {
		this.xp = Math.round(value);
	}

	setY(value: number): void {
		this.yp = Math.round(value);
	}

	toString(): string {
		return `Point(x: ${this.xp}, y: ${this.yp})`;
	}

	transposed(): Point {
		return new Point(this.yp, this.xp);
	}

	x(): number {
		return this.xp;
	}

	y(): number {
		return this.yp;
	}
}

import React, {PropsWithChildren} from 'react';

interface IIconProps {
	className?: string;
	id?: string;
	middleAlign?: boolean;
	name: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => any;
	style?: React.CSSProperties;
	title?: string;
}

export default function Icon(props: PropsWithChildren<IIconProps>) {
	const {className, id, middleAlign, name, onClick, style, title} = props;
	const classNames: string[] = ['material-icons'];
	if (middleAlign) {
		classNames.push('vertical-align--middle');
	}
	if (className) {
		classNames.push(className);
	}
	return (
		<i onClick={onClick} aria-hidden="true" className={classNames.join(' ')} id={id} style={style} title={title}>{name}</i>
	);
}

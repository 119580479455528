import {combineReducers} from 'redux';

import {isNumber} from '../../util';
import {initialState} from './state';
import {set} from '../../tools';
import {ItemRole} from '../../constants';
import {CatalogActionTypes} from '../actions/catalog';
import {
	RESET_CATALOG,
	RECEIVE_GROUP_PRODUCER_CATALOG,
} from '../actions/types';

type HasName = {name: string;};
type HasSize = {size: number;};
const initial: ICatalogState = initialState('catalog');

function sortNamedThings<T extends HasName>(items: T[]): T[] {
	const cmp = new Intl.Collator().compare;

	function key(objA: T, objB: T): number {
		return cmp(objA.name.trim(), objB.name.trim());
	}

	const rv = [...items];
	rv.sort(key);
	return rv;
}

function sizedThingSortKey<T extends HasSize>(a: T, b: T): number {
	if (a.size < b.size) {
		return -1;
	}
	if (a.size > b.size) {
		return 1;
	}
	return 0;
}

function allChoices(state: IChoice[] = initial.allChoices, action: CatalogActionTypes): IChoice[] {
	switch (action.type) {
		case RECEIVE_GROUP_PRODUCER_CATALOG:
			return sortNamedThings(action.data.choices);
		default:
			return state;
	}
}

function allItems(state: ICatalogItem[] = initial.allItems, action: CatalogActionTypes): ICatalogItem[] {
	switch (action.type) {
		case RECEIVE_GROUP_PRODUCER_CATALOG:
			return action.data.items;
		default:
			return state;
	}
}

function choiceByID(state: ChoiceByID = initial.choiceByID, action: CatalogActionTypes): ChoiceByID {
	switch (action.type) {
		case RECEIVE_GROUP_PRODUCER_CATALOG:
			const {choices} = action.data;
			const choicesByID = choices.reduce<ChoiceByID>((acc, choice) => {
				acc[choice.id] = choice;
				return acc;
			}, {});
			return {
				...state,
				...choicesByID,
			};
		case RESET_CATALOG:
			return {};
		default:
			return state;
	}
}

function itemByID(state: CatalogItemByID = initial.itemByID, action: CatalogActionTypes): CatalogItemByID {
	switch (action.type) {
		case RECEIVE_GROUP_PRODUCER_CATALOG:
			const {items} = action.data;
			const itemsByID = items.reduce<CatalogItemByID>((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {});
			return {
				...state,
				...itemsByID,
			};
		case RESET_CATALOG:
			return {};
		default:
			return state;
	}
}

export default combineReducers<ICatalogState>({
	allChoices,
	allItems,
	choiceByID,
	itemByID,
});

export function getAddOns(state: ICatalogState, itemID: number): ICatalogItem[] {
	const obj = getItem(state, itemID);
	if (obj) {
		const ids = new set(obj.addons);
		return state.allItems.filter(item => ids.has(item.id));
	}
	return [];
}

export function getItem(state: ICatalogState, itemID: number): ICatalogItem | null {
	const item = state.itemByID[itemID];
	return item || null;
}

export function getItemChildren(state: ICatalogState, itemID: number): ICatalogItem[] {
	return state.allItems.filter(itm => (itm.parentId === itemID));
}

export function getItemChildForSize(state: ICatalogState, itemID: number, size: number): ICatalogItem | null {
	const childrenWithSize = getItemChildren(state, itemID)
		.filter(child => (isNumber(child.size)));
	(<HasSize[]>childrenWithSize).sort(sizedThingSortKey);
	for (let i = 0; i < childrenWithSize.length; ++i) {
		const child = childrenWithSize[i];
		if ((<number>child.size) >= size) {
			return child;
		}
	}
	return null;
}

export function getItemsMaxSize(state: ICatalogState): number | null {
	const items = state.allItems;
	const sizes: number[] = [];
	for (let i = 0; i < items.length; ++i) {
		const obj = items[i];
		if (isNumber(obj.sizeMax)) {
			sizes.push(obj.sizeMax);
		}
	}
	const max = Math.max(...sizes);
	return Number.isFinite(max) ? max : null;
}

export function getChoices(state: ICatalogState, itemID: number): IChoice[] {
	const obj = getItem(state, itemID);
	return obj ? state.allChoices.filter(ch => (ch.itemId === itemID)) : [];
}

function isAddOn(item: ICatalogItem): boolean {
	return item.items.length > 0;
}

function isTopLevelItem(item: ICatalogItem): boolean {
	return !isAddOn(item) && ((item.role === ItemRole.Standalone) || (item.role === ItemRole.Parent));
}

export function topLevelItems(state: ICatalogState): ICatalogItem[] {
	return state.allItems.filter(isTopLevelItem);
}


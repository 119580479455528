import {El, elOpts, ElOpts} from '../el';
import {Completer} from './completer';
import {List, ListItemSelectEvt} from './list';
import {CaseSensitivity, MatchFlag} from '../constants';
import {bind, isNumber, pixelString} from '../util';
import {Evt} from '../evt';

export class AutoCompleterSelectEvt extends Evt {
	private c: string;
	private i: number;

	constructor(completion: string, index: number) {
		super(Evt.Select);
		this.c = completion;
		this.i = index;
	}

	completion(): string {
		return this.c;
	}

	index(): number {
		return this.i;
	}
}

export interface AutoCompleterOpts extends ElOpts {
	items: Iterable<string>;
}

export class AutoCompleter extends El {
	completer: Completer;
	list: List;

	constructor(opts: Partial<AutoCompleterOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<AutoCompleterOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<AutoCompleterOpts> | null, tagName?: TagName);
	constructor(opts: Partial<AutoCompleterOpts> | null, root?: Element | null);
	constructor(opts: Partial<AutoCompleterOpts>, parent?: El | null);
	constructor(opts?: Partial<AutoCompleterOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<AutoCompleterOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<AutoCompleterOpts>(a, b, c);
		if (!opts.root && !opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.hide();
		this.setId('id_pb-project-list-search-autocomplete-result-container');
		this.completer = new Completer(opts.items);
		this.completer.setCaseSensitivity(CaseSensitivity.CaseInsensitive);
		this.completer.setFilterMode(MatchFlag.MatchContains);
		this.list = new List(this);
		this.list.onEvt(this.listEvt);
	}

	completionCount(): number {
		return this.completer.completionCount();
	}

	completionPrefix(): string {
		return this.completer.completionPrefix();
	}

	currentRow(): number {
		return this.list.currentRow();
	}

	currentRowCompletion(): string {
		const currRow = this.currentRow();
		if (currRow >= 0 && currRow < this.completer.completionCount()) {
			for (let i = 0; this.completer.setCurrentRow(i); ++i) {
				if (i === currRow) {
					return this.completer.currentCompletion();
				}
			}
		}
		return '';
	}

	destroy(): void {
		this.list.offEvt(this.listEvt);
		this.list.destroy();
		this.completer.destroy();
		super.destroy();
	}

	focusFirstItem(): void {
		this.list.focusItemAt(0);
	}

	isOpen(): boolean {
		return this.isVisible() && Boolean(this.parent()) && (this.completer.completionCount() > 0);
	}

	@bind
	protected listEvt(evt: Evt): void {
		if (evt.type() === Evt.Select) {
			for (let i = 0; this.completer.setCurrentRow(i); ++i) {
				if (i === (<ListItemSelectEvt>evt).index()) {
					const completion = this.completer.currentCompletion();
					const index = this.completer.currentIndex();
					this.notifyEvt(new AutoCompleterSelectEvt(completion, index));
					return;
				}
			}
		}
	}

	setItems(items: Iterable<string>): void {
		this.list.clear();
		this.completer.setItems(items);
	}

	setPrefix(prefix: string): void {
		if (!prefix) {
			this.hide();
			return;
		}
		this.completer.setCompletionPrefix(prefix);
		this.list.clear();
		const count = this.completer.completionCount();
		if (count > 0) {
			if (count > 7) {

			}
			this.show();
			let height = 0;
			for (let i = 0; this.completer.setCurrentRow(i); ++i) {
				const s = this.completer.currentCompletion();
				const item = this.list.addItem(s);
				if (height < 336) {
					height += item.rect().height;
				}
			}
			const listElem = this.list.element();
			if (listElem) {
				const computed = window.getComputedStyle(listElem);
				const computedHeight = computed.height.trim().toLowerCase();
				let heightWithoutPadding: number;
				if (computedHeight.endsWith('px')) {
					heightWithoutPadding = (computedHeight.indexOf('.') >= 0) ? Number.parseFloat(computedHeight.replace(/px$/, '')) : Number.parseInt(computedHeight.replace(/px$/, ''));
				} else {
					heightWithoutPadding = (computedHeight.indexOf('.') >= 0) ? Number.parseFloat(computedHeight) : Number.parseInt(computedHeight);
				}
				if (isNumber(heightWithoutPadding)) {
					const heightWithPadding = this.list.rect().height;
					const paddingHeight = heightWithPadding - heightWithoutPadding;
					if (paddingHeight > 0) {
						height += paddingHeight;
					}
				}
			}
			this.setStyleProperty('height', pixelString(height));
		} else {
			this.hide();
		}
	}
}

import React, {PropsWithChildren} from 'react';

interface IProps {
	affirmText?: string;
	denyText?: string;
	onClick: (affirm: boolean) => any;
}

export default function Confirm(props: PropsWithChildren<IProps>) {
	const {affirmText, children, denyText, onClick} = props;
	const aTxt = affirmText ? affirmText : 'YES';
	const dTxt = denyText ? denyText : 'NO';
	return (
		<div>
			{children}
			<button className="mdc-button mdc-button--raised margin-left--8" onClick={() => onClick(false)} type="button">
				{dTxt}
			</button>
			<button className="mdc-button" onClick={() => onClick(true)} style={{color: 'red'}} type="button">
				{aTxt}
			</button>
		</div>
	);
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import store from './store';
import {UNIVERSAL_APP_ROOT_SELECTOR} from '../constants';

type AppName = 'createproject' | 'producer' | 'projectinvoice' | 'rescheduleproject' | 'wx';

export async function startApp(which: AppName, selector?: string): Promise<void> {
	let imp;
	switch (which) {
		case 'createproject':
			imp = import('./project/create');
			break;
		case 'producer':
			imp = import('./producer');
			break;
		case 'projectinvoice':
			imp = import('./project/invoice');
			break;
		case 'rescheduleproject':
			imp = import('./project/reschedule');
			break;
		case 'wx':
			imp = import('./marketwx');
			break;
		default:
			console.log(`reactui::startApp invalid argument ${which}`);
			return;
	}
	const mod = await imp;
	ReactDOM.render(
		<Provider store={store}>
			<mod.default/>
		</Provider>,
		document.querySelector(selector || UNIVERSAL_APP_ROOT_SELECTOR));
}

import {apiService as svc} from '../services';
import {El, elOpts, ElOpts} from '../el';
import {AbstractItemModel, ModelIndex} from '../itemmodel';
import {CurrentMenu} from '../ui/util/currentmenu';
import {DataTable} from '../ui/datatable';
import {Paginator} from '../ui/paginator';
import {DataTableEvt, DataTableHeaderSectionChangeEvt, DataTableSortEvt} from '../ui/datatable/evt';
import {TableCellMouseEvt, TableEl} from '../ui/datatable/el';
import {bind} from '../util';
import {Evt} from '../evt';
import {TableItem, TableModel} from '../ui/datatable/model';
import {AlignmentFlag, ItemDataRole, Orientation, SortOrder} from '../constants';
import {HeaderSectionMenu} from '../ui/headersectionmenu';
import {ListItemSelectEvt} from '../ui/list';
import {list, set} from '../tools';
import {Menu, MenuStateChangeEvt} from '../ui/menu';
import {TableElDelegate} from '../ui/datatable/delegate';
import {Variant} from '../variant';
import {IDataTableElOpts} from '../ui/datatable/dtel';

export interface AbstractTableViewOpts extends ElOpts {
	dtElOpts: Partial<IDataTableElOpts>;
	model: AbstractItemModel;
	paginator: Paginator;
	tableElColumnMap: Map<string, typeof TableEl>;
	tableElDelegateColumnMap: Map<string, typeof TableElDelegate>;
	tableItemPrototype: typeof TableItem;
	userUiTablePk: string;
}

export abstract class AbstractTableView extends El {
	static UITableName: string = '';

	protected currentIndex: ModelIndex;
	protected currentMenu: CurrentMenu;
	protected dataTable: DataTable;
	protected model: AbstractItemModel;
	protected paginator: Paginator | null;
	protected preSetDataSortEnabled: boolean;
	protected tableElColumnMap: Map<string, typeof TableEl>;
	protected tableElDelegateColumnMap: Map<string, typeof TableElDelegate>;
	protected tableItemPrototype: typeof TableItem;
	private userUiTable: IUserUITable;
	protected userUiTablePk: string;

	constructor(opts: Partial<AbstractTableViewOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<AbstractTableViewOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<AbstractTableViewOpts> | null, tagName?: TagName);
	constructor(opts: Partial<AbstractTableViewOpts> | null, root?: Element | null);
	constructor(opts: Partial<AbstractTableViewOpts>, parent?: El | null);
	constructor(opts?: Partial<AbstractTableViewOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<AbstractTableViewOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<AbstractTableViewOpts>(a, b, c);
		super(opts);
		this.currentIndex = new ModelIndex();
		this.currentMenu = new CurrentMenu();
		const dtRoot = document.querySelector('.mdc-data-table');
		const dtTag = dtRoot ?
			undefined :
			'div';
		const dtParent = dtRoot ?
			undefined :
			this.element();
		const dtElOpts: Partial<IDataTableElOpts> = opts.dtElOpts || {};
		this.dataTable = new DataTable({
			elOpts: {
				parent: dtParent ?
					new El({root: dtParent}) :
					undefined,
				root: dtRoot,
				tagName: dtTag,
				...dtElOpts,
			},
		});
		this.model = AbstractItemModel.staticEmptyModel();
		this.paginator = opts.paginator || null;
		this.preSetDataSortEnabled = false;
		this.tableElColumnMap = opts.tableElColumnMap ?
			opts.tableElColumnMap :
			new Map<string, typeof TableEl>();
		this.tableElDelegateColumnMap = opts.tableElDelegateColumnMap ?
			opts.tableElDelegateColumnMap :
			new Map<string, typeof TableElDelegate>();
		this.tableItemPrototype = opts.tableItemPrototype ?
			opts.tableItemPrototype :
			TableItem;
		this.userUiTable = staticUserUiTable;
		this.userUiTablePk = opts.userUiTablePk ?
			opts.userUiTablePk :
			(<typeof AbstractTableView>this.constructor).UITableName;
		this.init(opts.model);
	}

	protected beginFetchData(): void {
		this.dataTable.beginFetchData();
	}

	protected beginSetData(): void {
		this.preSetDataSortEnabled = this.dataTable.isSortingEnabled();
		this.dataTable.setSortingEnabled(false);
	}

	protected async columnSelectionchangeEvt(evt: DataTableHeaderSectionChangeEvt): Promise<void> {
		const pk = this.userUiTablePk;
		await svc.ui.batchUpdateColumns(pk, evt.sections().toArray());
		this.setUserUiTable(await svc.ui.table(pk));
		await this.fetchData();
	}

	protected contextMenuEvt(evt: TableCellMouseEvt): void {
		if (evt.row() < 0) {
			// Horizontal header
			evt.accept();
			this.openColumnSelectionMenu(evt.x(), evt.y());
		}
	}

	protected async createUserUiTableColumn(data: IUserUITableColumn): Promise<IUserUITable> {
		const pk = this.userUiTablePk;
		await svc.ui.createColumn(pk, data);
		return await svc.ui.table(pk);
	}

	@bind
	protected dataTableEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.ContextMenu:
				if (evt instanceof TableCellMouseEvt) {
					this.contextMenuEvt(evt);
				}
				break;
			case DataTableEvt.HeaderSectionChange:
				if (evt instanceof DataTableHeaderSectionChangeEvt) {
					this.columnSelectionchangeEvt(evt);
				}
				break;
			case DataTableEvt.ItemClick:
				if (evt instanceof DataTableEvt) {
					this.dataTableItemClickEvt(evt);
				}
				break;
			case DataTableEvt.SectionSelectionChange:
				if (evt instanceof DataTableEvt) {
					this.dataTableRowSelectionChangeEvt(evt);
				}
				break;
			case DataTableEvt.Sort:
				if (evt instanceof DataTableSortEvt) {
					this.dataTableSortEvt(evt);
				}
				break;
			case DataTableEvt.SectionMove:
				if (evt instanceof DataTableEvt) {
					this.dataTableSectionMoveEvt(evt);
				}
				break;
			case DataTableEvt.SectionClick:
				if (evt instanceof DataTableEvt) {
					this.dataTableSectionClickEvt(evt);
				}
				break;
		}
	}

	protected dataTableItem(index: ModelIndex): TableItem | null {
		if (this.model instanceof TableModel) {
			return this.model.item(index);
		}
		return null;
	}

	protected dataTableItemClickEvt(evt: DataTableEvt): void {
		this.currentIndex = evt.index();
	}

	protected dataTableRowSelectionChangeEvt(evt: DataTableEvt): void {
	}

	protected dataTableSectionClickEvt(evt: DataTableEvt): void {
		if (evt.orientation() === Orientation.Horizontal) {
			evt.setAccepted(true);
			const pos = evt.clientPos();
			this.openColumnSelectionMenu(pos.x(), pos.y());
		}
	}

	protected dataTableSectionMoveEvt(evt: DataTableEvt): void {
		this.moveSection(
			evt.fromSection(),
			evt.toSection(),
			evt.orientation());
	}

	protected async dataTableSortEvt(evt: DataTableSortEvt): Promise<void> {
		const pk = this.userUiTablePk;
		const userColLogIdx = this.userUiTableColumnLogicalIndex(evt.column());
		const userCol = (userColLogIdx >= 0) ? this.userUiTable.userColumns[userColLogIdx] : null;
		if (userCol) {
			await svc.ui.setSort(
				pk,
				{
					ascending: evt.order() === SortOrder.AscendingOrder,
					userUiTableColumnId: userCol.id,
				});
			this.userUiTable = await svc.ui.table(pk);
		}
	}

	destroy(): void {
		this.userUiTable = staticUserUiTable;
		this.currentIndex = new ModelIndex();
		const el = document.querySelector<HTMLElement>('.pb-table-column-select-menu-btn');
		if (el) {
			el.removeEventListener('click', this.settingsButtonClickEvent);
		}
		this.currentMenu.destroy();
		if (this.paginator) {
			this.paginator.offEvt(this.paginatorEvt);
			this.paginator.destroy();
		}
		this.paginator = null;
		this.dataTable.offEvt(this.dataTableEvt);
		this.dataTable.destroy();
		this.tableElColumnMap.clear();
		this.tableElDelegateColumnMap.clear();
		this.tableItemPrototype = TableItem;
		this.model = AbstractItemModel.staticEmptyModel();
		super.destroy();
	}

	protected endFetchData(): void {
		this.dataTable.endFetchData();
	}

	protected endSetData(): void {
		this.dataTable.setSortingEnabled(this.preSetDataSortEnabled);
	}

	protected async fetchData(...args: Array<any>): Promise<any> {
	}

	protected async headerSectionMenuEvt(evt: Evt, menu: HeaderSectionMenu): Promise<void> {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const idx = evt.index();
			if ((idx >= 0) && (idx < this.userUiTable.columns.length)) {
				const uiCol = this.userUiTable.columns[idx];
				const item = menu.item(idx);
				if (item) {
					const isVisible = item.isChecked();
					const existingUserCol = this.userUiTableColumnByUiTableColumnId(uiCol.id);
					if (existingUserCol) {
						// Update columns
						existingUserCol.isVisible = isVisible;
						this.setUserUiTable(
							await this.updateUserUiTableColumn(existingUserCol));
					} else {
						// Create column
						this.setUserUiTable(
							await this.createUserUiTableColumn({
								isVisible,
								uiTableColumnId: uiCol.id,
								index: uiCol.defaultIndex,
								id: -1,
							}));
					}
				}
			}
		}
	}

	protected async init(model?: AbstractItemModel | null): Promise<void> {
		if (model !== undefined) {
			this.setModel(model);
		}
		this.dataTable.setSortingEnabled(true);
		this.dataTable.setSectionsMovable(true, Orientation.Horizontal);
		this.dataTable.setSectionsMovable(false, Orientation.Vertical);
		if (this.paginator) {
			this.paginator.initialize();
			this.paginator.onEvt(this.paginatorEvt);
		}
		this.setUserUiTable(await svc.ui.table(this.userUiTablePk));
		const el = document.querySelector<HTMLElement>('.pb-table-column-select-menu-btn');
		if (el) {
			el.addEventListener('click', this.settingsButtonClickEvent);
		}
		this.dataTable.onEvt(this.dataTableEvt);
	}

	@bind
	protected menuEvt(evt: Evt): void {
		if (evt.type() === Evt.StateChange) {
			if ((evt instanceof MenuStateChangeEvt) && evt.closed() && this.currentMenu.instance) {
				this.setCurrentMenu(null, CurrentMenu.NoMenu);
			}
			return;
		}
		switch (this.currentMenu.type) {
			case CurrentMenu.TableColumn:
				if (this.currentMenu.instance && (this.currentMenu.instance instanceof HeaderSectionMenu)) {
					this.headerSectionMenuEvt(evt, this.currentMenu.instance);
				}
				break;
		}
	}

	protected moveHorizontalSection(fromVisualIndex: number, toVisualIndex: number): void {
		if (fromVisualIndex === toVisualIndex) {
			return;
		}
		const fromLogicalIndex = this.userUiTableColumnLogicalIndex(fromVisualIndex);
		const toLogicalIndex = this.userUiTableColumnLogicalIndex(toVisualIndex);
		if ((fromLogicalIndex >= 0) && (toLogicalIndex >= 0)) {
			const userColList = new list(this.userUiTable.userColumns);
			userColList.move(fromLogicalIndex, toLogicalIndex);
			for (let i = 0; i < userColList.size(); ++i) {
				userColList.at(i).index = i;
			}
			this.updateUserUiTableColumns(userColList.toArray())
				.then(tbl => this.setUserUiTable(tbl));
		}
	}

	protected moveSection(fromVisualIndex: number, toVisualIndex: number, orientation: Orientation): void {
		if (orientation === Orientation.Horizontal) {
			this.moveHorizontalSection(fromVisualIndex, toVisualIndex);
		} else {
			this.moveVerticalSection(fromVisualIndex, toVisualIndex);
		}
	}

	protected moveVerticalSection(fromVisualIndex: number, toVisualIndex: number): void {
	}

	protected openColumnSelectionMenu(x: number, y: number): void {
		const uiColIdUserColMap = new Map<number, IUserUITableColumn>(
			this.userUiTable.userColumns.map(col => ([col.uiTableColumnId, col])));
		const instance = new HeaderSectionMenu();
		for (let i = 0; i < this.userUiTable.columns.length; ++i) {
			const uiCol = this.userUiTable.columns[i];
			const userCol = uiColIdUserColMap.get(uiCol.id);
			const isVisible = userCol ?
				userCol.isVisible :
				false;
			instance.addHeaderSection(uiCol.id, uiCol.name, isVisible);
		}
		instance.setWidth(224);
		this.setCurrentMenu(instance, CurrentMenu.TableColumn);
		this.currentMenu.open(x, y);
	}

	@bind
	protected paginatorEvt(evt: Evt): void {
	}

	protected setCurrentMenu(menu: Menu | null, type: number): void {
		if (this.currentMenu.instance) {
			this.currentMenu.instance.offEvt(this.menuEvt);
		}
		if (menu) {
			menu.onEvt(this.menuEvt);
		}
		this.currentMenu.setCurrentMenu(menu, type);
	}

	@bind
	protected settingsButtonClickEvent(event: MouseEvent): void {
		this.openColumnSelectionMenu(event.clientX, event.clientY);
	}

	protected setModel(model: AbstractItemModel | null): void {
		if ((model === this.model) || (!model && (this.model === AbstractItemModel.staticEmptyModel()))) {
			return;
		}
		this.model = model ?
			model :
			AbstractItemModel.staticEmptyModel();
		this.dataTable.setModel(model);
	}

	protected setUserUiTable(userUiTable: IUserUITable): void {
		this.userUiTable = userUiTable;
		this.dataTable.clear();
		this.setUserUiTableColumns(userUiTable);
		this.setUserUiTableSort(userUiTable);
		this.userUiTableChanged();
	}

	protected setUserUiTableColumns(uiTable: IUserUITable): void {
		const visibleUiCols = this.visibleUiTableColumns(uiTable.columns);
		for (let column = 0; column < visibleUiCols.length; ++column) {
			this.dataTable.setElForColumn(column, null);
			this.dataTable.setItemDelegateForColumn(column, null);
		}
		const alignRight = new set(['integer', 'float', 'decimal', 'timedelta', 'datetime', 'date']);
		this.dataTable.setColumnCount(visibleUiCols.length);
		for (let column = 0; column < visibleUiCols.length; ++column) {
			const col = visibleUiCols[column];
			const elCls = this.tableElColumnMap.get(col.name);
			if (elCls) {
				this.dataTable.setElForColumn(column, elCls);
			}
			const delCls = this.tableElDelegateColumnMap.get(col.name);
			if (delCls) {
				this.dataTable.setItemDelegateForColumn(column, delCls);
			}
			const item = new this.tableItemPrototype(col.name);
			if (alignRight.has(col.dataType)) {
				item.setData(ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight));
			}
			this.dataTable.setHorizontalHeaderItem(column, item);
		}
	}

	protected setUserUiTableSort(uiTable: IUserUITable): void {
		if (uiTable.sort.length > 0) {
			const sort = uiTable.sort[0];
			const userColLogicalIdx = uiTable.userColumns.findIndex(col => (col.id === sort.userUiTableColumnId));
			const userColVisualIdx = this.userUiTableColumnVisibleIndex(userColLogicalIdx);
			if (userColVisualIdx >= 0) {
				this.dataTable.sortByColumn(
					userColVisualIdx,
					sort.ascending ?
						SortOrder.AscendingOrder :
						SortOrder.DescendingOrder);
				this.dataTable.setSortIndicatorShown(true);
				return;
			}
		}
		this.dataTable.setSortIndicatorShown(false);
	}

	protected uiTableColumn(id: number): IUITableColumn | null {
		for (let i = 0; i < this.userUiTable.columns.length; ++i) {
			const col = this.userUiTable.columns[i];
			if (col.id === id) {
				return col;
			}
		}
		return null;
	}

	protected uiTableColumns(): Array<IUITableColumn> {
		return this.userUiTable.columns;
	}

	protected async updateUserUiTableColumn(data: IUserUITableColumn): Promise<IUserUITable> {
		const pk = this.userUiTablePk;
		await svc.ui.updateColumn(
			pk,
			data.id,
			data,
		);
		return await svc.ui.table(pk);
	}

	protected async updateUserUiTableColumns(userUiColumns: Array<IUserUITableColumn>): Promise<IUserUITable> {
		const pk = this.userUiTablePk;
		await svc.ui.batchUpdateColumns(
			pk,
			userUiColumns);
		return await svc.ui.table(pk);
	}

	protected userUiTableChanged(): void {
	}

	protected userUiTableColumnAtLogicalIndex(logicalIndex: number): IUserUITableColumn | null {
		if ((logicalIndex >= 0) && (logicalIndex < this.userUiTable.userColumns.length)) {
			return this.userUiTable.userColumns[logicalIndex];
		}
		return null;
	}

	protected userUiTableColumnByUiTableColumnId(id: number): IUserUITableColumn | null {
		for (let i = 0; i < this.userUiTable.userColumns.length; ++i) {
			const userCol = this.userUiTable.userColumns[i];
			if (userCol.uiTableColumnId === id) {
				return userCol;
			}
		}
		return null;
	}

	protected userUiTableColumnLogicalIndex(visibleIndex: number): number {
		let vi = 0;
		for (let i = 0; i < this.userUiTable.userColumns.length; ++i) {
			const userCol = this.userUiTable.userColumns[i];
			if (!userCol.isVisible) {
				continue;
			}
			if (vi === visibleIndex) {
				return i;
			}
			++vi;
		}
		return -1;
	}

	protected userUiTableColumnVisibleIndex(logicalIndex: number): number {
		if ((logicalIndex >= 0) && (logicalIndex < this.userUiTable.userColumns.length)) {
			const userCol = this.userUiTable.userColumns[logicalIndex];
			return this.visibleUserUiTableColumns().indexOf(userCol);
		}
		return -1;
	}

	protected userUiTableFilters(): Array<IUIFilter> {
		return this.userUiTable.filters;
	}

	protected visibleUiTableColumnAtVisibleIndex(visibleIndex: number): IUITableColumn | null {
		const visibleCols = this.visibleUiTableColumns(this.uiTableColumns());
		if ((visibleIndex >= 0) && (visibleIndex < visibleCols.length)) {
			return visibleCols[visibleIndex];
		}
		return null;
	}

	protected visibleUiTableColumns(uiTableColumns: Array<IUITableColumn>): Array<IUITableColumn> {
		const uiColIdMap = new Map<number, IUITableColumn>(
			uiTableColumns.map(col => ([col.id, col])));
		const visibleUserCols = this.visibleUserUiTableColumns();
		const rv: Array<IUITableColumn> = [];
		for (const userCol of visibleUserCols) {
			const uiCol = uiColIdMap.get(userCol.uiTableColumnId);
			if (uiCol) {
				rv.push(uiCol);
			}
		}
		return rv;
	}

	protected visibleUserUiTableColumns(): Array<IUserUITableColumn> {
		return this.userUiTable.userColumns.filter(col => col.isVisible);
	}
}

const staticUserUiTable: IUserUITable = Object.freeze({
	columns: [],
	filters: [],
	name: '',
	sort: [],
	userColumns: [],
});

import React from 'react';
import {connect} from 'react-redux';

import {set} from '../../../tools';
import {date, datetime, time, timedelta} from '../../../datetime';
import {apiService as svc} from '../../../services';
import {isoDateToIDate, isoTimeToITime} from '../../components/scheduler';
import ComboBox, {ComboBoxOption} from '../../components/combobox';
import {CatalogListItem} from './components/catalog';
import {bind, isNumber, cssClassName, numberFormat, makeTime} from '../../../util';
import {StandardButton, DEFAULT_WX_DATE_COUNT} from '../../../constants';
import {
	Card,
	Grid,
	Modal,
	Slider,
	GridCell,
	Scheduler,
	TextField,
	Subheading,
	GridDivider,
} from '../../components';
import {
	getCatalogItemByID,
	getCatalogItemAddOns,
	getCatalogItemChoices,
	getCatalogItemChildren,
	getCatalogItemsInBuggy,
	getCatalogItemsMaxSize,
	getTopLevelCatalogItems,
	getCatalogItemChildForSize,
} from '../../reducers';
import {
	user as userAxns,
	weather as wxAxns,
	buggy as buggyAxns,
	catalog as catAxns,
	project as projAxns,
	producer as prodAxns,
} from '../../actions';
import {
	Header,
	Summary,
	Choices,
	StreetInput,
	LocationName,
	PrimaryButton,
} from './components';
import {Statement} from './components/summary';

interface IDispatchProps {
	accessOptionList: () => any;
	addItemAddOnToBuggy: (itemID: number, addOnID: number) => any;
	addItemChoiceToBuggy: (itemID: number, choiceID: number, isDefaultAction: boolean) => any;
	addItemToBuggy: (itemID: number) => any;
	businessHoursData: () => any,
	catalogData: (clientUserId?: number) => any;
	clientUsersData: () => any;
	createProject: (data: INewProjectData) => any;
	durationData: (query: IDurationRequest) => any;
	emptyBuggy: () => any;
	marketsData: () => any,
	marketWeatherData: (opts: IMarketWeatherRequest) => any;
	occupancyOptionList: () => any;
	removeItemAddOnFromBuggy: (itemID: number, addOnID: number) => any;
	removeItemChoiceFromBuggy: (itemID: number, choiceID: number, isDefaultAction: boolean) => any;
	removeItemFromBuggy: (itemID: number) => any;
	resetCatalogData: () => any;
	setProjectCreateState: (data: Partial<IProjectCreateState>) => any;
	summaryData: (query: ISummaryRequest) => any;
	userData: () => any;
}

interface IProps extends IProjectCreateState {
	accessOptions: IAccessOption[];
	buggyCatalogItemAddOnIDs: number[];
	buggyCatalogItemChoiceIDs: number[];
	buggyCatalogItemIDs: number[];
	buggyInteractedCatalogItemIDs: number[];
	businessHours: IBusinessHours[];
	catalogItemAddOns: (itemID: number) => ICatalogItem[];
	catalogItemByID: (itemID: number) => ICatalogItem | null;
	catalogItemChildForSize: (itemID: number, size: number) => (ICatalogItem | null);
	catalogItemChildren: (itemID: number) => ICatalogItem[];
	catalogItemChoices: (itemID: number) => IChoice[];
	catalogItemsInBuggy: ICatalogItem[];
	catalogItemsMaxSize: number | null;
	clientUsers: IUser[];
	isProducer: boolean;
	markets: IMarket[];
	marketWeather: IMarketWeather;
	occupancyOptions: IOccupancyOption[];
	topLevelCatalogItems: ICatalogItem[];
}

type Props = IDispatchProps & IProps;
type DateAvailabilityMap = Map<string, {sourced: number; data: DateTimeAvailability[]}>;

interface State {
	activatedTermsConditionsID: number | null;
	busy: boolean;
	currentSchedulerDate: string;
	dateAvailability: DateAvailabilityMap;
	dialogButtons: StandardButton | undefined;
	dialogInfoText: string | string[] | undefined;
	dialogIsOpen: boolean;
	dialogResultCallback: ((result: StandardButton) => any) | null;
	dialogText: string | undefined;
	eventStartBeforeAnytime: IDateTime | null;
	mqlSecondLevelTriggered: boolean;
	mqlTopLevelTriggered: boolean;
	selectedSchedulerAnyTime: boolean;
	selectedSchedulerDate: string;
	selectedSchedulerTime: string;
	view: View;
	weekdayTimeRangeMap: WeekdayTimeRangeMap | null;
}

enum View {
	Begin,
	Schedule,
	Final,
}

const TOP_LEVEL_ITEM_COLLAPSE_MAX_WIDTH = 544;
const TIME_DELTA_MINUTES = 30;
const TIME_DELTA_SECONDS = TIME_DELTA_MINUTES * 60;
const TIME_SEGMENT_STEP_MINUTES = TIME_DELTA_MINUTES;
const SECOND_LEVEL_ITEM_COLLAPSE_MAX_WIDTH = 672;

function businessHoursToWeekdayRangeMap(hours: IBusinessHours[]): WeekdayTimeRangeMap {
	const rv: WeekdayTimeRangeMap = {};
	for (let i = 0; i < hours.length; ++i) {
		rv[hours[i].weekday] = hours[i].range;
	}
	return rv;
}

function termsConditionsWithoutAgreement(termsConditionsList: ITermsConditions[], agreementList: ITermsConditionsAgreement[]): ITermsConditions[] {
	const agreedTncIDs: number[] = agreementList.map(obj => obj.termsConditionsId);
	return termsConditionsList.filter(obj => (agreedTncIDs.indexOf(obj.id) < 0));
}

class ProjectCreate extends React.Component<Props, State> {
	private mqlTopLevel: MediaQueryList | null;
	private mqlSecondLevel: MediaQueryList | null;

	constructor(props: Props) {
		super(props);
		this.mqlTopLevel = window.matchMedia(`(max-width: ${TOP_LEVEL_ITEM_COLLAPSE_MAX_WIDTH}px)`);
		this.mqlSecondLevel = window.matchMedia(`(max-width: ${SECOND_LEVEL_ITEM_COLLAPSE_MAX_WIDTH}px)`);
		this.state = {
			activatedTermsConditionsID: null,
			busy: false,
			currentSchedulerDate: '',
			dateAvailability: new Map(),
			dialogButtons: undefined,
			dialogInfoText: undefined,
			dialogIsOpen: false,
			dialogResultCallback: null,
			dialogText: undefined,
			eventStartBeforeAnytime: null,
			mqlSecondLevelTriggered: false,
			mqlTopLevelTriggered: false,
			selectedSchedulerAnyTime: false,
			selectedSchedulerDate: '',
			selectedSchedulerTime: '',
			view: View.Begin,
			weekdayTimeRangeMap: null,
		};
	}

	@bind
	accessNotesChange(event: React.ChangeEvent): void {
		const {setProjectCreateState} = this.props;
		setProjectCreateState({accessNotes: (event as React.ChangeEvent<HTMLTextAreaElement>).target.value});
	}

	@bind
	accessOptionChange(data: {index: number; value: string;}): void {
		const accessOptionId = (data.value.trim().length > 0) ? Number.parseInt(data.value) : null;
		if (isNumber(accessOptionId) || (accessOptionId === null)) {
			this.props.setProjectCreateState({accessOptionId});
		}
	}

	accessSelectOptions(): Array<ComboBoxOption> {
		const {accessOptions, accessOptionId} = this.props;
		return accessOptions.map(axs => ({
			selected: axs.id === accessOptionId,
			text: axs.text,
			value: axs.id,
		}));
	}

	accountTermsConditionsAgreementList(): Promise<ITermsConditionsAgreement[]> {
		return svc.account.termsConditionsAgreement.list();
	}

	availableWithinBounds(start: IDateTime, end: IDateTime): boolean {
		const {weekdayTimeRangeMap} = this.state;
		if (weekdayTimeRangeMap) {
			const timeRange = weekdayTimeRangeMap[start.date.weekdayNumber];
			if (timeRange) {
				return this.timeWithinBounds(start.time, end.time, timeRange);
			}
		}
		return false;
	}

	@bind
	backButtonClick(): void {
		const {currentSchedulerDate, view} = this.state;
		switch (view) {
			case View.Begin:
				return window.location.assign('/');
			case View.Schedule:
				if (!currentSchedulerDate) {
					this.setView(View.Begin);
					this.setDateTime(null);
				}
				this.setState({currentSchedulerDate: ''});
				break;
			case View.Final:
				this.setView(View.Schedule);
				break;
		}
	}

	catalogItemAddOn(itemID: number, addOnID: number): ICatalogItem | null {
		const {catalogItemAddOns} = this.props;
		const addons = catalogItemAddOns(itemID);
		const idx = addons.findIndex(obj => (obj.id === addOnID));
		return addons[idx] || null;
	}

	@bind
	catalogItemAddOnChange(itemID: number, addOnID: number, checked: boolean): void {
		const {addItemAddOnToBuggy, buggyCatalogItemAddOnIDs, removeItemAddOnFromBuggy} = this.props;
		// If item is being checked:
		//     Check if item is exclusive
		//     If item is exclusive
		//         Un-check all other items currently checked
		//     Else
		//         If item currently checked IS exclusive
		//             Un-check item currently checked
		if (checked) {
			const obj = this.catalogItemAddOn(itemID, addOnID);
			if (obj) {
				if (obj.exclusive) {
					buggyCatalogItemAddOnIDs.forEach(checkedID => removeItemAddOnFromBuggy(itemID, checkedID));
				} else {
					if (buggyCatalogItemAddOnIDs.length === 1) {
						const checkedAddOn = this.catalogItemAddOn(itemID, buggyCatalogItemAddOnIDs[0]);
						if (checkedAddOn && checkedAddOn.exclusive) {
							removeItemAddOnFromBuggy(itemID, checkedAddOn.id);
						}
					}
				}
			}
		}
		const proc = checked ? addItemAddOnToBuggy : removeItemAddOnFromBuggy;
		proc(itemID, addOnID);
	}

	@bind
	catalogItemAddOns(itemId: number): ICatalogItem[] {
		// - IF this item is NOT CHECKED
		//     - An empty list is returned (effectively has no add-ons at
		//       this point)
		//     - goto end
		// - ELSE, note this item's position within the currently selected
		//   item ID list.
		// - For each add-on attached to this item
		//     - Check if add-on is also attached to any other item CHECKED
		//       item
		//     - For every other item found to also possess this add-on, note
		//       its position within the currently selected itemId list
		//         - If the other item's checked list position is less than
		//           this item's checked position, this iteration's add-on
		//           DOES NOT get added to the returned collection.
		const {buggyCatalogItemIDs, catalogItemByID} = this.props;
		const thisItemIdx = buggyCatalogItemIDs.indexOf(itemId);
		if (thisItemIdx === -1) {
			return [];
		}
		const thisItem = catalogItemByID(itemId);
		if (!thisItem) {
			return [];
		}
		const otherCheckedItemIDs = buggyCatalogItemIDs.filter(id => (id !== itemId));
		const thisItemAddOnIDs = thisItem.addons;
		const rv: ICatalogItem[] = [];
		for (let i = 0; i < thisItemAddOnIDs.length; ++i) {
			const thisItemAddOnID = thisItemAddOnIDs[i];
			let useAddOn: boolean = true;
			for (let k = 0; k < otherCheckedItemIDs.length; ++k) {
				const otherCheckedItemID = otherCheckedItemIDs[k];
				const otherCheckedItem = catalogItemByID(otherCheckedItemID);
				if (!otherCheckedItem) {
					// Should never occur;
					continue;
				}
				const otherItemAddOnIDs = otherCheckedItem.addons;
				const addOnIdxWithinOtherItemAddOnIDs = otherItemAddOnIDs.indexOf(thisItemAddOnID);
				if (addOnIdxWithinOtherItemAddOnIDs === -1) {
					// Other Item does not also own this add-on
					continue;
				}
				// This Item's add-on is also attached to this other Item
				if (thisItemIdx > k) {
					// Other item appears before this item; thus this item does not render this iteration's add-on.
					useAddOn = false;
					break;
				}
			}
			if (useAddOn) {
				// This Item is either the sole owner of this add-on or is appearing in the selected list before any other item also owning this add-on.
				const thisItemAddOn = this.catalogItemAddOn(itemId, thisItemAddOnID);
				if (thisItemAddOn) {
					rv.push(thisItemAddOn);
				}
			}
		}
		return rv;
	}

	@bind
	catalogItemChange(itemID: number | string, checked: boolean): void {
		const {addItemToBuggy, catalogItemByID, buggyCatalogItemIDs, removeItemFromBuggy} = this.props;
		// If item is being checked:
		//     Check if item is exclusive
		//     If item is exclusive
		//         Un-check all other items currently checked
		//     Else
		//         If item currently checked IS exclusive
		//             Un-check item currently checked
		const idNum = isNumber(itemID) ? itemID : Number.parseInt(itemID);
		if (checked) {
			const obj = isNumber(idNum) ? catalogItemByID(idNum) : null;
			if (obj) {
				if (obj.exclusive) {
					buggyCatalogItemIDs.forEach(checkedID => removeItemFromBuggy(checkedID));
				} else {
					if (buggyCatalogItemIDs.length === 1) {
						const checkedItem = catalogItemByID(buggyCatalogItemIDs[0]);
						if (checkedItem && checkedItem.exclusive) {
							removeItemFromBuggy(checkedItem.id);
						}
					}
				}
			}
		}
		let proc: (itemID: number) => any;
		if (checked) {
			proc = addItemToBuggy;
			if (isNumber(idNum)) {
				this.selectCatalogItemDefaultChoices(idNum);
			}
		} else {
			proc = removeItemFromBuggy;
			if (isNumber(idNum)) {
				this.deselectCatalogItemDefaultChoices(idNum);
			}
		}
		if (isNumber(idNum)) {
			proc(idNum);
		}
	}

	catalogItemDefaultSelectedChoices(itemID: number): IChoice[] {
		const {catalogItemChoices} = this.props;
		return catalogItemChoices(itemID)
			.filter(choice => choice.defaultSelected);
	}

	catalogItemEverInteracted(itemID: number): boolean {
		const {buggyInteractedCatalogItemIDs} = this.props;
		return buggyInteractedCatalogItemIDs.indexOf(itemID) >= 0;
	}

	catalogItemHasSize(itemId: number): boolean {
		const {catalogItemsInBuggy} = this.props;
		for (let i = 0; i < catalogItemsInBuggy.length; ++i) {
			const item = catalogItemsInBuggy[i];
			if (item.id === itemId) {
				return isNumber(item.sizeMax);
			}
		}
		return false;
	}

	catalogItemHasSizeComponent(itemId: number): boolean {
		const {catalogItemByID, buggyCatalogItemIDs} = this.props;
		const indexOfItemId = buggyCatalogItemIDs.indexOf(itemId);
		if (indexOfItemId >= 0) {
			const item = catalogItemByID(itemId);
			if (item && isNumber(item.sizeMax)) {
				for (let i = 0; i < indexOfItemId; ++i) {
					const otherId = buggyCatalogItemIDs[i];
					if (otherId !== itemId) {
						const otherItem = catalogItemByID(otherId);
						if (otherItem && isNumber(otherItem.sizeMax)) {
							// Other item appears before given item within
							// selected items. Given item does not get
							// component.
							return false;
						}
					}
				}
				// Either given item is only selected item or given item was
				// selected before any other item with size.
				return true;
			}
		}
		// Given item is not selected
		return false;
	}

	catalogItemsMaxSize(): number {
		const {catalogItemsMaxSize} = this.props;
		return catalogItemsMaxSize ? catalogItemsMaxSize : 0;
	}

	catalogItemsWithSize(): ICatalogItemWithSize[] {
		const {catalogItemsInBuggy} = this.props;
		const rv: ICatalogItemWithSize[] = [];
		for (let i = 0; i < catalogItemsInBuggy.length; ++i) {
			const item = catalogItemsInBuggy[i];
			if (isNumber(item.sizeMax)) {
				rv.push(item as ICatalogItemWithSize);
			}
		}
		return rv;
	}

	@bind
	checkedCatalogItemChoiceChange(itemID: number, choiceID: number, checked: boolean): void {
		const {addItemChoiceToBuggy, removeItemChoiceFromBuggy} = this.props;
		const proc = checked ? addItemChoiceToBuggy : removeItemChoiceFromBuggy;
		proc(itemID, choiceID, false);
	}

	checkedCatalogItemsHaveSize(): boolean {
		const {buggyCatalogItemIDs} = this.props;
		const itemsWithSize = this.catalogItemsWithSize();
		return itemsWithSize.filter(item => (buggyCatalogItemIDs.indexOf(item.id) >= 0)).length > 0;
	}

	checkedWithChoices(): ICatalogItem[] {
		const {buggyCatalogItemAddOnIDs, buggyCatalogItemIDs, catalogItemByID} = this.props;
		const checkedIDs = (new set([...buggyCatalogItemIDs, ...buggyCatalogItemAddOnIDs])).toArray();
		const rv: ICatalogItem[] = [];
		for (let i = 0; i < checkedIDs.length; ++i) {
			const itemID = checkedIDs[i];
			const item = catalogItemByID(itemID);
			if (item && (item.choices.length > 0)) {
				rv.push(item);
			}
		}
		return rv;
	}

	clearBuggy(): void {
		const {emptyBuggy} = this.props;
		emptyBuggy();
	}

	clearCatalog(): void {
		const {resetCatalogData} = this.props;
		resetCatalogData();
	}

	clearDateAvailability(isoDateString: string): void {
		this.state.dateAvailability.delete(isoDateString);
	}

	@bind
	clientDueDateChange(event: React.ChangeEvent<HTMLInputElement>): void {
		this.props.setProjectCreateState({clientDueDate: event.target.value});
	}

	@bind
	clientDueTimeChange(event: React.ChangeEvent<HTMLInputElement>): void {
		this.props.setProjectCreateState({clientDueTime: event.target.value});
	}

	clientSelectOptions(): Array<ComboBoxOption> {
		const {clientUsers, clientUserID} = this.props;
		return clientUsers.map(user => ({
			selected: user.id === clientUserID,
			text: user.name,
			value: user.id,
		}));
	}

	@bind
	clientUserChange(data: {index: number; value: string;}): void {
		const {setProjectCreateState} = this.props;
		const clientUserID = data.value.trim().length > 0 ? Number.parseInt(data.value) : 0;
		if (isNumber(clientUserID)) {
			setProjectCreateState({clientUserID});
			this.clearBuggy();
			this.clearCatalog();
			this.fetchCatalog(clientUserID);
		}
	}

	componentDidMount(): void {
		this.init();
	}

	componentDidUpdate(): void {
		const {businessHours} = this.props;
		const {weekdayTimeRangeMap} = this.state;
		if (!weekdayTimeRangeMap && (businessHours.length > 0)) {
			this.setState({weekdayTimeRangeMap: businessHoursToWeekdayRangeMap(businessHours)});
		}
	}

	componentWillUnmount(): void {
		if (this.mqlTopLevel) {
			this.mqlTopLevel.removeListener(this.mediaQueryEvent);
			this.mqlTopLevel = null;
		}
		if (this.mqlSecondLevel) {
			this.mqlSecondLevel.removeListener(this.mediaQueryEvent);
			this.mqlSecondLevel = null;
		}
	}

	@bind
	async create(): Promise<void> {
		const {isProducer} = this.props;
		if (!isProducer) {
			const termsConditionsWithoutAgreement = await this.producerActiveTermsConditionsWithoutAgreementList();
			if (termsConditionsWithoutAgreement.length > 0) {
				const obj = termsConditionsWithoutAgreement[0];
				this.setState(
					{activatedTermsConditionsID: obj.id},
					() => this.renderDialog(
						'Terms & Conditions',
						obj.text,
						StandardButton.Accept | StandardButton.Decline,
						this.tncDialogCallback));
				return;
			}
		}
		await this._create();
	}

	async _create(): Promise<void> {
		const data = this.prepareNewProject();
		if (this.validateNewProject(data)) {
			this.setState({busy: true});
			await this.props.createProject(data);
			this.setState({busy: false});
		}
	}

	@bind
	dateAvailability(isoDateString: string): DateTimeAvailability[] {
		const d = this.state.dateAvailability.get(isoDateString);
		if (d) {
			return d.data;
		}
		return [];
	}

	@bind
	dateIsAvailable(isoDate: string): boolean {
		return Boolean(this.timeRangeForWeekday(isoDateToIDate(isoDate).weekdayNumber));
	}

	dateTimeIsOK(dt: IDateTime): boolean {
		const {duration} = this.props;
		const avail = this.dateAvailability(dt.date.isoformat);
		let remainingDuration = duration;
		const availStartIdx = avail.findIndex(a => ((a.datetime.time.hour >= dt.time.hour) && (a.datetime.time.minute >= dt.time.minute)));
		if (availStartIdx >= 0) {
			for (let i = availStartIdx; (i < avail.length) && (remainingDuration > 0); ++i) {
				const av = avail[i];
				if (!av.available) {
					return false;
				}
				remainingDuration -= TIME_DELTA_SECONDS;
			}
		}
		const startDt = datetime.fromIDateTime(dt);
		const delta = new timedelta(undefined, duration);
		const endDt = startDt.add(delta);
		return this.availableWithinBounds(startDt.toIDateTime(), endDt.toIDateTime());
	}

	@bind
	descriptionChangeEvent(event: React.ChangeEvent): void {
		this.props.setProjectCreateState({description: (event as React.ChangeEvent<HTMLInputElement>).target.value});
	}

	deselectCatalogItemDefaultChoices(itemID: number): void {
		if (this.catalogItemEverInteracted(itemID)) {
			return;
		}
		const {removeItemChoiceFromBuggy} = this.props;
		const defaultChoices = this.catalogItemDefaultSelectedChoices(itemID);
		defaultChoices.forEach(choice => removeItemChoiceFromBuggy(itemID, choice.id, true));
	}

	@bind
	dialogResult(result: StandardButton): void {
		const {dialogResultCallback} = this.state;
		if (dialogResultCallback) {
			dialogResultCallback(result);
		}
		this.setState({
			dialogButtons: undefined,
			dialogInfoText: undefined,
			dialogIsOpen: false,
			dialogResultCallback: null,
			dialogText: undefined,
		});
	}

	async fetchCatalog(clientUserID?: number): Promise<void> {
		await this.props.catalogData(clientUserID);
	}

	async fetchDateAvailability(isoDateString: string): Promise<void> {
		const {buggyCatalogItemIDs, marketID} = this.props;
		const d = date.fromisoformat(isoDateString);
		const timeRange = this.timeRangeForWeekday(d.isoweekday());
		if (isNumber(marketID) && timeRange) {
			const delta = `00:${TIME_DELTA_MINUTES}:00`;
			const startTime = time.fromITime(timeRange.start);
			const endTime = time.fromITime(timeRange.stop);
			const startDateTime = datetime.combine(d, startTime);
			const endDateTime = datetime.combine(d, endTime);
			const data = await svc.group.producer.availability.list({
				itemIds: buggyCatalogItemIDs,
				delta,
				startDateTime: startDateTime.isoformat(),
				endDateTime: endDateTime.isoformat(),
				marketId: marketID,
			});
			this.state.dateAvailability.set(isoDateString, {data, sourced: Date.now()});
			this.forceUpdate();
		}
	}

	async init(): Promise<void> {
		const {
			accessOptionList,
			occupancyOptionList,
			businessHoursData,
			clientUsersData,
			marketsData,
			userData,
		} = this.props;
		this.mqlTopLevel && this.mqlTopLevel.addListener(this.mediaQueryEvent);
		this.mqlSecondLevel && this.mqlSecondLevel.addListener(this.mediaQueryEvent);
		const axn = (await userData() as ReceiveUserDataAction);
		marketsData();
		accessOptionList();
		occupancyOptionList();
		if (axn.data.isProducer) {
			clientUsersData();
		} else {
			await this.fetchCatalog();
		}
		setTimeout(() => businessHoursData(), 350);
	}

	@bind
	itemChildren(itemId: number): ICatalogItem[] {
		const {size, catalogItemChildForSize, catalogItemChildren} = this.props;
		if (isNumber(size) && (size >= 0) && this.checkedCatalogItemsHaveSize()) {
			const childForSize = catalogItemChildForSize(itemId, size);
			if (childForSize) {
				return [childForSize];
			}
		}
		return catalogItemChildren(itemId);
	}

	@bind
	locationNameChange(value: string): void {
		this.props.setProjectCreateState({locationName: value});
	}

	market(): IMarket | null {
		const {marketID, markets} = this.props;
		for (let i = 0; i < markets.length; ++i) {
			const market = markets[i];
			if (market.id === marketID) {
				return market;
			}
		}
		return null;
	}

	@bind
	marketChange(data: {index: number; value: string;}): void {
		const marketID = Number.parseInt(data.value);
		if (isNumber(marketID)) {
			this.props.setProjectCreateState({marketID});
		}
	}

	marketCity(): string {
		const market = this.market();
		return market ? market.city : '';
	}

	marketSelectOptions(): Array<ComboBoxOption> {
		const {markets, marketID} = this.props;
		return markets.map(obj => ({
			selected: obj.id === marketID,
			text: obj.city,
			value: obj.id,
		}));
	}

	@bind
	mediaQueryEvent(event: MediaQueryListEvent): void {
		if (event.target === this.mqlTopLevel) {
			this.setState({mqlTopLevelTriggered: event.matches});
		} else if (event.target === this.mqlSecondLevel) {
			this.setState({mqlSecondLevelTriggered: event.matches});
		}
	}

	@bind
	notesChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
		this.props.setProjectCreateState({notes: event.target.value});
	}

	@bind
	occupancyChange(data: {index: number; value: string;}): void {
		const value = data.value.trim();
		const occupancyOptionId = (value.length > 0) ? Number.parseInt(value) : null;
		if (isNumber(occupancyOptionId) || (occupancyOptionId === null)) {
			this.props.setProjectCreateState({occupancyOptionId});
		}
	}

	occupancySelectOptions(): Array<ComboBoxOption> {
		const {occupancyOptions, occupancyOptionId} = this.props;
		return occupancyOptions.map(opt => ({
			selected: opt.id === occupancyOptionId,
			text: opt.text,
			value: opt.id,
		}));
	}

	prepareNewProject(): INewProjectData {
		const {
			accessNotes,
			accessOptionId,
			buggyCatalogItemAddOnIDs,
			buggyCatalogItemChoiceIDs,
			buggyCatalogItemIDs,
			clientDueDate,
			clientDueTime,
			clientUserID,
			description,
			locationName,
			marketID,
			notes,
			occupancyOptionId,
			size,
			street,
		} = this.props;
		const {
			selectedSchedulerAnyTime,
			selectedSchedulerDate,
			selectedSchedulerTime,
		} = this.state;
		const selectedTime = (!selectedSchedulerTime && selectedSchedulerAnyTime) ?
			undefined :
			selectedSchedulerTime;
		return {
			accessNotes,
			accessOptionId,
			addons: buggyCatalogItemAddOnIDs,
			anytime: selectedSchedulerAnyTime,
			choices: buggyCatalogItemChoiceIDs,
			clientDueDate: clientDueDate.trim().length > 0 ? clientDueDate : null,
			clientDueTime: clientDueTime.trim().length > 0 ? clientDueTime : null,
			clientUser: clientUserID,
			date: selectedSchedulerDate || undefined,
			description,
			items: buggyCatalogItemIDs,
			locationName,
			market: marketID,
			notes,
			occupancyOptionId,
			size: isNumber(size) ? Math.max(0, size) : null,
			street,
			time: selectedTime || undefined,
		};
	}

	@bind
	async primaryButtonClick(): Promise<void> {
		const {
			accessOptionId,
			accessOptions,
			buggyCatalogItemAddOnIDs,
			buggyCatalogItemIDs,
			clientUserID,
			durationData,
			isProducer,
			marketID,
			markets,
			marketWeatherData,
			occupancyOptionId,
			occupancyOptions,
			size,
			street,
			summaryData,
			topLevelCatalogItems,
		} = this.props;
		const {view, selectedSchedulerDate, selectedSchedulerTime, selectedSchedulerAnyTime} = this.state;
		const msgs: string[] = [];
		if ((buggyCatalogItemIDs.length < 1) && (topLevelCatalogItems.length > 0)) {
			msgs.push('- At least 1 service');
		}
		switch (view) {
			case View.Begin:
				if (isProducer && (clientUserID === null)) {
					msgs.push('- Client');
				}
				if (street.trim().length < 1) {
					msgs.push('- Street address');
				}
				if (!isNumber(marketID) && (markets.length > 0)) {
					msgs.push('- City');
				}
				if (!isNumber(size) && this.checkedCatalogItemsHaveSize()) {
					msgs.push('- Estimated Size (slide to select)');
				}
				if (!isNumber(accessOptionId) && (accessOptions.length > 0)) {
					msgs.push('- Property access');
				}
				if (!isNumber(occupancyOptionId) && (occupancyOptions.length > 0)) {
					msgs.push('- Occupancy');
				}
				if (msgs.length > 0) {
					break;
				}
				this.setState({busy: true});
				if (isNumber(marketID)) {
					await marketWeatherData({fromDate: 'tomorrow', dateCount: DEFAULT_WX_DATE_COUNT, marketID: marketID});
				}
				await durationData({
					clientUserID: isNumber(clientUserID) ? clientUserID : undefined,
					itemAddOnIDs: buggyCatalogItemAddOnIDs,
					itemIDs: buggyCatalogItemIDs,
					size: isNumber(size) ? size : undefined,
				});
				this.setState({busy: false});
				this.setView(View.Schedule);
				return;
			case View.Schedule:
				if (msgs.length > 0) {
					break;
				}
				if (!(selectedSchedulerDate && (selectedSchedulerTime || selectedSchedulerAnyTime))) {
					// Schedule view, no date/time slot picked yet, primary button
					// will be disabled. Nothing to do.
					break;
				}
				const selectedTime = (!selectedSchedulerTime && selectedSchedulerAnyTime) ? '00:00:00' : selectedSchedulerTime;
				await summaryData({
					clientUserID: isNumber(clientUserID) ? clientUserID : undefined,
					dateTime: `${selectedSchedulerDate}T${selectedTime}`,
					itemAddOnIDs: buggyCatalogItemAddOnIDs,
					itemIDs: buggyCatalogItemIDs,
					size: isNumber(size) ? size : undefined,
				});
				this.setView(View.Final);
				break;
			case View.Final:
				if (msgs.length > 0) {
					break;
				}
				await this.create();
				break;
		}
		if (msgs.length > 0) {
			this.renderDialog('Something\'s missing...', ['Please provide the following:', ...msgs]);
		}
	}

	primaryButtonDisabled(): boolean {
		const {busy, selectedSchedulerDate, selectedSchedulerTime, selectedSchedulerAnyTime, view} = this.state;
		if (busy) {
			return true;
		}
		if (view === View.Begin) {
			return false;
		}
		return !(selectedSchedulerDate && (selectedSchedulerTime || selectedSchedulerAnyTime));
	}

	primaryButtonText(): string {
		const {view} = this.state;
		if (view === View.Final) {
			return 'SUBMIT';
		}
		return 'NEXT';
	}

	producerActiveTermsConditionsList(): Promise<ITermsConditions[]> {
		return svc.group.producer.termsConditions.active();
	}

	async producerActiveTermsConditionsWithoutAgreementList(): Promise<ITermsConditions[]> {
		const accountAgreements = await this.accountTermsConditionsAgreementList();
		const producerActive = await this.producerActiveTermsConditionsList();
		return termsConditionsWithoutAgreement(producerActive, accountAgreements);
	}

	render(): React.ReactNode {
		const {
			accessNotes,
			buggyCatalogItemAddOnIDs,
			buggyCatalogItemChoiceIDs,
			buggyCatalogItemIDs,
			catalogItemChildren,
			catalogItemChoices,
			clientUsers,
			description,
			locationName,
			notes,
			size,
			street,
			summary,
			topLevelCatalogItems,
		} = this.props;
		const {
			currentSchedulerDate,
			dialogButtons,
			dialogInfoText,
			dialogIsOpen,
			dialogText,
			mqlSecondLevelTriggered,
			mqlTopLevelTriggered,
			selectedSchedulerAnyTime,
			view,
		} = this.state;
		const btnDisabled = this.primaryButtonDisabled();
		const btnIcon = 'chevron_right';
		const btnText = this.primaryButtonText();
		const showClientSelect = (clientUsers.length > 0);
		const showSecondaryButton = window.isProducer && window.isAdmin;
		const checkedItemsWithChoices = this.checkedWithChoices();
		const haveChoices = checkedItemsWithChoices.length > 0;
		let viewComponent: React.ReactNode = null;
		const maxSize = this.catalogItemsMaxSize();
		switch (view) {
			case View.Begin:
				viewComponent = (
					<React.Fragment>
						<TextField
							className="width--100-percent margin-bottom--24"
							label="Project name (optional)"
							onChange={this.descriptionChangeEvent}
							outlined={true}
							value={description}/>
						{showClientSelect ?
							<Section title="STEP 0" subTitle="Select Client">
								<SubSection>
									<ComboBox
										fullWidth={true}
										labelText="Client"
										onChange={this.clientUserChange}
										options={this.clientSelectOptions()}
										required={true}
										typeAhead={true}/>
								</SubSection>
							</Section> :
							null}
						<Section title="STEP 1" subTitle="Location">
							<SubSection>
								<StreetInput
									onChange={this.streetChange}
									value={street}/>
							</SubSection>
							<SubSection>
								<div className="display--flex flex-direction--row align-items--center">
									<label className="color--grayish margin-right--8" style={{minWidth: '48px'}}>
										City
									</label>
									<ComboBox
										fullWidth={true}
										noLabel={true}
										onChange={this.marketChange}
										options={this.marketSelectOptions()}
										required={true}/>
								</div>
							</SubSection>
							<SubSection>
								<LocationName
									onChange={this.locationNameChange}
									value={locationName}/>
							</SubSection>
						</Section>
						<Section title="STEP 2" subTitle="Select Services">
							<SubSection>
								<div className={cssClassName('mdc-list', {'mdc-list--two-line': mqlTopLevelTriggered})}>
									{topLevelCatalogItems.map(item => {
										const checked = buggyCatalogItemIDs.indexOf(item.id) >= 0;
										const addOns = checked ? this.catalogItemAddOns(item.id) : [];
										const hasSize = checked && this.catalogItemHasSize(item.id);
										const atSize = (hasSize && isNumber(size)) ? size : undefined;
										const hasSizeComp = this.catalogItemHasSizeComponent(item.id);
										return (
											<React.Fragment key={item.id}>
												<CatalogListItem
													atSize={atSize}
													childItems={this.itemChildren(item.id)}
													checked={checked}
													item={item}
													onChange={this.catalogItemChange}
													twoLine={mqlTopLevelTriggered}
												/>
												{
													(addOns.length > 0) ?
														<li className="padding-top--16 padding-bottom--16 padding-left--24">
															<ul className={cssClassName('mdc-list', {'mdc-list--two-line': mqlSecondLevelTriggered})}>
																{
																	addOns.map(addOn =>
																		<CatalogListItem
																			checked={buggyCatalogItemAddOnIDs.indexOf(addOn.id) >= 0}
																			item={addOn}
																			key={addOn.id}
																			onChange={(id, checked) => this.catalogItemAddOnChange(item.id, id, checked)}
																			twoLine={mqlSecondLevelTriggered}
																		/>)
																}
															</ul>
														</li> :
														null
												}
												{
													hasSizeComp ?
														<div className="property-size-range-container">
															<div className="display--flex flex-direction--row justify-content--space-between">
																<div className="property-size-range-extent-label">0 ft<sup>2</sup></div>
																<Slider
																	className="property-size-range"
																	discrete={true}
																	max={maxSize}
																	onInput={this.sizeChange}
																	step={50}
																	value={size || 0}
																/>
																<div className="property-size-range-extent-label">{numberFormat(maxSize)} ft<sup>2</sup></div>
															</div>
															<p className="color--grayish text-align--center">Estimated Size (slide to select)</p>
														</div> :
														null
												}
											</React.Fragment>
										);
									})}
								</div>
							</SubSection>
							{
								haveChoices ?
									<React.Fragment>
										<SubSectionDivider/>
										{
											this.checkedWithChoices().map(item =>
												<Choices
													choices={catalogItemChoices(item.id)}
													checkedChoiceIDs={buggyCatalogItemChoiceIDs}
													item={item}
													key={item.id}
													onChange={this.checkedCatalogItemChoiceChange}
												/>)
										}
									</React.Fragment> :
									null
							}
						</Section>
						<Section title="STEP 3" subTitle="Access & Occupancy">
							<SubSection>
								<ComboBox
									fullWidth={true}
									labelText="How should we access the property?"
									onChange={this.accessOptionChange}
									options={this.accessSelectOptions()}
									required={true}/>
								<TextField
									className="width--100-percent margin-top--16"
									noLabel={true}
									id="accessNotesID"
									name="accessNotes"
									onChange={this.accessNotesChange}
									placeholder="Access notes and info"
									rows={3}
									textArea={true}
									value={accessNotes}/>
							</SubSection>
							<SubSectionDivider/>
							<SubSection>
								<ComboBox
									fullWidth={true}
									labelText="Is the property occupied?"
									onChange={this.occupancyChange}
									options={this.occupancySelectOptions()}
									required={true}/>
							</SubSection>
							<SubSection>
								<PrimaryButtonBox>
									{showSecondaryButton ?
										<SecondaryButton onClick={this.secondaryButtonClicked}>Submit</SecondaryButton> :
										null}
									<PrimaryButton
										disabled={btnDisabled}
										onClick={this.primaryButtonClick}
										icon={btnIcon}
										text={btnText}/>
								</PrimaryButtonBox>
							</SubSection>
						</Section>
					</React.Fragment>
				);
				break;
			case View.Schedule:
				viewComponent = (
					<Section onNavBack={this.backButtonClick} title={this.scheduleViewHeaderPrimaryText()} subTitle={this.scheduleViewHeaderSecondaryText()}>
						<SubSection>
							<Scheduler
								currentDate={currentSchedulerDate}
								currentDateAvailability={this.dateAvailability}
								dateIsAvailable={this.dateIsAvailable}
								onAnyTimeChange={this.schedulerAnyTimeChanged}
								onDateChange={this.schedulerDateChanged}
								onTimeChange={this.schedulerTimeChanged}
								onTimeSelectRequest={this.timeSelectRequest}
								selectedEvent={this.schedulerSelectedEvent()}
								timeSegmentStep={TIME_SEGMENT_STEP_MINUTES}
								timeRangeForWeekday={this.timeRangeForWeekday}
								weather={this.weather()}/>
						</SubSection>
						{this.showPrimaryButtonScheduleView() ?
							<SubSection>
								<PrimaryButtonBox>
									{showSecondaryButton ?
										<SecondaryButton onClick={this.secondaryButtonClicked}>Submit</SecondaryButton> :
										null}
									<PrimaryButton
										disabled={btnDisabled}
										onClick={this.primaryButtonClick}
										icon={btnIcon}
										text={btnText}/>
								</PrimaryButtonBox>
							</SubSection> :
							null}
					</Section>
				);
				break;
			case View.Final:
				viewComponent = (
					<Section onNavBack={this.backButtonClick} title="FINAL STEP" subTitle="Review & Submit">
						{summary ?
							<React.Fragment>
								<SubSection>
									<Summary
										anyTime={selectedSchedulerAnyTime}
										city={this.marketCity()}
										street={street}
										summaryData={summary}/>
								</SubSection>
								<SubSection>
									<Statement itemChildren={catalogItemChildren} summary={summary}/>
								</SubSection>
								<SubSection>
									<Subheading>
										Special Instructions & Notes
									</Subheading>
								</SubSection>
								<SubSection>
									<textarea
										className="width--100-percent"
										id="notes_id"
										name="notes"
										onChange={this.notesChange}
										placeholder="i.e. Key is hidden under front step"
										rows={5}
										value={notes}/>
								</SubSection>
								<SubSection>
									<Subheading align={'start'}>
										When is the media needed by? (optional)
									</Subheading>
								</SubSection>
								<SubSection>
									<div className={'display--inline-flex flex-direction--column'}>
										<label htmlFor="id_pb-project-create-client-due-date">date</label>
										<input
											type="date"
											id={'id_pb-project-create-client-due-date'}
											name={'client_due_date'}
											onChange={this.clientDueDateChange}
										/>
									</div>
									<div className={'display--inline-flex flex-direction--column'}>
										<label htmlFor="id_pb-project-create-client-due-time">time</label>
										<input
											type="time"
											id={'id_pb-project-create-client-due-time'}
											name={'client_due_time'}
											onChange={this.clientDueTimeChange}
										/>
									</div>
								</SubSection>
								<SubSection>
									<PrimaryButtonBox>
										<PrimaryButton
											disabled={btnDisabled}
											onClick={this.primaryButtonClick}
											icon={btnIcon}
											text={btnText}/>
									</PrimaryButtonBox>
								</SubSection>
							</React.Fragment> :
							null}
					</Section>
				);
				break;
		}
		return (
			<React.Fragment>
				{viewComponent}
				<Modal
					buttons={dialogButtons}
					informativeText={dialogInfoText}
					isOpen={dialogIsOpen}
					onResult={this.dialogResult}
					text={dialogText}/>
			</React.Fragment>
		);
	}

	renderDialog(heading: string, infoText?: string | string[], buttons?: StandardButton, callBack?: (result: StandardButton) => any): void {
		const {dialogResultCallback} = this.state;
		const s = {
			dialogButtons: buttons,
			dialogInfoText: infoText,
			dialogIsOpen: true,
			dialogResultCallback: dialogResultCallback,
			dialogText: heading,
		};
		if (callBack) {
			s.dialogResultCallback = callBack;
		}
		this.setState(s);
	}

	@bind
	schedulerAnyTimeChanged(isChecked: boolean): void {
		this.setState({
			selectedSchedulerAnyTime: isChecked,
			selectedSchedulerDate: isChecked ? this.state.currentSchedulerDate : this.state.selectedSchedulerDate,
			selectedSchedulerTime: isChecked ? '' : this.state.selectedSchedulerTime,
		});
	}

	@bind
	schedulerDateChanged(dateIsoFormat: string | null): void {
		let currentSchedulerDate = '';
		let selectedSchedulerDate = this.state.selectedSchedulerDate;
		let selectedSchedulerTime = this.state.selectedSchedulerTime;
		if (dateIsoFormat) {
			this.fetchDateAvailability(dateIsoFormat);
			if (this.dateIsAvailable(dateIsoFormat)) {
				currentSchedulerDate = dateIsoFormat;
			}
		} else {
			selectedSchedulerDate = '';
			selectedSchedulerTime = '';
			this.clearDateAvailability(this.state.currentSchedulerDate);
		}
		this.setState({
			currentSchedulerDate,
			selectedSchedulerDate,
			selectedSchedulerTime,
		});
	}

	schedulerSelectedEvent(): ISchedulerSelectedBlock | null {
		const {duration} = this.props;
		const {
			selectedSchedulerAnyTime,
			selectedSchedulerDate,
			selectedSchedulerTime,
		} = this.state;
		if (selectedSchedulerDate && (selectedSchedulerTime || selectedSchedulerAnyTime)) {
			const d = isoDateToIDate(selectedSchedulerDate);
			const t = selectedSchedulerTime ? isoTimeToITime(selectedSchedulerTime) : time.fromisoformat('00:00:00').toITime();
			return {start: {date: d, time: t}, duration, anyTime: selectedSchedulerAnyTime};
		}
		return null;
	}

	@bind
	schedulerTimeChanged(timeIsoFormat: string | null): void {
		let selectedSchedulerDate = this.state.selectedSchedulerDate;
		let selectedSchedulerTime: string = '';
		if (timeIsoFormat) {
			const {currentSchedulerDate} = this.state;
			if (currentSchedulerDate) {
				selectedSchedulerDate = currentSchedulerDate;
				selectedSchedulerTime = timeIsoFormat;
			}
		}
		this.setState({
			selectedSchedulerAnyTime: false,
			selectedSchedulerDate,
			selectedSchedulerTime,
		});
	}

	scheduleViewHeaderPrimaryText(): string {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			return 'STEP 5';
		}
		return 'STEP 4';
	}

	scheduleViewHeaderSecondaryText(): string {
		const {currentSchedulerDate} = this.state;
		if (currentSchedulerDate) {
			return 'Choose a Time';
		}
		return 'Pick a Date';
	}

	@bind
	secondaryButtonClicked(): void {
		if (window.isProducer && window.isAdmin) {
			this.create();
		}
	}

	selectCatalogItemDefaultChoices(itemID: number): void {
		if (this.catalogItemEverInteracted(itemID)) {
			return;
		}
		const {addItemChoiceToBuggy} = this.props;
		const defaultChoices = this.catalogItemDefaultSelectedChoices(itemID);
		defaultChoices.forEach(choice => addItemChoiceToBuggy(itemID, choice.id, true));
	}

	selectedStartDate(): IDate | null {
		if (this.state.selectedSchedulerDate) {
			return isoDateToIDate(this.state.selectedSchedulerDate);
		}
		return null;
	}

	selectedStartTime(): ITime | null {
		if (this.state.selectedSchedulerTime) {
			return isoTimeToITime(this.state.selectedSchedulerTime);
		}
		return null;
	}

	setDateTime(dt: IDateTime & {anyTime?: boolean;} | null): void {
		if (dt) {
			const selectedSchedulerAnyTime = (dt.anyTime === undefined) ? this.state.selectedSchedulerAnyTime : dt.anyTime;
			this.setState({
				selectedSchedulerAnyTime,
				selectedSchedulerDate: dt.date.isoformat,
				selectedSchedulerTime: dt.time.isoformat,
			});
		} else {
			this.setState({
				selectedSchedulerAnyTime: false,
				selectedSchedulerDate: '',
				selectedSchedulerTime: '',
			});
		}
	}

	setView(value: View): void {
		this.setState({view: value});
	}

	showPrimaryButtonScheduleView(): boolean {
		const evt = this.schedulerSelectedEvent();
		return Boolean(evt);
	}

	@bind
	sizeChange(size: number | null): void {
		this.props.setProjectCreateState({size});
	}

	@bind
	streetChange(street: string): void {
		this.props.setProjectCreateState({street});
	}

	@bind
	timeRangeForWeekday(weekday: number): ITimeRange | null {
		const {weekdayTimeRangeMap} = this.state;
		return weekdayTimeRangeMap ? weekdayTimeRangeMap[weekday] || null : null;
	}

	@bind
	timeSelectRequest(dt: IDateTime): boolean {
		const dt1 = {...dt};
		const minute = (dt.time.minute > 0) ? 0 : 30;
		const dt2 = {date: dt.date, time: makeTime(dt.time.hour, minute, dt.time.second)};
		if (this.dateTimeIsOK(dt1)) {
			if (this.dateTimeIsOK(dt2)) {
				return true;
			}
			this.setDateTime({...dt1, anyTime: false});
		} else if (this.dateTimeIsOK(dt2)) {
			this.setDateTime({...dt2, anyTime: false});
		}
		return false;
	}

	timeWithinBounds(targetStart: ITime, targetEnd: ITime, range: ITimeRange): boolean {
		const tgtStart = targetStart.hour * 60 + targetStart.minute;
		const tgtEnd = targetEnd.hour * 60 + targetEnd.minute;
		const earliest = range.start;
		const latest = range.stop;
		const early = earliest.hour * 60 + earliest.minute;
		const late = latest.hour * 60 + latest.minute;
		return (tgtStart >= early) && (tgtEnd <= late);
	}

	@bind
	async tncDialogCallback(result: StandardButton): Promise<void> {
		const {activatedTermsConditionsID} = this.state;
		if (isNumber(activatedTermsConditionsID)) {
			let cb: (() => void) | undefined = undefined;
			if (result === StandardButton.Accept) {
				const termsConditions = await svc.group.producer.termsConditions.get(activatedTermsConditionsID);
				await svc.account.termsConditionsAgreement.agreeTo(termsConditions);
				cb = this.create;
			}
			this.setState({activatedTermsConditionsID: null}, cb);
		} else {
			console.log('ProjectCreate::tncDialogCallback activatedTermsConditionsID is null.');
		}
	}

	validateNewProject(data: INewProjectData): boolean {
		const {isProducer, markets} = this.props;
		const isProducerAdmin = isProducer && window.isAdmin;
		const msgs: Array<string> = [];
		if (isProducer && !isNumber(data.clientUser)) {
			msgs.push('Please select a client');
		}
		if (!isNumber(data.market) && (markets.length > 0)) {
			msgs.push('Please select a city');
		}
		if (!isProducerAdmin) {
			if (!(data.date && (data.time || data.anytime))) {
				msgs.push('Please select a date and time (or "anytime")');
			}
			if (data.street.trim().length < 1) {
				msgs.push('Please enter a street address');
			}
		}
		if (msgs.length > 0) {
			this.renderDialog('Something\'s missing...', msgs);
			return false;
		}
		return true;
	}

	weather(): IDateWeather[] {
		const {marketID, marketWeather} = this.props;
		return isNumber(marketID) ? marketWeather[marketID] || [] : [];
	}
}

function Section({children, onNavBack, subTitle, title}: React.PropsWithChildren<{onNavBack?: () => any; title?: string; subTitle?: string;}>) {
	return (
		<Card>
			{title ?
				<Header onBackClick={onNavBack} primaryText={title} secondaryText={subTitle}/> :
				null}
			<Grid>
				{children}
			</Grid>
		</Card>
	);
}

function SubSection({children}: React.PropsWithChildren<{}>) {
	return (
		<GridCell>
			{children}
		</GridCell>
	);
}

function SubSectionDivider() {
	return <GridDivider/>;
}

function SecondaryButton({children, onClick}: React.PropsWithChildren<{onClick?: () => any}>) {
	return (
		<button className="mdc-button pb-project-create-axn-btn" onClick={onClick} type="button">
			<span className="mdc-button__ripple"/>
			<span className="mdc-button__label">
				{children}
			</span>
		</button>
	);
}

function PrimaryButtonBox({children}: React.PropsWithChildren<{}>) {
	const clsName = 'display--flex flex-direction--row justify-content--space-between';
	return (
		<div className={clsName}>
			{children}
		</div>
	);
}

function mapStateToProps(state: IState): IProps {
	return {
		...state.projectCreate,
		markets: state.producer.markets,
		isProducer: state.user.isProducer,
		businessHours: state.producer.hours,
		clientUsers: state.producer.clientUsers,
		buggyCatalogItemIDs: state.buggy.itemIDs,
		marketWeather: state.global.marketWeather,
		accessOptions: state.producer.accessOptions,
		buggyCatalogItemAddOnIDs: state.buggy.addonIDs,
		buggyCatalogItemChoiceIDs: state.buggy.choiceIDs,
		occupancyOptions: state.producer.occupancyOptions,
		catalogItemsInBuggy: getCatalogItemsInBuggy(state),
		catalogItemsMaxSize: getCatalogItemsMaxSize(state),
		topLevelCatalogItems: getTopLevelCatalogItems(state),
		buggyInteractedCatalogItemIDs: state.buggy.interactedItemIDs,
		catalogItemByID: (itemID: number) => getCatalogItemByID(state, itemID),
		catalogItemAddOns: (itemID: number) => getCatalogItemAddOns(state, itemID),
		catalogItemChoices: (itemID: number) => getCatalogItemChoices(state, itemID),
		catalogItemChildren: (itemID: number) => getCatalogItemChildren(state, itemID),
		catalogItemChildForSize: (itemID: number, size: number) => getCatalogItemChildForSize(state, itemID, size),
	};
}

const dispatchProps: IDispatchProps = {
	userData: userAxns.userData,
	emptyBuggy: buggyAxns.emptyBuggy,
	summaryData: projAxns.summaryData,
	durationData: projAxns.durationData,
	createProject: projAxns.createProject,
	resetCatalogData: catAxns.resetCatalog,
	accessOptionList: prodAxns.accessOptions,
	addItemToBuggy: buggyAxns.addItemToBuggy,
	catalogData: catAxns.groupProducerCatalog,
	clientUsersData: prodAxns.clientUsersData,
	marketWeatherData: wxAxns.marketWeatherData,
	marketsData: prodAxns.groupProducerMarketList,
	occupancyOptionList: prodAxns.occupancyOptions,
	addItemAddOnToBuggy: buggyAxns.addItemAddOnToBuggy,
	removeItemFromBuggy: buggyAxns.removeItemFromBuggy,
	addItemChoiceToBuggy: buggyAxns.addItemChoiceToBuggy,
	setProjectCreateState: projAxns.setProjectCreateState,
	businessHoursData: prodAxns.groupProducerBusinessHoursList,
	removeItemAddOnFromBuggy: buggyAxns.removeItemAddOnFromBuggy,
	removeItemChoiceFromBuggy: buggyAxns.removeItemChoiceFromBuggy,
};

export default connect(mapStateToProps, dispatchProps)(ProjectCreate);

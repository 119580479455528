import React, {PropsWithChildren} from 'react';

import {Icon} from '../../../components';

interface IProps {
	disabled?: boolean;
	icon?: string;
	onClick?: () => any;
	text: string;
	title?: string;
}

export default function PrimaryButton(props: PropsWithChildren<IProps>) {
	const {disabled, icon, onClick, text, title} = props;
	return (
		<button
			onClick={onClick}
			id="create-form-button"
			type="button"
			form="create-form"
			className="mdc-button mdc-button--raised pb-project-create-axn-btn"
			style={{height: '37px'}}
			title={title}
			disabled={disabled}>
			<span className="mdc-button__ripple"/>
			<span className="mdc-button__label create-form-button__label">{text}</span>
			{icon ? <Icon className="mdc-button__icon" name={icon}/> : null}
		</button>
	);
}

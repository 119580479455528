import {Variant} from '../../variant';
import {divmod, isNumber, lowerBound, padEnd, padStart, repeat, stringCmp} from '../../util';
import {CaseSensitivity, CheckState, ItemDataRole, ItemFlag, MetaType, Orientation, SortOrder} from '../../constants';
import {list} from '../../tools';
import {AbstractTableModel, ModelIndex, ModelIndexList} from '../../itemmodel';
import {DataTable} from './datatable';

const ItemIsHeaderItem = 128;
const InvalidTableItemId = -99;

class ItemData {
	role: number;
	value: Variant;

	constructor(role: number, value: Variant);
	constructor();
	constructor(role?: number, value?: Variant) {
		this.role = isNumber(role) ? role : -1;
		this.value = (value === undefined) ? new Variant() : value;
	}

	eq(other: ItemData): boolean {
		return (this.role === other.role) && this.value.eq(other.value);
	}
}

export class TableItem {
	id: number;
	itemFlags: ItemFlag;
	values: list<ItemData>;
	view: DataTable | null;

	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(a?: TableItem | string) {
		let checkState: CheckState | undefined = undefined;
		let itemFlags: ItemFlag =
			ItemFlag.ItemIsEnabled
			| ItemFlag.ItemIsDragEnabled
			| ItemFlag.ItemIsDropEnabled;
		let text: string | undefined = undefined;
		let textAlignment: number | undefined = undefined;
		let values: list<ItemData> = new list<ItemData>();
		let view: DataTable | null = null;
		if (a) {
			if (typeof a === 'string') {
				text = a;
			} else {
				itemFlags = a.itemFlags;
				values = a.values;
			}
		}
		this.id = -1;
		this.itemFlags = itemFlags;
		this.values = values;
		this.view = view;
		if (checkState !== undefined) {
			this.setCheckState(checkState);
		}
		if (text) {
			this.setText(text);
		}
		if (textAlignment !== undefined) {
			this.setTextAlignment(textAlignment);
		}
	}

	checkState(): CheckState {
		return this.data(ItemDataRole.CheckStateRole).toNumber();
	}

	clone(): TableItem {
		return new TableItem(this);
	}

	cmp(other: TableItem): number {
		const v1 = this.data(ItemDataRole.DisplayRole);
		const v2 = other.data(ItemDataRole.DisplayRole);
		return variantCmp(v1, v2);
	}

	column(): number {
		return this.view ? this.view.column(this) : -1;
	}

	data(role: ItemDataRole): Variant {
		for (const data of this.values) {
			if (data.role === role) {
				return data.value;
			}
		}
		return new Variant();
	}

	dataTable(): DataTable | null {
		return this.view;
	}

	debugData(): string {
		return padStart(this.id, 4, '0');
	}

	destroy(): void {
		const model = this.tableModel();
		if (model) {
			model.removeItem(this);
		}
		this.id = InvalidTableItemId;
		this.itemFlags = 0;
		this.values.clear();
		this.view = null;
	}

	flags(): ItemFlag {
		return this.itemFlags;
	}

	index(): ModelIndex {
		const model = this.tableModel();
		return model ? model.index(this) : new ModelIndex();
	}

	lt(other: TableItem): boolean {
		const v1 = this.data(ItemDataRole.DisplayRole);
		const v2 = other.data(ItemDataRole.DisplayRole);
		return TableModel.isVariantLessThan(v1, v2);
	}

	row(): number {
		return this.view ? this.view.row(this) : -1;
	}

	setCheckState(checkState: CheckState): void {
		this.setData(ItemDataRole.CheckStateRole, new Variant(checkState));
	}

	setData(role: ItemDataRole, value: Variant): void {
		let found: boolean = false;
		for (let i = 0; i < this.values.size(); ++i) {
			const data = this.values.at(i);
			if (data.role === role) {
				if (data.value.eq(value)) {
					return;
				}
				data.value = value;
				found = true;
				break;
			}
		}
		if (!found) {
			this.values.append(new ItemData(role, value));
		}
		const model = this.tableModel();
		if (model) {
			model.itemChanged(this);
		}
	}

	setFlags(flags: ItemFlag): void {
		this.itemFlags = flags;
		const model = this.tableModel();
		if (model) {
			model.itemChanged(this);
		}
	}

	setText(text: string | null): void {
		this.setData(ItemDataRole.DisplayRole, new Variant(text || ''));
	}

	setTextAlignment(alignment: number): void {
		this.setData(ItemDataRole.TextAlignmentRole, new Variant(alignment));
	}

	tableModel(): TableModel | null {
		if (this.view && (this.view.model instanceof TableModel)) {
			return this.view.model;
		}
		return null;
	}

	text(): string {
		return this.data(ItemDataRole.DisplayRole).toString();
	}

	textAlignment(): number {
		return this.data(ItemDataRole.TextAlignmentRole).toNumber();
	}
}

function tableItemCmpAsc(a: [TableItem, number], b: [TableItem, number]): number {
	return a[0].cmp(b[0]);
}

function tableItemCmpDesc(a: [TableItem, number], b: [TableItem, number]): number {
	return a[0].cmp(b[0]) * -1;
}

function tableItemGreaterThan(a: TableItem, b: TableItem): boolean {
	return b.lt(a);
}

function tableItemLessThan(a: TableItem, b: TableItem): boolean {
	return a.lt(b);
}

export class TableModel extends AbstractTableModel {
	static isVariantLessThan(left: Variant, right: Variant, cs: CaseSensitivity = CaseSensitivity.CaseSensitive, isLocaleAware: boolean = false): boolean {
		if (left.type() === MetaType.Invalid) {
			return false;
		}
		if (right.type() === MetaType.Invalid) {
			return true;
		}
		switch (left.type()) {
			case MetaType.Number:
				return left.toNumber() < right.toNumber();
			case MetaType.Date:
				return left.toDate().lt(right.toDate());
			case MetaType.Time:
				return left.toTime().lt(right.toTime());
			case MetaType.DateTime:
				return left.toDateTime().lt(right.toDateTime());
			default:
				return stringCmp(left.toString(), right.toString(), cs, isLocaleAware) < 0;
		}
	}

	static sortedInsertionIndex(from: number, to: number, collection: Array<TableItem> | list<TableItem>, sortOrder: SortOrder, item: TableItem): number {
		if (sortOrder === SortOrder.AscendingOrder) {
			return lowerBound(Array.from(collection).slice(from, to), item, tableItemLessThan);
		}
		return lowerBound(Array.from(collection).slice(from, to), item, tableItemGreaterThan);
	}

	private horizontalHeaderItems: list<TableItem | null>;
	private prototype: TableItem | null;
	private tableItems: list<TableItem | null>;
	private verticalHeaderItems: list<TableItem | null>;
	view: DataTable | null;

	constructor(rows: number, columns: number, view: DataTable | null = null) {
		super();
		this.horizontalHeaderItems = new list<TableItem | null>(columns, null);
		this.prototype = null;
		this.tableItems = new list<TableItem | null>(rows * columns, null);
		this.verticalHeaderItems = new list<TableItem | null>(rows, null);
		this.view = view;
	}

	clear(): void {
		for (let i = 0; i < this.verticalHeaderItems.size(); ++i) {
			const item = this.verticalHeaderItems.at(i);
			if (item) {
				item.view = null;
				this.verticalHeaderItems.replace(i, null);
				item.destroy();
			}
		}
		for (let i = 0; i < this.horizontalHeaderItems.size(); ++i) {
			const item = this.horizontalHeaderItems.at(i);
			if (item) {
				item.view = null;
				this.horizontalHeaderItems.replace(i, null);
				item.destroy();
			}
		}
		this.clearContents();
	}

	clearContents(): void {
		this.beginResetModel();
		for (let i = 0; i < this.tableItems.size(); ++i) {
			const item = this.tableItems.at(i);
			if (item) {
				item.view = null;
				this.tableItems.replace(i, null);
				item.destroy();
			}
		}
		this.endResetModel();
	}

	columnCount(parent: ModelIndex = new ModelIndex()): number {
		return parent.isValid() ? 0 : this.horizontalHeaderItems.size();
	}

	columnItems(column: number): list<TableItem> {
		// Returns the non-null items in column.
		const items = new list<TableItem>();
		const rowCount = this.rowCount();
		for (let row = 0; row < rowCount; ++row) {
			const item = this.item(row, column);
			if (!item) {
				// No more sortable items (all null items are at the end of
				// the table when it is sorted).
				break;
			}
			items.append(item);
		}
		return items;
	}

	createItem(): TableItem {
		return this.prototype ? this.prototype.clone() : new TableItem();
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		const item = this.item(index);
		if (item) {
			return item.data(role);
		}
		return new Variant();
	}

	destroy(): void {
		this.verticalHeaderItems.clear();
		this.horizontalHeaderItems.clear();
		this.tableItems.clear();
		if (this.prototype) {
			this.prototype.destroy();
			this.prototype = null;
		}
		super.destroy();
	}

	dumpData(opts?: Partial<{columnPadding: number; maxDataWidth: number; minColumnWidth: number;}>): void {
		opts = opts || {};
		opts.columnPadding = (opts.columnPadding === undefined) ? 3 : Math.max(0, opts.columnPadding);
		opts.maxDataWidth = (opts.maxDataWidth === undefined) ? -1 : Math.max(0, opts.maxDataWidth);
		opts.minColumnWidth = (opts.minColumnWidth === undefined) ? 0 : Math.max(0, opts.minColumnWidth);
		const rowCount = this.rowCount();
		const columnCount = this.columnCount();
		const columnMaxWidths: Array<number> = [0];
		for (let column = 0; column < columnCount; ++column) {
			columnMaxWidths.push(0);
		}
		const data: Array<Array<string>> = [];
		for (let row = 0; row < rowCount; ++row) {
			const r: Array<string> = [];
			for (let column = 0; column < columnCount; ++column) {
				const item = this.item(row, column);
				let text: string;
				if (item) {
					text = `<${item.debugData()}> ${item.text()}`;
				} else {
					text = `<NULL> NULL`;
				}
				if ((opts.maxDataWidth >= 0) && (text.length > opts.maxDataWidth)) {
					text = text.slice(0, opts.maxDataWidth);
				}
				columnMaxWidths[column] = Math.max(columnMaxWidths[column], text.length);
				r.push(text);
			}
			data.push(r);
		}
		for (let row = 0; row < data.length; ++row) {
			const r = data[row];
			for (let column = 0; column < r.length; ++column) {
				if (column < (r.length - 1)) {
					data[row][column] = padEnd(data[row][column], Math.max(columnMaxWidths[column], opts.minColumnWidth) + opts.columnPadding, ' ');
				}
			}
		}
		const rowStrings: Array<string> = [];
		for (let row = 0; row < data.length; ++row) {
			rowStrings.push(data[row].join(''));
		}
		const tableString = rowStrings.join('\n');
		console.log(tableString);
	}

	ensureSorted(column: number, sortOrder: SortOrder, start: number, end: number): void {
		const sorting: Array<[TableItem, number]> = [];
		for (let row = start; row <= end; ++row) {
			const item = this.item(row, column);
			if (!item) {
				// No more sortable items (all null items are at the end of
				// the table when it is sorted).
				break;
			}
			sorting.push([item, row]);
		}
		const cmp = (sortOrder === SortOrder.AscendingOrder) ? tableItemCmpAsc : tableItemCmpDesc;
		sorting.sort(cmp);
		let oldPersistentIndexes: ModelIndexList = new ModelIndexList();
		let newPersistentIndexes: ModelIndexList = new ModelIndexList();
		const newTable = new list<TableItem | null>(this.tableItems);
		const newVertical = new list<TableItem | null>(this.verticalHeaderItems);
		const colItems = this.columnItems(column);
		let vit: number = 0;
		let changed: boolean = false;
		for (let i = 0; i < sorting.length; ++i) {
			const oldRow = sorting[i][1];
			const item = colItems.at(oldRow);
			colItems.remove(oldRow);
			vit = TableModel.sortedInsertionIndex(vit, colItems.size(), colItems, sortOrder, item);
			let newRow = Math.max(vit, 0);
			if ((newRow < oldRow) && !(item.lt(colItems.at(oldRow - 1))) && !(colItems.at(oldRow - 1).lt(item))) {
				newRow = oldRow;
			}
			colItems.insert(vit, item);
			if (newRow !== oldRow) {
				if (!changed) {
					this.layoutAboutToBeChanged([]);
					oldPersistentIndexes = this.persistentIndexList();
					newPersistentIndexes = new ModelIndexList(oldPersistentIndexes);
					changed = true;
				}
				const cc = this.columnCount();
				const rowItems: Array<TableItem | null> = Array.from(repeat(null, cc));
				for (let j = 0; j < cc; ++j) {
					rowItems[j] = newTable.at(this.tableIndex(oldRow, j));
				}
				newTable.remove(this.tableIndex(oldRow, 0), cc);
				newTable.insert(this.tableIndex(newRow, 0), cc, null);
				for (let j = 0; j < cc; ++j) {
					newTable.replace(this.tableIndex(newRow, j), rowItems[j]);
				}
				const header = newVertical.at(oldRow);
				newVertical.remove(oldRow);
				newVertical.insert(newRow, header);
				this.updateRowIndexes(newPersistentIndexes, oldRow, newRow);
				for (let j = (i + 1); j < sorting.length; ++j) {
					const otherRow = sorting[j][1];
					if ((oldRow < otherRow) && (newRow >= otherRow)) {
						--sorting[j][1];
					} else if ((oldRow > otherRow) && (newRow <= otherRow)) {
						++sorting[j][1];
					}
				}
			}
		}
		if (changed) {
			this.tableItems = new list<TableItem | null>(newTable);
			this.verticalHeaderItems = new list<TableItem | null>(newVertical);
			this.changePersistentIndexList(oldPersistentIndexes, newPersistentIndexes);
			this.layoutChanged([]);
		}
	}

	headerData(section: number, orientation: Orientation, role: number = ItemDataRole.DisplayRole): Variant {
		if (section < 0) {
			return new Variant();
		}
		let item: TableItem | null = null;
		if (orientation === Orientation.Horizontal && section < this.horizontalHeaderItems.size()) {
			item = this.horizontalHeaderItems.at(section);
		} else if (orientation === Orientation.Vertical && section < this.verticalHeaderItems.size()) {
			item = this.verticalHeaderItems.at(section);
		} else {
			return new Variant();
		}
		if (item) {
			return item.data(role);
		}
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(section + 1);
		}
		return new Variant();
	}

	horizontalHeaderItem(section: number): TableItem | null {
		if ((section >= 0) && (section < this.horizontalHeaderItems.size())) {
			return this.horizontalHeaderItems.at(section);
		}
		return null;
	}

	index(row: number, column: number, parent?: ModelIndex): ModelIndex;
	index(item: TableItem | null): ModelIndex;
	index(a: TableItem | number | null, b?: number, c?: ModelIndex): ModelIndex {
		if (isNumber(a)) {
			if (isNumber(b)) {
				return super.index(a, b, c);
			}
		} else {
			const item = <TableItem>a;
			let i: number;
			const id = item.id;
			if ((id >= 0) && (id < this.tableItems.size()) && (this.tableItems.at(id) === item)) {
				i = id;
			} else {
				i = this.tableItems.indexOf(item);
				if (i === -1) {
					return new ModelIndex();
				}
			}
			return super.index(...divmod(i, this.columnCount()));
		}
		return new ModelIndex();
	}

	insertColumns(column: number, count: number = 1, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (column < 0) || (column > this.horizontalHeaderItems.size())) {
			return false;
		}
		this.beginInsertColumns(new ModelIndex(), column, column + count - 1);
		const rowCount = this.verticalHeaderItems.size();
		const colCount = this.horizontalHeaderItems.size();
		this.horizontalHeaderItems.insert(column, count, null);
		if (colCount === 0) {
			this.tableItems.insert(0, rowCount * count, null);
		} else {
			for (let row = 0; row < rowCount; ++row) {
				this.tableItems.insert(this.tableIndex(row, column), count, null);
			}
		}
		this.endInsertColumns();
		return true;
	}

	insertRows(row: number, count: number = 1, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (row < 0) || (row > this.verticalHeaderItems.size())) {
			return false;
		}
		this.beginInsertRows(new ModelIndex(), row, row + count - 1);
		const rowCount = this.verticalHeaderItems.size();
		const colCount = this.horizontalHeaderItems.size();
		this.verticalHeaderItems.insert(row, count, null);
		if (rowCount === 0) {
			this.tableItems.insert(0, colCount * count, null);
		} else {
			this.tableItems.insert(this.tableIndex(row, 0), colCount * count, null);
		}
		this.endInsertRows();
		return true;
	}

	private isValid(index: ModelIndex): boolean {
		return index.isValid()
			&& (index.row < this.verticalHeaderItems.size())
			&& (index.column < this.horizontalHeaderItems.size());
	}

	item(row: number, column: number): TableItem | null;
	item(index: ModelIndex): TableItem | null;
	item(a: ModelIndex | number, b?: number): TableItem | null {
		if (isNumber(a) && isNumber(b)) {
			return this.item(this.index(a, b));
		}
		const index = <ModelIndex>a;
		if (this.isValid(index)) {
			return this.tableItems.at(this.tableIndex(index.row, index.column));
		}
		return null;
	}

	itemChanged(item?: TableItem | null, roles?: Array<number>): void {
		if (!item) {
			return;
		}
		if (item.flags() & ItemIsHeaderItem) {
			const row = this.verticalHeaderItems.indexOf(item);
			if (row >= 0) {
				this.headerDataChanged(Orientation.Vertical, row, row);
			} else {
				const column = this.horizontalHeaderItems.indexOf(item);
				if (column >= 0) {
					this.headerDataChanged(Orientation.Horizontal, column, column);
				}
			}
		} else {
			const index = this.index(item);
			if (index.isValid()) {
				this.dataChanged(index, index, roles);
			}
		}
	}

	itemPrototype(): TableItem | null {
		return this.prototype;
	}

	removeColumns(column: number, count: number = 1, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (column < 0) || ((column + count) > this.horizontalHeaderItems.size())) {
			return false;
		}
		this.beginRemoveColumns(new ModelIndex(), column, column + count - 1);
		let oldItem: TableItem | null = null;
		for (let row = (this.rowCount() - 1); row >= 0; --row) {
			const i = this.tableIndex(row, column);
			for (let k = i; k < (i + count); ++k) {
				oldItem = this.tableItems.at(k);
				if (oldItem) {
					oldItem.view = null;
					this.tableItems.replace(k, null);
					oldItem.destroy();
				}
			}
			this.tableItems.remove(i, count);
		}
		for (let k = column; k < (column + count); ++k) {
			oldItem = this.horizontalHeaderItems.at(k);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
				this.horizontalHeaderItems.replace(k, null);
			}
		}
		this.horizontalHeaderItems.remove(column, count);
		this.endRemoveColumns();
		return true;
	}

	removeItem(item: TableItem): void {
		let i = this.tableItems.indexOf(item);
		if (i >= 0) {
			const index = this.index(item);
			this.tableItems.replace(i, null);
			this.dataChanged(index, index);
			return;
		}
		i = this.verticalHeaderItems.indexOf(item);
		if (i >= 0) {
			this.verticalHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Vertical, i, i);
		}
		i = this.horizontalHeaderItems.indexOf(item);
		if (i >= 0) {
			this.horizontalHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Horizontal, i, i);
		}
	}

	removeRows(row: number, count: number = 1, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (row < 0) || ((row + count) > this.verticalHeaderItems.size())) {
			return false;
		}
		this.beginRemoveRows(new ModelIndex(), row, row + count - 1);
		const i = this.tableIndex(row, 0);
		const n = count * this.columnCount();
		let oldItem: TableItem | null = null;
		for (let k = i; k < (n + i); ++k) {
			oldItem = this.tableItems.at(k);
			if (oldItem) {
				oldItem.view = null;
				this.tableItems.replace(k, null);
				oldItem.destroy();
			}
		}
		this.tableItems.remove(Math.max(i, 0), n);
		for (let k = row; k < (row + count); ++k) {
			oldItem = this.verticalHeaderItems.at(k);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
				this.verticalHeaderItems.replace(k, null);
			}
		}
		this.verticalHeaderItems.remove(row, count);
		this.endRemoveRows();
		return true;
	}

	rowCount(parent: ModelIndex = new ModelIndex()): number {
		return parent.isValid() ? 0 : this.verticalHeaderItems.size();
	}

	setColumnCount(columns: number): void {
		const cc = this.horizontalHeaderItems.size();
		if ((columns < 0) || (cc === columns)) {
			return;
		}
		if (cc < columns) {
			this.insertColumns(Math.max(cc, 0), columns - cc);
		} else {
			this.removeColumns(Math.max(columns, 0), cc - columns);
		}
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		if ((section < 0) || (orientation === Orientation.Horizontal && this.horizontalHeaderItems.size() <= section) || (orientation === Orientation.Vertical && this.verticalHeaderItems.size() <= section)) {
			return false;
		}
		let item: TableItem | null;
		if (orientation === Orientation.Horizontal) {
			item = this.horizontalHeaderItems.at(section);
		} else {
			item = this.verticalHeaderItems.at(section);
		}
		if (item) {
			item.setData(role, value);
			return true;
		}
		return false;
	}

	setHorizontalHeaderItem(section: number, item: TableItem | null): void {
		if ((section < 0) || (section >= this.horizontalHeaderItems.size())) {
			return;
		}
		const oldItem = this.horizontalHeaderItems.at(section);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			oldItem.destroy();
			this.horizontalHeaderItems.replace(section, null);
		}
		if (item) {
			item.view = this.view;
			item.itemFlags |= ItemIsHeaderItem;
		}
		this.horizontalHeaderItems.replace(section, item);
		this.headerDataChanged(Orientation.Horizontal, section, section);
	}

	setItem(row: number, column: number, item: TableItem | null): void {
		const i = this.tableIndex(row, column);
		if (!this.validTableItemIndex(i)) {
			return;
		}
		const oldItem = this.tableItems.at(i);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			this.tableItems.replace(i, null);
			oldItem.destroy();
		}
		if (item) {
			item.id = i;
		}
		this.tableItems.replace(i, item);
		if (this.view && this.view.isSortingEnabled() && (this.view.sortIndicatorSection() === column)) {
			const sortOrder: SortOrder = this.view.sortIndicatorOrder();
			const colItems = this.columnItems(column);
			if (row < colItems.size()) {
				colItems.remove(row);
			}
			let sortedRow: number;
			if (item) {
				const insertIdx = TableModel.sortedInsertionIndex(0, colItems.size(), colItems, sortOrder, item);
				sortedRow = Math.max(insertIdx, 0);
			} else {
				sortedRow = colItems.size();
			}
			if (sortedRow !== row) {
				this.layoutAboutToBeChanged([]);
				const colCount = this.columnCount();
				const rowItems = new list<TableItem | null>();
				for (let k = 0; k < colCount; ++k) {
					const idx = this.tableIndex(row, k);
					rowItems.append(this.tableItems.at(idx));
				}
				this.tableItems.remove(this.tableIndex(row, 0), colCount);
				this.tableItems.insert(this.tableIndex(sortedRow, 0), colCount, null);
				for (let k = 0; k < colCount; ++k) {
					this.tableItems.replace(this.tableIndex(sortedRow, k), rowItems.at(k));
				}
				const header = this.verticalHeaderItems.at(row);
				this.verticalHeaderItems.remove(row);
				this.verticalHeaderItems.insert(sortedRow, header);
				// Update persistent indexes
				const oldPersistentIndexes = this.persistentIndexList();
				const newPersistentIndexes = new ModelIndexList(oldPersistentIndexes);
				this.updateRowIndexes(newPersistentIndexes, row, sortedRow);
				this.changePersistentIndexList(oldPersistentIndexes, newPersistentIndexes);
				this.layoutChanged([]);
				return;
			}
		}
		const index = this.index(row, column);
		this.dataChanged(index, index);
	}

	setItemPrototype(item: TableItem | null): void {
		if (item !== this.prototype) {
			if (this.prototype) {
				this.prototype.destroy();
				this.prototype = null;
			}
			this.prototype = item;
		}
	}

	setRowCount(count: number): void {
		const curr = this.verticalHeaderItems.size();
		if ((count < 0) || (count === curr)) {
			return;
		}
		if (curr < count) {
			this.insertRows(Math.max(curr, 0), count - curr);
		} else {
			this.removeRows(Math.max(count, 0), curr - count);
		}
	}

	setVerticalHeaderItem(section: number, item: TableItem | null): void {
		if ((section < 0) || (section >= this.verticalHeaderItems.size())) {
			return;
		}
		const oldItem = this.verticalHeaderItems.at(section);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			this.verticalHeaderItems.replace(section, null);
			oldItem.destroy();
		}
		if (item) {
			item.view = this.view;
			item.itemFlags |= ItemIsHeaderItem;
		}
		this.verticalHeaderItems.replace(section, item);
		this.headerDataChanged(Orientation.Vertical, section, section);
	}

	sort(column: number, sortOrder: SortOrder): void {
		const sortable: Array<[TableItem, number]> = [];
		const unsortable: Array<number> = [];
		for (let row = 0; row < this.rowCount(); ++row) {
			const item = this.item(row, column);
			if (item) {
				sortable.push([item, row]);
			} else {
				unsortable.push(row);
			}
		}
		const cmp = (sortOrder === SortOrder.AscendingOrder) ? tableItemCmpAsc : tableItemCmpDesc;
		sortable.sort(cmp);
		const sortedTable: Array<TableItem | null> = Array.from(repeat(null, this.tableItems.size()));
		const from = new ModelIndexList();
		const to = new ModelIndexList();
		const numRows = this.rowCount();
		const numColumns = this.columnCount();
		for (let i = 0; i < numRows; ++i) {
			const r = (i < sortable.length) ? sortable[i][1] : unsortable[i - sortable.length];
			for (let c = 0; c < numColumns; ++c) {
				sortedTable[this.tableIndex(i, c)] = this.item(r, c);
				from.append(this.createIndex(r, c));
				to.append(this.createIndex(i, c));
			}
		}
		this.layoutAboutToBeChanged([]);
		this.tableItems = new list<TableItem | null>(sortedTable);
		this.changePersistentIndexList(from, to);
		this.layoutChanged([]);
	}

	private tableIndex(row: number, column: number): number {
		return (row * this.horizontalHeaderItems.size()) + column;
	}

	takeHorizontalHeaderItem(section: number): TableItem | null {
		if (this.validHorizontalHeaderItemIndex(section)) {
			const item = this.horizontalHeaderItems.at(section);
			if (item) {
				item.view = null;
				item.itemFlags &= ~ItemIsHeaderItem;
				this.horizontalHeaderItems.replace(section, null);
			}
			return item;
		}
		return null;
	}

	takeItem(row: number, column: number): TableItem | null {
		let item: TableItem | null = null;
		const i = this.tableIndex(row, column);
		if (i >= 0 && i < this.tableItems.size()) {
			item = this.tableItems.at(i);
		}
		if (item) {
			item.view = null;
			item.id = -1;
			this.tableItems.replace(i, null);
			const index = this.index(row, column);
			this.dataChanged(index, index);
		}
		return item;
	}

	takeVerticalHeaderItem(section: number): TableItem | null {
		if (this.validVerticalHeaderItemIndex(section)) {
			const item = this.verticalHeaderItems.at(section);
			if (item) {
				item.view = null;
				item.itemFlags &= ~ItemIsHeaderItem;
				this.verticalHeaderItems.replace(section, null);
			}
			return item;
		}
		return null;
	}

	updateRowIndexes(indexes: ModelIndexList, movedFromRow: number, movedToRow: number): void {
		// Adjusts the row of each index in `indexes` if necessary, given that
		// a row of items has been moved from row `movedFrom` to row
		// `movedTo`.
		for (let i = 0; i < indexes.size(); ++i) {
			const index = indexes.at(i);
			const oldRow = index.row;
			let newRow = oldRow;
			if (oldRow === movedFromRow) {
				newRow = movedToRow;
			} else if ((movedFromRow < oldRow) && (movedToRow >= oldRow)) {
				newRow = oldRow - 1;
			} else if ((movedFromRow > oldRow) && (movedToRow <= oldRow)) {
				newRow = oldRow + 1;
			}
			if (newRow !== oldRow) {
				i = indexes.indexOf(this.index(newRow, index.column, index.parent()));
			}
		}
	}

	validHorizontalHeaderItemIndex(index: number): boolean {
		return (index >= 0) && (index < this.horizontalHeaderItems.size());
	}

	validTableItemIndex(index: number): boolean {
		return (index >= 0) && (index < this.tableItems.size());
	}

	validVerticalHeaderItemIndex(index: number): boolean {
		return (index >= 0) && (index < this.verticalHeaderItems.size());
	}

	verticalHeaderItem(section: number): TableItem | null {
		if (section >= 0 && section < this.verticalHeaderItems.size()) {
			return this.verticalHeaderItems.at(section);
		}
		return null;
	}
}

function variantCmp(left: Variant, right: Variant, cs: CaseSensitivity = CaseSensitivity.CaseSensitive, isLocaleAware: boolean = false): number {
	if (left.type() === MetaType.Invalid) {
		return 1;
	}
	if (right.type() === MetaType.Invalid) {
		return -1;
	}
	switch (left.type()) {
		case MetaType.Number: {
			const leftN = left.toNumber();
			const rightN = right.toNumber();
			if (leftN < rightN) {
				return -1;
			}
			if (leftN > rightN) {
				return 1;
			}
			return 0;
		}
		case MetaType.DateTime: {
			return left.toDateTime()._cmp(right.toDateTime());
		}
		case MetaType.TimeDelta: {
			return left.toTimeDelta()._cmp(right.toTimeDelta());
		}
		case MetaType.Decimal: {
			return left.toDecimal().cmp(right.toDecimal());
		}
		case MetaType.Date: {
			return left.toDate()._cmp(right.toDate());
		}
		case MetaType.Time: {
			return left.toTime()._cmp(right.toTime());
		}
		case MetaType.ModelIndex: {
			return left.toModelIndex().cmp(right.toModelIndex());
		}
		default:
			return stringCmp(left.toString(), right.toString(), cs, isLocaleAware);
	}
}

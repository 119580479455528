import {MDCMenu} from './ctrl';

import {El, ElOpts, elOpts} from '../../el';
import {List, ListItem, ListItemOpts, ListItemSelectEvt} from '../list';
import {bind, pixelString} from '../../util';
import {list} from '../../tools';
import {Key} from '../../constants';
import {Evt} from '../../evt';

export enum Corner {
	TOP_LEFT = 0,
	TOP_RIGHT = 4,
	BOTTOM_LEFT = 1,
	BOTTOM_RIGHT = 5,
	TOP_START = 8,
	TOP_END = 12,
	BOTTOM_START = 9,
	BOTTOM_END = 13,
}

export interface MenuOpts extends ElOpts {
	items: Iterable<ListItem> | Iterable<Partial<ListItemOpts>> | Iterable<string>;
	list: List | null;
	listFactory: () => List;
	persistOnSelect: boolean;
}

export enum MenuState {
	Closed,
	Closing,
	Opened,
	Opening,
}

export class MenuStateChangeEvt extends Evt {
	static Closed: MenuState.Closed = MenuState.Closed;
	static Closing: MenuState.Closing = MenuState.Closing;
	static Opened: MenuState.Opened = MenuState.Opened;
	static Opening: MenuState.Opening = MenuState.Opening;

	private st: MenuState;

	constructor(state: MenuState) {
		super(Evt.StateChange);
		this.st = state;
	}

	closed(): boolean {
		return this.st === MenuState.Closed;
	}

	closing(): boolean {
		return this.st === MenuState.Closing;
	}

	opened(): boolean {
		return this.st === MenuState.Opened;
	}

	opening(): boolean {
		return this.st === MenuState.Opening;
	}

	state(): MenuState {
		return this.st;
	}
}

export class Menu extends El {
	static RootSelector: string = '.mdc-menu';
	static BOTTOM_END: Corner.BOTTOM_END = Corner.BOTTOM_END;
	static BOTTOM_LEFT: Corner.BOTTOM_LEFT = Corner.BOTTOM_LEFT;
	static BOTTOM_RIGHT: Corner.BOTTOM_RIGHT = Corner.BOTTOM_RIGHT;
	static BOTTOM_START: Corner.BOTTOM_START = Corner.BOTTOM_START;
	static TOP_END: Corner.TOP_END = Corner.TOP_END;
	static TOP_LEFT: Corner.TOP_LEFT = Corner.TOP_LEFT;
	static TOP_RIGHT: Corner.TOP_RIGHT = Corner.TOP_RIGHT;
	static TOP_START: Corner.TOP_START = Corner.TOP_START;

	ctrl: MDCMenu | null;
	list: List | null;
	private persist: boolean;
	private positionAbs: boolean;
	private positionFixed: boolean;

	constructor(opts: Partial<MenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts>, parent?: El | null);
	constructor(opts?: Partial<MenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<MenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<MenuOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.ctrl = null;
		if (opts.list) {
			this.list = opts.list;
		} else if (opts.listFactory) {
			this.list = opts.listFactory();
		} else {
			this.list = new List({root: this.querySelector(List.RootSelector)});
		}
		this.persist = Boolean(opts.persistOnSelect);
		this.positionAbs = false;
		this.positionFixed = false;
		this.addClass('mdc-menu', 'mdc-menu-surface');
		this.list.setAttribute([['aria-hidden', 'true'], ['aria-orientation', 'vertical'], ['role', 'list']]);
		this.list.setTabIndex(-1);
		if (opts.items) {
			this.addItems(opts.items);
		}
		if (!this.contains(this.list)) {
			this.appendChild(this.list.group ? this.list.group : this.list);
		}
		this.setupCtrl();
		this.list.onEvt(this.listEvt);
	}

	addItem(item: ListItem | Partial<ListItemOpts> | string): ListItem {
		return this.insertItem(-1, item);
	}

	addItems(items: Iterable<ListItem> | Iterable<Partial<ListItemOpts>> | Iterable<string>): void {
		for (const it of items) {
			this.addItem(it);
		}
	}

	anchorToBody(x: number, y: number): void {
		const node = this.element();
		if (node) {
			document.body.appendChild(node);
		}
		this.setAbsolutePosition(x, y);
	}

	checkedItems(): list<ListItem> {
		if (this.list) {
			return this.list.checkedItems();
		}
		return new list<ListItem>();
	}

	clear(): void {
		if (this.list) {
			this.list.clear();
		}
	}

	close(): void {
		this.setOpen(false);
	}

	protected closed(): void {
		window.removeEventListener('keydown', this.event, true);
		window.removeEventListener('resize', this.event);
		window.removeEventListener('click', this.event);
		this.notifyEvt(new MenuStateChangeEvt(MenuState.Closed));
	}

	destroy(): void {
		window.removeEventListener('keydown', this.event, true);
		window.removeEventListener('resize', this.event);
		window.removeEventListener('click', this.event);
		this.releaseCtrl();
		if (this.list) {
			this.list.destroy();
		}
		this.list = null;
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'keydown':
				this.keyPressEvent(<KeyboardEvent>event);
				break;
			case 'resize':
				this.resizeEvent();
				break;
			case 'click':
				this.mouseClickEvent(<MouseEvent>event);
				break;
			case 'MDCMenuSurface:closed':
				this.closed();
				break;
			case 'MDCMenuSurface:opened':
				this.opened();
				break;
			default:
				break;
		}
	}

	insertItem(index: number, item: ListItem | Partial<ListItemOpts> | string): ListItem {
		if (this.list) {
			const it = this.list.insertItem(index, item);
			if (!it.hasAttribute('role')) {
				it.setAttribute('role', 'menuitem');
			}
			return it;
		}
		let it: ListItem;
		if (item instanceof ListItem) {
			it = item;
		} else if (typeof item === 'string') {
			it = new ListItem({text: item});
		} else {
			it = new ListItem(item);
		}
		if (!it.hasAttribute('role')) {
			it.setAttribute('role', 'menuitem');
		}
		return it;
	}

	isOpen(): boolean {
		return this.ctrl ? this.ctrl.open : false;
	}

	item(index: number): ListItem | null {
		if (this.list) {
			return this.list.item(index);
		}
		return null;
	}

	protected keyPressEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case Key.Escape:
				event.stopImmediatePropagation();
				this.close();
				break;
		}
	}

	@bind
	protected listEvt(evt: Evt): void {
		if (evt instanceof ListItemSelectEvt) {
			this.listItemSelectEvt(evt);
		}
	}

	protected listItemSelectEvt(evt: ListItemSelectEvt): void {
		this.notifyEvt(evt);
		if (!this.persist) {
			this.close();
		}
	}

	protected mouseClickEvent(event: MouseEvent): void {
		let el: El;
		try {
			el = El.fromEvent(event);
		} catch {
			return;
		}
		if (!this.contains(el)) {
			this.close();
		}
	}

	open(): void {
		this.setOpen(true);
	}

	protected opened(): void {
		window.addEventListener('resize', this.event);
		window.addEventListener('keydown', this.event, true);
		window.addEventListener('click', this.event);
		if (this.ctrl) {
			this.ctrl.layout();
		}
		if (this.list) {
			if (this.list.ctrl) {
				this.list.ctrl.layout();
			}
		}
		this.notifyEvt(new MenuStateChangeEvt(MenuState.Opened));
	}

	persistOnSelect(): boolean {
		return this.persist;
	}

	private releaseCtrl(): void {
		if (this.ctrl) {
			this.removeEventListener('MDCMenuSurface:opened', this.event);
			this.removeEventListener('MDCMenuSurface:closed', this.event);
			this.ctrl.destroy();
			this.ctrl = null;
		}
	}

	protected resizeEvent(): void {
		if (this.positionAbs || this.positionFixed) {
			this.close();
		}
	}

	setAbsolutePosition(x: number, y: number): void {
		if (this.ctrl) {
			this.ctrl.setAbsolutePosition(x, y);
		}
		this.positionAbs = true;
	}

	setFixPosition(fixPosition: boolean): void {
		if (fixPosition === this.positionFixed) {
			return;
		}
		this.positionFixed = fixPosition;
		if (this.ctrl) {
			this.ctrl.setFixedPosition(this.positionFixed);
		}
		if (this.positionFixed) {
			this.removeClass('mdc-menu-surface--fullwidth');
		}
	}

	setOpen(open: boolean): void {
		if (!this.ctrl || (open === this.isOpen())) {
			return;
		}
		this.ctrl.open = open;
	}

	setPersistOnSelect(persist: boolean): void {
		this.persist = persist;
	}

	private setupCtrl(): void {
		if (!this.ctrl) {
			const root = this.element();
			if (root) {
				this.addEventListener('MDCMenuSurface:closed', this.event);
				this.addEventListener('MDCMenuSurface:opened', this.event);
				this.ctrl = new MDCMenu(root, this.list && this.list.ctrl);
				this.ctrl.layout();
			}
		}
	}

	setWidth(width: number): void {
		this.setStyleProperty('width', pixelString(width));
	}
}

import {MDCRipple} from '@material/ripple';

import {El, elOpts, ElOpts} from '../el';
import {bind} from '../util';
import {Evt, MouseEvt} from '../evt';
import {KeyboardModifier, MouseButton} from '../constants';
import {Point} from '../tools';

export interface IconButtonOpts extends ElOpts {
	disabled: boolean;
	href: string | {href: string; opts?: Partial<{target: 'blank';}>}
	iconName: string;
	isAnchor: boolean;
	isSubmit: boolean;
	name: string;
	smaller: boolean;
	title: string;
}

export class IconButtonClickEvt extends MouseEvt {
	static fromClickEvent(instance: IconButton, event: MouseEvent): IconButtonClickEvt {
		const {button, buttons, modifiers, pos} = this.fromEventParts(event);
		return new this(
			instance,
			pos,
			button,
			buttons,
			modifiers);
	}

	private b: IconButton;

	constructor(instance: IconButton, clientPos: Point, mouseButton: MouseButton, mouseButtons: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(Evt.MouseButtonClick, clientPos, mouseButton, mouseButtons, modifiers);
		this.b = instance;
	}

	iconButton(): IconButton {
		return this.b;
	}
}

export class IconButton extends El {
	static RootSelector: string = '.mdc-icon-button';

	private clickEventListenerAdded: boolean;
	private initOpts: Partial<IconButtonOpts>;
	protected rip: MDCRipple | null;

	constructor(opts: Partial<IconButtonOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts>, parent?: El | null);
	constructor(opts?: Partial<IconButtonOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<IconButtonOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<IconButtonOpts>(a, b, c);
		if (!(opts.root || opts.tagName)) {
			opts.tagName = opts.isAnchor ?
				'a' :
				'button';
		}
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-icon-button',
			'material-icons',
			'pb-icon-button',
			...classNames,
		];
		super(opts);
		this.initOpts = {...opts};
		if (!opts.isAnchor) {
			this.setType(opts.isSubmit ?
				'submit' :
				'button');
		}
		this.clickEventListenerAdded = false;
		if (opts.name) {
			this.setName(opts.name);
		}
		if (opts.title) {
			this.setTitle(opts.title);
		}
		if (opts.iconName) {
			this.setIconName(opts.iconName);
		}
		if (opts.disabled) {
			this.setDisabled(opts.disabled);
		}
		if (opts.smaller) {
			this.addClass('pb-icon-button--size-20');
		}
		if (opts.href) {
			if (typeof opts.href === 'string') {
				this.setHref(opts.href);
			} else {
				this.setHref(opts.href.href, opts.href.opts);
			}
		}
		this.rip = new MDCRipple(this.elem);
		this.rip.unbounded = true;
	}

	clone(): IconButton {
		return new (<typeof IconButton>this.constructor)(this.initOpts);
	}

	destroy(): void {
		if (this.rip) {
			this.rip.destroy();
			this.rip = null;
		}
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'click':
				this.mouseClickEvent(<MouseEvent>event);
				break;
		}
	}

	protected evtListenerAdded(): void {
		if (!this.clickEventListenerAdded) {
			this.clickEventListenerAdded = true;
			this.addEventListener('click', this.event);
		}
	}

	protected evtListenerRemoved(): void {
		if (this.evtListeners.isEmpty()) {
			this.removeEventListeners();
		}
	}

	iconName(): string {
		return this.text();
	}

	isAnchor(): boolean {
		return this.tagName() === 'A';
	}

	private mouseClickEvent(event: MouseEvent): void {
		if (!this.evtListeners.isEmpty()) {
			event.stopPropagation();
		}
		this.notifyEvt(IconButtonClickEvt.fromClickEvent(this, event));
	}

	protected removeEventListeners(): void {
		this.removeEventListener('click', this.mouseClickEvent);
		this.clickEventListenerAdded = false;
	}

	setIconName(icon?: string | null): void {
		this.setText(icon);
	}
}

import React from 'react';

import {TextField} from '../../../components';
import {bind} from '../../../../util';

interface IProps {
	onChange: (value: string, name: 'description') => any;
	value: string;
}

export default class Description extends React.Component<IProps, {}> {
	static Name: 'description' = 'description';

	@bind
	changeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, this.name());
	}

	name(): 'description' {
		return Description.Name;
	}

	render(): React.ReactNode {
		const {value} = this.props;
		return <TextField
			className="width--100-percent"
			helpText="e.g.: Interior Photos & then some"
			label="Description"
			name={this.name()}
			onChange={this.changeEvent}
			value={value}/>;
	}
}

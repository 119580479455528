import {MDCTextField} from '@material/textfield';

import {CSS_CLASS_NO_VALUE, ProjectEmailType, ProjectStatus, StandardButton} from '../../constants';
import {bind, isNumber, numberFormat} from '../../util';
import {apiService as svc} from '../../services';
import {El, elOpts, ElOpts} from '../../el';
import {Evt} from '../../evt';
import {MenuStateChangeEvt} from '../../ui/menu';
import {list, Point, set} from '../../tools';
import {StatusMenu, StatusMenuButton, StatusMenuButtonEvt, StatusMenuSelectEvt} from './status';
import {Dia} from './dia';
import {ProjectTaskChangeEvt, ProjectTaskMenu} from '../../ui/projecttaskmenu';
import {OptionMenu, OptionMenuEvt, ProjectOption} from './option';
import {isNetworkErrorObject} from '../../services/request';
import {ProjectURLView} from './projecturl';
import {ProjectEmailView} from './projectemail';
import {AssignableForm, AssignedForm, AssignedFormEvt} from './assignment';

const assignablePhotoFormSelector = '#id_pb-assignable-photography-form';
const assignablePhotoSelectSelector = '#id_pb-assignable-photography-select';
const assignedPhotoFormSelector = '#id_pb-assigned-photography-form';
const assignableEditFormSelector = '#id_pb-assignable-editing-form';
const assignableEditSelectSelector = '#id_pb-assignable-editing-select';
const assignedEditFormSelector = '#id_pb-assigned-editing-form';
const projectContainerSelector = '.project-container';

enum Assignment {
	Photography = 1,
	Editing,
}

class AssignablePhotographyForm extends AssignableForm {
	static AssignableSelectRootSelector: string = assignablePhotoSelectSelector;
	static RootSelector: string = assignablePhotoFormSelector;
}

class AssignedPhotographyForm extends AssignedForm {
	static RootSelector: string = assignedPhotoFormSelector;
}

class AssignableEditingForm extends AssignableForm {
	static AssignableSelectRootSelector: string = assignableEditSelectSelector;
	static RootSelector: string = assignableEditFormSelector;
}

class AssignedEditingForm extends AssignedForm {
	static RootSelector: string = assignedEditFormSelector;
}

interface ProjectDetailViewOpts extends ElOpts {
	slug: string;
}

export class ProjectDetailView extends El {
	static DispatchEmailInputRootSelector: string = 'input[type="hidden"][name="dispatch_email"]';
	static OptionCardRootSelector: string = '#id_project-detail-option-card';
	static OptionMenuButtonRootSelector: string = '.project-options-toggle';
	static PaymentFormAmountInputRootSelector: string = '#id_amount';
	static PaymentFormButtonRootSelector: string = '#id_payment_button';
	static PaymentFormRootSelector: string = '#payment-form';
	static RootSelector: string = '#pb-main';
	static UpdateFormRootSelector: string = '#single-project-edit-form';

	private choiceMgr: ChoiceManager | null;
	private dia: Dia | null;
	private emailView: ProjectEmailView | null;
	private optionMenu: OptionMenu | null;
	private slug: string;
	private statusMenu: StatusMenu | null;
	private statusMenuBtn: StatusMenuButton | null;
	private taskMenu: ProjectTaskMenu | null;
	private taskMenuBtn: TaskMenuButton | null;
	private urlView: ProjectURLView | null;
	private assignmentForms: Map<Assignment, {assignable: AssignableForm; assigned: AssignedForm;}>;

	constructor(opts: Partial<ProjectDetailViewOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ProjectDetailViewOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ProjectDetailViewOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ProjectDetailViewOpts> | null, root?: Element | null);
	constructor(opts: Partial<ProjectDetailViewOpts>, parent?: El | null);
	constructor(opts?: Partial<ProjectDetailViewOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ProjectDetailViewOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ProjectDetailViewOpts>(a, b, c);
		opts.root = document.querySelector(ProjectDetailView.RootSelector);
		super(opts);
		this.assignmentForms = new Map();
		this.choiceMgr = null;
		this.dia = null;
		this.emailView = null;
		this.optionMenu = null;
		this.slug = opts.slug || '';
		this.statusMenu = null;
		this.statusMenuBtn = null;
		this.taskMenu = null;
		this.taskMenuBtn = null;
		this.urlView = null;
		this.initialize();
	}

	private assignForms(assignment: Assignment): {assignable: AssignableForm; assigned: AssignedForm} | null {
		return this.assignmentForms.get(assignment) || null;
	}

	@bind
	private assignableEditFormEvt(evt: Evt): void {
		if (evt.type() !== Evt.Submit) {
			return;
		}
		this.assignmentFormChanged(Assignment.Editing);
	}

	@bind
	private assignablePhotoFormEvt(evt: Evt): void {
		if (evt.type() !== Evt.Submit) {
			return;
		}
		this.assignmentFormChanged(Assignment.Photography);
	}

	private assignmentFormChanged(assignment: Assignment): void {
		const forms = this.assignForms(assignment);
		if (!forms) {
			console.log('assignmentFormSubmitted: null returned for assignment %s (%s)', assignment, Assignment[assignment]);
			return;
		}
		const val = forms.assignable.currentSelectValue().trim();
		if (val.length < 1) {
			// Null option
			return;
		}
		const selectedId = Number.parseInt(val);
		if (isNumber(selectedId)) {
			if (forms.assigned.hasUser(selectedId)) {
				// Already assigned. Nothing to do.
				return;
			}
			const newAssignedIds = new set<number>(forms.assigned.users().map(x => x.id));
			newAssignedIds.add(selectedId);
			this.updateProjectAssignment(assignment, newAssignedIds.toList());
		} else {
			console.log('assignmentFormChanged: received NaN');
		}
	}

	private assignedFormChanged(assignment: Assignment, user: IUser): void {
		this.removeAssignedUser(assignment, user);
	}

	@bind
	private assignedEditFormEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof AssignedFormEvt)) {
			this.assignedFormChanged(
				Assignment.Editing,
				evt.assignedUser(),
			);
		}
	}

	@bind
	private assignedPhotoFormEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof AssignedFormEvt)) {
			this.assignedFormChanged(
				Assignment.Photography,
				evt.assignedUser(),
			);
		}
	}

	destroy(): void {
		this.destroyDia();
		this.destroyEventListeners();
		this.destroyOptionMenu();
		this.destroyStatusMenu();
		this.destroyTaskMenu();
		this.choiceMgr = null;
		for (const obj of this.assignmentForms.values()) {
			obj.assignable.destroy();
			obj.assigned.destroy();
		}
		this.assignmentForms.clear();
		if (this.statusMenuBtn) {
			this.statusMenuBtn.destroy();
		}
		this.statusMenuBtn = null;
		if (this.taskMenuBtn) {
			this.taskMenuBtn.destroy();
		}
		this.taskMenuBtn = null;
		if (this.urlView) {
			this.urlView.destroy();
		}
		this.urlView = null;
		super.destroy();
	}

	private destroyDia(): void {
		if (this.dia) {
			this.dia.destroy();
		}
		this.dia = null;
	}

	private destroyEventListeners(): void {
		let el = this.querySelector(ProjectDetailView.OptionMenuButtonRootSelector);
		if (el) {
			el.removeEventListener('click', this.optionMenuButtonClickEvent);
		}
		el = this.querySelector(ProjectDetailView.PaymentFormRootSelector);
		if (el) {
			el.removeEventListener('submit', this.paymentFormSubmitEvent);
		}
		el = this.querySelector(projectContainerSelector);
		if (el) {
			el.removeEventListener('dblclick', this.projectContainerDblClickEvent);
		}
		el = this.querySelector(ProjectDetailView.UpdateFormRootSelector);
		if (el) {
			el.removeEventListener('submit', this.updateFormSubmitEvent);
		}
		el = this.querySelector(ProjectDetailView.PaymentFormAmountInputRootSelector);
		if (el) {
			el.removeEventListener('input', this.paymentFormAmountInputInputEvent);
		}
		if (this.statusMenuBtn) {
			this.statusMenuBtn.offEvt(this.statusMenuBtnEvt);
		}
		if (this.taskMenuBtn) {
			this.taskMenuBtn.offEvt(this.taskMenuBtnEvt);
		}
		let assignForms = this.assignForms(Assignment.Photography);
		if (assignForms) {
			assignForms.assignable.offEvt(this.assignablePhotoFormEvt);
			assignForms.assigned.offEvt(this.assignedPhotoFormEvt);
		}
		assignForms = this.assignForms(Assignment.Editing);
		if (assignForms) {
			assignForms.assignable.offEvt(this.assignableEditFormEvt);
			assignForms.assigned.offEvt(this.assignedEditFormEvt);
		}
	}

	private destroyOptionMenu(): void {
		if (this.optionMenu) {
			this.optionMenu.offEvt(this.optionMenuEvt);
			this.optionMenu.destroy();
		}
		this.optionMenu = null;
	}

	private destroyStatusMenu(): void {
		if (this.statusMenu) {
			this.statusMenu.offEvt(this.statusMenuEvt);
			this.statusMenu.destroy();
		}
		this.statusMenu = null;
	}

	private destroyTaskMenu(): void {
		if (this.taskMenu) {
			this.taskMenu.offEvt(this.taskMenuEvt);
			this.taskMenu.destroy();
		}
		this.taskMenu = null;
	}

	@bind
	private diaProjectOptionConfirmationResult(opt: IProjectOption, result: number): void {
		if (result === StandardButton.Yes) {
			this.executeProjectOption(opt);
		}
	}

	@bind
	private diaStatusEmailConfirmationResult(newStatus: ProjectStatus, result: number): void {
		if (result !== StandardButton.Cancel) {
			const text = this.dia ? this.dia.textInputValue() : '';
			this.updateStatus(newStatus, result === StandardButton.Yes, text);
		}
	}

	@bind
	private diaUpdateSubmitDispatchEmailConfirmationResult(result: number): void {
		if (result !== StandardButton.Cancel) {
			this.setDispatchEmailInputValue(result === StandardButton.Yes);
			this.submitUpdateForm();
		}
	}

	private async executeProjectOption(opt: IProjectOption): Promise<void> {
		const additionalEmailText = this.dia ? this.dia.textInputValue() : '';
		const errors: Array<string> = [];
		let msgTitle: string = '';
		let successMsg: string = '';
		try {
			switch (opt.option) {
				case ProjectOption.CancelProject: {
					msgTitle = 'Cancel Project';
					await svc.project.cancel(this.slug);
					window.location.assign(window.pburls.project.list);
					break;
				}
				case ProjectOption.MarkPaid: {
					msgTitle = 'Mark Paid';
					successMsg = 'Marked paid!';
					await svc.project.markPaid(this.slug, {additionalEmailText});
					break;
				}
				case ProjectOption.SendCompleteEmail: {
					msgTitle = 'Send "Complete" Email';
					successMsg = 'Email sent!';
					await svc.project.sendEmail(
						this.slug,
						{
							additionalEmailText,
							dispatchEmail: true,
							emailType: ProjectEmailType.Complete,
							wasSent: false,
						});
					break;
				}
				case ProjectOption.SendConfirmedEmail: {
					msgTitle = 'Send "Confirm" Email';
					successMsg = 'Email sent!';
					await svc.project.sendEmail(
						this.slug,
						{dispatchEmail: true, additionalEmailText, emailType: ProjectEmailType.Confirmed, wasSent: false});
					break;
				}
				case ProjectOption.SendCreatedEmail: {
					msgTitle = 'Send "Created" Email';
					successMsg = 'Email sent!';
					await svc.project.sendEmail(
						this.slug,
						{dispatchEmail: true, additionalEmailText, emailType: ProjectEmailType.Created, wasSent: false});
					break;
				}
				case ProjectOption.SendInvoiceEmail: {
					msgTitle = 'Send "Invoice" Email';
					successMsg = 'Email sent!';
					await svc.project.sendEmail(
						this.slug,
						{dispatchEmail: true, additionalEmailText, emailType: ProjectEmailType.Invoice, wasSent: false});
					break;
				}
				case ProjectOption.SendGalleryEmail: {
					msgTitle = 'Send "Gallery" Email';
					successMsg = 'Email sent!';
					await svc.project.sendEmail(
						this.slug,
						{dispatchEmail: true, additionalEmailText, emailType: ProjectEmailType.Gallery, wasSent: false});
					break;
				}
				case ProjectOption.SendOnHoldEmail: {
					msgTitle = 'Send "On Hold" Email';
					await svc.project.sendEmail(
						this.slug,
						{
							additionalEmailText,
							dispatchEmail: true,
							emailType: ProjectEmailType.OnHold,
							wasSent: false,
						},
					);
					break;
				}
				default: {
					console.log('executeProjectOption: Invalid option "%s"', opt.option);
					break;
				}
			}
		} catch (exc) {
			if (isNetworkErrorObject(exc) && exc.response) {
				if (exc.response.data.error.details.length > 0) {
					const details = exc.response.data.error.details;
					for (let i = 0; i < details.length; ++i) {
						const detail = details[i];
						const msg = detail.message.trim();
						if (msg.length > 0) {
							errors.push(msg);
						}
					}
				}
			}
			if (errors.length < 1) {
				errors.push(exc.message);
			}
		}
		if (errors.length > 0) {
			this.openAlert(`${msgTitle} Error`.trim(), ...errors.map(err => `- ${err}`));
		} else if (successMsg.length > 0) {
			this.openAlert(msgTitle || 'Success', successMsg);
		}
	}

	private async initialize(): Promise<void> {
		this.statusMenuBtn = new StatusMenuButton({
			root: this.querySelector(StatusMenuButton.RootSelector),
		});
		this.taskMenuBtn = new TaskMenuButton({
			root: this.querySelector(TaskMenuButton.RootSelector),
		});
		this.choiceMgr = new ChoiceManager();
		if (window.isProducer) {
			const photoForms = {
				assignable: new AssignablePhotographyForm({
					root: this.querySelector(AssignablePhotographyForm.RootSelector),
				}),
				assigned: new AssignedPhotographyForm({
					root: this.querySelector(AssignedPhotographyForm.RootSelector),
				}),
			};
			this.assignmentForms.set(Assignment.Photography, photoForms);
			const editForms = {
				assignable: new AssignableEditingForm({
					root: this.querySelector(AssignableEditingForm.RootSelector),
				}),
				assigned: new AssignedEditingForm({
					root: this.querySelector(AssignedEditingForm.RootSelector),
				}),
			};
			this.assignmentForms.set(Assignment.Editing, editForms);
			const assigned = await svc.project.assigned(this.slug);
			await this.setAssignedUsers(Assignment.Photography, assigned.photography.map(x => (x.id)));
			await this.setAssignedUsers(Assignment.Editing, assigned.editing.map(x => (x.id)));
		}
		await this.initializeOptionCard();
		this.initializeURLView();
		this.initializeEmailView();
		this.initializeProjectDetailInput();
		this.initializeTempDatetimeChangerThing();
		this.initializePaymentFormAmountInput();
		this.initializeEventListeners();
	}

	private initializeEmailView(): void {
		if (window.isProducer) {
		}
	}

	private initializeEventListeners(): void {
		if (this.statusMenuBtn) {
			this.statusMenuBtn.onEvt(this.statusMenuBtnEvt);
		}
		if (this.taskMenuBtn) {
			this.taskMenuBtn.onEvt(this.taskMenuBtnEvt);
		}
		let assignForms = this.assignForms(Assignment.Photography);
		if (assignForms) {
			assignForms.assignable.onEvt(this.assignablePhotoFormEvt);
			assignForms.assigned.onEvt(this.assignedPhotoFormEvt);
		}
		assignForms = this.assignForms(Assignment.Editing);
		if (assignForms) {
			assignForms.assignable.onEvt(this.assignableEditFormEvt);
			assignForms.assigned.onEvt(this.assignedEditFormEvt);
		}
		let el = this.querySelector(ProjectDetailView.OptionMenuButtonRootSelector);
		if (el) {
			el.addEventListener('click', this.optionMenuButtonClickEvent);
		}
		el = this.querySelector(ProjectDetailView.PaymentFormRootSelector);
		if (el) {
			el.addEventListener('submit', this.paymentFormSubmitEvent);
		}
		el = this.querySelector(ProjectDetailView.UpdateFormRootSelector);
		if (el) {
			el.addEventListener('submit', this.updateFormSubmitEvent);
		}
		el = this.querySelector(ProjectDetailView.PaymentFormAmountInputRootSelector);
		if (el) {
			el.addEventListener('input', this.paymentFormAmountInputInputEvent);
		}
		if (window.location.pathname.indexOf('update') < 0) {
			el = this.querySelector(projectContainerSelector);
			if (el) {
				el.addEventListener('dblclick', this.projectContainerDblClickEvent);
			}
		}
	}

	private async initializeOptionCard(): Promise<void> {
		const count = (await svc.project.options(this.slug)).length;
		this.setOptionCardVisible(count > 0);
	}

	private initializePaymentFormAmountInput(): void {
		const el = this.querySelector(ProjectDetailView.PaymentFormAmountInputRootSelector);
		if (el) {
			const wrapper = el.closestMatchingAncestor('.pb-text-field');
			const wrapperRoot = wrapper && wrapper.element();
			if (wrapperRoot) {
				const comp = new MDCTextField(wrapperRoot);
				const val = comp.value;
				const n = Number(val);
				const disabled = !isNumber(n) || (n < 1);
				this.setPaymentFormButtonState(val ? `Pay $${numberFormat(val)}` : 'Pay', disabled);
			}
		}
	}

	private initializeProjectDetailInput(): void {
		for (const el of document.querySelectorAll('.pb-project-detail-input-field.mdc-text-field, .pb-project-detail-text-input.mdc-text-field')) {
			new MDCTextField(el);
		}
	}

	private initializeTempDatetimeChangerThing(): void {
		const formRoot = document.getElementById('id_temp-datetime-changer-thing-form');
		if (formRoot) {
			for (const el of formRoot.querySelectorAll('.mdc-text-field')) {
				new MDCTextField(el);
			}
		}
	}

	private initializeURLView(): void {
		const root = document.getElementById('id_pb-project-detail-url');
		if (root) {
			this.urlView = new ProjectURLView(this.slug, {root});
		}
	}

	private openAlert(title: string, ...message: Array<string>): void {
		this.destroyDia();
		this.dia = new Dia();
		this.dia.hideTextInput();
		this.dia.setStandardButtons(StandardButton.Ok);
		this.dia.setTitle(title);
		const d = this.dia;
		message.forEach(msg => {
			const el = new El({tagName: 'div'});
			el.setText(msg);
			d.appendContent(el);
		});
		this.dia.open();
	}

	private openEmailConfirmationPrompt(cb?: (result: number) => any, showTextInput: boolean = true): void {
		this.destroyDia();
		this.dia = new Dia();
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.No | StandardButton.Yes);
		this.dia.setTitle('Send email?');
		this.dia.setMessage('This action COULD dispatch an email.');
		this.dia.clearTextInput();
		this.dia.closeTextInput();
		this.dia.setTextInputVisible(showTextInput);
		this.dia.open(cb);
	}

	private openOptionConfirmationPrompt(opt: IProjectOption, title: string, showTextInput: boolean = true): void {
		this.destroyDia();
		this.dia = new Dia();
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		this.dia.setTitle(title);
		this.dia.setMessage('');
		this.dia.clearTextInput();
		this.dia.closeTextInput();
		this.dia.setTextInputVisible(showTextInput);
		this.dia.open(this.diaProjectOptionConfirmationResult.bind(this, opt));
	}

	private openOptionMenu(optLists: Array<Array<IProjectOption>>, x: number, y: number): void {
		this.destroyOptionMenu();
		this.optionMenu = new OptionMenu();
		this.optionMenu.setOptionLists(optLists);
		this.optionMenu.anchorToBody(x, y);
		this.optionMenu.open();
		this.optionMenu.onEvt(this.optionMenuEvt);
	}

	private openStatusMenu(currentStatus: ProjectStatus, x: number, y: number): void {
		this.destroyStatusMenu();
		this.statusMenu = new StatusMenu({currentStatus});
		this.statusMenu.anchorToBody(x, y);
		this.statusMenu.open();
		this.statusMenu.onEvt(this.statusMenuEvt);
	}

	private openTaskMenu(tasks: Array<IProjectTask>, x: number, y: number): void {
		this.destroyTaskMenu();
		this.taskMenu = new ProjectTaskMenu();
		this.taskMenu.onEvt(this.taskMenuEvt);
		this.taskMenu.anchorToBody(x, y);
		this.taskMenu.setTasks(tasks);
		this.taskMenu.open();
	}

	@bind
	private async optionMenuButtonClickEvent(event: MouseEvent): Promise<void> {
		this.openOptionMenu(
			await svc.project.options(this.slug),
			event.clientX,
			event.clientY,
			);
	}

	@bind
	private optionMenuEvt(evt: Evt): void {
		if (!this.optionMenu) {
			return;
		}
		if ((evt.type() === Evt.StateChange) && (evt instanceof MenuStateChangeEvt)) {
			if (evt.closed()) {
				this.destroyOptionMenu();
			}
		} else if ((evt.type() === Evt.Select) && (evt instanceof OptionMenuEvt)) {
			this.optionMenuOptionSelected(evt.option());
		}
	}

	private optionMenuOptionSelected(opt: IProjectOption): void {
		let showTextInput: boolean = true;
		const title: string = `${opt.text}?`;
		this.openOptionConfirmationPrompt(opt, title, showTextInput);
	}

	@bind
	private paymentFormAmountInputInputEvent(event: Event): void {
		const el = El.fromEvent(event).closestMatchingAncestor(ProjectDetailView.PaymentFormAmountInputRootSelector);
		if (el) {
			const val = el.value();
			const n = Number(val);
			const disabled = !isNumber(n) || (n < 1);
			this.setPaymentFormButtonState(val ? `Pay $${numberFormat(val)}` : 'Pay', disabled);
		}
	}

	@bind
	private paymentFormSubmitEvent(): void {
		this.setPaymentFormButtonState('Processing', true);
	}

	@bind
	private projectContainerDblClickEvent(): void {
		if (window.pburls.project.detail) {
			window.location.assign(`${window.pburls.project.detail.uri}update/`);
		}
	}

	private removeAssignedUser(assignment: Assignment, user: IUser): void {
		const forms = this.assignForms(assignment);
		if (forms) {
			forms.assigned.removeUser(user.id);
			this.updateProjectAssignment(assignment, forms.assigned.users().map(x => x.id));
		}
	}

	private async setAssignedUsers(assignment: Assignment, userPks: Array<number>): Promise<void> {
		if (!window.isProducer) {
			return;
		}
		const forms = this.assignForms(assignment);
		if (!forms) {
			return;
		}
		const prevAssignedPks = new set<number>(forms.assigned.users().map(x => x.id));
		const newAssignedPks = new set<number>(userPks);
		const assignedPksToRemove = prevAssignedPks.difference(newAssignedPks);
		for (const pk of assignedPksToRemove) {
			forms.assigned.removeUser(pk);
		}
		const assignedPksToAdd = newAssignedPks.difference(prevAssignedPks);
		if (assignedPksToAdd.size > 0) {
			const users = await svc.group.teamMember.list();
			for (const obj of users) {
				if (assignedPksToAdd.has(obj.id)) {
					forms.assigned.addUser(obj);
				}
			}
		}
	}

	private setDispatchEmailInputValue(dispatchEmail: boolean): void {
		const el = this.querySelector(ProjectDetailView.DispatchEmailInputRootSelector);
		if (el) {
			el.setValue(dispatchEmail ? 'true' : 'false');
		}
	}

	private setOptionCardVisible(visible: boolean): void {
		const el = this.querySelector(ProjectDetailView.OptionCardRootSelector);
		if (el) {
			el.setVisible(visible);
		}
	}

	private setPaymentFormButtonState(label: string, disabled: boolean): void {
		const el = this.querySelector(ProjectDetailView.PaymentFormButtonRootSelector);
		if (el) {
			el.setDisabled(disabled);
			const lbl = el.querySelector('.pb-button__label');
			if (lbl) {
				lbl.setText(label);
			}
		}
	}

	private async statusChangeRequest(newStatus: ProjectStatus): Promise<void> {
		const objs = await svc.project.emailList(this.slug);
		let idx: number = -1;
		switch (newStatus) {
			case ProjectStatus.Pending:
				break;
			case ProjectStatus.InProgress:
				idx = objs.findIndex(x => (x.emailType === ProjectEmailType.Confirmed));
				break;
			case ProjectStatus.Complete:
				idx = objs.findIndex(x => (x.emailType === ProjectEmailType.Complete));
				break;
			case ProjectStatus.OnHold:
				break;
		}
		if ((idx >= 0) && !objs[idx].wasSent) {
			this.openEmailConfirmationPrompt(
				this.diaStatusEmailConfirmationResult.bind(
					this,
					newStatus),
				true);
		} else {
			this.diaStatusEmailConfirmationResult(newStatus, StandardButton.Yes);
		}
	}

	@bind
	private async statusMenuBtnEvt(evt: Evt): Promise<void> {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof StatusMenuButtonEvt)) {
			const pos = evt.pos();
			const proj = await svc.project.get(this.slug);
			this.openStatusMenu(proj.status, pos.x(), pos.y());
		}
	}

	@bind
	private statusMenuEvt(evt: Evt): void {
		if (!this.statusMenu) {
			return;
		}
		if ((evt.type() === Evt.StateChange) && (evt instanceof MenuStateChangeEvt)) {
			if (evt.closed()) {
				this.destroyStatusMenu();
			}
		} else if ((evt.type() === Evt.Select) && (evt instanceof StatusMenuSelectEvt)) {
			this.statusChangeRequest(evt.status());
		}
	}

	private submitUpdateForm(): void {
		const el = this.querySelector(ProjectDetailView.UpdateFormRootSelector);
		if (el) {
			el.submit();
		}
	}

	@bind
	private async taskMenuBtnEvt(evt: Evt): Promise<void> {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof TaskMenuButtonEvt)) {
			const pos = evt.pos();
			const tasks = await svc.project.tasks(this.slug);
			this.openTaskMenu(tasks, pos.x(), pos.y());
		}
	}

	@bind
	private taskMenuEvt(evt: Evt): void {
		if (!this.taskMenu) {
			return;
		}
		if ((evt.type() === Evt.StateChange) && (evt instanceof MenuStateChangeEvt)) {
			if (evt.closed()) {
				this.destroyTaskMenu();
			}
		} else if ((evt.type() === Evt.Change) && (evt instanceof ProjectTaskChangeEvt)) {
			const task = evt.task();
			task.isComplete = evt.checked();
			this.updateTask(task);
		}
	}

	private async updateProjectAssignment(assignment: Assignment, pks: list<number>): Promise<void> {
		const data: Partial<{photography: Array<number>; editing: Array<number>}> = {};
		switch (assignment) {
			case Assignment.Photography: {
				data.photography = pks.toArray();
				break;
			}
			case Assignment.Editing: {
				data.editing = pks.toArray();
				break;
			}
			default:
				return;
		}
		const proj = await svc.project.updateAssigned(
			this.slug,
			data);
		await this.setAssignedUsers(assignment, (assignment === Assignment.Photography) ? proj.assigned : proj.editors);
	}

	@bind
	private async updateFormSubmitEvent(event: Event): Promise<void> {
		if (window.isProducer) {
			event.preventDefault();
			this.diaUpdateSubmitDispatchEmailConfirmationResult(StandardButton.Yes);
		}
	}

	private async updateStatus(status: ProjectStatus, dispatchEmail: boolean, additionalEmailText: string): Promise<void> {
		const old = await svc.project.get(this.slug);
		const proj = await svc.project.update(
			this.slug,
			{...old, additionalEmailText, dispatchEmail, status});
		if (this.statusMenuBtn) {
			this.statusMenuBtn.setLabel(proj.statusDisplay);
		}
		if (this.urlView) {
			await this.urlView.projectChanged();
		}
	}

	private async updateTask(task: IProjectTask): Promise<void> {
		const proj = await svc.project.updateTask(this.slug, task);
		if (this.taskMenuBtn) {
			this.taskMenuBtn.setLabel(proj.lastTask);
		}
	}
}

class TaskMenuButtonEvt extends Evt {
	private p: Point;

	constructor(type: number, pos: Point) {
		super(type);
		this.p = pos;
	}

	pos(): Point {
		return this.p;
	}
}

class TaskMenuButton extends El {
	static RootSelector: string = '#id_pb-project-detail-task-menu-button';

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		super(opts);
		this.initialize();
	}

	destroy(): void {
		this.destroyEventListeners();
		super.destroy();
	}

	private destroyEventListeners(): void {
		this.removeEventListener('click', this.event);
	}

	@bind
	private event(event: Event): void {
		if (event.type === 'click') {
			const {clientX, clientY} = <MouseEvent>event;
			this.notifyEvt(new TaskMenuButtonEvt(Evt.MouseButtonClick, new Point(clientX, clientY)));
		}
	}

	private initialize(): void {
		this.initializeEventListeners();
	}

	private initializeEventListeners(): void {
		this.addEventListener('click', this.event);
	}

	label(): string {
		const el = this.labelEl();
		const rv = el ? el.text() : '';
		return rv === '\u2014' ? '' : rv;
	}

	private labelEl(): El | null {
		return this.querySelector('.pb-project-detail-task-menu-button-label');
	}

	setLabel(label?: string | null): void {
		const el = this.labelEl();
		if (el) {
			if (label && (label !== '\u2014')) {
				el.setText(label);
				el.removeClass(CSS_CLASS_NO_VALUE);
			} else {
				el.addClass(CSS_CLASS_NO_VALUE);
				el.setText('\u2014');
			}
		}
	}

	setText(text?: string | null): void {
		this.setLabel(text);
	}

	text(): string {
		return this.label();
	}
}

class ChoiceCount extends El {
	static CountPatt: RegExp = /(\d[\d,]*)\/(\d[\d,]*)/;

	constructor(parent: El, itemId: number | string) {
		const root = parent.querySelector(`[data-item-id="${itemId}"] .pb-service-choice-collection-header__choice-count`);
		super({root});
	}

	checkedCount(): number {
		return this.parseCounts()[0];
	}

	maxCount(): number {
		return this.parseCounts()[1];
	}

	private parseCounts(): [number, number] {
		const match = ChoiceCount.CountPatt.exec(this.text().trim());
		if (match && (match.length === 3)) {
			const checkedCount = Number(match[1]);
			const maxCount = Number(match[2]);
			return [checkedCount, maxCount];
		}
		return [Number.NaN, Number.NaN];
	}

	setCheckedCount(count: number): void {
		if (isNumber(count)) {
			this.setText(`(${numberFormat(count)}/${numberFormat(this.maxCount())})`);
		}
	}

	setMaxCount(count: number): void {
		if (isNumber(count)) {
			this.setText(`(${numberFormat(this.checkedCount())}/${numberFormat(count)})`);
		}
	}
}

class ChoiceManager {
	constructor() {
		this.initialize();
	}

	@bind
	private changeEvent(event: Event): void {
		let inpEl: El | null = null;
		try {
			inpEl = El.fromEvent(event).closestMatchingAncestor('input[type="checkbox"]');
		} catch {
			return;
		}
		let rowEl: El | null = null;
		try {
			rowEl = El.fromEvent(event).closestMatchingAncestor('.service-table-row');
		} catch {
			return;
		}
		if (rowEl && inpEl) {
			const bodyEl = rowEl.parent();
			const itemId = rowEl.attribute('data-item-id');
			if (bodyEl && itemId) {
				const count = new ChoiceCount(bodyEl, itemId);
				let checkedCount = count.checkedCount();
				let maxCount = count.maxCount();
				if (isNumber(checkedCount) && isNumber(maxCount)) {
					if (inpEl.isChecked()) {
						++checkedCount;
					} else {
						--checkedCount;
					}
					if (!window.isProducer && (checkedCount > maxCount)) {
						inpEl.setChecked(false);
					}
					count.setCheckedCount(checkedCount);
					count.setMaxCount(maxCount);
					const isDisabled = !window.isProducer && (checkedCount >= maxCount);
					const unchecked = bodyEl.querySelectorAll(`[data-item-id="${itemId}"] input[type="checkbox"]:not(:checked)`);
					for (let i = 0; i < unchecked.size(); ++i) {
						const un = unchecked.at(i);
						un.setDisabled(isDisabled);
						let anc = un.closestMatchingAncestor('.mdc-checkbox');
						if (anc) {
							anc.setClass(isDisabled, 'mdc-checkbox--disabled');
						}
						anc = un.closestMatchingAncestor('.service-choice-checkbox-container');
						if (anc) {
							anc.setClass(isDisabled, 'service-checkbox-container--disabled');
						}
					}
				}
			}
		}
	}

	private initialize(): void {
		document
			.querySelectorAll('.service-choice-table .service-choice-input')
			.forEach(el => el.addEventListener('change', this.changeEvent));
	}
}

import React from 'react';

import {TextField} from '../../../components';

interface IProps {
	onChange: (value: string, name: 'color') => any;
	value: string;
}

export default class Color extends React.Component<IProps, {}> {
	static Name: 'color' = 'color';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, this.name());
	}

	name(): 'color' {
		return Color.Name;
	}

	render(): React.ReactNode {
		const {value} = this.props;
		return <TextField
			className="width--100-percent align-items--flex-end"
			helpText="e.g.: #00299B"
			label="Color"
			name={this.name()}
			onChange={this.changeEvent}
			type="color"
			value={value}/>;
	}
}

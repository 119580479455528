import {OBJ} from '../../obj';
import {apiService as svc} from '../../services';
import {El, elOpts, ElOpts} from '../../el';
import {TextInput, TextInputEvt} from '../../ui/textinput';
import {bind, closestMatchingElement, stringIterableToStringArray} from '../../util';
import {Button, ButtonClickEvt} from '../../ui/button';
import {IconButton, IconButtonClickEvt} from '../../ui/iconbutton';
import {list} from '../../tools';
import {Evt} from '../../evt';
import {NBSP_CHAR_CODE, ProjectEmailType, ProjectURLState} from '../../constants';
import {Menu, MenuStateChangeEvt} from '../../ui/menu';
import {ListItemSelectEvt} from '../../ui/list';

enum ConfirmMode {
	NoMode,
	Delete,
	Release,
	Message,
	DispatchEmail,
}

@OBJ
export class ProjectURLView extends El {
	private addBtn: Button;
	private confirm: ConfirmAction | null;
	private currentConfirmMode: ConfirmMode;
	private currentRowIndex: number;
	private mnu: Menu | null;
	private rows: list<Row>;
	private slug: string;

	constructor(slug: string, opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(slug: string, opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(slug: string, tagName: TagName, parent?: El | null);
	constructor(slug: string, root: Element | null, parent?: El | null);
	constructor(slug: string, opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(slug: string, opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(slug: string, opts: Partial<ElOpts>, parent?: El | null);
	constructor(slug: string, opts?: Partial<ElOpts>);
	constructor(slug: string, root?: Element | null);
	constructor(slug: string, tagName?: TagName);
	constructor(slug: string, parent?: El | null);
	constructor(slug: string, a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--column',
			...classNames,
		];
		super(opts);
		this.slug = slug;
		this.mnu = null;
		this.currentConfirmMode = ConfirmMode.NoMode;
		this.currentRowIndex = -1;
		this.addBtn = new Button({
			leadingIcon: 'add',
			text: 'Add URL',
		});
		this.addBtn.setVisible(window.isProducer);
		this.appendChild(this.addBtn);
		this.addBtn.onEvt(this.addButtonEvt);
		this.confirm = null;
		this.rows = new list<Row>();
		this.init();
	}

	@bind
	private addButtonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof ButtonClickEvt)) {
			const obj = this.addRow();
			if (obj) {
				obj.setMode(RowMode.ReadWrite);
			}
		}
	}

	addProjectURL(obj: IProjectURL): void {
		this.addRow(obj);
	}

	addRow(data?: IProjectURL): Row | null {
		if (this.currentRowIndex >= 0) {
			// Currently confirming another row
			return null;
		}
		const row = new Row(this, {data, parent: this});
		this.rows.append(row);
		this.addBtn.setText('Add another URL');
		this.addBtn.addClass('align-self--flex-end');
		return row;
	}

	closeMenu(): void {
		this.destroyMenu();
	}

	async confirmDelete(rowIndex: number): Promise<void> {
		if (this.currentRowIndex >= 0) {
			// Currently confirming another row
			return;
		}
		this.destroyConfirm();
		if ((rowIndex < 0) || (rowIndex >= this.rows.size())) {
			return;
		}
		const row = this.rows.at(rowIndex);
		if (!row.data) {
			return;
		}
		try {
			await svc.project.urlObject(this.slug, row.data.id);
		} catch {
			// Likely no longer exists
			return;
		}
		this.currentConfirmMode = ConfirmMode.Delete;
		this.currentRowIndex = rowIndex;
		// Hide row
		// Insert confirm delete into same index as row
		row.hide();
		this.confirm = new ConfirmAction(this);
		this.confirm.setText('Really delete?');
		// Add 1 to position "in front of" this row's position.
		this.insertChild(rowIndex + 1, this.confirm);
	}

	async confirmRelease(rowIndex: number): Promise<void> {
		if (this.currentRowIndex >= 0) {
			// Currently confirming another row
			return;
		}
		this.destroyConfirm();
		if ((rowIndex < 0) || (rowIndex >= this.rows.size())) {
			return;
		}
		const row = this.rows.at(rowIndex);
		if (!row.data) {
			return;
		}
		try {
			const data = await svc.project.urlObject(this.slug, row.data.id);
			if (data.state === ProjectURLState.Released) {
				return;
			}
		} catch {
			// Likely doesn't exist or server error
			return;
		}
		let mediaEmailSent = false;
		const objs = await svc.project.emailList(this.slug);
		for (const summ of objs) {
			if (summ.emailType === ProjectEmailType.Gallery) {
				mediaEmailSent = summ.wasSent;
				break;
			}
		}
		this.currentConfirmMode = ConfirmMode.Release;
		this.currentRowIndex = rowIndex;
		row.hide();
		this.confirm = new ConfirmAction(this);
		const ps: Array<string> = [
			'Make link available to Client on Photobutton?',
		];
		if (mediaEmailSent) {
			ps.push('(use Send Gallery in Options to also send email)');
		}
		this.confirm.setText(ps.join(' '));
		// Add 1 to position "in front of" this row's position.
		this.insertChild(rowIndex + 1, this.confirm);
	}

	// async confirmationResult(affirm: boolean): Promise<void> {
	// 	this.destroyConfirm();
	// 	if ((this.currentRowIndex >= 0) && (this.currentRowIndex < this.rows.size())) {
	// 		const row = this.rows.at(this.currentRowIndex);
	// 		switch (this.currentConfirmMode) {
	// 			case ConfirmMode.Message: {
	// 				// Only displaying a message to the user; nothing more to
	// 				// be done.
	// 				row.show();
	// 				break;
	// 			}
	// 			case ConfirmMode.Delete: {
	// 				if (affirm) {
	// 					if (row.data) {
	// 						await this.deleteUrl(row.data);
	// 					}
	// 					this.removeRow(this.rowIndex(row));
	// 				} else {
	// 					row.show();
	// 				}
	// 				break;
	// 			}
	// 			case ConfirmMode.Release: {
	// 				row.show();
	// 				if (affirm && row.data) {
	// 					const data = await this.updateUrl({
	// 						...row.data,
	// 						state: ProjectURLState.Released,
	// 					});
	// 					row.setData(data);
	// 				}
	// 				break;
	// 			}
	// 			default: {
	// 				console.log('confirmationResult: invalid confirm mode.');
	// 				break;
	// 			}
	// 		}
	// 	}
	// 	this.currentConfirmMode = ConfirmMode.NoMode;
	// 	this.currentRowIndex = -1;
	// }

	async confirmationResult(affirm: boolean): Promise<void> {
		this.destroyConfirm();
		if ((this.currentRowIndex >= 0) && (this.currentRowIndex < this.rows.size())) {
			const row = this.rows.at(this.currentRowIndex);
			switch (this.currentConfirmMode) {
				case ConfirmMode.Message: {
					// Only displaying a message to the user; nothing more to
					// be done.
					row.show();
					break;
				}
				case ConfirmMode.Delete: {
					if (affirm) {
						if (row.data) {
							await this.deleteUrl(row.data);
						}
						this.removeRow(this.rowIndex(row));
					} else {
						row.show();
					}
					break;
				}
				case ConfirmMode.Release: {
					if (affirm && row.data) {
						let mediaEmailSent = false;
						const objs = await svc.project.emailList(this.slug);
						for (const summ of objs) {
							if (summ.emailType === ProjectEmailType.Gallery) {
								mediaEmailSent = summ.wasSent;
								break;
							}
						}
						if (!mediaEmailSent) {
							// Ask to send email now.
							this.currentConfirmMode = ConfirmMode.DispatchEmail;
							this.confirm = new ConfirmAction(this);
							this.confirm.setText('Shall an email be sent to the client?');
							this.insertChild(this.currentRowIndex + 1, this.confirm);
							return;
						}
						const data = await this.updateUrl({
							...row.data,
							state: ProjectURLState.Released,
							dispatchEmail: false,
						});
						row.setData(data);
					}
					row.show();
					break;
				}
				case ConfirmMode.DispatchEmail: {
					if (row.data) {
						const data = await this.updateUrl({
							...row.data,
							state: ProjectURLState.Released,
							dispatchEmail: affirm,
						});
						row.setData(data);
					}
					row.show();
					break;
				}
				default: {
					console.log('confirmationResult: invalid confirm mode.');
					break;
				}
			}
		}
		this.currentConfirmMode = ConfirmMode.NoMode;
		this.currentRowIndex = -1;
	}

	async createUrl(data: IProjectURL): Promise<IProjectURL> {
		return await svc.project.createUrl(this.slug, data);
	}

	async deleteUrl(data: IProjectURL): Promise<void> {
		return await svc.project.deleteUrl(this.slug, data.id);
	}

	destroy(): void {
		this.destroyMenu();
		this.destroyConfirm();
		this.addBtn.offEvt(this.addButtonEvt);
		this.addBtn.destroy();
		for (const obj of this.rows) {
			obj.destroy();
		}
		this.currentRowIndex = -1;
		super.destroy();
	}

	private destroyConfirm(): void {
		if (this.confirm) {
			this.confirm.destroy();
		}
		this.confirm = null;
	}

	private destroyMenu(): void {
		if (this.mnu) {
			this.mnu.offEvt(this.menuEvt);
			this.mnu.destroy();
		}
		this.mnu = null;
	}

	displayRowMessage(rowIndex: number, message: string): void {
		if (this.currentRowIndex >= 0) {
			// Currently confirming another row
			return;
		}
		this.destroyConfirm();
		if ((rowIndex < 0) || (rowIndex >= this.rows.size())) {
			return;
		}
		const row = this.rows.at(rowIndex);
		this.currentConfirmMode = ConfirmMode.Message;
		this.currentRowIndex = rowIndex;
		row.hide();
		this.confirm = new ConfirmAction(this, {onlyOK: true});
		this.confirm.setText(message);
		// Add 1 to position "in front of" this row's position.
		this.insertChild(rowIndex + 1, this.confirm);
	}

	async fetchProject(): Promise<IProject> {
		return await svc.project.get(this.slug);
	}

	private async init(): Promise<void> {
		const objs = await svc.project.urlList(this.slug);
		if (objs.length > 0) {
			for (const obj of objs) {
				this.addProjectURL(obj);
			}
		} else {
			if (!window.isProducer) {
				const el = new El({
					classNames: [
						'display--flex',
						'flex-direction--row',
						'justify-content--center',
						'align-items--center',
					],
					parent: this,
					tagName: 'div',
				});
				el.setText('Nothing yet, but watch this space');
			}
		}
	}

	menu(): Menu {
		this.destroyMenu();
		this.mnu = new Menu();
		this.mnu.onEvt(this.menuEvt);
		return this.mnu;
	}

	@bind
	private menuEvt(evt: Evt): void {
		if (evt instanceof MenuStateChangeEvt) {
			if (evt.closed()) {
				this.destroyMenu();
			}
		}
	}

	async projectChanged(): Promise<void> {
		for (const obj of this.rows) {
			if (obj.data) {
				obj.setData(await svc.project.urlObject(this.slug, obj.data.id));
			}
		}
	}

	removeRow(index: number): void {
		if ((index >= 0) && (index < this.rows.size())) {
			this.rows.takeAt(index).destroy();
		}
		if (this.rows.isEmpty()) {
			this.addBtn.setText('Add URL');
			this.addBtn.removeClass('align-self--flex-end');
		}
	}

	rowIndex(row: Row): number {
		return this.rows.indexOf(row);
	}

	async setReadyForRelease(rowIndex: number, ready: boolean): Promise<void> {
		if ((rowIndex >= 0) && (rowIndex < this.rows.size())) {
			const row = this.rows.at(rowIndex);
			let data: IProjectURL | null = null;
			if (row.data) {
				const obj = await svc.project.urlObject(
					this.slug,
					row.data.id);
				data = await this.updateUrl({
					...obj,
					state: ready ?
						ProjectURLState.ReadyForRelease :
						ProjectURLState.Draft,
				});
			}
			row.setData(data);
		}
	}

	async updateUrl(data: IProjectURL): Promise<IProjectURL> {
		return await svc.project.updateUrl(this.slug, data);
	}
}

interface RowOpts extends ElOpts {
	data: IProjectURL;
}

enum RowOption {
	Edit = 'Edit',
	CancelEdit = 'Cancel edit',
	Delete = 'Delete',
	ReadyForRelease = 'Ready for release',
	NotReadyForRelease = 'Not ready for release',
	Release = 'Release',
	UnRelease = 'Un-Release',
}

enum RowMode {
	NoMode,
	ReadOnly,
	ReadWrite,
}

@OBJ
export class Row extends El {
	private currentMenuItems: Array<RowOption>;
	data: IProjectURL | null;
	private labelEl: El;
	private labelInput: TextInput;
	private leadingIcon: El;
	private menuButton: IconButton;
	private mid: El;
	private mode: RowMode;
	private saveButton: IconButton;
	private urlEl: El;
	private urlInput: TextInput;
	private view: ProjectURLView | null;

	constructor(view: ProjectURLView, opts: Partial<RowOpts> | null, tagName: TagName, parent?: El | null);
	constructor(view: ProjectURLView, opts: Partial<RowOpts> | null, root: Element | null, parent?: El | null);
	constructor(view: ProjectURLView, tagName: TagName, parent?: El | null);
	constructor(view: ProjectURLView, root: Element | null, parent?: El | null);
	constructor(view: ProjectURLView, opts: Partial<RowOpts> | null, tagName?: TagName);
	constructor(view: ProjectURLView, opts: Partial<RowOpts> | null, root?: Element | null);
	constructor(view: ProjectURLView, opts: Partial<RowOpts>, parent?: El | null);
	constructor(view: ProjectURLView, opts?: Partial<RowOpts>);
	constructor(view: ProjectURLView, root?: Element | null);
	constructor(view: ProjectURLView, tagName?: TagName);
	constructor(view: ProjectURLView, parent?: El | null);
	constructor(view: ProjectURLView, a?: Partial<RowOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<RowOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'pb-project-url-row',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.currentMenuItems = [];
		this.data = null;
		this.mode = RowMode.NoMode;
		this.view = view;
		this.leadingIcon = new El({
			classNames: [
				'material-icons',
				'leading-icon',
				'deactivated',
			],
			tagName: 'i',
		});
		this.leadingIcon.setVisible(window.isProducer);
		this.appendChild(this.leadingIcon);
		this.leadingIcon.setText('circle');
		this.mid = new El({
			classNames: [
				'lb-mid',
			],
			parent: this,
			tagName: 'div',
		});
		if (window.isProducer) {
			this.mid.addEventListener('dblclick', this.domEvent);
		}
		this.labelEl = new El({
			classNames: [
				'text-repr',
				'leading-detail',
			],
			tagName: 'div',
		});
		this.labelEl.hide();
		this.mid.appendChild(this.labelEl);
		this.labelInput = new TextInput({
			classNames: [
				'pb-text-input--underlined-less-dense-no-padding',
				'leading-detail',
			],
			labelText: 'Label',
		});
		this.labelInput.hide();
		if (window.isProducer) {
			this.labelInput.onEvt(this.textInputEvt);
		}
		this.mid.appendChild(this.labelInput);
		const sep = new El({
			// parent: this,
			parent: this.mid,
			tagName: 'div',
		});
		sep.setText(String.fromCharCode(NBSP_CHAR_CODE));
		this.urlEl = new El({
			classNames: [
				'text-repr',
			],
			tagName: 'div',
		});
		this.urlEl.hide();
		this.mid.appendChild(this.urlEl);
		this.urlInput = new TextInput({
			classNames: [
				'pb-text-input--underlined-less-dense-no-padding',
				'flex-grow--1',
			],
			labelText: 'URL',
		});
		this.urlInput.hide();
		this.mid.appendChild(this.urlInput);
		if (window.isProducer) {
			this.urlInput.onEvt(this.textInputEvt);
		}
		this.saveButton = new IconButton({
			classNames: [
				'pb-smaller-tool-button',
				'save-button',
				'visibility--hidden',
			],
			iconName: 'save',
			smaller: true,
		});
		const trailingCont = new El({
			classNames: [
				'trailing-detail',
			],
			parent: this,
			tagName: 'div',
		});
		this.appendChild(trailingCont);
		if (window.isProducer) {
			this.saveButton.onEvt(this.saveButtonEvt);
		}
		trailingCont.appendChild(this.saveButton);
		this.menuButton = new IconButton({
			classNames: [
				'pb-smaller-tool-button',
			],
			iconName: 'more_vert',
			smaller: true,
		});
		this.menuButton.setVisible(window.isProducer);
		trailingCont.appendChild(this.menuButton);
		if (window.isProducer) {
			this.menuButton.onEvt(this.menuButtonEvt);
		}
		this.setMode(RowMode.ReadOnly);
		if (opts.data) {
			this.setData(opts.data);
		}
		if (window.isProducer) {
			this.addEventListener('keydown', this.domEvent);
		}
	}

	private cancelEdit(): void {
		this.setData(this.data);
		this.setMode(RowMode.ReadOnly);
	}

	destroy(): void {
		this.currentMenuItems = [];
		this.removeEventListener('keydown', this.domEvent);
		this.leadingIcon.destroy();
		this.labelEl.destroy();
		this.labelInput.offEvt(this.textInputEvt);
		this.labelInput.destroy();
		this.urlEl.destroy();
		this.urlInput.offEvt(this.textInputEvt);
		this.urlInput.destroy();
		this.mid.removeEventListener('dblclick', this.domEvent);
		this.mid.destroy();
		this.saveButton.offEvt(this.saveButtonEvt);
		this.saveButton.destroy();
		this.menuButton.destroy();
		this.data = null;
		this.view = null;
		super.destroy();
	}

	private domDoubleClickEvent(event: MouseEvent): void {
		if ((this.mode === RowMode.ReadOnly) && event.target && (event.target instanceof Element)) {
			const el = closestMatchingElement(event.target, '.text-repr');
			if (el && (el === this.labelEl.element())) {
				this.setMode(RowMode.ReadWrite);
			} else {
				this.setMode(RowMode.ReadWrite, 'url');
			}
		}
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'keydown':
				this.domKeyDownEvent(<KeyboardEvent>event);
				break;
			case 'dblclick':
				this.domDoubleClickEvent(<MouseEvent>event);
				break;
		}
	}

	private domKeyDownEvent(event: KeyboardEvent): void {
		if (window.isProducer && (this.mode === RowMode.ReadWrite)) {
			switch (event.key) {
				case 'Enter':
					this.saveData();
					break;
				case 'Escape':
					this.cancelEdit();
					break;
			}
		}
	}

	private hasDataChanged(): boolean {
		if (this.data) {
			return !((this.labelValue() === this.data.label) && (this.urlInput.value() === this.data.url));
		}
		return true;
	}

	labelValue(): string {
		return this.labelInput.value();
	}

	@bind
	private menuButtonEvt(evt: Evt): void {
		if (window.isProducer && this.view && (evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			const menu = this.view.menu();
			menu.anchorToBody(evt.x(), evt.y());
			menu.onEvt(this.menuEvt);
			this.currentMenuItems = this.menuOptions();
			menu.addItems(this.currentMenuItems);
			menu.open();
		}
	}

	@bind
	private async menuEvt(evt: Evt): Promise<void> {
		if (evt instanceof ListItemSelectEvt) {
			const index = evt.index();
			if ((index >= 0) && (index < this.currentMenuItems.length)) {
				switch (this.currentMenuItems[index]) {
					case RowOption.Edit:
						this.setMode(RowMode.ReadWrite);
						break;
					case RowOption.CancelEdit:
						this.cancelEdit();
						break;
					case RowOption.Delete:
						if (this.view) {
							if (this.data) {
								if (this.data.state === ProjectURLState.Released) {
									this.view.displayRowMessage(
										this.view.rowIndex(this),
										'Please un-release before deleting');
								} else {
									await this.view.confirmDelete(this.view.rowIndex(this));
								}
							} else {
								this.view.removeRow(this.view.rowIndex(this));
							}
						}
						break;
					case RowOption.ReadyForRelease:
						if (this.view) {
							const proj = await this.view.fetchProject();
							if (proj.finalized) {
								this.view.displayRowMessage(this.view.rowIndex(this), 'The project has been finalized. Please choose the "Release" option.');
							} else {
								if (this.urlValue().trim().length > 0) {
									await this.view.setReadyForRelease(this.view.rowIndex(this), true);
								} else {
									this.view.displayRowMessage(this.view.rowIndex(this), 'URL is missing');
								}
							}
						}
						break;
					case RowOption.NotReadyForRelease:
						if (this.view) {
							await this.view.setReadyForRelease(this.view.rowIndex(this), false);
						}
						break;
					case RowOption.Release:
						if (this.view) {
							if (this.urlValue().trim().length > 0) {
								await this.view.confirmRelease(this.view.rowIndex(this));
							} else {
								this.view.displayRowMessage(this.view.rowIndex(this), 'URL is missing');
							}
						}
						break;
					case RowOption.UnRelease:
						if (this.data && this.view) {
							this.setData(await this.view.updateUrl({
								...this.data,
								state: ProjectURLState.Draft,
							}));
						}
						break;
				}
			}
		}
	}

	private menuOptions(): Array<RowOption> {
		const rv: Array<RowOption> = [];
		if (this.mode === RowMode.ReadWrite) {
			rv.push(RowOption.CancelEdit);
		} else if (this.mode === RowMode.ReadOnly) {
			rv.push(RowOption.Edit);
			if (this.data) {
				if (this.data.state === ProjectURLState.ReadyForRelease) {
					rv.push(RowOption.NotReadyForRelease);
				} else {
					if (this.data.state !== ProjectURLState.Released) {
						rv.push(RowOption.ReadyForRelease);
					}
				}
				if (this.data.state === ProjectURLState.Released) {
					rv.push(RowOption.UnRelease);
				} else {
					rv.push(RowOption.Release);
				}
			}
			rv.push(RowOption.Delete);
		}
		return rv;
	}

	@bind
	private async saveButtonEvt(evt: Evt): Promise<void> {
		if (window.isProducer && (this.mode === RowMode.ReadWrite) && this.view && (evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			await this.saveData();
		}
	}

	private async saveData(): Promise<void> {
		if (window.isProducer && (this.mode === RowMode.ReadWrite) && this.view) {
			let data: IProjectURL | null = null;
			const label = this.labelValue().trim();
			const url = this.urlValue().trim();
			if (this.data) {
				data = await this.view.updateUrl({
					...this.data,
					label,
					url,
				});
			} else {
				if ((label.length > 0) || (url.length > 0)) {
					data = await this.view.createUrl({
						id: -1,
						label,
						projectId: -1,
						state: ProjectURLState.Draft,
						url,
						additionalEmailText: '',
						dispatchEmail: false,
					});
				}
			}
			this.setData(data);
			this.setMode(RowMode.ReadOnly);
		}
	}

	setData(data: IProjectURL | null): void {
		this.data = data;
		this.setUrlElData(this.data ? this.data.url : '');
		if (this.data) {
			const ready = this.data.state === ProjectURLState.ReadyForRelease;
			const released = this.data.state === ProjectURLState.Released;
			this.leadingIcon.setClass(released, 'activated');
			this.leadingIcon.setClass(ready, 'ready');
			this.leadingIcon.setClass(!(ready || released), 'deactivated');
			this.leadingIcon.setTitle(released ?
				'URL is released' :
				ready ?
					'URL is ready for release' :
					'URL is not released');
			if (this.data.label.trim().length > 0) {
				this.labelEl.setText(this.data.label);
				this.labelEl.removeClass('no-value');
			} else {
				this.labelEl.addClass('no-value');
				this.labelEl.setText('No label');
			}
			this.labelInput.setValue(this.data.label);
			this.urlInput.setValue(this.data.url);
		} else {
			this.leadingIcon.addClass('deactivated');
			this.leadingIcon.removeClass('ready');
			this.leadingIcon.removeClass('activated');
			this.leadingIcon.setTitle('URL is not released');
			this.labelEl.addClass('no-value');
			this.labelEl.setText('No label');
			this.labelInput.setValue('');
			this.urlInput.setValue('');
		}
	}

	setMode(mode: RowMode, inputToFocus: 'label' | 'url' = 'label'): void {
		if (mode === this.mode) {
			return;
		}
		if (!window.isProducer && (mode !== RowMode.ReadOnly)) {
			console.log('setMode: Invalid mode');
			return this.setMode(RowMode.ReadOnly);
		}
		this.mode = mode;
		switch (this.mode) {
			case RowMode.ReadOnly:
				this.labelInput.hide();
				this.labelEl.show();
				this.urlInput.hide();
				this.urlEl.show();
				this.saveButton.addClass('visibility--hidden');
				break;
			case RowMode.ReadWrite:
				this.labelEl.hide();
				this.labelInput.show();
				this.urlEl.hide();
				this.urlInput.show();
				this.saveButton.removeClass('visibility--hidden');
				this.saveButton.setDisabled(true);
				switch (inputToFocus) {
					case 'label':
						this.labelInput.focus();
						break;
					case 'url':
						this.urlInput.focus();
						break;
					default:
						console.log('Invalid input kind');
						break;
				}
				break;
			default:
				console.log('setMode: Got invalid mode.');
				return this.setMode(RowMode.ReadOnly);
		}
	}

	setUrlElData(data: string): void {
		if (data.trim().length > 0) {
			if (!this.urlEl.isA('a')) {
				this.urlEl.destroy();
				this.urlEl = new El({
					classNames: [
						'text-repr',
					],
					tagName: 'a',
				});
				// this.insertChild(4, this.urlEl);
				this.mid.insertChild(2, this.urlEl);
			}
			this.urlEl.removeClass('no-value');
			this.urlEl.setHref(data, {text: data, target: 'blank'});
		} else {
			if (this.urlEl.isA('a')) {
				this.urlEl.destroy();
				this.urlEl = new El({
					classNames: [
						'text-repr',
					],
					tagName: 'div',
				});
				// this.insertChild(4, this.urlEl);
				this.mid.insertChild(2, this.urlEl);
			}
			this.urlEl.addClass('no-value');
			this.urlEl.setText('No URL');
		}
	}

	@bind
	private textInputEvt(evt: Evt): void {
		if ((evt.type() === Evt.Input) && (evt instanceof TextInputEvt)) {
			this.saveButton.setEnabled(this.hasDataChanged());
		}
	}

	urlValue(): string {
		return this.urlInput.value();
	}
}

interface ConfirmActionOpts extends ElOpts {
	onlyOK: boolean;
}

@OBJ
export class ConfirmAction extends El {
	private cancelBtn: Button;
	private txt: El;
	private view: ProjectURLView | null;
	private yesBtn: Button;

	constructor(view: ProjectURLView, opts: Partial<ConfirmActionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(view: ProjectURLView, opts: Partial<ConfirmActionOpts> | null, root: Element | null, parent?: El | null);
	constructor(view: ProjectURLView, tagName: TagName, parent?: El | null);
	constructor(view: ProjectURLView, root: Element | null, parent?: El | null);
	constructor(view: ProjectURLView, opts: Partial<ConfirmActionOpts> | null, tagName?: TagName);
	constructor(view: ProjectURLView, opts: Partial<ConfirmActionOpts> | null, root?: Element | null);
	constructor(view: ProjectURLView, opts: Partial<ConfirmActionOpts>, parent?: El | null);
	constructor(view: ProjectURLView, opts?: Partial<ConfirmActionOpts>);
	constructor(view: ProjectURLView, root?: Element | null);
	constructor(view: ProjectURLView, tagName?: TagName);
	constructor(view: ProjectURLView, parent?: El | null);
	constructor(view: ProjectURLView, a?: Partial<ConfirmActionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ConfirmActionOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'pb-project-url-confirm-row',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.view = view;
		this.txt = new El({
			classNames: [
				'mdc-typography',
				'mdc-typography--body1',
				'message-text',
			],
			parent: this,
			tagName: 'div',
		});
		this.cancelBtn = new Button({
			classNames: [
				'pb-shorter-button',
			],
			unelevated: true,
			text: 'No!',
		});
		this.cancelBtn.setVisible(!opts.onlyOK);
		this.appendChild(this.cancelBtn);
		if (!opts.onlyOK) {
			this.cancelBtn.onEvt(this.rejectButtonEvt);
		}
		this.yesBtn = new Button({
			classNames: [
				'pb-shorter-button',
			],
			parent: this,
			text: opts.onlyOK ? 'OK' : 'Yes',
			unelevated: opts.onlyOK,
		});
		this.yesBtn.onEvt(this.acceptButtonEvt);
		this.addEventListener('keydown', this.domEvent);
		setTimeout(() => {
			if (opts.onlyOK) {
				this.yesBtn.focus();
			} else {
				this.cancelBtn.focus();
			}
		}, 100);
	}

	@bind
	private acceptButtonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof ButtonClickEvt) && this.view) {
			this.view.confirmationResult(true);
		}
	}

	destroy(): void {
		this.removeEventListener('keydown', this.domEvent);
		this.cancelBtn.offEvt(this.rejectButtonEvt);
		this.yesBtn.offEvt(this.acceptButtonEvt);
		this.view = null;
		super.destroy();
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'keydown':
				this.domKeyDownEvent(<KeyboardEvent>event);
				break;
		}
	}

	private domKeyDownEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Escape':
				if (this.view) {
					this.view.confirmationResult(false);
				}
				break;
		}
	}

	@bind
	private rejectButtonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof ButtonClickEvt) && this.view) {
			this.view.confirmationResult(false);
		}
	}

	setText(text?: string | null): void {
		this.txt.setText(text);
	}

	text(): string {
		return this.txt.text();
	}

}

import {isNumber} from '../util';

export function commonDateTimeParams<T extends Partial<ICommonDateTimeRequest>>(opts?: T): any {
	if (opts) {
		const o = Object.assign<object, T | undefined>({}, opts);
		const rv: any = {};
		if (isNumber(o.dateCount)) {
			rv['n'] = o.dateCount;
		}
		if (o.fromDate) {
			rv['f'] = o.fromDate;
		}
		if (o.toDate) {
			rv['t'] = o.toDate;
		}
		return rv;
	}
	return undefined;
}

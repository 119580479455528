import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const middleware = window.pbenablelogger ?
	[thunk, createLogger({collapsed: true})] :
	[thunk];

export default createStore(rootReducer, applyMiddleware(...middleware));

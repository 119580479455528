import {MDCDrawer} from '@material/drawer';

let _drawer: MDCDrawer | null = null;

export function drawer(): MDCDrawer | null {
	if (!_drawer) {
		const elem = document.getElementById('primary-drawer');
		if (elem) {
			_drawer = new MDCDrawer(elem);
		}
	}
	return _drawer;
}

export function init(): void {
	drawer();
}

import React from 'react';

import {
	Icon,
	Input,
	Select,
	Confirm,
} from '../../components';
import {bind} from '../../../util';

interface IProps {
	itemOptions: {value: number, text: string;}[];
	lineItem: ILineItem;
	onChange: (id: number, data: Partial<ILineItem>) => any;
	onDelete: (id: number) => any;
	onSave: (id: number) => any;
}

type Props = React.PropsWithChildren<IProps>;
type State = {initial: ILineItem; saving: boolean; showConfirm: boolean;};

export default class LineItem extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			initial: {
				...props.lineItem,
			},
			saving: false,
			showConfirm: false,
		};
	}

	@bind
	changeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
		const field = event.target.name;
		let value: string | number | boolean = event.target.value;
		if (field === 'itemId') {
			value = Number.parseInt(value);
			if (Number.isNaN(value)) {
				return;
			}
		}
		if ((event.target.type === 'checkbox') && ('checked' in event.target)) {
			value = event.target.checked;
		}
		const {lineItem, onChange} = this.props;
		onChange(lineItem.id, {[field]: value});
	}

	componentDidUpdate(): void {
		const {saving} = this.state;
		if (saving) {
			this.setState({initial: {...this.props.lineItem}, saving: false});
		}
	}

	@bind
	confirmClicked(result: boolean): void {
		if (result) {
			this.delete();
		}
	}

	delete(): void {
		const {lineItem, onDelete} = this.props;
		onDelete(lineItem.id);
	}

	@bind
	deleteClicked(): void {
		this.setState({showConfirm: true});
	}

	hasChanged(): boolean {
		const {lineItem} = this.props;
		const {initial} = this.state;
		return !(
			(lineItem.id === initial.id)
			&& (lineItem.projectId === initial.projectId)
			&& (lineItem.itemId === initial.itemId)
			&& (lineItem.amount.trim() === initial.amount.trim())
			&& (lineItem.description.trim() === initial.description.trim())
			&& (lineItem.name.trim() === initial.name.trim())
			&& (lineItem.taxable === initial.taxable)
		);
	}

	isValid(): boolean {
		return this.props.lineItem.amount.trim().length > 0;
	}

	render(): React.ReactNode {
		const {
			itemOptions,
			lineItem: {amount, description, itemId, name, taxable},
		} = this.props;
		const {saving, showConfirm} = this.state;
		if (!saving && showConfirm) {
			return (
				<tr>
					<td colSpan={6}>
						<Confirm onClick={this.confirmClicked}>
							<span className="font-weight--bolder"><span className="color--danger">DELETE</span> {name}</span>?
						</Confirm>
					</td>
				</tr>
			);
		}
		const changed = this.hasChanged();
		const valid = this.isValid();
		const saveEnabled = changed && valid;
		return (
			<tr>
				<td>
					<label>
						<Select
							disabled={saving}
							name="itemId"
							nullOption={true}
							onChange={this.changeEvent}
							options={itemOptions}
							value={itemId || ''}/>
					</label>
				</td>
				<td>
					<label>
						<Input
							className="width--100-percent"
							disabled={saving}
							onChange={this.changeEvent}
							name="name"
							type="text"
							value={name}/>
					</label>
				</td>
				<td>
					<label>
						<Input
							className="width--100-percent"
							disabled={saving}
							onChange={this.changeEvent}
							name="description"
							type="text"
							value={description}/>
					</label>
				</td>
				<td>
					<label>
						<Input
							className="text-align--right width--96px"
							disabled={saving}
							onChange={this.changeEvent}
							name="amount"
							type="text"
							value={amount}/>
					</label>
				</td>
				<td>
					<label>
						<Input
							className="width--100-percent"
							disabled={saving}
							onChange={this.changeEvent}
							name="taxable"
							type="checkbox"
							checked={taxable}
						/>
					</label>
				</td>
				<td className="text-align--center">
					<button className="mdc-button" disabled={!saveEnabled} onClick={this.saveClicked} tabIndex={-1} title="Save Changes" type="button">
						<Icon middleAlign={true} name="save"/>
					</button>
				</td>
				<td className="text-align--center">
					<button className="mdc-button" disabled={saving} onClick={this.deleteClicked} tabIndex={-1} title="Delete Line Item" type="button">
						<Icon className="color--danger" middleAlign={true} name="delete"/>
					</button>
				</td>
			</tr>
		);
	}

	@bind
	saveClicked(): void {
		const {lineItem, onSave} = this.props;
		this.setState({saving: true});
		onSave(lineItem.id);
	}
}

import {MDCTopAppBar} from '@material/top-app-bar';

import {drawer} from './drawer';

export function init(): void {
	const elem = document.querySelector('.mdc-top-app-bar');
	if (elem) {
		const topBar = new MDCTopAppBar(elem);
		topBar.listen('MDCTopAppBar:nav', navEvent);
	}
}

function navEvent(): void {
	const d = drawer();
	if (d) {
		d.open = !d.open;
	}
}

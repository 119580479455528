import {ResourceService} from './resource';

export class ExclusiveClientUserTypeItemService extends ResourceService {
	static uri: string = '/api/group/catalog/exclusive-items/';

	async create(data: IExclusiveClientUserTypeItem, cfg?: ResourceDataRequestConfig): Promise<IExclusiveClientUserTypeItem> {
		return (await this.POST<IExclusiveClientUserTypeItem>(this.url(), data, cfg)).data;
	}

	async delete(id: number, cfg?: ResourceDataRequestConfig): Promise<void> {
		return (await this.DELETE<void>(this.url(id), undefined, cfg)).data;
	}

	async get(id: number, params?: any, cfg?: ResourceQueryRequestConfig): Promise<IExclusiveClientUserTypeItem> {
		return (await this.GET<IExclusiveClientUserTypeItem>(this.url(id), params, cfg)).data;
	}

	async list(params?: any, cfg?: ResourceQueryRequestConfig): Promise<IExclusiveClientUserTypeItem[]> {
		return (await this.GET<IExclusiveClientUserTypeItem[]>(this.url(), params, cfg)).data;
	}

	async update(id: number, data: IExclusiveClientUserTypeItem, cfg?: ResourceDataRequestConfig): Promise<IExclusiveClientUserTypeItem> {
		return (await this.PUT<IExclusiveClientUserTypeItem>(this.url(id), data, cfg)).data;
	}
}

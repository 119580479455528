import {isNumber} from '../util';
import {apiService as svc} from '../services';
import {El} from '../el';

export function init() {
	document.querySelectorAll('.mdc-switch input[type="checkbox"]')
		.forEach(node => node.addEventListener('change', changeEvent));
}

function changeEvent(event: Event): void {
	const elem = El.fromEvent(event);
	const idStr = elem.attribute('value');
	const id = idStr && Number.parseInt(idStr);
	if (isNumber(id)) {
		svc.account.emailNotification.update(id, {id, receiveNotifications: elem.isChecked()});
	} else {
		console.log('accountnotifications::changeEvent: Got invalid integer for object id');
	}
}

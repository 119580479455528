import React from 'react';

import {
	padEnd,
	isNumber,
	numberFormat,
	numberStringCmp,
	secondsToDuration,
} from '../../../../util';
import {
	DataTable,
	DataTableRow,
	DataTableBody,
	DataTableHead,
	DataTableCell,
	DataTableTable,
	DataTableColumn,
	DataTableContainer,
} from '../../../components';

interface IProps {
	objects: IItem[];
	objectPrices?: (id: number) => IPriceGroupItemPrice[];
	onClick?: (object: IItem) => any;
}

function formatDecimal(value: string | null): string {
	if (value) {
		const parts = value.trim().split('.', 2);
		const lhs = (parts[0].length) > 0 ? parts[0] : '0';
		let rhs = (parts.length === 2) ? parts[1] : '';
		if (rhs.length < 2) {
			rhs = padEnd(rhs, 2, '0');
		}
		return `${lhs}.${rhs}`;
	}
	return '';
}

export default class TableView extends React.Component<IProps, {}> {
	objectPrices(obj: IItem): IPriceGroupItemPrice[] | undefined {
		const {objectPrices} = this.props;
		if (objectPrices) {
			return objectPrices(obj.id);
		}
		return undefined;
	}

	render(): React.ReactNode {
		const {objects} = this.props;
		return (
			<DataTable className="width--100-percent">
				<DataTableContainer>
					<DataTableTable>
						<DataTableHead>
							<DataTableRow headerRow={true}>
								<DataTableColumn>
									Name
								</DataTableColumn>
								<DataTableColumn>
									Description
								</DataTableColumn>
								<DataTableColumn>
									Pricing
								</DataTableColumn>
								<DataTableColumn>
									Production Duration
								</DataTableColumn>
								<DataTableColumn>
									Post-Production Duration
								</DataTableColumn>
								<DataTableColumn numeric={true}>
									Size
								</DataTableColumn>
							</DataTableRow>
						</DataTableHead>
						<DataTableBody>
							{objects.map(obj =>
								<Row
									key={obj.id}
									obj={obj}
									onClick={this.rowClicked.bind(this, obj)}
									prices={this.objectPrices(obj)}/>)}
						</DataTableBody>
					</DataTableTable>
				</DataTableContainer>
			</DataTable>
		);
	}

	rowClicked(obj: IItem): void {
		const {onClick} = this.props;
		if (onClick) {
			onClick(obj);
		}
	}
}

function Name({value}: {value: string}) {
	return (value.trim().length > 0) ?
		<span>{value}</span> :
		<NoValue/>;
}

function Description({value}: {value: string}) {
	return (value.trim().length > 0) ?
		<span>{value}</span> :
		<NoValue/>;
}

function Duration({value}: {value: number | null}) {
	if (isNumber(value)) {
		const {days, hours, minutes} = secondsToDuration(value);
		const suffix = (num: number) => (num === 1) ? '' : 's';
		const parts: string[] = [];
		if (days > 0) {
			parts.push(`${days} day${suffix(days)}`);
		}
		if (hours > 0) {
			parts.push(`${hours} hour${suffix(hours)}`);
		}
		if (minutes > 0) {
			parts.push(`${minutes} minute${suffix(minutes)}`);
		}
		if (parts.length > 0) {
			return <span>{parts.join(' ')}</span>;
		}
	}
	return <NoValue/>;
}

function itemPriceSortKey(a: IPriceGroupItemPrice, b: IPriceGroupItemPrice): number {
	return numberStringCmp(a.price, b.price);
}

function Pricing({prices}: {prices?: IPriceGroupItemPrice[];}) {
	if (prices && (prices.length > 0)) {
		if (prices.length === 1) {
			const value = prices[0].price;
			if (value) {
				return <span>{`$${numberFormat(formatDecimal(value))}`}</span>;
			}
		}
		const sortedPrices = [...prices];
		sortedPrices.sort(itemPriceSortKey);
		const lowestValue = sortedPrices[0].price;
		const highestValue = sortedPrices[sortedPrices.length - 1].price;
		if (lowestValue && highestValue) {
			return <span>{`$${numberFormat(formatDecimal(lowestValue))} ~ $${numberFormat(formatDecimal(highestValue))}`}</span>;
		}
		if (lowestValue || highestValue) {
			return <span>{`$${numberFormat(formatDecimal(lowestValue || highestValue))}`}</span>;
		}
	}
	return <NoValue/>;
}

function Row({obj, onClick, prices}: {obj: IItem; onClick: () => any; prices?: IPriceGroupItemPrice[]}) {
	return (
		<DataTableRow className="cursor--pointer" key={obj.id} onClick={onClick}>
			<DataTableCell>
				<Name value={obj.name}/>
			</DataTableCell>
			<DataTableCell>
				<Description value={obj.description}/>
			</DataTableCell>
			<DataTableCell>
				<Pricing prices={prices}/>
			</DataTableCell>
			<DataTableCell>
				<Duration value={obj.duration}/>
			</DataTableCell>
			<DataTableCell>
				<Duration value={obj.succeedingDuration}/>
			</DataTableCell>
			<DataTableCell numeric={true}>
				<Size value={obj.size}/>
			</DataTableCell>
		</DataTableRow>
	);
}

function Size({value}: {value: number | null}) {
	return isNumber(value) ?
		<span>{numberFormat(value)} ft<sup>2</sup></span> :
		<NoValue/>;
}

function NoValue() {
	return <span className="pb-muted">&mdash;</span>;
}

import {Form} from '../ui/form';
import {TextInput} from '../ui/textinput';
import {ButtonRole, StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {accountService as acct} from '../services/account';
import {Dialog} from '../ui/dialog';
import {Button} from '../ui/button';
import {El} from '../el';
import {HBoxLayout, VBoxLayout} from '../ui/layout';
import {bind, isNumber} from '../util';

class CreateForm extends Form {
	cardholderNameInput: TextInput;
	cardNumberInput: TextInput;
	cvcInput: TextInput;
	expMonthInput: TextInput;
	expYearInput: TextInput;

	constructor() {
		super();
		const rootVBox = new VBoxLayout(this);
		rootVBox.setSpacing(16);
		this.cardNumberInput = new TextInput({
			fullWidth: true,
			inputId: 'id_card-number-label',
			labelText: 'Card number',
			name: 'number',
			required: true,
		});
		this.cardNumberInput.autocomplete('cc-number');
		rootVBox.addEl(this.cardNumberInput);
		const hBox = new HBoxLayout(this);
		this.expMonthInput = new TextInput({
			inputId: 'id_exp-month-label',
			labelText: 'MM',
			name: 'expmonth',
			required: true,
		});
		this.expMonthInput.autocomplete('cc-exp-month');
		hBox.addEl(this.expMonthInput);
		const separator = El.span({parent:this, classNames:'pb-payment-method__exp-separator'});
		separator.setText('/');
		hBox.addSpacing(4);
		hBox.addEl(separator);
		hBox.addSpacing(4);
		this.expYearInput = new TextInput({
			inputId: 'id_exp-year-label',
			labelText: 'YY',
			name: 'expyear',
			required: true,
		});
		this.expYearInput.autocomplete('cc-exp-year');
		hBox.addEl(this.expYearInput);
		hBox.addSpacing(16);
		this.cvcInput = new TextInput({
			inputId: 'id_cvc-label',
			labelText: 'CVC',
			name: 'cvc',
			required: true,
		});
		this.cvcInput.autocomplete('cc-csc');
		hBox.addEl(this.cvcInput);
		rootVBox.addEl(hBox);
		this.cardholderNameInput = new TextInput({
			fullWidth: true,
			inputId: 'id_cardholder-name-label',
			labelText: 'Cardholder name',
			name: 'cardholder',
			required: true,
		});
		this.cardholderNameInput.autocomplete('cc-name');
		rootVBox.addEl(this.cardholderNameInput);
	}

	submitButton(): El {
		return El.fromSelector(`[form="${this.id()}"][type="submit"]`);
	}
}

class TokenForm extends Form {
	tokenInput: TextInput;

	constructor() {
		super();
		this.hide();
		this.setAction(window.pburls.account.paymentMethod.list);
		this.setMethod('POST');
		this.tokenInput = new TextInput();
		this.tokenInput.setName('token');
		this.tokenInput.setType('hidden');
		this.insertAdjacentElement('beforeend', this.tokenInput);
	}

	setToken(token: string): void {
		this.tokenInput.setValue(token);
	}
}

class Dia extends Dialog {
	theForm: Form;

	constructor(theForm: Form, theOtherForm: Form) {
		super();
		this.theForm = theForm;
		this.setContent(this.theForm);
		this.appendContent(theOtherForm);
	}

	// protected buttonClicked(button: Button): void {
	// 	const role = this.buttonRole(button);
	// 	if ((role === ButtonRole.RejectRole) || (role === ButtonRole.NoRole) || (role === ButtonRole.InvalidRole)) {
	// 		super.buttonClicked(button);
	// 	}
	// }
}

class PaymentMethod {
	static CREATE_CTA_SELECTOR = '#add-payment-method-button-id';
	static DELETE_SELECTOR = 'form[id^="payment-method-delete-form-"]';
	static OBJECT_SELECTOR: string = '.pb-payment-method';

	createForm: CreateForm;
	dia: Dia;
	tokenForm: TokenForm;

	constructor() {
		this.createForm = new CreateForm();
		this.tokenForm = new TokenForm();
		this.dia = new Dia(this.createForm, this.tokenForm);
	}

	init(): void {
		this.createForm.setId('id_payment-method-form');
		this.createForm.onSubmit(this._formSubmitEvent);
		let elem: Element | null = document.querySelector(PaymentMethod.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this._createCTAClickEvent);
		}
		document
			.querySelectorAll(PaymentMethod.DELETE_SELECTOR)
			.forEach(el => el.addEventListener('submit', this._deleteFormSubmitEvent));
		if (this._objectListIsEmpty()) {
			this.openCreateDialog();
		}
	}

	openCreateDialog(): void {
		this.createForm.cardNumberInput.setValue('');
		this.createForm.expMonthInput.setValue('');
		this.createForm.expYearInput.setValue('');
		this.createForm.cvcInput.setValue('');
		this.createForm.cardholderNameInput.setValue('');
		this.createForm.show();
		this.tokenForm.setToken('');
		this.dia.setTitle('Add a payment method');
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.createForm.id());
		}
		this.dia.open();
	}

	@bind
	private _createCTAClickEvent(): void {
		this.openCreateDialog();
	}

	@bind
	private async _deleteFormSubmitEvent(event: Event): Promise<void> {
		event.preventDefault();
		const form = El.fromEvent(event);
		const idInp = form.querySelector('input[type="hidden"][name="id"]');
		if (!idInp) {
			return;
		}
		const objID = idInp.value();
		const data = await acct.paymentMethod.get(objID);

		function dialogCallback(resultCode: number): void {
			if (resultCode === StandardButton.Yes) {
				form.submit();
			}
		}

		this.createForm.hide();
		this.dia.setTitle(`Delete ${data.display}?`);
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			noBtn.setRaised(true);
		}
		this.dia.open(dialogCallback);
	}

	@bind
	private async _formSubmitEvent(): Promise<void> {
		const btn = this.createForm.submitButton();
		btn.setDisabled(true);
		const btnLabel = btn.querySelector('.pb-button__label');
		if (btnLabel) {
			btnLabel.setText('Saving...');
		}
		const form = this.createForm;
		const cardNumber = form.cardNumberInput.value();
		const expMonth = Number.parseInt(form.expMonthInput.value());
		const expYear = Number.parseInt(form.expYearInput.value());
		const cvc = form.cvcInput.value();
		const cardholderName = form.cardholderNameInput.value();
		if (!isNumber(expMonth)) {
			throw new Error('Invalid expiration month');
		}
		if (!isNumber(expYear)) {
			throw new Error('Invalid expiration year');
		}
		const proc = await svc.etc.paymentProcessor.get();
		const publicKey = proc.publicKey;
		Stripe.setPublishableKey(publicKey);
		Stripe.card.createToken({
			number: cardNumber,
			exp_month: expMonth,
			exp_year: expYear,
			cvc: cvc,
			name: cardholderName,
		}, this._stripeResponse);
	}

	private _objectListIsEmpty(): boolean {
		return document.querySelectorAll(PaymentMethod.OBJECT_SELECTOR).length < 1;
	}

	@bind
	private _stripeResponse(_: number, response: stripe.StripeCardTokenResponse): void {
		const error = response.error;
		if (error) {
			console.log('Processor error %o', error);
			this.dia.setTitle('Processing Error');
			this.dia.setText(error.message);
			this.dia.setStandardButtons(StandardButton.Ok);
			this.dia.open();
		} else {
			this.tokenForm.setToken(response.id);
			this.tokenForm.submit();
		}
	}
}

export function init(): void {
	const comp = new PaymentMethod();
	comp.init();
}

import React from 'react';

import {Icon} from '../../../components';

interface IProps {
	onBackClick?: () => any;
	primaryText: string;
	secondaryText?: string;
	title?: string;
}

export default function Header(props: React.PropsWithChildren<IProps>) {
	const {children, onBackClick, primaryText, secondaryText, title} = props;
	return (
		<header className="create-view-header">
			{onBackClick ?
				<BackButton onClick={onBackClick} title={title}/> :
				null}
			<p id="create-view-header-title">
				<Primary>{primaryText}</Primary>{secondaryText ? ` \u2014 ${secondaryText}` : null}
			</p>
			{children}
		</header>
	);
}

function Primary({children}: React.PropsWithChildren<{}>) {
	return (
		<span className="font-weight--bolder">
            {children}
        </span>
	);
}

function BackButton({onClick, title}: React.PropsWithChildren<{onClick: () => any; title?: string;}>) {
	return (
		<Icon id="create-view-header-back-icon" name="keyboard_arrow_left" onClick={onClick} title={title}/>
	);
}

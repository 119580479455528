import React from 'react';

import DenseTextInput from './densetextinput';
import {Grid, GridCell, Slider} from '../../../components';
import {
	bind,
	clamp,
	isNumber,
	secondsToDuration,
} from '../../../../util';
import {
	SECONDS_IN_DAY,
	SECONDS_IN_HOUR,
	SECONDS_IN_MINUTE,
	SECONDS_IN_QUARTER_HOUR,
	ITEM_DURATION_MAX_SECONDS,
} from '../../../../constants';

interface IProps {
	value: number;
	// onChange: (value: number, name: 'duration') => any;
	onChange: (value: number) => any;
	renderTextInput?: boolean;
	textInputSpan?: GridCellSpan;
}

export default class DurationInput extends React.Component<IProps, {}> {
	// static Name: 'duration' = 'duration';

	calculateTotalSeconds(input: number, currentTotal: number, period: string): number {
		input = isNumber(input) ? input : 0;
		const dur = secondsToDuration(currentTotal);
		let rv: number = currentTotal;
		switch (period) {
			case 'days':
				rv -= (dur.days * SECONDS_IN_DAY);
				rv += (input * SECONDS_IN_DAY);
				break;
			case 'hours':
				rv -= (dur.hours * SECONDS_IN_HOUR);
				rv += (input * SECONDS_IN_HOUR);
				break;
			case 'minutes':
				rv -= (dur.minutes * SECONDS_IN_MINUTE);
				rv += (input * SECONDS_IN_MINUTE);
				break;
		}
		return rv;
	}

	maxDays(): number {
		return Math.floor(ITEM_DURATION_MAX_SECONDS / SECONDS_IN_DAY);
	}

	// name(): 'duration' {
	// 	return DurationInput.Name;
	// }

	notify(value: number): void {
		const {onChange} = this.props;
		// onChange(clamp(isNumber(value) ? value : 0, 0, ITEM_DURATION_MAX_SECONDS), this.name());
		onChange(clamp(isNumber(value) ? value : 0, 0, ITEM_DURATION_MAX_SECONDS));
	}

	render(): React.ReactNode {
		const {renderTextInput, textInputSpan, value} = this.props;
		const {days, hours, minutes} = secondsToDuration(value);
		const cellSpan = textInputSpan ? textInputSpan : 4;
		if (renderTextInput) {
			return (
				<Grid className="pb-layout-grid--no-margin-or-something">
					<GridCell phoneSpan={12} span={cellSpan} tabletSpan={12}>
						<DenseTextInput
							helpText="Days"
							label="Days"
							max={this.maxDays()}
							min={0}
							name="days"
							onChange={this.textInputChanged}
							persistHelpText={true}
							step={1}
							type="number"
							value={days}/>
					</GridCell>
					<GridCell phoneSpan={12} span={cellSpan} tabletSpan={12}>
						<DenseTextInput
							helpText="Hours"
							label="Hours"
							name="hours"
							onChange={this.textInputChanged}
							persistHelpText={true}
							step={1}
							type="number"
							value={hours}/>
					</GridCell>
					<GridCell phoneSpan={12} span={cellSpan} tabletSpan={12}>
						<DenseTextInput
							helpText="Minutes"
							label="Minutes"
							name="minutes"
							onChange={this.textInputChanged}
							persistHelpText={true}
							step={1}
							type="number"
							value={minutes}/>
					</GridCell>
				</Grid>
			);
		}
		return (
			<React.Fragment>
				<Slider max={ITEM_DURATION_MAX_SECONDS} min={0} onInput={this.sliderChanged} step={SECONDS_IN_QUARTER_HOUR} value={value}/>
				<div className="display--flex flex-direction--row">
					<SubLabel text="Day" value={days}/>
					<SubLabel text="Hour" value={hours}/>
					<SubLabel text="Minute" value={minutes}/>
				</div>
			</React.Fragment>
		);
	}

	@bind
	sliderChanged(value: number): void {
		this.notify(value);
	}

	@bind
	textInputChanged(event: React.ChangeEvent): void {
		this.notify(
			this.calculateTotalSeconds(
				Number.parseInt((event as React.ChangeEvent<HTMLInputElement>).target.value),
				this.props.value,
				(event as React.ChangeEvent<HTMLInputElement>).target.name));
	}
}

function SubLabel({pluralizedText, text, value}: {pluralizedText?: string; text: string; value: number;}) {
	text = (value === 1) ?
		text :
		pluralizedText ? text : `${text}s`;
	return (
		<div className="pb-catalog-item-form__subsection-input-label">
			<div>
				{value}
			</div>
			<div>
				{text}
			</div>
		</div>
	);
}

import {El, ElOpts, elOpts} from '../../el';
import {Checkbox} from '../checkbox';
import {bind, pixelString, testFlag} from '../../util';
import {StyleOption, ViewItemFeature} from './delegate';
import {list, Point} from '../../tools';
import {AlignmentFlag, ArrowType, CheckState, KeyboardModifier, MouseButton} from '../../constants';
import {Evt, MouseEvt} from '../../evt';

export enum TableElState {
	NoState,
	Anchor,
	Checkbox,
	Sort,
}

export enum TableElType {
	NoType,
	HeaderCell,
	HeaderRow,
	TableCell,
	TableRow,
}

const cssClassNames = {
	HeaderCell: {
		Checkbox: 'mdc-data-table__header-row-checkbox',
		Root: 'mdc-data-table__header-cell',
		RootCheckbox: 'mdc-data-table__header-cell--checkbox',
		RootNumeric: 'mdc-data-table__header-cell--numeric',
		RootSort: 'mdc-data-table__header-cell--with-sort',
		SortWrapper: 'mdc-data-table__header-cell-wrapper',
		SortWrapperButton: 'mdc-data-table__sort-icon-button',
		SortWrapperLabel: 'mdc-data-table__header-cell-label',
		SortWrapperStatusLabel: 'mdc-data-table__sort-status-label',
	},
	HeaderRow: {
		Root: 'mdc-data-table__header-row',
	},
	TableCell: {
		Anchor: 'pb-data-table__cell-anchor',
		Checkbox: 'mdc-data-table__row-checkbox',
		Root: 'mdc-data-table__cell',
		RootAnchor: 'pb-data-table__link-cell',
		RootCheckbox: 'mdc-data-table__cell--checkbox',
		RootNumeric: 'mdc-data-table__cell--numeric',
	},
	TableRow: {
		Root: 'mdc-data-table__row',
	},
};

const cssSelectors = {
	HeaderCell: {
		Checkbox: `.${cssClassNames.HeaderCell.Checkbox}`,
		Root: `.${cssClassNames.HeaderCell.Root}`,
		RootCheckbox: `.${cssClassNames.HeaderCell.RootCheckbox}`,
		RootNumeric: `.${cssClassNames.HeaderCell.RootNumeric}`,
		RootSort: `.${cssClassNames.HeaderCell.RootSort}`,
		SortWrapper: `.${cssClassNames.HeaderCell.SortWrapper}`,
		SortWrapperButton: `.${cssClassNames.HeaderCell.SortWrapperButton}`,
		SortWrapperLabel: `.${cssClassNames.HeaderCell.SortWrapperLabel}`,
		SortWrapperStatusLabel: `.${cssClassNames.HeaderCell.SortWrapperStatusLabel}`,
	},
	HeaderRow: {
		Root: `.${cssClassNames.HeaderRow.Root}`,
	},
	TableCell: {
		Anchor: `.${cssClassNames.TableCell.Anchor}`,
		Checkbox: `.${cssClassNames.TableCell.Checkbox}`,
		Root: `.${cssClassNames.TableCell.Root}`,
		RootAnchor: `.${cssClassNames.TableCell.RootAnchor}`,
		RootCheckbox: `.${cssClassNames.TableCell.RootCheckbox}`,
		RootNumeric: `.${cssClassNames.TableCell.RootNumeric}`,
	},
	TableRow: {
		Root: `.${cssClassNames.TableRow.Root}`,
	},
};

export interface TableElOpts extends ElOpts {
	selectable: boolean;
	style: StyleOption;
	type: TableElType;
}

export class TableEl extends El {
	static dragClone: Element | null = null;
	static dragging: TableEl | null = null;
	static dragOver: TableEl | null = null;
	static dragStart: number = 0;
	static headerCellRootSelector: string = cssSelectors.HeaderCell.Root;
	static headerRowRootSelector: string = cssSelectors.HeaderRow.Root;
	static tableCellRootSelector: string = cssSelectors.TableCell.Root;
	static tableRowRootSelector: string = cssSelectors.TableRow.Root;

	protected cachedRect: DOMRect;
	protected checkbox: Checkbox | null;
	protected checkboxProxy: TableEl | null;
	protected childEls: list<TableEl>;
	protected lastDragStart: number;
	protected wasDraggable: boolean; // For header row checkbox cells
	protected selectable: boolean;
	protected state: TableElState;
	protected typ: TableElType;

	constructor(opts: Partial<TableElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableElOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableElOpts>, parent?: El | null);
	constructor(opts?: Partial<TableElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<TableElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = initOpts(elOpts<TableElOpts>(a, b, c));
		// If `draggable` is passed-in at this time, the call to
		// `setDraggable` will fail because we have not yet set our `typ` and
		// our override of `setDraggable` checks against our `typ`. Instead of
		// unnecessarily calling `setDraggable` a few thousand times, the
		// value is recorded here then set `undefined`. After we've
		// initialized, we'll set draggable accordingly.
		const draggable = opts.draggable;
		opts.draggable = undefined;
		super(opts);
		this.cachedRect = this.rect();
		this.checkbox = null;
		this.checkboxProxy = null;
		this.childEls = new list<TableEl>();
		this.lastDragStart = TableEl.dragStart;
		this.selectable = false;
		this.state = TableElState.NoState;
		this.typ = TableElType.NoType;
		this.wasDraggable = false;
		this.init({...opts, draggable});
	}

	protected anchorEl(): El | null {
		return this.querySelector(cssSelectors.TableCell.Anchor);
	}

	private checkEl(): El | null {
		if (this.checkboxProxy) {
			return this.checkboxProxy;
		}
		return this.checkbox;
	}

	checkState(): CheckState {
		const el = this.checkEl();
		return el ?
			el.checkState() :
			CheckState.Unchecked;
	}

	clear(): void {
		if ((this.typ === TableElType.TableRow) || (this.typ === TableElType.HeaderRow)) {
			for (const child of this.childEls) {
				child.destroy();
			}
			this.childEls.clear();
		} else {
			this.exitState(TableElState.Anchor);
			this.exitState(TableElState.Checkbox);
			this.exitState(TableElState.Sort);
			this.removeText();
		}
	}

	protected contextMenuEvent(event: MouseEvent): void {
		const {row, column} = this.location();
		const evt = TableCellMouseEvt.fromEvent(event, row, column);
		this.notifyEvt(evt);
		if (evt.isAccepted()) {
			event.preventDefault();
		}
	}

	protected createCheckboxProxy(): void {
		if (!this.checkboxProxy) {
			const style = new StyleOption();
			style.features |= ViewItemFeature.HasCheckIndicator;
			this.checkboxProxy = new TableEl({
				style,
				parent: this,
				placementIndex: 0,
				type: (this.typ === TableElType.TableRow) ?
					TableElType.TableCell :
					TableElType.HeaderCell,
			});
		}
	}

	destroy(): void {
		this.destroyEventListeners();
		if (TableEl.dragging === this) {
			TableEl.dragging = null;
		}
		if (TableEl.dragOver === this) {
			TableEl.dragOver = null;
		}
		for (const child of this.childEls) {
			child.destroy();
		}
		this.childEls.clear();
		this.destroyCheckboxProxy();
		if (this.checkbox) {
			this.checkbox.destroy();
		}
		this.checkbox = null;
		super.destroy();
	}

	protected destroyCheckboxProxy(): void {
		if (this.checkboxProxy) {
			this.checkboxProxy.destroy();
		}
		this.checkboxProxy = null;
	}

	protected destroyDragEventListeners(): void {
		this.removeEventListener('dragend', this.event);
		this.removeEventListener('dragover', this.event);
		this.removeEventListener('dragstart', this.event);
		this.removeEventListener('drop', this.event);
	}

	protected destroyEventListeners(): void {
		this.removeEventListener('contextmenu', this.event);
		this.destroyDragEventListeners();
	}

	protected dragEnded(): void {
		this.removeDragClone();
		window.removeEventListener('scroll', this.event);
		const container = this.closestMatchingAncestor('.mdc-data-table__table-container');
		if (container) {
			container.removeEventListener('scroll', this.event);
		}
		dropIndicator.hide();
		TableEl.dragging = null;
		TableEl.dragOver = null;
	}

	protected dragEndEvent(): void {
		this.dragEnded();
	}

	protected dragOverEvent(event: DragEvent): void {
		if (!TableEl.dragging || (this.typ !== TableEl.dragging.typ)) {
			return;
		}
		event.preventDefault();
		if (this.lastDragStart !== TableEl.dragStart) {
			this.lastDragStart = TableEl.dragStart;
			this.cachedRect = this.rect();
		}
		let x: number;
		let y: number;
		if (this.typ === TableElType.HeaderCell) {
			const rel = event.clientX - this.cachedRect.x;
			const halfOrMore = rel >= (this.cachedRect.width / 2);
			x = halfOrMore ?
				(this.cachedRect.x + this.cachedRect.width) :
				this.cachedRect.x;
			y = this.cachedRect.y;
		} else if (this.typ === TableElType.TableRow) {
			const rel = event.clientY - this.cachedRect.y;
			const halfOrMore = rel >= (this.cachedRect.height / 2);
			x = this.cachedRect.x;
			y = halfOrMore ?
				(this.cachedRect.y + this.cachedRect.height) :
				this.cachedRect.y;
		} else {
			return;
		}
		dropIndicator.setPos(x, y);
		TableEl.dragOver = this;
	}

	protected dragStartEvent(event: DragEvent): void {
		TableEl.dragging = null;
		TableEl.dragOver = null;
		if (this.hasClass(cssClassNames.HeaderCell.RootCheckbox)) {
			dropIndicator.hide();
			event.preventDefault();
		} else {
			TableEl.dragStart = Date.now();
			this.cachedRect = this.rect();
			dropIndicator.setDirection((this.typ === TableElType.HeaderCell) ?
				ArrowType.DownArrow :
				(this.typ === TableElType.TableRow) ?
					ArrowType.RightArrow :
					ArrowType.NoArrow);
			dropIndicator.setPos(this.cachedRect.x + this.cachedRect.width, this.cachedRect.y);
			this.lastDragStart = TableEl.dragStart;
			TableEl.dragging = this;
			window.addEventListener('scroll', this.event);
			const container = this.closestMatchingAncestor('.mdc-data-table__table-container');
			if (container) {
				container.addEventListener('scroll', this.event);
			}
			if (event.dataTransfer) {
				const clone = <HTMLElement>this.elem.cloneNode(true);
				clone.style.setProperty('position', 'absolute');
				clone.style.setProperty('left', '-100%');
				document.body.appendChild(clone);
				event.dataTransfer.setDragImage(clone, 0, 0);
				TableEl.dragClone = clone;
			}
			dropIndicator.show();
		}
	}

	dropEvent(event: DragEvent): void {
		const dragging = TableEl.dragging;
		this.dragEnded();
		if (dragging && (dragging !== this)) {
			this.notifyEvt(new TableElMoveEvt(
				dragging,
				this,
				new Point(event.clientX, event.clientY)));
		}
		event.preventDefault();
	}

	protected ensureAnchor(): void {
		if ((this.typ === TableElType.TableCell) && !this.anchorEl()) {
			const el = new El({
				classNames: cssClassNames.TableCell.Anchor,
				tagName: 'a',
			});
			for (const obj of this.children()) {
				obj.setParent(el);
			}
			el.setParent(this);
		}
	}

	protected ensureCheckbox(): void {
		if (((this.typ === TableElType.TableCell) || (this.typ === TableElType.HeaderCell)) && !this.checkbox) {
			const className = (this.typ === TableElType.TableCell) ?
				cssClassNames.TableCell.Checkbox :
				cssClassNames.HeaderCell.Checkbox;
			this.checkbox = new Checkbox({
				classNames: className,
				parent: this,
				placementIndex: 0,
			});
		}
	}

	protected ensureSort(): void {
		if ((this.typ === TableElType.HeaderCell) && !this.sortWrapperEl()) {
			let text: string = '';
			if (this.textNode) {
				text = this.textNode.data;
			}
			this.removeText();
			this.addClass(cssClassNames.HeaderCell.RootSort);
			this.setAttribute('aria-sort', 'none');
			const sortWrapperEl = new El({
				classNames: cssClassNames.HeaderCell.SortWrapper,
				parent: this,
				tagName: 'div',
			});
			new El({
				classNames: cssClassNames.HeaderCell.SortWrapperLabel,
				parent: sortWrapperEl,
				tagName: 'div',
			});
			const sortButtonEl = new El({
				attributes: [['type', 'button']],
				classNames: [
					'mdc-icon-button', 'material-icons',
					cssClassNames.HeaderCell.SortWrapperButton,
				],
				parent: sortWrapperEl,
				tagName: 'button',
			});
			sortButtonEl.setText('arrow_upward');
			new El({
				attributes: [['aria-hidden', 'true']],
				classNames: cssClassNames.HeaderCell.SortWrapperStatusLabel,
				parent: sortWrapperEl,
				tagName: 'div',
			});
			this.setText(text);
		}
	}

	protected enterState(state: TableElState): void {
		switch (state) {
			case TableElState.Anchor: {
				if (this.typ === TableElType.TableCell) {
					this.addClass(cssClassNames.TableCell.RootAnchor);
					this.ensureAnchor();
				}
				break;
			}
			case TableElState.Checkbox: {
				if ((this.typ === TableElType.TableCell) || (this.typ === TableElType.HeaderCell)) {
					let className = cssClassNames.TableCell.RootCheckbox;
					if (this.typ === TableElType.HeaderCell) {
						className = cssClassNames.HeaderCell.RootCheckbox;
						this.wasDraggable = this.isDraggable();
						this.setDraggable(false);
					}
					this.removeText();
					this.addClass(className);
					this.ensureCheckbox();
				}
				break;
			}
			case TableElState.Sort: {
				if (this.typ === TableElType.HeaderCell) {
					this.addClass(cssClassNames.HeaderCell.RootSort);
					this.ensureSort();
				}
				break;
			}
		}
	}

	@bind
	protected event(event: Event): void {
		if ((this.typ === TableElType.HeaderCell) && (this.checkbox || this.checkboxProxy)) {
			return;
		}
		switch (event.type) {
			case 'scroll':
				this.scrollEvent(event);
				break;
			case 'dragover':
				this.dragOverEvent(<DragEvent>event);
				break;
			case 'click':
				this.mouseClickEvent(<MouseEvent>event);
				break;
			case 'contextmenu':
				this.contextMenuEvent(<MouseEvent>event);
				break;
			case 'dragstart':
				this.dragStartEvent(<DragEvent>event);
				break;
			case 'drop':
				this.dropEvent(<DragEvent>event);
				break;
			case 'dragend':
				this.dragEndEvent();
				break;
		}
	}

	protected exitState(state: TableElState): void {
		switch (state) {
			case TableElState.Anchor: {
				if (this.typ === TableElType.TableCell) {
					this.removeClass(cssClassNames.TableCell.RootAnchor);
					this.removeAnchor();
				}
				break;
			}
			case TableElState.Checkbox: {
				if ((this.typ === TableElType.TableCell) || (this.typ === TableElType.HeaderCell)) {
					let className = cssClassNames.TableCell.RootCheckbox;
					if (this.typ === TableElType.HeaderCell) {
						className = cssClassNames.HeaderCell.RootCheckbox;
						this.setDraggable(this.wasDraggable);
					}
					this.removeClass(className);
					this.removeCheckbox();
				}
				break;
			}
			case TableElState.Sort: {
				if (this.typ === TableElType.HeaderCell) {
					this.removeClass(cssClassNames.HeaderCell.RootSort);
					this.removeSort();
				}
				break;
			}
		}
	}

	protected iconEl(): El | null {
		return this.querySelector(El.IconSelector);
	}

	protected init(opts: Partial<TableElOpts>): void {
		this.typ = (opts.type === undefined) ?
			TableElType.NoType :
			opts.type;
		if (opts.selectable !== undefined) {
			this.setSelectionEnabled(opts.selectable);
		}
		if (opts.style) {
			this._paint(opts.style);
		}
		if (opts.draggable !== undefined) {
			this.setDraggable(opts.draggable);
		}
		this.initializeEventListeners();
	}

	protected initializeDragEventListeners(): void {
		if ((this.typ === TableElType.TableRow) || (this.typ === TableElType.HeaderCell)) {
			this.addEventListener('dragstart', this.event);
			this.addEventListener('dragover', this.event);
			this.addEventListener('drop', this.event);
			this.addEventListener('dragend', this.event);
		}
	}

	protected initializeEventListeners(): void {
		if ((this.typ === TableElType.TableCell) || (this.typ === TableElType.HeaderCell)) {
			this.addEventListener('click', this.event);
		}
		if (this.typ === TableElType.HeaderCell) {
			this.addEventListener('contextmenu', this.event);
		}
		if (this.isDraggable()) {
			this.initializeDragEventListeners();
		}
	}

	insertChild(index: number, child: TableEl): void {
		if ((this.typ === TableElType.TableRow) || (this.typ === TableElType.HeaderRow)) {
			this.childEls.insert(index, child);
			if (this.isSelectable()) {
				++index;
			}
		}
		super.insertChild(index, child);
	}

	isSelectable(): boolean {
		return this.selectable;
	}

	location(): {row: number; column: number;} {
		let row: number = -1;
		let column: number = -1;
		const parent = this.parent();
		if (parent && parent.tagName() === 'TR') {
			column = parent.children().indexOf(this);
			const grandParent = parent.parent();
			const grandParentTagName = grandParent ?
				grandParent.tagName() :
				'';
			if (grandParent && ((this.typ === TableElType.TableCell) && (grandParentTagName === 'TBODY'))) {
				row = grandParent.children().indexOf(parent);
			}
		}
		return {row, column};
	}

	protected mouseClickEvent(event: MouseEvent): void {
		const {row, column} = this.location();
		this.notifyEvt(TableCellMouseEvt.fromEvent(event, row, column));
	}

	paint(opt: StyleOption): void {
		this._paint(opt);
	}

	private _paint(opt: StyleOption): void {
		let pointerCursor = false;
		if (testFlag(opt.features, ViewItemFeature.HyperLink)) {
			this.setState(TableElState.Anchor);
		} else if (testFlag(opt.features, ViewItemFeature.HasCheckIndicator)) {
			this.setState(TableElState.Checkbox);
		} else if (testFlag(opt.features, ViewItemFeature.HasSortIndicator)) {
			this.setState(TableElState.Sort);
		} else if (testFlag(opt.features, ViewItemFeature.IsSelectable) && (this.typ === TableElType.TableCell)) {
			pointerCursor = true;
		} else {
			this.setState(TableElState.NoState);
		}
		this.setBackground(opt.background);
		this.setForeground(opt.foreground);
		this.setText(opt.text);
		if (testFlag(opt.features, ViewItemFeature.HasDecoration)) {
			this.setIcon(opt.icon);
		}
		const numericClassName = (this.typ === TableElType.TableCell) ?
			cssClassNames.TableCell.RootNumeric :
			(this.typ === TableElType.HeaderCell) ?
				cssClassNames.HeaderCell.RootNumeric :
				'';
		const align = opt.displayAlignment;
		if (numericClassName.length > 0) {
			this.setClass(align === AlignmentFlag.AlignRight, numericClassName);
		}
		this.setClass(align === AlignmentFlag.AlignCenter, 'text-align--center');

		this.setClass(pointerCursor, 'cursor--pointer');
		this.setCheckState(opt.checkState);
		if (opt.url) {
			this.setHref(opt.url);
		}
		if (testFlag(opt.features, ViewItemFeature.HasToolTip)) {
			this.setTitle(opt.toolTip);
		}
	}

	protected removeAnchor(): void {
		const el = this.anchorEl();
		if (el) {
			el.destroy();
		}
	}

	protected removeCheckbox(): void {
		if (this.checkbox) {
			this.checkbox.destroy();
		}
		this.checkbox = null;
	}

	protected removeDragClone(): void {
		if (TableEl.dragClone) {
			TableEl.dragClone.remove();
		}
		TableEl.dragClone = null;
	}

	protected removeSort(): void {
		const el = this.sortWrapperEl();
		if (el) {
			el.destroy();
		}
	}

	// noinspection JSUnusedLocalSymbols
	protected scrollEvent(event: Event): void {
		TableEl.dragStart = Date.now();
	}

	protected selectableChanged(): void {
		if ((this.typ === TableElType.TableRow) || (this.typ === TableElType.HeaderRow)) {
			if (this.selectable) {
				this.createCheckboxProxy();
			} else {
				this.destroyCheckboxProxy();
			}
		}
	}

	protected setBackground(value: string): void {
		if (value) {
			this.setStyleProperty('background-color', value);
		} else {
			this.removeStyleProperty('background-color');
		}
	}

	setCheckState(state: CheckState): void {
		const el = this.checkEl();
		el && el.setCheckState(state);
	}

	setDraggable(draggable: boolean): void {
		draggable = ((this.typ === TableElType.TableRow) || (this.typ === TableElType.HeaderCell)) ?
			draggable :
			false;
		super.setDraggable(draggable);
		if (this.isDraggable()) {
			this.initializeDragEventListeners();
		} else {
			this.destroyDragEventListeners();
		}
	}

	protected setForeground(value: string): void {
		const el = (this.state === TableElState.Anchor) ?
			this.anchorEl() :
			this;
		if (el) {
			if (value) {
				el.setStyleProperty('color', value);
			} else {
				el.removeStyleProperty('color');
			}
		}
	}

	setHref(href: string, opts?: Partial<{text: string; target: 'blank'}>): void {
		const el = this.anchorEl();
		if (el) {
			el.setHref(href, opts);
		}
	}

	protected setIcon(icon: string): void {
		let el = this.iconEl();
		if (!icon) {
			if (el) {
				el.removeClass(El.IconClassName);
				el.setText();
			}
			return;
		}
		if (!el) {
			el = this.anchorEl();
			if (el) {
				el.addClass(El.IconClassName);
			} else {
				el = new El({
					classNames: El.IconClassName,
					parent: this,
					tagName: 'i',
				});
			}
		}
		el.setText(icon);
	}

	setSelectionEnabled(enable: boolean): void {
		if (enable === this.selectable) {
			return;
		}
		this.selectable = enable;
		this.selectableChanged();
	}

	protected setState(state: TableElState): void {
		if (state === this.state) {
			return;
		}
		this.exitState(this.state);
		this.state = state;
		this.enterState(this.state);
	}

	setText(text: string | null): void {
		if ((this.typ === TableElType.TableCell) || (this.typ === TableElType.HeaderCell)) {
			const el = (this.typ === TableElType.TableCell) ?
				this.anchorEl() :
				this.sortWrapperLabelEl();
			if (el) {
				el.setText(text);
			} else if (!this.checkbox) {
				super.setText(text);
			}
		}
	}

	protected sortWrapperEl(): El | null {
		return this.querySelector(cssSelectors.HeaderCell.SortWrapper);
	}

	private sortWrapperLabelEl(): El | null {
		return this.querySelector(cssSelectors.HeaderCell.SortWrapperLabel);
	}
}

function initOpts(opts: Partial<TableElOpts>, type?: TableElType): Partial<TableElOpts> {
	const typ = (type === undefined) ?
		(opts.type === undefined) ?
			TableElType.NoType :
			opts.type :
		type;
	const rv: Partial<TableElOpts> = {...opts};
	const attributes: Array<[string, string]> = rv.attributes ?
		Array.from(rv.attributes) :
		[];
	const classNames: Array<string> = rv.classNames ?
		(typeof rv.classNames === 'string') ?
			[rv.classNames] :
			Array.from(rv.classNames) :
		[];
	switch (typ) {
		case TableElType.TableCell: {
			const rowHeader = rv.style && rv.style.index.isValid() && (rv.style.index.column === 0);
			if (rowHeader) {
				attributes.push(['scope', 'row']);
			}
			classNames.push(cssClassNames.TableCell.Root);
			rv.tagName = rowHeader ?
				'th' :
				'td';
			break;
		}
		case TableElType.TableRow: {
			classNames.push(cssClassNames.TableRow.Root);
			rv.tagName = 'tr';
			break;
		}
		case TableElType.HeaderRow: {
			classNames.push(cssClassNames.HeaderRow.Root);
			rv.tagName = 'tr';
			break;
		}
		case TableElType.HeaderCell: {
			attributes.push(['role', 'columnheader'], ['scope', 'col']);
			classNames.push(cssClassNames.HeaderCell.Root);
			rv.tagName = 'th';
			break;
		}
	}
	rv.attributes = attributes;
	rv.classNames = classNames;
	rv.type = typ;
	return rv;
}

export class TableCellMouseEvt extends MouseEvt {
	static fromEvent(event: MouseEvent, row: number = 0, column: number = 0): TableCellMouseEvt {
		const parts = this.fromEventParts(event);
		return new this(
			parts.type,
			parts.pos,
			parts.button,
			parts.buttons,
			row,
			column,
			parts.modifiers);
	}

	private c: number;
	private r: number;

	constructor(type: number, clientPos: Point, button: MouseButton, buttons: number, row: number, column: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, clientPos, button, buttons, modifiers);
		this.c = column;
		this.r = row;
		this.setAccepted(false);
	}

	column(): number {
		return this.c;
	}

	row(): number {
		return this.r;
	}
}

export class TableElMoveEvt extends Evt {
	private cp: Point;
	private s: TableEl;
	private t: TableEl;

	constructor(source: TableEl, target: TableEl, clientPos: Point) {
		super(Evt.Move);
		this.cp = clientPos;
		this.s = source;
		this.t = target;
	}

	clientPos(): Point {
		return this.cp;
	}

	source(): TableEl {
		return this.s;
	}

	target(): TableEl {
		return this.t;
	}
}

const indicatorDirectionMap = new Map<ArrowType, string>([
	[ArrowType.UpArrow, 'keyboard_arrow_up'],
	[ArrowType.RightArrow, 'keyboard_arrow_right'],
	[ArrowType.DownArrow, 'keyboard_arrow_down'],
	[ArrowType.LeftArrow, 'keyboard_arrow_left'],
]);

class DropIndicator extends El {
	constructor(direction?: ArrowType) {
		super({classNames: 'material-icons', tagName: 'div'});
		this.hide();
		this.setDirection(direction || ArrowType.DownArrow);
		this.setStyleProperty('z-index', '9001');
		this.setStyleProperty('position', 'fixed');
		this.setStyleProperty('top', '0');
		this.setStyleProperty('left', '0');
	}

	setDirection(direction: ArrowType): void {
		this.setText(indicatorDirectionMap.get(direction) || '');
	}

	setPos(x: number, y: number): void {
		this.setStyleProperty('left', pixelString(x - 12));
		this.setStyleProperty('top', pixelString(y - 12));
	}
}

const dropIndicator = new DropIndicator();
dropIndicator.appendToBody();

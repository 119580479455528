import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../util';

interface IProps extends React.ButtonHTMLAttributes<any> {
	icon: string;
	smaller?: boolean;
	onHoverClassNames?: string[];
}

type Props = PropsWithChildren<IProps>;

interface State {
	classNames: Set<string>;
}

export default class IconButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.mouseEnterEvent = this.mouseEnterEvent.bind(this);
		this.mouseLeaveEvent = this.mouseLeaveEvent.bind(this);
		const classNames = new Set(['mdc-icon-button', 'material-icons', 'pb-icon-button']);
		if (props.smaller) {
			classNames.add('pb-icon-button--size-20-5');
		}
		this.state = {
			classNames,
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (this.props.smaller !== prevProps.smaller) {
			const classNames = this.state.classNames;
			if (this.props.smaller) {
				classNames.add('pb-icon-button--size-20-5');
			} else {
				classNames.delete('pb-icon-button--size-20-5');
			}
			this.setState({classNames});
		}
	}

	mouseEnterEvent(): void {
		const {onHoverClassNames} = this.props;
		if (onHoverClassNames) {
			const {classNames} = this.state;
			onHoverClassNames.forEach(name => classNames.add(name));
			this.setState({classNames: new Set(classNames)});
		}
	}

	mouseLeaveEvent(): void {
		const {onHoverClassNames} = this.props;
		if (onHoverClassNames) {
			const {classNames} = this.state;
			onHoverClassNames.forEach(name => classNames.delete(name));
			this.setState({classNames: new Set(classNames)});
		}
	}

	render(): React.ReactNode {
		const {children, className, icon, onHoverClassNames, smaller, type, ...attrs} = this.props;
		const {classNames} = this.state;
		return (
			<button
				className={cssClassName(...classNames, className)}
				onMouseEnter={this.mouseEnterEvent}
				onMouseLeave={this.mouseLeaveEvent}
				type={type || 'button'}
				{...attrs}>
				{icon}
				{children}
			</button>
		);
	}
}

import {MDCComponent, MDCFoundation} from '@material/base';
import {CustomEventListener, SpecificEventListener} from '@material/base/types';
import {MDCFloatingLabel, MDCFloatingLabelFactory} from '@material/floating-label/component';
import {MDCLineRipple, MDCLineRippleFactory} from '@material/line-ripple/component';
import * as menuSurfaceConstants from '@material/menu-surface/constants';
import * as menuConstants from '@material/menu/constants';
import {MDCMenuItemEvent} from '@material/menu/types';
import {MDCNotchedOutline, MDCNotchedOutlineFactory} from '@material/notched-outline/component';
import {MDCRippleAdapter} from '@material/ripple/adapter';
import {MDCRipple} from '@material/ripple/component';
import {normalizeKey, KEY} from '@material/dom/keyboard';
import {MDCRippleFoundation} from '@material/ripple/foundation';
import {MDCSelectEventDetail, MDCSelectHelperTextFactory, MDCSelectIconFactory, MDCSelectHelperText, MDCSelectIcon, MDCSelectAdapter, MDCSelectIconFoundation, MDCSelectHelperTextFoundation, MDCSelectFoundationMap} from '@material/select';

import {Corner, MDCMenu} from '../menu/ctrl';

export class MDCSelectFoundation extends MDCFoundation<MDCSelectAdapter> {
	static get cssClasses() {
		return {
			ACTIVATED: 'mdc-select--activated',
			DISABLED: 'mdc-select--disabled',
			FOCUSED: 'mdc-select--focused',
			INVALID: 'mdc-select--invalid',
			MENU_INVALID: 'mdc-select__menu--invalid',
			OUTLINED: 'mdc-select--outlined',
			REQUIRED: 'mdc-select--required',
			ROOT: 'mdc-select',
			WITH_LEADING_ICON: 'mdc-select--with-leading-icon',
		};
	}

	static get numbers() {
		return {
			LABEL_SCALE: 0.75,
			UNSET_INDEX: -1,
		};
	}

	static get strings() {
		return {
			ARIA_CONTROLS: 'aria-controls',
			ARIA_DESCRIBEDBY: 'aria-describedby',
			ARIA_SELECTED_ATTR: 'aria-selected',
			CHANGE_EVENT: 'MDCSelect:change',
			HIDDEN_INPUT_SELECTOR: 'input[type="hidden"]',
			LABEL_SELECTOR: '.mdc-floating-label',
			LEADING_ICON_SELECTOR: '.mdc-select__icon',
			LINE_RIPPLE_SELECTOR: '.mdc-line-ripple',
			MENU_SELECTOR: '.mdc-select__menu',
			OUTLINE_SELECTOR: '.mdc-notched-outline',
			SELECTED_TEXT_SELECTOR: '.mdc-select__selected-text',
			SELECT_ANCHOR_SELECTOR: '.mdc-select__anchor',
			VALUE_ATTR: 'data-value',
		};
	}

	/**
	 * See {@link MDCSelectAdapter} for typing information on parameters and return types.
	 */
	static get defaultAdapter(): MDCSelectAdapter {
		// tslint:disable:object-literal-sort-keys Methods should be in the same order as the adapter interface.
		return {
			addClass: () => undefined,
			removeClass: () => undefined,
			hasClass: () => false,
			activateBottomLine: () => undefined,
			deactivateBottomLine: () => undefined,
			getSelectedIndex: () => -1,
			setSelectedIndex: () => undefined,
			hasLabel: () => false,
			floatLabel: () => undefined,
			getLabelWidth: () => 0,
			setLabelRequired: () => undefined,
			hasOutline: () => false,
			notchOutline: () => undefined,
			closeOutline: () => undefined,
			setRippleCenter: () => undefined,
			notifyChange: () => undefined,
			setSelectedText: () => undefined,
			isSelectAnchorFocused: () => false,
			getSelectAnchorAttr: () => '',
			setSelectAnchorAttr: () => undefined,
			removeSelectAnchorAttr: () => undefined,
			addMenuClass: () => undefined,
			removeMenuClass: () => undefined,
			openMenu: () => undefined,
			closeMenu: () => undefined,
			getAnchorElement: () => null,
			setMenuAnchorElement: () => undefined,
			setMenuAnchorCorner: () => undefined,
			setMenuWrapFocus: () => undefined,
			focusMenuItemAtIndex: () => undefined,
			getMenuItemCount: () => 0,
			getMenuItemValues: () => [],
			getMenuItemTextAtIndex: () => '',
			isTypeaheadInProgress: () => false,
			typeaheadMatchItem: () => -1,
		};
		// tslint:enable:object-literal-sort-keys
	}

	private readonly leadingIcon: MDCSelectIconFoundation | undefined;
	private readonly helperText: MDCSelectHelperTextFoundation | undefined;

	// Disabled state
	private disabled = false;
	// isMenuOpen is used to track the state of the menu by listening to the
	// MDCMenuSurface:closed event For reference, menu.open will return false if
	// the menu is still closing, but isMenuOpen returns false only after the menu
	// has closed
	private isMenuOpen = false;
	// By default, select is invalid if it is required but no value is selected.
	private useDefaultValidation = true;
	private customValidity = true;
	private lastSelectedIndex = MDCSelectFoundation.numbers.UNSET_INDEX;

	/* istanbul ignore next: optional argument is not a branch statement */
	/**
	 * @param adapter
	 * @param foundationMap Map from subcomponent names to their subfoundations.
	 */
	constructor(adapter?: Partial<MDCSelectAdapter>, foundationMap: Partial<MDCSelectFoundationMap> = {}) {
		super({...MDCSelectFoundation.defaultAdapter, ...adapter});

		this.leadingIcon = foundationMap.leadingIcon;
		this.helperText = foundationMap.helperText;
	}

	/** Returns the index of the currently selected menu item, or -1 if none. */
	getSelectedIndex(): number {
		return this.adapter.getSelectedIndex();
	}

	setSelectedIndex(index: number, closeMenu = false, skipNotify = false) {
		if (index >= this.adapter.getMenuItemCount()) {
			return;
		}
		if (index === MDCSelectFoundation.numbers.UNSET_INDEX) {
			this.adapter.setSelectedText('');
		} else {
			this.adapter.setSelectedText(this.adapter.getMenuItemTextAtIndex(index).trim());
		}
		this.adapter.setSelectedIndex(index);
		if (closeMenu) {
			this.adapter.closeMenu();
		}
		if (!skipNotify && this.lastSelectedIndex !== index) {
			this.handleChange();
		}
		this.lastSelectedIndex = index;
	}

	setValue(value: string, skipNotify = false) {
		const index = this.adapter.getMenuItemValues().indexOf(value);
		this.setSelectedIndex(index, /** closeMenu */ false, skipNotify);
	}

	getValue() {
		const index = this.adapter.getSelectedIndex();
		const menuItemValues = this.adapter.getMenuItemValues();
		return index !== MDCSelectFoundation.numbers.UNSET_INDEX ? menuItemValues[index] : '';
	}

	getDisabled() {
		return this.disabled;
	}

	setDisabled(isDisabled: boolean) {
		this.disabled = isDisabled;
		if (this.disabled) {
			this.adapter.addClass(MDCSelectFoundation.cssClasses.DISABLED);
			this.adapter.closeMenu();
		} else {
			this.adapter.removeClass(MDCSelectFoundation.cssClasses.DISABLED);
		}

		if (this.leadingIcon) {
			this.leadingIcon.setDisabled(this.disabled);
		}

		if (this.disabled) {
			// Prevent click events from focusing select. Simply pointer-events: none
			// is not enough since screenreader clicks may bypass this.
			this.adapter.removeSelectAnchorAttr('tabindex');
		} else {
			this.adapter.setSelectAnchorAttr('tabindex', '0');
		}

		this.adapter.setSelectAnchorAttr('aria-disabled', this.disabled.toString());
	}

	/** Opens the menu. */
	openMenu() {
		this.adapter.addClass(MDCSelectFoundation.cssClasses.ACTIVATED);
		this.adapter.openMenu();
		this.isMenuOpen = true;
		this.adapter.setSelectAnchorAttr('aria-expanded', 'true');
	}

	/**
	 * @param content Sets the content of the helper text.
	 */
	setHelperTextContent(content: string) {
		if (this.helperText) {
			this.helperText.setContent(content);
		}
	}

	/**
	 * Re-calculates if the notched outline should be notched and if the label
	 * should float.
	 */
	layout() {
		if (this.adapter.hasLabel()) {
			const optionHasValue = this.getValue().length > 0;
			const isFocused = this.adapter.hasClass(MDCSelectFoundation.cssClasses.FOCUSED);
			const shouldFloatAndNotch = optionHasValue || isFocused;
			const isRequired = this.adapter.hasClass(MDCSelectFoundation.cssClasses.REQUIRED);

			this.notchOutline(shouldFloatAndNotch);
			this.adapter.floatLabel(shouldFloatAndNotch);
			this.adapter.setLabelRequired(isRequired);
		}
	}

	/**
	 * Synchronizes the list of options with the state of the foundation. Call
	 * this whenever menu options are dynamically updated.
	 */
	layoutOptions() {
		const menuItemValues = this.adapter.getMenuItemValues();
		const selectedIndex = menuItemValues.indexOf(this.getValue());
		this.setSelectedIndex(
			selectedIndex, /** closeMenu */ false, /** skipNotify */ true);
	}

	handleMenuOpened() {
		if (this.adapter.getMenuItemValues().length === 0) {
			return;
		}

		// Menu should open to the last selected element, should open to first menu item otherwise.
		const selectedIndex = this.getSelectedIndex();
		const focusItemIndex = selectedIndex >= 0 ? selectedIndex : 0;
		this.adapter.focusMenuItemAtIndex(focusItemIndex);
	}

	handleMenuClosed() {
		this.adapter.removeClass(MDCSelectFoundation.cssClasses.ACTIVATED);
		this.isMenuOpen = false;
		this.adapter.setSelectAnchorAttr('aria-expanded', 'false');

		// Unfocus the select if menu is closed without a selection
		if (!this.adapter.isSelectAnchorFocused()) {
			this.blur();
		}
	}

	/**
	 * Handles value changes, via change event or programmatic updates.
	 */
	handleChange() {
		this.layout();
		this.adapter.notifyChange(this.getValue());

		const isRequired = this.adapter.hasClass(MDCSelectFoundation.cssClasses.REQUIRED);
		if (isRequired && this.useDefaultValidation) {
			this.setValid(this.isValid());
		}
	}

	handleMenuItemAction(index: number) {
		this.setSelectedIndex(index, /** closeMenu */ true);
	}

	/**
	 * Handles focus events from select element.
	 */
	handleFocus() {
		this.adapter.addClass(MDCSelectFoundation.cssClasses.FOCUSED);
		this.layout();

		this.adapter.activateBottomLine();
	}

	/**
	 * Handles blur events from select element.
	 */
	handleBlur() {
		if (this.isMenuOpen) {
			return;
		}
		this.blur();
	}

	handleClick(normalizedX: number) {
		if (this.disabled) {
			return;
		}

		if (this.isMenuOpen) {
			this.adapter.closeMenu();
			return;
		}

		this.adapter.setRippleCenter(normalizedX);

		this.openMenu();
	}

	/**
	 * Handles keydown events on select element. Depending on the type of
	 * character typed, does typeahead matching or opens menu.
	 */
	handleKeydown(event: KeyboardEvent) {
		if (this.isMenuOpen || !this.adapter.hasClass(MDCSelectFoundation.cssClasses.FOCUSED)) {
			return;
		}

		const isEnter = normalizeKey(event) === KEY.ENTER;
		const isSpace = normalizeKey(event) === KEY.SPACEBAR;
		const arrowUp = normalizeKey(event) === KEY.ARROW_UP;
		const arrowDown = normalizeKey(event) === KEY.ARROW_DOWN;

		// Typeahead
		if (!isSpace && event.key && event.key.length === 1 ||
			isSpace && this.adapter.isTypeaheadInProgress()) {
			const key = isSpace ? ' ' : event.key;
			const typeaheadNextIndex =
				this.adapter.typeaheadMatchItem(key, this.getSelectedIndex());
			if (typeaheadNextIndex >= 0) {
				this.setSelectedIndex(typeaheadNextIndex);
			}
			event.preventDefault();
			return;
		}

		if (!isEnter && !isSpace && !arrowUp && !arrowDown) {
			return;
		}

		// Increment/decrement index as necessary and open menu.
		if (arrowUp && this.getSelectedIndex() > 0) {
			this.setSelectedIndex(this.getSelectedIndex() - 1);
		} else if (
			arrowDown &&
			this.getSelectedIndex() < this.adapter.getMenuItemCount() - 1) {
			this.setSelectedIndex(this.getSelectedIndex() + 1);
		}

		this.openMenu();
		event.preventDefault();
	}

	/**
	 * Opens/closes the notched outline.
	 */
	notchOutline(openNotch: boolean) {
		if (!this.adapter.hasOutline()) {
			return;
		}
		const isFocused = this.adapter.hasClass(MDCSelectFoundation.cssClasses.FOCUSED);

		if (openNotch) {
			const labelScale = MDCSelectFoundation.numbers.LABEL_SCALE;
			const labelWidth = this.adapter.getLabelWidth() * labelScale;
			this.adapter.notchOutline(labelWidth);
		} else if (!isFocused) {
			this.adapter.closeOutline();
		}
	}

	/**
	 * Sets the aria label of the leading icon.
	 */
	setLeadingIconAriaLabel(label: string) {
		if (this.leadingIcon) {
			this.leadingIcon.setAriaLabel(label);
		}
	}

	/**
	 * Sets the text content of the leading icon.
	 */
	setLeadingIconContent(content: string) {
		if (this.leadingIcon) {
			this.leadingIcon.setContent(content);
		}
	}

	setUseDefaultValidation(useDefaultValidation: boolean) {
		this.useDefaultValidation = useDefaultValidation;
	}

	setValid(isValid: boolean) {
		if (!this.useDefaultValidation) {
			this.customValidity = isValid;
		}

		this.adapter.setSelectAnchorAttr('aria-invalid', (!isValid).toString());
		if (isValid) {
			this.adapter.removeClass(MDCSelectFoundation.cssClasses.INVALID);
			this.adapter.removeMenuClass(MDCSelectFoundation.cssClasses.MENU_INVALID);
		} else {
			this.adapter.addClass(MDCSelectFoundation.cssClasses.INVALID);
			this.adapter.addMenuClass(MDCSelectFoundation.cssClasses.MENU_INVALID);
		}

		this.syncHelperTextValidity(isValid);
	}

	isValid() {
		if (this.useDefaultValidation &&
			this.adapter.hasClass(MDCSelectFoundation.cssClasses.REQUIRED) &&
			!this.adapter.hasClass(MDCSelectFoundation.cssClasses.DISABLED)) {
			// See notes for required attribute under https://www.w3.org/TR/html52/sec-forms.html#the-select-element
			// TL;DR: Invalid if no index is selected, or if the first index is selected and has an empty value.
			return this.getSelectedIndex() !== MDCSelectFoundation.numbers.UNSET_INDEX &&
				(this.getSelectedIndex() !== 0 || Boolean(this.getValue()));
		}
		return this.customValidity;
	}

	setRequired(isRequired: boolean) {
		if (isRequired) {
			this.adapter.addClass(MDCSelectFoundation.cssClasses.REQUIRED);
		} else {
			this.adapter.removeClass(MDCSelectFoundation.cssClasses.REQUIRED);
		}
		this.adapter.setSelectAnchorAttr('aria-required', isRequired.toString());
		this.adapter.setLabelRequired(isRequired);
	}

	getRequired() {
		return this.adapter.getSelectAnchorAttr('aria-required') === 'true';
	}

	init() {
		const anchorEl = this.adapter.getAnchorElement();
		if (anchorEl) {
			this.adapter.setMenuAnchorElement(anchorEl);
			this.adapter.setMenuAnchorCorner(Corner.BOTTOM_START);
		}
		this.adapter.setMenuWrapFocus(false);

		this.setDisabled(this.adapter.hasClass(MDCSelectFoundation.cssClasses.DISABLED));
		this.syncHelperTextValidity(!this.adapter.hasClass(MDCSelectFoundation.cssClasses.INVALID));
		this.layout();
		this.layoutOptions();
	}

	/**
	 * Unfocuses the select component.
	 */
	private blur() {
		this.adapter.removeClass(MDCSelectFoundation.cssClasses.FOCUSED);
		this.layout();
		this.adapter.deactivateBottomLine();

		const isRequired = this.adapter.hasClass(MDCSelectFoundation.cssClasses.REQUIRED);
		if (isRequired && this.useDefaultValidation) {
			this.setValid(this.isValid());
		}
	}

	private syncHelperTextValidity(isValid: boolean) {
		if (!this.helperText) {
			return;
		}

		this.helperText.setValidity(isValid);

		const helperTextVisible = this.helperText.isVisible();
		const helperTextId = this.helperText.getId();

		if (helperTextVisible && helperTextId) {
			this.adapter.setSelectAnchorAttr(MDCSelectFoundation.strings.ARIA_DESCRIBEDBY, helperTextId);
		} else {
			// Needed because screenreaders will read labels pointed to by
			// `aria-describedby` even if they are `aria-hidden`.
			this.adapter.removeSelectAnchorAttr(MDCSelectFoundation.strings.ARIA_DESCRIBEDBY);
		}
	}
}

export class MDCSelect extends MDCComponent<MDCSelectFoundation> {
	static attachTo(root: Element): MDCSelect {
		return new MDCSelect(root);
	}

	private ripple!: MDCRipple | null;
	private menu!: MDCMenu;
	private selectAnchor!: HTMLElement;
	private selectedText!: HTMLElement;
	private hiddenInput!: HTMLInputElement | null;
	private menuElement!: Element;
	// private menuItemValues!: string[];
	private leadingIcon?: MDCSelectIcon;
	private helperText!: MDCSelectHelperText | null;
	private lineRipple!: MDCLineRipple | null;
	private label!: MDCFloatingLabel | null;
	private outline!: MDCNotchedOutline | null;
	private handleFocus!: SpecificEventListener<'focus'>;
	private handleBlur!: SpecificEventListener<'blur'>;
	private handleClick!: SpecificEventListener<'click'>;
	private handleKeydown!: SpecificEventListener<'keydown'>;
	private handleMenuOpened!: EventListener;
	private handleMenuClosed!: EventListener;
	private handleMenuItemAction!: CustomEventListener<MDCMenuItemEvent>;
	private ownMenu!: boolean;

	initialize(
		labelFactory: MDCFloatingLabelFactory = (el) => new MDCFloatingLabel(el),
		lineRippleFactory: MDCLineRippleFactory = (el) => new MDCLineRipple(el),
		outlineFactory: MDCNotchedOutlineFactory = (el) => new MDCNotchedOutline(el),
		menuFactory: ((root: Element) => MDCMenu) | null = null,
		iconFactory: MDCSelectIconFactory = (el) => new MDCSelectIcon(el),
		helperTextFactory: MDCSelectHelperTextFactory = (el) => new MDCSelectHelperText(el),
	) {
		this.selectAnchor =
			this.root.querySelector(MDCSelectFoundation.strings.SELECT_ANCHOR_SELECTOR) as HTMLElement;
		this.selectedText =
			this.root.querySelector(MDCSelectFoundation.strings.SELECTED_TEXT_SELECTOR) as HTMLElement;
		this.hiddenInput = this.root.querySelector(MDCSelectFoundation.strings.HIDDEN_INPUT_SELECTOR) as
			HTMLInputElement;
		if (!this.selectedText) {
			throw new Error(
				'MDCSelect: Missing required element: The following selector must be present: ' +
				`'${MDCSelectFoundation.strings.SELECTED_TEXT_SELECTOR}'`,
			);
		}
		if (this.selectAnchor.hasAttribute(MDCSelectFoundation.strings.ARIA_CONTROLS)) {
			const helperTextElement = document.getElementById(
				this.selectAnchor.getAttribute(MDCSelectFoundation.strings.ARIA_CONTROLS)!);
			if (helperTextElement) {
				this.helperText = helperTextFactory(helperTextElement);
			}
		}
		if (menuFactory) {
			this.ownMenu = false;
		} else {
			this.ownMenu = true;
			menuFactory = (el) => new MDCMenu(el);
		}
		this.menuSetup(menuFactory);
		const labelElement = this.root.querySelector(MDCSelectFoundation.strings.LABEL_SELECTOR);
		this.label = labelElement ? labelFactory(labelElement) : null;
		const lineRippleElement =
			this.root.querySelector(MDCSelectFoundation.strings.LINE_RIPPLE_SELECTOR);
		this.lineRipple =
			lineRippleElement ? lineRippleFactory(lineRippleElement) : null;
		const outlineElement = this.root.querySelector(MDCSelectFoundation.strings.OUTLINE_SELECTOR);
		this.outline = outlineElement ? outlineFactory(outlineElement) : null;
		const leadingIcon = this.root.querySelector(MDCSelectFoundation.strings.LEADING_ICON_SELECTOR);
		if (leadingIcon) {
			this.leadingIcon = iconFactory(leadingIcon);
		}
		if (!this.root.classList.contains(MDCSelectFoundation.cssClasses.OUTLINED)) {
			this.ripple = this.createRipple();
		}
	}

	/**
	 * Initializes the select's event listeners and internal state based
	 * on the environment's state.
	 */
	initialSyncWithDOM() {
		this.handleFocus = () => {
			this.foundation.handleFocus();
		};
		this.handleBlur = () => {
			this.foundation.handleBlur();
		};
		this.handleClick = (evt) => {
			this.selectAnchor.focus();
			this.foundation.handleClick(this.getNormalizedXCoordinate(evt));
		};
		this.handleKeydown = (evt) => {
			this.foundation.handleKeydown(evt);
		};
		this.handleMenuItemAction = (evt) => {
			this.foundation.handleMenuItemAction(evt.detail.index);
		};
		this.handleMenuOpened = () => {
			this.foundation.handleMenuOpened();
		};
		this.handleMenuClosed = () => {
			this.foundation.handleMenuClosed();
		};
		this.selectAnchor.addEventListener('focus', this.handleFocus);
		this.selectAnchor.addEventListener('blur', this.handleBlur);
		this.selectAnchor.addEventListener(
			'click', this.handleClick as EventListener);
		this.selectAnchor.addEventListener('keydown', this.handleKeydown);
		this.menu.listen(
			menuSurfaceConstants.strings.CLOSED_EVENT, this.handleMenuClosed);
		this.menu.listen(
			menuSurfaceConstants.strings.OPENED_EVENT, this.handleMenuOpened);
		this.menu.listen(
			menuConstants.strings.SELECTED_EVENT, this.handleMenuItemAction);
		if (this.hiddenInput) {
			if (this.hiddenInput.value) {
				this.foundation.setValue(
					this.hiddenInput.value, /** skipNotify */ true);
				this.foundation.layout();
				return;
			}
			this.hiddenInput.value = this.value;
		}
	}

	destroy() {
		this.selectAnchor.removeEventListener('focus', this.handleFocus);
		this.selectAnchor.removeEventListener('blur', this.handleBlur);
		this.selectAnchor.removeEventListener('keydown', this.handleKeydown);
		this.selectAnchor.removeEventListener(
			'click', this.handleClick as EventListener);
		this.menu.unlisten(
			menuSurfaceConstants.strings.CLOSED_EVENT, this.handleMenuClosed);
		this.menu.unlisten(
			menuSurfaceConstants.strings.OPENED_EVENT, this.handleMenuOpened);
		this.menu.unlisten(
			menuConstants.strings.SELECTED_EVENT, this.handleMenuItemAction);
		if (this.ownMenu) {
			this.menu.destroy();
		}
		if (this.ripple) {
			this.ripple.destroy();
		}
		if (this.outline) {
			this.outline.destroy();
		}
		if (this.leadingIcon) {
			this.leadingIcon.destroy();
		}
		if (this.helperText) {
			this.helperText.destroy();
		}
		super.destroy();
	}

	get value(): string {
		return this.foundation.getValue();
	}

	set value(value: string) {
		this.foundation.setValue(value);
	}

	get selectedIndex(): number {
		return this.foundation.getSelectedIndex();
	}

	set selectedIndex(selectedIndex: number) {
		this.foundation.setSelectedIndex(selectedIndex, /** closeMenu */ true);
	}

	get disabled(): boolean {
		return this.foundation.getDisabled();
	}

	set disabled(disabled: boolean) {
		this.foundation.setDisabled(disabled);
		if (this.hiddenInput) {
			this.hiddenInput.disabled = disabled;
		}
	}

	set leadingIconAriaLabel(label: string) {
		this.foundation.setLeadingIconAriaLabel(label);
	}

	/**
	 * Sets the text content of the leading icon.
	 */
	set leadingIconContent(content: string) {
		this.foundation.setLeadingIconContent(content);
	}

	/**
	 * Sets the text content of the helper text.
	 */
	set helperTextContent(content: string) {
		this.foundation.setHelperTextContent(content);
	}

	/**
	 * Enables or disables the default validation scheme where a required select
	 * must be non-empty. Set to false for custom validation.
	 * @param useDefaultValidation Set this to false to ignore default
	 *     validation scheme.
	 */
	set useDefaultValidation(useDefaultValidation: boolean) {
		this.foundation.setUseDefaultValidation(useDefaultValidation);
	}

	/**
	 * Sets the current invalid state of the select.
	 */
	set valid(isValid: boolean) {
		this.foundation.setValid(isValid);
	}

	/**
	 * Checks if the select is in a valid state.
	 */
	get valid(): boolean {
		return this.foundation.isValid();
	}

	/**
	 * Sets the control to the required state.
	 */
	set required(isRequired: boolean) {
		this.foundation.setRequired(isRequired);
	}

	/**
	 * Returns whether the select is required.
	 */
	get required(): boolean {
		return this.foundation.getRequired();
	}

	get menuItemValues(): Array<string> {
		return this.menu.items.map((el) => el.getAttribute(MDCSelectFoundation.strings.VALUE_ATTR) || '');
	}

	/**
	 * Re-calculates if the notched outline should be notched and if the label
	 * should float.
	 */
	layout() {
		this.foundation.layout();
	}

	/**
	 * Synchronizes the list of options with the state of the foundation. Call
	 * this whenever menu options are dynamically updated.
	 */
	layoutOptions() {
		this.foundation.layoutOptions();
		this.menu.layout();
		if (this.hiddenInput) {
			this.hiddenInput.value = this.value;
		}
	}

	getDefaultFoundation() {
		const adapter: MDCSelectAdapter = {
			...this.getSelectAdapterMethods(),
			...this.getCommonAdapterMethods(),
			...this.getOutlineAdapterMethods(),
			...this.getLabelAdapterMethods(),
		};
		return new MDCSelectFoundation(adapter, this.getFoundationMap());
	}

	/**
	 * Handles setup for the menu.
	 */
	private menuSetup(menuFactory: (root: Element) => MDCMenu) {
		this.menuElement = this.root.querySelector(MDCSelectFoundation.strings.MENU_SELECTOR)!;
		this.menu = menuFactory(this.menuElement);
		this.menu.hasTypeahead = true;
		this.menu.singleSelection = true;
	}

	private createRipple(): MDCRipple {
		const adapter: MDCRippleAdapter = {
			...MDCRipple.createAdapter({root: this.selectAnchor}),
			registerInteractionHandler: (evtType, handler) => {
				this.selectAnchor.addEventListener(evtType, handler);
			},
			deregisterInteractionHandler: (evtType, handler) => {
				this.selectAnchor.removeEventListener(evtType, handler);
			},
		};
		return new MDCRipple(this.selectAnchor, new MDCRippleFoundation(adapter));
	}

	private getSelectAdapterMethods() {
		return {
			getMenuItemAttr: (menuItem: Element, attr: string) =>
				menuItem.getAttribute(attr),
			setSelectedText: (text: string) => {
				this.selectedText.textContent = text;
			},
			isSelectAnchorFocused: () => document.activeElement === this.selectAnchor,
			getSelectAnchorAttr: (attr: string) =>
				this.selectAnchor.getAttribute(attr),
			setSelectAnchorAttr: (attr: string, value: string) => {
				this.selectAnchor.setAttribute(attr, value);
			},
			removeSelectAnchorAttr: (attr: string) => {
				this.selectAnchor.removeAttribute(attr);
			},
			addMenuClass: (className: string) => {
				this.menuElement.classList.add(className);
			},
			removeMenuClass: (className: string) => {
				this.menuElement.classList.remove(className);
			},
			openMenu: () => {
				this.menu.open = true;
			},
			closeMenu: () => {
				this.menu.open = false;
			},
			getAnchorElement: () =>
				this.root.querySelector(MDCSelectFoundation.strings.SELECT_ANCHOR_SELECTOR)!,
			setMenuAnchorElement: (anchorEl: HTMLElement) => {
				this.menu.setAnchorElement(anchorEl);
			},
			setMenuAnchorCorner: (anchorCorner: Corner) => {
				this.menu.setAnchorCorner(anchorCorner);
			},
			setMenuWrapFocus: (wrapFocus: boolean) => {
				this.menu.wrapFocus = wrapFocus;
			},
			getSelectedIndex: () => {
				const index = this.menu.selectedIndex;
				return index instanceof Array ? index[0] : index;
			},
			setSelectedIndex: (index: number) => {
				this.menu.selectedIndex = index;
			},
			focusMenuItemAtIndex: (index: number) => {
				(this.menu.items[index] as HTMLElement).focus();
			},
			getMenuItemCount: () => {
				return this.menu.items.length;
			},
			getMenuItemValues: () => this.menuItemValues,
			getMenuItemTextAtIndex: (index: number) =>
				this.menu.getPrimaryTextAtIndex(index),
			isTypeaheadInProgress: () => this.menu.typeaheadInProgress,
			typeaheadMatchItem: (nextChar: string, startingIndex: number) =>
				this.menu.typeaheadMatchItem(nextChar, startingIndex),
		};
	}

	private getCommonAdapterMethods() {
		return {
			addClass: (className: string) => {
				this.root.classList.add(className);
			},
			removeClass: (className: string) => {
				this.root.classList.remove(className);
			},
			hasClass: (className: string) => this.root.classList.contains(className),
			setRippleCenter: (normalizedX: number) => {
				this.lineRipple && this.lineRipple.setRippleCenter(normalizedX);
			},
			activateBottomLine: () => {
				this.lineRipple && this.lineRipple.activate();
			},
			deactivateBottomLine: () => {
				this.lineRipple && this.lineRipple.deactivate();
			},
			notifyChange: (value: string) => {
				const index = this.selectedIndex;
				this.emit<MDCSelectEventDetail>(MDCSelectFoundation.strings.CHANGE_EVENT, {value, index}, true /* shouldBubble  */);
				if (this.hiddenInput) {
					this.hiddenInput.value = value;
				}
			},
		};
	}

	private getOutlineAdapterMethods() {
		return {
			hasOutline: () => Boolean(this.outline),
			notchOutline: (labelWidth: number) => {
				this.outline && this.outline.notch(labelWidth);
			},
			closeOutline: () => {
				this.outline && this.outline.closeNotch();
			},
		};
	}

	private getLabelAdapterMethods() {
		return {
			hasLabel: () => !!this.label,
			floatLabel: (shouldFloat: boolean) => {
				this.label && this.label.float(shouldFloat);
			},
			getLabelWidth: () => this.label ? this.label.getWidth() : 0,
			setLabelRequired: (isRequired: boolean) => {
				this.label && this.label.setRequired(isRequired);
			},
		};
	}

	/**
	 * Calculates where the line ripple should start based on the x coordinate within the component.
	 */
	private getNormalizedXCoordinate(evt: MouseEvent | TouchEvent): number {
		const targetClientRect = (evt.target as Element).getBoundingClientRect();
		const xCoordinate =
			this.isTouchEvent(evt) ? evt.touches[0].clientX : evt.clientX;
		return xCoordinate - targetClientRect.left;
	}

	private isTouchEvent(evt: MouseEvent | TouchEvent): evt is TouchEvent {
		return Boolean((evt as TouchEvent).touches);
	}

	/**
	 * Returns a map of all subcomponents to subfoundations.
	 */
	private getFoundationMap(): Partial<MDCSelectFoundationMap> {
		return {
			helperText: this.helperText ? this.helperText.foundationForSelect :
				undefined,
			leadingIcon: this.leadingIcon ? this.leadingIcon.foundationForSelect :
				undefined,
		};
	}
}

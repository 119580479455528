import React, {PropsWithChildren} from 'react';

interface IProps extends React.HTMLAttributes<any> {
}

type Props = PropsWithChildren<IProps>;

export default class DataTableHead extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, ...attrs} = this.props;
		return (
			<thead {...attrs}>
				{children}
			</thead>
		);
	}
}

import React, {PropsWithChildren} from 'react';
import {
	EventType,
	SpecificEventListener,
} from '@material/base/types';
import {
	MDCTextFieldAdapter,
	MDCTextFieldFoundation,
	MDCTextFieldRootAdapter,
	MDCTextFieldInputAdapter,
	MDCTextFieldLabelAdapter,
	MDCTextFieldOutlineAdapter,
	MDCTextFieldLineRippleAdapter,
	MDCTextFieldNativeInputElement,
} from '@material/textfield';

import Outline from './outline';
import HelpText from './helptext';
import LineRipple from './lineripple';
import FloatingLabel from './floatinglabel';
import {bind, isNumber, cssClassName} from '../../../util';

interface IProps {
	className?: string;
	columns?: number;
	disabled?: boolean;
	helpText?: string;
	helpTextIsPersistent?: boolean;
	id?: string;
	inputClassName?: string;
	label?: string;
	max?: number | string;
	min?: number | string;
	name?: string;
	noLabel?: boolean;
	onChange?: (event: React.ChangeEvent) => any;
	outlined?: boolean;
	placeholder?: string;
	required?: boolean;
	rows?: number;
	step?: number | string;
	takeFocus?: boolean;
	textArea?: boolean;
	title?: string;
	type?: string;
	value?: string | number;
}

interface IState {
	classNames: Set<string>;
	labelFloat: boolean;
	labelRequire: boolean;
	labelShake: boolean;
}

type Props = PropsWithChildren<IProps>;
type State = IState;

export default class TextField extends React.Component<Props, State> {
	private _mdc: MDCTextFieldFoundation | null;
	private _outlineRef: React.RefObject<Outline>;
	private _lineRippleRef: React.RefObject<LineRipple>;
	private _rootRef: React.RefObject<HTMLDivElement>;
	private _inputRef: React.RefObject<HTMLElement>;
	private _floatingLabelRef: React.RefObject<FloatingLabel>;

	constructor(props: Props) {
		super(props);
		const classNames = [
			'mdc-text-field',
			props.outlined ?
				'mdc-text-field--outlined' :
				'mdc-text-field--filled',
		];
		if (props.noLabel) {
			classNames.push('mdc-text-field--no-label');
		}
		if (props.textArea) {
			classNames.push('mdc-text-field--textarea');
		}
		this.state = {
			labelFloat: false,
			labelShake: false,
			labelRequire: false,
			classNames: new Set(classNames),
		};
		this._mdc = null;
		this._rootRef = React.createRef();
		this._inputRef = React.createRef();
		this._outlineRef = React.createRef();
		this._lineRippleRef = React.createRef();
		this._floatingLabelRef = React.createRef();
	}

	componentDidMount(): void {
		const {disabled, takeFocus} = this.props;
		this._mdc = new MDCTextFieldFoundation(this._mdcAdapter());
		this._mdc.init();
		if (disabled) {
			this._mdc.setDisabled(disabled);
		}
		if (takeFocus) {
			this.focusInput();
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {disabled: disabledPrev, noLabel: noLabelPrev, outlined: outlinedPrev, takeFocus: takeFocusPrev} = prevProps;
		const {disabled, noLabel, outlined, takeFocus, value} = this.props;
		const {classNames, labelFloat} = this.state;
		let classNamesChanged = false;
		const mdc = this._mdc;
		if (mdc) {
			if (disabledPrev !== disabled) {
				mdc.setDisabled(Boolean(disabled));
			}
			if (noLabelPrev !== noLabel) {
				if (noLabel) {
					classNames.add('mdc-text-field--no-label');
				} else {
					classNames.delete('mdc-text-field--no-label');
				}
				classNamesChanged = true;
			}
			if (outlinedPrev !== outlined) {
				if (outlined) {
					classNames.add('mdc-text-field--outlined');
					classNames.delete('mdc-text-field--filled');
				} else {
					classNames.add('mdc-text-field--filled');
					classNames.delete('mdc-text-field--outlined');
				}
				classNamesChanged = true;
			}
			if ((takeFocusPrev !== takeFocus) && takeFocus) {
				this.focusInput();
			}
		}
		const newState: Pick<State, 'classNames' | 'labelFloat'> = {classNames, labelFloat};
		let stateChanged = false;
		if (classNamesChanged) {
			newState.classNames = new Set(classNames);
			stateChanged = true;
		}
		if (!labelFloat && (value || isNumber(value))) {
			newState.labelFloat = true;
			stateChanged = true;
		}
		if (stateChanged) {
			this.setState(newState);
		}
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	focusInput(): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.focus();
			const mdc = this._mdc;
			if (mdc) {
				mdc.activateFocus();
			}
		}
	}

	labelID(): string | undefined {
		const {id} = this.props;
		if (id) {
			return `id_${id}-label`;
		}
		return undefined;
	}

	render(): React.ReactNode {
		const {
			className,
			columns,
			disabled,
			helpText,
			helpTextIsPersistent,
			id,
			inputClassName,
			label,
			max,
			min,
			name,
			noLabel,
			onChange,
			outlined,
			placeholder,
			required,
			rows,
			step,
			textArea,
			title,
			type,
			value,
		} = this.props;
		const {
			classNames,
			labelFloat,
			labelShake,
			labelRequire,
		} = this.state;
		const inp = textArea ?
			<textarea
				className={cssClassName('mdc-text-field__input', inputClassName)}
				cols={columns}
				disabled={disabled}
				id={id}
				name={name}
				onChange={onChange}
				placeholder={noLabel ? placeholder : undefined}
				// @ts-ignore
				ref={this._inputRef}
				required={required}
				rows={rows}
				value={value}/> :
			<input
				className={cssClassName('mdc-text-field__input', inputClassName)}
				disabled={disabled}
				id={id}
				placeholder={noLabel ? placeholder : undefined}
				max={max}
				min={min}
				name={name}
				onChange={onChange}
				// @ts-ignore
				ref={this._inputRef}
				required={required}
				step={step}
				title={title}
				type={type || 'text'}
				value={value}/>;
		const lbl = (
			<FloatingLabel
				float={labelFloat}
				id={this.labelID()}
				ref={this._floatingLabelRef}
				required={labelRequire}
				shake={labelShake}>{label}</FloatingLabel>);
		return (
			<React.Fragment>
				<div className={cssClassName(className, ...classNames)} ref={this._rootRef}>
					{outlined ? null : <div className="mdc-text-field__ripple"/>}
					{outlined ?
						<React.Fragment>
							{inp}
							<Outline noLabel={noLabel} ref={this._outlineRef}>
								{lbl}
							</Outline>
						</React.Fragment> :
						<React.Fragment>
							{lbl}
							{inp}
						</React.Fragment>}
					{outlined ? null : <LineRipple ref={this._lineRippleRef}/>}
				</div>
				{helpText ?
					<div className="mdc-text-field-helper-line">
						<HelpText persistent={helpTextIsPersistent}>{helpText}</HelpText>
					</div> :
					null}
			</React.Fragment>
		);
	}

	@bind
	private _mdcActivateLineRipple(): void {
		const comp = this._lineRippleRef.current;
		if (comp) {
			comp.activate();
		}
	}

	@bind
	private _mdcAdapter(): MDCTextFieldAdapter {
		return {
			...this._mdcRootAdapter(),
			...this._mdcInputAdapter(),
			...this._mdcLabelAdapter(),
			...this._mdcLineRippleAdapter(),
			...this._mdcOutlineAdapter(),
		};
	}

	@bind
	private _mdcAddClass(value: string): void {
		const {classNames} = this.state;
		classNames.add(value);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcCloseOutline(): void {
		const comp = this._outlineRef.current;
		if (comp) {
			comp.closeNotch();
		}
	}

	@bind
	private _mdcDeactivateLineRipple(): void {
		const comp = this._lineRippleRef.current;
		if (comp) {
			comp.deactivate();
		}
	}

	@bind
	private _mdcDeregisterInputInteractionHandler<K extends EventType>(evtType: K, handler: SpecificEventListener<K>): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.removeEventListener(evtType, handler);
		}
	}

	@bind
	private _mdcDeregisterTextFieldInteractionHandler<K extends EventType>(evtType: K, handler: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.removeEventListener(evtType, handler);
		}
	}

	@bind
	private _mdcDeregisterValidationAttributeChangeHandler(observer: MutationObserver): void {
		observer.disconnect();
	}

	@bind
	private _mdcFloatLabel(floatLabel: boolean): void {
		this.setState({labelFloat: floatLabel});
	}

	@bind
	private _mdcGetLabelWidth(): number {
		const comp = this._floatingLabelRef.current;
		if (comp) {
			return comp.width();
		}
		return 0;
	}

	@bind
	private _mdcGetNativeInput(): MDCTextFieldNativeInputElement | null {
		return this._inputRef.current as MDCTextFieldNativeInputElement | null;
	}

	@bind
	private _mdcHasClass(value: string): boolean {
		return this.state.classNames.has(value);
	}

	@bind
	private _mdcHasLabel(): boolean {
		return Boolean(this._floatingLabelRef.current);
	}

	@bind
	private _mdcHasOutline(): boolean {
		return Boolean(this._outlineRef.current);
	}

	@bind
	private _mdcInputAdapter(): MDCTextFieldInputAdapter {
		return {
			deregisterInputInteractionHandler: this._mdcDeregisterInputInteractionHandler,
			getNativeInput: this._mdcGetNativeInput,
			isFocused: this._mdcIsFocused,
			registerInputInteractionHandler: this._mdcRegisterInputInteractionHandler,
			removeInputAttr: this._mdcRemoveInputAttr,
			setInputAttr: this._mdcSetInputAttr,
		};
	}

	@bind
	private _mdcIsFocused(): boolean {
		const elem = this._inputRef.current;
		return elem ? (document.activeElement === elem) : false;
	}

	@bind
	private _mdcLabelAdapter(): MDCTextFieldLabelAdapter {
		return {
			floatLabel: this._mdcFloatLabel,
			getLabelWidth: this._mdcGetLabelWidth,
			hasLabel: this._mdcHasLabel,
			setLabelRequired: this._mdcSetLabelRequired,
			shakeLabel: this._mdcShakeLabel,
		};
	}

	@bind
	private _mdcLineRippleAdapter(): MDCTextFieldLineRippleAdapter {
		return {
			activateLineRipple: this._mdcActivateLineRipple,
			deactivateLineRipple: this._mdcDeactivateLineRipple,
			setLineRippleTransformOrigin: this._mdcSetLineRippleTransformOrigin,
		};
	}

	@bind
	private _mdcNotchOutline(value: number): void {
		const comp = this._outlineRef.current;
		if (comp) {
			comp.setNotch(value);
		}
	}

	@bind
	private _mdcOutlineAdapter(): MDCTextFieldOutlineAdapter {
		return {
			closeOutline: this._mdcCloseOutline,
			hasOutline: this._mdcHasOutline,
			notchOutline: this._mdcNotchOutline,
		};
	}

	@bind
	private _mdcRegisterInputInteractionHandler<K extends EventType>(evtType: K, handler: SpecificEventListener<K>): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.addEventListener(evtType, handler);
		}
	}

	@bind
	private _mdcRegisterTextFieldInteractionHandler<K extends EventType>(evtType: K, handler: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.addEventListener(evtType, handler);
		}
	}

	@bind
	private _mdcRegisterValidationAttributeChangeHandler(handler: (attributeNames: string[]) => void): MutationObserver {
		const elem = this._inputRef.current;
		if (elem) {
			const observer = new MutationObserver((mutations: MutationRecord[]) => {
				handler(mutations.map(mut => mut.attributeName).filter(name => Boolean(name)) as string[]);
			});
			observer.observe(elem, {attributes: true});
			return observer;
		}
		return staticMutationObserver;
	}

	@bind
	private _mdcRemoveClass(value: string): void {
		const {classNames} = this.state;
		classNames.delete(value);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcRemoveInputAttr(name: string): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.removeAttribute(name);
		}
	}

	@bind
	private _mdcRootAdapter(): MDCTextFieldRootAdapter {
		return {
			addClass: this._mdcAddClass,
			deregisterTextFieldInteractionHandler: this._mdcDeregisterTextFieldInteractionHandler,
			deregisterValidationAttributeChangeHandler: this._mdcDeregisterValidationAttributeChangeHandler,
			hasClass: this._mdcHasClass,
			registerTextFieldInteractionHandler: this._mdcRegisterTextFieldInteractionHandler,
			registerValidationAttributeChangeHandler: this._mdcRegisterValidationAttributeChangeHandler,
			removeClass: this._mdcRemoveClass,
		};
	}

	@bind
	private _mdcSetInputAttr(name: string, value: string): void {
		const elem = this._inputRef.current;
		if (elem) {
			elem.setAttribute(name, value);
		}
	}

	@bind
	private _mdcSetLabelRequired(required: boolean): void {
		this.setState({labelRequire: required});
	}

	@bind
	private _mdcSetLineRippleTransformOrigin(normalizedX: number): void {
		const comp = this._lineRippleRef.current;
		if (comp) {
			comp.setRippleCenter(normalizedX);
		}
	}

	@bind
	private _mdcShakeLabel(shouldShake: boolean): void {
		this.setState({labelShake: shouldShake});
	}
}

const staticMutationObserver: MutationObserver = {
	disconnect(): void {
	},
	observe(): void {
	},
	takeRecords(): MutationRecord[] {
		return [];
	},
};

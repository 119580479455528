import {El, ElOpts, elOpts} from '../../el';
import {list, set, Url} from '../../tools';
import {Dialog} from '../../ui/dialog';
import {Menu} from '../../ui/menu';
import {ProjectTaskChangeEvt, ProjectTaskMenu} from '../../ui/projecttaskmenu';
import {bind, capitalize, debounce, isNumber, numberFormat} from '../../util';
import {apiService as svc} from '../../services';
import {ProjectService} from '../../services/project';
import {AlignmentFlag, CSS_CLASS_FULL_WIDTH, CSS_CLASS_NO_VALUE, ItemDataRole, Orientation, ProjectColumnName, ProjectEmailType, ProjectStatus, ProjectStatusDisplay, SearchFilterType, StandardButton} from '../../constants';
import {ListItem, ListItemSelectEvt} from '../../ui/list';
import {QBInvoiceAction, QBInvoiceActionEvt, QBInvoiceLinkMenu} from './qb';
import {AbstractItemModel, ModelIndex} from '../../itemmodel';
import {decodeQBInvoiceLinkText, encodeQBInvoiceLinkText} from './util';
import {Paginator, PaginatorEvt, PaginatorEvtType} from '../../ui/paginator';
import {Checkbox} from '../../ui/checkbox';
import {Radio} from '../../ui/radio';
import {Evt} from '../../evt';
import {Search, SearchAutoCompleteEvt, SearchEvt, SearchFilter, SearchFilterEvt, SearchMode} from './search';
import {Dia} from '../projectdetail/dia';
import {DataTableEvt} from '../../ui/datatable/evt';
import {TableItem, TableModel} from '../../ui/datatable/model';
import {Variant} from '../../variant';
import {TableEl, TableElOpts} from '../../ui/datatable/el';
import {IconButton, IconButtonOpts} from '../../ui/iconbutton';
import {Button, ButtonOpts} from '../../ui/button';
import {date, datetime} from '../../datetime';
import {Decimal} from '../../decimal';
import {AbstractTableView} from '../../views/abstracttableview';
import {CurrentMenu} from '../../ui/util/currentmenu';
import {isNetworkErrorObject} from '../../services/request';

type DialogCallback = (...args: Array<any>) => any;
type RequestedResource<T> = {requested: boolean; data: T;};

const DEBOUNCE_MS = 300;
const PaymentSectionId = 'pb-project-list-payment-section';

export class ProjectListView extends AbstractTableView {
	static UITableName: string = 'project_list';

	private batchPayment: BatchPayment;
	private clientGroups: RequestedResource<list<IGroup>>;
	private clientUsers: RequestedResource<list<IUser>>;
	private dialogCallback: DialogCallback | null;
	private dialogCtrl: Dialog | null;
	private listItemNumberMap: Map<ListItem, number>;
	private projects: list<IProject>;
	private search: Search | null;

	constructor() {
		super({
			model: new TableModel(0, 0),
			paginator: new Paginator(),
			root: document.getElementById('id_project-list-view'),
			tableElColumnMap: elForColumnMap,
			tableItemPrototype: ProjectTableItem,
		});
		this.batchPayment = new BatchPayment({root: document.getElementById(PaymentSectionId)});
		this.clientGroups = {requested: false, data: new list<IGroup>()};
		this.clientUsers = {requested: false, data: new list<IUser>()};
		this.dialogCallback = null;
		this.dialogCtrl = null;
		this.listItemNumberMap = new Map<ListItem, number>();
		this.projects = new list<IProject>();
		this.search = null;
	}

	private addSearchFilter(filter: IUIFilter): void {
		if (this.search) {
			this.search.addFilter(filter);
		}
	}

	private async addUiFilter(filter: IUIFilter): Promise<IUIFilter> {
		return await svc.ui.createFilter(ProjectListView.UITableName, filter);
	}

	private assignMenuEvt(evt: Evt, menu: Menu): void {
		if (evt.type() === Evt.Select) {
			const assignedIds = new list<number>();
			for (const obj of menu.checkedItems()) {
				const id = this.listItemNumberMap.get(obj);
				if (id !== undefined) {
					assignedIds.append(id);
				}
			}
			this.updateProjectAssigned(this.currentSlug(), assignedIds);
		}
	}

	private editorMenuEvt(evt: Evt, menu: Menu): void {
		if (evt.type() === Evt.Select) {
			const editorIds = new list<number>();
			for (const obj of menu.checkedItems()) {
				const id = this.listItemNumberMap.get(obj);
				if (id !== undefined) {
					editorIds.append(id);
				}
			}
			this.updateProjectEditors(this.currentSlug(), editorIds);
		}
	}

	private async createFilter(type: SearchFilterType, value: number | string, enabled: boolean = true, text: string = ''): Promise<IUIFilter> {
		// <type>__<id>__<enabled>
		//
		// Search for client Cindy Mueller:               USER__7__ON
		// Search for client group David Benford Group:   GROUP__20__ON
		// Search for the string "123 E Main Street":     TEXT__123 E Main Street__ON
		return await this.addUiFilter({
			attribute: this.searchFilterAttribute(type),
			id: -1,
			text,
			value: (new SearchFilter(enabled, type, value)).encode(),
		});
	}

	private currentProject(): IProject | null {
		const slug = this.currentSlug();
		return slug ?
			this.project(slug) :
			null;
	}

	private currentSearchInputValue(): string {
		return this.search ?
			this.search.inputValue() :
			'';
	}

	private currentSlug(): string {
		if (this.currentIndex.isValid()) {
			const tableItem = this.dataTableItem(this.currentIndex);
			return tableItem ?
				tableItem.slug :
				'';
		}
		return '';
	}

	protected dataTableItem(index: ModelIndex): ProjectTableItem | null {
		return <ProjectTableItem | null>super.dataTableItem(index);
	}

	protected async dataTableItemClickEvt(evt: DataTableEvt): Promise<void> {
		super.dataTableItemClickEvt(evt);
		const pos = evt.clientPos();
		const tableItem = this.dataTableItem(this.currentIndex);
		const slug = tableItem ? tableItem.slug : '';
		const userColLogIdx = this.userUiTableColumnLogicalIndex(this.currentIndex.column);
		const userCol = this.userUiTableColumnAtLogicalIndex(userColLogIdx);
		const uiCol = userCol ?
			this.uiTableColumn(userCol.uiTableColumnId) :
			null;
		if (uiCol && slug) {
			switch (uiCol.name) {
				case ProjectColumnName.Shooter:
					this.openProjectAssignMenu(
						(await svc.project.assignable(slug)).photography,
						pos.x(),
						pos.y());
					break;
				case ProjectColumnName.Editor:
					this.openProjectEditorMenu(
						(await svc.project.assignable(slug)).editing,
						pos.x(),
						pos.y());
					break;
				case ProjectColumnName.Status:
					this.openProjectStatusMenu(pos.x(), pos.y());
					break;
				case ProjectColumnName.Files:
					await this.openProjectFileLocationMenu(pos.x(), pos.y());
					break;
				case ProjectColumnName.Task:
					this.openProjectTaskMenu(
						new list(await svc.project.tasks(slug)),
						pos.x(),
						pos.y());
					break;
				case ProjectColumnName.QuickBooks:
					this.openQbInvoiceLinkMenu(
						await svc.group.app.quickbooks.get(slug),
						pos.x(),
						pos.y());
					break;
			}
		}
	}

	protected dataTableRowSelectionChangeEvt(evt: DataTableEvt): void {
		super.dataTableRowSelectionChangeEvt(evt);
		if (evt.orientation() === Orientation.Vertical) {
			const slugs = new list<string>();
			const selectedIndices = this.dataTable.selectedRows();
			const anySelected = selectedIndices.size() > 0;
			this.batchPayment.setVisible(anySelected);
			if (anySelected) {
				for (const idx of selectedIndices) {
					const tableItem = this.dataTableItem(idx);
					if (tableItem) {
						slugs.append(tableItem.slug);
					}
				}
			}
			this.updateBatchPayment(slugs);
		}
	}

	destroy(): void {
		this.destroyDialogCtrl();
		this.dialogCallback = null;
		if (this.search) {
			this.search.destroy();
		}
		this.search = null;
		this.clientGroups.data.clear();
		this.clientGroups.requested = false;
		this.clientUsers.data.clear();
		this.clientUsers.requested = false;
		this.listItemNumberMap.clear();
		this.batchPayment.destroy();
		this.projects.clear();
		super.destroy();
	}

	private destroyDialogCtrl(): void {
		if (this.dialogCtrl) {
			this.dialogCtrl.destroy();
			this.dialogCtrl = null;
		}
	}

	@bind
	private dialogResult(result: number): void {
		if (this.dialogCallback) {
			this.dialogCallback(result);
			this.dialogCallback = null;
		}
		this.destroyDialogCtrl();
	}

	private async ensureStatusFilters(existingFilters: Array<IUIFilter>): Promise<boolean> {
		const allStatuses = new set<string>();
		for (const k of Object.keys(ProjectStatus)) {
			const n = Number.parseInt(k);
			if (isNumber(n)) {
				allStatuses.add(k);
			}
		}
		const seenStatuses = new set<string>();
		for (const obj of existingFilters) {
			if (obj.attribute === 'status') {
				seenStatuses.add(SearchFilter.decode(obj.value).value);
			}
		}
		const missingStatuses = allStatuses.difference(seenStatuses);
		if (missingStatuses.size > 0) {
			for (const missing of missingStatuses) {
				const sf = new SearchFilter(true, SearchFilterType.UNKNOWN, missing);
				await this.addUiFilter({
					attribute: 'status',
					id: -1,
					text: '',
					value: sf.encode(),
				});
			}
		}
		return missingStatuses.size < 1;
	}

	private async fetchClientGroups(cachedOk: boolean = true): Promise<list<IGroup>> {
		if (cachedOk && this.clientGroups.requested) {
			return this.clientGroups.data;
		}
		this.clientGroups.requested = false;
		this.clientGroups.data = new list(await svc.group.client.group.list());
		this.clientGroups.requested = true;
		return this.clientGroups.data;
	}

	private async fetchClientUsers(cachedOk: boolean = true): Promise<list<IUser>> {
		if (cachedOk && this.clientUsers.requested) {
			return this.clientUsers.data;
		}
		this.clientUsers.requested = false;
		this.clientUsers.data = new list(await svc.group.client.user.list());
		this.clientUsers.requested = true;
		return this.clientUsers.data;
	}

	protected async fetchData(params?: Partial<IPaginatedProjectRequest>): Promise<void> {
		this.beginFetchData();
		await this._debouncedFetchProjects(params);
	}

	@bind
	private async _fetchProjects(params?: Partial<IPaginatedProjectRequest>): Promise<void> {
		const res = await svc.project.list(ProjectService.paginatedRequestParams(await this.fetchRequestParams(params)));
		this.projects = new list<IProject>(res.objects);
		this.paginator && this.paginator.pageInfo().setPaginatedObject(res);
		this.setData(res.objects);
		this.endFetchData();
	}

	private _debouncedFetchProjects: (params?: Partial<IPaginatedProjectRequest>) => Promise<void> = debounce(this._fetchProjects, DEBOUNCE_MS);

	private async fetchRequestParams(params?: Partial<IPaginatedProjectRequest>): Promise<Partial<IPaginatedProjectRequest>> {
		params = params ? {...params} : {};
		const filters: Array<IProjectFilter> = [];
		const groupIds: set<number> = new set<number>();
		const queries: set<string> = new set<string>();
		const statuses: set<number> = new set<number>();
		const userIds: set<number> = new set<number>();
		const enabledFilters = this.search ?
			this.search.enabledFilters() :
			[];
		const qs = this.currentSearchInputValue().trim();
		if (qs.length > 0) {
			queries.add(qs);
		}
		for (const filter of enabledFilters) {
			const searchFilter = SearchFilter.decode(filter.value);
			const filterType = searchFilter.type;
			const filterValue = searchFilter.value;
			if (filter.attribute === 'status') {
				if (filterValue in ProjectStatus) {
					statuses.add(Number(filterValue));
				}
			} else {
				switch (filterType) {
					case SearchFilterType.GROUP: {
						const n = Number.parseInt(filterValue);
						if (isNumber(n)) {
							groupIds.add(n);
						} else {
							filters.push({field: filterType.toLowerCase(), value: filterValue});
						}
						break;
					}
					case SearchFilterType.USER: {
						const n = Number.parseInt(filterValue);
						if (isNumber(n)) {
							userIds.add(n);
						} else {
							filters.push({field: filterType.toLowerCase(), value: filterValue});
						}
						break;
					}
					case SearchFilterType.TEXT: {
						queries.add(filterValue);
						break;
					}
				}
			}
		}
		if (params.filters === undefined && filters.length > 0) {
			params.filters = filters;
		}
		if (params.group === undefined && groupIds.size > 0) {
			params.group = groupIds.toArray();
		}
		if ((params.q === undefined) && (queries.size > 0)) {
			params.q = queries.toArray();
		}
		if (params.status === undefined && statuses.size > 0) {
			params.status = statuses.toArray();
		}
		if (params.user === undefined && userIds.size > 0) {
			params.user = userIds.toArray();
		}
		if (this.paginator && (params.page === undefined)) {
			params.page = this.paginator.pageInfo().currentPageNumber();
		}
		if (this.paginator && (params.show === undefined)) {
			params.show = this.paginator.pageInfo().perPageCount();
		}
		return params;
	}

	private async filterChanged(filter: IUIFilter): Promise<void> {
		await svc.ui.updateFilter(
			ProjectListView.UITableName,
			filter.id,
			filter);
	}

	private async filterDeleted(filter: IUIFilter): Promise<void> {
		await svc.ui.deleteFilter(
			ProjectListView.UITableName,
			filter.id);
	}

	protected async init(model?: AbstractItemModel | null): Promise<void> {
		await super.init(model);
		if (!(await this.ensureStatusFilters(this.userUiTableFilters()))) {
			this.setUserUiTable(await svc.ui.table(this.userUiTablePk));
		}
		this.dataTable.setRowSelectionEnabled(!window.isProducer);
		this.search = new Search();
		this.search.onEvt(this.searchEvt);
		this.setUiTableFilters(this.userUiTableFilters());
		await this.fetchData();
	}

	@bind
	protected menuEvt(evt: Evt): void {
		if (evt.type() === Evt.StateChange) {
			return;
		}
		switch (this.currentMenu.type) {
			case CurrentMenu.Assign:
				if (this.currentMenu.instance) {
					this.assignMenuEvt(evt, this.currentMenu.instance);
				}
				break;
			case CurrentMenu.Editor:
				if (this.currentMenu.instance) {
					this.editorMenuEvt(evt, this.currentMenu.instance);
				}
				break;
			case CurrentMenu.Status:
				if (this.currentMenu.instance) {
					this.projectStatusMenuEvt(evt, this.currentMenu.instance);
				}
				break;
			case CurrentMenu.Task:
				this.projectTaskMenuEvt(evt);
				break;
			case CurrentMenu.QBInvoice:
				if (this.currentMenu.instance && (this.currentMenu.instance instanceof QBInvoiceLinkMenu)) {
					this.qbInvoiceMenuEvt(evt, this.currentMenu.instance);
				}
				break;
			case CurrentMenu.DataLocationOption:
				if (this.currentMenu.instance) {
					this.projectDataLocationOptionMenuEvt(evt, this.currentMenu.instance);
				}
				break;
			default:
				super.menuEvt(evt);
		}
	}

	private openProjectAssignMenu(users: list<IUser> | Array<IUser>, x: number, y: number): void {
		this.listItemNumberMap.clear();
		const proj = this.currentProject();
		if (proj) {
			const assigned = new set<number>(proj.assigned);
			const instance = new Menu({persistOnSelect: true});
			for (const obj of users) {
				const checkbox = new Checkbox();
				checkbox.setInputId(`id_project-assigned-${obj.id}`);
				checkbox.setChecked(assigned.has(obj.id));
				const item = instance.addItem({
					classNames: 'pb-check-list-item',
					leadingEl: checkbox,
					text: obj.name,
					textIsLabel: true,
				});
				checkbox.setBuddy(item.textEl());
				this.listItemNumberMap.set(item, obj.id);
			}
			this.setCurrentMenu(instance, CurrentMenu.Assign);
			this.currentMenu.open(x, y);
		} else {
			console.log('ProjectListView::openProjectAssignMenu: Current project is not defined');
		}
	}

	private openProjectEditorMenu(users: list<IUser> | Array<IUser>, x: number, y: number): void {
		this.listItemNumberMap.clear();
		const proj = this.currentProject();
		if (proj) {
			const editors = new set<number>(proj.editors);
			const instance = new Menu({persistOnSelect: true});
			for (const obj of users) {
				const checkbox = new Checkbox();
				checkbox.setInputId(`id_project-editor-${obj.id}`);
				checkbox.setChecked(editors.has(obj.id));
				const item = instance.addItem({
					classNames: 'pb-check-list-item',
					leadingEl: checkbox,
					text: obj.name,
					textIsLabel: true,
				});
				checkbox.setBuddy(item.textEl());
				this.listItemNumberMap.set(item, obj.id);
			}
			this.setCurrentMenu(instance, CurrentMenu.Editor);
			this.currentMenu.open(x, y);
		} else {
			console.log('ProjectListView::openProjectEditorMenu: Current project is not defined');
		}
	}

	private async openProjectFileLocationMenu(x: number, y: number): Promise<void> {
		this.listItemNumberMap.clear();
		const proj = this.currentProject();
		if (proj) {
			const instance = new Menu();
			const opts = await svc.etc.dataLocationOptions.list();
			for (const opt of opts) {
				const radio = new Radio();
				radio.setInputId(`id_project-data-location-option-${opt.id}`);
				radio.setName(`project-data-location-option-${instance.instanceNumber}`);
				radio.setChecked(!!proj.dataLocation && (proj.dataLocation.dataLocationOptionId === opt.id));
				const item = instance.addItem({
					classNames: 'pb-check-list-item',
					leadingEl: radio,
					text: opt.label,
					textIsLabel: true,
				});
				this.listItemNumberMap.set(item, opt.id);
				radio.setBuddy(item.textEl());
			}
			const radio = new Radio();
			radio.setInputId('id_project-data-location-option-0');
			radio.setName('project-data-location-option-clear');
			const item = instance.addItem({
				classNames: 'pb-check-list-item',
				leadingEl: radio,
				text: 'Clear selection',
				textIsLabel: true,
			});
			this.listItemNumberMap.set(item, 0);
			radio.setBuddy(item.textEl());
			this.setCurrentMenu(instance, CurrentMenu.DataLocationOption);
			this.currentMenu.open(x, y);
		}
	}

	private openProjectStatusMenu(x: number, y: number): void {
		this.listItemNumberMap.clear();
		const proj = this.currentProject();
		if (proj) {
			const instance = new Menu();
			for (const k in ProjectStatus) {
				if (ProjectStatus.hasOwnProperty(k)) {
					const key = Number.parseInt(k);
					if (!isNumber(key)) {
						continue;
					}
					const radio = new Radio();
					radio.setInputId(`id_project-status-${key}`);
					radio.setName(`project-status-${instance.instanceNumber}`);
					radio.setChecked(proj.status === key);
					const item = instance.addItem({
						classNames: 'pb-check-list-item',
						leadingEl: radio,
						text: ProjectStatusDisplay[<ProjectStatus>key],
						textIsLabel: true,
					});
					this.listItemNumberMap.set(item, key);
					radio.setBuddy(item.textEl());
				}
			}
			this.setCurrentMenu(instance, CurrentMenu.Status);
			this.currentMenu.open(x, y);
		}
	}

	private openProjectTaskMenu(tasks: Iterable<IProjectTask>, x: number, y: number): void {
		const instance = new ProjectTaskMenu();
		instance.setTasks(tasks);
		this.setCurrentMenu(instance, CurrentMenu.Task);
		this.currentMenu.open(x, y);
	}

	private openQbErrorDialog(message: string): void {
		if (!this.dialogCtrl) {
			this.dialogCtrl = new Dialog();
		}
		if (this.dialogCtrl) {
			this.dialogCallback = this.qbInvoiceLinkMenuErrorDialogCallback;
			this.dialogCtrl.setTitle('Error');
			this.dialogCtrl.setMessage(message);
			this.dialogCtrl.setStandardButtons(StandardButton.Ok);
			this.dialogCtrl.open(this.dialogResult);
		}
	}

	private openQbInvoiceLinkMenu(linkData: IQuickBooksLinkingResponse, x: number, y: number): void {
		const instance = new QBInvoiceLinkMenu();
		instance.setLinkData(linkData);
		this.setCurrentMenu(instance, CurrentMenu.QBInvoice);
		this.currentMenu.open(x, y);
	}

	@bind
	protected paginatorEvt(evt: Evt): void {
		if (evt instanceof PaginatorEvt) {
			const params: Partial<IPaginatedProjectRequest> = {};
			switch (evt.type()) {
				case PaginatorEvtType.PerPageCountChanged:
					params.show = evt.perPageCount();
					break;
				case PaginatorEvtType.PageChanged:
					params.page = evt.page();
					break;
				default:
					return super.paginatorEvt(evt);
			}
			this.fetchData(params);
		}
	}

	private project(slug: string): IProject | null {
		for (const project of this.projects) {
			if (project.slug === slug) {
				return project;
			}
		}
		return null;
	}

	private async projectDataLocationOptionChange(optPk: DataLocationOptionPk): Promise<void> {
		const proj = this.currentProject();
		if (proj) {
			if ((optPk < 1) && (!proj.dataLocation || !proj.dataLocation.dataLocationOptionId)) {
				return;
			}
			if ((optPk > 0) && proj.dataLocation && (proj.dataLocation.dataLocationOptionId === optPk)) {
				return;
			}
			this.currentMenu.close();
			await this.updateProjectDataLocation(proj.slug, optPk);
		} else {
			console.log('ProjectListView::projectDataLocationOptionChange: Current project is not defined.');
		}
	}

	private async projectStatusChange(newStatus: number): Promise<void> {
		const obj = this.currentProject();
		if (obj) {
			if (obj.status !== newStatus) {
				this.currentMenu.close();
				this.destroyDialogCtrl();
				const objs = await svc.project.emailList(obj.slug);
				let idx: number = -1;
				switch (newStatus) {
					case ProjectStatus.Pending:
						break;
					case ProjectStatus.InProgress:
						idx = objs.findIndex(x => (x.emailType === ProjectEmailType.Confirmed));
						break;
					case ProjectStatus.Complete:
						idx = objs.findIndex(x => (x.emailType === ProjectEmailType.Complete));
						break;
					case ProjectStatus.OnHold:
						break;
				}
				if ((idx >= 0) && !objs[idx].wasSent) {
					this.dialogCallback = this.projectStatusDialogCallback.bind(this, obj.slug, newStatus);
					const dia = new Dia();
					dia.setTitle('Send email?');
					dia.setMessage('This action COULD dispatch an email.');
					dia.closeTextInput();
					dia.clearTextInput();
					dia.showTextInput();
					dia.setStandardButtons(StandardButton.Cancel | StandardButton.No | StandardButton.Yes);
					this.dialogCtrl = dia;
					dia.open(this.dialogResult);
				} else {
					this.projectStatusDialogCallback(obj.slug, newStatus, StandardButton.Yes);
				}
			}
		} else {
			console.log('ProjectListView::projectStatusMenuChangeAction: Current project is not defined.');
		}
	}

	private projectStatusDialogCallback(projectSlug: string, projectStatus: number, dialogResult: number): void {
		if (dialogResult === StandardButton.Cancel) {
			return;
		}
		const d = <Dia | null>this.dialogCtrl;
		let optMsg = '';
		if (d) {
			optMsg = d.textInputValue();
		}
		this.updateProjectStatus(
			projectSlug,
			projectStatus,
			optMsg,
			dialogResult === StandardButton.Yes);
	}

	private projectStatusMenuEvt(evt: Evt, menu: Menu): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const item = menu.item(evt.index());
			if (item) {
				const projStatus = this.listItemNumberMap.get(item);
				if (projStatus !== undefined) {
					this.projectStatusChange(projStatus);
				}
			}
		}
	}

	private projectDataLocationOptionMenuEvt(evt: Evt, menu: Menu): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const item = menu.item(evt.index());
			if (item) {
				const dataLocOptPk = this.listItemNumberMap.get(item);
				if (dataLocOptPk !== undefined) {
					this.projectDataLocationOptionChange(dataLocOptPk);
				}
			}
		}
	}

	private projectTaskMenuEvt(evt: Evt): void {
		if ((evt.type() === Evt.Change) && (evt instanceof ProjectTaskChangeEvt)) {
			const task = evt.task();
			if (isNumber(task.projectId)) {
				const projectSlug = this.currentSlug();
				if (projectSlug) {
					task.isComplete = evt.checked();
					this.updateProjectTask(projectSlug, task);
				} else {
					console.log('ProjectListView::projectTaskMenuChangeAction: Invalid project slug returned.');
				}
			}
		}
	}

	@bind
	private qbInvoiceLinkMenuErrorDialogCallback(): void {
		if ((this.currentMenu.type === CurrentMenu.QBInvoice) && this.currentMenu.isOpen()) {
			(<QBInvoiceLinkMenu>this.currentMenu.instance).focus();
		}
	}

	private async qbInvoiceMenuEvt(evt: Evt, menu: QBInvoiceLinkMenu): Promise<void> {
		if (!((evt.type() === Evt.ActionRequest) && (evt instanceof QBInvoiceActionEvt))) {
			return;
		}
		const slug = this.currentSlug();
		if (!slug) {
			console.log('qbInvoiceLinkMenuQBAction: Project slug is not defined.');
			return;
		}
		const payload: IQuickBooksLinkingRequest = {
			docNumber: null,
			slug,
			type: '',
		};
		switch (evt.action()) {
			case QBInvoiceAction.CreateInvoice:
				payload.type = 'REAL';
				break;
			case QBInvoiceAction.HasInvoice:
				payload.type = 'FAKE';
				break;
			case QBInvoiceAction.LinkInvoice:
				payload.docNumber = evt.docNumber();
				payload.type = 'MANUAL';
				break;
			case QBInvoiceAction.UnlinkInvoice:
				payload.type = 'UNLINK';
				break;
		}
		const {response, error, message} = await this.sendQbLinkRequest(payload);
		if (response) {
			const {docNumber, hasFakeLink} = response;
			if (this.currentIndex.isValid()) {
				this.setQbTableItemData(
					this.currentIndex,
					encodeQBInvoiceLinkText(docNumber, hasFakeLink),
					docNumber);
			}
			if (menu.isOpen()) {
				menu.setLinkData(response);
			}
		}
		if (error && message) {
			this.openQbErrorDialog(message);
		} else {
			this.setCurrentMenu(null, CurrentMenu.NoMenu);
		}
	}

	private replaceProject(project: IProject): void {
		let replaced = false;
		for (let i = 0; i < this.projects.size(); ++i) {
			if (this.projects.at(i).slug === project.slug) {
				this.projects.replace(i, project);
				replaced = true;
				break;
			}
		}
		if (replaced) {
			this.setData(this.projects);
		}
	}

	@bind
	private searchEvt(evt: Evt): void {
		switch (evt.type()) {
			case SearchEvt.InputChange:
				this.searchInputChangeEvt();
				break;
			case Evt.Change:
				if (evt instanceof SearchFilterEvt) {
					this.searchFilterChangeEvt(evt);
				}
				break;
			case Evt.Delete:
				if (evt instanceof SearchFilterEvt) {
					this.searchFilterDeleteEvt(evt);
				}
				break;
			case Evt.Submit:
				if (evt instanceof SearchEvt) {
					this.searchInputSubmitEvt(evt);
				}
				break;
			case SearchEvt.AutoComplete:
				if (evt instanceof SearchAutoCompleteEvt) {
					this.searchInputAutoCompleteEvt(evt);
				}
				break;
			case SearchEvt.SearchFilterTypeChange:
				if (evt instanceof SearchEvt) {
					this.searchFilterTypeChangeEvt(evt);
				}
				break;
		}
	}

	private searchFilterAttribute(type: SearchFilterType): string {
		switch (type) {
			case SearchFilterType.USER:
				return 'users';
			case SearchFilterType.GROUP:
				return 'users__group';
			default:
				return '';
		}
	}

	private async searchFilterChangeEvt(evt: SearchFilterEvt): Promise<void> {
		await this.filterChanged(evt.filter());
		await this.fetchData();
	}

	private async searchFilterDeleteEvt(evt: SearchFilterEvt): Promise<void> {
		const filter = evt.filter();
		const enabled = SearchFilter.decode(filter.value).enabled;
		await this.filterDeleted(filter);
		if (enabled) {
			await this.fetchData();
		}
	}

	private async searchFilterTypeChangeEvt(evt: SearchEvt): Promise<void> {
		if (!this.search) {
			return;
		}
		let strings: list<string>;
		switch (evt.searchFilterType()) {
			case SearchFilterType.GROUP: {
				strings = (await this.fetchClientGroups(true)).map(obj => obj.name);
				break;
			}
			case SearchFilterType.USER: {
				strings = (await this.fetchClientUsers(true)).map(obj => obj.name);
				break;
			}
			default: {
				this.search.setAutoCompleterEnabled(false);
				return;
			}
		}
		this.search.setAutoCompleterEnabled(true, strings);
	}

	private async searchInputAutoCompleteEvt(evt: SearchAutoCompleteEvt): Promise<void> {
		if (!this.search) {
			return;
		}
		const index = evt.index();
		const filterType = evt.searchFilterType();
		if (filterType === SearchFilterType.GROUP) {
			if ((index >= 0) && (index < this.clientGroups.data.size())) {
				const obj = this.clientGroups.data.at(index);
				const filter = await this.createFilter(
					SearchFilterType.GROUP,
					obj.id,
					true,
					obj.name.trim());
				this.search.addFilter(filter);
			}
		} else if (filterType === SearchFilterType.USER) {
			if ((index >= 0) && (index < this.clientUsers.data.size())) {
				const obj = this.clientUsers.data.at(index);
				const filter = await this.createFilter(
					SearchFilterType.USER,
					obj.id,
					true,
					obj.name.trim());
				this.search.addFilter(filter);
			}
		}
		this.search.setAutoCompleterEnabled(false);
		this.search.setSearchMode(SearchMode.DefaultInputMode);
		await this.fetchData();
	}

	private searchInputChangeEvt(): void {
		this.fetchData();
	}

	private async searchInputSubmitEvt(evt: SearchEvt): Promise<void> {
		if (!this.search) {
			return;
		}
		const val = this.search.inputValue().trim();
		if (val.length > 0) {
			this.search.setInputValue();
			this.search.setSearchMode(SearchMode.DefaultInputMode);
			const filter = await this.createFilter(evt.searchFilterType(), val, true, val);
			this.search.addFilter(filter);
			await this.fetchData();
		}
	}

	private async sendQbLinkRequest(payload: IQuickBooksLinkingRequest): Promise<{response: IQuickBooksLinkingResponse | null; error: boolean; message: string;}> {
		let error: boolean;
		let message: string;
		let response: IQuickBooksLinkingResponse | null = null;
		try {
			response = await svc.group.app.quickbooks.link(payload);
			error = response.error;
			message = response.message;
		} catch (err) {
			error = true;
			message = 'An error occurred but gave little info about exactly what happened.';
			if (isNetworkErrorObject(err) && err.response && (err.response.data.error.message.trim().length > 0)) {
				message = err.response.data.error.message;
			} else {
				if ('message' in err) {
					message = err.message;
				}
			}
		}
		return {response, error, message};
	}

	private setAssignedTableItemData(index: ModelIndex, data: Array<string>): void {
		const tableItem = this.dataTable.item(index);
		if (tableItem) {
			tableItem.setText(assignedString(data));
		}
	}

	private setData(objs: Iterable<IProject>): void {
		this.beginSetData();
		this.projects = new list(objs);
		const columnCount = this.dataTable.columnCount();
		const rowCount = this.projects.size();
		this.dataTable.clearContents();
		this.dataTable.setRowCount(rowCount);
		const visibleUserUiColumns = this.visibleUserUiTableColumns();
		for (let row = 0; row < rowCount; ++row) {
			const proj = this.projects.at(row);
			for (let column = 0; column < columnCount; ++column) {
				const userCol = visibleUserUiColumns[column];
				const uiCol = this.uiTableColumn(userCol.uiTableColumnId);
				const uiColName = uiCol ? uiCol.name : '';
				let useUrl: boolean = true;
				const itemData: Array<[ItemDataRole, Variant]> = [];
				switch (uiColName) {
					case ProjectColumnName.Created:
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(datetime.fromisoformat(proj.created))],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)],
						);
						break;
					case ProjectColumnName.PhotosDueDateTime: {
						if (proj.dueDate) {
							if (proj.dueTime) {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(datetime.fromisoformat(`${proj.dueDate}T${proj.dueTime}`)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							} else {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(date.fromisoformat(proj.dueDate)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							}
						}
						break;
					}
					case ProjectColumnName.VideoDueDateTime: {
						if (proj.videoDueDate) {
							if (proj.videoDueTime) {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(datetime.fromisoformat(`${proj.videoDueDate}T${proj.videoDueTime}`)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							} else {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(date.fromisoformat(proj.videoDueDate)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							}
						}
						break;
					}
					case ProjectColumnName.ClientDueDateTime: {
						if (proj.clientDueDate) {
							if (proj.clientDueTime) {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(datetime.fromisoformat(`${proj.clientDueDate}T${proj.clientDueTime}`)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							} else {
								itemData.push(
									[
										ItemDataRole.DisplayRole,
										new Variant(date.fromisoformat(proj.clientDueDate)),
									],
									[
										ItemDataRole.TextAlignmentRole,
										new Variant(AlignmentFlag.AlignRight),
									],
								);
							}
						}
						break;
					}
					case ProjectColumnName.Client:
					case ProjectColumnName.TeamMember:
						itemData.push([ItemDataRole.DisplayRole, new Variant(proj.usersDisplay)]);
						break;
					case ProjectColumnName.Description: {
						const text = proj.description.trim();
						const displayText = (text.length > 24) ?
							`${text.slice(0, 24)}...` :
							text;
						itemData.push([
							ItemDataRole.DisplayRole, new Variant(displayText),
						]);
						if (displayText !== text) {
							itemData.push([
								ItemDataRole.ToolTipRole, new Variant(text),
							]);
						}
						useUrl = text.length > 0;
						break;
					}
					case ProjectColumnName.Location:
						itemData.push([ItemDataRole.DisplayRole, new Variant(proj.locationDisplay)]);
						break;
					case ProjectColumnName.LocationName:
						itemData.push([ItemDataRole.DisplayRole, new Variant(proj.locationName)]);
						break;
					case ProjectColumnName.Task: {
						if (proj.lastTask) {
							itemData.push([ItemDataRole.DisplayRole, new Variant(proj.lastTask)]);
						} else {
							itemData.push([ItemDataRole.DisplayRole, new Variant(null)]);
						}
						useUrl = !window.isProducer;
						break;
					}
					case ProjectColumnName.InvoiceTotal: {
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(new Decimal(proj.invoiceTotal, 2))],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)],
						);
						if (proj.paid) {
							itemData.push(
								[ItemDataRole.BackgroundRole, new Variant('rgba(0, 204, 117, 1)')],
								[ItemDataRole.ForegroundRole, new Variant('rgba(255, 255, 255, 1)')]);
						}

						break;
					}
					case ProjectColumnName.Media: {
						itemData.push(
							[ItemDataRole.DecorationRole, new Variant(proj.hasReleasedUrls ? 'link' : 'link_off')],
						);
						if (!proj.hasReleasedUrls) {
							itemData.push([ItemDataRole.ForegroundRole, new Variant('rgb(239, 239, 239)')]);
						}
						break;
					}
					case ProjectColumnName.QuickBooks: {
						itemData.push([ItemDataRole.DisplayRole, new Variant(encodeQBInvoiceLinkText(proj.qbDocNumber, proj.qbHasFakeLink))]);
						useUrl = false;
						break;
					}
					case ProjectColumnName.QuickBooksInvoiceId: {
						itemData.push(
							[ItemDataRole.DisplayRole, new Variant(proj.qbDocNumber)],
							[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						useUrl = false;
						break;
					}
					case ProjectColumnName.Scheduled: {
						if (proj.scheduled) {
							itemData.push(
								[ItemDataRole.DisplayRole, new Variant(datetime.fromisoformat(proj.scheduled))],
								[ItemDataRole.TextAlignmentRole, new Variant(AlignmentFlag.AlignRight)]);
						} else {
							itemData.push([ItemDataRole.DisplayRole, new Variant(null)]);
						}
						break;
					}
					case ProjectColumnName.Services:
						itemData.push([ItemDataRole.DisplayRole, new Variant(proj.services)]);
						break;
					case ProjectColumnName.Status: {
						itemData.push([ItemDataRole.DisplayRole, new Variant(capitalize(proj.statusDisplay))]);
						useUrl = !window.isProducer;
						break;
					}
					case ProjectColumnName.Files: {
						itemData.push([ItemDataRole.DisplayRole, new Variant(capitalize(proj.dataLocationDisplay))]);
						useUrl = !window.isProducer;
						break;
					}
					case ProjectColumnName.Shooter:
					case ProjectColumnName.Editor: {
						const s = assignedString(
							(uiColName === ProjectColumnName.Shooter) ?
								proj.assignedDisplay :
								proj.editorsDisplay,
						);
						itemData.push([ItemDataRole.DisplayRole, new Variant(s)]);
						useUrl = !window.isProducer;
						break;
					}
					case ProjectColumnName.CreatedByUser: {
						itemData.push([ItemDataRole.DisplayRole, new Variant(proj.createdByUserDisplay)]);
						// useUrl = !window.isProducer;
						// useUrl = false;
						break;
					}
				}
				if (useUrl) {
					const url = `${window.location.protocol}//${window.location.host}${proj.absoluteUrl}`;
					itemData.push([ItemDataRole.UserRole, new Variant(new Url(url))]);
				}
				const item = new ProjectTableItem();
				item.slug = proj.slug;
				for (const [role, val] of itemData) {
					item.setData(role, val);
				}
				this.dataTable.setItem(row, column, item);
			}
		}
		this.endSetData();
	}

	private setQbTableItemData(index: ModelIndex, encodedLinkText: string, docNumber: string): void {
		const tableItem = this.dataTable.item(index);
		if (tableItem) {
			const row = tableItem.row();
			tableItem.setText(encodedLinkText);
			const visibleUserCols = this.visibleUserUiTableColumns();
			for (let i = 0; i < visibleUserCols.length; ++i) {
				const col = this.uiTableColumn(visibleUserCols[i].uiTableColumnId);
				if (col && (col.name === ProjectColumnName.QuickBooksInvoiceId)) {
					const otherItem = this.dataTable.item(row, i);
					if (otherItem) {
						otherItem.setText(docNumber);
					}
					break;
				}
			}
		}
	}

	private setProjectStatusTableItemData(index: ModelIndex, data: string): void {
		const tableItem = this.dataTable.item(index);
		if (tableItem) {
			tableItem.setText((data && capitalize(data)) || '\u2014');
		}
	}

	private setProjectDataLocationTableItemData(index: ModelIndex, data: string): void {
		const tableItem = this.dataTable.item(index);
		if (tableItem) {
			tableItem.setText(data);
		}
	}

	private setProjectTaskTableItemData(index: ModelIndex, data: string | null): void {
		const tableItem = this.dataTableItem(index);
		if (tableItem) {
			tableItem.setText(data);
		}
	}

	private setUiTableFilters(filters: Array<IUIFilter>): void {
		for (const fil of filters) {
			this.addSearchFilter(fil);
		}
	}

	private async updateBatchPayment(slugs: Iterable<string>): Promise<void> {
		const strings = (typeof slugs === 'string') ?
			[slugs] :
			Array.from(slugs);
		const data = (strings.length > 0) ?
			await svc.project.batch(strings) :
			null;
		this.batchPayment.setData(data);
	}

	private async updateProjectAssigned(projectSlug: string, assignedUserIds: list<number>): Promise<void> {
		if (!projectSlug) {
			console.log('updateProjectAssigned: invalid slug argument');
			return;
		}
		const proj = await svc.project.updateAssigned(
			projectSlug,
			{photography: assignedUserIds.toArray()});
		this.replaceProject(proj);
		if (this.currentIndex.isValid()) {
			this.setAssignedTableItemData(
				this.currentIndex,
				proj.assignedDisplay);
		}
	}

	private async updateProjectEditors(projectSlug: string, editorUserIds: list<number>): Promise<void> {
		if (!projectSlug) {
			console.log('updateProjectEditors: invalid slug argument');
			return;
		}
		const proj = await svc.project.updateAssigned(
			projectSlug,
			{editing: editorUserIds.toArray()});
		this.replaceProject(proj);
		if (this.currentIndex.isValid()) {
			this.setAssignedTableItemData(
				this.currentIndex,
				proj.editorsDisplay);
		}
	}

	private async updateProjectDataLocation(projectSlug: string, dataLocationOptionId: DataLocationOptionPk): Promise<void> {
		const optPk: DataLocationOptionPk | null = (dataLocationOptionId < 1) ?
			null :
			dataLocationOptionId;
		let proj = await svc.project.get(projectSlug);
		proj = await svc.project.updateDataLocation(
			projectSlug,
			proj.dataLocation ?
				{
					...proj.dataLocation,
					dataLocationOptionId: optPk,
				} :
				{
					id: 0,
					dataLocationOptionId: optPk,
					notes: '',
				},
		);
		if (this.currentIndex.isValid()) {
			this.setProjectDataLocationTableItemData(
				this.currentIndex,
				proj.dataLocationDisplay,
			);
		}
		this.replaceProject(proj);
	}

	private async updateProjectStatus(projectSlug: string, status: number, additionalEmailText: string, dispatchEmail: boolean): Promise<void> {
		let proj = await svc.project.get(projectSlug);
		proj = await svc.project.update(
			proj.slug,
			{...proj, status, additionalEmailText, dispatchEmail});
		if (this.currentIndex.isValid()) {
			this.setProjectStatusTableItemData(this.currentIndex, proj.statusDisplay);
		}
		this.replaceProject(proj);
	}

	private async updateProjectTask(projectSlug: string, task: IProjectTask): Promise<void> {
		const proj = await svc.project.updateTask(projectSlug, task);
		if (this.currentIndex.isValid()) {
			this.setProjectTaskTableItemData(this.currentIndex, proj.lastTask);
		}
		this.replaceProject(proj);
	}

	protected userUiTableChanged(): void {
		super.userUiTableChanged();
		if (this.projects.size() > 0) {
			this.setData(this.projects);
		}
	}
}

class ProjectTableItem extends TableItem {
	slug: string = '';

	debugData(): string {
		return `${super.debugData()}+${this.slug}`;
	}

	destroy(): void {
		this.slug = '';
		super.destroy();
	}
}

class BatchPayment extends El {
	clearData(): void {
		let el = this.tbody();
		if (el) {
			el.clear();
		}
		el = this.hiddenInputContainer();
		if (el) {
			el.clear();
		}
	}

	hiddenInputContainer(): El | null {
		return this.querySelector('#hidden-inputs');
	}

	makeHiddenInput(slug: string): void {
		const container = this.hiddenInputContainer();
		if (container) {
			new El({
				attributes: [['name', 'slugs'], ['type', 'hidden'], ['value', slug]],
				parent: container,
				tagName: 'input',
			});
		}
	}

	makeRow(data: IProjectBulkBalanceObject): El {
		const tr = new El({tagName: 'tr'});
		let cell = new El({tagName: 'td', parent: tr});
		cell.setText(data.location);
		cell = new El({tagName: 'td', parent: tr});
		cell.setStyleProperty('text-align', 'right');
		cell.setText(`$${numberFormat(data.balance)}`);
		return tr;
	}

	setData(data: IProjectBulkBalance | null): void {
		this.clearData();
		if (data) {
			const tbody = this.tbody();
			if (tbody) {
				for (const obj of data.objects) {
					tbody.appendChild(this.makeRow(obj));
					this.makeHiddenInput(obj.slug);
				}
			}
			this.setTableVisible(true);
		} else {
			this.setTableVisible(false);
		}
		const balStr = data ? data.balanceTotal : '';
		const bal = data ? Number.parseFloat(balStr) : Number.NaN;
		const posBal = isNumber(bal) ? (bal > 0) : false;
		this.setSubmitButtonDisabled(!posBal);
		if (posBal) {
			this.setSubmitButtonText(`PAY $${numberFormat(balStr)}`);
		} else {
			this.setSubmitButtonText('PAY');
		}
	}

	setSubmitButtonDisabled(disabled: boolean): void {
		const el = this.submitButton();
		if (el) {
			el.setDisabled(disabled);
		}
	}

	setSubmitButtonText(text: string): void {
		const el = this.submitButton();
		if (el) {
			const label = el.querySelector('.pb-button__label');
			if (label) {
				label.setText(text);
			}
		}
	}

	setTableVisible(visible: boolean): void {
		const el = this.table();
		if (el) {
			el.setVisible(visible);
		}
	}

	submitButton(): El | null {
		return this.querySelector('button[type="submit"]');
	}

	table(): El | null {
		const el = this.tbody();
		if (el) {
			const parent = el.parent();
			if (parent && (parent.tagName() === 'TABLE')) {
				return parent;
			}
		}
		return null;
	}

	tbody(): El | null {
		return this.querySelector('#batch-payment-checked-list');
	}
}

class UserLinkContainer extends El {
	linkIcon: IconButton | null;
	textEl: El | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		opts.tagName = 'div';
		opts.styles = [
			['display', 'flex'],
			['flex-direction', 'row'],
		];
		super(opts);
		this.linkIcon = null;
		this.textEl = null;
	}

	destroy(): void {
		if (this.linkIcon) {
			this.linkIcon.destroy();
		}
		this.linkIcon = null;
		if (this.textEl) {
			this.textEl.destroy();
		}
		this.textEl = null;
		super.destroy();
	}
}

class UserLinkTableCell extends TableEl {
	cont: UserLinkContainer | null;

	constructor(opts: Partial<TableElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableElOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableElOpts>, parent?: El | null);
	constructor(opts?: Partial<TableElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<TableElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<TableElOpts>(a, b, c);
		super(opts);
		this.cont = new UserLinkContainer({
			parent: this,
		});
		this.cont.linkIcon = new IconButton({
			isAnchor: true,
			iconName: 'account_circle',
			parent: this.cont,
		});
		this.cont.textEl = new El({
			tagName: 'a',
			parent: this.cont,
		});
	}

	protected ensureAnchor(): void {
		// if ((this.typ === TableElType.TableCell) && !this.anchorEl() && this.cont && this.cont.textEl) {
		// 	const el = new El({
		// 		classNames: 'pb-data-table__cell-anchor',
		// 		tagName: 'a',
		// 	});
		// 	for (const obj of this.cont.textEl.children()) {
		// 		obj.setParent(el);
		// 	}
		// 	el.setParent(this.cont.textEl);
		// }
	}

	// setHref(href: string, opts?: Partial<{text: string; target: 'blank'}>): void {
	// 	super.setHref(href, opts);
	// }

	// protected anchorEl(): El | null {
	// 	return this.cont && this.cont.textEl;
	// }
}

class InvoiceTotalTableCell extends TableEl {
	private chip: InvoiceTotalChip | null;

	constructor(opts: Partial<TableElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<TableElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableElOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableElOpts>, parent?: El | null);
	constructor(opts?: Partial<TableElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<TableElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		super(elOpts<TableElOpts>(a, b, c));
		this.addClass('pb-data-table__link-cell--no-decoration');
		this.chip = new InvoiceTotalChip({parent: this});
	}

	destroy(): void {
		if (this.chip) {
			this.chip.destroy();
		}
		this.chip = null;
		super.destroy();
	}

	protected setBackground(value: string): void {
		if (this.chip) {
			this.chip.setBackground(value);
		}
	}

	protected setForeground(value: string): void {
		if (this.chip) {
			this.chip.setForeground(value);
		}
	}

	setText(text: string | null): void {
		this.chip && this.chip.setText(numberFormat(text || ''));
	}
}

class InvoiceTotalChip extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('mdc-chip', 'justify-content--flex-end');
		opts.classNames = classNames;
		opts.tagName = 'span';
		super(opts);
		const icon = new El({
			classNames: ['material-icons', 'mdc-chip__icon', 'mdc-chip__icon--leading', 'pb-margin-right--auto'],
			parent: this,
			tagName: 'i',
		});
		icon.setText('attach_money');
		const gridCell = El.span({
			attributes: [['role', 'gridcell']],
			parent: this,
		});
		const btn = El.span({
			attributes: [['role', 'button']],
			classNames: 'mdc-chip__primary-action',
			parent: gridCell,
		});
		El.span({
			classNames: 'mdc-chip__text',
			parent: btn,
		});
	}

	private iconEls(): list<El> {
		return this.querySelectorAll('.mdc-chip__icon');
	}

	setBackground(value: string): void {
		if (value) {
			this.setStyleProperty('background-color', value);
		} else {
			this.removeStyleProperty('background-color');
		}
	}

	setForeground(value: string): void {
		if (value) {
			this.setStyleProperty('color', value);
		} else {
			this.removeStyleProperty('color');
		}
		for (const el of this.iconEls()) {
			if (value) {
				el.setStyleProperty('color', value);
			} else {
				el.removeStyleProperty('color');
			}
		}
	}

	setHref(href: string, opts?: Partial<{text: string; target: 'blank'}>): void {
		const optsText = opts && opts.text;
		super.setHref(href, opts || {});
		if (optsText) {
			const el = this.textEl();
			if (el) {
				el.setText(optsText);
			}
		}
	}

	setText(text?: string | null): void {
		const el = this.textEl();
		el && el.setText(text);
	}

	private textEl(): El | null {
		return this.querySelector('.mdc-chip__text');
	}
}

class QBInvoiceLinkTableCellIconButton extends IconButton {
	constructor(opts: Partial<IconButtonOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<IconButtonOpts>, parent?: El | null);
	constructor(opts?: Partial<IconButtonOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<IconButtonOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<IconButtonOpts>(a, b, c);
		opts.iconName = undefined;
		super(opts);
		this._setText('link');
	}

	setText(text: string | null): void {
		let qbDocNumber: string = '';
		let qbHasFakeLink: boolean = false;
		if (text) {
			const bits = decodeQBInvoiceLinkText(text);
			qbDocNumber = bits.qbDocNumber;
			qbHasFakeLink = bits.qbHasFakeLink;
		}
		this.setTitle(qbDocNumber);
		this.setStyleProperty('color', (qbDocNumber || qbHasFakeLink) ? '#00CC75' : '#EFEFEF');
	}
}

class QBInvoiceLinkTableCell extends TableEl {
	private iconBtn: QBInvoiceLinkTableCellIconButton | null = null;

	destroy(): void {
		if (this.iconBtn) {
			this.iconBtn.destroy();
			this.iconBtn = null;
		}
		super.destroy();
	}

	setText(text: string | null): void {
		if (!this.iconBtn) {
			this.iconBtn = new QBInvoiceLinkTableCellIconButton({parent: this});
		}
		this.iconBtn.setText(text);
	}
}

class SelectMenuButton extends Button {
	static view: ProjectListView | null = null;

	constructor(opts: Partial<ButtonOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts> | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ButtonOpts>, parent?: El | null);
	constructor(opts?: Partial<ButtonOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ButtonOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ButtonOpts>(a, b, c);
		opts.outlined = true;
		opts.trailingIcon = 'arrow_drop_down';
		super(opts);
		this.addClass('pb-project-list-cell-select-menu-button', CSS_CLASS_FULL_WIDTH, 'justify-content--space-between');
		const labelEl = this.labelEl();
		if (labelEl) {
			labelEl.addClass('pb-project-list-cell-select-menu-button-label');
		}
	}

	cmp(other: SelectMenuButton): number {
		return this.text().localeCompare(other.text());
	}

	lastTask(): string {
		const text = this.text().trim();
		if (text === '\u2014') {
			return '';
		}
		return text;
	}

	setText(text?: string | null): void {
		text = text || '\u2014';
		super.setText(text);
		const labelEl = this.labelEl();
		if (labelEl) {
			text = text.trim();
			labelEl.setClass(!text || (text === '\u2014'), CSS_CLASS_NO_VALUE);
		}
	}

	text(): string {
		const text = super.text();
		if (text === '\u2014') {
			return '';
		}
		return text;
	}
}

class SelectMenuCell extends TableEl {
	private btn: SelectMenuButton | null = null;

	destroy(): void {
		if (this.btn) {
			this.btn.destroy();
			this.btn = null;
		}
		super.destroy();
	}

	setText(text: string | null): void {
		if (!this.btn) {
			this.btn = new SelectMenuButton({parent: this});
		}
		this.btn.setText(text);
	}
}

const elForColumnMap = new Map<string, typeof TableEl>([
	[ProjectColumnName.InvoiceTotal, InvoiceTotalTableCell],
	[ProjectColumnName.QuickBooks, QBInvoiceLinkTableCell],
]);
if (window.isProducer) {
	const _pel: Array<[string, typeof TableEl]> = [
		[ProjectColumnName.Shooter, SelectMenuCell],
		[ProjectColumnName.Editor, SelectMenuCell],
		[ProjectColumnName.Status, SelectMenuCell],
		[ProjectColumnName.Task, SelectMenuCell],
		[ProjectColumnName.Files, SelectMenuCell],
		// [ProjectColumnName.CreatedByUser, UserLinkTableCell],
	];
	for (const [_a, _b] of _pel) {
		elForColumnMap.set(_a, _b);
	}
}

function assignedString(data: Array<string>): string {
	if (data.length > 0) {
		const s = data.slice(0, 2).join(', ');
		if (data.length > 2) {
			return `${s}, ...`;
		}
		return s;
	}
	return '\u2014';
}

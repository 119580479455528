import {El} from '../el';

const APPLIES_TO_SELECTOR = '#id_applies-to';
const CLIENT_USER_TYPE_SELECT_SELECTOR = '#id_client-user-type-select';

function appliesToChangeEvent(event: Event) {
	const elem = El.fromEvent(event);
	setClientUserTypeSelectVisibilityAccordingToAppliesTo(elem.value());
}

export function init(): void {
	initListeners();
	const elem = El.fromSelector(APPLIES_TO_SELECTOR);
	setClientUserTypeSelectVisibilityAccordingToAppliesTo(elem.value());
}

function initListeners(): void {
	const elem = document.querySelector(APPLIES_TO_SELECTOR);
	if (elem) {
		elem.addEventListener('change', appliesToChangeEvent);
	}
}

function setClientUserTypeSelectVisibilityAccordingToAppliesTo(value: string): void {
	setClientUserTypeSelectVisibility(value === 'with_client_user_type');
}

function setClientUserTypeSelectVisibility(visible: boolean): void {
	const elem = El.fromSelector(CLIENT_USER_TYPE_SELECT_SELECTOR);
	elem.setVisible(visible);
}

import {CatalogService} from './catalog';
import {ProjectService} from './project';
import {EtcService, etcService} from './etc';
import {GroupService, groupService} from './group';
import {Resource, ResourceService} from './resource';
import {AccountService, accountService} from './account';
import {WeatherService, weatherService} from './weather';
import {commonDateTimeParams} from './common';
import {UIService, uiService} from './ui';

class EventExt extends Resource<IEvent> {
	static uri: string = 'events';

	async list(params?: Partial<ICommonDateTimeRequest>, cfg?: Omit<IRequestConfig, 'params' | 'method' | 'url'>): Promise<IEvent[]> {
		return super.list(commonDateTimeParams(params), cfg);
	}
}

export class APIService extends ResourceService {
	static uri: string = '/api/';

	account: AccountService;
	catalog: CatalogService;
	etc: EtcService;
	event: EventExt;
	group: GroupService;
	project: ProjectService;
	ui: UIService;
	weather: WeatherService;

	constructor() {
		super();
		this.account = accountService;
		this.catalog = new CatalogService();
		this.etc = etcService;
		this.event = new EventExt(this);
		this.group = groupService;
		this.project = new ProjectService();
		this.ui = uiService;
		this.weather = weatherService;
	}
}

export const apiService: APIService = new APIService();

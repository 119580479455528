import React from 'react';

import {cssClassName} from '../../../../util';

interface IProps {
	className?: string;
	htmlFor?: string;
	label?: string;
	labelClassName?: string;
}

export default class LabeledControl extends React.Component<React.PropsWithChildren<IProps>, {}> {
	render(): React.ReactNode {
		const {
			children,
			className,
			htmlFor,
			label,
			labelClassName,
		} = this.props;
		return (
			<div className={cssClassName('pb-catalog-item-form__labeled-ctrl', className)}>
				<label className={cssClassName('pb-catalog-item-form__label', labelClassName)} htmlFor={htmlFor}>{label}</label>
				{children}
			</div>
		);
	}
}

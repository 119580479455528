import React, {PropsWithChildren} from 'react';

import Times from './times';
import Input from '../input';
import Calendar from '../calendar';
import IconButton from '../iconbutton';
import Subheading from '../subheading';
import {date, datetime, genDates, time, timedelta} from '../../../datetime';
import {bind, cssClassName, genTimes, isNumber, padStart, to12Hour, toPeriod} from '../../../util';
import {COLOR_PB_GREEN} from '../../../constants';

interface IProps {
	currentDate?: string | null;
	currentDateAvailability?: (isoDateString: string) => DateTimeAvailability[];
	dateIsAvailable?: (date: string) => boolean;
	events?: ISchedulerBlock[];
	onAnyTimeChange?: (isChecked: boolean) => any;
	onDateChange?: (dateIsoFormat: string | null) => any;
	onTimeChange?: (timeIsoFormat: string | null) => any;
	onTimeSelectRequest?: (datetime: IDateTime) => boolean;
	selectedEvent?: ISchedulerSelectedBlock | null;
	timeRangeForWeekday?: (weekday: number) => ITimeRange | null;
	timeSegmentStep?: number | null;
	weather?: IDateWeather[];
}

type Props = PropsWithChildren<IProps>;

interface State {
	miniDatePickerInputValue: string;
	timeSelectAtIsoTime: string;
}

export function isoDateToIDate(isoDate: string): IDate {
	return date.fromisoformat(isoDate).toIDate();
}

export function isoTimeToITime(isoTime: string): ITime {
	return time.fromisoformat(isoTime).toITime();
}

const DEFAULT_SEGMENT_STEP = 30;
const MINI_DATE_PICKER_TEXT = 'Need to schedule a bit further into the future? (weather permitting)';

export default class Scheduler extends React.Component<Props, State> {
	calendarDates: IDate[];

	constructor(props: Props) {
		super(props);
		const today = date.today();
		const tomorrow = today.add(new timedelta(1));
		this.calendarDates = genDates(9, tomorrow);
		this.state = {
			miniDatePickerInputValue: '',
			timeSelectAtIsoTime: '',
		};
	}

	@bind
	anyTimeChanged(isChecked: boolean): void {
		const {onAnyTimeChange} = this.props;
		if (onAnyTimeChange) {
			onAnyTimeChange(isChecked);
		}
		this.setState({timeSelectAtIsoTime: ''});
	}

	availabilityDates(): DateAvailability[] {
		const {dateIsAvailable} = this.props;
		const func = dateIsAvailable ?
			dateIsAvailable :
			() => true;
		return this.calendarDates.map(d => ({date: d, available: func(d.isoformat)}));
	}

	@bind
	calendarChanged(index: number | null): void {
		let value: string = '';
		if (isNumber(index)) {
			const date = this.calendarDates[index];
			if (date) {
				value = date.isoformat;
			}
		}
		this.currentDateChanged(value);
	}

	calendarOverlay(): {text: string; index: number;} | undefined {
		const timeDisplay = this.calendarTimeDisplay();
		if (timeDisplay) {
			const idx = this.selectedEventCalendarDateIndex();
			if (idx >= 0) {
				return {
					index: idx,
					text: timeDisplay,
				};
			}
		}
		return undefined;
	}

	calendarTimeDisplay(): string {
		const {selectedEvent} = this.props;
		if (selectedEvent) {
			if (selectedEvent.anyTime) {
				return 'Anytime';
			}
			const {start: {time}} = selectedEvent;
			return `${to12Hour(time.hour)}:${padStart(time.minute, 2, '0')} ${toPeriod(time.hour)}`;
		}
		return '';
	}

	componentDidMount(): void {
		document.addEventListener('keydown', this.keyDownEvent);
	}

	componentDidUpdate(): void {
		const {selectedEvent} = this.props;
		const {miniDatePickerInputValue} = this.state;
		if (selectedEvent) {
			const isMiniPickerDateTime = this.selectedEventCalendarDateIndex() === -1;
			if (isMiniPickerDateTime && !miniDatePickerInputValue) {
				this.setState({miniDatePickerInputValue: selectedEvent.start.date.isoformat});
			}
		}
	}

	componentWillUnmount(): void {
		document.removeEventListener('keydown', this.keyDownEvent);
	}

	currentDateAvailability(): DateTimeAvailability[] {
		const {currentDate, currentDateAvailability} = this.props;
		return (currentDate && currentDateAvailability) ?
			currentDateAvailability(currentDate) :
			[];
	}

	@bind
	currentDateChanged(isoDate: string | null): void {
		const {onDateChange} = this.props;
		if (onDateChange) {
			onDateChange(isoDate);
		}
		this.setState({timeSelectAtIsoTime: ''});
	}

	currentDateEvents(): Array<ISchedulerBlock> {
		const {currentDate} = this.props;
		const rv: Array<ISchedulerBlock> = [];
		if (currentDate) {
			const selEvt = this.selectedTimelineEvent();
			if (selEvt) {
				rv.push(selEvt);
			}
			rv.push(...this.unavailableEvents(), ...this.dateEvents(currentDate));
		}
		return rv;
	}

	currentDateFormatString(): string {
		const {currentDate} = this.props;
		if (currentDate) {
			const {day, monthName, weekdayName} = isoDateToIDate(currentDate);
			return `${weekdayName}, ${monthName} ${day}`;
		}
		return '';
	}

	currentDateIsAnyTime(): boolean {
		const {selectedEvent} = this.props;
		return Boolean(this.currentDateIsSelectedDate() && selectedEvent && selectedEvent.anyTime);
	}

	currentDateIsSelectedDate(): boolean {
		const {currentDate, selectedEvent} = this.props;
		return Boolean(selectedEvent && (currentDate === selectedEvent.start.date.isoformat));
	}

	currentDateTimes(): ITime[] {
		const {currentDate, timeRangeForWeekday} = this.props;
		if (currentDate && timeRangeForWeekday) {
			const timeRange = timeRangeForWeekday(isoDateToIDate(currentDate).weekdayNumber);
			if (timeRange) {
				const startHour = timeRange.start.hour;
				const stopHour = timeRange.stop.hour;
				return genTimes(startHour, stopHour, false);
			}
		}
		return [];
	}

	dateEvents(isoDate: string): ISchedulerBlock[] {
		return (this.props.events || []).filter(evt => (evt.start.date.isoformat === isoDate));
	}

	@bind
	keyDownEvent(event: KeyboardEvent): void {
		if (event.key === 'Escape' && this.state.timeSelectAtIsoTime) {
			this.setState({timeSelectAtIsoTime: ''});
		}
	}

	@bind
	miniDatePickerButtonClicked(): void {
		if (this.state.miniDatePickerInputValue) {
			this.currentDateChanged(this.state.miniDatePickerInputValue);
		}
	}

	@bind
	miniDatePickerInputValueChanged(value: string): void {
		if (value || !this.miniDatePickerIsSelected()) {
			this.setState({miniDatePickerInputValue: value});
		} else {
			this.selectedTimeChanged(null);
			this.currentDateChanged(null);
		}
	}

	miniDatePickerIsSelected(): boolean {
		const {selectedEvent} = this.props;
		return Boolean(selectedEvent) && (this.selectedEventCalendarDateIndex() === -1);
	}

	partitionUnavailable(avail: DateTimeAvailability[]): {start: IDateTime; end: IDateTime;}[] {
		const rv: {start: IDateTime; end: IDateTime;}[] = [];
		let start: IDateTime | null = null;
		for (let i = 0; i < avail.length; ++i) {
			const av = avail[i];
			if (av.available) {
				if (start) {
					rv.push({start, end: av.datetime});
					start = null;
				}
			} else {
				if (!start) {
					start = av.datetime;
				} else {
					if (i === (avail.length - 1)) {
						rv.push({start, end: av.datetime});
					}
				}
			}
		}
		return rv;
	}

	render(): React.ReactNode {
		const {currentDate, weather} = this.props;
		const {miniDatePickerInputValue, timeSelectAtIsoTime} = this.state;
		const anyTime = this.currentDateIsAnyTime();
		const times = this.currentDateTimes();
		const noTime = (times.length < 1);
		const showNoTime = noTime && !anyTime;
		const showTimes = !(anyTime || noTime);
		const miniPickerBtnClassName = miniDatePickerInputValue ? 'pb-nudge-anim' : undefined;
		return !currentDate ?
			<Dates>
				<Calendar
					dates={this.availabilityDates()}
					onClick={this.calendarChanged}
					overlay={this.calendarOverlay()}
					weather={weather}/>
				{window.pbdomsupport.inputDateType ?
					<MiniPicker>
						<DatePicker
							onChange={this.miniDatePickerInputValueChanged}
							selected={this.miniDatePickerIsSelected()}
							value={miniDatePickerInputValue}/>
						<IconButton
							className={miniPickerBtnClassName}
							disabled={!Boolean(miniDatePickerInputValue)}
							icon="forward" onClick={this.miniDatePickerButtonClicked}
							smaller={true}
							style={{marginLeft: '4px'}}/>
					</MiniPicker> :
					null}
			</Dates> :
			showNoTime ?
				<TimesNoTime/> :
				<React.Fragment>
					<TimesHeading>{this.currentDateFormatString()}</TimesHeading>
					<TimesAnytime checked={anyTime} onClick={this.anyTimeChanged}/>
					{showTimes ?
						<Times
							events={this.currentDateEvents()}
							onClick={this.timeClicked}
							onSegmentClick={this.selectedTimeChanged}
							segmentStep={this.timeSegmentStep()}
							times={times}
							timeSelectAtIsoTime={timeSelectAtIsoTime}/> :
						null}
				</React.Fragment>;
	}

	selectedEventCalendarDateIndex(): number {
		const {selectedEvent} = this.props;
		const isoDate = (selectedEvent && selectedEvent.start.date.isoformat) || '';
		return this.calendarDates.findIndex(d => d.isoformat === isoDate);
	}

	@bind
	selectedTimeChanged(time: ITime | null): void {
		const {onTimeChange} = this.props;
		if (onTimeChange) {
			onTimeChange(time ? time.isoformat : null);
		}
		this.setState({miniDatePickerInputValue: '', timeSelectAtIsoTime: ''});
	}

	selectedTimelineEvent(): ISchedulerBlock | null {
		const {selectedEvent} = this.props;
		if (this.currentDateIsSelectedDate() && selectedEvent) {
			return {
				color: COLOR_PB_GREEN,
				interactive: true,
				zIndex: 1,
				...selectedEvent,
			};
		}
		return null;
	}

	@bind
	timeClicked(isoTime: string): void {
		const {currentDate, onTimeSelectRequest} = this.props;
		let timeSelectAtIsoTime: string = '';
		if (currentDate) {
			let time: ITime | null = null;
			const times = this.currentDateTimes();
			for (let i = 0; i < times.length; ++i) {
				if (times[i].isoformat === isoTime) {
					time = times[i];
					break;
				}
			}
			if (time) {
				if (!onTimeSelectRequest || onTimeSelectRequest({date: isoDateToIDate(currentDate), time})) {
					timeSelectAtIsoTime = isoTime;
				}
			}
		}
		this.setState({timeSelectAtIsoTime});
	}

	timeSegmentStep(): number {
		const {timeSegmentStep} = this.props;
		return isNumber(timeSegmentStep) ?
			timeSegmentStep :
			DEFAULT_SEGMENT_STEP;
	}

	unavailableEvent(start: IDateTime, end: IDateTime, color?: string): ISchedulerBlock {
		const startDt = datetime.fromIDateTime(start);
		const delta = datetime.fromIDateTime(end).sub(startDt);
		return {
			color,
			duration: delta.totalSeconds(),
			start,
		};
	}

	unavailableEvents(): ISchedulerBlock[] {
		const {currentDate, currentDateAvailability} = this.props;
		const rv: ISchedulerBlock[] = [];
		if (currentDate && currentDateAvailability) {
			const parts = this.partitionUnavailable(currentDateAvailability(currentDate));
			if (parts.length > 0) {
				rv.push(...parts.map(p => this.unavailableEvent(p.start, p.end)));
			}
		}
		return rv;
	}
}

function DatePicker({onChange, selected, value}: PropsWithChildren<{onChange: (value: string) => any; selected?: boolean | null; value?: string}>) {
	const className = selected ? 'pb-project-create-input--selected' : undefined;
	return (
		<DateInput
			className={className}
			onChange={onChange}
			value={value}/>
	);
}

function Dates({children}: PropsWithChildren<{}>) {
	return (
		<div className="display--flex flex-direction--column">
			{children}
		</div>
	);
}

function MiniPicker({children}: PropsWithChildren<{}>) {
	return (
		<div className="display--flex flex-direction--column padding-top--16">
			<small className="display--flex color--grayish">
				{MINI_DATE_PICKER_TEXT}
			</small>
			<div className="display--flex flex-direction--row padding-top--8 align-items--center">
				{children}
			</div>
		</div>
	);
}

function TimesAnytime({checked, onClick}: {checked?: boolean; onClick: (checked: boolean) => any}) {
	const icon = checked ?
		'check_circle' :
		'radio_button_unchecked';
	const bgClassName = checked ?
		'pb-project-create-service-list-item--selected' :
		'background-color--pb-dark-blue';
	return (
		<div>
			<ul className="mdc-list padding--0-0-0-0">
				<li className={`mdc-list-item pb-project-create-service-list-item ${bgClassName} pb-color--white`} onClick={() => onClick(!checked)}>
					<i aria-hidden="true" className="material-icons mdc-list-item__graphic pb-color--white pb-margin-right--8">{icon}</i>
					Anytime
				</li>
			</ul>
		</div>
	);
}

function TimesHeading({children}: PropsWithChildren<{}>) {
	return (
		<Subheading className="padding-bottom--16">
			{children}
		</Subheading>
	);
}

function TimesNoTime() {
	return (
		<div className="display--flex flex-direction--column align-items--center justify-content--center color--grayish padding-top--32 padding-bottom--8">
			<i aria-hidden="true" className="material-icons font-size--32px">event_busy</i>
			<span className="padding-top--4">No times available on this day</span>
		</div>
	);
}

interface IDateInputProps {
	className?: string;
	max?: string;
	min?: string;
	onChange: (value: string) => any;
	value?: string;
}

class DateInput extends React.Component<IDateInputProps, {}> {
	@bind
	changeEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		this.props.onChange(event.target.value);
	}

	render(): React.ReactNode {
		const {className, max, min, value} = this.props;
		return (
			<label>
				<Input
					className={cssClassName('outline--none', className)}
					max={max}
					min={min}
					onChange={this.changeEvent}
					type="date"
					value={value}/>
			</label>
		);
	}
}

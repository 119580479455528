import {MDCTextField} from '@material/textfield';

import {El} from '../../el';
import {bind} from '../../util';
import {Evt} from '../../evt';

export enum SearchInputEvtType {
	FocusIn = 753,
	FocusOut,
	LeadingIcon,
	TextChange,
	TrailingIcon,
}

export class SearchInputEvt extends Evt {
	static FocusIn: SearchInputEvtType.FocusIn = SearchInputEvtType.FocusIn;
	static FocusOut: SearchInputEvtType.FocusOut = SearchInputEvtType.FocusOut;
	static LeadingIcon: SearchInputEvtType.LeadingIcon = SearchInputEvtType.LeadingIcon;
	static TextChange: SearchInputEvtType.TextChange = SearchInputEvtType.TextChange;
	static TrailingIcon: SearchInputEvtType.TrailingIcon = SearchInputEvtType.TrailingIcon;

	private k: string;
	private t: string;

	constructor(type: number, key: string = '', text: string = '') {
		super(type);
		this.k = key;
		this.t = text;
	}

	gotFocus(): boolean {
		return this.type() === SearchInputEvtType.FocusIn;
	}

	key(): string {
		return this.k;
	}

	lostFocus(): boolean {
		return this.type() === SearchInputEvtType.FocusOut;
	}

	text(): string {
		return this.t;
	}
}

export class SearchInput extends El {
	static defaultLeadingIconName: string = 'filter_list';
	static defaultTrailingIconName: string = 'search';

	private ctrl: MDCTextField | null;

	constructor() {
		super(document.getElementById('id_project-search'));
		this.removeClass('mdc-text-field--with-trailing-icon');
		this.ctrl = null;
		const root = this.element();
		if (root) {
			root.addEventListener('MDCTextField:icon', this.iconEvent);
			this.ctrl = new MDCTextField(root);
		}
		const inp = this.inputEl();
		if (inp) {
			inp.addEventListener('input', this.event);
			inp.addEventListener('keydown', this.event);
			inp.addEventListener('focus', this.event);
			inp.addEventListener('blur', this.event);
		}
	}

	destroy(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
		super.destroy();
	}

	@bind
	private event(event: Event): void {
		switch (event.type) {
			case 'input':
				this.inputEvent(event);
				break;
			case 'keydown':
				this.keyPressEvent(<KeyboardEvent>event);
				break;
			case 'focus':
			case 'blur':
				this.focusEvent(event);
				break;
		}
	}

	focus(): void {
		const el = this.inputEl();
		if (el) {
			el.focus();
		}
	}

	private focusEvent(event: Event): void {
		const type = (event.type === 'blur') ?
			SearchInputEvtType.FocusOut :
			SearchInputEvtType.FocusIn;
		this.notifyEvt(new SearchInputEvt(type));
	}

	hasFocus(): boolean {
		const el = this.inputEl();
		if (el) {
			return el.hasFocus();
		}
		return super.hasFocus();
	}

	private iconEl(which: Which): El | null {
		return this.querySelector(`.mdc-text-field__icon--${which}`);
	}

	@bind
	private iconEvent(event: Event): void {
		const tgt = El.fromEvent(event);
		const type = tgt.eq(this.iconEl('leading')) ?
			SearchInputEvtType.LeadingIcon :
			SearchInputEvtType.TrailingIcon;
		this.notifyEvt(new SearchInputEvt(type));
	}

	private inputEl(): El | null {
		return this.querySelector('.mdc-text-field__input');
	}

	private inputEvent(event: Event): void {
		const el = El.fromEvent(event);
		if (el.eq(this.inputEl())) {
			this.notifyEvt(new SearchInputEvt(
				SearchInputEvtType.TextChange,
				'',
				el.value()));
		}
	}

	leadingIconEl(): El | null {
		return this.iconEl('leading');
	}

	private keyPressEvent(event: KeyboardEvent): void {
		const evt = new SearchInputEvt(Evt.KeyPress, event.key);
		evt.setAccepted(false);
		this.notifyEvt(evt);
		if (evt.isAccepted()) {
			event.preventDefault();
		}
	}

	private setIcon(which: Which, iconName: string, interactive?: boolean): void {
		const el = this.iconEl(which);
		if (el) {
			el.setText(iconName);
			if (typeof interactive === 'boolean') {
				if (interactive) {
					el.setAttribute([['role', 'button'], ['tabindex', '0']]);
				} else {
					el.removeAttribute('role', 'tabindex');
				}
			}
		}
	}

	setLeadingIcon(iconName: string, interactive?: boolean): void {
		this.setIcon('leading', iconName, interactive);
	}

	setTrailingIcon(iconName: string, interactive?: boolean): void {
		this.setIcon('trailing', iconName, interactive);
	}

	setValue(value: string): void {
		if (this.ctrl) {
			this.ctrl.value = value;
		} else {
			const el = this.inputEl();
			if (el) {
				el.setValue(value);
			}
		}
	}

	value(): string {
		if (this.ctrl) {
			return this.ctrl.value;
		}
		const el = this.inputEl();
		return el ? el.value() : '';
	}
}

import {MDCMenu} from '@material/menu';

import {MDCSelect} from './ctrl';
import {El, elOpts, ElOpts} from '../../el';
import {ListItem, ListItemOpts, ListItemSelectEvt} from '../list';
import {bind, pixelString} from '../../util';
import {Menu} from '../menu';
import {Evt} from '../../evt';

export interface ComboBoxItem {
	disabled?: boolean;
	listItemOptions?: Partial<ListItemOpts>;
	selected?: boolean;
	value: number | string;
}

export interface ComboBoxOpts extends ElOpts {
	autoPositionMenu: boolean;
	emptyItem: boolean;
	items: Iterable<ComboBoxItem>;
	labelId: string;
	labelText: string;
	leadingIcon: string;
	noLabel: boolean;
	outlined: boolean;
	required: boolean;
	selectedTextId: string;
}

export class ComboBoxChangeEvt extends Evt {
	private cb: ComboBox;
	private i: number;
	private v: string;

	constructor(instance: ComboBox, index: number, value: string) {
		super(Evt.Change);
		this.cb = instance;
		this.i = index;
		this.v = value;
	}

	comboBox(): ComboBox {
		return this.cb;
	}

	index(): number {
		return this.i;
	}

	value(): string {
		return this.v;
	}
}

export class ComboBox extends El {
	private autoPosMenu: boolean;
	private ctrl: MDCSelect | null;
	private haveEmpty: boolean;
	private menu: Menu | null;
	private initOpts: Partial<ComboBoxOpts>;

	constructor(opts: Partial<ComboBoxOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts>, parent?: El | null);
	constructor(opts?: Partial<ComboBoxOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ComboBoxOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ComboBoxOpts>(a, b, c);
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.autoPosMenu = false;
		this.ctrl = null;
		this.haveEmpty = false;
		this.initOpts = {...opts};
		if (!opts.root) {
			buildTree(this, opts);
		}
		this.menu = new Menu({root: this.querySelector(Menu.RootSelector)});
		if (this.menu.list) {
			this.menu.list.setAttribute('role', 'listbox');
		}
		if (opts.emptyItem) {
			this.insertEmptyItem();
		}
		if (opts.items) {
			for (const it of opts.items) {
				this.addItem(it);
			}
		}
		this.setupCtrl();
		if (opts.required !== undefined) {
			this.setRequired(opts.required);
		}
		if (opts.autoPositionMenu !== undefined) {
			this.setAutoPositionMenuEnabled(opts.autoPositionMenu);
		}
		this.menu.onEvt(this.menuEvt);
	}

	addItem(item: ComboBoxItem): ListItem {
		return this.insertItem(-1, item);
	}

	@bind
	private anchorEvent(event: Event): void {
		if (event.type === 'mouseup') {
			this.anchorMouseUpEvent(<MouseEvent>event);
		}
	}

	anchorMenuToBody(x: number, y: number): void {
		if (this.menu) {
			this.menu.anchorToBody(x, y);
			this.autoPosMenu = true;
		}
	}

	private anchorMouseUpEvent(event: MouseEvent): void {
		if (!this.isOpen() && this.menu) {
			const {clientX, clientY} = event;
			this.menu.anchorToBody(clientX, clientY);
			this.menu.setStyleProperty('width', pixelString(this.rect().width));
		}
	}

	clear(): void {
		if (this.ctrl) {
			this.ctrl.selectedIndex = -1;
		}
	}

	clearItems(): void {
		this.clear();
		if (this.menu) {
			this.menu.clear();
		}
		if (this.haveEmpty) {
			this.insertEmptyItem();
		}
	}

	clone(): ComboBox {
		return new (<typeof ComboBox>this.constructor)(this.initOpts);
	}

	destroy(): void {
		this.destroyCtrl();
		if (this.menu) {
			this.menu.offEvt(this.menuEvt);
			this.menu.destroy();
			this.menu = null;
		}
		super.destroy();
	}

	private destroyCtrl(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
	}

	protected floatingLabelEl(): El | null {
		return this.querySelector('.mdc-floating-label');
	}

	private hiddenInputEl(): El | null {
		return this.querySelector('input[type="hidden"]');
	}

	protected insertEmptyItem(): void {
		this.insertItem(0, {selected: true, disabled: false, value: ''});
		this.haveEmpty = true;
	}

	insertItem(index: number, item: ComboBoxItem): ListItem {
		const attrs: Array<[string, string]> = [
			['aria-selected', item.selected ? 'true' : 'false'],
			['data-value', String(item.value)],
			['role', 'option'],
		];
		const classNames: Array<string> = [];
		if (item.disabled) {
			attrs.push(['aria-disabled', 'true']);
		}
		if (item.selected) {
			classNames.push('mdc-list-item--selected');
			if (!this.ctrl) {
				const txt = this.selectedTextEl();
				if (txt) {
					txt.setText(String(item.value));
				}
			}
		}
		const opts = item.listItemOptions ? {...item.listItemOptions} : {};
		opts.attributes = [...(opts.attributes || []), ...attrs];
		opts.classNames = [...(opts.classNames || []), ...classNames];
		const itm = new ListItem(opts);
		if (this.menu) {
			this.menu.insertItem(index, itm);
		}
		return itm;
	}

	isAutoPositionMenuEnabled(): boolean {
		return this.autoPosMenu;
	}

	isDisabled(): boolean {
		return this.ctrl ? this.ctrl.disabled : false;
	}

	isOpen(): boolean {
		return this.menu ?
			this.menu.isOpen() :
			false;
	}

	isRequired(): boolean {
		return this.ctrl ? this.ctrl.required : false;
	}

	isValid(): boolean {
		if (this.ctrl) {
			return this.ctrl.valid;
		}
		return true;
	}

	leadingIcon(): string {
		const el = this.leadingIconEl();
		return (el ? el.text() : '').trim();
	}

	protected leadingIconEl(): El | null {
		return this.querySelector('.mdc-select__icon');
	}

	@bind
	private menuEvt(evt: Evt): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const index = evt.index();
			const item = this.menu ? this.menu.item(index) : null;
			const value = item ? item.attribute('data-value') : null;
			this.notifyEvt(new ComboBoxChangeEvt(this, index, value || ''));
			if (this.ctrl) {
				this.ctrl.value = value || '';
			}
		}
	}

	name(): string {
		const el = this.hiddenInputEl();
		return el ? el.name() : '';
	}

	protected notchedOutlineEl(): El | null {
		return this.querySelector('.mdc-notched-outline');
	}

	selectedIndex(): number {
		return this.ctrl ? this.ctrl.selectedIndex : -1;
	}

	private selectedTextEl(): El | null {
		return this.querySelector('.mdc-select__selected-text');
	}

	setAutoPositionMenuEnabled(enable: boolean): void {
		if (enable === this.autoPosMenu) {
			return;
		}
		this.autoPosMenu = enable;
		if (!this.autoPosMenu && this.menu) {
			this.menu.removeStyleProperty('width');
		}
		const el = this.querySelector('.mdc-select__anchor');
		if (el) {
			if (this.autoPosMenu) {
				el.addEventListener('mouseup', this.anchorEvent);
			} else {
				el.removeEventListener('mouseup', this.anchorEvent);
			}
		}
	}

	setDisabled(disabled: boolean): void {
		if (this.ctrl) {
			this.ctrl.disabled = disabled;
		}
	}

	setFixPosition(fixPosition: boolean): void {
		if (this.menu) {
			this.menu.setFixPosition(fixPosition);
		}
	}

	setLeadingIcon(iconName?: string | null): void {
		if ((iconName || '').trim() === this.leadingIcon()) {
			return;
		}
		let el = this.leadingIconEl();
		if (!iconName) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			let sibling: El | null = this.floatingLabelEl();
			if (!sibling) {
				sibling = this.notchedOutlineEl();
			}
			if (sibling) {
				el = new El({classNames: ['material-icons', 'mdc-select__icon'], tagName: 'i'});
				sibling.insertAdjacentElement('afterend', el);
			}
		}
		if (el) {
			el.setText(iconName);
		}
	}

	setName(name: string): void {
		let el = this.hiddenInputEl();
		let doCtrl = false;
		if (!el) {
			this.destroyCtrl();
			doCtrl = true;
			el = El.input({
				attributes: [['type', 'hidden']],
				parent: this,
				placementIndex: 0,
			});
		}
		el.setName(name);
		if (doCtrl) {
			this.setupCtrl();
		}
	}

	setRequired(required: boolean): void {
		if (this.ctrl) {
			this.ctrl.required = required;
		}
	}

	setSelectedIndex(index: number): void {
		if (index === this.selectedIndex()) {
			return;
		}
		if (this.ctrl) {
			this.ctrl.selectedIndex = index;
		}
	}

	private setupCtrl(): void {
		if (!this.ctrl) {
			const root = this.element();
			if (root) {
				let menuFactory: ((elem: Element) => MDCMenu) | undefined = undefined;
				if (this.menu && this.menu.ctrl) {
					menuFactory = () => <MDCMenu>this.menu!.ctrl;
				}
				this.ctrl = new MDCSelect(root, undefined, undefined, undefined, undefined, menuFactory);
			}
		}
	}

	setValid(valid: boolean): void {
		if (this.ctrl) {
			this.ctrl.valid = valid;
		}
	}

	setValue(value: string): void {
		if (this.ctrl) {
			this.ctrl.value = value;
		}
	}

	value(): string {
		return this.ctrl ?
			this.ctrl.value :
			'';
	}
}

function buildTree(root: El, opts: Partial<ComboBoxOpts>) {
	root.addClass('mdc-select', opts.outlined ? 'mdc-select--outlined' : 'mdc-select--filled');
	if (opts.noLabel) {
		root.addClass('mdc-select--no-label');
	}
	if (opts.leadingIcon) {
		root.addClass('mdc-select--with-leading-icon');
	}
	const anchor = El.div({classNames: 'mdc-select__anchor'}, root);
	const anchorAttrs: Array<[string, string]> = [
		['role', 'button'],
		['aria-haspopup', 'listbox'],
		['aria-expanded', 'false'],
	];
	let labeledBy: Array<string> = [];
	if (opts.labelId) {
		labeledBy.push(opts.labelId);
	}
	if (opts.selectedTextId) {
		labeledBy.push(opts.selectedTextId);
	}
	if (labeledBy.length > 0) {
		anchorAttrs.push(['aria-labelledby', labeledBy.join(' ')]);
	}
	anchor.setAttribute(anchorAttrs);
	let label: El | null = null;
	if (opts.outlined) {
		const outline = El.div({classNames: 'mdc-notched-outline'}, anchor);
		El.span({classNames: 'mdc-notched-outline__leading'}, outline);
		if (!opts.noLabel) {
			const notch = El.div({classNames: 'mdc-notched-outline__notch'}, outline);
			label = El.span({classNames: 'mdc-floating-label'}, notch);
		}
		El.span({classNames: 'mdc-notched-outline__trailing'}, outline);
	} else {
		El.span({classNames: 'mdc-select__ripple'}, anchor);
		if (!opts.noLabel) {
			label = El.span({classNames: 'mdc-floating-label'}, anchor);
		}
	}
	if (label) {
		if (opts.labelId) {
			label.setId(opts.labelId);
		}
		if (opts.labelText) {
			label.setText(opts.labelText);
		}
	}
	if (opts.leadingIcon) {
		const icon = new El({parent: anchor, classNames: ['material-icons', 'mdc-select__icon'], tagName: 'i'});
		icon.setText(opts.leadingIcon);
	}
	const selectedTextContainer = El.div({classNames: 'mdc-select__selected-text-container'}, anchor);
	const selectedText = El.span({classNames: 'mdc-select__selected-text'}, selectedTextContainer);
	if (opts.selectedTextId) {
		selectedText.setId(opts.selectedTextId);
	}
	const dropdownIcon = El.div({classNames: 'mdc-select__dropdown-icon'}, anchor);
	const dropdownIconGraphic = El.svg({classNames: 'mdc-select__dropdown-icon-graphic'}, dropdownIcon);
	dropdownIconGraphic.setAttribute([['viewBox', '7 10 10 5'], ['focusable', 'false']]);
	const poly1 = El.polygon({classNames: 'mdc-select__dropdown-icon-inactive'}, dropdownIconGraphic);
	poly1.setAttribute([['stroke', 'none'], ['fill-rule', 'evenodd'], ['points', '7 10 12 15 17 10']]);
	const poly2 = El.polygon({classNames: 'mdc-select__dropdown-icon-active'}, dropdownIconGraphic);
	poly2.setAttribute([['stroke', 'none'], ['fill-rule', 'evenodd'], ['points', '7 15 12 10 17 15']]);
	if (!opts.outlined) {
		El.span({classNames: 'mdc-line-ripple'}, anchor);
	}
	El.div({classNames: ['mdc-select__menu', 'mdc-menu', 'mdc-menu-surface', 'mdc-menu-surface--fullwidth']}, root);
}

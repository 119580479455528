import {MDCTextField} from '@material/textfield';

import {El, elOpts, ElOpts} from '../el';
import {CSS_CLASS_FULL_WIDTH} from '../constants';
import {registerTextField} from '../autoinit';
import {bind} from '../util';
import {Evt, EvtType} from '../evt';

interface TextInputIconOpts {
	interactive: boolean;
	name: string;
}

export interface TextInputOpts extends ElOpts {
	columns: number | string | null;
	fullWidth: boolean;
	inputId: string;
	labelText: string;
	leadingIcon: TextInputIconOpts | string;
	name: string;
	outlined: boolean;
	placeholder: string;
	required: boolean;
	resizable: boolean;
	rows: number | string | null;
	textarea: boolean;
	trailingIcon: TextInputIconOpts | string;
	type: string;
}

type Which = 'leading' | 'trailing';

export class TextInputEvt extends Evt {
	private i: TextInput;
	private t: string;

	constructor(type: EvtType, instance: TextInput, text: string) {
		super(type);
		this.i = instance;
		this.t = text;
	}

	text(): string {
		return this.t;
	}

	textInput(): TextInput {
		return this.i;
	}
}

export class TextInputIconEvt extends Evt {
	private w: Which;

	constructor(which: Which) {
		super(Evt.MouseButtonClick);
		this.w = which;
	}

	isLeading(): boolean {
		return this.w === 'leading';
	}

	isTrailing(): boolean {
		return this.w === 'trailing';
	}

	which(): Which {
		return this.w;
	}
}

export class KeyPressEvt extends Evt {
	private ak: boolean;
	private ck: boolean;
	private k: string;
	private mk: boolean;
	private p: boolean;
	private sk: boolean;

	constructor(key: string, altKey: boolean, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean, propagate: boolean) {
		super(Evt.KeyPress);
		this.ak = altKey;
		this.ck = ctrlKey;
		this.k = key;
		this.mk = metaKey;
		this.p = propagate;
		this.sk = shiftKey;
	}

	altKey(): boolean {
		return this.ak;
	}

	ctrlKey(): boolean {
		return this.ck;
	}

	key(): string {
		return this.k;
	}

	metaKey(): boolean {
		return this.mk;
	}

	propagate(): boolean {
		return this.p;
	}

	setPropagate(propagate: boolean): void {
		this.p = propagate;
	}

	shiftKey(): boolean {
		return this.sk;
	}
}

export class TextInput extends El {
	ctrl: MDCTextField | null;
	private initOpts: Partial<TextInputIconOpts>;

	constructor(opts: Partial<TextInputOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<TextInputOpts> | null, parent?: El | null);
	constructor(opts: Partial<TextInputOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<TextInputOpts>, parent?: El | null);
	constructor(opts?: Partial<TextInputOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<TextInputOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<TextInputOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.initOpts = {...opts};
		this.addClass('mdc-text-field', 'pb-text-input');
		if (opts.outlined) {
			this.addClass('mdc-text-field--outlined');
			const outline = El.div({classNames: 'mdc-notched-outline'}, this);
			El.div({classNames: 'mdc-notched-outline__leading'}, outline);
			const notch = El.div({classNames: 'mdc-notched-outline__notch'}, outline);
			El.label({classNames: 'mdc-floating-label'}, notch);
			El.div({classNames: 'mdc-notched-outline__trailing'}, outline);
			if (opts.textarea) {
				this.addClass('mdc-text-field--textarea');
				let textAreaParent: El = this;
				if (opts.resizable) {
					textAreaParent = El.div({classNames: 'mdc-text-field__resizer', parent: this});
				}
				const textarea = new El({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				if (opts.columns) {
					textarea.setAttribute('cols', String(opts.columns));
				}
				if (opts.rows) {
					textarea.setAttribute('rows', String(opts.rows));
				}
			} else {
				const input = El.input({classNames: 'mdc-text-field__input'}, this);
				input.setType('text');
			}
		} else {
			this.addClass('mdc-text-field--filled');
			El.div({classNames: 'mdc-text-field__ripple'}, this);
			El.label({classNames: 'mdc-floating-label'}, this);
			if (opts.textarea) {
				this.addClass('mdc-text-field--textarea');
				let textAreaParent: El = this;
				if (opts.resizable) {
					textAreaParent = El.div({classNames: 'mdc-text-field__resizer', parent: this});
				}
				const textarea = new El({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				if (opts.columns) {
					textarea.setAttribute('cols', String(opts.columns));
				}
				if (opts.rows) {
					textarea.setAttribute('rows', String(opts.rows));
				}
			} else {
				const input = El.input({classNames: 'mdc-text-field__input'}, this);
				input.setType('text');
			}
			El.div({classNames: 'mdc-line-ripple'}, this);
		}
		if (opts.type) {
			this.setType(opts.type);
		}
		if (opts.name) {
			this.setName(opts.name);
		}
		if (opts.inputId) {
			this.setInputId(opts.inputId);
		}
		if (opts.labelText) {
			this.setLabelText(opts.labelText);
		}
		const inp = this.inputEl();
		if (inp) {
			inp.addEventListener('change', this.event, true);
			inp.addEventListener('input', this.event, true);
			inp.addEventListener('keydown', this.event, true);
		}
		this.addEventListener('MDCTextField:icon', this.event);
		registerTextField(<HTMLElement>this.elem);
		if (opts.required) {
			this.setRequired(opts.required);
		}
		if (opts.fullWidth) {
			this.setFullWidth(opts.fullWidth);
		}
		if (opts.leadingIcon) {
			if (typeof opts.leadingIcon === 'string') {
				this.setLeadingIcon(opts.leadingIcon, false);
			} else {
				this.setLeadingIcon(opts.leadingIcon.name, opts.leadingIcon.interactive);
			}
		}
		if (opts.trailingIcon) {
			if (typeof opts.trailingIcon === 'string') {
				this.setTrailingIcon(opts.trailingIcon, false);
			} else {
				this.setTrailingIcon(opts.trailingIcon.name, opts.trailingIcon.interactive);
			}
		}
		if (opts.placeholder) {
			this.setPlaceholder(opts.placeholder);
		}
		this.ctrl = new MDCTextField(this.elem);
		this.ctrl.layout();
	}

	autocomplete(value?: string): void {
		const el = this.inputEl();
		if (el) {
			if (value) {
				el.setAttribute('autocomplete', value);
			} else {
				el.removeAttribute('autocomplete');
			}
		}
	}

	blur(): void {
		const el = this.inputEl();
		if (el) {
			el.blur();
		}
	}

	protected changeEvent(): void {
		this.notifyEvt(new TextInputEvt(Evt.Change, this, this.value()));
	}

	clear(): void {
		this.setValue('');
		// we don't get an event when setting value to the empty string. Don't
		// know but I've also not spent any time looking into it. Probably
		// just Google being crappy at writing software again.
		this.notifyEvt(new TextInputEvt(Evt.Input, this, this.value()));
		this.notifyEvt(new TextInputEvt(Evt.Change, this, this.value()));
	}

	clone(): TextInput {
		return new (<typeof TextInput>this.constructor)(this.initOpts);
	}

	destroy(): void {
		const inp = this.inputEl();
		if (inp) {
			inp.removeEventListener('keydown', this.event, true);
		}
		this.removeEventListener('MDCTextField:icon', this.event);
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		super.destroy();
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'keydown':
				this.keyPressEvent(<KeyboardEvent>event);
				break;
			case 'input':
				this.inputEvent();
				break;
			case 'change':
				this.changeEvent();
				break;
			case 'MDCTextField:icon':
				this.iconEvent(event);
				break;
		}
	}

	focus(): void {
		if (this.ctrl) {
			this.ctrl.focus();
		}
	}

	private iconEl(which: Which): El | null {
		return this.querySelector(`.mdc-text-field__icon--${which}`);
	}

	protected iconEvent(event: Event): void {
		let el: El | null = null;
		try {
			el = El.fromEvent(event).closestMatchingAncestor('.mdc-text-field__icon');
		} catch {
			return;
		}
		let which: Which | null = null;
		if (el) {
			if (el.eq(this.iconEl('leading'))) {
				which = 'leading';
			} else if (el.eq(this.iconEl('trailing'))) {
				which = 'trailing';
			}
		}
		if (which) {
			this.notifyEvt(new TextInputIconEvt(which));
		}
	}

	private inputEl(): El | null {
		return this.querySelector('.mdc-text-field__input');
	}

	protected inputEvent(): void {
		this.notifyEvt(new TextInputEvt(Evt.Input, this, this.value()));
	}

	inputId(): string {
		const el = this.inputEl();
		return el ? el.id() : '';
	}

	private insertIconEl(el: El, which: Which): boolean {
		if (which === 'leading') {
			if (this.isOutlined()) {
				// <span class="mdc-notched-outline">
				// 	<span class="mdc-notched-outline__leading"></span>
				// 	<span class="mdc-notched-outline__notch">
				// 		<span class="mdc-floating-label" id="my-label-id">Your Name</span>
				// 	</span>
				// 	<span class="mdc-notched-outline__trailing"></span>
				// </span>
				// <i class="material-icons mdc-text-field__icon mdc-text-field__icon--leading" tabindex="0" role="button">event</i>
				// <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
				const outlineEl = this.outlineEl();
				if (outlineEl) {
					outlineEl.insertAdjacentElement('afterend', el);
					return true;
				}
				const inputEl = this.inputEl();
				if (inputEl) {
					inputEl.insertAdjacentElement('beforebegin', el);
					return true;
				}
				return false;
			} else {
				// <span class="mdc-floating-label" id="my-label-id">Your Name</span>
				// <i class="material-icons mdc-text-field__icon mdc-text-field__icon--leading" tabindex="0" role="button">event</i>
				// <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
				const labelEl = this.labelEl();
				if (labelEl) {
					labelEl.insertAdjacentElement('afterend', el);
					return true;
				}
				const inputEl = this.inputEl();
				if (inputEl) {
					inputEl.insertAdjacentElement('beforebegin', el);
					return true;
				}
				return false;
			}
		} else if (which === 'trailing') {
			if (this.isOutlined()) {
				// 	<input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
				// 	<i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing" tabindex="0" role="button">event</i>
				// </root>
				this.appendChild(el);
				return true;
			} else {
				// <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
				// <i class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing" tabindex="0" role="button">event</i>
				// <span class="mdc-line-ripple"></span>
				const inputEl = this.inputEl();
				if (inputEl) {
					inputEl.insertAdjacentElement('afterend', el);
					return true;
				}
				const ripEl = this.lineRippleEl();
				if (ripEl) {
					ripEl.insertAdjacentElement('beforebegin', el);
					return true;
				}
				return false;
			}
		} else {
			return false;
		}
	}

	isDisabled(): boolean {
		if (this.ctrl) {
			return this.ctrl.disabled;
		}
		return false;
	}

	private isFullWidth(): boolean {
		return this.hasClass(CSS_CLASS_FULL_WIDTH);
	}

	private isOutlined(): boolean {
		return this.hasClass('mdc-text-field--outlined');
	}

	isRequired(): boolean {
		if (this.ctrl) {
			return this.ctrl.required;
		}
		return false;
	}

	private isTextArea(): boolean {
		return this.hasClass('mdc-text-field--textarea');
	}

	isValid(): boolean {
		if (this.ctrl) {
			this.ctrl.valid;
		}
		return true;
	}

	protected keyPressEvent(event: KeyboardEvent): void {
		const {key, altKey, ctrlKey, metaKey, shiftKey} = event;
		const evt = new KeyPressEvt(key, altKey, ctrlKey, metaKey, shiftKey, true);
		this.notifyEvt(evt);
		if (!evt.propagate()) {
			event.stopPropagation();
		}
	}

	private labelEl(): El | null {
		return this.querySelector('label');
	}

	labelText(): string {
		const el = this.labelEl();
		return el ? el.text() : '';
	}

	private lineRippleEl(): El | null {
		return this.querySelector('.mdc-line-ripple');
	}

	max(): string {
		if (this.ctrl) {
			return this.ctrl.max;
		}
		return '';
	}

	min(): string {
		if (this.ctrl) {
			return this.ctrl.min;
		}
		return '';
	}

	name(): string {
		const el = this.inputEl();
		return el ? el.name() : '';
	}

	private outlineEl(): El | null {
		return this.querySelector('.mdc-notched-outline');
	}

	reportValidity(): boolean {
		const el = this.inputEl();
		return el ? el.reportValidity() : super.reportValidity();
	}

	setDisabled(disabled: boolean): void {
		this.ctrl && (this.ctrl.disabled = disabled);
	}

	setFullWidth(fullWidth: boolean): void {
		if (fullWidth === this.isFullWidth()) {
			return;
		}
		this.setClass(fullWidth, CSS_CLASS_FULL_WIDTH);
		this.ctrl && this.ctrl.layout();
	}

	setInputId(id: string): void {
		let el = this.inputEl();
		if (el) {
			el.setId(id);
		}
		el = this.labelEl();
		if (el) {
			el.setAttribute('for', id);
		}
	}

	setLabelText(text: string): void {
		const el = this.labelEl();
		if (el) {
			el.setText(text);
		}
	}

	setIcon(which: Which, iconName: string, interactive?: boolean): void {
		// Passing the empty string removes the icon
		iconName = iconName.trim();
		let el = this.iconEl(which);
		if (!iconName) {
			if (!el) {
				return;
			}
			el.destroy();
			this.removeClass(`mdc-text-field--with-${which}-icon`);
			return;
		}
		if (!el) {
			this.addClass(`mdc-text-field--with-${which}-icon`);
			el = new El({classNames: ['material-icons', 'mdc-text-field__icon', `mdc-text-field__icon--${which}`]}, 'i');
			if (!this.insertIconEl(el, which)) {
				el.destroy();
				return;
			}
		}
		el.setText(iconName);
		if (interactive !== undefined) {
			this.setIconElInteractive(el, interactive);
		}
	}

	private setIconElInteractive(el: El, interactive: boolean): void {
		if (interactive) {
			el.setAttribute('role', 'button');
			el.setTabIndex(0);
		} else {
			el.removeAttribute('tabindex');
			el.removeAttribute('role');
		}
	}

	setLeadingIcon(iconName: string, interactive: boolean = false): void {
		this.setIcon('leading', iconName, interactive);
	}

	setMax(value: number | string): void {
		this.ctrl && (this.ctrl.max = String(value));
	}

	setMin(value: number | string): void {
		this.ctrl && (this.ctrl.min = String(value));
	}

	setName(name: string): void {
		const el = this.inputEl();
		if (el) {
			el.setName(name);
		}
	}

	setPlaceholder(placeholder?: string): void {
		const inp = this.inputEl();
		if (inp) {
			if (placeholder) {
				inp.setAttribute('placeholder', placeholder);
			} else {
				inp.removeAttribute('placeholder');
			}
		}
	}

	setRequired(required: boolean): void {
		this.ctrl && (this.ctrl.required = required);
	}

	setStep(value: number | string): void {
		this.ctrl && (this.ctrl.step = String(value));
	}

	setTrailingIcon(iconName: string, interactive: boolean = false): void {
		this.setIcon('trailing', iconName, interactive);
	}

	setType(type: string): void {
		if (!this.isTextArea()) {
			const el = this.inputEl();
			if (el) {
				el.setType(type);
			}
		}
	}

	setValid(valid: boolean): void {
		this.ctrl && (this.ctrl.valid = valid);
	}

	setValue(value: number | string): void {
		this.ctrl && (this.ctrl.value = String(value));
	}

	step(): string {
		return this.ctrl ? this.ctrl.step : '';
	}

	type(): string {
		if (this.isTextArea()) {
			return '';
		}
		const el = this.inputEl();
		return el ? el.type() : '';
	}

	value(): string {
		return this.ctrl ? this.ctrl.value : '';
	}
}

import axios from 'axios';
import {REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY} from '../constants';

const STANDARD_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};
const XSRF_CFG = {
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
};

export class RequestService implements IRequestService {
	DELETE<T>(url: string, data?: any, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<IResponse<T>> {
		return this.request({data: data, method: 'DELETE', url: url, ...cfg});
	}

	GET<T>(url: string, params?: any, cfg?: Omit<IRequestConfig, 'params' | 'method' | 'url'>): Promise<IResponse<T>> {
		return this.request({method: 'GET', params: params, url: url, ...cfg});
	}

	PATCH<T>(url: string, data?: any, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<IResponse<T>> {
		return this.request({data: data, method: 'PATCH', url: url, ...cfg});
	}

	POST<T>(url: string, data?: any, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<IResponse<T>> {
		return this.request({data: data, method: 'POST', url: url, ...cfg});
	}

	PUT<T>(url: string, data?: any, cfg?: Omit<IRequestConfig, 'data' | 'method' | 'url' | 'wellKnown'>): Promise<IResponse<T>> {
		return this.request({data: data, method: 'PUT', url: url, ...cfg});
	}

	prepareRequest(cfg: Omit<IRequestConfig, 'wellKnown'>): IRequestConfig {
		const headers: any = cfg.headers ?
			{...STANDARD_HEADERS, ...cfg.headers} :
			{...STANDARD_HEADERS};
		let rv: IRequestConfig = {
			data: cfg.data,
			headers: headers,
			method: cfg.method,
			params: cfg.params,
			responseType: cfg.responseType ? cfg.responseType : 'json',
			timeout: cfg.timeout,
			url: cfg.url,
			wellKnown: Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY),
		};
		switch (cfg.method) {
			case 'DELETE':
			case 'PATCH':
			case 'POST':
			case 'PUT':
				rv = {...rv, ...XSRF_CFG};
				break;
		}
		return rv;
	}

	async request<T>(cfg: Omit<IRequestConfig, 'wellKnown'>): Promise<IResponse<T>> {
		const prepped = this.prepareRequest(cfg);
		const rv = await axios.request<T>(prepped);
		rv.config = prepped;
		return <IResponse<T>>rv;
	}
}

export function isErrorResponse(obj: any): obj is IResponse<IErrorResponseData> {
	try {
		return ('data' in obj)
			&& ('error' in obj.data)
			&& ('code' in obj.data.error)
			&& ('details' in obj.data.error)
			&& ('message' in obj.data.error)
			&& ('target' in obj.data.error);
	} catch {
	}
	return false;
}

export function isRequestConfig(obj: any): obj is IRequestConfig {
	try {
		if ('wellKnown' in obj) {
			return obj.wellKnown === Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY);
		}
	} catch {
	}
	return false;
}

export function isNetworkErrorObject(obj: any): obj is INetworkError {
	try {
		return (obj instanceof Error)
			&& ('isAxiosError' in obj)
			&& ((<any>obj).isAxiosError === true)
			&& (isErrorResponse((<any>obj).response) || ((<any>obj).response === undefined))
			&& ('config' in obj)
			&& isRequestConfig((<any>obj).config);
	} catch {
	}
	return false;
}

import React, {PropsWithChildren} from 'react';

interface IProps {
	primaryText?: string;
	secondaryText?: string;
}

interface IHeadings {
	primary?: string;
	secondary?: string;
}

function headings(primary?: string, secondary?: string): IHeadings {
	const rv: IHeadings = {primary, secondary};
	if (rv.primary && rv.secondary) {
		rv.secondary = ` \u2003\u2014 ${rv.secondary}`;
	}
	return rv;
}

export default function Header(props: PropsWithChildren<IProps>) {
	const {children, primaryText, secondaryText} = props;
	const h = headings(primaryText, secondaryText);
	return (
		<header className="base-card-header">
			{(h.primary || h.secondary) ?
				<h2 className="mdc-typography--title">
					{h.primary ? h.primary : null}{h.secondary}
				</h2> :
				null}
			{children}
		</header>
	);
}

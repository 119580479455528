import {MDCSwitch} from '@material/switch';
import {MDCTextField} from '@material/textfield';

import {set} from './tools';

const switchReg: set<HTMLElement> = new set<HTMLElement>();
const textFieldReg: set<HTMLElement> = new set<HTMLElement>();

function initSwitch(node: HTMLElement): void {
	if (switchReg.has(node)) {
		return;
	}
	switchReg.add(node);
	try {
		new MDCSwitch(node);
	} catch (err) {
		console.log('During autoinit, an exception occurred: %s', err);
	}
}

function initSwitches(): void {
	document.querySelectorAll<HTMLElement>('.mdc-switch')
		.forEach(node => initSwitch(node));
}

function initTextField(node: HTMLElement): void {
	if (textFieldReg.has(node)) {
		return;
	}
	textFieldReg.add(node);
	try {
		new MDCTextField(node);
	} catch (err) {
		console.log('During autoinit, an exception occurred: %s', err);
	}
}

function initTextFields(): void {
	document.querySelectorAll<HTMLElement>('.mdc-text-field')
		.forEach(node => initTextField(node));
}

export function autoInit(): void {
	initSwitches();
	initTextFields();
}

export function registerTextField(node: HTMLElement): void {
	textFieldReg.add(node);
}

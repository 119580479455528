import {CSRF_COOKIE_NAME, CSRF_INPUT_NAME} from '../constants';
import {bind, extractCookie} from '../util';
import {El} from '../el';

type Obs = () => any;

export class Form extends El {
	obs: Obs | null;

	constructor(parent: El | null = null) {
		super('form', parent);
		this.obs = null;
	}

	private installCSRF(): void {
		const csrfToken = extractCookie(CSRF_COOKIE_NAME);
		if (!csrfToken) {
			console.log('Form::installCSRF CSRF token is null');
			return;
		}
		let elem = this.querySelector(`input[type="hidden"][name="${CSRF_INPUT_NAME}"]`);
		if (!elem) {
			elem = El.input();
			elem.setName(CSRF_INPUT_NAME);
			elem.setType('hidden');
			this.insertAdjacentElement('afterbegin', elem);
		}
		elem.setValue(csrfToken);
	}

	isValid(): boolean {
		return this.reportValidity();
	}

	method(): string {
		if (this.elem instanceof HTMLFormElement) {
			return this.elem.method;
		}
		return '';
	}

	onSubmit(cb?: Obs | null): void {
		this.obs = cb || null;
		if (this.obs) {
			this.addEventListener('submit', this.submitEvent);
		} else {
			this.removeEventListener('submit', this.submitEvent);
		}
	}

	removeCSRF(): void {
		const elem = this.querySelector(`input[type="hidden"][name="${CSRF_INPUT_NAME}"]`);
		if (elem) {
			elem.setName('');
			elem.setValue('');
			elem.remove();
		}
	}

	setAction(url?: string): void {
		if (url) {
			this.setAttribute('action', url);
		} else {
			this.removeAttribute('action');
		}
	}

	setMethod(method: string): void {
		this.setAttribute('method', method);
		if (method.trim().toUpperCase() === 'POST') {
			this.installCSRF();
		} else {
			this.removeCSRF();
		}
	}

	@bind
	protected submitEvent(event: Event): void {
		if (this.obs) {
			event.preventDefault();
			this.obs();
		}
	}
}

import React from 'react';

import CTA from './cta';
import Choice from './choice';
import {
	List,
	ListItem,
	IconButton,
	ListItemDetail,
} from '../../../components';

interface IProps {
	objects: IChoice[];
	onAdd: () => any;
	onChange: (object: IChoice) => any;
	onDelete: (object: IChoice) => any;
	takeFocus?: boolean;
}

export default class ChoiceList extends React.Component<IProps, {}> {
	render(): React.ReactNode {
		const {objects, onAdd, onChange, onDelete, takeFocus} = this.props;
		if (objects.length > 0) {
			return (
				<React.Fragment>
					<p className="pb-catalog-item-form__subsection-label text-align--right">Default selected</p>
					<List ariaLabel="Item Choice List" dense={true} role="group">
						{objects.map((obj, idx) =>
							<Choice
								key={obj.id}
								object={obj}
								onChange={onChange}
								onDeleteRequest={onDelete}
								takeFocus={takeFocus && (idx === (objects.length - 1))}/>)}
						<AddButton onClick={onAdd}/>
					</List>
				</React.Fragment>
			);
		}
		return <CTA className="text-align--center" onClick={onAdd} text="Add choice"/>;
	}
}

function AddButton({onClick}: {onClick: () => any}) {
	return (
		<ListItem
			checked={false}
			className="no-pointer-except-for-detail"
			leadingChild={
				<ListItemDetail>
					<IconButton
						className="pb-icon-button--size-20-5"
						icon="add_circle_outline"
						onClick={onClick}
						style={{color: '#2D65FF'}}/>
				</ListItemDetail>}
			noRipple={true}>
		</ListItem>
	);
}

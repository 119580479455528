import React, {PropsWithChildren} from 'react';
import {
	MDCTextFieldHelperTextAdapter,
	MDCTextFieldHelperTextFoundation,
} from '@material/textfield/helper-text';

import {bind, cssClassName} from '../../../util';

type Props = PropsWithChildren<{persistent?: boolean}>;
type State = {classNames: Set<string>;};

export default class HelpText extends React.Component<Props, State> {
	private _mdc: MDCTextFieldHelperTextFoundation | null;
	private _rootRef: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this.state = {
			classNames: new Set(['mdc-text-field-helper-text']),
		};
		this._mdc = null;
		this._rootRef = React.createRef();
	}

	componentDidMount(): void {
		const {persistent} = this.props;
		this._mdc = new MDCTextFieldHelperTextFoundation(this._mdcAdapter());
		this._mdc.init();
		this._mdc.setPersistent(Boolean(persistent));
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {persistent: persistentPrev} = prevProps;
		const {persistent} = this.props;
		const mdc = this._mdc;
		if (mdc) {
			if (persistentPrev !== persistent) {
				mdc.setPersistent(Boolean(persistent));
			}
		}
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	render(): React.ReactNode {
		const {children} = this.props;
		const {classNames} = this.state;
		return <div aria-hidden={true} className={cssClassName(...classNames)} ref={this._rootRef}>{children}</div>;
	}

	private _mdcAdapter(): MDCTextFieldHelperTextAdapter {
		return {
			addClass: this._mdcAddClass,
			getAttr: this._mdcGetAttr,
			hasClass: this._mdcHasClass,
			removeAttr: this._mdcRemoveAttr,
			removeClass: this._mdcRemoveClass,
			setAttr: this._mdcSetAttr,
			setContent: this._mdcSetContent,
		};
	}

	@bind
	private _mdcAddClass(value: string): void {
		const {classNames} = this.state;
		classNames.add(value);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcGetAttr(name: string): string | null {
		const elem = this._rootRef.current;
		if (elem) {
			return elem.getAttribute(name);
		}
		return null;
	}

	@bind
	private _mdcHasClass(value: string): boolean {
		return this.state.classNames.has(value);
	}

	@bind
	private _mdcRemoveAttr(name: string): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.removeAttribute(name);
		}
	}

	@bind
	private _mdcRemoveClass(value: string): void {
		const {classNames} = this.state;
		classNames.delete(value);
		this.setState({classNames: new Set(classNames)});
	}

	@bind
	private _mdcSetAttr(name: string, value: string): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.setAttribute(name, value);
		}
	}

	@bind
	private _mdcSetContent(value: string): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.textContent = value;
		}
	}
}

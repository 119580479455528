const initState: IState = {
	buggy: {
		addonIDs: [],
		choiceIDs: [],
		interactedItemIDs: [],
		itemIDs: [],
	},
	catalog: {
		allChoices: [],
		allItems: [],
		choiceByID: {},
		itemByID: {},
	},
	global: {
		error: null,
		marketWeather: {},
	},
	producer: {
		accessOptions: [],
		clientUsers: [],
		hours: [],
		items: [],
		markets: [],
		occupancyOptions: [],
	},
	projectCreate: {
		accessNotes: '',
		accessOptionId: null,
		clientUserID: null,
		description: '',
		duration: 0,
		locationName: '',
		marketID: null,
		notes: '',
		occupancyOptionId: null,
		size: null,
		street: '',
		summary: null,
		clientDueDate: '',
		clientDueTime: '',
	},
	user: {
		clientUserTypeDisplay: '',
		clientUserTypeId: null,
		defaultMarketId: null,
		email: '',
		emailAddresses: [],
		excludeAvailability: false,
		firstName: '',
		groupDisplay: '',
		groupId: null,
		id: 0,
		isAdmin: false,
		isProducer: false,
		itemExclusions: [],
		itemPostProd: [],
		lastName: '',
		markets: [],
		name: '',
		notes: [],
		phoneNumbers: [],
		qbCustomerId: '',
		receiveNotifications: true,
		typeId: null,
	},
};

export function initialState<K extends keyof IState>(key: K): IState[K] {
	return Object.assign({}, initState[key]);
}

import React from 'react';

import CTA from './cta';
import TableView from './tableview';
import {bind} from '../../../../util';

interface IProps {
	itemPrices: (id: number) => IPriceGroupItemPrice[];
	objects: IItem[];
	onAdd: () => any;
	onClick: (object: IItem) => any;
}

export default class ChildTable extends React.Component<IProps, IState> {
	@bind
	ctaClicked(): void {
		this.props.onAdd();
	}

	render(): React.ReactNode {
		const {
			itemPrices,
			objects,
			onClick,
		} = this.props;
		if (objects.length > 0) {
			return (
				<React.Fragment>
					<TableView
						objects={objects}
						onClick={onClick}
						objectPrices={itemPrices}
					/>
					<CTA
						className="padding-top--16"
						onClick={this.ctaClicked}
						text="Add variant"
					/>
				</React.Fragment>
			);
		}
		return <CTA
			className="text-align--center"
			onClick={this.ctaClicked}
			text="Add variant"
		/>;
	}
}

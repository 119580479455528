import {apiService as svc} from '../services';
import {numberFormat} from '../util';
import {El} from '../el';

const QB_OBJECT_ID_DATA_ATTRIBUTE_NAME = 'data-object-id';
const SYNC_ELEMENT_SELECTOR = '#id_qb-sync-button';

function objectCountSelector(objectId: string): string {
	return `#id_${objectId}-count`;
}

function objectElem(objectId: string): El {
	return El.fromSelector(objectSelector(objectId));
}

function objectSelector(objectId: string): string {
	return `[${QB_OBJECT_ID_DATA_ATTRIBUTE_NAME}="${objectId}"]`;
}

export function init(): void {
	initListeners();
}

function initListeners(): void {
	const elem = document.querySelector(SYNC_ELEMENT_SELECTOR);
	if (elem) {
		elem.addEventListener('click', syncElementEvent);
	}
}

async function syncElementEvent(event: Event): Promise<void> {
	const btn = El.fromEvent(event).closestMatchingAncestor(SYNC_ELEMENT_SELECTOR);
	const btnLabel = btn && btn.querySelector('.mdc-button__label');
	const btnLabelText = btnLabel ? btnLabel.text() : '';
	btn && btn.setDisabled(true);
	btnLabel && btnLabel.setText('Syncing...');
	try {
		const data = await svc.group.app.quickbooks.sync({accounts: true, customers: true, items: true});
		data.objects.forEach(obj => updateDocument(obj.id, obj.count));
	} catch (err) {
		console.log(err);
	} finally {
		btnLabel && btnLabel.setText(btnLabelText);
		btn && btn.setDisabled(false);
	}
}

function updateDocument(objectId: string, count: number): void {
	const root = objectElem(objectId);
	const countElem = root.querySelector(objectCountSelector(objectId));
	countElem && countElem.setText(numberFormat(count));
}

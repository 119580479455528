import React from 'react';

import {isNumber} from '../../../../util';
import DenseTextInput from './densetextinput';
import {IconButton} from '../../../components';

interface IProps {
	limitChoiceCount: boolean;
	max?: number;
	onChange: <K extends keyof Pick<IItem, 'limitChoiceCount' | 'maxChoices'>>(value: IItem[K], name: K) => any;
	value: number;
}

export default class MaxChoices extends React.Component<IProps, {}> {
	static Name: 'maxChoices' = 'maxChoices';

	constructor(props: IProps) {
		super(props);
		this.iconButtonClickEvent = this.iconButtonClickEvent.bind(this);
		this.textInputChangeEvent = this.textInputChangeEvent.bind(this);
	}

	iconButtonClickEvent(): void {
		const {limitChoiceCount, onChange} = this.props;
		onChange(!limitChoiceCount, 'limitChoiceCount');
	}

	name(): 'maxChoices' {
		return MaxChoices.Name;
	}

	render(): React.ReactNode {
		const {limitChoiceCount, max, value} = this.props;
		return (
			<div className="padding-top--8 padding-left--12">
				<div className="display--flex flex-direction--row align-items--center">
					<LimitChoiceCountToggle
						limitChoiceCount={limitChoiceCount}
						onClick={this.iconButtonClickEvent}/>
					<label className="display--block pb-catalog-item-form__subsection-label" htmlFor="id_maxChoices" style={{opacity: limitChoiceCount ? undefined : '0.57'}}>
						Limit # of selected choices
					</label>
				</div>
				<div style={{paddingLeft: '37px', width: '156px'}}>
					<DenseTextInput
						className="pb-text-field--not-so-tall"
						disabled={!limitChoiceCount}
						id="id_maxChoices"
						max={max}
						min={0}
						name={this.name()}
						noLabel={true}
						onChange={this.textInputChangeEvent}
						step={1}
						type="number"
						value={value}/>
				</div>
			</div>
		);
	}

	textInputChangeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		const value = Number.parseInt((event as React.ChangeEvent<HTMLInputElement>).target.value);
		if (isNumber(value)) {
			onChange(value, this.name());
		}
	}
}

function LimitChoiceCountToggle({limitChoiceCount, onClick}: {limitChoiceCount: boolean; onClick: () => any}) {
	return (
		<div className="padding-right--8">
			<IconButton
				className="pb-icon-button--size-20-5"
				onClick={onClick}
				icon={limitChoiceCount ? 'toggle_on' : 'toggle_off'}
				style={{color: limitChoiceCount ? '#2D65FF' : 'rgba(0, 0, 0, 0.38)'}}/>
		</div>
	);
}

import {El, elOpts} from '../../el';
import {ComboBox} from '../../ui/combobox';
import {bind, isIterable, isNumber, pixelString} from '../../util';
import {CCardEdit, CCardEditOpts, CCardEditSectionRow} from '../../ui/ccard/edit';
import {getLogger} from '../../logging';
import {Menu, MenuStateChangeEvt} from '../../ui/menu';
import {Chip, ChipEvt} from '../../ui/chipset';
import {Evt} from '../../evt';
import {list} from '../../tools';
import {ListItem, ListItemSelectEvt} from '../../ui/list';
import {RowCtrlEvt} from '../../ui/ccard/rowctrl';

const logger = getLogger('site::clientuserlist::ccard');

enum ClientUserCCardEditFieldName {
	FirstName = 'First name',
	LastName = 'Last name',
	Email = 'Email',
	Phone = 'Phone',
	Note = 'Note',
	Label = 'Label',
	Group = 'Group',
	QBCustomer = 'Customer',
}

enum ClientUserCCardEditRowType {
	Name = 'Name',
	Email = 'Email',
	Phone = 'Phone',
	Note = 'Note',
	Group = 'Group',
	Integration = 'Integration',
}

export interface ClientUserCCardEditOpts extends CCardEditOpts {
	clientUserTypes: Iterable<IClientUserType>;
	emailAddressFactory: () => IEmailAddress;
	groups: Iterable<IGroup>;
	isAdmin: boolean;
	noteFactory: () => INote;
	phoneNumberFactory: () => IPhoneNumber;
	qbCustomers: Iterable<IQuickBooksCustomer>;
}

export class ClientUserCCardEdit extends CCardEdit {
	private admin: boolean;
	private adminChip: Chip | null;
	private clientUserTypeMenu: Menu | null;
	private clientUserTypes: list<IClientUserType>;
	private currentClientUserTypeId: number | null;
	private emailAddressFactory: (() => IEmailAddress) | null;
	private listItemClientUserTypeMap: Map<ListItem, IClientUserType>;
	private noteFactory: (() => INote) | null;
	private phoneNumberFactory: (() => IPhoneNumber) | null;
	private rowEmailMap: Map<CCardEditSectionRow, IEmailAddress>;
	private rowNoteMap: Map<CCardEditSectionRow, INote>;
	private rowPhoneMap: Map<CCardEditSectionRow, IPhoneNumber>;

	constructor(opts: Partial<ClientUserCCardEditOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ClientUserCCardEditOpts> | null, root?: Element | null);
	constructor(opts: Partial<ClientUserCCardEditOpts>, parent?: El | null);
	constructor(opts?: Partial<ClientUserCCardEditOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ClientUserCCardEditOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ClientUserCCardEditOpts>(a, b, c);
		if (!opts.header) {
			opts.header = 'Edit client';
		}
		opts.rowTypes = [
			{
				iconName: 'account_circle',
				many: false,
				sectionIndex: 0,
				type: ClientUserCCardEditRowType.Name,
			},
			{
				iconName: 'mail_outline',
				many: true,
				sectionIndex: 0,
				type: ClientUserCCardEditRowType.Email,
			},
			{
				iconName: 'phone',
				many: true,
				sectionIndex: 0,
				type: ClientUserCCardEditRowType.Phone,
			},
			{
				iconName: 'notes',
				many: true,
				sectionIndex: 0,
				type: ClientUserCCardEditRowType.Note,
			},
			{
				iconName: 'groups',
				many: false,
				sectionIndex: 0,
				type: ClientUserCCardEditRowType.Group,
			},
			{
				iconName: 'person',
				many: false,
				sectionIndex: 1,
				type: ClientUserCCardEditRowType.Integration,
			},
		];
		super(opts);
		this.adminChip = this.addChip({
			leadingIcon: 'account_circle',
			styles: [['margin-right', '8px']],
		}, 0);
		if (this.adminChip) {
			this.adminChip.setText('Regular Privileges');
			this.adminChip.setTitle('User privileges');
		}
		this.setLabelToolTip('Client user type');
		this.admin = false;
		this.clientUserTypeMenu = null;
		this.clientUserTypes = new list<IClientUserType>(opts.clientUserTypes);
		this.currentClientUserTypeId = null;
		this.emailAddressFactory = opts.emailAddressFactory || null;
		this.listItemClientUserTypeMap = new Map<ListItem, IClientUserType>();
		this.noteFactory = opts.noteFactory || null;
		this.phoneNumberFactory = opts.phoneNumberFactory || null;
		this.rowEmailMap = new Map<CCardEditSectionRow, IEmailAddress>();
		this.rowPhoneMap = new Map<CCardEditSectionRow, IPhoneNumber>();
		this.rowNoteMap = new Map<CCardEditSectionRow, INote>();
		this.addIntegrationsSection('QuickBooks');
		if (opts.isAdmin) {
			this.setIsAdmin(true);
		}
		this.addRow(ClientUserCCardEditRowType.Name);
		this.addField(
			ClientUserCCardEditFieldName.FirstName,
			ClientUserCCardEditRowType.Name);
		this.addField(
			ClientUserCCardEditFieldName.LastName,
			ClientUserCCardEditRowType.Name);
		if (opts.groups) {
			const objs = Array.isArray(opts.groups) ?
				opts.groups :
				Array.from(opts.groups);
			const groupsControl = new ComboBox({
				autoPositionMenu: true,
				classNames: 'pb-combo-box--underlined-less-dense-no-padding',
				items: objs.map(obj => ({listItemOptions: {text: obj.name}, value: obj.id})),
				labelId: 'id_group-combo-box',
				labelText: 'Group',
				required: true,
			});
			groupsControl.setStyleProperty('min-width', pixelString(320));
			this.addRow(ClientUserCCardEditRowType.Group);
			this.addField(
				ClientUserCCardEditFieldName.Group,
				ClientUserCCardEditRowType.Group,
				-1,
				groupsControl);
		}
		if (opts.qbCustomers) {
			const objs: Array<IQuickBooksCustomer> = Array.isArray(opts.qbCustomers) ?
				opts.qbCustomers :
				Array.from(opts.qbCustomers);
			const qbCtrl = new ComboBox({
				autoPositionMenu: true,
				classNames: 'pb-combo-box--underlined-less-dense-no-padding',
				items: objs.map(obj => ({listItemOptions: {text: obj.displayName}, value: obj.id})),
				labelId: 'id_qb-customer-combo-box',
				labelText: ClientUserCCardEditFieldName.QBCustomer,
			});
			this.addRow(ClientUserCCardEditRowType.Integration);
			this.addField(
				ClientUserCCardEditFieldName.QBCustomer,
				ClientUserCCardEditRowType.Integration,
				-1,
				qbCtrl);
		}
		this.focusField(
			'First name',
			ClientUserCCardEditRowType.Name,
			0,
			42);
	}

	addEmailRow(obj: IEmailAddress, enabled: boolean = true): void {
		const row = this.addRow(ClientUserCCardEditRowType.Email, enabled);
		if (row) {
			this.addField(
				ClientUserCCardEditFieldName.Email,
				ClientUserCCardEditRowType.Email);
			this.addField(
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Email);
			this.setFieldValue(
				obj.address,
				ClientUserCCardEditFieldName.Email,
				ClientUserCCardEditRowType.Email);
			this.setFieldValue(
				obj.label,
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Email);
			this.rowEmailMap.set(row, obj);
		}
	}

	addNoteRow(obj: INote): void {
		const row = this.addRow(ClientUserCCardEditRowType.Note);
		if (row) {
			this.addField(
				ClientUserCCardEditFieldName.Note,
				ClientUserCCardEditRowType.Note);
			this.addField(
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Note);
			this.setFieldValue(
				obj.text,
				ClientUserCCardEditFieldName.Note,
				ClientUserCCardEditRowType.Note);
			this.setFieldValue(
				obj.label,
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Note);
			this.rowNoteMap.set(row, obj);
		}
	}

	addPhoneRow(obj: IPhoneNumber): void {
		const row = this.addRow(ClientUserCCardEditRowType.Phone);
		if (row) {
			this.addField(
				ClientUserCCardEditFieldName.Phone,
				ClientUserCCardEditRowType.Phone);
			this.addField(
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Phone);
			this.setFieldValue(
				obj.number,
				ClientUserCCardEditFieldName.Phone,
				ClientUserCCardEditRowType.Phone);
			this.setFieldValue(
				obj.label,
				ClientUserCCardEditFieldName.Label,
				ClientUserCCardEditRowType.Phone);
			this.rowPhoneMap.set(row, obj);
		}
	}

	private adminChipMouseButtonClickEvt(evt: ChipEvt): void {
		this.setIsAdmin(!evt.chip().isSelected());
	}

	protected chipMouseButtonClickEvt(evt: ChipEvt): void {
		const chip = evt.chip();
		if (chip === this.labelChip) {
			this.labelChipMouseButtonClickEvt(evt);
		} else if (chip === this.adminChip) {
			this.adminChipMouseButtonClickEvt(evt);
		}
	}

	clientUserTypeId(): number | null {
		return this.currentClientUserTypeId;
	}

	@bind
	private clientUserTypeMenuEvt(evt: Evt): void {
		if ((evt.type() === Evt.StateChange) && (evt instanceof MenuStateChangeEvt) && evt.closed()) {
			this.destroyClientUserTypeMenu();
		} else if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			this.clientUserTypeSelectEvt(evt);
		}
	}

	private clientUserTypeSelectEvt(evt: ListItemSelectEvt): void {
		const idx = evt.index();
		const item = this.clientUserTypeMenu ?
			this.clientUserTypeMenu.item(idx) :
			null;
		const typeObj = item ?
			this.listItemClientUserTypeMap.get(item) :
			null;
		this.setClientUserType(typeObj ? typeObj.id : null);
	}

	protected defaultLabelText(): string {
		return 'No Client Type';
	}

	destroy(): void {
		this.currentClientUserTypeId = null;
		this.emailAddressFactory = null;
		this.noteFactory = null;
		this.phoneNumberFactory = null;
		this.clientUserTypes.clear();
		this.listItemClientUserTypeMap.clear();
		this.rowEmailMap.clear();
		this.rowNoteMap.clear();
		this.rowPhoneMap.clear();
		if (this.adminChip) {
			this.adminChip.destroy();
		}
		this.adminChip = null;
		this.destroyClientUserTypeMenu();
		super.destroy();
	}

	private destroyClientUserTypeMenu(): void {
		if (this.clientUserTypeMenu) {
			this.clientUserTypeMenu.offEvt(this.clientUserTypeMenuEvt);
			this.clientUserTypeMenu.destroy();
		}
		this.clientUserTypeMenu = null;
	}

	emailAddresses(): list<IEmailAddress> {
		const rv = new list<IEmailAddress>();
		for (const [row, obj] of this.rowEmailMap) {
			const rowIndex = this.rowIndex(row);
			if (rowIndex >= 0) {
				obj.address = this.fieldValue(
					ClientUserCCardEditFieldName.Email,
					ClientUserCCardEditRowType.Email,
					rowIndex);
				obj.label = this.fieldValue(
					ClientUserCCardEditFieldName.Label,
					ClientUserCCardEditRowType.Email,
					rowIndex);
				if (this.isRowEnabled(ClientUserCCardEditRowType.Email, rowIndex)) {
					rv.append(obj);
				}
			} else {
				logger.error('emailAddresses: Invalid index for row.');
			}
		}
		return rv;
	}

	firstName(): string {
		return this.fieldValue(
			ClientUserCCardEditFieldName.FirstName,
			ClientUserCCardEditRowType.Name);
	}

	groupId(): number | null {
		const val = this.fieldValue(
			ClientUserCCardEditFieldName.Group,
			ClientUserCCardEditRowType.Group);
		if (val.length > 0) {
			try {
				const num = Number.parseInt(val);
				if (isNumber(num)) {
					return num;
				}
			} catch {
			}
			logger.error('groupId: Invalid current value "%s"', val);
		}
		return null;
	}

	isAdmin(): boolean {
		return this.admin;
	}

	private labelChipMouseButtonClickEvt(evt: ChipEvt): void {
		const chip = evt.chip();
		const {x, y} = chip.rect();
		this.openClientUserTypeMenu(x, y);
	}

	lastName(): string {
		return this.fieldValue(
			ClientUserCCardEditFieldName.LastName,
			ClientUserCCardEditRowType.Name);
	}

	notes(): list<INote> {
		const rv = new list<INote>();
		for (const [row, obj] of this.rowNoteMap) {
			const rowIndex = this.rowIndex(row);
			if (rowIndex >= 0) {
				obj.text = this.fieldValue(
					ClientUserCCardEditFieldName.Note,
					ClientUserCCardEditRowType.Note,
					rowIndex);
				obj.label = this.fieldValue(
					ClientUserCCardEditFieldName.Label,
					ClientUserCCardEditRowType.Note,
					rowIndex);
			} else {
				logger.error('notes: Invalid index for row.');
			}
			rv.append(obj);
		}
		return rv;
	}

	private openClientUserTypeMenu(x: number, y: number): void {
		this.destroyClientUserTypeMenu();
		this.listItemClientUserTypeMap.clear();
		this.clientUserTypeMenu = new Menu();
		this.clientUserTypeMenu.setStyleProperty('min-width', '320px');
		for (const obj of this.clientUserTypes) {
			let trailingIcon: string = '';
			let trailingElStyles: Array<[string, string]> | undefined = undefined;
			if (obj.id === this.currentClientUserTypeId) {
				trailingIcon = 'check';
				trailingElStyles = [['color', '#2D65FF']];
			}
			const item = this.clientUserTypeMenu.addItem({
				text: obj.name,
				trailingIcon,
				trailingElStyles,
			});
			this.listItemClientUserTypeMap.set(item, obj);
		}
		this.clientUserTypeMenu.anchorToBody(x, y);
		this.clientUserTypeMenu.onEvt(this.clientUserTypeMenuEvt);
		this.clientUserTypeMenu.open();
	}

	phoneNumbers(): list<IPhoneNumber> {
		const rv = new list<IPhoneNumber>();
		for (const [row, obj] of this.rowPhoneMap) {
			const rowIndex = this.rowIndex(row);
			if (rowIndex >= 0) {
				obj.number = this.fieldValue(
					ClientUserCCardEditFieldName.Phone,
					ClientUserCCardEditRowType.Phone,
					rowIndex);
				obj.label = this.fieldValue(
					ClientUserCCardEditFieldName.Label,
					ClientUserCCardEditRowType.Phone,
					rowIndex);
			} else {
				logger.error('phoneAddresses: Invalid index for row.');
			}
			rv.append(obj);
		}
		return rv;
	}

	quickBooksCustomerId(): string {
		return this.fieldValue(
			ClientUserCCardEditFieldName.QBCustomer,
			ClientUserCCardEditRowType.Integration);
	}

	protected rowCtrlRowAddButtonPressEvt(evt: RowCtrlEvt): void {
		switch (evt.rowType()) {
			case ClientUserCCardEditRowType.Email:
				if (this.emailAddressFactory) {
					this.addEmailRow(this.emailAddressFactory());
					return;
				}
				break;
			case ClientUserCCardEditRowType.Note:
				if (this.noteFactory) {
					this.addNoteRow(this.noteFactory());
					return;
				}
				break;
			case ClientUserCCardEditRowType.Phone:
				if (this.phoneNumberFactory) {
					this.addPhoneRow(this.phoneNumberFactory());
					return;
				}
				break;
			default:
				logger.error('rowCtrlRowAddButtonPressEvt: Invalid row type "%s"', evt.rowType());
				return;
		}
		logger.warning('rowCtrlRowAddButtonPressEvt: Request to add row with no factory defined.');
	}

	protected rowCtrlRowRemoveButtonPressEvt(evt: RowCtrlEvt): void {
		this.removeRow(evt.rowType(), evt.rowIndex());
	}

	protected rowCtrlRowRemovedEvt(evt: RowCtrlEvt): void {
		const row = evt.row();
		if (row) {
			if (this.rowEmailMap.has(row)) {
				this.rowEmailMap.delete(row);
			} else if (this.rowNoteMap.has(row)) {
				this.rowNoteMap.delete(row);
			} else if (this.rowPhoneMap.has(row)) {
				this.rowPhoneMap.delete(row);
			} else {
				logger.error('rowCtrlRowRemovedEvt: Event row object not found in object mappings.');
			}
		} else {
			logger.warning('rowCtrlRowRemovedEvt: Event carries no row object.');
		}
	}

	setClientUserType(id?: number | null): void {
		let typeId = isNumber(id) ?
			id :
			null;
		this.currentClientUserTypeId = (typeId === this.currentClientUserTypeId) ?
			null : // Toggle selection
			typeId;
		if (isNumber(this.currentClientUserTypeId)) {
			const idx = this.clientUserTypes
				.findIndex(x => (x.id === this.currentClientUserTypeId));
			if (idx >= 0) {
				this.setLabel(this.clientUserTypes.at(idx).name);
				return;
			}
		}
		this.setLabel();
	}

	setFirstName(firstName: string): void {
		this.setFieldValue(
			firstName,
			ClientUserCCardEditFieldName.FirstName,
			ClientUserCCardEditRowType.Name);
	}

	setGroup(groupId?: number | string | null): void {
		const ctrlAdded = this.hasRowType(ClientUserCCardEditRowType.Group)
			&& this.rowCount(ClientUserCCardEditRowType.Group) > 0;
		if (!ctrlAdded) {
			logger.warning('setGroup: Group type does not exist. (Did you define any group objects?).');
			return;
		}
		const val = isNumber(groupId) ?
			String(groupId) :
			(typeof groupId === 'string') ?
				groupId :
				'';
		this.setFieldValue(
			val,
			ClientUserCCardEditFieldName.Group,
			ClientUserCCardEditRowType.Group,
			0);
	}

	setIsAdmin(isAdmin: boolean): void {
		if (isAdmin === this.admin) {
			return;
		}
		this.admin = isAdmin;
		if (this.adminChip) {
			const leadingIcon = this.admin ?
				'admin_panel_settings' :
				'account_circle';
			const text = this.admin ?
				'Admin Privileges' :
				'Regular Privileges';
			this.adminChip.setSelected(this.admin);
			this.adminChip.setLeadingIconName(leadingIcon);
			this.adminChip.setText(text);
		}
	}

	setLastName(lastName: string): void {
		this.setFieldValue(
			lastName || '',
			ClientUserCCardEditFieldName.LastName,
			ClientUserCCardEditRowType.Name);
	}

	setName(firstName: string, lastName?: string): void {
		this.setFirstName(firstName);
		this.setLastName(lastName || '');
	}

	setNote(note: INote | Iterable<INote>): void {
		const objs: Array<INote> = isIterable(note) ?
			Array.isArray(note) ?
				note :
				Array.from(note) :
			[note];
		for (const obj of objs) {
			this.addNoteRow(obj);
		}
	}

	setOtherEmail(otherEmail: IEmailAddress | Iterable<IEmailAddress>): void {
		const objs: Array<IEmailAddress> = isIterable(otherEmail) ?
			Array.isArray(otherEmail) ?
				otherEmail :
				Array.from(otherEmail) :
			[otherEmail];
		for (const obj of objs) {
			this.addEmailRow(obj);
		}
	}

	setPhoneNumber(phoneNumber: IPhoneNumber | Iterable<IPhoneNumber>): void {
		const objs: Array<IPhoneNumber> = isIterable(phoneNumber) ?
			Array.isArray(phoneNumber) ?
				phoneNumber :
				Array.from(phoneNumber) :
			[phoneNumber];
		for (const obj of objs) {
			this.addPhoneRow(obj);
		}
	}

	setQuickBooksCustomerId(id: string): void {
		this.setFieldValue(
			id,
			ClientUserCCardEditFieldName.QBCustomer,
			ClientUserCCardEditRowType.Integration);
	}
}

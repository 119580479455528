import {Resource, ResourceService} from './resource';

class QuickBooksAccount extends Resource<IQuickBooksAccount> {
	static uri: string = 'accounts';
}

class QuickBooksCustomer extends Resource<IQuickBooksCustomer> {
	static uri: string = 'customers';
}

class QuickBooksItem extends Resource<IQuickBooksItem> {
	static uri: string = 'items';
}

export class QuickBooks extends ResourceService {
	static uri: string = '/api/group/apps/quickbooks';

	account: QuickBooksAccount;
	customer: QuickBooksCustomer;
	item: QuickBooksItem;

	constructor() {
		super();
		this.account = new QuickBooksAccount(this);
		this.customer = new QuickBooksCustomer(this);
		this.item = new QuickBooksItem(this);
	}

	async get(slug: string): Promise<IQuickBooksLinkingResponse> {
		return (await this.GET<IQuickBooksLinkingResponse>(this.url('link'), {slug})).data;
	}

	async link(data: IQuickBooksLinkingRequest): Promise<IQuickBooksLinkingResponse> {
		const url = this.url('link');
		return (await this.POST<IQuickBooksLinkingResponse>(url, data)).data;
	}

	async sync(data: IQuickBooksSyncRequest): Promise<IQuickBooksSyncResponse> {
		const url = this.url('sync');
		return (await this.PUT<IQuickBooksSyncResponse>(url, data)).data;
	}
}

import {MDCSwitch} from '@material/switch';

import {El} from '../el';
import {CheckState} from '../constants';

export class Switch extends El {
	ctrl: MDCSwitch;

	constructor(parent: El | null = null) {
		super('div', parent);
		const switchRoot = El.div({classNames: 'mdc-switch'}, this);
		El.div({classNames: 'mdc-switch__track'}, switchRoot);
		const underlay = El.div({classNames: 'mdc-switch__thumb-underlay'}, switchRoot);
		El.div({classNames: 'mdc-switch__thumb'}, underlay);
		const checkbox = El.checkbox({classNames: 'mdc-switch__native-control'}, underlay);
		checkbox.setAttribute([['role', 'switch'], ['aria-checked', 'false']]);
		const label = El.label(this);
		label.setStyleProperty('padding-left', '8px');
		this.ctrl = new MDCSwitch(<HTMLElement>switchRoot._giveMeTheNodeIKnowWhatImDoing());
	}

	checkState(): CheckState {
		return this.ctrl.checked ?
			CheckState.Checked :
			CheckState.Unchecked;
	}

	private inputEl(): El | null {
		return this.querySelector('.mdc-switch__native-control');
	}

	inputId(): string {
		const el = this.inputEl();
		if (el) {
			return el.id();
		}
		return '';
	}

	isDisabled(): boolean {
		return this.ctrl.disabled;
	}

	private labelEl(): El | null {
		return this.querySelector('label');
	}

	setCheckState(state: CheckState): void {
		this.ctrl.checked = (state === CheckState.Checked);
	}

	setDisabled(disabled: boolean): void {
		this.ctrl.disabled = disabled;
	}

	setInputId(id: string): void {
		let el = this.inputEl();
		if (el) {
			el.setId(id);
		}
		el = this.labelEl();
		if (el) {
			el.setAttribute('for', id);
		}
	}

	setLabelText(text: string): void {
		const el = this.labelEl();
		if (el) {
			el.setText(text);
		}
	}

	setName(name: string): void {
		const el = this.inputEl();
		if (el) {
			el.setName(name);
		}
	}
}

import 'reflect-metadata';
import {init as initSentry} from '@sentry/browser';
import {Integrations} from '@sentry/tracing';

if (window.pbInProduction) {
	initSentry({
		debug: false,
		dsn: 'https://c5589dfea8ff49a1a36b4c4ef698fca2@o54202.ingest.sentry.io/143881',
		integrations: [new Integrations.BrowserTracing()],
		normalizeDepth: 4,
		tracesSampleRate: 0,
	});
}

import {basicConfig} from './logging';

basicConfig();
import './site';

type AppName = 'createproject' | 'producer' | 'projectinvoice' | 'rescheduleproject' | 'wx';
let autoInit: boolean = !window.inProjectDetail;
let someApp: AppName | undefined;
const pathname = window.location.pathname;
const urls = window.pburls;
switch (pathname) {
	case urls.project.list:
		if (window.isProducer) {
			someApp = 'wx';
		}
		autoInit = false;
		break;
	case urls.project.create:
		someApp = 'createproject';
		break;
}
if (urls.project.reschedule) {
	someApp = 'rescheduleproject';
}
if ((pathname === urls.group.catalog.item.create) || urls.group.catalog.item.detail) {
	someApp = 'producer';
} else if (urls.invoice.update) {
	someApp = 'projectinvoice';
}
if (someApp) {
	import('./reactui/index')
		.then(mod =>
			mod.startApp(<AppName>someApp));
} else if (autoInit) {
	import('./autoinit')
		.then(mod =>
			mod.autoInit());
}
import './ui/completer';

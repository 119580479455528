import {ComboBox, ComboBoxItem, ComboBoxOpts} from '../../ui/combobox';
import {El, elOpts} from '../../el';
import {ListItemOpts} from '../../ui/list';
import {SearchFilterType} from '../../constants';

export interface SearchFilterComboBoxItemListItemOpts extends Partial<ListItemOpts> {
	leadingIcon: string;
	text: string;
}

export interface SearchFilterComboBoxItem extends ComboBoxItem {
	listItemOptions: SearchFilterComboBoxItemListItemOpts;
	value: SearchFilterType;
}

export interface SearchFilterComboBoxOpts extends ComboBoxOpts {
	items: Iterable<SearchFilterComboBoxItem>;
}

export class SearchFilterComboBox extends ComboBox {
	static DefaultLeadingIconName: string = 'filter_list';
	static ElementId: string = 'id_pb-project-list-view-search-filter-select';
	static RootSelector: string = `#${SearchFilterComboBox.ElementId}`;

	constructor(opts: Partial<ComboBoxOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ComboBoxOpts>, parent?: El | null);
	constructor(opts?: Partial<ComboBoxOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ComboBoxOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ComboBoxOpts>(a, b, c);
		if (!opts.leadingIcon) {
			opts.leadingIcon = SearchFilterComboBox.DefaultLeadingIconName;
		}
		if (!opts.root) {
			opts.root = document.getElementById(SearchFilterComboBox.ElementId);
		}
		super(opts);
	}
}

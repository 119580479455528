import {Menu, MenuOpts} from '../../ui/menu';
import {El, elOpts} from '../../el';
import {Evt} from '../../evt';
import {bind} from '../../util';
import {ListItemOpts, ListItemSelectEvt} from '../../ui/list';

export class OptionMenuEvt extends Evt {
	private o: IProjectOption;

	constructor(type: number, option: IProjectOption) {
		super(type);
		this.o = option;
	}

	option(): IProjectOption {
		return this.o;
	}
}

export enum ProjectOption {
	CancelProject = 'cancelProject',
	MarkPaid = 'markPaid',
	SendCompleteEmail = 'sendCompleteEmail',
	SendConfirmedEmail = 'sendConfirmedEmail',
	SendCreatedEmail = 'sendCreatedEmail',
	SendInvoiceEmail = 'sendInvoiceEmail',
	SendGalleryEmail = 'sendGalleryEmail',
	SendOnHoldEmail = 'sendOn holdEmail',
}

export class OptionMenu extends Menu {
	private indexOptMap: Map<number, IProjectOption>;

	constructor(opts: Partial<MenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts>, parent?: El | null);
	constructor(opts?: Partial<MenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<MenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<MenuOpts>(a, b, c);
		super(opts);
		this.indexOptMap = new Map<number, IProjectOption>();
		this.initialize();
	}

	destroy(): void {
		this.offEvt(this.evt);
		this.indexOptMap.clear();
		super.destroy();
	}

	@bind
	private evt(evt: Evt): void {
		if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			const opt = this.indexOptMap.get(evt.index());
			if (opt) {
				this.notifyEvt(new OptionMenuEvt(Evt.Select, opt));
			}
		}
	}

	private initialize(): void {
		this.onEvt(this.evt);
	}

	setOptionLists(opts: Array<Array<IProjectOption>>): void {
		this.indexOptMap.clear();
		let idx = 0;
		for (let outer = 0; outer < opts.length; ++outer) {
			const optList = opts[outer];
			for (let inner = 0; inner < optList.length; ++inner) {
				const opt = optList[inner];
				const itemOpts: Partial<ListItemOpts> = {
					attributes: [
						['data-project-option', opt.option],
					],
					text: opt.text,
				};
				if (opt.foreground) {
					itemOpts.styles = [
						['color', opt.foreground],
					];
				}
				this.addItem(itemOpts);
				this.indexOptMap.set(idx, opt);
				++idx;
			}
			if (outer < (opts.length - 1)) {
				this.list && this.list.appendListItemDivider();
			}
		}
	}
}

import {getLogger} from '../../logging';
import {El, elOpts, ElOpts} from '../../el';
import {list} from '../../tools';
import {IconButton, IconButtonClickEvt} from '../iconbutton';
import {bind} from '../../util';
import {Evt, EvtType} from '../../evt';
import {Menu, MenuStateChangeEvt} from '../menu';
import {ListItemOpts, ListItemSelectEvt} from '../list';
import {Chip, ChipOpts, ChipSet, ChipSetOpts} from '../chipset';
import {
	CCardHeader,
	CCardHeaderInner,
	CCardRoot,
	CCardScrollableContainer,
	CCardScrollableContainerInner,
	CCardSection, CCardSectionOpts,
	CCardSectionRow,
	CCardSubtleLabel,
	CCardToolButton,
} from './common';

const logger = getLogger('ui::ccard::detail');

class CCardDetailHeaderInner extends CCardHeaderInner {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__inner');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardDetailHeaderAvatarContainer extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-detail-header__avatar-container',
			...classNames,
		];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface CCardDetailHeaderAvatarOpts extends ElOpts {
	iconName: string;
}

class CCardDetailHeaderAvatar extends El {
	constructor(opts: Partial<CCardDetailHeaderAvatarOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailHeaderAvatarOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailHeaderAvatarOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailHeaderAvatarOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailHeaderAvatarOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('material-icons', 'pb-cc-detail-header__avatar');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'span';
		}
		super(opts);
		if (opts.iconName) {
			this.setIconName(opts.iconName);
		}
	}

	iconName(): string {
		return this.text();
	}

	setIconName(icon?: string | null): void {
		this.setText(icon);
	}
}

interface CCardDetailHeaderAvatarCaptionOpts extends ElOpts {
	text: string;
}

class CCardDetailHeaderAvatarCaption extends El {
	constructor(opts: Partial<CCardDetailHeaderAvatarCaptionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarCaptionOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarCaptionOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailHeaderAvatarCaptionOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailHeaderAvatarCaptionOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailHeaderAvatarCaptionOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailHeaderAvatarCaptionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailHeaderAvatarCaptionOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__avatar-caption');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'span';
		}
		super(opts);
		this.setText(opts.text);
	}
}

class CCardDetailHeaderChip extends Chip {
	constructor(opts: Partial<ChipOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ChipOpts> | null, root?: Element | null);
	constructor(opts: Partial<ChipOpts>, parent?: El | null);
	constructor(opts?: Partial<ChipOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ChipOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ChipOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-chip',
			'pb-cc-detail-header__chip',
			...classNames,
		];
		super(opts);
	}
}

class CCardDetailHeaderChipSet extends ChipSet {
	constructor(opts: Partial<ChipSetOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ChipSetOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipSetOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ChipSetOpts> | null, root?: Element | null);
	constructor(opts: Partial<ChipSetOpts>, parent?: El | null);
	constructor(opts?: Partial<ChipSetOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ChipSetOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ChipSetOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-cc-chip-set',
			'pb-cc-detail-header__chip-set',
			...classNames,
		];
		opts.chipPrototype = CCardDetailHeaderChip;
		super(opts);
	}
}

class CCardDetailHeaderDescribe extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__describe');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardDetailHeaderIntro extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__intro');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardDetailHeaderName extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__name');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'span';
		}
		super(opts);
	}
}

class CCardDetailHeaderNameTitle extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__name-title');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

class CCardDetailHeaderTitle extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__title');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'span';
		}
		super(opts);
	}
}

export enum ToolbarAction {
	Invalid,
	Close,
	Edit,
	Menu,
}

class CCardDetailHeaderToolbar extends El {
	private btnMap: Map<IconButton, ToolbarAction>;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<ElOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-header__toolbar');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.btnMap = new Map<IconButton, ToolbarAction>();
		let btn = new CCardToolButton({
			iconName: 'create',
			parent: this,
			smaller: true,
		});
		btn.onEvt(this.toolButtonEvt);
		this.btnMap.set(btn, ToolbarAction.Edit);
		btn = new CCardToolButton({
			iconName: 'more_vert',
			parent: this,
			smaller: true,
		});
		btn.onEvt(this.toolButtonEvt);
		this.btnMap.set(btn, ToolbarAction.Menu);
		btn = new CCardToolButton({
			iconName: 'close',
			parent: this,
			smaller: true,
		});
		btn.onEvt(this.toolButtonEvt);
		this.btnMap.set(btn, ToolbarAction.Close);
	}

	action(btn: IconButton): ToolbarAction {
		const rv = this.btnMap.get(btn);
		return (rv === undefined) ?
			ToolbarAction.Invalid :
			rv;
	}

	button(action: ToolbarAction): IconButton | null {
		for (const [btn, axn] of this.btnMap) {
			if (axn === action) {
				return btn;
			}
		}
		return null;
	}

	destroy(): void {
		for (const obj of this.btnMap.keys()) {
			obj.destroy();
		}
		this.btnMap.clear();
		super.destroy();
	}

	@bind
	private toolButtonEvt(evt: Evt): void {
		this.notifyEvt(evt);
	}
}

class CCardDetailSection extends CCardSection {
	constructor(opts: Partial<CCardSectionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardSectionOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardSectionOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardSectionOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardSectionOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardSectionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardSectionOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-section');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
	}
}

interface CCardDetailSectionRowOpts extends ElOpts {
	iconName: string;
	text: Partial<CCardDetailSectionItemTextOpts>;
	toolButtonIcon: string;
	toolButtonOn: boolean;
}

class CCardDetailSectionRow extends CCardSectionRow {
	private iconEl: CCardDetailSectionItemIcon | null;
	private textEl: CCardDetailSectionItemText | null;
	private toolBtnEl: CCardToolButton | null;

	constructor(opts: Partial<CCardDetailSectionRowOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionRowOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionRowOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailSectionRowOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailSectionRowOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailSectionRowOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailSectionRowOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailSectionRowOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-section__row');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.iconEl = null;
		this.textEl = null;
		this.toolBtnEl = null;
		this.setIcon(opts.iconName);
		if (opts.text) {
			this.ensureTextEl(opts.text);
		}
		if (opts.toolButtonIcon) {
			this.setToolButtonIcon(opts.toolButtonIcon);
		}
		if (opts.toolButtonOn !== undefined) {
			this.setToolButtonOn(opts.toolButtonOn);
		}
	}

	clear(): void {
		[
			this.iconEl,
			this.textEl,
			this.toolBtnEl,
		].forEach(el => el && el.clear());
	}

	destroy(): void {
		[
			this.iconEl,
			this.textEl,
			this.toolBtnEl,
		].forEach(el => el && el.destroy());
		this.iconEl = null;
		this.textEl = null;
		this.toolBtnEl = null;
		super.destroy();
	}

	private ensureTextEl(opts?: Partial<CCardDetailSectionItemTextOpts>): CCardDetailSectionItemText {
		if (!this.textEl) {
			opts = opts || {};
			this.textEl = new CCardDetailSectionItemText({
				...opts,
				parent: this,
				placementIndex: 1,
			});
		}
		return this.textEl;
	}

	href(): string {
		return this.textEl ?
			this.textEl.href() :
			'';
	}

	icon(): string {
		return this.iconEl ?
			this.iconEl.iconName() :
			'';
	}

	label(): string {
		return this.textEl ?
			this.textEl.label() :
			'';
	}

	sectionIcon(): CCardDetailSectionItemIcon | null {
		return this.iconEl;
	}

	sectionText(): CCardDetailSectionItemText | null {
		return this.textEl;
	}

	setHref(href: string, opts?: Partial<{text: string; target: 'blank'}>): void {
		this.ensureTextEl().setHref(href, opts);
	}

	setIcon(icon?: string | null): void {
		if (!this.iconEl) {
			this.iconEl = new CCardDetailSectionItemIcon({
				parent: this,
				placementIndex: 0,
			});
		}
		this.iconEl.setIconName(icon);
	}

	setLabel(label?: string | null): void {
		this.ensureTextEl().setLabel(label);
	}

	setText(text?: string | null, label?: string | null): void {
		this.ensureTextEl().setText(text, label);
	}

	setToolButtonIcon(icon?: string | null): void {
		if (!icon) {
			if (this.toolBtnEl) {
				this.toolBtnEl.destroy();
			}
			this.toolBtnEl = null;
			return;
		}
		if (!this.toolBtnEl) {
			this.toolBtnEl = new CCardToolButton({
				classNames: ['some-rip', 'pb-icon-button--low-size'],
				parent: this,
				placementIndex: 2,
			});
		}
		this.toolBtnEl.setIconName(icon);
	}

	setToolButtonOn(on: boolean, iconName?: string): void {
		if (iconName) {
			this.setToolButtonIcon(iconName);
		}
		if (this.toolBtnEl) {
			this.toolBtnEl.setOn(on);
		}
	}

	text(): string {
		return this.textEl ?
			this.textEl.text() :
			'';
	}

	toolButton(): CCardToolButton | null {
		return this.toolBtnEl;
	}
}

interface CCardDetailSectionItemIconOpts extends ElOpts {
	iconName: string;
}

class CCardDetailSectionItemIcon extends El {
	constructor(opts: Partial<CCardDetailSectionItemIconOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionItemIconOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionItemIconOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailSectionItemIconOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailSectionItemIconOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailSectionItemIconOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailSectionItemIconOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailSectionItemIconOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('material-icons', 'pb-cc-detail-section__item-icon');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.setIconName(opts.iconName);
	}

	iconName(): string {
		return this.text();
	}

	setIconName(name?: string | null): void {
		this.setText(name);
	}
}

interface CCardDetailSectionItemTextOpts extends ElOpts {
	isAnchor: boolean;
	href: {href: string; opts?: Partial<{text: string; target: 'blank'}>;};
	label: string;
	text: string;
}

class CCardDetailSectionItemText extends El {
	private labelEl: El | null;
	private textEl: El | null;
	private textIsAnchor: boolean;

	constructor(opts: Partial<CCardDetailSectionItemTextOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionItemTextOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailSectionItemTextOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailSectionItemTextOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailSectionItemTextOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailSectionItemTextOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailSectionItemTextOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailSectionItemTextOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		classNames.push('pb-cc-detail-section__item-text');
		opts.classNames = classNames;
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.labelEl = null;
		this.textEl = null;
		this.textIsAnchor = Boolean(opts.isAnchor);
		this.setText(opts.text);
		this.setLabel(opts.label);
		if (opts.href) {
			this.setHref(opts.href.href, opts.href.opts);
		}
	}

	clear(): void {
		if (this.textEl) {
			this.textEl.clear();
			if (this.textIsAnchor) {
				this.textEl.setHref('', {text: ''});
			}
		}
		if (this.labelEl) {
			this.labelEl.clear();
		}
	}

	destroy(): void {
		this.clear();
		if (this.labelEl) {
			this.labelEl.destroy();
		}
		this.labelEl = null;
		if (this.textEl) {
			this.textEl.destroy();
		}
		this.textEl = null;
		super.destroy();
	}

	private ensureTextEl(): El {
		if (!this.textEl) {
			this.textEl = new El({
				parent: this,
				placementIndex: 0,
				tagName: this.textIsAnchor ?
					'a' :
					'span',
			});
		}
		return this.textEl;
	}

	href(): string {
		if (this.textIsAnchor && this.textEl) {
			return this.textEl.href();
		}
		return '';
	}

	label(): string {
		return this.labelEl ?
			this.labelEl.text() :
			'';
	}

	setHref(href: string, opts?: Partial<{text: string; target: 'blank'}>): void {
		if (this.textIsAnchor) {
			this.ensureTextEl().setHref(href, opts);
		}
	}

	setLabel(text?: string | null): void {
		if (!this.labelEl) {
			this.labelEl = new CCardSubtleLabel({
				parent: this,
				placementIndex: 1,
			});
		}
		this.labelEl.setText(text);
	}

	setText(text?: string | null, label?: string | null): void {
		this.ensureTextEl().setText(text);
		this.setLabel(label);
	}

	text(): string {
		return this.textEl ?
			this.textEl.text() :
			'';
	}
}

enum ToolBarMenuItemActionType {
	Delete,
}

export class CCardDetailNotificationChangeEvt extends Evt {
	private e: string;

	constructor(emailAddress: string) {
		super(Evt.Change);
		this.e = emailAddress;
	}

	emailAddress(): string {
		return this.e;
	}
}

interface CCardDetailOpts extends ElOpts {
	avatar: string;
	avatarCaption: string;
}

export class CCardDetail extends CCardRoot {
	static ToolBarMenuItems: Array<{type: ToolBarMenuItemActionType; opts: Partial<ListItemOpts>}> = [
		{
			opts: {
				leadingIcon: 'delete_outline',
				text: 'Delete',
			},
			type: ToolBarMenuItemActionType.Delete,
		},
	];

	private avatarEl: CCardDetailHeaderAvatar | null;
	private avatarCaptionEl: CCardDetailHeaderAvatarCaption | null;
	private chipSet: CCardDetailHeaderChipSet | null;
	private contactSection: CCardDetailSection | null;
	private emailRows: list<{el: CCardDetailSectionRow; emailAddress: string;}>;
	private integrationSections: list<CCardDetailSection>;
	private nameEl: CCardDetailHeaderName | null;
	private noteRows: list<CCardDetailSectionRow>;
	private notesSection: CCardDetailSection | null;
	private phoneRows: list<CCardDetailSectionRow>;
	private scrollableContainerInner: CCardScrollableContainerInner | null;
	private tBar: CCardDetailHeaderToolbar | null;
	private tBarMenu: Menu | null;
	private titleEl: CCardDetailHeaderTitle | null;

	constructor(opts: Partial<CCardDetailOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<CCardDetailOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CCardDetailOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CCardDetailOpts> | null, root?: Element | null);
	constructor(opts: Partial<CCardDetailOpts>, parent?: El | null);
	constructor(opts?: Partial<CCardDetailOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<CCardDetailOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<CCardDetailOpts>(a, b, c);
		super(opts);
		this.tBarMenu = null;
		this.emailRows = new list<{el: CCardDetailSectionRow; emailAddress: string;}>();
		this.integrationSections = new list<CCardDetailSection>();
		this.noteRows = new list<CCardDetailSectionRow>();
		this.phoneRows = new list<CCardDetailSectionRow>();
		const header = new CCardHeader({
			parent: this,
		});
		const headerInner = new CCardDetailHeaderInner({
			parent: header,
		});
		const headerIntro = new CCardDetailHeaderIntro({
			parent: headerInner,
		});
		const avatarContainer = new CCardDetailHeaderAvatarContainer({
			parent: headerIntro,
		});
		this.avatarEl = new CCardDetailHeaderAvatar({
			iconName: opts.avatar || 'account_circle',
			parent: avatarContainer,
		});
		this.avatarCaptionEl = new CCardDetailHeaderAvatarCaption({
			parent: avatarContainer,
			text: opts.avatarCaption,
		});
		const headerDescribe = new CCardDetailHeaderDescribe({
			parent: headerIntro,
		});
		const headerNameTitle = new CCardDetailHeaderNameTitle({
			parent: headerDescribe,
		});
		this.nameEl = new CCardDetailHeaderName({
			parent: headerNameTitle,
		});
		this.titleEl = new CCardDetailHeaderTitle({
			parent: headerNameTitle,
		});
		this.chipSet = new CCardDetailHeaderChipSet({
			parent: headerDescribe,
		});
		this.tBar = new CCardDetailHeaderToolbar({
			parent: headerInner,
		});
		this.tBar.onEvt(this.toolBarEvt);
		const scrollableContainer = new CCardScrollableContainer({
			parent: this,
		});
		this.scrollableContainerInner = new CCardScrollableContainerInner({
			parent: scrollableContainer,
		});
		this.contactSection = new CCardDetailSection({
			header: 'Contact details',
			parent: this.scrollableContainerInner,
		});
		this.notesSection = new CCardDetailSection({
			header: 'Notes',
			parent: this.scrollableContainerInner,
		});
		this.notesSection.hide();
	}

	addEmail(email: string, receiveNotifications: boolean, label?: string): void {
		const row = new CCardDetailSectionRow({
			iconName: this.emailRows.isEmpty() ?
				'mail_outline' :
				undefined,
			parent: this.contactSection,
			placementIndex: this.emailRows.size() + 1, // Number of existing email rows plus 1 to account for the header element child (at position 0)
			text: {
				href: {
					href: `mailto:${email}`,
					opts: {text: email},
				},
				isAnchor: true,
				label,
			},
			toolButtonIcon: receiveNotifications ?
				'notifications_active' :
				'notifications_off',
			toolButtonOn: receiveNotifications,
		});
		const btn = row.toolButton();
		if (btn) {
			btn.onEvt(this.rowToolButtonEvt);
		}
		this.emailRows.append({
			el: row,
			emailAddress: email,
		});
	}

	addIntegration(section: CCardDetailSection): void {
		if (this.scrollableContainerInner) {
			this.scrollableContainerInner.appendChild(section);
		}
	}

	addLabel(text: string, toolTip?: string): void {
		if (this.chipSet) {
			const chip = this.chipSet.addChip({text});
			if (toolTip) {
				chip.setTitle(toolTip);
			}
		}
	}

	addNote(text: string, label?: string): void {
		const row = new CCardDetailSectionRow({
			iconName: this.noteRows.isEmpty() ?
				'notes' :
				undefined,
			parent: this.notesSection,
			text: {
				isAnchor: false,
				label,
				text,
			},
		});
		this.noteRows.append(row);
		if (this.notesSection) {
			this.notesSection.show();
		}
	}

	addPhone(phone: string, label?: string): void {
		const row = new CCardDetailSectionRow({
			iconName: this.phoneRows.isEmpty() ?
				'phone' :
				undefined,
			parent: this.contactSection,
			text: {
				href: {
					href: `tel:${phone}`,
					opts: {text: phone},
				},
				isAnchor: true,
				label,
			},
		});
		this.phoneRows.append(row);
	}

	avatar(): string {
		return this.avatarEl ?
			this.avatarEl.iconName() :
			'';
	}

	avatarCaption(): string {
		return this.avatarCaptionEl ?
			this.avatarCaptionEl.text() :
			'';
	}

	clear(): void {
		this.clearContactDetails();
		this.clearIntegrations();
		this.clearLabels();
		this.setTitle();
		this.setName();
	}

	clearContactDetails(): void {
		for (const obj of this.iterContactDetailRows()) {
			obj.clear();
			obj.destroy();
		}
		this.emailRows.clear();
		this.phoneRows.clear();
	}

	clearIntegrations(): void {
		for (const obj of this.integrationSections) {
			obj.clear();
			obj.destroy();
		}
		this.integrationSections.clear();
	}

	clearLabels(): void {
		if (this.chipSet) {
			this.chipSet.clear();
		}
	}

	destroy(): void {
		this.destroyToolBarMenu();
		[
			this.chipSet,
			this.contactSection,
			this.nameEl,
			this.notesSection,
			this.titleEl,
			this.tBarMenu,
			this.tBar,
			this.scrollableContainerInner,
		].forEach(el => el && el.destroy());
		this.chipSet = null;
		this.contactSection = null;
		this.nameEl = null;
		this.notesSection = null;
		this.titleEl = null;
		this.tBar = null;
		this.tBarMenu = null;
		for (const obj of this.emailRows) {
			obj.el.destroy();
		}
		this.emailRows.clear();
		for (const obj of this.integrationSections) {
			obj.destroy();
		}
		this.integrationSections.clear();
		for (const obj of this.noteRows) {
			obj.destroy();
		}
		this.noteRows.clear();
		for (const obj of this.phoneRows) {
			obj.destroy();
		}
		this.phoneRows.clear();
		super.destroy();
	}

	private destroyToolBarMenu(): void {
		if (this.tBarMenu) {
			this.tBarMenu.offEvt(this.toolBarMenuEvt);
			this.tBarMenu.destroy();
		}
		this.tBarMenu = null;
	}

	private *iterContactDetailRows(): IterableIterator<CCardDetailSectionRow> {
		yield *this.phoneRows;
		for (const obj of this.emailRows) {
			yield obj.el;
		}
	}

	private openToolBarMenu(x: number, y: number): void {
		this.destroyToolBarMenu();
		this.tBarMenu = new Menu({
			classNames: 'pb-cc-detail-header__toolbar-menu',
			items: CCardDetail.ToolBarMenuItems.map(x => x.opts),
		});
		this.tBarMenu.anchorToBody(x, y);
		this.tBarMenu.onEvt(this.toolBarMenuEvt);
		this.tBarMenu.open();
	}

	name(): string {
		return this.nameEl ?
			this.nameEl.text() :
			'';
	}

	@bind
	private rowToolButtonEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			this.rowToolButtonMouseClickEvt(evt);
		}
	}

	private rowToolButtonMouseClickEvt(evt: IconButtonClickEvt): void {
		const btn = evt.iconButton();
		for (const obj of this.emailRows) {
			if (obj.el.toolButton() === btn) {
				this.notifyEvt(new CCardDetailNotificationChangeEvt(
					obj.emailAddress));
				return;
			}
		}
	}

	setAvatar(iconName?: string | null): void {
		if (this.avatarEl) {
			this.avatarEl.setIconName(iconName);
		}
	}

	setAvatarCaption(caption?: string | null): void {
		if (this.avatarCaptionEl) {
			this.avatarCaptionEl.setText(caption);
		}
	}

	setEmailReceivesNotifications(emailAddress: string, receivesNotifications: boolean): void {
		for (const obj of this.emailRows) {
			if (obj.emailAddress === emailAddress) {
				obj.el.setToolButtonOn(receivesNotifications);
				obj.el.setToolButtonIcon(receivesNotifications ?
					'notifications_active' :
					'notifications_off');
			}
		}
	}

	setName(name?: string | null): void {
		if (this.nameEl) {
			this.nameEl.setText(name);
		}
	}

	setTitle(title?: string | null): void {
		if (this.titleEl) {
			this.titleEl.setText(title);
		}
	}

	title(): string {
		return this.titleEl ?
			this.titleEl.text() :
			'';
	}

	toolBar(): CCardDetailHeaderToolbar | null {
		return this.tBar;
	}

	private toolBarButtonEvt(evt: IconButtonClickEvt): void {
		if (!this.tBar) {
			return;
		}
		const axn = this.tBar.action(evt.iconButton());
		if (axn === ToolbarAction.Invalid) {
			logger.warning('ToolBar returned Invalid action type.');
			return;
		}
		switch (axn) {
			case ToolbarAction.Close:
				this.notifyEvt(new Evt(Evt.Close));
				break;
			case ToolbarAction.Edit:
				this.notifyEvt(new Evt(Evt.Edit));
				break;
			case ToolbarAction.Menu:
				this.openToolBarMenu(evt.x(), evt.y());
				break;
		}
	}

	@bind
	private toolBarEvt(evt: Evt): void {
		if ((evt.type() === Evt.MouseButtonClick) && (evt instanceof IconButtonClickEvt)) {
			this.toolBarButtonEvt(evt);
		}
	}

	@bind
	private toolBarMenuEvt(evt: Evt): void {
		if (evt.type() === Evt.StateChange) {
			if ((evt instanceof MenuStateChangeEvt) && evt.closed()) {
				this.destroyToolBarMenu();
			}
		} else if ((evt.type() === Evt.Select) && (evt instanceof ListItemSelectEvt)) {
			this.toolBarMenuSelectEvt(evt);
		}
	}

	private toolBarMenuSelectEvt(evt: ListItemSelectEvt): void {
		const idx = evt.index();
		if ((idx >= 0) && (idx < CCardDetail.ToolBarMenuItems.length)) {
			const item = CCardDetail.ToolBarMenuItems[idx];
			let evtType: EvtType;
			switch (item.type) {
				case ToolBarMenuItemActionType.Delete:
					evtType = EvtType.Delete;
					break;
			}
			this.notifyEvt(new Evt(evtType));
		}
	}
}

interface QuickBooksDetailSectionOpts extends CCardSectionOpts {
	customerId: string;
}

export class QuickBooksDetailSection extends CCardDetailSection {
	private row: CCardDetailSectionRow | null;

	constructor(opts: Partial<QuickBooksDetailSectionOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<QuickBooksDetailSectionOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<QuickBooksDetailSectionOpts> | null, tagName?: TagName);
	constructor(opts: Partial<QuickBooksDetailSectionOpts> | null, root?: Element | null);
	constructor(opts: Partial<QuickBooksDetailSectionOpts>, parent?: El | null);
	constructor(opts?: Partial<QuickBooksDetailSectionOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<QuickBooksDetailSectionOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<QuickBooksDetailSectionOpts>(a, b, c);
		if (!opts.header) {
			opts.header = 'QuickBooks';
		}
		super(opts);
		this.row = null;
		if (opts.customerId) {
			this.setCustomerId(opts.customerId);
		}
	}

	setCustomerId(id?: string | null): void {
		if (!id) {
			if (this.row) {
				this.row.destroy();
			}
			this.row = null;
			return;
		}
		if (!this.row) {
			this.row = new CCardDetailSectionRow({
				iconName: 'exit_to_app',
				parent: this,
				text: {isAnchor: true},
			});
		}
		const url = `https://qbo.intuit.com/app/customerdetail?nameId=${id}`;
		this.row.setHref(url, {text: 'View on QuickBooks', target: 'blank'});
		this.row.setLabel('Customer profile');
	}
}

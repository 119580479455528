export const ADD_ITEM_ADDON_TO_BUGGY = 'ADD_ITEM_ADDON_TO_BUGGY';
export const ADD_ITEM_CHOICE_TO_BUGGY = 'ADD_CHOICE_TO_BUGGY';
export const ADD_ITEM_TO_BUGGY = 'ADD_ITEM_TO_BUGGY';
export const EMPTY_BUGGY = 'EMPTY_BUGGY';
export const ERROR = 'ERROR';
export const RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST = 'RECEIVE_GROUP_PRODUCER_BUSINESS_HOUR_LIST';
export const RECEIVE_GROUP_PRODUCER_CATALOG = 'RECEIVE_GROUP_PRODUCER_CATALOG';
export const RECEIVE_CLIENT_USERS_DATA = 'RECEIVE_CLIENT_USERS_DATA';
export const RECEIVE_CREATED_PROJECT_DATA = 'RECEIVE_CREATED_PROJECT_DATA';
export const RECEIVE_DURATION_DATA = 'RECEIVE_DURATION_DATA';
export const RECEIVE_ITEMS_DATA = 'RECEIVE_ITEMS_DATA';
export const RECEIVE_MARKET_WEATHER_DATA = 'RECEIVE_MARKET_WEATHER_DATA';
export const RECEIVE_GROUP_PRODUCER_MARKET_LIST = 'RECEIVE_GROUP_PRODUCER_MARKET_LIST';
export const RECEIVE_ACCESS_OPTIONS = 'RECEIVE_ACCESS_OPTIONS';
export const RECEIVE_OCCUPANCY_OPTIONS = 'RECEIVE_OCCUPANCY_OPTIONS';
export const RECEIVE_SUMMARY_DATA = 'RECEIVE_SUMMARY_DATA';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const REMOVE_ITEM_ADDON_FROM_BUGGY = 'REMOVE_ITEM_ADDON_FROM_BUGGY';
export const REMOVE_ITEM_CHOICE_FROM_BUGGY = 'REMOVE_CHOICE_FROM_BUGGY';
export const REMOVE_ITEM_FROM_BUGGY = 'REMOVE_ITEM_FROM_BUGGY';
export const RESET_CATALOG = 'RESET_CATALOG';
export const SET_PROJECT_CREATE_STATE = 'SET_PROJECT_CREATE_STATE';

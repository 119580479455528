import {ItemRole} from '../../../constants';

export function staticItem(props?: Partial<IItem>): IItem {
	const obj: IItem = {
		absoluteUrl: '',
		addons: [],
		children: [],
		choices: [],
		color: '',
		description: '',
		duration: null,
		exclusive: false,
		icon: '',
		id: -1,
		isPublic: false,
		items: [],
		limitChoiceCount: false,
		longDescription: '',
		maxChoices: 0,
		name: '',
		parentId: null,
		placement: 0,
		price: null,
		proxyId: null,
		qbitemId: null,
		role: ItemRole.Standalone,
		size: null,
		succeedingDuration: null,
		userExclusions: [],
	};
	return {
		...obj,
		...(props || {}),
	};
}

import React from 'react';

import {itemPriceThing} from '../../../../util/price';
import {
	numberFormat,
	padStart,
	to12Hour,
	toPeriod,
} from '../../../../util';
import {
	DataTable,
	DataTableBody,
	DataTableCell,
	DataTableColumn,
	DataTableContainer,
	DataTableHead,
	DataTableRow,
	DataTableTable,
	Icon,
} from '../../../components';

interface IProps {
	anyTime: boolean;
	city: string;
	street: string;
	summaryData: ISummaryData;
}

type Props = React.PropsWithChildren<IProps>;

export default class Summary extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {
			anyTime,
			city,
			street,
			summaryData,
		} = this.props;
		const {startDatetime, endDatetime} = summaryData;
		return (
			<div className="display--flex align-items--center flex-direction--column">
				<span className="font-weight--bolder font-size--1point2rem">
					{street}, {city}
				</span>
				<Date date={startDatetime.date}/>
				{anyTime ?
					<Anytime/> :
					<Time start={startDatetime.time} end={endDatetime.time}/>}
			</div>
		);
	}
}

function Anytime() {
	return (
		<div className="font-weight--300 font-size--0point96rem">ANYTIME</div>
	);
}

function Date({date}: React.PropsWithChildren<{date: IDate}>) {
	return (
		<span className="font-size--1point2rem padding-top--12">
            {date.weekdayName}, {date.monthName} {date.day}
        </span>
	);
}

function StartTime({time}: React.PropsWithChildren<{time: ITime}>) {
	return (
		<div className="text-align--right padding-right--4">
			<div className="font-weight--300 font-size--0point96rem">START</div>
			<div>{to12Hour(time.hour)}:{padStart(time.minute, 2, '0')} {toPeriod(time.hour)}</div>
		</div>
	);
}

function EndTime({time}: React.PropsWithChildren<{time: ITime}>) {
	return (
		<div className="text-align--left padding-left--4">
			<div className="font-weight--300 font-size--0point96rem">END <small>est.</small></div>
			<div>{to12Hour(time.hour)}:{padStart(time.minute, 2, '0')} {toPeriod(time.hour)}</div>
		</div>
	);
}

function Time({start, end}: React.PropsWithChildren<{start: ITime; end: ITime;}>) {
	return (
		<div className="display--flex padding-top--12">
			<StartTime time={start}/>
			<EndTime time={end}/>
		</div>
	);
}

export function Statement(props: React.PropsWithChildren<{itemChildren: (itemID: number) => ICatalogItem[]; summary: ISummaryData;}>) {
	const {summary: {items, total, altTotal}, itemChildren} = props;
	return (
		<DataTable className="width--100-percent">
			<DataTableContainer>
				<DataTableTable>
					<DataTableHead>
						<DataTableRow headerRow={true}>
							<DataTableColumn>Services</DataTableColumn>
							<DataTableColumn numeric={true}>Quantity</DataTableColumn>
							<DataTableColumn numeric={true}>Price</DataTableColumn>
						</DataTableRow>
					</DataTableHead>
					<DataTableBody>
						{items.map(({item, quantity}, idx) => {
							const children = itemChildren(item.id);
							const [price, altPrice] = itemPriceThing(item, children);
							return (
								<DataTableRow key={idx}>
									<DataTableCell>
										<Icon className="color--faint-blue padding-right--16" middleAlign={true} name={item.icon}/>{item.name}
									</DataTableCell>
									<DataTableCell numeric={true}>{quantity}</DataTableCell>
									<DataTableCell numeric={true}>
										{altPrice ?
											<span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--grayish">{altPrice}</span> :
											null}
										{price || 'TBD'}
									</DataTableCell>
								</DataTableRow>
							);
						})}
						<DataTableRow>
							<DataTableCell/>
							<DataTableCell>Total (excluding tax)</DataTableCell>
							<DataTableCell numeric={true}>
								{altTotal ?
									<span className="text-decoration--line-through padding-right--4 font-size--0point8rem color--grayish">${numberFormat(altTotal)}</span> :
									null}
								<span className="font-weight--bolder">${numberFormat(total)}</span>
							</DataTableCell>
						</DataTableRow>
					</DataTableBody>
				</DataTableTable>
			</DataTableContainer>
		</DataTable>
	);
}

import React, {PropsWithChildren} from 'react';
import {
	MDCListAdapter,
	MDCListFoundation,
	cssClasses as _mdcCssClasses,
} from '@material/list';

import {assert, bind, closestMatchingElement, cssClassName, isNumber} from '../../../util';
import {set} from '../../../tools';
import {Orientation} from '../../../constants';

export {ListItem} from './listitem';
export {ListItemText} from './listitemtext';

export interface IListItemContext {
	blurEvent?: (event: React.FocusEvent, index: number) => any;
	classNames?: () => Map<number, set<string>>;
	clickEvent?: (event: React.MouseEvent, index: number) => any;
	destroyed?: (index: number) => any;
	focusEvent?: (event: React.FocusEvent, index: number) => any;
	initialTabIndex?: (index: number) => number;
	keyDownEvent?: (event: React.KeyboardEvent, index: number) => any;
}

export const ListItemContext = React.createContext<IListItemContext>({});

interface IProps {
	ariaLabel?: string;
	avatarList?: boolean;
	className?: string;
	dense?: boolean;
	iconList?: boolean;
	imageList?: boolean;
	onSelect?: (index: number) => any;
	orientation?: Orientation;
	role?: string;
	selectedIndex?: number;
	singleSelection?: boolean;
	textList?: boolean;
	thumbnailList?: boolean;
	twoLine?: boolean;
	typeAhead?: boolean;
	videoList?: boolean;
	wrapFocus?: boolean;
}

interface IState {
	itemIndexClassNamesMap: Map<number, set<string>>;
}

type Props = PropsWithChildren<IProps>;
type State = IState;

export default class List extends React.Component<Props, State> {
	private _itemTabIndexInitialized: boolean;
	private _mdc: MDCListFoundation | null;
	private _rootRef: React.RefObject<HTMLUListElement>;

	constructor(props: Props) {
		super(props);
		const classNames = [_mdcCssClasses.ROOT];
		if (props.dense) {
			classNames.push('mdc-list--dense');
		}
		if (props.twoLine) {
			classNames.push('mdc-list--two-line');
		}
		this._itemTabIndexInitialized = false;
		this.state = {
			itemIndexClassNamesMap: new Map(),
		};
		this._mdc = null;
		this._rootRef = React.createRef();
	}

	addClassNameToItemAtIndex(index: number, className: string): void {
		this._mdcAddClassForElementIndex(index, className);
	}

	@bind
	private blurEvent(event: React.FocusEvent, index: number): void {
		if (this._mdc) {
			this._mdc.handleFocusOut(event.nativeEvent, index);
		}
	}

	@bind
	private clickEvent(event: React.MouseEvent, index: number): void {
		if (this._mdc) {
			this._mdc.handleClick(index, false);
		}
	}

	componentDidMount(): void {
		const {orientation, selectedIndex, singleSelection, typeAhead, wrapFocus} = this.props;
		this._mdc = new MDCListFoundation(this._mdcAdapter());
		this._mdc.init();
		this._mdc.setSingleSelection(Boolean(singleSelection));
		this._mdc.layout();
		if (isNumber(selectedIndex)) {
			this._mdc.setSelectedIndex(selectedIndex);
		}
		this._mdc.setWrapFocus((wrapFocus === undefined) || wrapFocus);
		this._mdc.setVerticalOrientation((orientation === undefined) || (orientation === Orientation.Vertical));
		this._mdc.setHasTypeahead((typeAhead === undefined) || typeAhead);
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		const {
			orientation: orientationPrev,
			selectedIndex: selectedIndexPrev,
			singleSelection: singleSelectionPrev,
			typeAhead: typeAheadPrev,
			wrapFocus: wrapFocusPrev,
		} = prevProps;
		const {orientation, selectedIndex, singleSelection, typeAhead, wrapFocus} = this.props;
		const mdc = this._mdc;
		if (mdc) {
			if (singleSelection !== singleSelectionPrev) {
				mdc.setSingleSelection(Boolean(singleSelection));
			}
			if (selectedIndex !== selectedIndexPrev) {
				mdc.setSelectedIndex(isNumber(selectedIndex) ? selectedIndex : -1);
			}
			if (wrapFocus !== wrapFocusPrev) {
				mdc.setWrapFocus((wrapFocus === undefined) || wrapFocus);
			}
			if (orientation !== orientationPrev) {
				mdc.setVerticalOrientation((orientation === undefined) || (orientation === Orientation.Vertical));
			}
			if (typeAhead !== typeAheadPrev) {
				mdc.setHasTypeahead((typeAhead === undefined) || typeAhead);
			}
			mdc.layout();
		}
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	count(): number {
		return this._listItemElements().length;
	}

	focus(): void {
		this._rootOrDie().focus();
	}

	@bind
	private focusEvent(event: React.FocusEvent, index: number): void {
		if (this._mdc) {
			this._mdc.handleFocusIn(event.nativeEvent, index);
		}
	}

	focusItemAtIndex(index: number): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.focusNextElement(index - 1);
		}
	}

	indexOfListItemElement(elem: Element): number {
		return this._listItemElements().indexOf(elem);
	}

	itemAtIndex(index: number): Element | null {
		const items = this.itemElements();
		if (index >= 0 && index < items.length) {
			return items[index];
		}
		return null;
	}

	@bind
	itemClassNames(): Map<number, set<string>> {
		return this.state.itemIndexClassNamesMap;
	}

	@bind
	itemDestroyed(index: number): void {
		const {itemIndexClassNamesMap} = this.state;
		itemIndexClassNamesMap.delete(index);
		this.setState({
			itemIndexClassNamesMap: new Map(itemIndexClassNamesMap),
		});
	}

	itemElements(): HTMLElement[] {
		return this._listItemElements();
	}

	@bind
	itemInitialTabIndex(itemIndex: number): number {
		if (!this._itemTabIndexInitialized) {
			const {selectedIndex} = this.props;
			if ((itemIndex === selectedIndex) || (selectedIndex === -1)) {
				this._itemTabIndexInitialized = true;
				return 0;
			}
		}
		return -1;
	}

	@bind
	private keyDownEvent(event: React.KeyboardEvent, index: number): void {
		if (this._mdc) {
			this._mdc.handleKeydown(
				event.nativeEvent,
				index >= 0,
				index);
		}
	}

	removeAttributeFromItemAtIndex(index: number, name: string): void {
		const elem = this._listItemElements()[index];
		if (elem) {
			elem.removeAttribute(name);
		}
	}

	removeClassNameFromItemAtIndex(index: number, className: string): void {
		this._mdcRemoveClassForElementIndex(index, className);
	}

	render(): React.ReactNode {
		const {
			ariaLabel,
			avatarList,
			children,
			className,
			dense,
			iconList,
			imageList,
			role,
			textList,
			thumbnailList,
			twoLine,
			videoList,
		} = this.props;
		const clsName = cssClassName(
			_mdcCssClasses.ROOT,
			className,
			{
				'mdc-list--avatar-list': avatarList,
				'mdc-list--dense': dense,
				'mdc-list--icon-list': iconList,
				'mdc-list--image-list': imageList,
				'mdc-list--textual-list': textList,
				'mdc-list--thumbnail-list': thumbnailList,
				'mdc-list--two-line': twoLine,
				'mdc-list--video-list': videoList,
			});
		const itemCtx: IListItemContext = {
			blurEvent: this.blurEvent,
			classNames: this.itemClassNames,
			clickEvent: this.clickEvent,
			destroyed: this.itemDestroyed,
			focusEvent: this.focusEvent,
			initialTabIndex: this.itemInitialTabIndex,
			keyDownEvent: this.keyDownEvent,
		};
		return (
			<ul aria-label={ariaLabel} className={clsName} ref={this._rootRef} role={role}>
				<ListItemContext.Provider value={itemCtx}>
					{children}
				</ListItemContext.Provider>
			</ul>
		);
	}

	selectedIndex(): number | number[] {
		if (this._mdc) {
			return this._mdc.getSelectedIndex();
		}
		return -1;
	}

	setAttributeForItemAtIndex(index: number, name: string, value: string): void {
		this._mdcSetAttributeForElementIndex(index, name, value);
	}

	setSelectedIndex(index: number | number[]): void {
		if (this._mdc) {
			this._mdc.setSelectedIndex(index);
		}
	}

	setWrapFocus(wrap: boolean): void {
		if (this._mdc) {
			this._mdc.setWrapFocus(wrap);
		}
	}

	typeaheadInProgress(): boolean {
		if (this._mdc) {
			return this._mdc.isTypeaheadInProgress();
		}
		return false;
	}

	typeaheadMatchItem(nextChar: string, startingIndex: number): number {
		if (this._mdc) {
			return this._mdc.typeaheadMatchItem(nextChar, startingIndex);
		}
		return -1;
	}

	@bind
	private _clickEvent(event: React.MouseEvent): void {
		const mdc = this._mdc;
		if (mdc) {
			const el = closestMatchingElement(event.target as Element, `.${_mdcCssClasses.LIST_ITEM_CLASS}`);
			if (el) {
				const idx = this._listItemElements().indexOf(el);
				if (idx >= 0) {
					mdc.handleClick(idx, false);
				}
			}
		}
	}

	private _deleteItemIndexClassName(index: number, className: string): void {
		const {itemIndexClassNamesMap} = this.state;
		let classNames = itemIndexClassNamesMap.get(index);
		if (!classNames) {
			classNames = new set();
			itemIndexClassNamesMap.set(index, classNames);
		}
		classNames.discard(className);
		this.setState({itemIndexClassNamesMap: new Map(itemIndexClassNamesMap)});
	}

	private _listItemElements<E extends Element>(): E[] {
		const root = this._rootOrDie();
		return Array.from(root.querySelectorAll<E>(`.${MDCListFoundation.cssClasses.LIST_ITEM_CLASS}`));
	}

	private _mdcAdapter(): MDCListAdapter {
		return {
			addClassForElementIndex: this._mdcAddClassForElementIndex,
			focusItemAtIndex: this._mdcFocusItemAtIndex,
			getAttributeForElementIndex: this._mdcGetAttributeForElementIndex,
			getFocusedElementIndex: this._mdcGetFocusedElementIndex,
			getListItemCount: this._mdcGetListItemCount,
			getPrimaryTextAtIndex: this._mdcGetPrimaryTextAtIndex,
			hasCheckboxAtIndex: this._mdcHasCheckboxAtIndex,
			hasRadioAtIndex: this._mdcHasRadioAtIndex,
			isCheckboxCheckedAtIndex: this._mdcIsCheckboxCheckedAtIndex,
			isFocusInsideList: this._mdcIsFocusInsideList,
			isRootFocused: this._mdcIsRootFocused,
			listItemAtIndexHasClass: this._mdcListItemAtIndexHasClass,
			notifyAction: this._mdcNotifyAction,
			removeClassForElementIndex: this._mdcRemoveClassForElementIndex,
			setAttributeForElementIndex: this._mdcSetAttributeForElementIndex,
			setCheckedCheckboxOrRadioAtIndex: this._mdcSetCheckedCheckboxOrRadioAtIndex,
			setTabIndexForListItemChildren: this._mdcSetTabIndexForListItemChildren,
		};
	}

	@bind
	private _mdcAddClassForElementIndex(index: number, className: string): void {
		this._setItemIndexClassName(index, className);
	}

	@bind
	private _mdcFocusItemAtIndex(index: number): void {
		const item = this._listItemElements<HTMLElement>()[index];
		if (item) {
			item.focus();
		}
	}

	@bind
	private _mdcGetAttributeForElementIndex(index: number, attr: string): string | null {
		const item = this._listItemElements()[index];
		if (item) {
			return item.getAttribute(attr);
		}
		return null;
	}

	@bind
	private _mdcGetFocusedElementIndex(): number {
		const active = document.activeElement;
		return active ? this._listItemElements().indexOf(active) : -1;
	}

	@bind
	private _mdcGetListItemCount(): number {
		return this._listItemElements().length;
	}

	@bind
	private _mdcGetPrimaryTextAtIndex(index: number): string {
		const parent = this._listItemElements()[index];
		if (parent) {
			return this._primaryText(parent);
		}
		return '';
	}

	@bind
	private _mdcHasCheckboxAtIndex(index: number): boolean {
		const item = this._listItemElements()[index];
		if (item) {
			return Boolean(item.querySelector(MDCListFoundation.strings.CHECKBOX_SELECTOR));
		}
		return false;
	}

	@bind
	private _mdcHasRadioAtIndex(index: number): boolean {
		const item = this._listItemElements()[index];
		if (item) {
			return Boolean(item.querySelector(MDCListFoundation.strings.RADIO_SELECTOR));
		}
		return false;
	}

	@bind
	private _mdcIsCheckboxCheckedAtIndex(index: number): boolean {
		const item = this._listItemElements()[index];
		const child = item.querySelector<HTMLInputElement>(MDCListFoundation.strings.CHECKBOX_SELECTOR);
		if (child) {
			return child.checked;
		}
		return false;
	}

	@bind
	private _mdcIsFocusInsideList(): boolean {
		return this._rootOrDie().contains(document.activeElement);
	}

	@bind
	private _mdcIsRootFocused(): boolean {
		const active = document.activeElement;
		return active ? active === this._rootOrDie() : false;
	}

	@bind
	private _mdcListItemAtIndexHasClass(index: number, className: string): boolean {
		const item = this._listItemElements()[index];
		return item ? item.classList.contains(className) : false;
	}

	@bind
	private _mdcNotifyAction(index: number): void {
		const {onSelect} = this.props;
		if (onSelect) {
			onSelect(index);
		}
	}

	@bind
	private _mdcRemoveClassForElementIndex(index: number, className: string): void {
		this._deleteItemIndexClassName(index, className);
	}

	@bind
	private _mdcSetAttributeForElementIndex(index: number, attribute: string, value: string): void {
		const item = this._listItemElements()[index];
		if (item) {
			item.setAttribute(attribute, value);
		}
	}

	@bind
	private _mdcSetCheckedCheckboxOrRadioAtIndex(index: number, isChecked: boolean): void {
		// noop
	}

	@bind
	private _mdcSetTabIndexForListItemChildren(listItemIndex: number, tabIndexValue: string): void {
		const item = this._listItemElements()[listItemIndex];
		if (item) {
			const children = item.querySelectorAll<HTMLElement>(MDCListFoundation.strings.CHILD_ELEMENTS_TO_TOGGLE_TABINDEX);
			children.forEach(child => child.setAttribute('tabindex', tabIndexValue));
		}
	}

	private _primaryText(parent: Element): string {
		let elem = parent.querySelector(`.${MDCListFoundation.cssClasses.LIST_ITEM_PRIMARY_TEXT_CLASS}`);
		if (elem) {
			return elem.textContent || '';
		}
		elem = parent.querySelector(`.${MDCListFoundation.cssClasses.LIST_ITEM_TEXT_CLASS}`);
		if (elem) {
			return elem.textContent || '';
		}
		return '';
	}

	private _rootOrDie(): HTMLUListElement {
		const elem = this._rootRef.current;
		assert(elem, 'Root ref current is null');
		return elem;
	}

	private _setItemIndexClassName(index: number, className: string): void {
		const {itemIndexClassNamesMap} = this.state;
		let classNames = itemIndexClassNamesMap.get(index);
		if (!classNames) {
			classNames = new set();
			itemIndexClassNamesMap.set(index, classNames);
		}
		classNames.add(className);
		this.setState({itemIndexClassNamesMap: new Map(itemIndexClassNamesMap)});
	}
}

interface ListItemDetailProps extends React.HTMLAttributes<any> {
	trailing?: boolean;
}

export function ListItemDetail({children, className, trailing, ...rest}: ListItemDetailProps) {
	return (
		<div className={cssClassName(trailing ? 'mdc-list-item__meta' : 'mdc-list-item__graphic', className)} {...rest}>
			{children}
		</div>
	);
}

import React from 'react';

import {Slider, Subheading} from '../../components';
import {SECONDS_IN_HOUR} from '../../../constants';
import {bind, padStart, secondsToDuration} from '../../../util';

interface IProps {
	onChange: (totalSeconds: number | null) => any;
	totalSeconds: number;
}

type Props = IProps;
const MAX_TOTAL_SECONDS = SECONDS_IN_HOUR * 9;
const STEP = SECONDS_IN_HOUR / 4;

export default class extends React.Component<Props, {}> {
	@bind
	onChange(value: number): void {
		this.props.onChange(value);
	}

	render(): React.ReactNode {
		const {totalSeconds} = this.props;
		return (
			<div className="display--flex flex-direction--column justify-content--center">
				<Subheading>
					{this.toString()}
				</Subheading>
				<Slider
					max={MAX_TOTAL_SECONDS}
					onInput={this.onChange}
					step={STEP}
					value={totalSeconds}/>
			</div>
		);
	}

	toString(): string {
		const {totalSeconds} = this.props;
		const dur = secondsToDuration(totalSeconds);
		const hr = `Hour${(dur.hours === 1) ? '' : 's'}`;
		return `${dur.hours} ${hr} ${padStart(dur.minutes, 2, ' ')} Minutes`;
	}
}

import {QuickBooks} from './quickbooks';
import {Resource, ResourceService} from './resource';
import {groupProducerService, GroupProducerService} from './groupproducer';

class App {
	quickbooks: QuickBooks;

	constructor() {
		this.quickbooks = new QuickBooks();
	}
}

class ClientGroup extends Resource<IGroup> {
	static uri: string = 'clients/groups';
}

class ClientUserExt extends Resource<IUser> {
	static uri: string = 'clients/users';

	async alternateEmailAddressList(clientUserID: number): Promise<IEmailAddress[]> {
		const url = this.url(clientUserID, 'email-addresses');
		return (await this.svc.GET<IEmailAddress[]>(url)).data;
	}

	async createAlternateEmailAddress(clientUserID: number, data: IEmailAddress): Promise<IEmailAddress> {
		const url = this.url(clientUserID, 'email-addresses');
		return (await this.svc.POST<IEmailAddress>(url, data)).data;
	}

	async createClientUser(data: INewUserData): Promise<IUser> {
		return (await this.svc.POST<IUser>(this.url(), data)).data;
	}

	async createNote(clientUserID: number, data: INote): Promise<INote> {
		const url = this.url(clientUserID, 'notes');
		return (await this.svc.POST<INote>(url, data)).data;
	}

	async createPhoneNumber(clientUserID: number, data: IPhoneNumber): Promise<IPhoneNumber> {
		const url = this.url(clientUserID, 'phone-numbers');
		return (await this.svc.POST<IPhoneNumber>(url, data)).data;
	}

	async deleteAlternateEmailAddress(clientUserID: number, emailAddressID: number): Promise<void> {
		const url = this.url(clientUserID, 'email-addresses', emailAddressID);
		return (await this.svc.DELETE<void>(url)).data;
	}

	async deleteNote(clientUserID: number, noteID: number): Promise<void> {
		const url = this.url(clientUserID, 'notes', noteID);
		return (await this.svc.DELETE<void>(url)).data;
	}

	async deletePhoneNumber(clientUserID: number, phoneNumberID: number): Promise<void> {
		const url = this.url(clientUserID, 'phone-numbers', phoneNumberID);
		return (await this.svc.DELETE<void>(url)).data;
	}

	async phoneNumber(clientUserID: number, phoneNumberID: number): Promise<IPhoneNumber> {
		const url = this.url(clientUserID, 'phone-numbers', phoneNumberID);
		return (await this.svc.GET<IPhoneNumber>(url)).data;
	}

	async phoneNumberList(clientUserID: number): Promise<IPhoneNumber[]> {
		const url = this.url(clientUserID, 'phone-numbers');
		return (await this.svc.GET<IPhoneNumber[]>(url)).data;
	}

	async updateAlternateEmailAddress(clientUserID: number, emailAddressID: number, data: IEmailAddress): Promise<IEmailAddress> {
		const url = this.url(clientUserID, 'email-addresses', emailAddressID);
		return (await this.svc.PUT<IEmailAddress>(url, data)).data;
	}

	async updateNote(clientUserID: number, noteID: number, data: INote): Promise<INote> {
		const url = this.url(clientUserID, 'notes', noteID);
		return (await this.svc.PUT<INote>(url, data)).data;
	}

	async updatePhoneNumber(clientUserID: number, phoneNumberID: number, data: IPhoneNumber): Promise<IPhoneNumber> {
		const url = this.url(clientUserID, 'phone-numbers', phoneNumberID);
		return (await this.svc.PUT<IPhoneNumber>(url, data)).data;
	}
}

class ClientUserType extends Resource<IClientUserType> {
	static uri: string = 'clients/types';
}

class Client {
	group: ClientGroup;
	type: ClientUserType;
	user: ClientUserExt;

	constructor(svc: GroupService) {
		this.group = new ClientGroup(svc);
		this.type = new ClientUserType(svc);
		this.user = new ClientUserExt(svc);
	}
}

class BusinessHour extends Resource<IBusinessHours> {
	static uri: string = 'hours';
}

class Market extends Resource<IMarket> {
	static uri: string = 'markets';
}

class ProjectEmailSubscription extends Resource<IProjectEmailSubscription> {
	static uri: string = 'subscriptions';
}

class TeamMember extends Resource<IUser> {
	static uri: string = 'team-members';

	async emailAddressList(memberID: number): Promise<IEmailAddress[]> {
		const url = this.url(memberID, 'email-addresses');
		return (await this.svc.GET<IEmailAddress[]>(url)).data;
	}

	async phoneNumberList(memberID: number): Promise<IPhoneNumber[]> {
		const url = this.url(memberID, 'phone-numbers');
		return (await this.svc.GET<IPhoneNumber[]>(url)).data;
	}
}

export class GroupService extends ResourceService {
	static uri: string = '/api/group';

	app: App;
	client: Client;
	hours: BusinessHour;
	market: Market;
	producer: GroupProducerService;
	subscription: ProjectEmailSubscription;
	teamMember: TeamMember;

	constructor() {
		super();
		this.app = new App();
		this.client = new Client(this);
		this.hours = new BusinessHour(this);
		this.market = new Market(this);
		this.producer = groupProducerService;
		this.subscription = new ProjectEmailSubscription(this);
		this.teamMember = new TeamMember(this);
	}
}

export const groupService: GroupService = new GroupService();

import {El, elOpts, ElOpts} from '../../el';
import {AbstractItemModel, ItemModelDataChangeEvt, ItemModelEvtType, ItemModelHeaderDataChangeEvt, ItemModelSectionAddRemoveEvt, ModelIndex} from '../../itemmodel';
import {Evt} from '../../evt';
import {bind, isNumber, setFlag} from '../../util';
import {ItemDataRole, MetaType, Orientation, SortOrder} from '../../constants';
import {list, Point} from '../../tools';
import {TableCellMouseEvt, TableEl, TableElMoveEvt, TableElType} from './el';
import {TableElDelegate, TableElStyleOption, textForRole, ViewItemFeature} from './delegate';
import {MDCDataTable} from './ctrl';
import {DataTableEvt, HeaderSectionMoveEvt, HeaderSortIndicatorChangeEvt} from './evt';

export interface IDataTableElOpts extends ElOpts {
	ensureSettingsBtn: boolean;
	model: AbstractItemModel;
}

export class DataTableEl extends El {
	private cells: list<TableEl | null>;
	private checkableRows: boolean;
	private clickableColumns: boolean;
	private columnDelegates: Map<number, typeof TableElDelegate>;
	private columnElClsMap: Map<number, typeof TableEl>;
	protected ctrl: MDCDataTable | null;
	private hdr: TableEl | null;
	private hdrCells: list<TableEl | null>;
	private mdl: AbstractItemModel;
	private movableColumns: boolean;
	private movableRows: boolean;
	private rows: list<TableEl | null>;
	private sortOrder: SortOrder;
	private sortSection: number;
	private sortVisible: boolean;

	constructor(opts: Partial<IDataTableElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<IDataTableElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<IDataTableElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<IDataTableElOpts> | null, root?: Element | null);
	constructor(opts: Partial<IDataTableElOpts>, parent?: El | null);
	constructor(opts?: Partial<IDataTableElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<IDataTableElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts<IDataTableElOpts>(a, b, c);
		if (!(opts.root || opts.tagName)) {
			opts.root = document.querySelector('.mdc-data-table');
			if (!opts.root) {
				opts.tagName = 'div';
			}
		}
		super(opts);
		this.addClass('mdc-data-table', 'pb-data-table');
		let cont = this.querySelector('.mdc-data-table__table-container');
		if (!cont) {
			cont = new El({
				classNames: 'mdc-data-table__table-container',
				parent: this,
				tagName: 'div',
			});
		}
		let tbl = cont.querySelector('.mdc-data-table__table');
		if (!tbl) {
			tbl = new El({
				classNames: 'mdc-data-table__table',
				parent: cont,
				tagName: 'table',
			});
		}
		let thead = tbl.querySelector('thead');
		if (!thead) {
			thead = new El({
				parent: tbl,
				tagName: 'thead',
			});
		}
		const tr = thead.querySelector('.mdc-data-table__header-row');
		if (!tr) {
			new El({
				classNames: 'mdc-data-table__header-row',
				parent: thead,
				tagName: 'tr',
			});
		}
		const tbody = tbl.querySelector('.mdc-data-table__content');
		if (!tbody) {
			new El({
				parent: tbl,
				tagName: 'tbody', classNames: 'mdc-data-table__content',
			});
		}
		new ProgressIndicator({
			parent: this,
		});
		if (opts.ensureSettingsBtn) {
			let btn = cont.querySelector('.pb-table-column-select-menu-btn');
			if (!btn) {
				btn = new El({
					attributes: [
						['type', 'button'],
					],
					classNames: [
						'mdc-icon-button',
						'material-icons',
						'pb-icon-button',
						'pb-icon-button--size-20',
						'pb-table-column-select-menu-btn',
					],
					parent: cont,
					placementIndex: 0,
					tagName: 'button',
				});
				btn.setText('settings');
			}
		}
		this.cells = new list<TableEl | null>();
		this.checkableRows = false;
		this.clickableColumns = true;
		this.columnDelegates = new Map<number, typeof TableElDelegate>();
		this.columnElClsMap = new Map<number, typeof TableEl>();
		this.ctrl = null;
		this.hdr = null;
		this.hdrCells = new list<TableEl | null>();
		this.mdl = AbstractItemModel.staticEmptyModel();
		this.movableColumns = false;
		this.movableRows = false;
		this.rows = new list<TableEl | null>();
		this.sortOrder = -1;
		this.sortSection = -1;
		this.sortVisible = false;
		this.init(opts.model);
	}

	private addCell(row: number, column: number): TableEl {
		let cls: (typeof TableEl) | null = this.columnElClsMap.get(column) || null;
		if (!cls) {
			cls = TableEl;
		}
		const el = new cls({type: TableElType.TableCell});
		this.setCell(row, column, el);
		return el;
	}

	private addHeaderCell(column: number): TableEl {
		const el = new TableEl({
			draggable: this.movableColumns,
			selectable: this.checkableRows,
			type: TableElType.HeaderCell,
		});
		this.setHeaderCell(column, el);
		return el;
	}

	private addRow(row: number): TableEl {
		const el = new TableEl({
			draggable: this.movableRows,
			selectable: this.checkableRows,
			type: TableElType.TableRow,
		});
		this.setRow(row, el);
		return el;
	}

	private body(): El | null {
		return this.querySelector('tbody');
	}

	private cell(row: number, column: number): TableEl | null {
		if ((row < this.rows.size()) && (column < this.mdl.columnCount())) {
			return this.cells.at(this.cellIndex(row, column));
		}
		return null;
	}

	private cellClickEvt(evt: TableCellMouseEvt): void {
		const pos = evt.pos();
		const column = evt.column();
		const col = this.checkableRows ?
			column - 1 :
			column;
		const index = this.mdl.index(evt.row(), col);
		if (index.isValid()) {
			this.notifyEvt(new DataTableEvt(
				DataTableEvt.ItemClick,
				{clientPos: pos, index}));
		}
	}

	@bind
	private cellEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick && (evt instanceof TableCellMouseEvt)) {
			this.cellClickEvt(evt);
		}
	}

	private cellIndex(row: number, column: number): number {
		return (row * this.mdl.columnCount()) + column;
	}

	clear(): void {
		this.clearRows();
		this.clearHeader();
		this.clearCells();
	}

	clearContents(): void {
		this.clearCells();
	}

	private clearCells(): void {
		for (let i = 0; i < this.cells.size(); ++i) {
			const el = this.cells.at(i);
			if (el) {
				this.cells.replace(i, null);
				el.destroy();
			}
		}
	}

	private clearHeader(): void {
		for (let i = 0; i < this.hdrCells.size(); ++i) {
			const el = this.hdrCells.at(i);
			if (el) {
				this.hdrCells.replace(i, null);
				el.destroy();
			}
		}
		if (this.hdr) {
			this.hdr.clear();
		}
	}

	private clearRows(): void {
		for (let i = 0; i < this.rows.size(); ++i) {
			const el = this.rows.at(i);
			if (el) {
				this.rows.replace(i, null);
				el.destroy();
			}
		}
	}

	private columnCount(): number {
		return (this.mdl === AbstractItemModel.staticEmptyModel()) ?
			0 :
			this.mdl.columnCount();
	}

	private deselectRows(): void {
		if (!this.checkableRows) {
			return;
		}
		if (this.ctrl) {
			this.ctrl.setSelectedRowIds([]);
		} else {
			if (this.hdr) {
				this.hdr.setChecked(false);
			}
			for (const obj of this.rows) {
				if (obj) {
					obj.setChecked(false);
				}
			}
		}
		this.notifyEvt(new DataTableEvt(
			DataTableEvt.SectionSelectionChange,
			{orientation: Orientation.Vertical}));
	}

	destroy(): void {
		this.destroyCtrl();
		this.clear();
		this.setModel(null);
		this.columnDelegates.clear();
		this.columnElClsMap.clear();
		super.destroy();
	}

	private destroyCtrl(): void {
		const root = this.element();
		if (root) {
			root.addEventListener('MDCDataTable:rowSelectionChanged', this.event);
			root.addEventListener('MDCDataTable:selectedAll', this.event);
			root.addEventListener('MDCDataTable:sorted', this.event);
			root.addEventListener('MDCDataTable:unselectedAll', this.event);
		}
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
	}

	private drawCell(option: TableElStyleOption, index: ModelIndex): void {
		const {row, column} = index;
		option.el = this.cell(row, column) || this.addCell(row, column);
		const delegateColumn = this.checkableRows ? index.column + 1 : index.column;
		const delegateForColumn = this.columnDelegates.get(delegateColumn);
		const delegate = delegateForColumn ?
			new delegateForColumn() :
			new TableElDelegate();
		delegate.paint(option, index);
	}

	private drawHeaderCell(opt: TableElStyleOption, column: number): void {
		opt.el = this.headerCell(column) || this.addHeaderCell(column);
		let value = this.mdl.headerData(column, Orientation.Horizontal, ItemDataRole.TextAlignmentRole);
		if (value.isValid() && !value.isNull()) {
			opt.displayAlignment = value.toNumber();
		}
		value = this.mdl.headerData(column, Orientation.Horizontal, ItemDataRole.CheckStateRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasCheckIndicator;
			opt.checkState = value.toNumber();
		}
		value = this.mdl.headerData(column, Orientation.Horizontal, ItemDataRole.DisplayRole);
		if (value.isValid() && !value.isNull()) {
			opt.features |= ViewItemFeature.HasDisplay;
			opt.text = textForRole(ItemDataRole.DisplayRole, value);
		}
		opt.features = setFlag(opt.features, ViewItemFeature.HasSortIndicator, this.sortVisible);
		opt.el.paint(opt);
	}

	@bind
	private event(event: Event): void {
		switch (event.type) {
			case 'MDCDataTable:rowSelectionChanged':
			case 'MDCDataTable:selectedAll':
			case 'MDCDataTable:unselectedAll':
				this.notifyEvt(new DataTableEvt(
					DataTableEvt.SectionSelectionChange,
					{orientation: Orientation.Vertical}));
				break;
		}
	}

	private flipSortIndicator(section: number): void {
		let sortOrder: SortOrder;
		if (this.sortSection === section) {
			sortOrder = (this.sortOrder === SortOrder.DescendingOrder) ?
				SortOrder.AscendingOrder :
				SortOrder.DescendingOrder;
		} else {
			const value = this.mdl.headerData(
				section,
				Orientation.Horizontal,
				ItemDataRole.InitialSortOrderRole);
			if (value.canConvert(MetaType.Number)) {
				sortOrder = <SortOrder>value.toNumber();
			} else {
				sortOrder = SortOrder.AscendingOrder;
			}
		}
		this.setSortIndicator(section, sortOrder);
	}

	private headerCell(column: number): TableEl | null {
		if ((column >= 0) && (column < this.hdrCells.size())) {
			return this.hdrCells.at(column);
		}
		return null;
	}

	private headerCellMouseEvt(evt: TableCellMouseEvt): void {
		if (!this.clickableColumns) {
			return;
		}
		const column = this.checkableRows ?
			evt.column() - 1 :
			evt.column();
		if (column < 0) {
			return;
		}
		if (evt.type() === Evt.MouseButtonClick) {
			this.flipSortIndicator(column);
		}
		const newEvt = new TableCellMouseEvt(
			evt.type(),
			evt.pos(),
			evt.button(),
			evt.buttons(),
			evt.row(),
			column,
			evt.modifiers());
		newEvt.setAccepted(evt.isAccepted());
		this.notifyEvt(newEvt);
		evt.setAccepted(newEvt.isAccepted());
	}

	@bind
	private headerCellEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Move: {
				if (evt instanceof TableElMoveEvt) {
					this.headerCellMoveEvt(evt);
				}
				break;
			}
			case Evt.MouseButtonClick:
			case Evt.ContextMenu: {
				if (evt instanceof TableCellMouseEvt) {
					this.headerCellMouseEvt(evt);
				}
				break;
			}
		}
	}

	private headerCellMoveEvt(evt: TableElMoveEvt): void {
		const pos = evt.clientPos();
		const target = evt.target();
		// NB: Since `tableHeaderCells[x]` may be NULL, is the
		//     following a safe operation in order to discern accurate
		//     column index?
		//
		// NB: Does having row selection enabled alter the index
		//     (perceived or otherwise) of these columns? When row
		//     selection is enabled, the first header cell is the
		//     row's checkbox; the following header cell is the actual
		//     first column.
		const srcIdx = this.hdrCells.indexOf(evt.source());
		const droppedIdx = this.hdrCells.indexOf(target);
		if ((srcIdx >= 0) && (droppedIdx >= 0) && (srcIdx !== droppedIdx)) {
			const toSection = dndToIndex(Orientation.Horizontal, srcIdx, droppedIdx, target.rect(), pos);
			if (isNumber(toSection)) {
				const fromSection = srcIdx;
				if (toSection !== fromSection) {
					this.notifyEvt(new HeaderSectionMoveEvt(fromSection, toSection));
				}
			}
		}
	}

	hideProgress(): void {
		this.setProgressVisible(false);
	}

	private init(model?: AbstractItemModel | null): void {
		this.hdr = new TableEl({
			selectable: this.checkableRows,
			root: this.querySelector(`tr${TableEl.headerRowRootSelector}`),
			type: TableElType.HeaderRow,
		});
		if (model) {
			this.setModel(model);
		}
		this.initCtrl();
	}

	private initCtrl(): void {
		if (!this.ctrl) {
			const root = this.element();
			if (root) {
				root.addEventListener('MDCDataTable:rowSelectionChanged', this.event);
				root.addEventListener('MDCDataTable:selectedAll', this.event);
				root.addEventListener('MDCDataTable:sorted', this.event);
				root.addEventListener('MDCDataTable:unselectedAll', this.event);
				this.ctrl = new MDCDataTable(root);
				this.ctrl.initialize();
			}
		}
	}

	private insertColumns(column: number, count: number = 1): boolean {
		if ((count < 1) || (column < 0) || (column > this.hdrCells.size())) {
			return false;
		}
		const rc = this.rows.size();
		const cc = this.hdrCells.size();
		this.hdrCells.insert(column, count, null);
		if (cc === 0) {
			this.cells.insert(0, rc * count, null);
		} else {
			for (let row = 0; row < rc; ++row) {
				this.cells.insert(this.cellIndex(row, column), count, null);
			}
		}
		return true;
	}

	private insertRows(row: number, count: number = 1): boolean {
		if ((count < 1) || (row < 0) || (row > this.rows.size())) {
			return false;
		}
		const rc = this.rows.size();
		const cc = this.mdl.columnCount();
		this.rows.insert(row, count, null);
		if (rc === 0) {
			this.cells.insert(0, cc * count, null);
		} else {
			this.cells.insert(this.cellIndex(row, 0), cc * count, null);
		}
		return true;
	}

	isColumnsClickable(): boolean {
		return this.clickableColumns;
	}

	isColumnsMovable(): boolean {
		return this.movableColumns;
	}

	isRowChecked(row: number): boolean {
		if (this.checkableRows) {
			if ((row >= 0) && (row < this.rows.size())) {
				const obj = this.rows.at(row);
				if (obj) {
					return obj.isChecked();
				}
			}
		}
		return false;
	}

	isRowsCheckable(): boolean {
		return this.checkableRows;
	}

	isRowsMovable(): boolean {
		return this.movableRows;
	}

	private modelColumnsInsertedEvt(): void {
		this.setColumnCount(this.columnCount());
	}

	private modelColumnsRemovedEvt(): void {
		this.setColumnCount(this.columnCount());
	}

	private modelDataChangeEvt(evt: ItemModelDataChangeEvt): void {
		const option = new TableElStyleOption();
		this.drawCell(option, evt.topLeft());
	}

	@bind
	private modelEvt(evt: Evt): void {
		switch (evt.type()) {
			case ItemModelEvtType.DataChanged:
				if (evt instanceof ItemModelDataChangeEvt) {
					this.modelDataChangeEvt(evt);
				}
				break;
			case ItemModelEvtType.RowsInserted:
				if (evt instanceof ItemModelSectionAddRemoveEvt) {
					this.modelRowsInsertedEvt();
				}
				break;
			case ItemModelEvtType.ColumnsInserted:
				if (evt instanceof ItemModelSectionAddRemoveEvt) {
					this.modelColumnsInsertedEvt();
				}
				break;
			case ItemModelEvtType.HeaderDataChanged: {
				if (evt instanceof ItemModelHeaderDataChangeEvt) {
					this.modelHeaderDataChangeEvt(evt);
				}
				break;
			}
			case ItemModelEvtType.RowsRemoved:
				this.modelRowsRemovedEvt();
				break;
			case ItemModelEvtType.ColumnsRemoved:
				this.modelColumnsRemovedEvt();
				break;
			case ItemModelEvtType.LayoutChanged:
				this.modelLayoutChangedEvt();
				break;
			case ItemModelEvtType.ModelReset:
				this.modelResetEvt();
				break;
		}
	}

	private modelHeaderDataChangeEvt(evt: ItemModelHeaderDataChangeEvt): void {
		const logicalFirst = evt.first();
		const logicalLast = evt.last();
		for (let column = logicalFirst; column <= logicalLast; ++column) {
			this.drawHeaderCell(new TableElStyleOption(), column);
		}
	}

	private modelLayoutChangedEvt(): void {
		this.deselectRows();
		this.clearCells();
		for (let row = 0; row < this.rowCount(); ++row) {
			for (let column = 0; column < this.columnCount(); ++column) {
				const index = this.mdl.index(row, column);
				this.drawCell(new TableElStyleOption(), index);
			}
		}
	}

	private modelResetEvt(): void {
		this.clearContents();
	}

	private modelRowsInsertedEvt(): void {
		this.setRowCount(this.rowCount());
	}

	private modelRowsRemovedEvt(): void {
		this.setRowCount(this.rowCount());
	}

	private removeColumns(column: number, count: number = 1): boolean {
		if ((count < 1) || (column < 0) || ((column + count) > this.hdrCells.size())) {
			return false;
		}
		for (let row = this.rowCount() - 1; row >= 0; --row) {
			const i = this.cellIndex(row, column);
			for (let k = i; k < (i + count); ++k) {
				const oldCell = this.cells.at(k);
				if (oldCell) {
					oldCell.destroy();
				}
			}
			this.cells.remove(i, count);
		}
		for (let i = column; i < (column + count); ++i) {
			const oldItem = this.hdrCells.at(i);
			if (oldItem) {
				oldItem.destroy();
				this.hdrCells.replace(i, null);
			}
		}
		this.hdrCells.remove(column, count);
		return true;
	}

	private removeRows(row: number, count: number = 1): boolean {
		if ((count < 1) || (row < 0) || ((row + count) > this.rows.size())) {
			return false;
		}
		const i = this.cellIndex(row, 0);
		const n = count * this.columnCount();
		let oldItem: El | null = null;
		for (let k = i; k < (n + i); ++k) {
			oldItem = this.cells.at(k);
			if (oldItem) {
				this.cells.replace(k, null);
				oldItem.destroy();
			}
		}
		this.cells.remove(Math.max(i, 0), n);
		for (let k = row; k < (row + count); ++k) {
			oldItem = this.rows.at(k);
			if (oldItem) {
				oldItem.destroy();
				this.rows.replace(k, null);
			}
		}
		this.rows.remove(row, count);
		return true;
	}

	private rowCount(): number {
		return (this.mdl === AbstractItemModel.staticEmptyModel()) ?
			0 :
			this.mdl.rowCount();
	}

	@bind
	private rowEvt(evt: Evt): void {
		if ((evt.type() === Evt.Move) && (evt instanceof TableElMoveEvt)) {
			this.rowMoveEvt(evt);
		}
	}

	private rowMoveEvt(evt: TableElMoveEvt): void {
		const pos = evt.clientPos();
		const target = evt.target();
		const srcIdx = this.rows.indexOf(evt.source());
		const droppedIdx = this.rows.indexOf(target);
		if ((srcIdx >= 0) && (droppedIdx >= 0) && (srcIdx !== droppedIdx)) {
			const toSection = dndToIndex(Orientation.Vertical, srcIdx, droppedIdx, target.rect(), pos);
			if (isNumber(toSection)) {
				const fromSection = srcIdx;
				if (toSection !== fromSection) {
					this.notifyEvt(new DataTableEvt(
						DataTableEvt.SectionMove,
						{
							move: {fromSection, toSection},
							orientation: Orientation.Vertical,
						}));
				}
			}
		}
	}

	private setCell(row: number, column: number, el: TableEl | null): void {
		const i = this.cellIndex(row, column);
		if ((i < 0) || (i >= this.cells.size())) {
			return;
		}
		const oldEl = this.cells.at(i);
		if (el === oldEl) {
			return;
		}
		if (oldEl) {
			this.cells.replace(i, null);
			oldEl.destroy();
		}
		if (el) {
			el.onEvt(this.cellEvt);
			let rowItem = this.rows.at(row);
			if (!rowItem) {
				rowItem = this.addRow(row);
			}
			rowItem.insertChild(column, el);
		}
		this.cells.replace(i, el);
	}

	private setColumnCount(count: number): boolean {
		const curr = this.hdrCells.size();
		if ((count < 0) || (count === curr)) {
			return false;
		}
		if (curr < count) {
			return this.insertColumns(Math.max(curr, 0), count - curr);
		} else {
			return this.removeColumns(Math.max(count, 0), curr - count);
		}
	}

	setColumnElCls(column: number, cls: typeof TableEl | null): void {
		if (cls) {
			this.columnElClsMap.set(column, cls);
		} else {
			this.columnElClsMap.delete(column);
		}
	}

	setColumnsClickable(enable: boolean): void {
		this.clickableColumns = enable;
	}

	setColumnsMovable(movable: boolean): void {
		if (movable === this.movableColumns) {
			return;
		}
		for (let i = 0; i < this.hdrCells.size(); ++i) {
			if ((i === 0) && this.checkableRows) {
				continue;
			}
			const obj = this.hdrCells.at(i);
			if (obj) {
				obj.setDraggable(movable);
			}
		}
		this.movableColumns = movable;
	}

	private setHeaderCell(column: number, item: TableEl | null): void {
		if (item) {
			if ((column < 0) || (column >= this.hdrCells.size())) {
				return;
			}
			const oldItem = this.hdrCells.at(column);
			if (item === oldItem) {
				return;
			}
			if (oldItem) {
				oldItem.offEvt(this.headerCellEvt);
				oldItem.destroy();
				this.hdrCells.replace(column, null);
			}
			if (this.hdr) {
				this.hdr.insertChild(column, item);
			}
			this.hdrCells.replace(column, item);
			item.onEvt(this.headerCellEvt);
		} else {
			const item = this.takeHeaderCell(column);
			if (item) {
				item.destroy();
			}
		}
	}

	setItemDelegateForColumn(column: number, delegate: typeof TableElDelegate | null): void {
		if (delegate) {
			this.columnDelegates.set(column, delegate);
		} else {
			this.columnDelegates.delete(column);
		}
	}

	setModel(model: AbstractItemModel | null): void {
		if (model === this.mdl) {
			return;
		}
		if (this.mdl !== AbstractItemModel.staticEmptyModel()) {
			this.mdl.offEvt(this.modelEvt);
		}
		this.mdl = model ?
			model :
			AbstractItemModel.staticEmptyModel();
		if (this.mdl !== AbstractItemModel.staticEmptyModel()) {
			this.mdl.onEvt(this.modelEvt);
		}
	}

	setProgressVisible(visible: boolean): void {
		if (this.ctrl) {
			if (visible) {
				this.ctrl.showProgress();
			} else {
				this.ctrl.hideProgress();
			}
		}
	}

	private setRow(row: number, el: TableEl | null): void {
		if (el) {
			if ((row < 0) || (row >= this.rows.size())) {
				return;
			}
			const oldEl = this.rows.at(row);
			if (el === oldEl) {
				return;
			}
			if (oldEl) {
				oldEl.offEvt(this.rowEvt);
				this.rows.replace(row, null);
				oldEl.destroy();
			}
			if (el) {
				const body = this.body();
				if (body) {
					body.insertChild(row, el);
				}
				el.onEvt(this.rowEvt);
			}
			this.rows.replace(row, el);
		} else {
			el = this.takeRow(row);
			if (el) {
				el.destroy();
			}
		}
	}

	private setRowCount(count: number): boolean {
		const curr = this.rows.size();
		if ((count < 0) || (count === curr)) {
			return false;
		}
		if (curr < count) {
			return this.insertRows(Math.max(curr, 0), count - curr);
		} else {
			return this.removeRows(Math.max(count, 0), curr - count);
		}
	}

	setRowsCheckable(checkable: boolean): void {
		if (checkable === this.checkableRows) {
			return;
		}
		this.destroyCtrl();
		if (this.hdr) {
			this.hdr.setSelectionEnabled(checkable);
		}
		for (const item of this.rows) {
			if (item) {
				item.setSelectionEnabled(checkable);
			}
		}
		this.initCtrl();
		this.checkableRows = checkable;
	}

	setRowsMovable(movable: boolean): void {
		if (movable === this.movableRows) {
			return;
		}
		for (const obj of this.rows) {
			if (obj) {
				obj.setDraggable(movable);
			}
		}
		this.movableRows = movable;
	}

	setSortIndicator(column: number, order: SortOrder): void {
		if (this.ctrl && (column >= 0) && (order >= 0)) {
			this.ctrl.doSort(
				this.checkableRows ?
					column + 1 :
					column,
				order);
		}
		if ((column === this.sortSection) && (order === this.sortOrder)) {
			return;
		}
		this.sortOrder = order;
		this.sortSection = column;
		this.notifyEvt(new HeaderSortIndicatorChangeEvt(
			this.sortSection,
			this.sortOrder));
	}

	setSortIndicatorShown(show: boolean): void {
		this.sortVisible = show;
		if (this.ctrl) {
			if (this.sortVisible) {
				if ((this.sortSection >= 0) && (this.sortOrder >= 0)) {
					this.ctrl.doSort(this.sortSection, this.sortOrder);
				}
			} else {
				this.ctrl.doHideIndicator();
			}
		}
	}

	showProgress(): void {
		this.setProgressVisible(true);
	}

	sortIndicatorOrder(): SortOrder {
		return this.sortOrder;
	}

	sortIndicatorSection(): number {
		return this.sortSection;
	}

	private takeHeaderCell(column: number): TableEl | null {
		if ((column < 0) || (column >= this.hdrCells.size())) {
			return null;
		}
		const item = this.hdrCells.at(column);
		if (item) {
			this.hdrCells.replace(column, null);
		}
		return item;
	}

	private takeRow(row: number): TableEl | null {
		if ((row < 0) || (row >= this.rows.size())) {
			return null;
		}
		const item = this.rows.at(row);
		if (item) {
			this.rows.replace(row, null);
		}
		if (item) {
			item.setParent(null);
		}
		return item;
	}
}

function dndToIndex(orientation: Orientation, srcIdx: number, droppedIdx: number, droppedRect: DOMRect, clientPos: Point): number {
	let toIndex: number = Number.NaN;
	const movingToLowerIdx = srcIdx > droppedIdx;
	const relPos = (orientation === Orientation.Horizontal) ?
		clientPos.x() - droppedRect.x :
		clientPos.y() - droppedRect.y;
	const dim = (orientation === Orientation.Horizontal) ? droppedRect.width : droppedRect.height;
	const halfOrOver = relPos >= (dim / 2);
	if (movingToLowerIdx) {
		if (halfOrOver) {
			if ((droppedIdx - srcIdx) === -1) {
				// Do nothing
			} else {
				toIndex = droppedIdx + 1;
			}
		} else {
			toIndex = droppedIdx;
		}
	} else {
		// Moving to higher index
		if (halfOrOver) {
			toIndex = droppedIdx;
		} else {
			if ((droppedIdx - srcIdx) === 1) {
				// Do nothing
			} else {
				toIndex = droppedIdx - 1;
			}
		}
	}
	return toIndex;
}

class ProgressIndicator extends El {
	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		const opts = elOpts(a, b, c);
		let rootExisted = Boolean(opts.root);
		if (!(opts.root || opts.tagName)) {
			const root = opts.parent ?
				opts.parent.querySelector('.mdc-data-table__progress-indicator') :
				null;
			if (root) {
				opts.root = root;
				rootExisted = true;
			} else {
				opts.tagName = 'div';
			}
		}
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table__progress-indicator',
			...classNames,
		];
		super(opts);
		if (!rootExisted) {
			new El({
				classNames: 'mdc-data-table__scrim',
				parent: this,
				tagName: 'div',
			});
			const bar = new El({
				attributes: [
					['aria-label', 'Loading data'],
					['role', 'progressbar'],
				],
				classNames: [
					'mdc-linear-progress',
					'mdc-linear-progress--indeterminate',
					'mdc-data-table__linear-progress',
				],
				parent: this,
				tagName: 'div',
			});
			const buffer = new El({
				classNames: 'mdc-linear-progress__buffer',
				parent: bar,
				tagName: 'div',
			});
			new El({
				classNames: 'mdc-linear-progress__buffer-bar',
				parent: buffer,
				tagName: 'div',
			});
			new El({
				classNames: 'mdc-linear-progress__buffer-dots',
				parent: buffer,
				tagName: 'div',
			});
			const primary = new El({
				classNames: [
					'mdc-linear-progress__bar',
					'mdc-linear-progress__primary-bar',
				],
				parent: bar,
				tagName: 'div',
			});
			new El({
				classNames: 'mdc-linear-progress__bar-inner',
				parent: primary,
				tagName: 'span',
			});
			const secondary = new El({
				classNames: [
					'mdc-linear-progress__bar',
					'mdc-linear-progress__secondary-bar',
				],
				parent: bar,
				tagName: 'div',
			});
			new El({
				classNames: 'mdc-linear-progress__bar-inner',
				parent: secondary,
				tagName: 'span',
			});
		}
	}
}

import {list} from './tools';
import {CheckState, CSS_CLASS_HIDE} from './constants';
import {closestMatchingElement, elementMatchesSelector, elementString, isNumber, printTree} from './util';
import {EvtDispatcher} from './evt';

const staticNode: Element = document.createElement('div');

class EventListenerInfo {
	capture?: boolean;
	eventType: string;
	listener: EventListenerOrEventListenerObject;

	constructor(type: string, listener: EventListenerOrEventListenerObject, capture?: boolean) {
		this.capture = capture;
		this.eventType = type;
		this.listener = listener;
	}

	eq(other: EventListenerInfo): boolean {
		const {capture, eventType, listener} = this;
		return (capture === other.capture) && (eventType === other.eventType) && (listener === other.listener);
	}
}

export interface ElOpts {
	attributes: Iterable<[string, string]>;
	classNames: Iterable<string>;
	draggable: boolean;
	id: string;
	namespace: string | null;
	parent: El | null;
	placementIndex: number | null;
	root: El | Element | null;
	styles: Iterable<[string, string]>;
	tagName: TagName;
}

export function elOpts<T extends ElOpts = ElOpts>(a?: Partial<T> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null): Partial<T> {
	let opts: Partial<T> = {};
	let parent: El | null = null;
	let root: Element | null = null;
	let tagName: TagName | null = null;
	if (a) {
		if (typeof a === 'string') {
			tagName = a;
		} else if (a instanceof El) {
			parent = a;
		} else if (a instanceof Element) {
			root = a;
		} else {
			opts = a;
		}
	}
	if (b) {
		if (typeof b === 'string') {
			tagName = b;
		} else if (b instanceof El) {
			parent = b;
		} else {
			root = b;
		}
	}
	if (c) {
		parent = c;
	}
	if (parent) {
		opts.parent = parent;
	}
	if (root) {
		opts.root = root;
	}
	if (tagName) {
		opts.tagName = tagName;
	}
	parent = null;
	root = null;
	tagName = null;
	return opts;
}

export class El extends EvtDispatcher {
	static IconClassName: string = 'material-icons';
	static IconSelector: string = `.${El.IconClassName}`;
	static instanceCount: number = 0;

	static body(opts: Partial<ElOpts> | null = null): El {
		return new this(opts, document.body);
	}

	static checkbox(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static checkbox(parent?: El | null): El;
	static checkbox(opts?: Partial<ElOpts> | null): El;
	static checkbox(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		const inp = this.input(elOpts(a, b));
		inp.setType('checkbox');
		return inp;
	}

	static cmp(a: El, b: El): number {
		return a.eq(b) ? 0 : -1;
	}

	static div(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static div(parent?: El | null): El;
	static div(opts?: Partial<ElOpts> | null): El;
	static div(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'div');
	}

	static documentElement(opts: Partial<ElOpts> | null = null): El {
		return new this(opts, document.documentElement);
	}

	static fromEvent(event: Event, opts: Partial<ElOpts> | null = null): El {
		const tgt = event.target;
		if (tgt && (tgt instanceof Element)) {
			return new this(opts, tgt);
		}
		throw new Error('El::fromEvent: Event target is null or not an instance of Element');
	}

	static fromSelector(selector: string, opts: Partial<ElOpts> | null = null): El {
		const result = document.querySelector(selector);
		if (result) {
			return new this(opts, result);
		}
		throw new Error(`El::fromSelector: Element was not found using selector "${selector}"`);
	}

	static input(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static input(parent?: El | null): El;
	static input(opts?: Partial<ElOpts> | null): El;
	static input(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'input');
	}

	static label(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static label(parent?: El | null): El;
	static label(opts?: Partial<ElOpts> | null): El;
	static label(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'label');
	}

	static li(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static li(parent?: El | null): El;
	static li(opts?: Partial<ElOpts> | null): El;
	static li(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'li');
	}

	static path(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static path(parent?: El | null): El;
	static path(opts?: Partial<ElOpts> | null): El;
	static path(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		const opts = elOpts(a, b);
		opts.namespace = 'http://www.w3.org/2000/svg';
		return new this(opts, 'path');
	}

	static polygon(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static polygon(parent?: El | null): El;
	static polygon(opts?: Partial<ElOpts> | null): El;
	static polygon(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		const opts = elOpts(a, b);
		opts.namespace = 'http://www.w3.org/2000/svg';
		return new this(opts, 'polygon');
	}

	static span(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static span(parent?: El | null): El;
	static span(opts?: Partial<ElOpts> | null): El;
	static span(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'span');
	}

	static svg(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static svg(parent?: El | null): El;
	static svg(opts?: Partial<ElOpts> | null): El;
	static svg(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		const opts = elOpts(a, b);
		opts.namespace = 'http://www.w3.org/2000/svg';
		return new this(opts, 'svg');
	}

	static ul(opts: Partial<ElOpts> | null, parent?: El | null): El;
	static ul(parent?: El | null): El;
	static ul(opts?: Partial<ElOpts> | null): El;
	static ul(a?: Partial<ElOpts> | El | null, b?: El | null): El {
		return new this(elOpts(a, b), 'ul');
	}

	instanceNumber: number;
	protected elem: Element;
	protected listenerInfo: list<EventListenerInfo>;
	protected textNode: Text | null;

	constructor(opts: Partial<ElOpts> | null, tagName: TagName, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, root: Element | null, parent?: El | null);
	constructor(tagName: TagName, parent?: El | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElOpts>, parent?: El | null);
	constructor(opts?: Partial<ElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: El | null);
	constructor(a?: Partial<ElOpts> | El | Element | TagName | null, b?: El | Element | TagName | null, c?: El | null) {
		super();
		this.instanceNumber = ++(<typeof El>this.constructor).instanceCount;
		const opts = elOpts(a, b, c);
		this.elem = staticNode;
		this.listenerInfo = new list();
		this.textNode = null;
		if (opts.root) {
			if (opts.root instanceof El) {
				this.elem = opts.root.elem;
				this.listenerInfo = opts.root.listenerInfo;
				this.textNode = opts.root.textNode;
			} else {
				this.elem = opts.root;
			}
		} else if (opts.tagName) {
			if (opts.namespace) {
				this.elem = document.createElementNS(opts.namespace, opts.tagName);
			} else {
				this.elem = document.createElement(opts.tagName);
			}
		}
		if (opts.id) {
			this.setId(opts.id);
		}
		if (opts.draggable) {
			this.setDraggable(true);
		}
		if (opts.attributes) {
			this.setAttribute(opts.attributes);
		}
		if (opts.classNames) {
			if (typeof opts.classNames === 'string') {
				this.addClass(opts.classNames);
			} else {
				this.addClass(...opts.classNames);
			}
		}
		if (opts.styles) {
			for (const [name, value] of opts.styles) {
				this.setStyleProperty(name, value);
			}
		}
		if (opts.parent) {
			this.setParent(opts.parent, opts.placementIndex);
		}
	}

	appendChild(child: El | Element): void {
		if (this.elem !== staticNode) {
			const childRoot = (child instanceof El) ? (child.elem === staticNode) ? null : child.elem : child;
			if (childRoot) {
				this.elem.appendChild(childRoot);
			}
		}
	}

	addClass(...name: Array<string>): void {
		if ((this.elem !== staticNode) && name.length) {
			this.elem.classList.add(...name);
		}
	}

	addEventListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
	addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
	addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
		if (this.elem !== staticNode) {
			this.elem.addEventListener(type, listener, options);
			this.listenerInfo.append(new EventListenerInfo(type, listener, ((options === undefined) || (typeof options === 'boolean')) ? options : undefined));
		}
	}

	appendToBody(): void {
		if (this.elem !== staticNode) {
			document.body.appendChild(this.elem);
		}
	}

	attribute(name: string): string | null {
		if (this.elem !== staticNode) {
			return this.elem.getAttribute(name);
		}
		return null;
	}

	blur(): void {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			this.elem.blur();
		}
	}

	checkState(): CheckState {
		if (this.elem instanceof HTMLInputElement) {
			return this.elem.indeterminate ?
				CheckState.PartiallyChecked :
				this.elem.checked ?
					CheckState.Checked :
					CheckState.Unchecked;
		}
		return CheckState.Unchecked;
	}

	children(): list<El> {
		return this.makeList(Array.from(this.elem.children).map(elem => (new El(elem))));
	}

	className(): string {
		if (this.elem !== staticNode) {
			return this.elem.className;
		}
		return '';
	}

	clear(): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.textNode) {
			this.textNode.data = '';
			this.textNode.remove();
			this.textNode = null;
		}
		let node: Node | null = this.elem.firstChild;
		while (node) {
			this.elem.removeChild(node);
			node = this.elem.firstChild;
		}
	}

	clone(deep?: boolean): El {
		if (this.elem === staticNode) {
			return new El();
		}
		return new El(<Element>this.elem.cloneNode(deep));
	}

	closestMatchingAncestor(selector: string): El | null {
		const result = closestMatchingElement(this.elem, selector);
		if (result) {
			return new El(result);
		}
		return null;
	}

	contains(elem: El | Element | null): boolean {
		if (!elem || (this.elem === staticNode)) {
			return false;
		}
		return this.elem.contains((elem instanceof El) ? elem.elem : elem);
	}

	containsFocus(): boolean {
		if (this.hasFocus()) {
			return true;
		}
		const active = document.activeElement;
		return active ? this.contains(active) : false;
	}

	destroy(): void {
		this.remove();
		if (this.elem !== staticNode) {
			for (const info of this.listenerInfo) {
				this.elem.removeEventListener(info.eventType, info.listener, info.capture);
			}
		}
		this.listenerInfo.clear();
		if (this.textNode) {
			this.textNode.remove();
			this.textNode.data = '';
			this.textNode = null;
		}
		this.elem = staticNode;
		super.destroy();
	}

	dumpTree(): void {
		if (this.elem !== staticNode) {
			printTree(this.elem);
		}
	}

	element<E extends Element>(): E | null {
		return (this.elem === staticNode) ? null : <E>this.elem;
	}

	private ensureTextNode(): Text {
		this.textNode = findTextNode(this.elem);
		// Unsure if that spec means that there will ALWAYS be a Text node
		// or only if there was text content present within the node at
		// some point in its lifetime. Since I don't know and currently
		// lack the willpower to dive deep into this mystery, we'll settle
		// for a check and create a new instance if necessary.
		if (!this.textNode) {
			this.textNode = document.createTextNode('');
			this.elem.appendChild(this.textNode);
		}
		return this.textNode;
	}

	eq(other: El | Element | null): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		const otherElem = (other instanceof El) ?
			other.elem :
			other;
		return this.elem === otherElem;
	}

	focus(): void {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			this.elem.focus();
		}
	}

	hasAttribute(name: string): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return this.elem.hasAttribute(name);
	}

	hasClass(name: string): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return this.elem.classList.contains(name);
	}

	hasFocus(): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		const active = document.activeElement;
		return (active !== null) && (active === this.elem);
	}

	hide(): void {
		if (this.elem !== staticNode) {
			this.setVisible(false);
		}
	}

	href(): string {
		if (this.isA('a')) {
			return this.attribute('href') || '';
		} else {
			console.log('href() called on on-anchor');
		}
		return '';
	}

	id(): string {
		if (this.elem === staticNode) {
			return '';
		}
		return this.elem.id;
	}

	insertAdjacentElement(position: AdjacentPosition, elemToInsert: El | Element): void {
		if (this.elem !== staticNode) {
			this.elem.insertAdjacentElement(position, (elemToInsert instanceof El) ? elemToInsert.elem : elemToInsert);
		}
	}

	insertAdjacentText(position: AdjacentPosition, text: string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.textNode) {
			this.textNode.remove();
			this.textNode.data = '';
			this.textNode = null;
		}
		this.elem.insertAdjacentText(position, text);
	}

	insertChild(index: number, child: El | Element): void {
		if (this.elem === staticNode) {
			return;
		}
		const children = this.children();
		const count = children.size();
		const childEl = (child instanceof El) ? child.elem : child;
		if ((count === 0) || (index < 0) || (index >= count)) {
			this.elem.appendChild(childEl);
		} else {
			this.elem.children[index].insertAdjacentElement('beforebegin', childEl);
		}
	}

	isA(tagName: TagName): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return tagName.toUpperCase() === this.tagName();
	}

	isChecked(): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return this.checkState() === CheckState.Checked;
	}

	isDisabled(): boolean {
		if (this.elem !== staticNode) {
			if (disableable(this.elem)) {
				return this.elem.disabled;
			}
		}
		return false;
	}

	isDraggable(): boolean {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			const val = this.attribute('draggable');
			return (typeof val === 'string') && (val.toLowerCase() === 'true');
		}
		return false;
	}

	isHidden(): boolean {
		return this.hasClass(CSS_CLASS_HIDE);
	}

	isIndeterminate(): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return this.checkState() === CheckState.PartiallyChecked;
	}

	isRequired(): boolean {
		if (this.elem !== staticNode) {
			if ((this.elem instanceof HTMLInputElement) || (this.elem instanceof HTMLSelectElement) || (this.elem instanceof HTMLTextAreaElement)) {
				return this.elem.required;
			}
		}
		return false;
	}

	isValid(): boolean {
		if (this.elem !== staticNode) {
			const state = this.validity();
			if (state) {
				return state.valid;
			}
		}
		return true;
	}

	isVisible(): boolean {
		return !this.isHidden();
	}

	private makeList(items: Iterable<El>): list<El> {
		return new list(items, El.cmp);
	}

	matchesSelector(selector: string): boolean {
		if (this.elem === staticNode) {
			return false;
		}
		return elementMatchesSelector(this.elem, selector);
	}

	name(): string {
		if (this.elem !== staticNode) {
			if (nameable(this.elem)) {
				return this.elem.name;
			}
		}
		return '';
	}

	nextElementSibling(): El | null {
		if (this.elem !== staticNode) {
			if (this.elem.nextElementSibling) {
				return new El(this.elem.nextElementSibling);
			}
		}
		return null;
	}

	nodeName(): string {
		// Will be UPPERCASE
		if (this.elem === staticNode) {
			return '';
		}
		return this.elem.nodeName.toUpperCase();
	}

	parent(): El | null {
		if ((this.elem !== staticNode) && this.elem.parentElement) {
			return new El(this.elem.parentElement);
		}
		return null;
	}

	previousElementSibling(): El | null {
		if (this.elem !== staticNode) {
			const elem = this.elem.previousElementSibling;
			if (elem) {
				return new El(elem);
			}
		}
		return null;
	}

	querySelectorAll(selector: string): list<El> {
		if (this.elem === staticNode) {
			return this.makeList([]);
		}
		return this.makeList(Array.from(this.elem.querySelectorAll(selector)).map(elem => (new El(elem))));
	}

	querySelector(selector: string): El | null {
		if (this.elem === staticNode) {
			return null;
		}
		const result = this.elem.querySelector(selector);
		return result ?
			new El(result) :
			null;
	}

	rect(): DOMRect {
		if (this.elem === staticNode) {
			return {bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0, toJSON: () => ''};
		}
		return this.elem.getBoundingClientRect();
	}

	remove(): void {
		this.elem.remove();
	}

	removeAttribute(...name: Array<string>): void {
		if (this.elem !== staticNode) {
			name.forEach(n => this.elem.removeAttribute(n));
		}
	}

	removeChild(child: El | Node): void {
		if (this.elem !== staticNode) {
			this.elem.removeChild((child instanceof El) ? child.elem : child);
		}
	}

	removeClass(...name: Array<string>): void {
		if (this.elem !== staticNode) {
			this.elem.classList.remove(...name);
		}
	}

	removeEventListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
	removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
	removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
		if (this.elem !== staticNode) {
			this.elem.removeEventListener(type, listener, options);
			const cmp = new EventListenerInfo(type, listener, ((options === undefined) || (typeof options === 'boolean')) ? options : undefined);
			let i = (this.listenerInfo.size() - 1);
			for (; i >= 0; --i) {
				const info = this.listenerInfo.at(i);
				if (info.eq(cmp)) {
					this.listenerInfo.removeAt(i);
				}
			}
		}
	}

	removeStyleProperty(name: string): void {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			this.elem.style.removeProperty(name);
		}
	}

	removeText(): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.textNode) {
			this.textNode.data = '';
			this.textNode.remove();
			this.textNode = null;
		}
		const nodes = this.elem.childNodes;
		for (let i = 0; i < nodes.length; ++i) {
			const node = nodes[i];
			if (node.nodeType === Node.TEXT_NODE) {
				this.elem.removeChild(node);
			}
		}
	}

	replaceChild(newChild: El, oldChild: El): void {
		if (this.elem === staticNode) {
			return;
		}
		this.elem.replaceChild(newChild.elem, oldChild.elem);
	}

	reportValidity(): boolean {
		if (this.elem !== staticNode) {
			if ((this.elem instanceof HTMLFormElement) || (this.elem instanceof HTMLInputElement) || (this.elem instanceof HTMLTextAreaElement) || (this.elem instanceof HTMLSelectElement) || (this.elem instanceof HTMLButtonElement) || (this.elem instanceof HTMLFieldSetElement) || (this.elem instanceof HTMLObjectElement) || (this.elem instanceof HTMLOutputElement)) {
				return this.elem.reportValidity();
			}
		}
		return true;
	}

	scrollIntoView(alignTop?: boolean): void {
		// alignTop: If value is true, align to top, if value is false (not
		// falsey), align to bottom.
		if (this.elem !== staticNode) {
			this.elem.scrollIntoView(alignTop);
		}
	}

	selectedIndex(): number {
		if (this.elem instanceof HTMLSelectElement) {
			return this.elem.selectedIndex;
		}
		return -1;
	}

	setAttribute(name: string, value: string): void;
	setAttribute(attrs: Iterable<[string, string]>): void;
	setAttribute(a: Iterable<[string, string]> | string, b?: string): void {
		if (typeof a === 'string') {
			if (this.elem !== staticNode) {
				this.elem.setAttribute(a, <string>b);
			}
		} else {
			for (const [k, v] of a) {
				this.setAttribute(k, v);
			}
		}
	}

	setChecked(checked: boolean): void {
		if (this.elem !== staticNode) {
			this.setCheckState(checked ? CheckState.Checked : CheckState.Unchecked);
		}
	}

	setCheckState(state: CheckState): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLInputElement) {
			if (state === CheckState.PartiallyChecked) {
				this.elem.indeterminate = true;
			} else {
				this.elem.indeterminate = false;
				this.elem.checked = state === CheckState.Checked;
			}
		} else {
			console.log('Calling setCheckState on an Element which does not have the "checked" property.');
		}
	}

	setClass(add: boolean, ...name: Array<string>): void {
		if (this.elem === staticNode) {
			return;
		}
		if (add) {
			this.addClass(...name);
		} else {
			this.removeClass(...name);
		}
	}

	setDisabled(disabled: boolean): void {
		if (this.elem === staticNode) {
			return;
		}
		if (disableable(this.elem)) {
			this.elem.disabled = disabled;
		} else {
			console.log('Calling setDisabled on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setDraggable(draggable: boolean): void {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			if (draggable) {
				this.setAttribute('draggable', 'true');
			} else {
				this.removeAttribute('draggable');
			}
		}
	}

	setEnabled(enabled: boolean): void {
		this.setDisabled(!enabled);
	}

	setHref(href: string, opts?: Partial<{text: string; target: 'blank';}>): void {
		if (this.isA('a')) {
			this.setAttribute('href', href);
			let target: string | undefined = '';
			let text: string | undefined = undefined;
			if (opts) {
				target = opts.target;
				text = opts.text;
			}
			if (target) {
				this.setAttribute([['target', `_${target}`], ['rel', 'noreferrer noopener']]);
			} else {
				this.removeAttribute('rel', 'target');
			}
			if (text) {
				this.setText(text);
			}
		} else {
			console.log('setHref called on non-anchor: %s', this);
		}
	}

	setId(id: string): void {
		if (this.elem !== staticNode) {
			this.elem.id = id;
		}
	}

	setIndeterminate(): void {
		if (this.elem === staticNode) {
			return;
		}
		this.setCheckState(CheckState.PartiallyChecked);
	}

	setMax(value: number | string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLInputElement) {
			this.elem.max = (typeof value === 'string') ? value : String(value);
		} else if ((this.elem instanceof HTMLMeterElement) || (this.elem instanceof HTMLProgressElement)) {
			this.elem.max = (typeof value === 'number') ? value : Number(value);
		}
	}

	setMin(value: number | string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLInputElement) {
			this.elem.min = (typeof value === 'string') ? value : String(value);
		} else if (this.elem instanceof HTMLMeterElement) {
			this.elem.min = (typeof value === 'number') ? value : Number(value);
		}
	}

	setName(name: string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (nameable(this.elem)) {
			this.elem.name = name;
		} else {
			console.log('Calling setName on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setParent(parent: El | Element | null, placementIndex: number | null = null): void {
		if (this.elem === staticNode) {
			return;
		}
		if (!parent) {
			this.remove();
			return;
		}
		const curr = this.parent();
		if (curr && curr.eq(parent)) {
			return;
		}
		const p = (parent instanceof El) ?
			parent.element() :
			parent;
		if (p) {
			if (isNumber(placementIndex)) {
				if (placementIndex < 0) {
					p.insertAdjacentElement('afterbegin', this.elem);
				} else {
					const siblings = p.children;
					if (placementIndex >= siblings.length) {
						p.appendChild(this.elem);
					} else {
						p.insertBefore(this.elem, siblings[placementIndex]);
					}
				}
			} else {
				p.appendChild(this.elem);
			}
		}
	}

	setRequired(required: boolean): void {
		if (this.elem === staticNode) {
			return;
		}
		if ((this.elem instanceof HTMLInputElement) || (this.elem instanceof HTMLSelectElement) || (this.elem instanceof HTMLTextAreaElement)) {
			this.elem.required = required;
		} else {
			console.log('Calling setRequired on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setSelectedIndex(index: number): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLSelectElement) {
			this.elem.selectedIndex = index;
		} else {
			console.log('Calling setSelectedIndex on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setStep(value: number | string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLInputElement) {
			this.elem.step = String(value);
		}
	}

	setStyleProperty(name: string, value: string | null): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLElement) {
			this.elem.style.setProperty(name, value);
		}
	}

	setTabIndex(index: number): void {
		if (this.elem === staticNode) {
			return;
		}
		if (this.elem instanceof HTMLElement) {
			this.elem.tabIndex = index;
		}
	}

	setText(text?: string | null): void {
		this._setText(text);
	}

	protected _setText(text?: string | null): void {
		if (this.elem === staticNode) {
			return;
		}
		this.ensureTextNode().data = text || '';
	}

	setTitle(title: string): void {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			this.elem.title = title;
		}
	}

	setType(type: string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (typeable(this.elem)) {
			this.elem.type = type;
		} else {
			console.log('Calling setType on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setValue(value: string): void {
		if (this.elem === staticNode) {
			return;
		}
		if (valuable(this.elem)) {
			this.elem.value = value;
		} else {
			console.log('Calling setValue on an Element (%s) which does not have this property.', this.tagName());
		}
	}

	setVisible(visible: boolean): void {
		if (this.elem === staticNode) {
			return;
		}
		this.setClass(!visible, CSS_CLASS_HIDE);
	}

	show(): void {
		if (this.elem === staticNode) {
			return;
		}
		this.setVisible(true);
	}

	submit(): void {
		if (this.elem instanceof HTMLFormElement) {
			this.elem.submit();
		}
	}

	tabIndex(): number {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			return this.elem.tabIndex;
		}
		return -1;
	}

	tagName(): string {
		// Will be UPPERCASE
		return this.elem.tagName.toUpperCase();
	}

	text(): string {
		if (this.elem !== staticNode) {
			return this.ensureTextNode().data;
		}
		return '';
	}

	title(): string {
		if ((this.elem !== staticNode) && (this.elem instanceof HTMLElement)) {
			return this.elem.title;
		}
		return '';
	}

	toString(): string {
		return (this.elem === staticNode) ? 'El(<nuthin>)' : `El(${elementString(this.elem)})`;
	}

	type(): string {
		if (this.elem !== staticNode) {
			if (typeable(this.elem)) {
				return this.elem.type;
			}
		}
		return '';
	}

	private validity(): ValidityState | null {
		if (this.elem !== staticNode) {
			if (validable(this.elem)) {
				return this.elem.validity;
			}
		}
		return null;
	}

	value(): string {
		if (this.elem !== staticNode) {
			if (valuable(this.elem)) {
				return this.elem.value;
			}
		}
		return '';
	}

	_giveMeTheNodeIKnowWhatImDoing<E extends Element>(): E | null {
		return (this.elem === staticNode) ? null : <E>this.elem;
	}
}

function findTextNode(elem: Element): Text | null {
	// WARNING: This routine will normalize this element's entire subtree.
	//
	// The Node.normalize() method puts the specified node and all of
	// its sub-tree into a "normalized" form. In a normalized sub-tree,
	// no text nodes in the sub-tree are empty and there are no
	// adjacent text nodes.
	elem.normalize();
	const childNodes = elem.childNodes;
	for (let i = 0; i < childNodes.length; ++i) {
		const node = childNodes[i];
		if (node.nodeType === Node.TEXT_NODE) {
			return <Text>node;
		}
	}
	return null;
}

function disableable(el: Element): el is Element & {disabled: boolean;} {
	return (el instanceof HTMLInputElement) || (el instanceof HTMLButtonElement) || (el instanceof HTMLSelectElement) || (el instanceof HTMLTextAreaElement) || (el instanceof HTMLOptionElement) || (el instanceof HTMLOptGroupElement) || (el instanceof HTMLFieldSetElement) || (el instanceof HTMLLinkElement) || (el instanceof SVGStyleElement);
}

function nameable(el: Element): el is Element & {name: string;} {
	return (el instanceof HTMLInputElement) || (el instanceof HTMLButtonElement) || (el instanceof HTMLSelectElement) || (el instanceof HTMLTextAreaElement) || (el instanceof HTMLFormElement) || (el instanceof HTMLFieldSetElement) || (el instanceof HTMLAnchorElement) || (el instanceof HTMLEmbedElement) || (el instanceof HTMLFrameElement) || (el instanceof HTMLIFrameElement) || (el instanceof HTMLImageElement) || (el instanceof HTMLMapElement) || (el instanceof HTMLMetaElement) || (el instanceof HTMLObjectElement) || (el instanceof HTMLOutputElement) || (el instanceof HTMLParamElement) || (el instanceof HTMLSlotElement);
}

function typeable(el: Element): el is Element & {type: string;} {
	return (el instanceof HTMLInputElement) || (el instanceof HTMLButtonElement) || (el instanceof HTMLAnchorElement) || (el instanceof HTMLEmbedElement) || (el instanceof HTMLLIElement) || (el instanceof HTMLLinkElement) || (el instanceof HTMLOListElement) || (el instanceof HTMLObjectElement) || (el instanceof HTMLParamElement) || (el instanceof HTMLScriptElement) || (el instanceof HTMLSourceElement) || (el instanceof HTMLStyleElement) || (el instanceof HTMLUListElement) || (el instanceof SVGScriptElement) || (el instanceof SVGStyleElement);
}

function validable(el: Element): el is Element & {validity: ValidityState;} {
	return (el instanceof HTMLInputElement) || (el instanceof HTMLButtonElement) || (el instanceof HTMLSelectElement) || (el instanceof HTMLTextAreaElement) || (el instanceof HTMLFieldSetElement) || (el instanceof HTMLObjectElement) || (el instanceof HTMLOutputElement);
}

function valuable(el: Element): el is Element & {value: string;} {
	return (el instanceof HTMLInputElement) || (el instanceof HTMLTextAreaElement) || (el instanceof HTMLSelectElement) || (el instanceof HTMLOptionElement) || (el instanceof HTMLDataElement) || (el instanceof HTMLOutputElement) || (el instanceof HTMLParamElement) || (el instanceof HTMLButtonElement);
}

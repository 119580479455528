import {Form} from '../ui/form';
import {ContactCard} from '../ui/contactcard';
import {TextInput} from '../ui/textinput';
import {StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {DataTable} from '../ui/datatable';
import {bind, closestMatchingElement, isNumber} from '../util';
import {CRUDView, ViewState} from './crudview';
import {Dialog} from '../ui/dialog';
import {VBoxLayout} from '../ui/layout';
import {El} from '../el';
import {ComboBox} from '../ui/combobox';
import {Evt} from '../evt';
import {IconButtonClickEvt} from '../ui/iconbutton';

class ParentGroupSelect extends ComboBox {
	parentID(): number | null {
		const rv = Number.parseInt(this.value());
		return isNumber(rv) ? rv : null;
	}
}

class ClientGroupForm extends Form {
	nameInput: TextInput;
	emailInput: TextInput;
	parentGroupSelect: ParentGroupSelect;

	constructor() {
		super();
		const box = new VBoxLayout(this);
		box.setSpacing(16);
		this.nameInput = new TextInput({
			required: true,
			inputId: 'id_name-label',
			name: 'name',
			labelText: 'Name',
			fullWidth: true,
		});
		box.addEl(this.nameInput);
		this.emailInput = new TextInput({
			inputId: 'id_email-label',
			name: 'email',
			labelText: 'Email',
			type: 'email',
			fullWidth: true,
		});
		box.addEl(this.emailInput);
		this.parentGroupSelect = new ParentGroupSelect({emptyItem: true, labelText: 'Parent group', parent: this});
		this.parentGroupSelect.setFixPosition(true);
		box.addEl(this.parentGroupSelect);
		this.setMethod('POST');
	}

	setParentGroupOptions(groups: IGroup[]): void {
		const select = this.parentGroupSelect;
		select.clearItems();
		for (let i = 0; i < groups.length; ++i) {
			const group = groups[i];
			select.addItem({value: group.id, listItemOptions: {text: group.name}});
		}
	}
}

class EmailCell {
	_root: El;

	constructor(elem: El | HTMLElement) {
		this._root = (elem instanceof El) ? elem : new El(null, elem);
	}

	setEmail(email: string): void {
		let anchor = this._root.querySelector('a');
		if (!email) {
			if (anchor) {
				anchor.remove();
			}
			return;
		}
		if (!anchor) {
			anchor = new El({parent: this._root, tagName: 'a'});
		}
		anchor.setAttribute('href', `mailto:${email}`);
	}
}

class TableObject {
	_view: View;

	constructor(view: View) {
		this._view = view;
	}

	remove(id: number): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const el = tableEl.querySelector(`[data-row-id="${id}"]`);
			if (el) {
				el.destroy();
			}
		}
	}

	setData(data: IGroup): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const row = tableEl.querySelector(`[data-row-id="${data.id}"]`);
			if (row) {
				const [nameCell, emailCell] = row.querySelectorAll('th,td');
				nameCell.setText(data.name);
				new EmailCell(emailCell).setEmail(data.email);
			}
		}
	}
}

interface IObjectData {
	obj: IGroup;
	parentGroup: IGroup | null;
}

class View extends CRUDView {
	static CREATE_CTA_SELECTOR = '#id_object-create-button';
	static OBJECT_COLLECTION_SELECTOR = '#id_object-data-table tbody';
	static OBJECT_SELECTOR = 'tr[data-row-id] td, tr[data-row-id] th';

	contactCard: ContactCard;
	dia: Dialog;
	form: ClientGroupForm;
	objectId: number | null;
	table: DataTable;

	constructor() {
		super();
		this.contactCard = new ContactCard();
		this.contactCard.setIcon('groups');
		this.form = new ClientGroupForm();
		this.contactCard.setContactDetailsHeading('Contact details');
		this.contactCard.section(1).appendChild(this.form);
		this.objectId = null;
		this.table = new DataTable();
		this.dia = new Dialog();
		this.dia.setContent(this.contactCard);
	}

	async deleteObject(): Promise<void> {
		const data = await this.objectData();
		await svc.group.client.group.delete(data.obj.id);
		this.removeDocumentObject(data.obj.id);
	}

	init(): void {
		this.contactCard.onEvt(this.cardActionEvt);
		this.form.setId('id_object-form');
		let elem: Element | null = document.querySelector(View.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this.createCTAClickEvent);
		}
		document.querySelectorAll(View.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectClickEvent));
	}

	async objectData(): Promise<IObjectData> {
		const obj = await svc.group.client.group.get(this.objectIdOrFail());
		const parentGroup = isNumber(obj.parentId) ?
			await svc.group.client.group.get(obj.parentId) :
			null;
		return {obj, parentGroup};
	}

	objectIdOrFail(): number {
		if (isNumber(this.objectId)) {
			return this.objectId;
		}
		throw new Error('View::objectID object ID is null');
	}

	protected async openCreateDialog(): Promise<void> {
		this.form.onSubmit();
		this.form.setAction(window.pburls.group.clientGroup.list);
		this.form.nameInput.setValue('');
		this.form.emailInput.setValue('');
		this.form.setParentGroupOptions(await svc.group.client.group.list());
		this.form.show();
		this.dia.showFooter();
		this.contactCard.setToolbarButtonVisible('edit', false);
		this.contactCard.setToolbarButtonVisible('more', false);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setHeading('Create client group');
		this.contactCard.setSectionVisible(false, 0);
		this.contactCard.setSectionVisible(true, 1);
		this.dia.setStandardButtons(StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	protected async openDeleteDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.contactCard.setDetailVisible(false);
		this.contactCard.setSectionVisible(false, 0);
		this.contactCard.setSectionVisible(false, 1);
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setHeading(`Delete "${data.obj.name}"?`);
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			// this.dia.setDefaultButton(noBtn);
			noBtn.setRaised(true);
		}
		this.openDia();
	}

	protected async openDetailDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.dia.hideFooter();
		this.openDia();
	}

	protected async openEditDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.contactCard.setDetailVisible(true);
		this.contactCard.setSectionVisible(false, 0);
		this.contactCard.setSectionVisible(true, 1);
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.form.onSubmit(this.editFormSubmitEvent);
		this.form.setAction('');
		this.form.nameInput.setValue(data.obj.name);
		this.form.emailInput.setValue(data.obj.email);
		this.form.setParentGroupOptions(await svc.group.client.group.list());
		this.form.parentGroupSelect.setValue(isNumber(data.obj.parentId) ? String(data.obj.parentId) : '');
		this.form.show();
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	@bind
	private async cardActionEvt(evt: Evt): Promise<void> {
		if (evt instanceof IconButtonClickEvt) {
			switch (evt.iconButton().name()) {
				case 'edit':
					if (this.state === ViewState.EditState) {
						this.setState(ViewState.DetailState);
					} else {
						this.setState(ViewState.EditState);
					}
					break;
				case 'delete':
					this.setState(ViewState.DeleteState);
					break;
				case 'close':
					this.setState(ViewState.NoState);
					break;
			}
		} else if (evt.type() === Evt.Delete) {
			this.setState(ViewState.DeleteState);
		}
	}

	@bind
	private async createCTAClickEvent(): Promise<void> {
		this.setState(ViewState.CreateState);
	}

	@bind
	private async editFormSubmitEvent(): Promise<void> {
		const name = this.form.nameInput.value();
		const email = this.form.emailInput.value();
		const parentId = this.form.parentGroupSelect.parentID();
		const id = this.objectIdOrFail();
		await svc.group.client.group.update(id, {email, name, parentId, id});
		const data = await this.objectData();
		this.syncDocumentObject(data);
		this.setState(ViewState.DetailState);
	}

	@bind
	private async objectClickEvent(event: Event): Promise<void> {
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::objectClickEvent event target is null');
			return;
		}
		const emailAnchorMaybe = closestMatchingElement(tgt, 'a[href^="mailto:"]');
		if (emailAnchorMaybe) {
			// Email anchor clicked. Ignore.
			return;
		}
		const elem = new El(null, closestMatchingElement(tgt, 'tr[data-row-id]'));
		const id = Number(elem.attribute('data-row-id'));
		if (Number.isNaN(id)) {
			console.log('View::objectClickEvent event target data-row-id attribute did not yield valid value');
			return;
		}
		this.objectId = id;
		this.setState(ViewState.DetailState);
	}

	private removeDocumentObject(objectID: number): void {
		(new TableObject(this)).remove(objectID);
	}

	private setCardData(data: IObjectData): void {
		this.contactCard.clearContactDetails();
		this.contactCard.setDetailVisible(false);
		this.contactCard.setSectionVisible(false, 0);
		this.contactCard.setSectionVisible(false, 1);
		this.contactCard.setHeading(data.obj.name);
		this.contactCard.setSubheading(data.parentGroup ? `Group member of ${data.parentGroup.name}` : '');
		if (data.obj.email) {
			this.contactCard.setDetailVisible(true);
			this.contactCard.setSectionVisible(true, 0);
			this.contactCard.addEmailAddress(data.obj.email);
		}
	}

	private syncDocumentObject(data: IObjectData): void {
		(new TableObject(this)).setData(data.obj);
	}
}

export function init(): void {
	const comp = new View();
	comp.init();
}

import React from 'react';

import {bind} from '../../../util';
import {Grid, GridCell} from '../../components';
import {
	Icon,
	Name,
	Color,
	IsPublic,
	SizeView,
	Subheading,
	Description,
	IsExclusive,
	DurationView,
	LongDescription,
} from './components';

interface IProps {
	addOnSelect?: React.ReactNode;
	choiceList?: React.ReactNode;
	clientUserTypeSelect?: React.ReactNode;
	compact?: boolean;
	formButton?: React.ReactNode;
	object: IItem;
	onChange: (object: IItem) => any;
	pricing?: React.ReactNode;
	quickBooksItemSelect?: React.ReactNode;
	userSelect?: React.ReactNode;
	variants?: React.ReactNode;
}

export default class ItemView extends React.Component<IProps, {}> {
	@bind
	exclusiveChanged(value: boolean, name: 'exclusive'): void {
		this.itemChanged(!value, name);
	}

	@bind
	itemChanged<K extends keyof IItem>(value: IItem[K], name: K): void {
		this.props.onChange(
			{
				...this.props.object,
				...{[name]: value},
			},
		);
	}

	render(): React.ReactNode {
		const {
			addOnSelect,
			choiceList,
			clientUserTypeSelect,
			compact,
			formButton,
			object,
			pricing,
			quickBooksItemSelect,
			userSelect,
			variants,
		} = this.props;
		const {
			color,
			description,
			duration,
			exclusive,
			icon,
			isPublic,
			longDescription,
			name,
			size,
			succeedingDuration,
		} = object;
		const nameComp = (
			<GridCell>
				<Name onChange={this.itemChanged} value={name}/>
			</GridCell>
		);
		const descriptionComp = (
			<GridCell>
				<Description onChange={this.itemChanged} value={description}/>
			</GridCell>
		);
		const longDescriptionComp = (
			<GridCell>
				<LongDescription onChange={this.itemChanged} value={longDescription}/>
			</GridCell>
		);
		const durationComp = (
			<GridCell desktopSpan={6}>
				<DurationView
					duration={duration}
					onChange={(value) => this.itemChanged(value, 'duration')}
					iconButtonHeadingText="Production duration"
				/>
			</GridCell>
		);
		const succeedingDurationComp = (
			<GridCell desktopSpan={6}>
				<DurationView
					duration={succeedingDuration}
					onChange={(value) => this.itemChanged(value, 'succeedingDuration')}
					iconButtonHeadingText="Post-production duration"
				/>
			</GridCell>
		);
		const pricingComp = pricing ?
			<GridCell>
				<Subheading>
					Pricing
				</Subheading>
				{pricing}
			</GridCell> :
			null;
		const sizeComp =
			<GridCell>
				<SizeView
					onChange={this.itemChanged}
					size={size}/>
			</GridCell>;
		return compact ?
			<Grid>
				{nameComp}
				{descriptionComp}
				{longDescriptionComp}
				{pricingComp}
				{durationComp}
				{succeedingDurationComp}
				{sizeComp}
			</Grid> :
			<Grid>
				<GridCell>
					<IsPublic onChange={this.itemChanged} value={isPublic}/>
					<IsExclusive onChange={this.exclusiveChanged} value={!exclusive}/>
					{clientUserTypeSelect}
				</GridCell>
				{nameComp}
				{descriptionComp}
				{longDescriptionComp}
				<GridCell desktopSpan={6}>
					<Icon onChange={this.itemChanged} value={icon}/>
				</GridCell>
				<GridCell desktopSpan={6}>
					<Color onChange={this.itemChanged} value={color}/>
				</GridCell>
				{durationComp}
				{succeedingDurationComp}
				{sizeComp}
				{pricingComp}
				{variants ?
					<GridCell>
						<Subheading>
							Variants
						</Subheading>
						{variants}
					</GridCell> :
					null}
				{choiceList ?
					<GridCell>
						<Subheading>
							Choices
						</Subheading>
						{choiceList}
					</GridCell> :
					null}
				{addOnSelect ?
					<GridCell>
						<Subheading>
							Add ons
						</Subheading>
						{addOnSelect}
					</GridCell> :
					null}
				{userSelect ?
					<GridCell>
						<Subheading>
							Assignable Team Members
						</Subheading>
						{userSelect}
					</GridCell> :
					null}
				{quickBooksItemSelect ?
					<GridCell>
						<Subheading>
							QuickBooks Item
						</Subheading>
						{quickBooksItemSelect}
					</GridCell> :
					null}
				{formButton ?
					<GridCell>
						{formButton}
					</GridCell> :
					null}
			</Grid>;
	}
}

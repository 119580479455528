import React from 'react';

import {isNumber} from '../../../../util';
import {Select} from '../../../components';

type Option = {id: number; name: string;}

interface IProps<T extends Option = Option> {
	onChange: (value: number[], name: 'addons') => any;
	options: T[];
	value: number[];
}

export default class AddOnSelect extends React.Component<IProps, {}> {
	static Name: 'addons' = 'addons';

	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent<HTMLSelectElement>): void {
		const {onChange} = this.props;
		const opts = event.target.selectedOptions;
		const rv: number[] = [];
		for (let i = 0; i < opts.length; ++i) {
			const opt = opts[i];
			const val = Number.parseInt(opt.value);
			if (isNumber(val)) {
				rv.push(val);
			}
		}
		onChange(rv, this.name());
	}

	name(): 'addons' {
		return AddOnSelect.Name;
	}

	render(): React.ReactNode {
		const {options, value} = this.props;
		return <Select
			className="width--100-percent pb-select--filled"
			multiple={true}
			name={this.name()}
			onChange={this.changeEvent}
			options={options.map(opt => ({text: opt.name, value: opt.id}))}
			size={4}
			value={value}/>;
	}
}

import {List, ListItemOpts} from '../ui/list';
import {Form} from '../ui/form';
import {Checkbox} from '../ui/checkbox';
import {Switch} from '../ui/switch';
import {ContactCard} from '../ui/contactcard';
import {list, set} from '../tools';
import {TextInput} from '../ui/textinput';
import {ItemRole, StandardButton} from '../constants';
import {apiService as svc} from '../services';
import {CRUDView, ViewState} from './crudview';
import {DataTable} from '../ui/datatable';
import {bind, closestMatchingElement, isNumber} from '../util';
import {Dialog} from '../ui/dialog';
import {HBoxLayout, VBoxLayout} from '../ui/layout';
import {El} from '../el';
import {Evt} from '../evt';
import {IconButtonClickEvt} from '../ui/iconbutton';

class TeamMemberForm extends Form {
	emailInput: TextInput;
	firstNameInput: TextInput;
	isAdminInput: Switch;
	lastNameInput: TextInput;
	passwordConfirmInput: TextInput;
	passwordInput: TextInput;

	constructor() {
		super();
		const rootVBox = new VBoxLayout(this);
		rootVBox.setSpacing(16);
		const nameHBox = new HBoxLayout();
		nameHBox.setSpacing(8);
		this.firstNameInput = new TextInput({
			fullWidth: true,
			inputId: 'id_first-name-label',
			labelText: 'First name',
			name: 'firstName',
		});
		nameHBox.addEl(this.firstNameInput);
		this.lastNameInput = new TextInput({
			fullWidth: true,
			inputId: 'id_last-name-label',
			labelText: 'Last name',
			name: 'lastName',
		});
		nameHBox.addEl(this.lastNameInput);
		rootVBox.addEl(nameHBox);
		this.emailInput = new TextInput({
			fullWidth: true,
			inputId: 'id_email-label',
			labelText: 'Email',
			name: 'email',
			required: true,
			type: 'email',
		});
		rootVBox.addEl(this.emailInput);
		const pwHBox = new HBoxLayout();
		pwHBox.setSpacing(8);
		this.passwordInput = new TextInput({
			fullWidth: true,
			inputId: 'id_password-label',
			labelText: 'Password',
			name: 'password',
			required: true,
			type: 'password',
		});
		pwHBox.addEl(this.passwordInput);
		this.passwordConfirmInput = new TextInput({
			fullWidth: true,
			inputId: 'id_password-confirm-label',
			labelText: 'Confirm password',
			name: 'passwordConfirm',
			required: true,
			type: 'password',
		});
		pwHBox.addEl(this.passwordConfirmInput);
		rootVBox.addEl(pwHBox);
		this.isAdminInput = new Switch(this);
		this.isAdminInput.setInputId('id_is-admin-input');
		this.isAdminInput.setLabelText('Administrator');
		this.isAdminInput.setName('isAdmin');
		rootVBox.addSpacing(4);
		rootVBox.addEl(this.isAdminInput);
		this.setMethod('POST');
	}

	hideEmail(): void {
		this.emailInput.setRequired(false);
		this.emailInput.setName('');
		this.emailInput.hide();
	}

	hidePassword(): void {
		this.passwordInput.setRequired(false);
		this.passwordInput.setName('');
		this.passwordInput.hide();
		this.passwordConfirmInput.setRequired(false);
		this.passwordConfirmInput.setName('');
		this.passwordConfirmInput.hide();
	}

	showEmail(): void {
		this.emailInput.setRequired(true);
		this.emailInput.setName('email');
		this.emailInput.show();
	}

	showPassword(): void {
		this.passwordInput.setRequired(true);
		this.passwordInput.setName('password');
		this.passwordInput.show();
		this.passwordConfirmInput.setRequired(true);
		this.passwordConfirmInput.setName('passwordConfirm');
		this.passwordConfirmInput.show();
	}
}

class AvailCell {
	_root: El;

	constructor(elem: El | HTMLElement) {
		this._root = (elem instanceof El) ? elem : new El(elem);
	}

	setAvailability(excludeAvailability: boolean): void {
		const button = this._root.querySelector('button[name="excludeAvailability"]');
		if (!button) {
			console.log('AvailCell::setAvailability button element was not found');
			return;
		}
		if (excludeAvailability) {
			button.removeClass('color--green');
			button.setText('event_busy');
		} else {
			button.setText('event_available');
			button.addClass('color--green');
		}
	}
}

class EmailCell {
	_root: El;

	constructor(elem: El | HTMLElement) {
		this._root = (elem instanceof El) ? elem : new El(elem);
	}

	setEmail(email: string): void {
		let anchor = this._root.querySelector('a');
		if (!email) {
			if (anchor) {
				anchor.remove();
			}
			return;
		}
		if (!anchor) {
			anchor = new El('a', this._root);
		}
		anchor.setAttribute('href', `mailto:${email}`);
	}
}

class TableObject {
	_view: View;

	constructor(view: View) {
		this._view = view;
	}

	remove(id: number): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const el = tableEl.querySelector(`[data-row-id="${id}"]`);
			if (el) {
				el.destroy();
			}
		}
	}

	setData(data: IUser): void {
		// FIXME
		// FIXME
		// FIXME
		// @ts-ignore
		const tableEl = this._view.table.el;
		if (tableEl) {
			const row = tableEl.querySelector(`[data-row-id="${data.id}"]`);
			if (row) {
				const [nameCell, emailCell, phoneCell, availCell] = row.querySelectorAll('th,td');
				nameCell.setText(`${data.firstName} ${data.lastName}`.trim());
				new EmailCell(emailCell).setEmail(data.email);
				if (window.isProducer) {
					new AvailCell(availCell).setAvailability(data.excludeAvailability);
				}
			}
		}
	}
}

interface IObjectData {
	emailAddresses: IEmailAddress[];
	obj: IUser;
	phoneNumbers: IPhoneNumber[];
}

const ContactDetailsSectionIndex = 0;
const FormSectionIndex = 1;
const ItemListSectionIndex = 2;
const MarketListSectionIndex = 3;

class View extends CRUDView {
	static CREATE_CTA_SELECTOR = '#id_object-create-button';
	static AVAILABILITY_SELECTOR = 'button[name="excludeAvailability"]';
	static OBJECT_COLLECTION_SELECTOR = '#id_object-data-table tbody';
	static OBJECT_SELECTOR = 'tr[data-row-id] td, tr[data-row-id] th';

	contactCard: ContactCard;
	dia: Dialog;
	form: TeamMemberForm;
	itemList: List;
	items: list<IItem>;
	markets: list<IMarket>;
	marketList: List;
	objId: number | null;
	table: DataTable;

	constructor() {
		super();
		this.contactCard = new (window.isProducer ? ContactCard : ContactCard)();
		this.contactCard.setIcon('person');
		this.form = new TeamMemberForm();
		this.contactCard.setContactDetailsHeading('Contact details');
		this.contactCard.section(FormSectionIndex).appendChild(this.form);
		this.items = new list<IItem>();
		this.itemList = new List();
		this.itemList.setStyleProperty('max-height', '240px');
		this.itemList.setStyleProperty('overflow-y', 'auto');
		this.contactCard.section(ItemListSectionIndex).appendChild(this.itemList);
		this.contactCard.setSectionHeading('Items', ItemListSectionIndex);
		this.marketList = new List();
		this.marketList.setStyleProperty('max-height', '240px');
		this.marketList.setStyleProperty('overflow-y', 'auto');
		this.contactCard.section(MarketListSectionIndex).appendChild(this.marketList);
		this.contactCard.setSectionHeading('Markets', MarketListSectionIndex);
		this.objId = null;
		this.markets = new list<IMarket>();
		this.table = new DataTable();
		this.dia = new Dialog();
		this.dia.setContent(this.contactCard);
	}

	init(): void {
		setTimeout(async () => {
			this.items = new list<IItem>((await svc.catalog.item.list()).objects);
			this.markets = new list<IMarket>(await svc.group.market.list());
		}, 30);
		this.contactCard.onEvt(this.cardActionEvt);
		this.form.setId('id_object-form');
		let elem: Element | null = document.querySelector(View.CREATE_CTA_SELECTOR);
		if (elem) {
			elem.addEventListener('click', this.createCTAClickEvent);
		}
		document
			.querySelectorAll(View.OBJECT_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectClickEvent));
		document
			.querySelectorAll(View.AVAILABILITY_SELECTOR)
			.forEach(el => el.addEventListener('click', this.objectAvailabilityClickEvent));
	}

	async objectData(): Promise<IObjectData> {
		const objId = this.objectId();
		const obj = await svc.group.teamMember.get(objId);
		const emailAddresses = await svc.group.teamMember.emailAddressList(objId);
		const phoneNumbers = await svc.group.teamMember.phoneNumberList(objId);
		return {emailAddresses, obj, phoneNumbers};
	}

	objectId(): number {
		if (isNumber(this.objId)) {
			return this.objId;
		}
		throw new Error('View::objectID object ID is null');
	}

	protected async openCreateDialog(): Promise<void> {
		this.form.onSubmit();
		this.form.setAction(window.pburls.group.teamMember.list);
		this.form.showEmail();
		this.form.showPassword();
		this.form.firstNameInput.setValue('');
		this.form.lastNameInput.setValue('');
		this.form.emailInput.setValue('');
		this.form.passwordInput.setValue('');
		this.form.passwordConfirmInput.setValue('');
		this.form.isAdminInput.setChecked(false);
		this.form.show();
		this.contactCard.setIcon('person');
		this.contactCard.setToolbarButtonVisible('edit', false);
		this.contactCard.setToolbarButtonVisible('more', false);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(true);
		this.contactCard.setSectionVisible(false, ContactDetailsSectionIndex);
		this.contactCard.setSectionVisible(true, FormSectionIndex);
		this.contactCard.setSectionVisible(false, ItemListSectionIndex);
		this.contactCard.setSectionVisible(false, MarketListSectionIndex);
		this.contactCard.setHeading('Create team member');
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	protected exitState(which: ViewState): void {
		if (which === ViewState.DeleteState) {
			const headerEl = this.contactCard.headerHeadingHeaderEl();
			if (headerEl) {
				headerEl.removeStyleProperty('color');
			}
		}
		super.exitState(which);
	}

	protected async openDeleteDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.dia.showFooter();
		this.contactCard.setHeading(`Delete ${data.obj.name}?`);
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(false);
		this.contactCard.setSectionVisible(false, ContactDetailsSectionIndex);
		this.contactCard.setSectionVisible(false, FormSectionIndex);
		this.contactCard.setSectionVisible(false, ItemListSectionIndex);
		this.contactCard.setSectionVisible(false, MarketListSectionIndex);
		const headerEl = this.contactCard.headerHeadingHeaderEl();
		if (headerEl) {
			headerEl.setStyleProperty('color', 'red');
		}
		this.dia.setStandardButtons(StandardButton.No | StandardButton.Yes);
		const noBtn = this.dia.button(StandardButton.No);
		if (noBtn) {
			noBtn.setRaised(true);
		}
		this.openDia();
	}

	protected async openDetailDialog(): Promise<void> {
		this.setCardData(await this.objectData());
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(true);
		this.contactCard.setSectionVisible(true, ContactDetailsSectionIndex);
		this.contactCard.setSectionVisible(false, FormSectionIndex);
		this.dia.hideFooter();
		this.openDia();
	}

	protected async openEditDialog(): Promise<void> {
		const data = await this.objectData();
		this.setCardData(data);
		this.form.onSubmit(this.editFormSubmitEvent);
		this.form.setAction('');
		this.form.firstNameInput.setValue(data.obj.firstName);
		this.form.lastNameInput.setValue(data.obj.lastName);
		this.form.hideEmail();
		this.form.hidePassword();
		this.form.isAdminInput.setChecked(data.obj.isAdmin);
		this.form.show();
		this.contactCard.setToolbarButtonVisible('edit', true);
		this.contactCard.setToolbarButtonVisible('more', true);
		this.contactCard.setToolbarButtonVisible('close', true);
		this.contactCard.setDetailVisible(true);
		this.contactCard.setSectionVisible(false, ContactDetailsSectionIndex);
		this.contactCard.setSectionVisible(true, FormSectionIndex);
		this.dia.showFooter();
		this.dia.setStandardButtons(StandardButton.Cancel | StandardButton.Save);
		const saveBtn = this.dia.button(StandardButton.Save);
		if (saveBtn) {
			saveBtn.setRaised(true);
			saveBtn.setType('submit');
			saveBtn.setAttribute('form', this.form.id());
		}
		this.openDia();
	}

	@bind
	private async cardActionEvt(evt: Evt): Promise<void> {
		if (evt instanceof IconButtonClickEvt) {
			switch (evt.iconButton().name()) {
				case 'edit':
					if (this.state === ViewState.EditState) {
						this.setState(ViewState.DetailState);
					} else {
						this.setState(ViewState.EditState);
					}
					break;
				case 'delete':
					this.setState(ViewState.DeleteState);
					break;
				case 'close':
					this.setState(ViewState.NoState);
					break;
			}
		} else if (evt.type() === Evt.Delete) {
			this.setState(ViewState.DeleteState);
		}
	}

	@bind
	private createCTAClickEvent() {
		this.setState(ViewState.CreateState);
	}

	protected async deleteObject(): Promise<void> {
		const data = await this.objectData();
		const objId = data.obj.id;
		await svc.group.teamMember.delete(objId);
		this.removeDocumentObject(objId);
		await super.deleteObject();
	}

	@bind
	private async editFormSubmitEvent(): Promise<void> {
		const id = this.objectId();
		const obj = await svc.group.teamMember.get(id);
		const firstName = this.form.firstNameInput.value();
		const lastName = this.form.lastNameInput.value();
		const isAdmin = this.form.isAdminInput.isChecked();
		const markets: Array<number> = [];
		for (const obj of this.marketList.querySelectorAll('input[type="checkbox"]:checked')) {
			const id = Number(obj.value());
			if (isNumber(id)) {
				markets.push(id);
			}
		}
		const itemExclusions: Array<number> = [];
		for (const obj of this.itemList.querySelectorAll('input[type="checkbox"][name="items"]')) {
			if (!obj.isChecked()) {
				const id = Number(obj.value());
				if (isNumber(id)) {
					itemExclusions.push(id);
				}
			}
		}

		const itemPostProd: Array<number> = [];
		for (const obj of this.itemList.querySelectorAll('input[type="checkbox"][name="itemsPostProd"]')) {
			if (obj.isChecked()) {
				const id = Number(obj.value());
				if (isNumber(id)) {
					itemPostProd.push(id);
				}
			}
		}

		const ob = await svc.group.teamMember.update(
			id,
			{
				...obj,
				firstName,
				lastName,
				isAdmin,
				itemExclusions,
				itemPostProd,
				markets,
			},
		);
		this.syncDocumentObject(ob);
		this.setState(ViewState.DetailState);
	}

	private *marketsForIDs(marketIDs: number[]): IterableIterator<IMarket> {
		const ids: set<number> = new set(marketIDs);
		for (const obj of this.markets) {
			if (ids.has(obj.id)) {
				yield obj;
			}
		}
	}

	@bind
	private async objectAvailabilityClickEvent(event: Event): Promise<void> {
		event.stopPropagation();
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::objectAvailabilityClickEvent event target is null');
			return;
		}
		const row = new El(closestMatchingElement(tgt, 'tr[data-row-id]'));
		const id = Number(row.attribute('data-row-id'));
		if (Number.isNaN(id)) {
			console.log('View::objectAvailabilityClickEvent event target data-row-id attribute did not yield valid value');
			return;
		}
		this.objId = id;
		let obj = await svc.group.teamMember.get(this.objId);
		obj = await svc.group.teamMember.update(
			id,
			{
				...obj,
				excludeAvailability: !obj.excludeAvailability,
			},
		);
		this.syncDocumentObject(obj);
		this.objId = null;
	}

	@bind
	private async objectClickEvent(event: Event): Promise<void> {
		const tgt = <HTMLElement | null>event.target;
		if (!tgt) {
			console.log('View::objectClickEvent event target is null');
			return;
		}
		const emailAnchorMaybe = closestMatchingElement(tgt, 'a[href^="mailto:"]');
		if (emailAnchorMaybe) {
			// Email anchor clicked. Ignore.
			return;
		}
		const elem = new El(closestMatchingElement(tgt, 'tr[data-row-id]'));
		const id = Number(elem.attribute('data-row-id'));
		if (Number.isNaN(id)) {
			console.log('View::objectClickEvent event target data-row-id attribute did not yield valid value');
			return;
		}
		this.objId = id;
		this.setState(ViewState.DetailState);
	}

	private removeDocumentObject(objectID: number): void {
		(new TableObject(this)).remove(objectID);
	}

	private setCardData(data: IObjectData): void {
		this.contactCard.clearContactDetails();
		this.contactCard.setHeading(data.obj.name);
		this.contactCard.setIcon(data.obj.isAdmin ? 'admin_panel_settings' : 'person');
		const subheadingText = data.obj.isAdmin ? 'Administrator' : '';
		const subheadingIsVisible = Boolean(subheadingText);
		this.contactCard.setSubheading(subheadingText, 0);
		this.contactCard.setSubheadingVisible(subheadingIsVisible, 0);
		this.contactCard.addEmailAddress(data.obj.email, data.obj.receiveNotifications);
		data.phoneNumbers.forEach(ph => this.contactCard.addPhoneNumber(ph.number));
		data.emailAddresses.forEach(addr => this.contactCard.addEmailAddress(addr.address, addr.receiveNotifications));
		this.itemList.clear();
		this.marketList.clear();
		if (window.isProducer) {
			this.setItemList(data.obj.itemExclusions, data.obj.itemPostProd);
			this.setMarketList(data.obj.markets);
		} else {
			this.contactCard.setSectionVisible(false, ItemListSectionIndex);
			this.contactCard.setSectionVisible(false, MarketListSectionIndex);
		}
	}

	private setItemList(excludedItemIds: Array<number>, postProdItemIds: Array<number>): void {
		const listItems = new list<Partial<ListItemOpts>>();
		const excludedIds = new set(excludedItemIds);
		const postProdIds = new set(postProdItemIds);
		const listItemLeadingEl: (ob: IItem) => El | string = (ob: IItem) => {
			if (this.state === ViewState.EditState) {
				const cont = new El({
					classNames: [
						'display--flex',
					],
					tagName: 'div',
				});
				new Checkbox({
					checked: !excludedIds.has(ob.id),
					name: 'items',
					parent: cont,
					value: String(ob.id),
				});
				new Checkbox({
					checked: postProdIds.has(ob.id),
					name: 'itemsPostProd',
					parent: cont,
					value: String(ob.id),
				});
				return cont;
			} else {
				return ob.icon;
			}
		};
		if (this.state === ViewState.EditState) {
			this.contactCard.setSectionHeading('Items [\u2713] Shoot [\u2713] Edit', ItemListSectionIndex);
			for (const obj of this.items) {
				if (obj.role !== ItemRole.Child) {
					listItems.append({
						leadingEl: <El>listItemLeadingEl(obj),
						text: obj.name,
						classNames: [
							'pb-list-item__start-detail-dbl-chk',
						],
					});
				}
			}
		} else {
			this.contactCard.setSectionHeading('Items', ItemListSectionIndex);
			for (const obj of this.items) {
				if ((obj.role !== ItemRole.Child) && (!excludedIds.has(obj.id))) {
					listItems.append({leadingIcon: <string>listItemLeadingEl(obj), text: obj.name});
				}
			}
		}
		if (listItems.isEmpty() && (this.state !== ViewState.EditState)) {
			listItems.append({leadingIcon: '', text: 'No items assigned'});
		}
		this.itemList.addItems(listItems);
	}

	private setMarketList(marketIds: Array<number>): void {
		const listItems = new list<Partial<ListItemOpts>>();
		const assignedIds = new set(marketIds);
		const listItemLeadingEl: (objId: number) => El | string = (objId: number) => {
			if (this.state === ViewState.EditState) {
				return new Checkbox({checked: assignedIds.has(objId), name: 'markets', value: String(objId)});
			} else {
				return 'pin_drop';
			}
		};
		if (this.state === ViewState.EditState) {
			for (const obj of this.markets) {
				listItems.append({leadingEl: <El>listItemLeadingEl(obj.id), text: obj.city});
			}
		} else {
			for (const obj of this.marketsForIDs(marketIds)) {
				listItems.append({leadingIcon: <string>listItemLeadingEl(obj.id), text: obj.city});
			}
		}
		if (listItems.isEmpty() && (this.state !== ViewState.EditState)) {
			listItems.append({leadingIcon: '', text: 'No markets assigned'});
		}
		this.marketList.addItems(listItems);
	}

	private syncDocumentObject(obj: IUser): void {
		(new TableObject(this)).setData(obj);
	}
}

export function init(): void {
	const comp = new View();
	comp.init();
}

import React, {PropsWithChildren} from 'react';
import {Corner} from '@material/menu-surface/constants';

import {bind, isNumber} from '../../../../util';
import {StandardButton} from '../../../../constants';
import {
	Menu,
	Modal,
	ListItem,
	IconButton,
	ListItemText,
	WickedCorner,
	ListItemDetail,
} from '../../../components';

interface IProps {
	buttons?: StandardButton;
	isOpen: boolean;
	maximized?: boolean;
	menuItems?: MenuItem[];
	onMenuItemSelect?: (result: MenuItem) => any;
	onResult?: (result: StandardButton) => any;
	title?: string;
}

type Props = PropsWithChildren<IProps>;

interface State {
	headerMenuIsOpen: boolean;
}

export default class Dialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			headerMenuIsOpen: false,
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if ((prevProps.isOpen !== this.props.isOpen) && this.state.headerMenuIsOpen) {
			this.setState(
				{
					headerMenuIsOpen: false,
				},
			);
		}
	}

	@bind
	headerMenuButtonClick(): void {
		this.setState(
			{
				headerMenuIsOpen: !this.state.headerMenuIsOpen,
			},
		);
	}

	@bind
	headerMenuClosed(): void {
		if (this.state.headerMenuIsOpen) {
			this.setState(
				{
					headerMenuIsOpen: false,
				},
			);
		}
	}

	headerMenuItems(): MenuItem[] {
		const {
			menuItems,
		} = this.props;
		return menuItems ?
			menuItems :
			[
				{
					icon: 'delete_outline',
					text: 'Delete',
				},
			];
	}

	@bind
	headerMenuSelect(index: number): void {
		const {
			onMenuItemSelect,
		} = this.props;
		if (onMenuItemSelect) {
			const objs = this.headerMenuItems();
			const obj = objs[index];
			if (!obj) {
				console.log('Dialog::headerMenuSelect indexing into item array did not return object');
				return;
			}
			onMenuItemSelect(obj);
		}
	}

	render(): React.ReactNode {
		const {
			buttons,
			children,
			isOpen,
			maximized,
			title,
		} = this.props;
		return (
			<Modal
				buttons={
					isNumber(buttons) ?
						buttons :
						(StandardButton.Cancel | StandardButton.Save)
				}
				className="pb-catalog-item-dialog"
				isOpen={isOpen}
				maximized={maximized}
				onResult={this.result}>
				<Header>
					{
						title ?
							<HeaderTitle>{title}</HeaderTitle> :
							null
					}
					<HeaderMenu
						isOpen={this.state.headerMenuIsOpen}
						menuItems={this.headerMenuItems()}
						onMenuButtonClick={this.headerMenuButtonClick}
						onMenuClose={this.headerMenuClosed}
						onMenuItemSelect={this.headerMenuSelect}
						corner={Corner.TOP_RIGHT}
						margins={
							{right: 120}
						}
					/>
				</Header>
				{children}
			</Modal>
		);
	}

	@bind
	result(btn: StandardButton): void {
		if (this.props.onResult) {
			this.props.onResult(btn);
		}
	}
}

function Header({children}: PropsWithChildren<{}>) {
	return (
		<div className="display--flex flex-direction--row justify-content--space-between align-items--center">
			{children}
		</div>
	);
}

function HeaderTitle({children}: PropsWithChildren<{}>) {
	return (
		<span>{children}</span>
	);
}

export type MenuItem = {
	icon?: string;
	text: string;
}

interface IHeaderMenuProps {
	corner?: WickedCorner;
	isOpen: boolean;
	margins?: Partial<IMargins>;
	menuItems: MenuItem[];
	onMenuButtonClick: () => any;
	onMenuItemSelect: (index: number) => any;
	onMenuClose?: () => any;
}

function HeaderMenu(props: IHeaderMenuProps) {
	const {
		corner,
		isOpen,
		margins,
		menuItems,
		onMenuButtonClick,
		onMenuClose,
		onMenuItemSelect,
	} = props;
	return (
		<div className="mdc-menu-surface--anchor">
			<IconButton
				className="pb-icon-button--size-20-5"
				icon="more_vert"
				onClick={onMenuButtonClick}
			/>
			{
				(menuItems.length > 0) ?
					<Menu
						corner={corner}
						isOpen={isOpen}
						margins={margins}
						onClose={onMenuClose}
						onSelect={onMenuItemSelect}>
						{
							menuItems.map(
								(obj, idx) =>
									<ListItem
										key={idx}
										leadingChild={
											obj.icon ?
												<ListItemDetail className="material-icons">{obj.icon}</ListItemDetail> :
												undefined
										}
										tabIndex={
											(idx === 0) ?
												0 :
												undefined
										}>
										<ListItemText primaryText={obj.text}/>
									</ListItem>,
							)
						}
					</Menu> :
					null
			}
		</div>
	);
}

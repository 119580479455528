import React from 'react';

import {TextField} from '../../../components';
import {bind} from '../../../../util';

interface IProps {
	onChange: (value: string, name: 'longDescription') => any;
	value: string;
}

export default class LongDescription extends React.Component<IProps, {}> {
	static Name: 'longDescription' = 'longDescription';

	@bind
	changeEvent(event: React.ChangeEvent): void {
		const {onChange} = this.props;
		onChange((event as React.ChangeEvent<HTMLInputElement>).target.value, this.name());
	}

	name(): 'longDescription' {
		return LongDescription.Name;
	}

	render(): React.ReactNode {
		const {value} = this.props;
		return <TextField
			className="width--100-percent"
			textArea={true}
			label="Long description"
			name={this.name()}
			onChange={this.changeEvent}
			value={value}/>;
	}
}

import React from 'react';

import {Switch} from '../../../components';
import LabeledControl from './labeledcontrol';

interface IProps {
	className?: string;
	htmlFor?: string;
	id?: string;
	isChecked: boolean;
	label?: string;
	name?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
}

export default class LabeledSwitch extends React.Component<IProps, {}> {
	render(): React.ReactNode {
		const {
			className,
			htmlFor,
			id,
			isChecked,
			label,
			name,
			onChange,
		} = this.props;
		return (
			<LabeledControl
				className={className}
				htmlFor={htmlFor}
				label={label}
				labelClassName="pb-catalog-item-form__switch-label">
				<Switch
					checked={isChecked}
					id={id}
					name={name}
					onChange={onChange}
				/>
			</LabeledControl>
		);
	}
}

import {Dialog} from '../../ui/dialog';
import {El} from '../../el';
import {AdditionalEmailTextInput} from '../../ui/additionalemailtextinput';

export class Dia extends Dialog {
	private addlTxt: AdditionalEmailTextInput | null;

	constructor(parent: El | null = null) {
		super(parent);
		this.addlTxt = null;
		this.initialize();
	}

	clearTextInput(): void {
		if (this.addlTxt) {
			this.addlTxt.clear();
		}
	}

	closeTextInput(): void {
		this.setTextInputOpen(false);
	}

	destroy(): void {
		if (this.addlTxt) {
			this.addlTxt.destroy();
		}
		super.destroy();
	}

	hideTextInput(): void {
		this.setTextInputVisible(false);
	}

	private initialize(): void {
		this.setMessage(' ');
		this.addlTxt = new AdditionalEmailTextInput();
		this.appendContent(this.addlTxt);
	}

	openTextInput(): void {
		this.setTextInputVisible(true);
		this.setTextInputOpen(true);
	}

	setTextInputOpen(open: boolean): void {
		if (this.addlTxt) {
			this.addlTxt.setOpen(open);
		}
	}

	setTextInputVisible(visible: boolean): void {
		if (this.addlTxt) {
			this.addlTxt.setVisible(visible);
		}
	}

	showTextInput(): void {
		this.setTextInputVisible(true);
	}

	textInputValue(): string {
		if (this.addlTxt) {
			return this.addlTxt.value();
		}
		return '';
	}
}

import {ItemService} from './item';
import {ChoiceService} from './choice';
import {PriceGroupService} from './pricegroup';
import {PriceGroupItemPriceService} from './pricegroupitemprice';
import {ExclusiveClientUserTypeItemService} from './exclusiveclientusertypeitem';

export class CatalogService {
	exclusiveItem: ExclusiveClientUserTypeItemService;
	itemChoice: ChoiceService;
	itemPrice: PriceGroupItemPriceService;
	item: ItemService;
	priceGroup: PriceGroupService;

	constructor() {
		this.exclusiveItem = new ExclusiveClientUserTypeItemService();
		this.itemChoice = new ChoiceService();
		this.itemPrice = new PriceGroupItemPriceService();
		this.item = new ItemService();
		this.priceGroup = new PriceGroupService();
	}
}

import React, {PropsWithChildren} from 'react';

import TextField from '../../../components/textfield';
import {CSS_CLASS_FULL_WIDTH} from '../../../../constants';

interface IProps {
	onChange: (value: string) => any;
	value: string;
}

type Props = PropsWithChildren<IProps>;

export default function Street({onChange, value}: Props) {
	function changeEvent(event: React.ChangeEvent): void {
		onChange((event as React.ChangeEvent<HTMLElement & {value: string;}>).target.value);
	}

	return (
		<div className="display--flex flex-direction--row align-items--center">
			<label htmlFor="street-addr-input-id" className="color--grayish margin-right--8" style={{minWidth: '48px'}}>
				Street
			</label>
			<TextField
				className={CSS_CLASS_FULL_WIDTH}
				id="street-addr-input-id"
				noLabel={true}
				onChange={changeEvent}
				placeholder="123 Ocean Boulevard"
				value={value}/>
		</div>
	);
}

import {setError} from './error';
import {apiService as svc} from '../../services';
import {
	RECEIVE_SUMMARY_DATA,
	RECEIVE_DURATION_DATA,
	SET_PROJECT_CREATE_STATE,
	RECEIVE_CREATED_PROJECT_DATA,
} from './types';

export function createProject(data: INewProjectData): FuncAction<Promise<ReceiveNewProjectDataAction>, ReceiveNewProjectDataAction> {
	return function (dispatch) {
		return svc.project.create(data)
			.then(data => dispatch(receiveCreatedProjectData(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function durationData(query: IDurationRequest): FuncAction<Promise<ReceiveDurationDataAction>, ReceiveDurationDataAction> {
	return function (dispatch) {
		return svc.project.duration(query)
			.then(data => dispatch(receiveDurationData(data)))
			.catch(err => dispatch(setError(err)));
	};
}

export function receiveCreatedProjectData(data: INewProjectResponse): ReceiveNewProjectDataAction {
	window.location.assign(data.redirectUrl);
	return {
		data,
		type: RECEIVE_CREATED_PROJECT_DATA,
	};
}

export function receiveDurationData(data: number): ReceiveDurationDataAction {
	return {
		data,
		type: RECEIVE_DURATION_DATA,
	};
}

export function receiveSummaryData(data: ISummaryData): ReceiveSummaryDataAction {
	return {
		data,
		type: RECEIVE_SUMMARY_DATA,
	};
}

export function setProjectCreateState(data: Partial<IProjectCreateState>): SetProjectCreateStateAction {
	return {
		data,
		type: SET_PROJECT_CREATE_STATE,
	};
}

export function summaryData(query: ISummaryRequest): FuncAction<Promise<ReceiveSummaryDataAction>, ReceiveSummaryDataAction> {
	return function (dispatch) {
		return svc.project.summary(query)
			.then(data => dispatch(receiveSummaryData(data)))
			.catch(err => dispatch(setError(err)));
	};
}

import React, {PropsWithChildren} from 'react';

import {bind, chunk} from '../../../../util';
import {GridCell} from '../../../components';
import {
	Row,
	Body,
	Cell,
	Label,
	CheckBox,
	FormField,
	Background,
	CheckTable,
	InputContainer,
} from './checktable';
import {set} from '../../../../tools';

interface IProps {
	checkedChoiceIDs: number[];
	item: ICatalogItem;
	choices: IChoice[];
	onChange: (itemID: number, choiceID: number, checked: boolean) => any;
}

type Props = PropsWithChildren<IProps>;

export default class Choices extends React.Component<Props, {}> {
	@bind
	change(value: string | number, checked: boolean): void {
		const {item, onChange} = this.props;
		onChange(item.id, value as number, checked);
	}

	checkedItemChoiceIds(): number[] {
		const {checkedChoiceIDs, choices} = this.props;
		const choiceIds = new set<number>(choices.map(c => c.id));
		return checkedChoiceIDs.filter(c => choiceIds.has(c));
	}

	isChecked(id: number): boolean {
		return this.checkedItemChoiceIds().indexOf(id) >= 0;
	}

	isDisabled(id: number): boolean {
		const {item: {limitChoiceCount, maxChoices}} = this.props;
		if (!limitChoiceCount) {
			return false;
		}
		return (this.checkedItemChoiceIds().length >= maxChoices) && !this.isChecked(id);
	}

	render(): React.ReactNode {
		const {item} = this.props;
		const checkedChoiceIDs = this.checkedItemChoiceIds();
		return (
			<React.Fragment>
				<GridCell>
					<Header
						itemName={item.name}
						limitChoiceCount={item.limitChoiceCount}
						maxChoices={item.maxChoices}
						selectedCount={checkedChoiceIDs.length}/>
				</GridCell>
				<GridCell>
					<CheckTable>
						<Body>
							{this.rows().map((row, rIdx) =>
								<Row key={rIdx}>
									{row.map((choice, cIdx) =>
										<Cell colSpan={(row.length === 1) ? 2 : 1} key={cIdx}>
											<Input
												checked={this.isChecked(choice.id)}
												choice={choice}
												disabled={this.isDisabled(choice.id)}
												onChange={this.change}/>
										</Cell>)}
								</Row>)}
						</Body>
					</CheckTable>
				</GridCell>
			</React.Fragment>
		);
	}

	rows(): IChoice[][] {
		const {choices} = this.props;
		return chunk(choices, 2);
	}
}

function Header({itemName, limitChoiceCount, maxChoices, selectedCount}: PropsWithChildren<{itemName: string; limitChoiceCount: boolean; maxChoices: number; selectedCount: number;}>) {
	return (
		<div className="display--flex justify-content--center align-items--center color--grayish">
			{itemName} Choices{limitChoiceCount ? <SelectionCount current={selectedCount} max={maxChoices}/> : null}
		</div>
	);
}

function Input({checked, choice, disabled, onChange}: PropsWithChildren<{checked: boolean; choice: IChoice; disabled: boolean; onChange: (value: string | number, checked: boolean) => any;}>) {
	const id = `item-${choice.itemId}-choice-${choice.id}`;
	return (
		<FormField disabled={disabled}>
			<InputContainer disabled={disabled}>
				<CheckBox
					checked={checked}
					disabled={disabled}
					id={id}
					onChange={onChange}
					title={choice.name}
					value={choice.id}/>
				<Background/>
			</InputContainer>
			<Label htmlFor={id}>
				{choice.name}
			</Label>
		</FormField>
	);
}

function SelectionCount({current, max}: PropsWithChildren<{current: number; max: number;}>) {
	const className = `padding-left--8${(current >= max) ? ' color--black' : ''}`;
	return (
		<small className={className}>({current}/{max})</small>
	);
}

import {clamp, padStart} from '.';
import {
	SECONDS_IN_DAY,
	SECONDS_IN_HOUR,
	SECONDS_IN_MINUTE,
} from '../constants';

export function genTimes(startHour: number = 0, endHour: number = 23, inclusive: boolean = true): ITime[] {
	const maxHour = inclusive ? 23 : 24;
	startHour = clamp(startHour, 0, maxHour);
	endHour = clamp(endHour, 0, maxHour);
	const upperBound = inclusive ? endHour + 1 : endHour;
	const rv: ITime[] = [];
	for (let i = startHour; i < upperBound; ++i) {
		rv.push({
			hour: i,
			isoformat: `${padStart(i, 2, '0')}:00:00`,
			minute: 0,
			second: 0,
		});
	}
	return rv;
}

export function makeTime(hour: number = 0, minute: number = 0, second: number = 0): ITime {
	const isoformat = `${padStart(hour, 2, '0')}:${padStart(minute, 2, '0')}:${padStart(second, 2, '0')}`;
	return {
		hour,
		minute,
		second,
		isoformat,
	};
}

export function secondsToDuration(totalSeconds: number): IDuration {
	let tot: number = totalSeconds;
	if (tot < 1) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};
	}
	const days = Math.floor(tot / SECONDS_IN_DAY);
	tot -= (days * SECONDS_IN_DAY);
	const hours = Math.floor(tot / SECONDS_IN_HOUR);
	tot -= (hours * SECONDS_IN_HOUR);
	const minutes = Math.floor(tot / SECONDS_IN_MINUTE);
	tot -= (minutes * SECONDS_IN_MINUTE);
	const seconds = Math.floor(tot);
	return {
		days,
		hours,
		minutes,
		seconds,
	};
}

/**
 * @param {number} hour - An hour from 24-hour time
 * @return {number} 12-hour time equivalent
 */
export function to12Hour(hour: number): number {
	if ((hour === 0) || (hour === 12)) {
		return 12;
	}
	return hour % 12;
}

/**
 * @param {number} hour - An hour from 12-hour time
 * @param {Period} period
 * @return {number} 24-hour time equivalent
 */
export function to24Hour(hour: number, period: Period): number {
	const isAM = (period === 'AM');
	if (hour === 12) {
		return isAM ? 0 : 12;
	}
	return isAM ? hour : hour + 12;
}

/**
 * @param {number} hour - An hour from 24-hour time
 * @return {Period} 12-hour time period ('AM' or 'PM')
 */
export function toPeriod(hour: number): Period {
	return hour < 12 ? 'AM' : 'PM';
}

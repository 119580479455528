import {
	EMPTY_BUGGY,
	ADD_ITEM_TO_BUGGY,
	REMOVE_ITEM_FROM_BUGGY,
	ADD_ITEM_ADDON_TO_BUGGY,
	ADD_ITEM_CHOICE_TO_BUGGY,
	REMOVE_ITEM_ADDON_FROM_BUGGY,
	REMOVE_ITEM_CHOICE_FROM_BUGGY,
} from './types';

type BuggyItemAction = DataAction<number, typeof ADD_ITEM_TO_BUGGY | typeof REMOVE_ITEM_FROM_BUGGY>;
type BuggyItemAddOnAction = DataAction<{itemID: number; addOnID: number;}, typeof ADD_ITEM_ADDON_TO_BUGGY | typeof REMOVE_ITEM_ADDON_FROM_BUGGY>;
type BuggyItemChoiceAction = DataAction<{itemID: number; choiceID: number; isDefaultAction: boolean;}, typeof ADD_ITEM_CHOICE_TO_BUGGY | typeof REMOVE_ITEM_CHOICE_FROM_BUGGY>;
type EmptyBuggyAction = Action<typeof EMPTY_BUGGY>;

export type BuggyActionTypes = BuggyItemAction | BuggyItemAddOnAction | BuggyItemChoiceAction | EmptyBuggyAction;

export function addItemAddOnToBuggy(itemID: number, addOnID: number): BuggyActionTypes {
	return {
		data: {itemID, addOnID},
		type: ADD_ITEM_ADDON_TO_BUGGY,
	};
}

export function addItemChoiceToBuggy(itemID: number, choiceID: number, isDefaultAction: boolean): BuggyActionTypes {
	return {
		data: {itemID, choiceID, isDefaultAction},
		type: ADD_ITEM_CHOICE_TO_BUGGY,
	};
}

export function addItemToBuggy(itemID: number): BuggyActionTypes {
	return {
		data: itemID,
		type: ADD_ITEM_TO_BUGGY,
	};
}

export function emptyBuggy(): BuggyActionTypes {
	return {
		type: EMPTY_BUGGY,
	};
}

export function removeItemAddOnFromBuggy(itemID: number, addOnID: number): BuggyActionTypes {
	return {
		data: {itemID, addOnID},
		type: REMOVE_ITEM_ADDON_FROM_BUGGY,
	};
}

export function removeItemChoiceFromBuggy(itemID: number, choiceID: number, isDefaultAction: boolean): BuggyActionTypes {
	return {
		data: {itemID, choiceID, isDefaultAction},
		type: REMOVE_ITEM_CHOICE_FROM_BUGGY,
	};
}

export function removeItemFromBuggy(itemID: number): BuggyActionTypes {
	return {
		data: itemID,
		type: REMOVE_ITEM_FROM_BUGGY,
	};
}

import React, {PropsWithChildren} from 'react';

import {bind, chunk, cssClassName} from '../../util';
import {CloudChart, PrecipChart} from './charts';

interface Overlay {
	index: number;
	text: string;
}

interface IProps {
	dates?: DateAvailability[] | IDate[];
	onClick?: (index: number | null) => any;
	overlay?: Overlay;
	weather?: IDateWeather[];
}

type Props = IProps;

function columnIndex(linearIndex: number, rowIndex: number): number {
	return linearIndex - Calendar.ROW_LENGTH * rowIndex;
}

function isIDateArray(obj: any): obj is IDate[] {
	if (Array.isArray(obj)) {
		if (obj.length < 1) {
			return true;
		}
		const first = obj[0];
		if (first && ('isoformat' in first)) {
			return true;
		}
	}
	return false;
}

function linearIndex(rowIndex: number, columnIndex: number): number {
	return columnIndex + Calendar.ROW_LENGTH * rowIndex;
}

function partitionChartData(wx?: IWeather[]): {cloud: {cloud_cov: number;}[]; precip: {prob_precip: number;}[]} {
	if (wx) {
		const cloud: {cloud_cov: number;}[] = [];
		const precip: {prob_precip: number;}[] = [];
		for (let i = 0; i < wx.length; ++i) {
			const w = wx[i];
			cloud.push({cloud_cov: w.cloudCov});
			precip.push({prob_precip: w.probPrecip});
		}
		return {cloud, precip};
	}
	return {
		cloud: [],
		precip: [],
	};
}

function validRowIndexForLinearIndex(linearIndex: number, rowIndex: number): boolean {
	const colIdx = columnIndex(linearIndex, rowIndex);
	return (colIdx >= 0) && (colIdx < Calendar.ROW_LENGTH);
}

export default class Calendar extends React.Component<Props, {}> {
	static ROW_LENGTH: number = 3;

	availabilityDates(): DateAvailability[] {
		const {dates} = this.props;
		if (dates) {
			if (isIDateArray(dates)) {
				return dates.map(d => ({date: d, available: true}));
			}
			return dates;
		}
		return [];
	}

	@bind
	dayClicked(index: number): void {
		const {onClick} = this.props;
		if (onClick) {
			onClick(index);
		}
	}

	dayClickHandler(rowIndex: number): ((idx: number) => any) | undefined {
		return this.props.onClick ?
			(idx: number) => this.dayClicked(linearIndex(rowIndex, idx)) :
			undefined;
	}

	dayOverlay(rowIndex: number): Overlay | undefined {
		const {overlay} = this.props;
		if (overlay && validRowIndexForLinearIndex(overlay.index, rowIndex)) {
			return {
				index: columnIndex(overlay.index, rowIndex),
				text: overlay.text,
			};
		}
		return undefined;
	}

	dateRows(): DateAvailability[][] {
		return chunk(this.availabilityDates(), Calendar.ROW_LENGTH);
	}

	render() {
		return (
			<div className="pb-calendar">
				{this.dateRows().map((row, idx) =>
					<Row
						dates={row}
						key={idx}
						onClick={this.dayClickHandler(idx)}
						overlay={this.dayOverlay(idx)}
						wx={this.weatherForDate}
					/>)}
			</div>
		);
	}

	@bind
	weatherForDate(isoDate: string): IWeather[] | undefined {
		const {weather} = this.props;
		if (weather) {
			for (let i = 0; i < weather.length; ++i) {
				const d = weather[i];
				if (d.date === isoDate) {
					return d.weather;
				}
			}
		}
		return undefined;
	}
}

interface DayProps {
	className?: string;
	interactive?: boolean;
	onClick?: () => any;
	selected?: boolean;
	unavailable?: boolean;
}

function Day({children, className, interactive, onClick, selected, unavailable}: PropsWithChildren<DayProps>) {
	const conditionalClassNames = {
		'pb-calendar__day--interactive': interactive,
		'pb-calendar__day--selected': selected,
		'pb-calendar__day--unavailable': unavailable,
	};
	return (
		<div className={cssClassName('pb-calendar__day', conditionalClassNames, className)} onClick={onClick}>
			{children}
		</div>
	);
}

function Date({day, name}: {day?: number; name?: string;}) {
	return (
		<div className="pb-calendar__date">
			<span className="pb-calendar__date__weekday">{name}</span>
			<span className="pb-calendar__date__day-of-month">{day}</span>
		</div>
	);
}

function Wx({cloud, children, precip}: PropsWithChildren<{cloud: {cloud_cov: number;}[]; precip: {prob_precip: number;}[]}>) {
	return (
		<div className="pb-calendar__wx">
			<div className="pb-calendar__sun"/>
			<div className="pb-calendar__wx-conditions">
				<CloudChart data={cloud}/>
			</div>
			<div className="pb-calendar__wx-conditions">
				<PrecipChart data={precip}/>
			</div>
			{children}
		</div>
	);
}

function DayPeriod() {
	return (
		<div className="pb-calendar__day-period">
			<span>AM</span>
			<span>NOON</span>
			<span>PM</span>
		</div>
	);
}

function Row({dates, onClick, overlay, wx}: {dates: DateAvailability[]; onClick?: (idx: number) => any; overlay?: Overlay; wx?: (isoFormat: string) => (IWeather[] | undefined)}) {
	return (
		<div className="pb-calendar__row">
			{dates.map((d, idx) =>
				<Day interactive={Boolean(d.available && onClick)} key={idx} onClick={onClick ? () => onClick(idx) : undefined} selected={Boolean(overlay && (overlay.index === idx))} unavailable={!d.available}>
					<Date day={d.date.day} name={d.date.weekdayNameAbbr}/>
					<Wx {...partitionChartData(wx ? wx(d.date.isoformat) : undefined)}>
						{d.available ?
							null :
							<DayLabel>
								UNAVAILABLE
							</DayLabel>}
						{(overlay && (overlay.index === idx)) ?
							<SelectedDayLabel>
								{overlay.text}
							</SelectedDayLabel> :
							null}
					</Wx>
				</Day>)}
			<DayPeriod/>
		</div>
	);
}

function DayLabel({children}: PropsWithChildren<{}>) {
	return (
		<div className="pb-calendar__day-label">
			{children}
		</div>
	);
}

function SelectedDayLabel({children}: PropsWithChildren<{}>) {
	return (
		<DayLabel>
			<span>{children}</span>
			<i className="material-icons pb-calendar__day-label__icon">check</i>
		</DayLabel>
	);
}

import {SearchFilterComboBox, SearchFilterComboBoxItem} from './searchfiltercombobox';
import {Evt, EvtDispatcher} from '../../evt';
import {bind, pixelString} from '../../util';
import {ComboBoxChangeEvt} from '../../ui/combobox';
import {AutoCompleter, AutoCompleterSelectEvt} from '../../ui/autocompleter';
import {SearchInput, SearchInputEvt} from './searchinput';
import {Key, ProjectStatus, SearchFilterType} from '../../constants';
import {Chip, ChipEvt, ChipOpts, ChipSet} from '../../ui/chipset';
import {El, elOpts} from '../../el';
import {ProjectStatusSegmentedButton, ProjectStatusSegmentedButtonEvt} from './statusbutton';

export enum SearchMode {
	DefaultInputMode,
	FilterInputMode,
}

export enum SearchEvtType {
	SearchModeChange = 8001,
	SearchFilterTypeChange,
	InputChange,
	AutoComplete,
}

export class SearchEvt extends Evt {
	static SearchFilterTypeChange: SearchEvtType.SearchFilterTypeChange = SearchEvtType.SearchFilterTypeChange;
	static SearchModeChange: SearchEvtType.SearchModeChange = SearchEvtType.SearchModeChange;
	static InputChange: SearchEvtType.InputChange = SearchEvtType.InputChange;
	static AutoComplete: SearchEvtType.AutoComplete = SearchEvtType.AutoComplete;

	private m: SearchMode;
	private t: SearchFilterType;

	constructor(type: number, searchMode: SearchMode, filterType: SearchFilterType = SearchFilterType.UNKNOWN) {
		super(type);
		this.m = searchMode;
		this.t = filterType;
	}

	searchFilterType(): SearchFilterType {
		return this.t;
	}

	searchMode(): SearchMode {
		return this.m;
	}
}

export class SearchAutoCompleteEvt extends SearchEvt {
	private c: string;
	private i: number;

	constructor(searchMode: SearchMode, filterType: SearchFilterType, completion: string, index: number) {
		super(SearchEvtType.AutoComplete, searchMode, filterType);
		this.c = completion;
		this.i = index;
	}

	completion(): string {
		return this.c;
	}

	index(): number {
		return this.i;
	}
}

export class SearchFilterEvt extends Evt {
	private f: IUIFilter;

	constructor(type: number, filter: IUIFilter) {
		super(type);
		this.f = filter;
	}

	filter(): IUIFilter {
		return this.f;
	}
}

class SearchChip extends Chip {
	constructor(opts: Partial<ChipOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts>, parent?: El | null);
	constructor(opts?: Partial<ChipOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ChipOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ChipOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'pb-project-search-chip',
			...classNames,
		];
		super(opts);
	}
}

const textFilterItem: SearchFilterComboBoxItem = {
	listItemOptions: {leadingIcon: '', text: ''},
	value: SearchFilterType.TEXT,
};
const filterItems: Array<SearchFilterComboBoxItem> = [
	textFilterItem,
	{listItemOptions: {leadingIcon: 'person', text: 'Client'}, value: SearchFilterType.USER},
	{listItemOptions: {leadingIcon: 'groups', text: 'Group'}, value: SearchFilterType.GROUP},
];

const defaultFilterTypeIconName = 'search';
const filterTypeIconMap = new Map<SearchFilterType, string>([
	[SearchFilterType.UNKNOWN, defaultFilterTypeIconName],
	[SearchFilterType.TEXT, defaultFilterTypeIconName],
	[SearchFilterType.USER, 'person'],
	[SearchFilterType.GROUP, 'groups'],
]);

export class Search extends EvtDispatcher {
	private autoCompleter: AutoCompleter | null;
	private chipFilterMap: Map<SearchChip, IUIFilter>;
	private chipSet: ChipSet | null;
	private currentFilterComboBoxItemIndex: number;
	private filterComboBox: SearchFilterComboBox | null;
	private filterItemIndexObjectMap: Map<number, SearchFilterComboBoxItem>;
	private input: SearchInput | null;
	private lastInputValue: string;
	private mode: SearchMode;
	private statusFilterMap: Map<ProjectStatus, IUIFilter>;
	private statusToggle: ProjectStatusSegmentedButton | null;

	constructor() {
		super();
		this.autoCompleter = null;
		this.chipFilterMap = new Map<SearchChip, IUIFilter>();
		this.chipSet = null;
		this.currentFilterComboBoxItemIndex = 0;
		this.filterComboBox = null;
		this.filterItemIndexObjectMap = new Map<number, SearchFilterComboBoxItem>();
		this.input = null;
		this.lastInputValue = '';
		this.mode = SearchMode.DefaultInputMode;
		this.statusFilterMap = new Map<ProjectStatus, IUIFilter>();
		this.statusToggle = null;
		this.initialize();
	}

	private addChipFilter(filter: IUIFilter): void {
		if (this.chipSet) {
			const searchFilter = SearchFilter.decode(filter.value);
			const chip = this.chipSet.addChip({
				leadingIcon: this.iconNameForFilterType(searchFilter.type),
				selected: searchFilter.enabled,
				text: filter.text,
				trailingIcon: 'cancel',
			});
			this.chipFilterMap.set(chip, filter);
			this.setChipSetContainerVisible(true);
		}
	}

	addFilter(filter: IUIFilter): void {
		if (filter.attribute === 'status') {
			this.addStatusFilter(filter);
		} else {
			this.addChipFilter(filter);
		}
	}

	private addStatusFilter(filter: IUIFilter): void {
		if (this.statusToggle) {
			const searchFilter = SearchFilter.decode(filter.value);
			if (searchFilter.value in ProjectStatus) {
				const status = <ProjectStatus>Number(searchFilter.value);
				this.statusFilterMap.set(status, filter);
				this.statusToggle.setStatusSelected(status, searchFilter.enabled);
			}
		}
	}

	@bind
	private autoCompleterEvt(evt: Evt): void {
		if ((evt.type() === Evt.Select) && (evt instanceof AutoCompleterSelectEvt)) {
			this.autoCompleterSelectEvent(evt);
		}
	}

	private autoCompleterSelectEvent(evt: AutoCompleterSelectEvt): void {
		this.notifyEvt(new SearchAutoCompleteEvt(
			this.mode,
			this.currentSearchFilterType(),
			evt.completion(),
			evt.index()));
	}

	private chipFilter(chip: SearchChip): IUIFilter | null {
		return this.chipFilterMap.get(chip) || null;
	}

	@bind
	private chipSetEvt(evt: Evt): void {
		if (!this.chipSet || !(evt instanceof ChipEvt)) {
			return;
		}
		switch (evt.type()) {
			case Evt.MouseButtonClick: {
				this.updateChipFilter(evt.chip());
				break;
			}
			case Evt.Delete: {
				this.deleteChipFilter(evt.chip());
				break;
			}

			default:
				return;
		}
	}

	private currentFilterComboBoxItem(): SearchFilterComboBoxItem | null {
		const item = this.filterItemIndexObjectMap.get(this.currentFilterComboBoxItemIndex);
		return item || null;
	}

	private currentSearchFilterType(): SearchFilterType {
		const item = this.currentFilterComboBoxItem();
		if (item) {
			return item.value;
		}
		return SearchFilterType.UNKNOWN;
	}

	private deleteChipFilter(chip: SearchChip): void {
		const filter = this.chipFilterMap.get(chip);
		this.chipFilterMap.delete(chip);
		if (filter) {
			this.notifyEvt(new SearchFilterEvt(Evt.Delete, filter));
		} else {
			console.log('Filter for chip was not found');
		}
		if (this.chipSet) {
			this.chipSet.deleteChip(chip);
		}
		if (this.searchFilterCount() < 1) {
			this.setChipSetContainerVisible(false);
		}
	}

	destroy(): void {
		this.destroyEventListeners();
		this.destroyAutoCompleter();
		this.destroyFilterComboBox();
		this.destroyInput();
		this.destroyStatusToggle();
		this.destroyChipSet();
		this.chipFilterMap.clear();
		this.currentFilterComboBoxItemIndex = 0;
		this.filterItemIndexObjectMap.clear();
		this.lastInputValue = '';
		this.mode = SearchMode.DefaultInputMode;
		this.statusFilterMap.clear();
		super.destroy();
	}

	private destroyAutoCompleter(): void {
		if (this.autoCompleter) {
			this.autoCompleter.offEvt(this.autoCompleterEvt);
			this.autoCompleter.destroy();
		}
		this.autoCompleter = null;
	}

	private destroyChipSet(): void {
		if (this.chipSet) {
			this.chipSet.offEvt(this.chipSetEvt);
			this.chipSet.destroy();
		}
		this.chipSet = null;
	}

	private destroyEventListeners(): void {
		window.removeEventListener('keydown', this.windowEvent, true);
		window.removeEventListener('resize', this.windowEvent);
	}

	private destroyFilterComboBox(): void {
		if (this.filterComboBox) {
			this.filterComboBox.offEvt(this.filterComboBoxEvt);
			this.filterComboBox.destroy();
		}
		this.filterComboBox = null;
	}

	private destroyInput(): void {
		if (this.input) {
			this.input.offEvt(this.inputEvt);
			this.input.destroy();
		}
		this.input = null;
	}

	private destroyStatusToggle(): void {
		if (this.statusToggle) {
			this.statusToggle.offEvt(this.statusToggleEvt);
			this.statusToggle.destroy();
		}
		this.statusToggle = null;
	}

	private doesAutoCompleterContainFocus(): boolean {
		return Boolean(this.autoCompleter && this.autoCompleter.isOpen() && this.autoCompleter.containsFocus());
	}

	*enabledFilters(): IterableIterator<IUIFilter> {
		for (const obj of this.iterFilters()) {
			if (SearchFilter.isEnabled(obj)) {
				yield obj;
			}
		}
	}

	private enterSearchMode(mode: SearchMode): void {
		switch (mode) {
			case SearchMode.DefaultInputMode: {
				this.setFilterComboBoxOptionIndex(0);
				break;
			}
			case SearchMode.FilterInputMode: {
				break;
			}
		}
	}

	private exitSearchMode(mode: SearchMode): void {
		switch (mode) {
			case SearchMode.DefaultInputMode:
				break;
			case SearchMode.FilterInputMode: {
				this.destroyAutoCompleter();
				this.setInputValue('');
				break;
			}
			default:
				break;
		}
	}

	private filterComboBoxChangeEvt(evt: ComboBoxChangeEvt): void {
		const idx = evt.index();
		const item = this.filterItemIndexObjectMap.get(idx);
		if (item === undefined) {
			console.log('filterComboBoxChangeEvt: undefined returned for key %s', idx);
			return;
		}
		this.currentFilterComboBoxItemIndex = idx;
		this.setSearchMode(this.filterItemSearchMode(item));
		this.setFilterComboBoxLeadingIcon((item === textFilterItem) ?
			SearchFilterComboBox.DefaultLeadingIconName :
			this.iconNameForFilterType(this.currentSearchFilterType()));
		this.focusInputDelayed();
		this.notifyEvt(new SearchEvt(
			SearchEvtType.SearchFilterTypeChange,
			this.mode,
			this.currentSearchFilterType()));
	}

	@bind
	private filterComboBoxEvt(evt: Evt): void {
		if ((evt.type() === Evt.Change) && (evt instanceof ComboBoxChangeEvt)) {
			this.filterComboBoxChangeEvt(evt);
		}
	}

	private filterItemSearchMode(item: SearchFilterComboBoxItem): SearchMode {
		return (item.value === SearchFilterType.UNKNOWN) ?
			SearchMode.DefaultInputMode :
			SearchMode.FilterInputMode;
	}

	private focusFirstAutoCompleterItem(): void {
		if (this.autoCompleter) {
			this.autoCompleter.focusFirstItem();
		}
	}

	private focusInput(): void {
		this.input && this.input.focus();
	}

	private focusInputDelayed(): void {
		setTimeout(() => this.focusInput(), 75);
	}

	private hideAutoCompleter(): void {
		if (this.autoCompleter) {
			this.autoCompleter.hide();
		}
	}

	private hideAutoCompleterIfNotContainsFocus(): void {
		if (!this.autoCompleter) {
			return;
		}
		if (this.autoCompleter.isOpen() && !this.autoCompleter.containsFocus()) {
			this.hideAutoCompleter();
		}
	}

	private iconNameForFilterType(type: SearchFilterType): string {
		return filterTypeIconMap.get(type) || defaultFilterTypeIconName;
	}

	private initialize(): void {
		this.input = new SearchInput();
		this.input.onEvt(this.inputEvt);
		this.filterComboBox = new SearchFilterComboBox();
		for (let i = 0; i < filterItems.length; ++i) {
			const item = filterItems[i];
			if (item === textFilterItem) {
				// This hack is because (again) Google software developers are
				// kind of crap. The `value` attribute is replaced with the
				// empty string so the "MDCSelect" component will do the right
				// thing when the default option is the current option.
				this.filterComboBox.addItem({...item, value: ''});
			} else {
				this.filterComboBox.addItem(item);
			}
			this.filterItemIndexObjectMap.set(i, item);
		}
		this.filterComboBox.onEvt(this.filterComboBoxEvt);
		this.statusToggle = new ProjectStatusSegmentedButton();
		this.statusToggle.onEvt(this.statusToggleEvt);
		this.chipSet = new ChipSet({
			chipPrototype: SearchChip,
			isFilterChipSet: true,
			root: document.getElementById('id_project-search-filters-container'),
		});
		this.chipSet.onEvt(this.chipSetEvt);
		this.initializeEventListeners();
	}

	private initializeAutoCompleter(items?: Iterable<string>): void {
		if (!this.autoCompleter) {
			this.autoCompleter = new AutoCompleter();
			this.autoCompleter.onEvt(this.autoCompleterEvt);
		}
		if (items) {
			this.autoCompleter.setItems(items);
		}
		if (this.input) {
			this.autoCompleter.appendToBody();
			this.updateAutoCompleterPosition(this.input.rect());
		}
	}

	private initializeEventListeners(): void {
		window.addEventListener('keydown', this.windowEvent, true);
		window.addEventListener('resize', this.windowEvent);
	}

	private inputArrowDownKeyPressEvt(evt: SearchInputEvt): void {
		if (this.isAutoCompleterOpen()) {
			evt.accept();
			this.focusFirstAutoCompleterItem();
		}
	}

	private inputEscapeKeyPressEvt(): void {
		if (this.isAutoCompleterOpen()) {
			this.hideAutoCompleter();
		} else {
			if (this.mode === SearchMode.FilterInputMode) {
				this.setSearchMode(SearchMode.DefaultInputMode);
			}
		}
	}

	@bind
	private inputEvt(evt: Evt): void {
		if (!this.input || !(evt instanceof SearchInputEvt)) {
			return;
		}
		switch (evt.type()) {
			case SearchInputEvt.TextChange: {
				this.inputTextChangeEvt(evt);
				break;
			}
			case SearchInputEvt.KeyPress: {
				this.inputKeyPressEvt(evt);
				break;
			}
			case SearchInputEvt.FocusIn: {
				this.inputFocusInEvt();
				break;
			}
			case SearchInputEvt.FocusOut: {
				this.inputFocusOutEvt();
				break;
			}
			case SearchInputEvt.TrailingIcon: {
				this.inputTrailingIconEvt();
				break;
			}
		}
	}

	private inputFocusInEvt(): void {
		this.showAutoCompleterIfInputValueMatchesPrefix();
	}

	private inputFocusOutEvt(): void {
		setTimeout(() => this.hideAutoCompleterIfNotContainsFocus(), 23);
	}

	private inputKeyPressEvt(evt: SearchInputEvt): void {
		switch (evt.key()) {
			case Key.Enter: {
				this.notifyEvt(new SearchEvt(
					Evt.Submit,
					this.mode,
					this.currentSearchFilterType()));
				break;
			}
			case Key.Escape: {
				this.inputEscapeKeyPressEvt();
				break;
			}
			case Key.ArrowDown: {
				this.inputArrowDownKeyPressEvt(evt);
				break;
			}
		}
	}

	private inputTextChangeEvt(evt: SearchInputEvt): void {
		const currVal = evt.text();
		const lastVal = this.lastInputValue;
		this.lastInputValue = currVal;
		switch (this.mode) {
			case SearchMode.DefaultInputMode: {
				if ((currVal.trim().length > 0) || (lastVal.trim().length > 0)) {
					this.notifyEvt(new SearchEvt(SearchEvtType.InputChange, this.mode));
				}
				break;
			}
			case SearchMode.FilterInputMode: {
				this.updateAutoCompleter(evt.text());
				break;
			}
		}
	}

	private inputTrailingIconEvt(): void {
		this.notifyEvt(new SearchEvt(
			Evt.Submit,
			this.mode,
			this.currentSearchFilterType()));
	}

	inputValue(): string {
		if (this.input) {
			return this.input.value();
		}
		return '';
	}

	private isAutoCompleterOpen(): boolean {
		return Boolean(this.autoCompleter && this.autoCompleter.isOpen());
	}

	private *iterFilters(): IterableIterator<IUIFilter> {
		yield *this.statusFilterMap.values();
		yield *this.chipFilterMap.values();
	}

	private searchFilterCount(): number {
		if (this.chipSet) {
			return this.chipSet.chips().size();
		}
		return 0;
	}

	setAutoCompleterEnabled(enable: boolean, items?: Iterable<string>): void {
		if (enable === Boolean(this.autoCompleter)) {
			if (enable && items) {
				this.setAutoCompleterItems(items);
			}
			return;
		}
		if (enable) {
			this.initializeAutoCompleter(items);
		} else {
			this.destroyAutoCompleter();
		}
	}

	setAutoCompleterItems(items: Iterable<string>): void {
		if (this.autoCompleter) {
			this.autoCompleter.setItems(items);
			this.updateAutoCompleter(this.inputValue());
		}
	}

	private setChipSetContainerVisible(visible: boolean): void {
		const el = El.body().querySelector('#id_project-search-filters-container');
		if (el) {
			el.setVisible(visible);
		}
	}

	private setFilterComboBoxLeadingIcon(iconName: string): void {
		if (this.filterComboBox) {
			this.filterComboBox.setLeadingIcon(iconName);
		}
	}

	private setFilterComboBoxOptionIndex(index: number): void {
		if (this.filterComboBox) {
			this.filterComboBox.setSelectedIndex(index);
		}
	}

	setInputValue(value?: string): void {
		if (this.input) {
			this.input.setValue(value || '');
		}
	}

	setSearchMode(mode: SearchMode): void {
		if (mode === this.mode) {
			return;
		}
		this.exitSearchMode(this.mode);
		this.mode = mode;
		this.enterSearchMode(this.mode);
		this.notifyEvt(new SearchEvt(
			SearchEvtType.SearchModeChange,
			this.mode,
			this.currentSearchFilterType()));
	}

	private showAutoCompleterIfInputValueMatchesPrefix(): void {
		if (!this.autoCompleter) {
			return;
		}
		if (!this.autoCompleter.isOpen() && (this.autoCompleter.completionCount() > 0) && (this.autoCompleter.completionPrefix() === this.inputValue())) {
			this.autoCompleter.show();
		}
	}

	private statusFilter(status: ProjectStatus): IUIFilter | null {
		return this.statusFilterMap.get(status) || null;
	}

	@bind
	private statusToggleEvt(evt: Evt): void {
		if ((evt.type() === Evt.Change) && (evt instanceof ProjectStatusSegmentedButtonEvt)) {
			const d = evt.data();
			this.updateStatusFilter(d.projectStatus, d.selected);
		}
	}

	private updateAutoCompleter(prefix: string): void {
		if (this.autoCompleter) {
			this.autoCompleter.setPrefix(prefix);
		}
	}

	private updateAutoCompleterPosition(referenceRect: DOMRect): void {
		if (this.autoCompleter) {
			const bodyAbsY = Math.abs(El.body().rect().y);
			const {height, width, x, y} = referenceRect;
			this.autoCompleter.setStyleProperty('top', pixelString(y + height + bodyAbsY));
			this.autoCompleter.setStyleProperty('left', pixelString(x - 1));
			this.autoCompleter.setStyleProperty('width', pixelString(width));
		}
	}

	private updateChipFilter(chip: SearchChip): void {
		const enabled = !chip.isSelected();
		chip.setSelected(enabled);
		const filter = this.chipFilter(chip);
		if (filter) {
			this.updateFilter(filter, enabled);
		}
	}

	private updateFilter(filter: IUIFilter, enabled: boolean): void {
		filter.value = SearchFilter
			.decode(filter.value)
			.replace({enabled}).encode();
		this.notifyEvt(new SearchFilterEvt(Evt.Change, filter));
	}

	private updateStatusFilter(status: ProjectStatus, enabled: boolean): void {
		const filter = this.statusFilter(status);
		if (filter) {
			this.updateFilter(filter, enabled);
		}
	}

	@bind
	private windowEvent(event: Event): void {
		switch (event.type) {
			case 'keydown': {
				this.windowKeyPressEvent(<KeyboardEvent>event);
				break;
			}
			case 'resize': {
				this.windowResizeEvent();
				break;
			}
		}
	}

	private windowArrowUpKeyPressEvent(): void {
		if (this.input && this.autoCompleter && this.autoCompleter.containsFocus()) {
			if (this.autoCompleter.currentRow() === 0) {
				this.focusInput();
			}
		}
	}

	private windowEscapeKeyPressEvent(): void {
		if (this.doesAutoCompleterContainFocus()) {
			this.hideAutoCompleter();
			this.focusInputDelayed();
		}
	}

	private windowKeyPressEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case Key.ArrowUp:
				this.windowArrowUpKeyPressEvent();
				break;
			case Key.Escape:
				this.windowEscapeKeyPressEvent();
				break;
		}
	}

	private windowResizeEvent(): void {
		if (this.input && this.isAutoCompleterOpen()) {
			this.updateAutoCompleterPosition(this.input.rect());
		}
	}
}

enum SearchFilterState {
	OFF = 'OFF',
	ON = 'ON',
}

export class SearchFilter {
	static ENCODING_SEPARATOR: string = '___';

	static decode(encodedValue: string): SearchFilter {
		let enabled: boolean = false;
		let type: SearchFilterType = SearchFilterType.UNKNOWN;
		let value: string = '';
		const encodedParts = this.encodedValueParts(encodedValue);
		if (encodedParts.length === 2) {
			// e.g.: Project status filter: "4___OFF" ('4' is ProjectStatus.OnHold)
			const [valStr, stateStr] = encodedParts;
			value = valStr;
			enabled = this.decodeState(stateStr);
		} else if (encodedParts.length === 3) {
			// e.g.:         Text search filter: "TEXT___Ben___OFF" (equivalent to entering "Ben" in search input)
			//       Client Group search filter: "GROUP___8___OFF"  (equivalent to selecting group with ID 8 from
			//                                                       within search input while using filter option)
			const [typeStr, valStr, stateStr] = encodedParts;
			if (typeStr in SearchFilterType) {
				type = <SearchFilterType>typeStr;
			}
			value = valStr;
			enabled = this.decodeState(stateStr);
		}
		return new this(enabled, type, value);
	}

	private static decodeState(state: string): boolean {
		return state === SearchFilterState.ON;
	}

	private static encodedValueParts(encodedValue: string): Array<string> {
		return encodedValue.split(this.ENCODING_SEPARATOR);
	}

	static isEnabled(filter: IUIFilter): boolean {
		return this.decode(filter.value).enabled;
	}

	readonly enabled: boolean;
	readonly type: SearchFilterType;
	readonly value: string;

	constructor(enabled: boolean, type: SearchFilterType, value: number | string) {
		this.enabled = enabled;
		this.type = type;
		this.value = String(value);
	}

	encode(): string {
		const state = this.enabled ?
			SearchFilterState.ON :
			SearchFilterState.OFF;
		const parts = (this.type === SearchFilterType.UNKNOWN) ?
			[this.value, state] :
			[this.type, this.value, state];
		return parts.join(SearchFilter.ENCODING_SEPARATOR);
	}

	replace(data: Partial<{enabled: boolean; type: SearchFilterType; value: string;}>): SearchFilter {
		const enabled = (data.enabled === undefined) ? this.enabled : data.enabled;
		const type = (data.type === undefined) ? this.type : data.type;
		const value = (data.value === undefined) ? this.value : data.value;
		return new SearchFilter(enabled, type, value);
	}

	toString(): string {
		return this.encode();
	}
}

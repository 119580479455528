import React, {PropsWithChildren} from 'react';

import SizeInput from './sizeinput';
import IconButtonHeading from './iconbuttonheading';

interface IProps {
	onChange: (value: number | null, name: 'size') => any;
	size: number | null;
}

type Props = PropsWithChildren<IProps>;

interface State {
	renderTextInput: boolean;
}

export default class SizeView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.buttonClick = this.buttonClick.bind(this);
		this.state = {renderTextInput: false};
	}

	buttonClick(): void {
		const {renderTextInput} = this.state;
		this.setState({renderTextInput: !renderTextInput});
	}

	render(): React.ReactNode {
		const {renderTextInput} = this.state;
		const {onChange, size} = this.props;
		return (
			<React.Fragment>
				<IconButtonHeading
					icon={renderTextInput ? 'text_fields' : 'linear_scale'}
					onClick={this.buttonClick}
					text="Size"
					title={renderTextInput ? 'Switch to slider' : 'Switch to text input'}/>
				<SizeInput
					onChange={onChange}
					renderTextInput={renderTextInput}
					value={size}/>
			</React.Fragment>
		);
	}
}

import {initialState} from './state';
import {
	RECEIVE_SUMMARY_DATA,
	RECEIVE_DURATION_DATA,
	SET_PROJECT_CREATE_STATE,
	RECEIVE_GROUP_PRODUCER_CATALOG,
} from '../actions/types';

const initial: IProjectCreateState = initialState('projectCreate');

export default function (state: IProjectCreateState = initial, action: Action): IProjectCreateState {
	switch (action.type) {
		case SET_PROJECT_CREATE_STATE:
			return {
				...state,
				...(<SetProjectCreateStateAction>action).data,
			};
		case RECEIVE_GROUP_PRODUCER_CATALOG:
			return {
				...state,
				duration: 0,
				size: null,
				summary: null,
			};
		case RECEIVE_DURATION_DATA:
			return {
				...state,
				duration: (<ReceiveDurationDataAction>action).data,
			};
		case RECEIVE_SUMMARY_DATA:
			return {
				...state,
				summary: {...(<ReceiveSummaryDataAction>action).data},
			};
		default:
			return state;
	}
}

import React from 'react';

import LabeledSwitch from './labeledswitch';
import {bind} from '../../../../util';

interface IProps {
	onChange: (value: boolean, name: 'exclusive') => any;
	value: boolean;
}

export default class IsExclusive extends React.Component<IProps, {}> {
	static Name: 'exclusive' = 'exclusive';

	@bind
	changeEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		this.props.onChange(
			event.target.checked,
			this.name(),
		);
	}

	name(): 'exclusive' {
		return IsExclusive.Name;
	}

	render(): React.ReactNode {
		return <LabeledSwitch
			htmlFor="id_isExclusive"
			id="id_isExclusive"
			isChecked={this.props.value}
			label="Can be ordered with any base services"
			name={this.name()}
			onChange={this.changeEvent}/>;
	}
}

import {list} from '../tools';
import {CaseSensitivity, MatchFlag} from '../constants';

class Match {
	index: number;
	value: string;

	constructor(index: number, value: string) {
		this.index = index;
		this.value = value;
	}
}

export class Completer {
	private candidates: list<string>;
	private caseSen: CaseSensitivity;
	private currRow: number;
	private matches: list<Match>;
	private mode: MatchFlag;
	private prefix: string;

	constructor(items?: Iterable<string>) {
		this.candidates = new list<string>(items);
		this.caseSen = CaseSensitivity.CaseSensitive;
		this.currRow = -1;
		this.matches = new list<Match>();
		this.mode = MatchFlag.MatchStartsWith;
		this.prefix = '';
	}

	caseSensitivity(): CaseSensitivity {
		return this.caseSen;
	}

	completionCount(): number {
		return this.matches.size();
	}

	completionPrefix(): string {
		return this.prefix;
	}

	currentCompletion(): string {
		if (this.currRow >= 0) {
			return this.matches.at(this.currRow).value;
		}
		return '';
	}

	currentIndex(): number {
		if (this.currRow >= 0) {
			return this.matches.at(this.currRow).index;
		}
		return -1;
	}

	currentRow(): number {
		return this.currRow;
	}

	destroy(): void {
		this.invalidate();
		this.candidates.clear();
		this.caseSen = CaseSensitivity.CaseSensitive;
		this.mode = MatchFlag.MatchStartsWith;
		this.prefix = '';
	}

	private filter(item: string): void {
		this.invalidate();
		if (this.caseSen === CaseSensitivity.CaseInsensitive) {
			item = item.toLocaleLowerCase();
		}
		for (let i = 0; i < this.candidates.size(); ++i) {
			const candidate = this.candidates.at(i);
			const s = (this.caseSen === CaseSensitivity.CaseInsensitive) ?
				candidate.toLocaleLowerCase() :
				candidate;
			let matched: boolean = false;
			switch (this.mode) {
				case MatchFlag.MatchStartsWith:
					matched = s.startsWith(item);
					break;
				case MatchFlag.MatchContains:
					matched = s.indexOf(item) >= 0;
					break;
				case MatchFlag.MatchEndsWith:
					matched = s.endsWith(item);
					break;
			}
			if (matched) {
				this.matches.append(new Match(i, candidate));
			}
		}
		this.currRow = this.matches.isEmpty() ? -1 : 0;
	}

	filterMode(): MatchFlag {
		return this.mode;
	}

	private invalidate(): void {
		this.currRow = -1;
		this.matches = new list<Match>();
	}

	items(): list<string> {
		return this.candidates;
	}

	setCaseSensitivity(cs: CaseSensitivity): void {
		if (cs === this.caseSen) {
			return;
		}
		this.caseSen = cs;
		this.invalidate();
	}

	setCompletionPrefix(prefix: string): void {
		this.prefix = prefix;
		this.filter(this.prefix);
	}

	setCurrentRow(row: number): boolean {
		const matchCount = this.matches.size();
		if ((row < 0) || (matchCount < 1) || (row >= matchCount)) {
			return false;
		}
		this.currRow = row;
		return true;
	}

	setFilterMode(mode: MatchFlag): void {
		if (mode === this.mode) {
			return;
		}
		this.mode = mode;
		this.invalidate();
	}

	setItems(items: Iterable<string>): void {
		this.invalidate();
		this.candidates = new list<string>(items);
	}
}

import React, {PropsWithChildren} from 'react';

import {cssClassName} from '../../../util';

interface IProps extends React.HTMLAttributes<any> {
	numeric?: boolean;
}

type Props = PropsWithChildren<IProps>;

export default class DataTableCell extends React.Component<Props, {}> {
	render(): React.ReactNode {
		const {children, className, numeric, ...attrs} = this.props;
		const clsName = `mdc-data-table__cell${numeric ? ' mdc-data-table__cell--numeric' : ''}`;
		return (
			<td className={cssClassName(clsName, className)} {...attrs}>
				{children}
			</td>
		);
	}
}

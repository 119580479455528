import {MDCChip, MDCChipSet} from '@material/chips';

import {El, elOpts, ElOpts} from '../el';
import {bind} from '../util';
import {list, Point} from '../tools';
import {Evt, EvtType, MouseEvt} from '../evt';
import {KeyboardModifier, MouseButton} from '../constants';

export interface ChipSetOpts extends ElOpts {
	chipPrototype: typeof Chip;
	isFilterChipSet: boolean;
	isInputChipSet: boolean;
}

export class ChipSet extends El {
	private m_chips: list<Chip>;
	private chipPrototype: typeof Chip;
	private ctrl: MDCChipSet | null;

	constructor(opts: Partial<ChipSetOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipSetOpts> | null, parent?: El | null);
	constructor(opts: Partial<ChipSetOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipSetOpts>, parent?: El | null);
	constructor(opts?: Partial<ChipSetOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ChipSetOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ChipSetOpts>(a, b, c);
		const attrs: Array<[string, string]> = opts.attributes ?
			Array.from(opts.attributes) :
			[];
		attrs.push(['role', 'grid']);
		opts.attributes = attrs;
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-chip-set',
			'pb-chip-set',
			...classNames,
		];
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.chipPrototype = opts.chipPrototype || Chip;
		this.m_chips = new list<Chip>();
		this.ctrl = null;
		if (opts.isFilterChipSet) {
			this.addClass('mdc-chip-set--filter');
		}
		if (opts.isInputChipSet) {
			this.addClass('mdc-chip-set--input');
		}
		const root = this.element();
		if (root) {
			this.ctrl = new MDCChipSet(root);
		}
	}

	addChip(opts?: Partial<ChipOpts>): Chip {
		return this.insertChip(-1, opts);
	}

	@bind
	protected chipEvt(evt: Evt): void {
		this.notifyEvt(evt);
	}

	chips(): list<Chip> {
		return this.m_chips;
	}

	clear(): void {
		for (const obj of this.m_chips) {
			this.deleteChip(obj);
		}
		this.m_chips.clear();
	}

	deleteChip(chip: Chip): void {
		const idx = this.m_chips.indexOf(chip);
		if (idx >= 0) {
			const obj = this.m_chips.takeAt(idx);
			obj.destroy();
		}
	}

	destroy(): void {
		this.clear();
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
		super.destroy();
	}

	insertChip(index: number, opts?: Partial<ChipOpts>): Chip {
		if (index < 0) {
			index = this.m_chips.size();
		}
		opts = opts || {};
		opts.isFilterChip = this.isFilterChipSet();
		opts.parent = null;
		const chip = new this.chipPrototype(opts);
		this.insertChild(index, chip);
		chip.onEvt(this.chipEvt);
		this.m_chips.append(chip);
		return chip;
	}

	private isFilterChipSet(): boolean {
		return this.hasClass('mdc-chip-set--filter');
	}

	removeChip(chip: Chip): void {
		const idx = this.m_chips.indexOf(chip);
		if (idx >= 0) {
			this.m_chips.removeAt(idx);
			chip.destroy();
		}
	}

	selectedChips(): list<Chip> {
		const rv = new list<Chip>();
		for (const chip of this.m_chips) {
			if (chip.isSelected()) {
				rv.append(chip);
			}
		}
		return rv;
	}
}

export interface ChipOpts extends ElOpts {
	isFilterChip: boolean;
	leadingIcon: string;
	selected: boolean;
	text: string;
	trailingIcon: string;
}

export class ChipEvt extends Evt {
	private c: Chip;

	constructor(type: EvtType, chip: Chip) {
		super(type);
		this.c = chip;
	}

	chip(): Chip {
		return this.c;
	}
}

export class ChipMouseEvt extends MouseEvt {
	static fromMouseEvent(instance: Chip, event: MouseEvent): ChipMouseEvt {
		const {
			button,
			buttons,
			modifiers,
			pos,
			type,
		} = this.fromEventParts(event);
		return new this(instance, type, pos, button, buttons, modifiers);
	}

	private i: Chip;

	constructor(instance: Chip, type: number, clientPos: Point, button: MouseButton, buttons: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, clientPos, button, buttons, modifiers);
		this.i = instance;
	}

	chip(): Chip {
		return this.i;
	}
}

export class Chip extends El {
	protected ctrl: MDCChip | null;

	constructor(opts: Partial<ChipOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ChipOpts>, parent?: El | null);
	constructor(opts?: Partial<ChipOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ChipOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ChipOpts>(a, b, c);
		if (!(opts.root || opts.tagName)) {
			opts.tagName = 'div';
		}
		const attrs: Array<[string, string]> = opts.attributes ?
			Array.from(opts.attributes) :
			[];
		attrs.push(['role', 'row']);
		opts.attributes = attrs;
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-chip',
			'pb-chip',
			...classNames,
		];
		super(opts);
		const rippleEl = El.div({
			classNames: 'mdc-chip__ripple',
			parent: this,
		});
		let leadingIconEl: El | null = null;
		if (opts.leadingIcon) {
			leadingIconEl = new El({
				classNames: [
					'material-icons',
					'mdc-chip__icon',
					'mdc-chip__icon--leading',
				],
				parent: this,
				tagName: 'i',
			});
			if (opts.isFilterChip && opts.selected) {
				leadingIconEl.addClass('mdc-chip__icon--leading-hidden');
			}
			leadingIconEl.setText(opts.leadingIcon);
		}
		if (opts.isFilterChip) {
			const checkmark = El.div({
				classNames: 'mdc-chip__checkmark',
				parent: this,
			});
			const svg = El.svg({
				classNames: 'mdc-chip__checkmark-svg',
				parent: checkmark,
			});
			svg.setAttribute('viewBox', '-2 -3 30 30');
			const path = El.path({
				classNames: 'mdc-chip__checkmark-path',
				parent: svg,
			});
			path.setAttribute([
				['fill', 'none'],
				['stroke', 'black'],
				['d', 'M1.73,12.91 8.1,19.28 22.79,4.59'],
			]);
			(leadingIconEl || rippleEl).insertAdjacentElement('afterend', checkmark);
		}
		const gridcell = El.div({
			attributes: [['role', 'gridcell']],
			parent: this,
		});
		const btn = El.div({
			attributes: [
				['role', opts.isFilterChip ? 'checkbox' : 'button'],
			],
			classNames: 'mdc-chip__primary-action',
			parent: gridcell,
		});
		if (opts.isFilterChip) {
			btn.setAttribute('aria-checked', 'false');
		}
		const txt = El.span({
			classNames: 'mdc-chip__text',
			parent: btn,
		});
		if (opts.text) {
			txt.setText(opts.text);
		}
		if (opts.trailingIcon) {
			const gridcell = El.div({
				attributes: [['role', 'gridcell']],
				parent: this,
			});
			const i = new El({
				attributes: [
					['tabindex', '-1'],
					['role', 'button'],
				],
				classNames: [
					'material-icons',
					'mdc-chip__icon',
					'mdc-chip__icon--trailing',
					'mdc-chip-trailing-action',
				],
				parent: gridcell,
				tagName: 'i',
			});
			i.setText(opts.trailingIcon);
		}
		this.ctrl = null;
		this.setupCtrl();
		this.setSelected(Boolean(opts.selected));
	}

	@bind
	private ctrlEvent(event: Event): void {
		switch (event.type) {
			case 'MDCChip:interaction':
				this.notifyEvt(new ChipEvt(Evt.MouseButtonClick, this));
				break;
			case 'MDCChip:selection':
				break;
			case 'MDCChip:removal':
				this.notifyEvt(new ChipEvt(Evt.Delete, this));
				break;
			case 'MDCChip:trailingIconInteraction':
				if (this.ctrl) {
					if (!this.isSelected()) {
						// This is a hack because Google is awful
						this.setSelected(true);
					}
					this.ctrl.beginExit();
				}
				break;
			case 'MDCChip:navigation':
				break;
		}
	}

	destroy(): void {
		this.destroyCtrl();
		super.destroy();
	}

	private destroyCtrl(): void {
		if (this.ctrl) {
			this.removeEventListener('MDCChip:interaction', this.ctrlEvent);
			this.removeEventListener('MDCChip:selection', this.ctrlEvent);
			this.removeEventListener('MDCChip:removal', this.ctrlEvent);
			this.removeEventListener('MDCChip:trailingIconInteraction', this.ctrlEvent);
			this.removeEventListener('MDCChip:navigation', this.ctrlEvent);
			this.ctrl.destroy();
			this.ctrl = null;
		}
	}

	isFilterChip(): boolean {
		return Boolean(this.querySelector('.mdc-chip__checkmark'));
	}

	isSelected(): boolean {
		if (this.ctrl) {
			return this.ctrl.selected;
		}
		return this.hasClass('mdc-chip--selected');
	}

	protected leadingIconEl(): El | null {
		return this.querySelector('.mdc-chip__icon--leading');
	}

	protected primaryActionEl(): El | null {
		return this.querySelector('.mdc-chip__primary-action');
	}

	setLeadingIconName(iconName: string): void {
		const el = this.leadingIconEl();
		if (el) {
			el.setText(iconName);
		}
	}

	setSelected(selected: boolean): void {
		this.setClass(
			selected,
			'mdc-chip--selected',
			'pb-chip--selected');
		if (this.isFilterChip()) {
			const axn = this.primaryActionEl();
			if (axn) {
				axn.setAttribute(
					'aria-checked',
					selected ?
						'true' :
						'false');
			}
		}
		if (this.ctrl) {
			this.ctrl.selected = selected;
		}
	}

	setText(text?: string | null): void {
		const el = this.textEl();
		if (el) {
			el.setText(text);
		}
	}

	private setupCtrl(): void {
		if (!this.ctrl) {
			const root = this.element();
			if (root) {
				this.addEventListener('MDCChip:interaction', this.ctrlEvent);
				this.addEventListener('MDCChip:selection', this.ctrlEvent);
				this.addEventListener('MDCChip:removal', this.ctrlEvent);
				this.addEventListener('MDCChip:trailingIconInteraction', this.ctrlEvent);
				this.addEventListener('MDCChip:navigation', this.ctrlEvent);
				this.ctrl = new MDCChip(root);
				this.ctrl.shouldRemoveOnTrailingIconClick = false;
			}
		}
	}

	text(): string {
		const el = this.textEl();
		return el ? el.text() : '';
	}

	protected textEl(): El | null {
		return this.querySelector('.mdc-chip__text');
	}
}

import {CaseSensitivity, CheckIndexOption, ItemDataRole, ItemFlag, MatchFlag, Orientation, SortOrder} from './constants';
import {assert} from './util';
import {list, multihash, stack} from './tools';
import {Variant} from './variant';
import {Evt} from './evt';

export enum LayoutChangeHint {
	NoLayoutChangeHint,
	VerticalSortHint,
	HorizontalSortHint,
}

export class ModelIndex {
	column: number;
	i: unknown;
	model: AbstractItemModel | null;
	row: number;

	constructor(row: number = -1, column: number = -1, model: AbstractItemModel | null = null, ptr: unknown = null) {
		this.column = column;
		this.row = row;
		this.model = model;
		this.i = ptr;
	}

	cmp(other: ModelIndex): number {
		if ((this.row < other.row) || ((this.row === other.row) && (this.column < other.column))) {
			return -1;
		}
		if ((other.row === this.row) && (other.column === this.column) && (other.model === this.model) && (other.i === this.i)) {
			return 0;
		}
		return 1;
	}

	data(role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		return this.model ? this.model.data(this, role) : new Variant();
	}

	eq(other: ModelIndex): boolean {
		return this.cmp(other) === 0;
	}

	flags(): ItemFlag {
		return this.model ?
			this.model.flags(this) :
			ItemFlag.NoItemFlags;
	}

	gt(other: ModelIndex): boolean {
		return this.cmp(other) > 0;
	}

	internalPointer(): any {
		return this.i;
	}

	isValid(): boolean {
		return (this.row >= 0) && (this.column >= 0) && (this.model !== null);
	}

	lt(other: ModelIndex): boolean {
		return this.cmp(other) < 0;
	}

	ne(other: ModelIndex): boolean {
		return !this.eq(other);
	}

	parent(): ModelIndex {
		return this.model ?
			this.model.parent(this) :
			new ModelIndex();
	}

	sibling(row: number, column: number): ModelIndex {
		return this.model ?
			((this.row === row) && (this.column === column)) ?
				this :
				this.model.sibling(row, column, this) :
			new ModelIndex();
	}

	siblingAtColumn(column: number): ModelIndex {
		return this.model ?
			(this.column === column) ?
				this :
				this.model.sibling(this.row, column, this) :
			new ModelIndex();
	}

	siblingAtRow(row: number): ModelIndex {
		return this.model ?
			(this.row === row) ?
				this :
				this.model.sibling(row, this.column, this) :
			new ModelIndex();
	}

	toString(): string {
		const {column, row} = this;
		return `ModelIndex(${row}, ${column})`;
	}
}

class PersistentModelIndexData {
	static create(index: ModelIndex): PersistentModelIndexData {
		// We will _never_ insert an invalid index in the list
		assert(index.isValid());
		const model = <AbstractItemModel>index.model; // Valid index has valid pointer to model
		let d: PersistentModelIndexData;
		const indexes = model.persistent.indexes;
		const data = Array.from(indexes.find(index));
		if (data.length > 0) {
			d = data[data.length - 1];
		} else {
			d = new PersistentModelIndexData(index);
			data.push(d);
		}
		assert(d);
		return d;
	}

	static destroy(data: PersistentModelIndexData): void {
		assert(data);
		assert(data.ref === null);
		const model = data.index.model;
		// A valid persistent model index with a null model pointer can only
		// happen if the model was destroyed
		if (model) {
			model.removePersistentIndexData(data);
		}
	}

	index: ModelIndex;
	ref: unknown | null;

	constructor(index: ModelIndex) {
		this.index = index;
		this.ref = null;
	}
}

export class PersistentModelIndex implements Ieq {
	d: PersistentModelIndexData | null;

	constructor(other?: PersistentModelIndex | ModelIndex) {
		this.d = null;
		if (other) {
			if (other instanceof PersistentModelIndex) {
				this.d = other.d;
				other.d = null;
			} else {
				if (other.isValid()) {
					this.d = PersistentModelIndexData.create(other);
				}
			}
		}
	}

	cast(): ModelIndex {
		if (this.d) {
			return this.d.index;
		}
		return new ModelIndex();
	}

	column(): number {
		if (this.d) {
			return this.d.index.column;
		}
		return -1;
	}

	data(role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		if (this.d) {
			return this.d.index.data(role);
		}
		return new Variant();
	}

	destroy(): void {
		if (this.d) {
			PersistentModelIndexData.destroy(this.d);
			this.d = null;
		}
	}

	eq(other: PersistentModelIndex | ModelIndex): boolean {
		if (other instanceof PersistentModelIndex) {
			if (this.d && other.d) {
				return this.d.index.eq(other.d.index);
			}
			return this.d === other.d;
		} else {
			if (this.d) {
				return this.d.index.eq(other);
			}
			return !other.isValid();
		}
	}

	flags(): ItemFlag {
		if (this.d) {
			return this.d.index.flags();
		}
		return 0;
	}

	internalPointer(): any {
		if (this.d) {
			return this.d.index.internalPointer();
		}
		return null;
	}

	isValid(): boolean {
		if (this.d) {
			return this.d.index.isValid();
		}
		return false;
	}

	lt(other: PersistentModelIndex): boolean {
		if (this.d && other.d) {
			return this.d.index.lt(other.d.index);
		}
		return false;
	}

	model(): AbstractItemModel | null {
		if (this.d) {
			return this.d.index.model;
		}
		return null;
	}

	ne(other: PersistentModelIndex | ModelIndex): boolean {
		return !this.eq(other);
	}

	parent(): ModelIndex {
		if (this.d) {
			return this.d.index.parent();
		}
		return new ModelIndex();
	}

	row(): number {
		if (this.d) {
			return this.d.index.row;
		}
		return -1;
	}

	sibling(row: number, column: number): ModelIndex {
		if (this.d) {
			return this.d.index.sibling(row, column);
		}
		return new ModelIndex();
	}

	toString(): string {
		const m = this.model();
		return `PersistentModelIndex(${this.row()}, ${this.column()}, ${m ? m.constructor.name : 'null'})`;
	}
}

type CmpFunc<ModelIndex> = (a: ModelIndex, b: ModelIndex) => number;
const defaultModelIndexCmp = (a: ModelIndex, b: ModelIndex) => a.cmp(b);

export class ModelIndexList extends list<ModelIndex> {
	constructor(size: number, value: ModelIndex, cmp?: CmpFunc<ModelIndex>);
	constructor(items?: Iterable<ModelIndex>, cmp?: CmpFunc<ModelIndex>);
	constructor(a?: number | Iterable<ModelIndex>, b?: ModelIndex | CmpFunc<ModelIndex>, c?: CmpFunc<ModelIndex>) {
		const args = list.constructorArguments(a, b, c);
		args.cmp = args.cmp || defaultModelIndexCmp;
		super(args);
	}
}

export enum ItemModelEvtType {
	ColumnsAboutToBeInserted,
	ColumnsAboutToBeMoved,
	ColumnsAboutToBeRemoved,
	ColumnsInserted,
	ColumnsMoved,
	ColumnsRemoved,
	DataChanged,
	HeaderDataChanged,
	LayoutAboutToBeChanged,
	LayoutChanged,
	ModelAboutToBeReset,
	ModelReset,
	RowsAboutToBeInserted,
	RowsAboutToBeMoved,
	RowsAboutToBeRemoved,
	RowsInserted,
	RowsMoved,
	RowsRemoved,
}

export class ItemModelSectionAddRemoveEvt extends Evt {
	private f: number;
	private l: number;
	private p: ModelIndex;

	constructor(type: ItemModelEvtType, parent: ModelIndex, first: number, last: number) {
		super(type);
		this.f = first;
		this.l = last;
		this.p = parent;
	}

	first(): number {
		return this.f;
	}

	last(): number {
		return this.l;
	}

	parent(): ModelIndex {
		return this.p;
	}
}

export class ItemModelColumnMoveEvt extends Evt {
	private dc: number;
	private dp: ModelIndex;
	private se: number;
	private sp: ModelIndex;
	private ss: number;

	constructor(type: ItemModelEvtType, sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationColumn: number) {
		super(type);
		this.dc = destinationColumn;
		this.dp = destinationParent;
		this.se = sourceEnd;
		this.sp = sourceParent;
		this.ss = sourceStart;
	}

	destinationColumn(): number {
		return this.dc;
	}

	destinationParent(): ModelIndex {
		return this.dp;
	}

	sourceEnd(): number {
		return this.se;
	}

	sourceParent(): ModelIndex {
		return this.sp;
	}

	sourceStart(): number {
		return this.ss;
	}
}

export class ItemModelRowMoveEvt extends Evt {
	private dp: ModelIndex;
	private dr: number;
	private se: number;
	private sp: ModelIndex;
	private ss: number;

	constructor(type: ItemModelEvtType, sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationRow: number) {
		super(type);
		this.dp = destinationParent;
		this.dr = destinationRow;
		this.se = sourceEnd;
		this.sp = sourceParent;
		this.ss = sourceStart;
	}

	destinationParent(): ModelIndex {
		return this.dp;
	}

	destinationRow(): number {
		return this.dr;
	}

	sourceEnd(): number {
		return this.se;
	}

	sourceParent(): ModelIndex {
		return this.sp;
	}

	sourceStart(): number {
		return this.ss;
	}
}

export class ItemModelLayoutEvt extends Evt {
	private h: LayoutChangeHint;
	private p: Array<PersistentModelIndex>;

	constructor(type: ItemModelEvtType, parents: Array<PersistentModelIndex>, hint: LayoutChangeHint) {
		super(type);
		this.h = hint;
		this.p = parents;
	}

	hint(): LayoutChangeHint {
		return this.h;
	}

	parents(): Array<PersistentModelIndex> {
		return this.p;
	}
}

export class ItemModelDataChangeEvt extends Evt {
	private br: ModelIndex;
	private r: Array<number>;
	private tl: ModelIndex;

	constructor(topLeft: ModelIndex, bottomRight: ModelIndex, roles: Array<number>) {
		super(ItemModelEvtType.DataChanged);
		this.br = bottomRight;
		this.r = roles;
		this.tl = topLeft;
	}

	bottomRight(): ModelIndex {
		return this.br;
	}

	roles(): Array<number> {
		return this.r;
	}

	topLeft(): ModelIndex {
		return this.tl;
	}
}

export class ItemModelHeaderDataChangeEvt extends Evt {
	private f: number;
	private l: number;
	private o: Orientation;

	constructor(orientation: Orientation, first: number, last: number) {
		super(ItemModelEvtType.HeaderDataChanged);
		this.f = first;
		this.l = last;
		this.o = orientation;
	}

	first(): number {
		return this.f;
	}

	last(): number {
		return this.l;
	}

	orientation(): Orientation {
		return this.o;
	}
}

export class ItemModelEvt extends Evt {
	constructor(type: ItemModelEvtType) {
		super(type);
	}
}

class Persistent {
	indexes: multihash<ModelIndex, PersistentModelIndexData> = new multihash<ModelIndex, PersistentModelIndexData>();
	invalidated: stack<Array<PersistentModelIndexData>> = new stack();
	moved: stack<Array<PersistentModelIndexData>> = new stack();

	destroy(): void {
		this.indexes.destroy();
		this.invalidated.destroy();
		this.moved.destroy();
	}
}

interface EvtListener {
	(evt: Evt): any;
}

export abstract class AbstractItemModel {
	static staticEmptyModel(): AbstractItemModel {
		return staticEmptyModel;
	}

	protected changes: stack<Change> = new stack<Change>();
	protected evtListeners: list<EvtListener> = new list<EvtListener>();
	persistent: Persistent = new Persistent();

	protected allowMove(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationStart: number, orientation: Orientation): boolean {
		// Returns whether a move operation is valid.
		//
		// A move operation is not allowed if it moves a continuous range of
		// rows to a destination within itself, or if it attempts to move a
		// row to one of its own descendants.
		if (destinationParent === srcParent) {
			// Don't move the range within itself.
			return !((destinationStart >= srcFirst) && (destinationStart <= (srcLast + 1)));
		}
		let destinationAncestor: ModelIndex = destinationParent;
		let pos: number = (orientation === Orientation.Vertical) ?
			destinationAncestor.row :
			destinationAncestor.column;
		while (true) {
			if (destinationAncestor.eq(srcParent)) {
				if ((pos >= srcFirst) && (pos <= srcLast)) {
					return false;
				}
				break;
			}
			if (!destinationAncestor.isValid()) {
				break;
			}
			pos = (orientation === Orientation.Vertical) ?
				destinationAncestor.row :
				destinationAncestor.column;
			destinationAncestor = destinationAncestor.parent();
		}
		return true;
	}

	abstract columnCount(parent?: ModelIndex): number;

	beginInsertColumns(parent: ModelIndex, first: number, last: number): void {
		// Begins a column insertion operation.
		//
		// When reimplementing insertColumns() in a subclass, you must call
		// this function BEFORE inserting data into the model's underlying
		// data store.
		//
		// The `parent` index corresponds to the parent into which the new
		// columns are inserted; `first` and `last` are the column numbers of
		// the new columns will have after they have been inserted.
		//
		// Note: This function emits the columnsAboutToBeInserted() signal
		// which connected views (or proxies) must handle before the data is
		// inserted. Otherwise, the views may end up in an invalid state.
		assert(first >= 0);
		assert(first <= this.columnCount(parent));
		assert(last >= first);
		this.changes.push(new Change(parent, first, last));
		this.columnsAboutToBeInserted(parent, first, last);
		this._columnsAboutToBeInserted(parent, first, last);
	}

	beginInsertRows(parent: ModelIndex, first: number, last: number): void {
		// Begins a row insertion operation.
		//
		// When reimplementing insertRows() in a subclass, you must call this
		// function BEFORE inserting data into the model's underlying data
		// store.
		//
		// The `parent` index corresponds to the parent into which the new
		// rows are inserted; `first` and `last` are the row numbers that the
		// new rows will have after they have been inserted.
		//
		// Note: This function emits the rowsAboutToBeInserted() signal which
		// connected views (or proxies) must handle before the data is
		// inserted. Otherwise, the views may end up in an invalid state.
		assert(first >= 0);
		assert(first <= this.rowCount(parent)); // == is allowed, to insert at the end
		assert(last >= first);
		this.changes.push(new Change(parent, first, last));
		this.rowsAboutToBeInserted(parent, first, last);
		this._rowsAboutToBeInserted(parent, first, last);
	}

	beginMoveColumns(sourceParent: ModelIndex, sourceFirst: number, sourceLast: number, destinationParent: ModelIndex, destinationColumn: number): boolean {
		// Begins a column move operation.
		//
		// When reimplementing a subclass, this method simplifies moving
		// entities in your model. This method is responsible for moving
		// persistent indexes in the model, which you would otherwise be
		// required to do yourself. Using beginMoveColumns and endMoveColumns
		// is an alternative to emitting layoutAboutToBeChanged and
		// layoutChanged directly along with changePersistentIndex.
		//
		// The `sourceParent` index corresponds to the parent from which the
		// columns are moved; `sourceFirst` and `sourceLast` are the first
		// and last column numbers of the columns to be moved. The
		// `destinationParent` index corresponds to the parent into which
		// those columns are moved. The `destinationChild` is the column to
		// which the columns will be moved. That is, the index at column
		// `sourceFirst` in `sourceParent` will become column
		// `destinationChild` in `destinationParent`, followed by all other
		// columns up to `sourceLast`.
		//
		// However, when moving columns down in the same parent (`sourceParent`
		// and `destinationParent` are equal), the columns will be placed
		// before the `destinationChild` index. That is, if you wish to move
		// columns 0 and 1 so they will become columns 1 and 2,
		// `destinationChild` should be 3. In this case, the new index for the
		// source column `i` (which is between `sourceFirst` and `sourceLast`)
		// is equal to
		// destinationChild - sourceLast - 1 + i.
		//
		// Note that if `sourceParent` and `destinationParent` are the same,
		// you must ensure that the `destinationChild` is not within the range
		// of `sourceFirst` and `sourceLast` + 1 ou must also ensure that you
		// do not attempt to move a column to one of its own children or
		// ancestors. This method returns false if either condition is true,
		// in which case you should abort your move operation.
		assert(sourceFirst >= 0);
		assert(sourceLast >= sourceFirst);
		assert(destinationColumn >= 0);
		if (!this.allowMove(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn, Orientation.Horizontal)) {
			return false;
		}
		const sourceChange = new Change(sourceParent, sourceFirst, sourceLast);
		sourceChange.needsAdjust = sourceParent.isValid() && (sourceParent.row >= destinationColumn) && sourceParent.parent().eq(destinationParent);
		this.changes.push(sourceChange);
		const destinationLast = destinationColumn + (sourceLast - sourceFirst);
		const destinationChange = new Change(destinationParent, destinationColumn, destinationLast);
		destinationChange.needsAdjust = destinationParent.isValid() && (destinationParent.row >= sourceLast) && destinationParent.parent().eq(sourceParent);
		this.changes.push(destinationChange);
		this._itemsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn, Orientation.Horizontal);
		this.columnsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn);
		return true;
	}

	beginMoveRows(sourceParent: ModelIndex, sourceFirst: number, sourceLast: number, destinationParent: ModelIndex, destinationRow: number): boolean {
		// Begins a row move operation.
		//
		// When reimplementing a subclass, this method simplifies moving
		// entities in your model. This method is responsible for moving
		// persistent indexes in the model, which you would otherwise be
		// required to do yourself. Using beginMoveRows and endMoveRows is an
		// alternative to emitting layoutAboutToBeChanged and layoutChanged
		// directly along with changePersistentIndex.
		//
		// The `sourceParent` index corresponds to the parent from which the
		// rows are moved; `sourceFirst` and `sourceLast` are the first and
		// last row numbers of the rows to be moved. The `destinationParent`
		// index corresponds to the parent into which those rows are moved.
		// The `destinationChild` is the row to which the rows will be moved.
		// That is, the index at row `sourceFirst` in `sourceParent` will
		// become row `destinationChild` in `destinationParent`, followed by
		// all other rows up to `sourceLast`.
		//
		// However, when moving rows down in the same parent (`sourceParent`
		// and `destinationParent` are equal), the rows will be placed before
		// the `destinationChild` index. That is, if you wish to move rows 0
		// and 1 so they will become rows 1 and 2, `destinationChild` should
		// be 3. In this case, the new index for the source row `i` (which is
		// between `sourceFirst` and `sourceLast`) is equal to
		// destinationChild - sourceLast - 1 + i.
		//
		// Note that if `sourceParent` and `destinationParent` are the same,
		// you must ensure that the `destinationChild` is not within the range
		// of `sourceFirst` and `sourceLast` + 1.  You must also ensure that
		// you do not attempt to move a row to one of its own children or
		// ancestors. This method returns false if either condition is true,
		// in which case you should abort your move operation.
		assert(sourceFirst >= 0);
		assert(sourceLast >= sourceFirst);
		assert(destinationRow >= 0);
		if (!this.allowMove(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow, Orientation.Vertical)) {
			return false;
		}
		const sourceChange = new Change(sourceParent, sourceFirst, sourceLast);
		sourceChange.needsAdjust = sourceParent.isValid() && (sourceParent.row >= destinationRow) && sourceParent.parent().eq(destinationParent);
		this.changes.push(sourceChange);
		const destinationLast = destinationRow + (sourceLast - sourceFirst);
		const destinationChange = new Change(destinationParent, destinationRow, destinationLast);
		destinationChange.needsAdjust = destinationParent.isValid() && (destinationParent.row >= sourceLast) && destinationParent.parent().eq(sourceParent);
		this.changes.push(destinationChange);
		this.rowsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow);
		this._itemsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow, Orientation.Vertical);
		return true;
	}

	beginRemoveColumns(parent: ModelIndex, first: number, last: number): void {
		// Begins a column removal operation.
		//
		// When reimplementing removeColumns() in a subclass, you must call
		// this function BEFORE removing data from the model's underlying data
		// store.
		//
		// The `parent` index corresponds to the parent from which the new
		// columns are removed; `first` and `last` are the column numbers of
		// the first and last columns to be removed.
		//
		// Note: This function emits the columnsAboutToBeRemoved() signal
		// which connected views (or proxies) must handle before the data is
		// removed. Otherwise, the views may end up in an invalid state.
		assert(first >= 0);
		assert(last >= first);
		assert(last < this.columnCount(parent));
		this.changes.push(new Change(parent, first, last));
		this.columnsAboutToBeRemoved(parent, first, last);
		this._columnsAboutToBeRemoved(parent, first, last);
	}

	beginRemoveRows(parent: ModelIndex, first: number, last: number): void {
		// Begins a row removal operation.
		//
		// When reimplementing removeRows() in a subclass, you must call this
		// function BEFORE removing data from the model's underlying data
		// store.
		//
		// The `parent` index corresponds to the parent from which the new
		// rows are removed; `first` and `last` are the row numbers of the
		// rows to be removed.
		//
		// Note: This function emits the rowsAboutToBeRemoved() signal which
		// connected views (or proxies) must handle before the data is
		// removed. Otherwise, the views may end up in an invalid state.
		assert(first >= 0);
		assert(last >= first);
		assert(last < this.rowCount(parent));
		this.changes.push(new Change(parent, first, last));
		this.rowsAboutToBeRemoved(parent, first, last);
		this._rowsAboutToBeRemoved(parent, first, last);
	}

	beginResetModel(): void {
		// Begins a model reset operation.
		//
		// A reset operation resets the model to its current state in any
		// attached views.
		//
		// Note: Any views attached to this model will be reset as well.
		//
		// When a model is reset it means that any previous data reported from
		// the model is now invalid and has to be queried for again. This also
		// means that the current item and any selected items will become
		// invalid.
		//
		// When a model radically changes its data it can sometimes be easier
		// to just call this function rather than emit dataChanged() to inform
		// other components when the underlying data source, or its structure,
		// has changed.
		//
		// You must call this function before resetting any internal data
		// structures in your model or proxy model.
		//
		// This function emits the signal modelAboutToBeReset().
		this.modelAboutToBeReset();
	}

	changePersistentIndexList(from: ModelIndexList, to: ModelIndexList): void {
		if (this.persistent.indexes.isEmpty()) {
			return;
		}
		const toBeInserted: Array<PersistentModelIndexData> = [];
		for (let i = 0; i < from.size(); ++i) {
			if (from.at(i).eq(to.at(i))) {
				continue;
			}
			const dataList = new list(this.persistent.indexes.find(from.at(i)));
			if (dataList.size() > 0) {
				const data = dataList.first();
				this.persistent.indexes.remove(from.at(i));
				data.index = to.at(i);
				if (data.index.isValid()) {
					toBeInserted.push(data);
				} else {
					console.log('AbstractItemModel::changePersistentIndexList: Invalid index derived from arguments: to[%s] in model %s', to.at(i), this);
				}
			}
		}
		for (const data of toBeInserted) {
			this.persistent.indexes.insertEnd(data.index, data);
		}
	}

	checkIndex(index: ModelIndex, options: CheckIndexOption = CheckIndexOption.NoOption): boolean {
		// This function checks whether `index` is a legal model index for
		// this model. A legal model index is either an invalid model index,
		// or a valid model index for which all the following holds:
		//
		// - the index' model is this;
		// - the index' row is greater or equal than zero;
		// - the index' row is less than the row count for the index' parent;
		// - the index' column is greater or equal than zero;
		// - the index' column is less than the column count for the index' parent.
		//
		// The `options` argument may change some of these checks. If
		// `options` contains `IndexIsValid`, then `index` must be a valid
		// index; this is useful when reimplementing functions such as data()
		// or setData(), which expect valid indexes.
		//
		// If `options` contains `DoNotUseParent`, then the checks that would
		// call parent() are omitted; this allows calling this function from a
		// parent() reimplementation (otherwise, this would result in endless
		// recursion and a crash).
		//
		// If `options` does not contain `DoNotUseParent`, and it contains
		// `ParentIsInvalid`, then an additional check is performed: the
		// parent index is checked for not being valid. This is useful when
		// implementing flat models such as lists or tables, where no model
		// index should have a valid parent index.
		//
		// This function returns true if all the checks succeeded, and false
		// otherwise. This allows to use the function in debugging mechanisms.
		// If some check failed, a warning message will be printed containing
		// some information that may be useful for debugging the failure.
		//
		// Note: This function is a debugging helper for implementing your own
		// item models. When developing complex models, as well as when
		// building complicated model hierarchies (e.g. using proxy models),
		// it is useful to call this function in order to catch bugs relative
		// to illegal model indices (as defined above) accidentally passed to
		// some AbstractItemModel API.
		//
		// Warning: Note that it's undefined behavior to pass illegal indices
		// to item models, so applications must refrain from doing so, and not
		// rely on any "defensive" programming that item models could employ
		// to handle illegal indexes gracefully.
		if (!index.isValid()) {
			if (options & CheckIndexOption.IndexIsValid) {
				console.log(`Warning: Index ${index} is not valid (expected valid)`);
				return false;
			}
			return true;
		}
		if (index.model !== this) {
			const m = index.model;
			console.log(`Warning: Index ${index} is for model ${m ? m : 'null'} which is different from this model ${this}`);
			return false;
		}
		if (index.row < 0) {
			console.log(`Warning: Index ${index} has negative row ${index.row}`);
			return false;
		}
		if (index.column < 0) {
			console.log(`Warning: Index ${index} has negative column ${index.column}`);
			return false;
		}
		if (!(options & CheckIndexOption.DoNotUseParent)) {
			const parentIndex = index.parent();
			if (options & CheckIndexOption.ParentIsInvalid) {
				if (parentIndex.isValid()) {
					console.log(`Warning: Index ${index} has valid parent ${parentIndex} (expected an invalid parent)`);
					return false;
				}
			}
			const rc = this.rowCount(parentIndex);
			if (index.row >= rc) {
				console.log(`Warning: Index ${index} has out of range row ${index.row} rowCount() is ${rc}`);
				return false;
			}
			const cc = this.columnCount(parentIndex);
			if (index.column >= cc) {
				console.log(`Warning: Index ${index} has out of range column ${index.column} columnCount() is ${cc}`);
				return false;
			}
		}
		return true;
	}

	protected columnsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.ColumnsAboutToBeInserted,
			parent,
			first,
			last));
	}

	private _columnsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		if (first < this.columnCount(parent)) {
			for (const data of this.persistent.indexes.values()) {
				const index = data.index;
				if ((index.column >= first) && index.isValid() && (index.parent().eq(parent))) {
					persistentMoved.push(data);
				}
			}
		}
		this.persistent.moved.push(persistentMoved);
	}

	protected columnsAboutToBeMoved(sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationColumn: number): void {
		this.notifyEvt(new ItemModelColumnMoveEvt(
			ItemModelEvtType.ColumnsAboutToBeMoved,
			sourceParent,
			sourceStart,
			sourceEnd,
			destinationParent,
			destinationColumn));
	}

	protected columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.ColumnsAboutToBeRemoved,
			parent,
			first,
			last));
	}

	private _columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		const persistentInvalidated: Array<PersistentModelIndexData> = [];
		// Find the persistent indexes that are affected by the change,
		// either by being in the removed subtree or by being on the same
		// level and to the right of the removed columns.
		for (const data of this.persistent.indexes.values()) {
			let levelChanged: boolean = false;
			let current: ModelIndex = data.index;
			while (current.isValid()) {
				const currentParent = current.parent();
				if (currentParent.eq(parent)) {
					// On the same level as the change
					if (!levelChanged && (current.column > last)) {
						// Right of the removed columns
						persistentMoved.push(data);
					} else if ((current.column <= last) && (current.column >= first)) {
						// In the removed subtree
						persistentInvalidated.push(data);
					}
					break;
				}
				current = currentParent;
				levelChanged = true;
			}
		}
		this.persistent.moved.push(persistentMoved);
		this.persistent.invalidated.push(persistentInvalidated);
	}

	protected columnsInserted(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.ColumnsInserted,
			parent,
			first,
			last));
	}

	private _columnsInserted(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.remove(old);
			data.index = this.index(old.row, old.column + count, parent);
			if (data.index.isValid()) {
				this.persistent.indexes.insertEnd(data.index, data);
			} else {
				console.log('AbstractItemModel::endInsertColumns: Invalid index (%s, %s) in model %s', old.row, (old.column + count), this);
			}
		}
	}

	protected columnsMoved(parent: ModelIndex, start: number, end: number, destination: ModelIndex, column: number): void {
		this.notifyEvt(new ItemModelColumnMoveEvt(
			ItemModelEvtType.ColumnsMoved,
			parent,
			start,
			end,
			destination,
			column));
	}

	protected columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.ColumnsRemoved,
			parent,
			first,
			last));
	}

	private _columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.remove(old);
			data.index = this.index(old.row, old.column - count, parent);
			if (data.index.isValid()) {
				this.persistent.indexes.insertEnd(data.index, data);
			} else {
				console.log('AbstractItemModel::endRemoveColumns: Invalid index (%s, %s) in model %s', old.row, (old.column - count), this);
			}
		}
		const persistentInvalidated = this.persistent.invalidated.pop();
		for (const data of persistentInvalidated) {
			this.persistent.indexes.remove(data.index);
			data.index = new ModelIndex();
		}
	}

	createIndex(row: number, column: number, data?: unknown): ModelIndex {
		return new ModelIndex(row, column, this, data);
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		// Returns the data stored under the given role for the item referred
		// to by the index. If you do not have a value to return, return an
		// invalid Variant instead of returning null or undefined.
		return new Variant();
	}

	protected dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		this.notifyEvt(new ItemModelDataChangeEvt(
			topLeft,
			bottomRight,
			roles || []));
	}

	destroy(): void {
		this.invalidatePersistentIndexes();
		this.persistent.destroy();
		this.changes.clear();
		this.evtListeners.clear();
	}

	endInsertColumns(): void {
		// Ends a column insertion operation.
		//
		// When reimplementing insertColumns() in a subclass, you must call
		// this function AFTER inserting data into the model's underlying data
		// store.
		const change = this.changes.pop();
		const {parent, first, last} = change;
		this._columnsInserted(parent, first, last);
		this.columnsInserted(parent, first, last);
	}

	endInsertRows(): void {
		// Ends a row insertion operation.
		//
		// When reimplementing insertRows() in a subclass, you must call this
		// function AFTER inserting data into the model's underlying data
		// store.
		const change = this.changes.pop();
		const {parent, first, last} = change;
		this._rowsInserted(parent, first, last);
		this.rowsInserted(parent, first, last);
	}

	endMoveColumns(): void {
		// Ends a column move operation.
		//
		// When implementing a subclass, you must call this function AFTER
		// moving data within the model's underlying data store.
		const insertChange = this.changes.pop();
		const removeChange = this.changes.pop();
		let adjustedSource = removeChange.parent;
		let adjustedDestination = insertChange.parent;
		const numMoved = removeChange.last - removeChange.first + 1;
		if (insertChange.needsAdjust) {
			adjustedDestination = this.createIndex(adjustedDestination.row, adjustedDestination.column - numMoved);
		}
		if (removeChange.needsAdjust) {
			adjustedSource = this.createIndex(adjustedSource.row, adjustedSource.column + numMoved);
		}
		this._itemsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first, Orientation.Horizontal);
		this.columnsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first);
	}

	endMoveRows(): void {
		// Ends a row move operation.
		//
		// When implementing a subclass, you must call this function AFTER
		// moving data within the model's underlying data store.
		const insertChange = this.changes.pop();
		const removeChange = this.changes.pop();
		let adjustedSource = removeChange.parent;
		let adjustedDestination = insertChange.parent;
		const numMoved = removeChange.last - removeChange.first + 1;
		if (insertChange.needsAdjust) {
			adjustedDestination = this.createIndex(adjustedDestination.row - numMoved, adjustedDestination.column);
		}
		if (removeChange.needsAdjust) {
			adjustedSource = this.createIndex(adjustedSource.row + numMoved, adjustedSource.column);
		}
		this._itemsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first, Orientation.Vertical);
		this.rowsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first);
	}

	endRemoveColumns(): void {
		// Ends a column removal operation.
		//
		// When reimplementing removeColumns() in a subclass, you must call
		// this function AFTER removing data from the model's underlying data
		// store.
		const change = this.changes.pop();
		const {parent, first, last} = change;
		this._columnsRemoved(parent, first, last);
		this.columnsRemoved(parent, first, last);
	}

	endRemoveRows(): void {
		// Ends a row removal operation.
		//
		// When reimplementing removeRows() in a subclass, you must call this
		// function AFTER removing data from the model's underlying data
		// store.
		const change = this.changes.pop();
		const {parent, first, last} = change;
		this._rowsRemoved(parent, first, last);
		this.rowsRemoved(parent, first, last);
	}

	endResetModel(): void {
		// Completes a model reset operation.
		//
		// You must call this function AFTER resetting any internal data
		// structure in your model or proxy model.
		//
		// This function emits the signal modelReset().
		this.invalidatePersistentIndexes();
		this.resetInternalData();
		this.modelReset();
	}

	protected evtListenerAdded(): void {
	}

	protected evtListenerRemoved(): void {
	}

	flags(index: ModelIndex): ItemFlag {
		if (!this.indexValid(index)) {
			return ItemFlag.NoItemFlags;
		}
		// return ItemFlag.ItemIsSelectable | ItemFlag.ItemIsEnabled;
		return ItemFlag.ItemIsEnabled;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return (this.rowCount(parent) > 0) && (this.columnCount(parent) > 0);
	}

	hasIndex(row: number, column: number, parent: ModelIndex = new ModelIndex()): boolean {
		if ((row < 0) || (column < 0)) {
			return false;
		}
		return (row < this.rowCount(parent)) && (column < this.columnCount(parent));
	}

	headerData(section: number, orientation: Orientation, role: number = ItemDataRole.DisplayRole): Variant {
		// Returns the data for the given `role` and `section` in the header
		// with the specified `orientation`.
		//
		// For horizontal headers, the section number corresponds to the
		// column number. Similarly, for vertical headers, the section number
		// corresponds to the row number.
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(section + 1);
		}
		return new Variant();
	}

	protected headerDataChanged(orientation: Orientation, first: number, last: number): void {
		this.notifyEvt(new ItemModelHeaderDataChangeEvt(
			orientation,
			first,
			last));
	}

	abstract index(row: number, column: number, parent?: ModelIndex): ModelIndex;

	protected indexValid(index: ModelIndex): boolean {
		return (index.row >= 0) && (index.column >= 0) && (index.model === this);
	}

	insertColumn(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.insertColumns(column, 1, parent);
	}

	insertColumns(column: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		// On models that support this, inserts `count` new columns into the
		// model before the given `column`. The items in each new column will
		// be children of the item represented by the `parent` model index.
		//
		// If `column` is 0, the columns are prepended to any existing
		// columns.
		//
		// If `column` is columnCount(), the columns are appended to any
		// existing columns.
		//
		// If `parent` has no children, a single row with `count` columns is
		// inserted.
		//
		// Returns true if the columns were successfully inserted; otherwise
		// returns false.
		//
		// If you implement your own model, you can reimplement this function
		// if you want to support insertions. Alternatively, you can provide
		// your own API for altering the data.
		//
		// Note: The base class implementation does nothing and returns false.
		return false;
	}

	insertRow(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.insertRows(row, 1, parent);
	}

	insertRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		// On models that support this, inserts `count` rows into the model
		// before the given `row`. Items in the new row will be children of
		// the item represented by the `parent` model index.
		//
		// If `row` is 0, the rows are prepended to any existing rows in the
		// parent.
		//
		// If `row` is rowCount(), the rows are appended to any existing rows
		// in the parent.
		//
		// If `parent` has no children, a single column with `count` rows is
		// inserted.
		//
		// Returns true if the rows were successfully inserted; otherwise
		// returns false.
		//
		// If you implement your own model, you can reimplement this function
		// if you want to support insertions. Alternatively, you can provide
		// your own API for altering the data. In either case, you will need
		// to call beginInsertRows() and endInsertRows() to notify other
		// components that the model has changed.
		//
		// Note: The base class implementation of this function does nothing
		// and returns false.
		return false;
	}

	invalidatePersistentIndex(index: ModelIndex): void {
		for (const data of this.persistent.indexes.values()) {
			data.index = new ModelIndex();
		}
		this.persistent.indexes.remove(index);
	}

	invalidatePersistentIndexes(): void {
		for (const data of this.persistent.indexes.values()) {
			data.index = new ModelIndex();
		}
		this.persistent.indexes.clear();
	}

	itemData(index: ModelIndex): Map<number, Variant> {
		const rv: Map<number, Variant> = new Map();
		for (let i = 0; i < ItemDataRole.UserRole; ++i) {
			const variantData = this.data(index, i);
			if (variantData.isValid()) {
				rv.set(i, variantData);
			}
		}
		return rv;
	}

	private _itemsAboutToBeMoved(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationChild: number, orientation: Orientation): void {
		const persistentMovedExplicitly: Array<PersistentModelIndexData> = [];
		const persistentMovedInSource: Array<PersistentModelIndexData> = [];
		const persistentMovedInDestination: Array<PersistentModelIndexData> = [];
		const sameParent = srcParent.eq(destinationParent);
		const movingUp = (srcFirst > destinationChild);
		for (const data of this.persistent.indexes.values()) {
			const index = data.index;
			const parent = index.parent();
			const isSourceIndex = parent.eq(srcParent);
			const isDestinationIndex = parent.eq(destinationParent);
			const childPosition = (orientation === Orientation.Vertical) ?
				index.row :
				index.column;
			if (!index.isValid() || !(isSourceIndex || isDestinationIndex)) {
				continue;
			}
			if (!sameParent && isDestinationIndex) {
				if (childPosition >= destinationChild) {
					persistentMovedInDestination.push(data);
				}
				continue;
			}
			if (sameParent && movingUp && (childPosition < destinationChild)) {
				continue;
			}
			if (sameParent && !movingUp && (childPosition < srcFirst)) {
				continue;
			}
			if (!sameParent && (childPosition < srcFirst)) {
				continue;
			}
			if (sameParent && (childPosition > srcLast) && (childPosition >= destinationChild)) {
				continue;
			}
			if ((childPosition <= srcLast) && (childPosition >= srcFirst)) {
				persistentMovedExplicitly.push(data);
			} else {
				persistentMovedInSource.push(data);
			}
		}
		this.persistent.moved.push(persistentMovedExplicitly);
		this.persistent.moved.push(persistentMovedInSource);
		this.persistent.moved.push(persistentMovedInDestination);
	}

	private _itemsMoved(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationChild: number, orientation: Orientation): void {
		const movedInDestination = this.persistent.moved.pop();
		const movedInSource = this.persistent.moved.pop();
		const movedExplicitly = this.persistent.moved.pop();
		const sameParent = srcParent.eq(destinationParent);
		const movingUp = (srcFirst > destinationChild);
		const explicitChange = (!sameParent || movingUp) ? (destinationChild - srcFirst) : (destinationChild - srcLast - 1);
		const sourceChange = (!sameParent || !movingUp) ? (-1 * (srcLast - srcFirst + 1)) : (srcLast - srcFirst + 1);
		const destinationChange = (srcLast - srcFirst + 1);
		this.movePersistentIndexes(movedExplicitly, explicitChange, destinationParent, orientation);
		this.movePersistentIndexes(movedInSource, sourceChange, srcParent, orientation);
		this.movePersistentIndexes(movedInDestination, destinationChange, destinationParent, orientation);
	}

	protected layoutAboutToBeChanged(parents: Array<PersistentModelIndex>, hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.notifyEvt(new ItemModelLayoutEvt(ItemModelEvtType.LayoutAboutToBeChanged, parents, hint));
	}

	protected layoutChanged(parents: Array<PersistentModelIndex>, hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.notifyEvt(new ItemModelLayoutEvt(ItemModelEvtType.LayoutChanged, parents, hint));
	}

	match(start: ModelIndex, role: number, value: Variant, hits: number = 1, flags: MatchFlag = MatchFlag.MatchStartsWith | MatchFlag.MatchWrap): ModelIndexList {
		// Returns a list of indexes for the items in the column of the
		// `start` index where data stored under the given `role` matches the
		// specified `value`. The way the search is performed is defined by
		// the `flags` given. The list that is returned may be empty. Note
		// also that the order of results in the list may not correspond to
		// the order in the model, if for example a proxy model is used. The
		// order of the results cannot be relied upon.
		//
		// The search begins from the `start` index, and continues until the
		// number of matching data items equals `hits`, the search reaches the
		// last row, or the search reaches `start` again - depending on
		// whether MatchWrap is specified in `flags`. If you want to search
		// for all matching items, use `hits` = -1.
		//
		// By default, this function will perform a wrapping, string-based
		// comparison on all items, searching for items that begin with the
		// search term specified by `value`.
		//
		// Note: The default implementation of this function only searches
		// columns. Reimplement this function to include a different search
		// behavior.
		const result = new ModelIndexList();
		const matchType = flags & 0x0F;
		const cs: CaseSensitivity = (flags & MatchFlag.MatchCaseSensitive) ?
			CaseSensitivity.CaseSensitive :
			CaseSensitivity.CaseInsensitive;
		const recurse = Boolean(flags & MatchFlag.MatchRecursive);
		const wrap = Boolean(flags & MatchFlag.MatchWrap);
		const allHits = (hits === -1);
		let text: string = '';
		let rx: RegExp = new RegExp('');
		const column = start.column;
		const p = this.parent(start);
		let from = start.row;
		let to = this.rowCount(p);
		// Iterates twice if wrapping
		for (let i = 0; (wrap && (i < 2)) || (!wrap && (i < 1)); ++i) {
			for (let r = from; (r < to) && (allHits || (result.size() < hits)); ++r) {
				const idx = this.index(r, column, p);
				if (!idx.isValid()) {
					continue;
				}
				const v = this.data(idx, role);
				// QVariant based matching
				if (matchType === MatchFlag.MatchExactly) {
					if (value.eq(v)) {
						result.append(idx);
					}
				} else {
					// String or regular expression based matching
					if (matchType === MatchFlag.MatchRegularExpression) {
						if (!rx.source.trim()) {
							let rxFlags: string | undefined = undefined;
							if (cs === CaseSensitivity.CaseInsensitive) {
								rxFlags = 'i';
							}
							rx = new RegExp(value.toString(), rxFlags);
						}
					} else if (matchType === MatchFlag.MatchWildcard) {
						if (!rx.source.trim()) {
							let rxFlags: string | undefined = undefined;
							if (cs === CaseSensitivity.CaseInsensitive) {
								rxFlags = 'i';
							}
							rx = new RegExp(value.toString(), rxFlags);
						}
					} else {
						if (!text.trim()) {
							// Lazy conversion
							text = value.toString();
						}
					}
					const t = v.toString();
					switch (matchType) {
						case MatchFlag.MatchRegularExpression:
						case MatchFlag.MatchWildcard:
							if (t.match(rx)) {
								result.append(idx);
							}
							break;
						case MatchFlag.MatchStartsWith:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().startsWith(text.toLocaleLowerCase())) {
									result.append(idx);
								}
							} else {
								if (t.startsWith(text)) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchEndsWith:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().endsWith(text.toLocaleLowerCase())) {
									result.append(idx);
								}
							} else {
								if (t.endsWith(text)) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchFixedString:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase() === text.toLocaleLowerCase()) {
									result.append(idx);
								}
							} else {
								if (t === text) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchContains:
						default:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0) {
									result.append(idx);
								}
							} else {
								if (t.indexOf(text) >= 0) {
									result.append(idx);
								}
							}
							break;
					}
				}
				if (recurse) {
					const parent = (column !== 0) ? idx.sibling(idx.row, 0) : idx;
					if (this.hasChildren(parent)) {
						// Search the hierarchy
						result.plusEq(this.match(
							this.index(0, column, parent),
							role,
							(!text.trim() ? value : new Variant(text)),
							(allHits ? -1 : hits - result.size()),
							flags));
					}
				}
			}
			// Prepare for the next iteration
			from = 0;
			to = start.row;
		}
		return result;
	}

	protected modelAboutToBeReset(): void {
		this.notifyEvt(new ItemModelEvt(ItemModelEvtType.ModelAboutToBeReset));
	}

	protected modelReset(): void {
		this.notifyEvt(new ItemModelEvt(ItemModelEvtType.ModelReset));
	}

	protected movePersistentIndexes(indexes: Array<PersistentModelIndexData>, change: number, parent: ModelIndex, orientation: Orientation): void {
		for (const data of this.persistent.indexes.values()) {
			let row = data.index.row;
			let column = data.index.column;
			if (orientation === Orientation.Vertical) {
				row += change;
			} else {
				column += change;
			}
			this.persistent.indexes.remove(data.index);
			data.index = this.index(row, column, parent);
			if (data.index.isValid()) {
				this.persistent.indexes.insertEnd(data.index, data);
			} else {
				console.log('AbstractItemModel::endMoveRows: Invalid index (%s, %s) in model %s', row, column, this);
			}
		}
	}

	protected notifyEvt(evt: Evt): void {
		for (const cb of this.evtListeners) {
			cb(evt);
		}
	}

	offEvt(cb: EvtListener): void {
		this.evtListeners.removeAll(cb);
		this.evtListenerRemoved();
	}

	onEvt(cb: EvtListener): void {
		if (this.evtListeners.indexOf(cb) === -1) {
			this.evtListeners.append(cb);
			this.evtListenerAdded();
		}
	}

	abstract parent(index?: ModelIndex): ModelIndex;

	persistentIndexList(): ModelIndexList {
		const rv = new ModelIndexList();
		for (const data of this.persistent.indexes.values()) {
			rv.append(data.index);
		}
		return rv;
	}

	removeColumn(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.removeColumns(column, 1, parent);
	}

	removeColumns(column: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		// On models that support this, removes `count` columns starting with
		// the given `column` under parent `parent` from the model.
		//
		// Returns true if the columns were successfully removed; otherwise
		// returns false.
		//
		// If you implement your own model, you can reimplement this function
		// if you want to support removing. Alternatively, you can provide
		// your own API for altering the data.
		//
		// Note: The base class implementation does nothing and returns false.
		return false;
	}

	removePersistentIndexData(data: PersistentModelIndexData): void {
		if (data.index.isValid()) {
			const deleted = this.persistent.indexes.remove(data.index);
			assert(deleted, 'PersistentModelIndex::destroy persistent model indexes corrupted');
			// This assert may happen if the model use changePersistentIndex
			// in a way that could result on two PersistentModelIndex pointing
			// to the same index.
		}
		// Make sure our optimization still works
		for (let i = this.persistent.moved.count() - 1; i >= 0; --i) {
			const idx = this.persistent.moved.at(i).indexOf(data);
			if (idx >= 0) {
				this.persistent.moved.at(i).splice(idx, 1);
			}
		}
		// Update the references to invalidated persistent indexes
		for (let i = this.persistent.invalidated.count() - 1; i >= 0; --i) {
			const idx = this.persistent.invalidated.at(i).indexOf(data);
			if (idx >= 0) {
				this.persistent.invalidated.at(i).splice(idx, 1);
			}
		}
	}

	removeRow(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.removeRows(row, 1, parent);
	}

	removeRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		// On models that support this, removes `count` rows starting with the
		// given `row` under parent `parent` from the model.
		//
		// Returns true if the rows were successfully removed; otherwise
		// returns false.
		//
		// If you implement your own model, you can reimplement this function
		// if you want to support removing. Alternatively, you can provide
		// your own API for altering the data.
		//
		// Note: The base class implementation does nothing and returns false.
		return false;
	}

	protected resetInternalData(): void {
	}

	abstract rowCount(parent?: ModelIndex): number;

	protected rowsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.RowsAboutToBeInserted,
			parent,
			first,
			last));
	}

	private _rowsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		if (first < this.rowCount(parent)) {
			for (const data of this.persistent.indexes.values()) {
				const index = data.index;
				if (index.row >= first && index.isValid() && index.parent().eq(parent)) {
					persistentMoved.push(data);
				}
			}
		}
		this.persistent.moved.push(persistentMoved);
	}

	protected rowsAboutToBeMoved(sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationRow: number): void {
		this.notifyEvt(new ItemModelRowMoveEvt(
			ItemModelEvtType.RowsAboutToBeMoved,
			sourceParent,
			sourceStart,
			sourceEnd,
			destinationParent,
			destinationRow));
	}

	protected rowsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.RowsAboutToBeRemoved,
			parent,
			first,
			last));
	}

	private _rowsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		const persistentInvalidated: Array<PersistentModelIndexData> = [];
		// Find the persistent indexes that are affected by the change, either
		// by being in the removed subtree or by being on the same level and
		// below the removed rows.
		for (const data of this.persistent.indexes.values()) {
			let levelChanged: boolean = false;
			let current: ModelIndex = data.index;
			while (current.isValid()) {
				const currentParent = current.parent();
				if (currentParent.eq(parent)) {
					// On the same level as the change
					if (!levelChanged && (current.row > last)) {
						// Below the removed rows
						persistentMoved.push(data);
					} else if ((current.row <= last) && (current.row >= first)) {
						// In the removed subtree
						persistentInvalidated.push(data);
					}
					break;
				}
				current = currentParent;
				levelChanged = true;
			}
		}
		this.persistent.moved.push(persistentMoved);
		this.persistent.invalidated.push(persistentInvalidated);
	}

	protected rowsInserted(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.RowsInserted,
			parent,
			first,
			last));
	}

	private _rowsInserted(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (let i = 0; i < persistentMoved.length; ++i) {
			const data = persistentMoved[i];
			const old = data.index;
			this.persistent.indexes.remove(old);
			data.index = this.index(old.row + count, old.column, parent);
			if (data.index.isValid()) {
				this.persistent.indexes.insertEnd(data.index, data);
			} else {
				console.log('AbstractItemModel::endInsertRows: Invalid index (%s, %s) in model %s', (old.row + count), old.column, this);
			}
		}
	}

	protected rowsMoved(parent: ModelIndex, start: number, end: number, destination: ModelIndex, row: number): void {
		this.notifyEvt(new ItemModelRowMoveEvt(
			ItemModelEvtType.RowsMoved,
			parent,
			start,
			end,
			destination,
			row));
	}

	protected rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.notifyEvt(new ItemModelSectionAddRemoveEvt(
			ItemModelEvtType.RowsRemoved,
			parent,
			first,
			last));
	}

	private _rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.remove(old);
			data.index = this.index(old.row - count, old.column, parent);
			if (data.index.isValid()) {
				this.persistent.indexes.remove(data.index, data);
			} else {
				console.log('AbstractItemModel::endRemoveRows: Invalid index (%s, %s) in model %s', (old.row - count), old.column, this);
			}
		}
		const persistentInvalidated = this.persistent.invalidated.pop();
		for (const data of persistentInvalidated) {
			this.persistent.indexes.remove(data.index);
			data.index = new ModelIndex();
		}
	}

	setData(index: ModelIndex, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		// The base class implementation returns false. This function and
		// data() must be reimplemented for editable models.
		return false;
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		// Sets the data for the given `role` and `section` in the header with
		// the specified `orientation` to the `value` supplied.
		//
		// Returns true if the header's data was updated; otherwise returns
		// false.
		//
		// When reimplementing this function, the headerDataChanged() signal
		// must be emitted explicitly.
		return false;
	}

	setItemData(index: ModelIndex, roles: Map<number, Variant>): boolean {
		// Sets the role data for the item at index to the associated value in roles, for every ItemDataRole.
		//
		// Returns true if successful; otherwise returns false.
		//
		// Roles that are not in roles will not be modified.
		for (const [role, variant] of roles) {
			if (!this.setData(index, variant, role)) {
				return false;
			}
		}
		return true;
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return ((row === index.row) && (column === index.column)) ?
			index :
			this.index(row, column, this.parent(index));
	}

	sort(column: number, order: SortOrder = SortOrder.AscendingOrder): void {
		// Sorts the model by `column` in the given `order`.
		//
		// Note: The base class implementation does nothing.
	}

	toString(): string {
		return this.constructor.name;
	}
}

class EmptyItemModel extends AbstractItemModel {
	columnCount(parent: ModelIndex = new ModelIndex()): number {
		return 0;
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		return new Variant();
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return false;
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return new ModelIndex();
	}

	parent(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	rowCount(parent: ModelIndex = new ModelIndex()): number {
		return 0;
	}
}

const staticEmptyModel = new EmptyItemModel();

export abstract class AbstractListModel extends AbstractItemModel {
	columnCount(parent: ModelIndex = new ModelIndex()): number {
		return (parent && parent.isValid()) ? 0 : 1;
	}

	flags(index: ModelIndex): ItemFlag {
		let rv = super.flags(index);
		if (index.isValid()) {
			rv |= ItemFlag.ItemNeverHasChildren;
		}
		return rv;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return (parent && parent.isValid()) ?
			false :
			(this.rowCount() > 0);
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return this.hasIndex(row, column, parent) ?
			this.createIndex(row, column) :
			new ModelIndex();
	}

	parent(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return this.index(row, column);
	}
}

export abstract class AbstractTableModel extends AbstractItemModel {
	flags(index: ModelIndex): ItemFlag {
		let rv = super.flags(index);
		if (index.isValid()) {
			rv |= ItemFlag.ItemNeverHasChildren;
		}
		return rv;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		if (!parent.isValid()) {
			return (this.rowCount(parent) > 0) && (this.columnCount(parent) > 0);
		}
		return false;
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return this.hasIndex(row, column, parent) ?
			this.createIndex(row, column) :
			new ModelIndex();
	}

	parent(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return this.index(row, column);
	}
}

class Change {
	first: number;
	last: number;
	needsAdjust: boolean;
	parent: ModelIndex;

	constructor(parent: ModelIndex = new ModelIndex(), first: number = -1, last: number = -1) {
		this.first = first;
		this.last = last;
		this.needsAdjust = false;
		this.parent = parent;
	}

	isValid(): boolean {
		return (this.first >= 0) && (this.last >= 0);
	}
}

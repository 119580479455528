import {Menu, MenuOpts} from '../../ui/menu';
import {ListItem, ListItemOpts, ListItemSelectEvt} from '../../ui/list';
import {El, elOpts} from '../../el';
import {bind} from '../../util';
import {IconButton} from '../../ui/iconbutton';
import {KeyPressEvt, TextInput} from '../../ui/textinput';
import {Key} from '../../constants';
import {list} from '../../tools';
import {Evt} from '../../evt';

enum LinkState {
	NoLink,
	FakeLink,
	RealLink,
}

enum ViewMode {
	TextMode,
	InputMode,
}

interface QBInvoiceLinkMenuListItemOpts extends ListItemOpts {
	actionType: QBInvoiceAction;
	enabledForLinkState: LinkState;
}

class QBInvoiceMenuListItemEvt extends Evt {
	private li: QBInvoiceLinkMenuListItem;
	private v: string;

	constructor(value: string, listItem: QBInvoiceLinkMenuListItem) {
		super(Evt.Submit);
		this.li = listItem;
		this.v = value;
	}

	listItem(): QBInvoiceLinkMenuListItem {
		return this.li;
	}

	value(): string {
		return this.v;
	}
}

class QBInvoiceLinkMenuListItem extends ListItem {
	actionType: QBInvoiceAction | number;
	enabledForLinkState: LinkState;
	protected viewMode: ViewMode;

	constructor(opts: Partial<QBInvoiceLinkMenuListItemOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<QBInvoiceLinkMenuListItemOpts> | null, parent?: El | null);
	constructor(opts: Partial<QBInvoiceLinkMenuListItemOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<QBInvoiceLinkMenuListItemOpts>, parent?: El | null);
	constructor(opts?: Partial<QBInvoiceLinkMenuListItemOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<QBInvoiceLinkMenuListItemOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<QBInvoiceLinkMenuListItemOpts>(a, b, c);
		super(opts);
		this.actionType = (opts.actionType === undefined) ? -1 : opts.actionType;
		this.enabledForLinkState = (opts.enabledForLinkState === undefined) ? LinkState.NoLink : opts.enabledForLinkState;
		this.viewMode = ViewMode.TextMode;
	}

	destroy(): void {
		this.viewMode = ViewMode.TextMode;
		super.destroy();
	}

	protected enterMode(mode: ViewMode): void {
		switch (mode) {
			case ViewMode.InputMode:
				this.setRippleEnabled(false);
				break;
			case ViewMode.TextMode:
				break;
		}
	}

	protected exitMode(mode: ViewMode): void {
		switch (mode) {
			case ViewMode.InputMode:
				this.setRippleEnabled(true);
				break;
			case ViewMode.TextMode:
				break;
		}
	}

	focusInput(): void {
	}

	inputValue(): string {
		return '';
	}

	mode(): ViewMode {
		return this.viewMode;
	}

	protected notifySubmitAction(value: string): void {
		this.notifyEvt(new QBInvoiceMenuListItemEvt(value, this));
	}

	setMode(mode: ViewMode): void {
		if (mode === this.viewMode) {
			return;
		}
		this.exitMode(this.viewMode);
		this.viewMode = mode;
		this.enterMode(this.viewMode);
	}

	protected setRippleEnabled(enabled: boolean): void {
		const el = this.rippleEl();
		if (el) {
			el.setVisible(enabled);
		}
	}

	setValid(valid: boolean): void {
	}
}

class QBInvoiceLinkMenuTextInputListItem extends QBInvoiceLinkMenuListItem {
	private closeBtn: IconButton | null;
	private textInput: TextInput | null;

	constructor(opts: Partial<ListItemOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts> | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts>, parent?: El | null);
	constructor(opts?: Partial<ListItemOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ListItemOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ListItemOpts>(a, b, c);
		super(opts);
		this.closeBtn = null;
		this.textInput = null;
	}

	protected clearInput(): void {
		if (this.textInput) {
			this.textInput.setValue('');
		}
	}

	@bind
	protected closeButtonEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick) {
			if (this.viewMode === ViewMode.InputMode) {
				this.setMode(ViewMode.TextMode);
			}
		}
	}

	destroy(): void {
		this.clearInput();
		if (this.closeBtn) {
			this.closeBtn.offEvt(this.closeButtonEvt);
			this.closeBtn.destroy();
			this.closeBtn = null;
		}
		if (this.textInput) {
			this.textInput.offEvt(this.textInputEvt);
			this.textInput.destroy();
			this.textInput = null;
		}
		super.destroy();
	}

	protected enterMode(mode: ViewMode): void {
		super.enterMode(mode);
		switch (mode) {
			case ViewMode.InputMode: {
				if (!this.closeBtn) {
					this.closeBtn = new IconButton({iconName: 'close', smaller: true});
					this.closeBtn.onEvt(this.closeButtonEvt);
				}
				this.setLeadingEl(this.closeBtn);
				this.closeBtn.show();
				const textEl = this.textEl();
				if (textEl) {
					textEl.hide();
				}
				if (!this.textInput) {
					this.textInput = new TextInput({
						classNames: 'pb-project-list-qb-invoice-link-menu__list-item-text-input',
						placeholder: 'QB invoice number',
						trailingIcon: {name: 'check', interactive: true},
					});
					this.textInput.onEvt(this.textInputEvt);
					if (textEl) {
						textEl.insertAdjacentElement('beforebegin', this.textInput);
					} else {
						const leadingEl = this.leadingEl();
						if (leadingEl) {
							leadingEl.insertAdjacentElement('afterend', this.textInput);
						} else {
							this.textInput.destroy();
							this.textInput = null;
							return;
						}
					}
				}
				this.textInput.show();
				this.textInput.focus();
				break;
			}
			case ViewMode.TextMode: {
				this.setLeadingIcon('link');
				const textEl = this.textEl();
				if (textEl) {
					textEl.show();
				}
				break;
			}
		}
	}

	protected exitMode(mode: ViewMode): void {
		super.exitMode(mode);
		switch (mode) {
			case ViewMode.InputMode: {
				this.clearInput();
				if (this.closeBtn) {
					this.closeBtn.hide();
				}
				if (this.textInput) {
					this.textInput.hide();
				}
				break;
			}
			case ViewMode.TextMode: {
				break;
			}
		}
	}

	focusInput(): void {
		super.focusInput();
		if (this.viewMode === ViewMode.InputMode && this.textInput) {
			this.textInput.focus();
		}
	}

	inputValue(): string {
		if (this.textInput) {
			return this.textInput.value();
		}
		return super.inputValue();
	}

	setValid(valid: boolean): void {
		if (this.viewMode === ViewMode.InputMode && this.textInput) {
			this.textInput.setValid(valid);
		} else {
			super.setValid(valid);
		}
	}

	@bind
	protected textInputEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.KeyPress:
				const e = <KeyPressEvt>evt;
				switch (e.key()) {
					case Key.Enter:
						e.setPropagate(false);
						this.notifySubmitAction(this.inputValue());
						break;
					case Key.Escape:
						if (this.viewMode === ViewMode.InputMode) {
							e.setPropagate(false);
							this.setMode(ViewMode.TextMode);
						}
						break;
					case Key.Tab:
						e.setPropagate(false);
						break;
				}
				break;
			case Evt.MouseButtonClick:
				this.notifySubmitAction(this.inputValue());
				break;
		}
	}
}

export enum QBInvoiceAction {
	CreateInvoice,
	HasInvoice,
	LinkInvoice,
	UnlinkInvoice,
}

export class QBInvoiceActionEvt extends Evt {
	private axn: QBInvoiceAction;
	private docNum: string;

	constructor(action: QBInvoiceAction, docNumber: string = '') {
		super(Evt.ActionRequest);
		this.axn = action;
		this.docNum = docNumber;
	}

	action(): QBInvoiceAction {
		return this.axn;
	}

	docNumber(): string {
		return this.docNum;
	}
}

export class QBInvoiceLinkMenu extends Menu {
	protected listItems: list<QBInvoiceLinkMenuListItem>;

	constructor(opts: Partial<MenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts>, parent?: El | null);
	constructor(opts?: Partial<MenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<MenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<MenuOpts>(a, b, c);
		super(opts);
		this.addClass('pb-project-list-qb-invoice-link-menu');
		this.listItems = new list<QBInvoiceLinkMenuListItem>();
		const items: Array<Partial<QBInvoiceLinkMenuListItemOpts> & {cls: typeof QBInvoiceLinkMenuListItem}> = [
			{cls: QBInvoiceLinkMenuListItem, leadingIcon: 'check', enabledForLinkState: LinkState.NoLink, text: 'Has invoice', actionType: QBInvoiceAction.HasInvoice},
			{cls: QBInvoiceLinkMenuListItem, leadingIcon: 'link', enabledForLinkState: LinkState.FakeLink, text: 'Has no invoice', actionType: QBInvoiceAction.UnlinkInvoice},
			{cls: QBInvoiceLinkMenuListItem, leadingIcon: 'add', enabledForLinkState: LinkState.NoLink, text: 'Create invoice', actionType: QBInvoiceAction.CreateInvoice},
			{cls: QBInvoiceLinkMenuTextInputListItem, leadingIcon: 'link', enabledForLinkState: LinkState.NoLink, text: 'Link invoice', actionType: QBInvoiceAction.LinkInvoice},
			{cls: QBInvoiceLinkMenuListItem, leadingIcon: 'link_off', enabledForLinkState: LinkState.RealLink, text: 'Remove link', actionType: QBInvoiceAction.UnlinkInvoice},
		];
		for (let i = 0; i < items.length; ++i) {
			const d = items[i];
			const item = new d.cls(d);
			item.onEvt(this.listItemEvt);
			this.listItems.append(item);
			this.addItem(item);
		}
	}

	destroy(): void {
		for (const item of this.listItems) {
			item.destroy();
		}
		this.listItems.clear();
		super.destroy();
	}

	focus(): void {
		for (const item of this.listItems) {
			if (item.mode() === ViewMode.InputMode) {
				item.focusInput();
				break;
			}
		}
	}

	@bind
	protected listEvt(evt: Evt): void {
		if (evt.type() === Evt.Select) {
			this.listItemSelected((<ListItemSelectEvt>evt).index());
		}
		super.listEvt(evt);
	}

	@bind
	protected listItemEvt(evt: Evt): void {
		switch (evt.type()) {
			case Evt.Submit:
				this.notifyEvt(new QBInvoiceActionEvt(QBInvoiceAction.LinkInvoice, (<QBInvoiceMenuListItemEvt>evt).value()));
				break;
		}
	}

	protected listItemSelected(index: number): void {
		if (index >= 0 && index < this.listItems.size()) {
			const item = this.listItems.at(index);
			if (item instanceof QBInvoiceLinkMenuTextInputListItem) {
				if (item.mode() === ViewMode.TextMode) {
					item.setMode(ViewMode.InputMode);
				}
			} else {
				switch (item.actionType) {
					case QBInvoiceAction.CreateInvoice:
					case QBInvoiceAction.HasInvoice:
					case QBInvoiceAction.UnlinkInvoice:
						this.notifyEvt(new QBInvoiceActionEvt(item.actionType));
						break;
				}
			}
		}
	}

	protected listItemSelectEvt(evt: ListItemSelectEvt): void {
		this.notifyEvt(evt);
	}

	setLinkData(data: IQuickBooksLinkingResponse): void {
		let linkState: LinkState;
		if (data.hasRealLink) {
			linkState = LinkState.RealLink;
		} else if (data.hasFakeLink) {
			linkState = LinkState.FakeLink;
		} else {
			linkState = LinkState.NoLink;
		}
		for (const item of this.listItems) {
			item.setVisible(item.enabledForLinkState === linkState);
		}
	}
}

import React from 'react';

import DenseTextInput from './densetextinput';
import {
	Checkbox,
	ListItem,
	IconButton,
	ListItemDetail,
} from '../../../components';

interface IProps {
	object: IChoice;
	onChange: (object: IChoice) => any;
	onDeleteRequest: (object: IChoice) => any;
	takeFocus?: boolean;
}

export default class Choice extends React.Component<IProps, {}> {
	static Name: string = 'choice';

	constructor(props: IProps) {
		super(props);
		this.nameChanged = this.nameChanged.bind(this);
		this.deleteClicked = this.deleteClicked.bind(this);
		this.defaultSelectedChanged = this.defaultSelectedChanged.bind(this);
	}

	defaultSelectedChanged(): void {
		const {object} = this.props;
		this.notify({defaultSelected: !object.defaultSelected});
	}

	deleteClicked(): void {
		const {object, onDeleteRequest} = this.props;
		onDeleteRequest(object);
	}

	nameChanged(event: React.ChangeEvent): void {
		this.notify({name: (event as React.ChangeEvent<HTMLInputElement>).target.value});
	}

	notify<K extends keyof IChoice>(data: Pick<IChoice, K>): void {
		const {object, onChange} = this.props;
		onChange({...object, ...data});
	}

	render(): React.ReactNode {
		const {object, takeFocus} = this.props;
		const {defaultSelected, id, name} = object;
		return (
			<ListItem
				checked={defaultSelected}
				key={id}
				leadingChild={<DeleteButton onClick={this.deleteClicked}/>}
				noRipple={true}
				tabIndex={defaultSelected ? 0 : undefined}
				trailingChild={
					<DefaultSelected
						defaultSelected={defaultSelected}
						id={id}
						onChange={this.defaultSelectedChanged}/>}>
				<DenseTextInput
					id={`${Choice.Name}__${id}`}
					label="Name"
					name="name"
					onChange={this.nameChanged}
					takeFocus={takeFocus}
					value={name}/>
			</ListItem>
		);
	}
}

function DefaultSelected({defaultSelected, id, onChange}: {defaultSelected: boolean, id: number, onChange: () => any}) {
	return (
		<ListItemDetail trailing={true}>
			<Checkbox
				checked={defaultSelected}
				id={`id_${Choice.Name}__${id}`}
				name="defaultSelected"
				onChange={onChange}
				title="Is choice selected by default?"
				value={id}/>
		</ListItemDetail>
	);
}

function DeleteButton({onClick}: {onClick: () => any}) {
	return (
		<ListItemDetail>
			<IconButton
				className="pb-icon-button--size-20-5"
				icon="remove_circle_outline"
				onClick={onClick}
				onHoverClassNames={['pb-icon-button--color-danger']}
				title="Delete this choice"/>
		</ListItemDetail>
	);
}

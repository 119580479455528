import {El} from '../el';
import {IconButton, IconButtonClickEvt} from './iconbutton';
import {Menu} from './menu';
import {assert, bind} from '../util';
import {list} from '../tools';
import {List, ListItemSelectEvt} from './list';
import {Evt} from '../evt';

export class ContactCard extends El {
	protected contactInfo: ContactDetails | null;
	protected detailEl: El;
	protected toolbarMenu: Menu;

	constructor(parent: El | null = null) {
		super('div', parent);
		this.contactInfo = null;
		this.addClass('pb-contact-card');
		const header = El.div({classNames: 'pb-contact-card__header'}, this);
		El.div({classNames: ['material-icons', 'pb-contact-card__header-media', 'pb-icon']}, header);
		const headingContainer = El.div({classNames: 'pb-contact-card__header-heading'}, header);
		new El({classNames: ['mdc-typography', 'mdc-typography--body1']}, 'h4', headingContainer);
		const toolbar = El.div({classNames: ['pb-contact-card__header-toolbar', 'mdc-menu-surface--anchor']}, header);
		const editBtn = new IconButton({iconName: 'edit', name: 'edit', title: 'Edit contact'}, toolbar);
		editBtn.onEvt(this.iconButtonEvt);
		const moreBtn = new IconButton({iconName: 'more_vert', name: 'more', title: 'More actions'}, toolbar);
		moreBtn.onEvt(this.iconButtonEvt);
		this.toolbarMenu = new Menu({items: [{leadingIcon: 'delete_outline', text: 'Delete'}]}, toolbar);
		this.toolbarMenu.onEvt(this.toolbarMenuEvt);
		const closeBtn = new IconButton({iconName: 'close', name: 'close', title: 'Close'}, toolbar);
		closeBtn.onEvt(this.iconButtonEvt);
		this.detailEl = El.div({classNames: 'pb-contact-card__detail'}, this);
	}

	addEmailAddress(address: string, receiveNotifications?: boolean, cb?: (evt: Evt) => any): void {
		this.ensureContactDetails().addEmail(address, receiveNotifications, cb);
	}

	addPhoneNumber(number: string): void {
		this.ensureContactDetails().addPhone(number);
	}

	addSection(heading?: string): void {
		new DetailSection({heading}, this.detailEl);
	}

	addSubheading(text: string): void {
		const container = this.headerHeadingContainerEl();
		if (container) {
			const el = El.span({classNames: 'pb-contact-card__header-subheading'}, container);
			el.setText(text);
		}
	}

	clearContactDetails(): void {
		if (this.contactInfo) {
			this.contactInfo.clear();
		}
	}

	closeToolbarMenu(): void {
		this.toolbarMenu.close();
	}

	contactDetailsHeading(): string {
		if (this.contactInfo) {
			return this.contactInfo.heading();
		}
		return '';
	}

	protected detailSectionEl(index: number): El | null {
		const els = this.detailSectionEls();
		if (index >= 0 && index < els.size()) {
			return els.at(index);
		}
		return null;
	}

	protected detailSectionEls(): list<El> {
		return this.detailEl.querySelectorAll(DetailSection.RootSelector);
	}

	protected ensureContactDetails(): ContactDetails {
		if (!this.contactInfo) {
			this.contactInfo = new ContactDetails();
			this.detailEl.insertAdjacentElement('afterbegin', this.contactInfo);
		}
		return this.contactInfo;
	}

	protected headerEl(): El | null {
		return this.querySelector('.pb-contact-card__header');
	}

	protected headerHeadingContainerEl(): El | null {
		const el = this.headerEl();
		if (el) {
			return el.querySelector('.pb-contact-card__header-heading');
		}
		return null;
	}

	headerHeadingHeaderEl(): El | null {
		const el = this.headerHeadingContainerEl();
		if (el) {
			return el.querySelector('h4');
		}
		return null;
	}

	protected headerMediaEl(): El | null {
		const header = this.headerEl();
		if (header) {
			return header.querySelector('.pb-contact-card__header-media');
		}
		return null;
	}

	protected headerSubheadingEl(index: number = 0): El | null {
		const els = this.headerSubheadingEls();
		if (index >= 0 && index < els.size()) {
			return els.at(index);
		}
		return null;
	}

	protected headerSubheadingEls(): list<El> {
		const el = this.headerHeadingContainerEl();
		if (el) {
			return el.querySelectorAll('.pb-contact-card__header-subheading');
		}
		return new list<El>();
	}

	heading(): string {
		const el = this.headerHeadingHeaderEl();
		return el ? el.text() : '';
	}

	hideToolbar(): void {
		this.setToolbarVisible(false);
	}

	@bind
	protected iconButtonEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick) {
			const e = <IconButtonClickEvt>evt;
			const name = e.iconButton().name();
			if (name === 'more') {
				const cc = e.pos();
				if (cc) {
					this.toolbarMenu.anchorToBody(cc.x(), cc.y());
					this.toolbarMenu.setOpen(!this.toolbarMenu.isOpen());
				}
			}
			this.notifyEvt(evt);
		}
	}

	section(index: number = 0): El {
		const els = this.detailSectionEls();
		if (index < 0) {
			index = Math.max(0, els.size() - 1);
		}
		if (index >= els.size()) {
			let diff = index - els.size() + 1;
			assert(diff > 0);
			while (diff) {
				this.addSection();
				--diff;
			}
		}
		const el = this.detailSectionEl(index);
		assert(el);
		return el;
	}

	sectionHeading(index: number = 0): string {
		const el = this.detailSectionEl(index);
		return el ? el.text() : '';
	}

	setContactDetailsHeading(text: string): void {
		this.ensureContactDetails().setHeading(text);
	}

	setDetailVisible(visible: boolean): void {
		this.detailEl.setVisible(visible);
	}

	setHeading(text: string): void {
		const el = this.headerHeadingHeaderEl();
		if (el) {
			el.setText(text);
		}
	}

	setIcon(iconName: string): void {
		const el = this.headerMediaEl();
		if (el) {
			el.setText(iconName);
		}
	}

	setSectionHeading(text: string, index: number = 0): void {
		const headerEl = this.section(index).querySelector('.pb-contact-card__detail-heading');
		if (headerEl) {
			headerEl.setText(text);
		}
	}

	setSectionVisible(visible: boolean, index: number = 0): void {
		const el = this.detailSectionEl(index);
		if (el) {
			el.setVisible(visible);
		}
	}

	setSubheading(text: string, index: number = 0): void {
		const els = this.headerSubheadingEls();
		if (index < 0) {
			index = Math.max(0, els.size() - 1);
		}
		if (index >= els.size()) {
			let diff = index - els.size() + 1;
			this.setSubheadings(diff);
		}
		const el = this.headerSubheadingEl(index);
		if (el) {
			el.setText(text);
		}
	}

	private setSubheadings(count: number): void {
		assert(count >= 0);
		while (count) {
			this.addSubheading('');
			--count;
		}
	}

	setSubheadingVisible(visible: boolean, index: number = 0): void {
		const el = this.headerSubheadingEl(index);
		if (el) {
			el.setVisible(visible);
		}
	}

	setToolbarVisible(visible: boolean): void {
		const el = this.toolbarEl();
		if (el) {
			el.setVisible(visible);
		}
	}

	showToolbar(): void {
		this.setToolbarVisible(true);
	}

	subheading(index: number): El {
		const els = this.headerSubheadingEls();
		if (index < 0) {
			index = els.size() - 1;
		}
		if (index >= els.size()) {
			this.setSubheadings(index - els.size() + 1);
		}
		const el = this.headerSubheadingEl(index);
		assert(el);
		return el;
	}

	protected toolbarButtonEl(name: string): El | null {
		const toolbar = this.toolbarEl();
		if (toolbar) {
			return toolbar.querySelector(`[name="${name}"]${IconButton.RootSelector}`);
		}
		return null;
	}

	setToolbarButtonVisible(which: 'edit' | 'more' | 'close', visible: boolean): void {
		const el = this.toolbarButtonEl(which);
		if (el) {
			el.setVisible(visible);
		}
	}

	protected toolbarEl(): El | null {
		return this.querySelector('.pb-contact-card__header-toolbar');
	}

	@bind
	protected toolbarMenuEvt(evt: Evt): void {
		if (evt.type() === Evt.Select) {
			const e = <ListItemSelectEvt>evt;
			if (e.index() === 0) {
				this.notifyEvt(new Evt(Evt.Delete));
			}
		}
	}
}

interface DetailSectionOpts {
	heading: string;
}

class DetailSection extends El {
	static RootSelector: string = '.pb-contact-card__detail-section';

	constructor(opts: Partial<DetailSectionOpts> | null, parent?: El | null);
	constructor(opts?: Partial<DetailSectionOpts> | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<DetailSectionOpts> | El | null, b?: El | null) {
		let opts: Partial<DetailSectionOpts> = {};
		let parent: El | null = null;
		if (a) {
			if (a instanceof El) {
				parent = a;
			} else {
				opts = a;
			}
		}
		if (b) {
			parent = b;
		}
		super('div', parent);
		this.addClass('pb-contact-card__detail-section');
		const header = new El({classNames: ['mdc-typography', 'mdc-typography--headline5', 'pb-contact-card__detail-heading']}, 'h4', this);
		if (opts.heading) {
			header.setText(opts.heading);
		}
	}

	protected headerEl(): El | null {
		return this.querySelector('.pb-contact-card__detail-heading');
	}

	heading(): string {
		const el = this.headerEl();
		return el ? el.text() : '';
	}

	setHeading(text: string): void {
		const el = this.headerEl();
		if (el) {
			el.setText(text);
		}
	}
}

class ContactDetails extends DetailSection {
	list: List;
	emailItemCount: number;

	constructor(opts: Partial<DetailSectionOpts> | null, parent?: El | null);
	constructor(opts?: Partial<DetailSectionOpts> | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<DetailSectionOpts> | El | null, b?: El | null) {
		let opts: Partial<DetailSectionOpts> = {};
		let parent: El | null = null;
		if (a) {
			if (a instanceof El) {
				parent = a;
			} else {
				opts = a;
			}
		}
		if (b) {
			parent = b;
		}
		if (!opts.heading) {
			opts.heading = 'Contact details';
		}
		super(opts, parent);
		this.emailItemCount = 0;
		this.list = new List(this);
	}

	addEmail(address: string, receiveNotifications?: boolean, cb?: (evt: Evt) => any): void {
		const iconName = (receiveNotifications === undefined) ? undefined : receiveNotifications ? 'notifications' : 'notifications_off';
		const button = new IconButton({iconName: iconName});
		if (cb) {
			button.onEvt(cb);
		}
		if (receiveNotifications) {
			button.setStyleProperty('color', 'rgb(45, 101, 255)');
		}
		const item = this.list.insertItem(this.emailItemCount, {leadingIcon: (this.emailItemCount > 0) ? '<blank>' : 'mail', text: address, textIsAnchor: true, trailingEl: button});
		const textEl = item.textEl();
		if (textEl) {
			textEl.setAttribute('href', `mailto:${address}`);
		}
		++this.emailItemCount;
	}

	addPhone(number: string): void {
		this.list.addItem({leadingIcon: ((this.emailItemCount + 1) <= this.list.size()) ? '<blank>' : 'phone', text: number});
	}

	clear(): void {
		this.list.clear();
		this.emailItemCount = 0;
	}

	destroy(): void {
		this.emailItemCount = 0;
		this.list.destroy();
		super.destroy();
	}
}

import React from 'react';

import LabeledControl from './labeledcontrol';
import {cssClassName, isNumber} from '../../../../util';

type Option = {id: number; name: string;}

interface IProps<T extends Option = Option> {
	className?: string;
	onChange: (value: number[]) => any;
	options: T[];
	value: number[];
}

export default class ClientUserTypeSelect extends React.Component<IProps, {}> {
	constructor(props: IProps) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent<HTMLSelectElement>): void {
		const {onChange} = this.props;
		const ids: number[] = [];
		const opts = event.target.selectedOptions;
		for (let i = 0; i < opts.length; ++i) {
			const opt = opts[i];
			const val = Number.parseInt(opt.value);
			if (isNumber(val)) {
				ids.push(val);
			}
		}
		onChange(ids);
	}

	render(): React.ReactNode {
		const {className, options, value} = this.props;
		const selected = value.map(x => String(x));
		return (
			<LabeledControl htmlFor="id_clientUserTypeId-select" label="Exclusive to client types">
				<select className={cssClassName('width--100-percent margin-top--8', className)} id="id_clientUserTypeId-select" multiple={true} name="clientUserTypeId" onChange={this.changeEvent} value={selected}>
					{options.map(obj =>
						<option key={obj.id} value={obj.id}>
							{obj.name}
						</option>)}
				</select>
			</LabeledControl>
		);
	}
}

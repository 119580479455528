import React, {PropsWithChildren} from 'react';
import {
	MDCCheckboxAdapter,
	MDCCheckboxFoundation,
} from '@material/checkbox';

import {assert, cssClassName} from '../../util';

interface IProps extends Omit<React.InputHTMLAttributes<any>, 'type'> {
	checked: boolean;
	indeterminate?: boolean;
}

interface IState {
	classNames: Set<string>;
	inputDisabled: boolean;
}

type Props = PropsWithChildren<IProps>;
type State = IState;

export default class Checkbox extends React.Component<Props, State> {
	private ctrl: MDCCheckboxFoundation | null;
	private _inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: Props) {
		super(props);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcIsChecked = this._mdcIsChecked.bind(this);
		this._mdcForceLayout = this._mdcForceLayout.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdcIsAttachedToDOM = this._mdcIsAttachedToDOM.bind(this);
		this._mdcIsIndeterminate = this._mdcIsIndeterminate.bind(this);
		this._mdcHasNativeControl = this._mdcHasNativeControl.bind(this);
		this._mdcSetNativeControlAttr = this._mdcSetNativeControlAttr.bind(this);
		this._mdcRemoveNativeControlAttr = this._mdcRemoveNativeControlAttr.bind(this);
		this._mdcSetNativeControlDisabled = this._mdcSetNativeControlDisabled.bind(this);
		this.state = {
			classNames: new Set(['mdc-checkbox']),
			inputDisabled: false,
		};
		this._inputRef = React.createRef();
		this.ctrl = null;
	}

	componentDidMount(): void {
		const {disabled} = this.props;
		this.ctrl = new MDCCheckboxFoundation(this._mdcAdapter());
		this.ctrl.init();
		this.ctrl.setDisabled(Boolean(disabled));
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (this.ctrl) {
			const {disabled: disabledPrev} = prevProps;
			const {disabled} = this.props;
			if (disabled !== disabledPrev) {
				this.ctrl.setDisabled(Boolean(disabled));
			}
		}
	}

	componentWillUnmount(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
		}
	}

	render(): React.ReactNode {
		const {
			checked: inputChecked,
			className,
			disabled,
			...restAttrs
		} = this.props;
		const {classNames, inputDisabled} = this.state;
		return (
			<div className={cssClassName(...classNames, className)}>
				<input
					checked={inputChecked}
					className="mdc-checkbox__native-control"
					disabled={inputDisabled}
					ref={this._inputRef}
					type="checkbox"
					{...restAttrs}/>
				<div className="mdc-checkbox__background">
					<svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
						<path className="mdc-checkbox__checkmark-path" d="M1.73,12.91 8.1,19.28 22.79,4.59" fill="none"/>
					</svg>
					<div className="mdc-checkbox__mixedmark"/>
				</div>
				<div className="mdc-checkbox__ripple"/>
			</div>
		);
	}

	private _mdcAdapter(): MDCCheckboxAdapter {
		return {
			addClass: this._mdcAddClass,
			forceLayout: this._mdcForceLayout,
			hasNativeControl: this._mdcHasNativeControl,
			isAttachedToDOM: this._mdcIsAttachedToDOM,
			isChecked: this._mdcIsChecked,
			isIndeterminate: this._mdcIsIndeterminate,
			removeClass: this._mdcRemoveClass,
			removeNativeControlAttr: this._mdcRemoveNativeControlAttr,
			setNativeControlAttr: this._mdcSetNativeControlAttr,
			setNativeControlDisabled: this._mdcSetNativeControlDisabled,
		};
	}

	private _mdcAddClass(className: string): void {
		const {classNames} = this.state;
		classNames.add(className);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcForceLayout(): void {
		this.forceUpdate();
	}

	private _mdcHasNativeControl(): boolean {
		try {
			this._inputOrDie();
			return true;
		} catch {
			return false;
		}
	}

	private _mdcIsAttachedToDOM(): boolean {
		return true;
	}

	private _mdcIsChecked(): boolean {
		return this.props.checked;
	}

	private _mdcIsIndeterminate(): boolean {
		return Boolean(this.props.indeterminate);
	}

	private _mdcRemoveClass(className: string): void {
		const {classNames} = this.state;
		classNames.delete(className);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcRemoveNativeControlAttr(attr: string): void {
		this._inputOrDie().removeAttribute(attr);
	}

	private _mdcSetNativeControlAttr(attr: string, value: string): void {
		this._inputOrDie().setAttribute(attr, value);
	}

	private _mdcSetNativeControlDisabled(disabled: boolean): void {
		this.setState({inputDisabled: disabled});
	}

	private _inputOrDie(): HTMLInputElement {
		const elem = this._inputRef.current;
		assert(elem, 'Input ref current is null');
		return elem;
	}
}

import {combineReducers} from 'redux';

import user from './user';
import buggy from './buggy';
import global from './global';
import producer from './producer';
import {set} from '../../tools';
import projectCreate from './projectcreate';
import catalog, * as fromCatalog from './catalog';

export default combineReducers<IState>({
	buggy,
	catalog,
	global,
	producer,
	projectCreate,
	user,
});

export function getCatalogItemAddOns(state: IState, itemID: number): ICatalogItem[] {
	return fromCatalog.getAddOns(state.catalog, itemID);
}

export function getCatalogItemChildForSize(state: IState, itemID: number, size: number): ICatalogItem | null {
	return fromCatalog.getItemChildForSize(state.catalog, itemID, size);
}

export function getCatalogItemChildren(state: IState, itemID: number): ICatalogItem[] {
	return fromCatalog.getItemChildren(state.catalog, itemID);
}

export function getCatalogItemByID(state: IState, itemID: number): ICatalogItem | null {
	return fromCatalog.getItem(state.catalog, itemID);
}

export function getCatalogItemChoices(state: IState, itemID: number): IChoice[] {
	return fromCatalog.getChoices(state.catalog, itemID);
}

export function getCatalogItemsMaxSize(state: IState): number | null {
	return fromCatalog.getItemsMaxSize(state.catalog);
}

export function getCatalogItemsInBuggy(state: IState): ICatalogItem[] {
	const itemIDs = new set(state.buggy.itemIDs);
	return state.catalog.allItems.filter(item => itemIDs.has(item.id));
}

export function getTopLevelCatalogItems(state: IState): ICatalogItem[] {
	return fromCatalog.topLevelItems(state.catalog);
}

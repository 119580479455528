import React, {PropsWithChildren} from 'react';

import SegmentSelect from './segmentselect';
import {
	bind,
	range,
	isNumber,
	makeTime,
	to12Hour,
	toPeriod,
	cssClassName,
	secondsToDuration,
} from '../../../util';

// Keep in-sync with scss/_timeline.scss $temp-hour-height
const CSS_HOUR_HEIGHT_PX = 48;

interface IProps {
	events?: ISchedulerBlock[];
	onClick: (value: string) => any;
	onSegmentClick: (data: ITime) => any;
	segmentStep: number;
	times: ITime[];
	timeSelectAtIsoTime: string;
}

type Props = PropsWithChildren<IProps>;

export default class Times extends React.Component<Props, {}> {
	earliestHour(): number | null {
		const {times} = this.props;
		if (times.length > 0) {
			return times[0].hour;
		}
		return null;
	}

	eventHeight(duration: number): number {
		const dur = secondsToDuration(duration);
		const hourFraction = dur.minutes / 60;
		const numHourBlocks = dur.hours;
		return ((CSS_HOUR_HEIGHT_PX * numHourBlocks) + (CSS_HOUR_HEIGHT_PX * hourFraction));
	}

	eventYOffset(eventTime: ITime): number {
		const earlyHour = this.earliestHour();
		if (earlyHour || (typeof earlyHour === 'number')) {
			const evtHour = eventTime.hour;
			const hourFraction = eventTime.minute / 60;
			return this.yOffset(evtHour - earlyHour, hourFraction);
		}
		return 0;
	}

	noTime(): boolean {
		return this.props.times.length < 1;
	}

	render() {
		const {
			events,
			times,
		} = this.props;
		return (
			<div className="pb-timeline-scroll-container margin-top--16">
				<div className="pb-timeline-container">
					{times.map((time, idx) =>
						<Hour
							key={time.isoformat}
							onClick={this.timeClicked.bind(this, idx)}/>)}
					<div className="pb-timeline-left-rail"/>
					{(events || []).map(evt =>
						<Event
							color={evt.color}
							height={this.eventHeight(evt.duration)}
							interactive={evt.interactive}
							key={evt.start.time.isoformat}
							yOffset={this.eventYOffset(evt.start.time)}
							zIndex={evt.zIndex}/>)}
					<LeftRail times={times}/>
					<Dividers count={times.length}/>
					{this.showSegmentSelect() ?
						<SegmentSelect onClick={this.segmentSelect} segments={this.selectSegments()} yOffset={this.segmentSelectYOffset()}/> :
						null}
				</div>
			</div>
		);
	}

	@bind
	segmentSelect(index: number): void {
		const segments = this.selectSegments();
		const segment = segments[index];
		if (!segment) {
			console.log('Times::segmentSelect index of out of range');
			return;
		}
		this.props.onSegmentClick(segment);
	}

	segmentSelectYOffset(): number {
		const {timeSelectAtIsoTime} = this.props;
		if (timeSelectAtIsoTime) {
			const idx = this.timeIndexByISOFormat(timeSelectAtIsoTime);
			return this.yOffset((idx >= 0) ? idx : 0);
		}
		return 0;
	}

	selectSegments(): ITime[] {
		const {segmentStep, timeSelectAtIsoTime} = this.props;
		const time = this.timeByISOFormat(timeSelectAtIsoTime);
		if (time) {
			const segments: ITime[] = [];
			const hour = time.hour;
			let minute = 0;
			while (minute < 60) {
				segments.push(makeTime(hour, minute));
				minute += segmentStep;
			}
			return segments;
		}
		return [];
	}

	showSegmentSelect(): boolean {
		return Boolean(this.props.timeSelectAtIsoTime);
	}

	timeByISOFormat(fmat: string): ITime | null {
		const {times} = this.props;
		const idx = times.findIndex(t => (t.isoformat === fmat));
		const time = times[idx];
		return time ? time : null;
	}

	timeClicked(index: number): void {
		const {onClick, times} = this.props;
		const time = times[index];
		onClick(time ? time.isoformat : '');
	}

	timeIndexByISOFormat(fmat: string): number {
		const {times} = this.props;
		return times.findIndex(t => (t.isoformat === fmat));
	}

	yOffset(wholeHours: number, hourFraction: number = 0): number {
		return (wholeHours * CSS_HOUR_HEIGHT_PX) + (CSS_HOUR_HEIGHT_PX * hourFraction);
	}
}

function Dividers({count}: {count: number}) {
	return (
		<div className="pb-timeline-dividers">
			<div className="pb-timeline-v-divider"/>
			{range(count).map(i => <div className="pb-timeline-h-divider" key={i}/>)}
		</div>
	);
}

interface IEventProps extends PropsWithChildren<{}> {
	color?: string;
	height: number;
	interactive?: boolean;
	yOffset: number;
	zIndex?: number;
}

function Event(props: IEventProps) {
	const {children, color, height, interactive, yOffset, zIndex} = props;
	const style = {
		backgroundColor: color || '#DBE1EA',
		height: `${height}px`,
		top: `${yOffset}px`,
		zIndex: isNumber(zIndex) ? zIndex : undefined,
	};
	return (
		<div className={`pb-timeline-event${interactive ? ' pointer-events--none' : ''}`} style={style}>
			{children}
		</div>
	);
}

function Hour({disabled, onClick}: {disabled?: boolean; onClick: () => any}) {
	return <div
		className={cssClassName('pb-timeline-hour', {'pb-timeline-hour--disabled': disabled})}
		onClick={onClick}/>;
}

function LeftRail({times}: {times: ITime[]}) {
	return (
		<div className="pb-timeline-left-rail-items">
			{times.map((time, idx) =>
				<div className="pb-timeline-left-rail-item" key={idx}>
					<div>{to12Hour(time.hour)}</div>
					<div className="font-weight--300">{toPeriod(time.hour)}</div>
				</div>)}
		</div>
	);
}

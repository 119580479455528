import {MDCComponent, MDCFoundation, SpecificEventListener, CustomEventListener} from '@material/base';
import {closest} from '@material/dom/ponyfill';
import {MDCListActionEvent, MDCListIndex} from '@material/list/types';
import type {MDCList} from '@material/list/component';
import {cssClasses as listCssClasses, numbers as listConstants} from '@material/list/constants';
import {getTransformPropertyName} from '@material/menu-surface/util';
import {MDCMenuSurfaceAdapter, MDCMenuDimensions, MDCMenuDistance, MDCMenuPoint, CornerBit, Corner, cssClasses as surfaceCssClasses, numbers as surfaceNumbers, strings as surfaceStrings} from '@material/menu-surface';
import {MDCMenuAdapter as MDCMenuAdapterSucks, MDCMenuItemComponentEventDetail, strings as menuStrings, cssClasses as menuCssClasses, numbers as menuNumbers, DefaultFocusState} from '@material/menu';

export {Corner};

interface AutoLayoutMeasurements {
	anchorSize: MDCMenuDimensions;
	bodySize: MDCMenuDimensions;
	surfaceSize: MDCMenuDimensions;
	viewportDistance: MDCMenuDistance;
	viewportSize: MDCMenuDimensions;
	windowScroll: MDCMenuPoint;
}

class MDCMenuSurfaceFoundation extends MDCFoundation<MDCMenuSurfaceAdapter> {
	static get cssClasses() {
		return surfaceCssClasses;
	}

	static get strings() {
		return surfaceStrings;
	}

	static get numbers() {
		return surfaceNumbers;
	}

	static get Corner() {
		return Corner;
	}

	static get defaultAdapter(): MDCMenuSurfaceAdapter {
		return {
			addClass: () => undefined,
			removeClass: () => undefined,
			hasClass: () => false,
			hasAnchor: () => false,
			isElementInContainer: () => false,
			isFocused: () => false,
			isRtl: () => false,
			getInnerDimensions: () => ({height: 0, width: 0}),
			getAnchorDimensions: () => null,
			getWindowDimensions: () => ({height: 0, width: 0}),
			getBodyDimensions: () => ({height: 0, width: 0}),
			getWindowScroll: () => ({x: 0, y: 0}),
			setPosition: () => undefined,
			setMaxHeight: () => undefined,
			setTransformOrigin: () => undefined,
			saveFocus: () => undefined,
			restoreFocus: () => undefined,
			notifyClose: () => undefined,
			notifyOpen: () => undefined,
		};
	}

	private isSurfaceOpen = false;
	private isQuickOpen = false;
	private isHoistedElement = false;
	private isFixedPosition = false;
	private openAnimationEndTimerId = 0;
	private closeAnimationEndTimerId = 0;
	private animationRequestId = 0;
	private anchorCorner: Corner = Corner.TOP_START;
	private originCorner: Corner = Corner.TOP_START;
	private readonly anchorMargin: MDCMenuDistance = {top: 0, right: 0, bottom: 0, left: 0};
	private readonly position: MDCMenuPoint = {x: 0, y: 0};
	private dimensions!: MDCMenuDimensions;         // assigned in open()
	private measurements!: AutoLayoutMeasurements;  // assigned in open()
	constructor(adapter?: Partial<MDCMenuSurfaceAdapter>) {
		super({...MDCMenuSurfaceFoundation.defaultAdapter, ...adapter});
	}

	init() {
		const {ROOT, OPEN} = MDCMenuSurfaceFoundation.cssClasses;
		if (!this.adapter.hasClass(ROOT)) {
			throw new Error(`${ROOT} class required in root element.`);
		}
		if (this.adapter.hasClass(OPEN)) {
			this.isSurfaceOpen = true;
		}
	}

	destroy() {
		clearTimeout(this.openAnimationEndTimerId);
		clearTimeout(this.closeAnimationEndTimerId);
		// Cancel any currently running animations.
		cancelAnimationFrame(this.animationRequestId);
	}

	setAnchorCorner(corner: Corner) {
		this.anchorCorner = corner;
	}

	flipCornerHorizontally() {
		this.originCorner = this.originCorner ^ CornerBit.RIGHT;
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		this.anchorMargin.top = margin.top || 0;
		this.anchorMargin.right = margin.right || 0;
		this.anchorMargin.bottom = margin.bottom || 0;
		this.anchorMargin.left = margin.left || 0;
	}

	/** Used to indicate if the menu-surface is hoisted to the body. */
	setIsHoisted(isHoisted: boolean) {
		this.isHoistedElement = isHoisted;
	}

	/** Used to set the menu-surface calculations based on a fixed position menu. */
	setFixedPosition(isFixedPosition: boolean) {
		this.isFixedPosition = isFixedPosition;
	}

	/** Sets the menu-surface position on the page. */
	setAbsolutePosition(x: number, y: number) {
		this.position.x = this.isFinite(x) ? x : 0;
		this.position.y = this.isFinite(y) ? y : 0;
	}

	setQuickOpen(quickOpen: boolean) {
		this.isQuickOpen = quickOpen;
	}

	isOpen() {
		return this.isSurfaceOpen;
	}

	/**
	 * Open the menu surface.
	 */
	open() {
		if (this.isSurfaceOpen) {
			return;
		}
		this.adapter.saveFocus();
		if (this.isQuickOpen) {
			this.isSurfaceOpen = true;
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
			this.dimensions = this.adapter.getInnerDimensions();
			this.autoposition();
			this.adapter.notifyOpen();
		} else {
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.ANIMATING_OPEN);
			this.animationRequestId = requestAnimationFrame(() => {
				this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
				this.dimensions = this.adapter.getInnerDimensions();
				this.autoposition();
				this.openAnimationEndTimerId = setTimeout(() => {
					this.openAnimationEndTimerId = 0;
					this.adapter.removeClass(
						MDCMenuSurfaceFoundation.cssClasses.ANIMATING_OPEN);
					this.adapter.notifyOpen();
				}, surfaceNumbers.TRANSITION_OPEN_DURATION);
			});
			this.isSurfaceOpen = true;
		}
	}

	/**
	 * Closes the menu surface.
	 */
	close(skipRestoreFocus = false) {
		if (!this.isSurfaceOpen) {
			return;
		}
		if (this.isQuickOpen) {
			this.isSurfaceOpen = false;
			if (!skipRestoreFocus) {
				this.maybeRestoreFocus();
			}
			this.adapter.removeClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
			this.adapter.removeClass(
				MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
			this.adapter.notifyClose();
		} else {
			this.adapter.addClass(
				MDCMenuSurfaceFoundation.cssClasses.ANIMATING_CLOSED);
			requestAnimationFrame(() => {
				this.adapter.removeClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
				this.adapter.removeClass(
					MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
				this.closeAnimationEndTimerId = setTimeout(() => {
					this.closeAnimationEndTimerId = 0;
					this.adapter.removeClass(
						MDCMenuSurfaceFoundation.cssClasses.ANIMATING_CLOSED);
					this.adapter.notifyClose();
				}, surfaceNumbers.TRANSITION_CLOSE_DURATION);
			});
			this.isSurfaceOpen = false;
			if (!skipRestoreFocus) {
				this.maybeRestoreFocus();
			}
		}
	}

	/** Handle clicks and close if not within menu-surface element. */
	handleBodyClick(evt: MouseEvent) {
		const el = evt.target as Element;
		if (this.adapter.isElementInContainer(el)) {
			return;
		}
		this.close();
	}

	/** Handle keys that close the surface. */
	handleKeydown(evt: KeyboardEvent) {
		const {keyCode, key} = evt;
		const isEscape = key === 'Escape' || keyCode === 27;
		if (isEscape) {
			this.close();
		}
	}

	autoposition() {
		// Compute measurements for autoposition methods reuse.
		this.measurements = this.getAutoLayoutmeasurements();
		const corner = this.getoriginCorner();
		const maxMenuSurfaceHeight = this.getMenuSurfaceMaxHeight(corner);
		const verticalAlignment =
			this.hasBit(corner, CornerBit.BOTTOM) ? 'bottom' : 'top';
		let horizontalAlignment =
			this.hasBit(corner, CornerBit.RIGHT) ? 'right' : 'left';
		const horizontalOffset = this.getHorizontalOriginOffset(corner);
		const verticalOffset = this.getVerticalOriginOffset(corner);
		const {anchorSize, surfaceSize} = this.measurements;
		const position: Partial<MDCMenuDistance> = {
			[horizontalAlignment]: horizontalOffset,
			[verticalAlignment]: verticalOffset,
		};
		// Center align when anchor width is comparable or greater than menu surface, otherwise keep corner.
		if (anchorSize.width / surfaceSize.width > surfaceNumbers.ANCHOR_TO_MENU_SURFACE_WIDTH_RATIO) {
			horizontalAlignment = 'center';
		}
		// If the menu-surface has been hoisted to the body, it's no longer relative to the anchor element
		if (this.isHoistedElement || this.isFixedPosition) {
			this.adjustPositionForHoistedElement(position);
		}
		this.adapter.setTransformOrigin(
			`${horizontalAlignment} ${verticalAlignment}`);
		this.adapter.setPosition(position);
		this.adapter.setMaxHeight(
			maxMenuSurfaceHeight ? maxMenuSurfaceHeight + 'px' : '');
		// If it is opened from the top then add is-open-below class
		if (!this.hasBit(corner, CornerBit.BOTTOM)) {
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
		}
	}

	/**
	 * @return Measurements used to position menu surface popup.
	 */
	private getAutoLayoutmeasurements(): AutoLayoutMeasurements {
		let anchorRect = this.adapter.getAnchorDimensions();
		const bodySize = this.adapter.getBodyDimensions();
		const viewportSize = this.adapter.getWindowDimensions();
		const windowScroll = this.adapter.getWindowScroll();
		if (!anchorRect) {
			anchorRect = {
				top: this.position.y,
				right: this.position.x,
				bottom: this.position.y,
				left: this.position.x,
				width: 0,
				height: 0,
			};
		}
		return {
			anchorSize: anchorRect,
			bodySize,
			surfaceSize: this.dimensions,
			viewportDistance: {
				top: anchorRect.top,
				right: viewportSize.width - anchorRect.right,
				bottom: viewportSize.height - anchorRect.bottom,
				left: anchorRect.left,
			},
			viewportSize,
			windowScroll,
		};
	}

	/**
	 * Computes the corner of the anchor from which to animate and position the
	 * menu surface.
	 *
	 * Only LEFT or RIGHT bit is used to position the menu surface ignoring RTL
	 * context. E.g., menu surface will be positioned from right side on TOP_END.
	 */
	private getoriginCorner(): Corner {
		let corner = this.originCorner;
		const {viewportDistance, anchorSize, surfaceSize} = this.measurements;
		const {MARGIN_TO_EDGE} = MDCMenuSurfaceFoundation.numbers;
		const isAnchoredToBottom = this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		let availableTop;
		let availableBottom;
		if (isAnchoredToBottom) {
			availableTop = viewportDistance.top - MARGIN_TO_EDGE + anchorSize.height +
				this.anchorMargin.bottom;
			availableBottom =
				viewportDistance.bottom - MARGIN_TO_EDGE - this.anchorMargin.bottom;
		} else {
			availableTop =
				viewportDistance.top - MARGIN_TO_EDGE + this.anchorMargin.top;
			availableBottom = viewportDistance.bottom - MARGIN_TO_EDGE +
				anchorSize.height - this.anchorMargin.top;
		}
		const isAvailableBottom = availableBottom - surfaceSize.height > 0;
		if (!isAvailableBottom && availableTop >= availableBottom) {
			// Attach bottom side of surface to the anchor.
			corner = this.setBit(corner, CornerBit.BOTTOM);
		}
		const isRtl = this.adapter.isRtl();
		const isFlipRtl = this.hasBit(this.anchorCorner, CornerBit.FLIP_RTL);
		const hasRightBit = this.hasBit(this.anchorCorner, CornerBit.RIGHT) ||
			this.hasBit(corner, CornerBit.RIGHT);
		// Whether surface attached to right side of anchor element.
		let isAnchoredToRight = false;
		// Anchored to start
		if (isRtl && isFlipRtl) {
			isAnchoredToRight = !hasRightBit;
		} else {
			// Anchored to right
			isAnchoredToRight = hasRightBit;
		}
		let availableLeft;
		let availableRight;
		if (isAnchoredToRight) {
			availableLeft =
				viewportDistance.left + anchorSize.width + this.anchorMargin.right;
			availableRight = viewportDistance.right - this.anchorMargin.right;
		} else {
			availableLeft = viewportDistance.left + this.anchorMargin.left;
			availableRight =
				viewportDistance.right + anchorSize.width - this.anchorMargin.left;
		}
		const isAvailableLeft = availableLeft - surfaceSize.width > 0;
		const isAvailableRight = availableRight - surfaceSize.width > 0;
		const isOriginCornerAlignedToEnd =
			this.hasBit(corner, CornerBit.FLIP_RTL) &&
			this.hasBit(corner, CornerBit.RIGHT);
		if (isAvailableRight && isOriginCornerAlignedToEnd && isRtl ||
			!isAvailableLeft && isOriginCornerAlignedToEnd) {
			// Attach left side of surface to the anchor.
			corner = this.unsetBit(corner, CornerBit.RIGHT);
		} else if (
			isAvailableLeft && isAnchoredToRight && isRtl ||
			(isAvailableLeft && !isAnchoredToRight && hasRightBit) ||
			(!isAvailableRight && availableLeft >= availableRight)) {
			// Attach right side of surface to the anchor.
			corner = this.setBit(corner, CornerBit.RIGHT);
		}
		return corner;
	}

	/**
	 * @param corner Origin corner of the menu surface.
	 * @return Maximum height of the menu surface, based on available space. 0 indicates should not be set.
	 */
	private getMenuSurfaceMaxHeight(corner: Corner): number {
		const {viewportDistance} = this.measurements;
		let maxHeight = 0;
		const isBottomAligned = this.hasBit(corner, CornerBit.BOTTOM);
		const isBottomAnchored = this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		const {MARGIN_TO_EDGE} = MDCMenuSurfaceFoundation.numbers;
		// When maximum height is not specified, it is handled from CSS.
		if (isBottomAligned) {
			maxHeight = viewportDistance.top + this.anchorMargin.top - MARGIN_TO_EDGE;
			if (!isBottomAnchored) {
				maxHeight += this.measurements.anchorSize.height;
			}
		} else {
			maxHeight = viewportDistance.bottom - this.anchorMargin.bottom +
				this.measurements.anchorSize.height - MARGIN_TO_EDGE;
			if (isBottomAnchored) {
				maxHeight -= this.measurements.anchorSize.height;
			}
		}
		return maxHeight;
	}

	/**
	 * @param corner Origin corner of the menu surface.
	 * @return Horizontal offset of menu surface origin corner from corresponding anchor corner.
	 */
	private getHorizontalOriginOffset(corner: Corner): number {
		const {anchorSize} = this.measurements;
		// isRightAligned corresponds to using the 'right' property on the surface.
		const isRightAligned = this.hasBit(corner, CornerBit.RIGHT);
		const avoidHorizontalOverlap =
			this.hasBit(this.anchorCorner, CornerBit.RIGHT);
		if (isRightAligned) {
			const rightOffset = avoidHorizontalOverlap ?
				anchorSize.width - this.anchorMargin.left :
				this.anchorMargin.right;
			// For hoisted or fixed elements, adjust the offset by the difference
			// between viewport width and body width so when we calculate the right
			// value (`adjustPositionForHoistedElement`) based on the element
			// position, the right property is correct.
			if (this.isHoistedElement || this.isFixedPosition) {
				return rightOffset -
					(this.measurements.viewportSize.width -
						this.measurements.bodySize.width);
			}
			return rightOffset;
		}
		return avoidHorizontalOverlap ? anchorSize.width - this.anchorMargin.right :
			this.anchorMargin.left;
	}

	/**
	 * @param corner Origin corner of the menu surface.
	 * @return Vertical offset of menu surface origin corner from corresponding anchor corner.
	 */
	private getVerticalOriginOffset(corner: Corner): number {
		const {anchorSize} = this.measurements;
		const isBottomAligned = this.hasBit(corner, CornerBit.BOTTOM);
		const avoidVerticalOverlap =
			this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		let y = 0;
		if (isBottomAligned) {
			y = avoidVerticalOverlap ? anchorSize.height - this.anchorMargin.top :
				-this.anchorMargin.bottom;
		} else {
			y = avoidVerticalOverlap ?
				(anchorSize.height + this.anchorMargin.bottom) :
				this.anchorMargin.top;
		}
		return y;
	}

	/** Calculates the offsets for positioning the menu-surface when the menu-surface has been hoisted to the body. */
	private adjustPositionForHoistedElement(position: Partial<MDCMenuDistance>) {
		const {windowScroll, viewportDistance} = this.measurements;
		const props = Object.keys(position) as Array<keyof Partial<MDCMenuDistance>>;
		for (const prop of props) {
			let value = position[prop] || 0;
			// Hoisted surfaces need to have the anchor elements location on the page added to the
			// position properties for proper alignment on the body.
			value += viewportDistance[prop];
			// Surfaces that are absolutely positioned need to have additional calculations for scroll
			// and bottom positioning.
			if (!this.isFixedPosition) {
				if (prop === 'top') {
					value += windowScroll.y;
				} else if (prop === 'bottom') {
					value -= windowScroll.y;
				} else if (prop === 'left') {
					value += windowScroll.x;
				} else { // prop === 'right'
					value -= windowScroll.x;
				}
			}
			position[prop] = value;
		}
	}

	/**
	 * The last focused element when the menu surface was opened should regain focus, if the user is
	 * focused on or within the menu surface when it is closed.
	 */
	private maybeRestoreFocus() {
		const isRootFocused = this.adapter.isFocused();
		const childHasFocus = document.activeElement &&
			this.adapter.isElementInContainer(document.activeElement);
		if (isRootFocused || childHasFocus) {
			this.adapter.restoreFocus();
		}
	}

	private hasBit(corner: Corner, bit: CornerBit): boolean {
		return Boolean(corner & bit);
	}

	private setBit(corner: Corner, bit: CornerBit): Corner {
		return corner | bit;
	}

	private unsetBit(corner: Corner, bit: CornerBit): Corner {
		return corner ^ bit;
	}

	private isFinite(num: number): boolean {
		return typeof num === 'number' && isFinite(num);
	}
}

export type MDCMenuSurfaceFactory = (el: Element, foundation?: MDCMenuSurfaceFoundation) => MDCMenuSurface;

export class MDCMenuSurface extends MDCComponent<MDCMenuSurfaceFoundation> {
	static attachTo(root: Element): MDCMenuSurface {
		return new MDCMenuSurface(root);
	}

	anchorElement!: Element | null;
	private previousFocus?: HTMLElement | SVGElement | null;
	// private handleKeydown!: SpecificEventListener<'keydown'>;
	// private handleBodyClick!: SpecificEventListener<'click'>;
	// private registerBodyClickListener!: RegisterFunction;
	// private deregisterBodyClickListener!: RegisterFunction;

	initialSyncWithDOM() {
		const parentEl = this.root.parentElement;
		this.anchorElement = parentEl && parentEl.classList.contains(surfaceCssClasses.ANCHOR) ? parentEl : null;
		if (this.root.classList.contains(surfaceCssClasses.FIXED)) {
			this.setFixedPosition(true);
		}
		// this.handleKeydown = event => this.foundation.handleKeydown(event);
		// this.handleBodyClick = event => this.foundation.handleBodyClick(event);
		// this.registerBodyClickListener = () => document.body.addEventListener('click', this.handleBodyClick, {capture: true});
		// this.deregisterBodyClickListener = () => document.body.removeEventListener('click', this.handleBodyClick, {capture: true});
		// this.listen('keydown', this.handleKeydown);
		// this.listen(surfaceStrings.OPENED_EVENT, this.registerBodyClickListener);
		// this.listen(surfaceStrings.CLOSED_EVENT, this.deregisterBodyClickListener);
	}

	autoposition(): void {
		this.foundation.autoposition();
	}

	destroy() {
		// this.unlisten('keydown', this.handleKeydown);
		// this.unlisten(surfaceStrings.OPENED_EVENT, this.registerBodyClickListener);
		// this.unlisten(surfaceStrings.CLOSED_EVENT, this.deregisterBodyClickListener);
		super.destroy();
	}

	isOpen(): boolean {
		return this.foundation.isOpen();
	}

	open() {
		this.foundation.open();
	}

	close(skipRestoreFocus = false) {
		this.foundation.close(skipRestoreFocus);
	}

	set quickOpen(quickOpen: boolean) {
		this.foundation.setQuickOpen(quickOpen);
	}

	setIsHoisted(isHoisted: boolean) {
		this.foundation.setIsHoisted(isHoisted);
	}

	setMenuSurfaceAnchorElement(element: Element) {
		this.anchorElement = element;
	}

	setFixedPosition(isFixed: boolean) {
		if (isFixed) {
			this.root.classList.add(surfaceCssClasses.FIXED);
		} else {
			this.root.classList.remove(surfaceCssClasses.FIXED);
		}
		this.foundation.setFixedPosition(isFixed);
	}

	setAbsolutePosition(x: number, y: number) {
		this.foundation.setAbsolutePosition(x, y);
		this.setIsHoisted(true);
	}

	setAnchorCorner(corner: Corner) {
		this.foundation.setAnchorCorner(corner);
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		this.foundation.setAnchorMargin(margin);
	}

	getDefaultFoundation() {
		const adapter: MDCMenuSurfaceAdapter = {
			addClass: className => this.root.classList.add(className),
			removeClass: className => this.root.classList.remove(className),
			hasClass: className => this.root.classList.contains(className),
			hasAnchor: () => !!this.anchorElement,
			notifyClose: () => this.emit(MDCMenuSurfaceFoundation.strings.CLOSED_EVENT, {}),
			notifyOpen: () => this.emit(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, {}),
			isElementInContainer: el => this.root.contains(el),
			isRtl: () => getComputedStyle(this.root).getPropertyValue('direction') === 'rtl',
			setTransformOrigin: origin => {
				const propertyName = `${getTransformPropertyName(window)}-origin`;
				(<HTMLElement>this.root).style.setProperty(propertyName, origin);
			},
			isFocused: () => document.activeElement === this.root,
			saveFocus: () => {
				this.previousFocus = <HTMLElement | SVGElement | null>document.activeElement;
			},
			restoreFocus: () => {
				if (this.root.contains(document.activeElement)) {
					if (this.previousFocus && this.previousFocus.focus) {
						this.previousFocus.focus();
					}
				}
			},
			getInnerDimensions: () => {
				return {
					width: (<HTMLElement>this.root).offsetWidth,
					height: (<HTMLElement>this.root).offsetHeight,
				};
			},
			getAnchorDimensions: () => this.anchorElement ? this.anchorElement.getBoundingClientRect() : null,
			getWindowDimensions: () => ({width: window.innerWidth, height: window.innerHeight}),
			getBodyDimensions: () => ({width: document.body.clientWidth, height: document.body.clientHeight}),
			getWindowScroll: () => ({x: window.pageXOffset, y: window.pageYOffset}),
			setPosition: position => {
				const rootHTML = <HTMLElement>this.root;
				rootHTML.style.left = 'left' in position ? `${position.left}px` : '';
				rootHTML.style.right = 'right' in position ? `${position.right}px` : '';
				rootHTML.style.top = 'top' in position ? `${position.top}px` : '';
				rootHTML.style.bottom = 'bottom' in position ? `${position.bottom}px` : '';
			},
			setMaxHeight: height => {
				(<HTMLElement>this.root).style.maxHeight = height;
			},
		};
		return new MDCMenuSurfaceFoundation(adapter);
	}
}

interface MDCMenuAdapter extends MDCMenuAdapterSucks {
	isAllowedToClose(): boolean;
	isElementInContainer(element: Element): boolean;
}

class MDCMenuFoundation extends MDCFoundation<MDCMenuAdapter> {
	static get cssClasses() {
		return menuCssClasses;
	}

	static get strings() {
		return menuStrings;
	}

	static get numbers() {
		return menuNumbers;
	}

	private closeAnimationEndTimerId_ = 0;
	private defaultFocusState_ = DefaultFocusState.LIST_ROOT;

	static get defaultAdapter(): MDCMenuAdapter {
		return {
			addClassToElementAtIndex: () => undefined,
			removeClassFromElementAtIndex: () => undefined,
			addAttributeToElementAtIndex: () => undefined,
			removeAttributeFromElementAtIndex: () => undefined,
			elementContainsClass: () => false,
			closeSurface: () => undefined,
			getElementIndex: () => -1,
			notifySelected: () => undefined,
			getMenuItemCount: () => 0,
			focusItemAtIndex: () => undefined,
			focusListRoot: () => undefined,
			getSelectedSiblingOfItemAtIndex: () => -1,
			isAllowedToClose: () => true,
			isSelectableItemAtIndex: () => false,
			isElementInContainer: () => false,
		};
	}

	constructor(adapter?: Partial<MDCMenuAdapter>) {
		super({...MDCMenuFoundation.defaultAdapter, ...adapter});
	}

	destroy() {
		if (this.closeAnimationEndTimerId_) {
			clearTimeout(this.closeAnimationEndTimerId_);
		}
		this.adapter.closeSurface();
	}

	handleKeydown(evt: KeyboardEvent) {
		const {key, keyCode} = evt;
		const isTab = key === 'Tab' || keyCode === 9;
		if (isTab) {
			this.adapter.closeSurface(/** skipRestoreFocus */ true);
		}
	}

	handleItemAction(listItem: Element) {
		// const index = this.adapter.getElementIndex(listItem);
		// if (index < 0) {
		// 	return;
		// }
		// this.adapter.notifySelected({index});
		// if (this.adapter.isAllowedToClose()) {
		// 	this.adapter.closeSurface();
		// 	this.closeAnimationEndTimerId_ = setTimeout(() => {
		// 		const recomputedIndex = this.adapter.getElementIndex(listItem);
		// 		if (recomputedIndex >= 0 &&
		// 			this.adapter.isSelectableItemAtIndex(recomputedIndex)) {
		// 			this.setSelectedIndex(recomputedIndex);
		// 		}
		// 	}, MDCMenuSurfaceFoundation.numbers.TRANSITION_CLOSE_DURATION);
		// }
	}

	handleMenuSurfaceOpened() {
		switch (this.defaultFocusState_) {
			case DefaultFocusState.FIRST_ITEM:
				this.adapter.focusItemAtIndex(0);
				break;
			case DefaultFocusState.LAST_ITEM:
				this.adapter.focusItemAtIndex(this.adapter.getMenuItemCount() - 1);
				break;
			case DefaultFocusState.NONE:
				// Do nothing.
				break;
			default:
				this.adapter.focusListRoot();
				break;
		}
	}

	setDefaultFocusState(focusState: DefaultFocusState) {
		this.defaultFocusState_ = focusState;
	}

	setSelectedIndex(index: number) {
		this.validatedIndex_(index);
		if (!this.adapter.isSelectableItemAtIndex(index)) {
			throw new Error('MDCMenuFoundation: No selection group at specified index.');
		}
		const prevSelectedIndex =
			this.adapter.getSelectedSiblingOfItemAtIndex(index);
		if (prevSelectedIndex >= 0) {
			this.adapter.removeAttributeFromElementAtIndex(
				prevSelectedIndex, menuStrings.ARIA_CHECKED_ATTR);
			this.adapter.removeClassFromElementAtIndex(
				prevSelectedIndex, menuCssClasses.MENU_SELECTED_LIST_ITEM);
		}
		this.adapter.addClassToElementAtIndex(
			index, menuCssClasses.MENU_SELECTED_LIST_ITEM);
		this.adapter.addAttributeToElementAtIndex(
			index, menuStrings.ARIA_CHECKED_ATTR, 'true');
	}

	setEnabled(index: number, isEnabled: boolean): void {
		this.validatedIndex_(index);
		if (isEnabled) {
			this.adapter.removeClassFromElementAtIndex(
				index, listCssClasses.LIST_ITEM_DISABLED_CLASS);
			this.adapter.addAttributeToElementAtIndex(
				index, menuStrings.ARIA_DISABLED_ATTR, 'false');
		} else {
			this.adapter.addClassToElementAtIndex(
				index, listCssClasses.LIST_ITEM_DISABLED_CLASS);
			this.adapter.addAttributeToElementAtIndex(
				index, menuStrings.ARIA_DISABLED_ATTR, 'true');
		}
	}

	private validatedIndex_(index: number): void {
		const menuSize = this.adapter.getMenuItemCount();
		const isIndexInRange = index >= 0 && index < menuSize;
		if (!isIndexInRange) {
			throw new Error('MDCMenuFoundation: No list item at specified index.');
		}
	}
}

export class MDCMenu extends MDCComponent<MDCMenuFoundation> {
	static attachTo(root: Element) {
		return new MDCMenu(root);
	}

	private menuSurfaceFactory_!: MDCMenuSurfaceFactory;
	private menuSurface_!: MDCMenuSurface;
	private list_: MDCList | null;
	private handleKeydown_!: SpecificEventListener<'keydown'>;
	private handleItemAction_!: CustomEventListener<MDCListActionEvent>;
	isAllowedToCloseCallSnack: () => boolean;
	private handleMenuSurfaceOpened_!: EventListener;

	constructor(root: Element, list: MDCList | null = null, isAllowedToCloseCallSnack?: () => boolean, foundation?: MDCMenuFoundation, ...args: Array<unknown>) {
		super(root, foundation, ...args);
		this.list_ = list;
		this.isAllowedToCloseCallSnack = isAllowedToCloseCallSnack || (() => true);
	}

	initialize(menuSurfaceFactory: MDCMenuSurfaceFactory = (el) => new MDCMenuSurface(el)) {
		this.menuSurfaceFactory_ = menuSurfaceFactory;
	}

	initialSyncWithDOM() {
		this.menuSurface_ = this.menuSurfaceFactory_(this.root);
		this.handleKeydown_ = (evt) => this.foundation.handleKeydown(evt);
		this.handleItemAction_ = (evt) => this.foundation.handleItemAction(this.items[evt.detail.index]);
		this.handleMenuSurfaceOpened_ = () => this.foundation.handleMenuSurfaceOpened();
		this.menuSurface_.listen(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, this.handleMenuSurfaceOpened_);
		this.listen('keydown', this.handleKeydown_);
		this.listen('MDCList:action', this.handleItemAction_);
	}

	destroy() {
		if (this.list_) {
			this.list_.destroy();
		}
		this.menuSurface_.destroy();
		this.menuSurface_.unlisten(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, this.handleMenuSurfaceOpened_);
		this.unlisten('keydown', this.handleKeydown_);
		this.unlisten('MDCList:action', this.handleItemAction_);
		super.destroy();
	}

	get open(): boolean {
		return this.menuSurface_.isOpen();
	}

	set open(value: boolean) {
		if (value) {
			this.menuSurface_.open();
		} else {
			this.menuSurface_.close();
		}
	}

	get wrapFocus(): boolean {
		return this.list_ ? this.list_.wrapFocus : false;
	}

	set wrapFocus(value: boolean) {
		if (this.list_) {
			this.list_.wrapFocus = value;
		}
	}

	set hasTypeahead(value: boolean) {
		if (this.list_) {
			this.list_.hasTypeahead = value;
		}
	}

	get typeaheadInProgress() {
		return this.list_ ? this.list_.typeaheadInProgress : false;
	}

	/**
	 * Given the next desired character from the user, adds it to the typeahead
	 * buffer. Then, attempts to find the next option matching the buffer. Wraps
	 * around if at the end of options.
	 *
	 * @param nextChar The next character to add to the prefix buffer.
	 * @param startingIndex The index from which to start matching. Only relevant
	 *     when starting a new match sequence. To start a new match sequence,
	 *     clear the buffer using `clearTypeaheadBuffer`, or wait for the buffer
	 *     to clear after a set interval defined in list foundation. Defaults to
	 *     the currently focused index.
	 * @return The index of the matched item, or -1 if no match.
	 */
	typeaheadMatchItem(nextChar: string, startingIndex?: number): number {
		if (this.list_) {
			return this.list_.typeaheadMatchItem(nextChar, startingIndex);
		}
		return -1;
	}

	/**
	 * Layout the underlying list element in the case of any dynamic updates
	 * to its structure.
	 */
	layout() {
		if (this.list_) {
			this.list_.layout();
		}
	}

	/**
	 * Return the items within the menu. Note that this only contains the set of elements within
	 * the items container that are proper list items, and not supplemental / presentational DOM
	 * elements.
	 */
	get items(): Element[] {
		return this.list_ ? this.list_.listElements : [];
	}

	/**
	 * Turns on/off the underlying list's single selection mode. Used mainly
	 * by select menu.
	 *
	 * @param singleSelection Whether to enable single selection mode.
	 */
	set singleSelection(singleSelection: boolean) {
		if (this.list_) {
			this.list_.singleSelection = singleSelection;
		}
	}

	/**
	 * Retrieves the selected index. Only applicable to select menus.
	 * @return The selected index, which is a number for single selection and
	 *     radio lists, and an array of numbers for checkbox lists.
	 */
	get selectedIndex(): MDCListIndex {
		return this.list_ ? this.list_.selectedIndex : listConstants.UNSET_INDEX;
	}

	/**
	 * Sets the selected index of the list. Only applicable to select menus.
	 * @param index The selected index, which is a number for single selection and
	 *     radio lists, and an array of numbers for checkbox lists.
	 */
	set selectedIndex(index: MDCListIndex) {
		if (this.list_) {
			this.list_.selectedIndex = index;
		}
	}

	set quickOpen(quickOpen: boolean) {
		this.menuSurface_.quickOpen = quickOpen;
	}

	/**
	 * Sets default focus state where the menu should focus every time when menu
	 * is opened. Focuses the list root (`DefaultFocusState.LIST_ROOT`) element by
	 * default.
	 * @param focusState Default focus state.
	 */
	setDefaultFocusState(focusState: DefaultFocusState) {
		this.foundation.setDefaultFocusState(focusState);
	}

	/**
	 * @param corner Default anchor corner alignment of top-left menu corner.
	 */
	setAnchorCorner(corner: Corner) {
		this.menuSurface_.setAnchorCorner(corner);
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		this.menuSurface_.setAnchorMargin(margin);
	}

	/**
	 * Sets the list item as the selected row at the specified index.
	 * @param index Index of list item within menu.
	 */
	setSelectedIndex(index: number) {
		this.foundation.setSelectedIndex(index);
	}

	/**
	 * Sets the enabled state to isEnabled for the menu item at the given index.
	 * @param index Index of the menu item
	 * @param isEnabled The desired enabled state of the menu item.
	 */
	setEnabled(index: number, isEnabled: boolean): void {
		this.foundation.setEnabled(index, isEnabled);
	}

	/**
	 * @return The item within the menu at the index specified.
	 */
	getOptionByIndex(index: number): Element | null {
		const items = this.items;
		if (index < items.length) {
			return this.items[index];
		} else {
			return null;
		}
	}

	/**
	 * @param index A menu item's index.
	 * @return The primary text within the menu at the index specified.
	 */
	getPrimaryTextAtIndex(index: number): string {
		const item = this.getOptionByIndex(index);
		if (item && this.list_) {
			return this.list_.getPrimaryText(item) || '';
		}
		return '';
	}

	setFixedPosition(isFixed: boolean) {
		this.menuSurface_.setFixedPosition(isFixed);
	}

	setIsHoisted(isHoisted: boolean) {
		this.menuSurface_.setIsHoisted(isHoisted);
	}

	setAbsolutePosition(x: number, y: number) {
		const isOpen = this.open;
		this.menuSurface_.setAbsolutePosition(x, y);
		if (isOpen) {
			this.menuSurface_.autoposition();
		}
	}

	/**
	 * Sets the element that the menu-surface is anchored to.
	 */
	setAnchorElement(element: Element) {
		this.menuSurface_.anchorElement = element;
	}

	getDefaultFoundation() {
		// DO NOT INLINE this variable. For backward compatibility, foundations take a Partial<MDCFooAdapter>.
		// To ensure we don't accidentally omit any methods, we need a separate, strongly typed adapter variable.
		// tslint:disable:object-literal-sort-keys Methods should be in the same order as the adapter interface.
		const adapter: MDCMenuAdapter = {
			addClassToElementAtIndex: (index, className) => {
				const list = this.items;
				list[index].classList.add(className);
			},
			isElementInContainer: el => this.root.contains(el),
			isAllowedToClose: () => this.isAllowedToCloseCallSnack(),
			removeClassFromElementAtIndex: (index, className) => {
				const list = this.items;
				list[index].classList.remove(className);
			},
			addAttributeToElementAtIndex: (index, attr, value) => {
				const list = this.items;
				list[index].setAttribute(attr, value);
			},
			removeAttributeFromElementAtIndex: (index, attr) => {
				const list = this.items;
				list[index].removeAttribute(attr);
			},
			elementContainsClass: (element, className) =>
				element.classList.contains(className),
			closeSurface: (skipRestoreFocus: boolean) => {
				this.menuSurface_.close(skipRestoreFocus);
			},
			getElementIndex: (element) => this.items.indexOf(element),
			notifySelected: (evtData) =>
				this.emit<MDCMenuItemComponentEventDetail>(menuStrings.SELECTED_EVENT, {
					index: evtData.index,
					item: this.items[evtData.index],
				}),
			getMenuItemCount: () => this.items.length,
			focusItemAtIndex: (index) => (this.items[index] as HTMLElement).focus(),
			focusListRoot: () =>
				(this.root.querySelector(menuStrings.LIST_SELECTOR) as HTMLElement)
					.focus(),
			isSelectableItemAtIndex: (index) =>
				!!closest(this.items[index], `.${menuCssClasses.MENU_SELECTION_GROUP}`),
			getSelectedSiblingOfItemAtIndex: (index) => {
				const selectionGroupEl = closest(this.items[index], `.${menuCssClasses.MENU_SELECTION_GROUP}`) as HTMLElement;
				const selectedItemEl = selectionGroupEl.querySelector(`.${menuCssClasses.MENU_SELECTED_LIST_ITEM}`);
				return selectedItemEl ? this.items.indexOf(selectedItemEl) : -1;
			},
		};
		return new MDCMenuFoundation(adapter);
	}
}

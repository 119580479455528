import {initialState} from './state';
import {ERROR, RECEIVE_MARKET_WEATHER_DATA} from '../actions/types';

const initial: IGlobalState = initialState('global');

export default function (state: IGlobalState = initial, action: Action): IGlobalState {
	switch (action.type) {
		case RECEIVE_MARKET_WEATHER_DATA:
			const marketWx = state.marketWeather;
			const data = (<ReceiveMarketWeatherAction>action).data;
			marketWx[data.marketID] = data.weather;
			return {
				...state,
				marketWeather: {...marketWx},
			};
		case ERROR:
			const err = (<SetErrorAction>action).data;
			if (err) {
				console.error(err);
			}
			return {
				...state,
				error: (err === undefined) ? null : err,
			};
		default:
			return state;
	}
}

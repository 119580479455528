import React, {PropsWithChildren} from 'react';
import {
	EventType,
	SpecificEventListener,
} from '@material/base/types';
import {
	MDCLineRippleAdapter,
	MDCLineRippleFoundation,
} from '@material/line-ripple';

import {cssClassName} from '../../../util';

type Props = PropsWithChildren<{}>;
type State = {classNames: Set<string>;};

export default class LineRipple extends React.Component<Props, State> {
	private _mdc: MDCLineRippleFoundation | null;
	private _rootRef: React.RefObject<HTMLSpanElement>;

	constructor(props: Props) {
		super(props);
		this._mdcAddClass = this._mdcAddClass.bind(this);
		this._mdcDeregisterInteractionHandler = this._mdcDeregisterInteractionHandler.bind(this);
		this._mdcHasClass = this._mdcHasClass.bind(this);
		this._mdcRegisterInteractionHandler = this._mdcRegisterInteractionHandler.bind(this);
		this._mdcRemoveClass = this._mdcRemoveClass.bind(this);
		this._mdcSetStyle = this._mdcSetStyle.bind(this);
		this._mdc = null;
		this._rootRef = React.createRef();
		this.state = {classNames: new Set(['mdc-line-ripple'])};
	}

	activate(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.activate();
		}
	}

	componentDidMount(): void {
		this._mdc = new MDCLineRippleFoundation(this._mdcAdapter());
		this._mdc.init();
	}

	componentWillUnmount(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.destroy();
		}
	}

	deactivate(): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.deactivate();
		}
	}

	render(): React.ReactNode {
		const {classNames} = this.state;
		return <span className={cssClassName(...classNames)} ref={this._rootRef}/>;
	}

	setRippleCenter(xCoordinate: number): void {
		const mdc = this._mdc;
		if (mdc) {
			mdc.setRippleCenter(xCoordinate);
		}
	}

	private _mdcAdapter(): MDCLineRippleAdapter {
		return {
			addClass: this._mdcAddClass,
			deregisterEventHandler: this._mdcDeregisterInteractionHandler,
			hasClass: this._mdcHasClass,
			registerEventHandler: this._mdcRegisterInteractionHandler,
			removeClass: this._mdcRemoveClass,
			setStyle: this._mdcSetStyle,
		};
	}

	private _mdcAddClass(value: string): void {
		const {classNames} = this.state;
		classNames.add(value);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcDeregisterInteractionHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.removeEventListener(type, listener);
		}
	}

	private _mdcHasClass(value: string): boolean {
		return this.state.classNames.has(value);
	}

	private _mdcRegisterInteractionHandler<K extends EventType>(type: K, listener: SpecificEventListener<K>): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.addEventListener(type, listener);
		}
	}

	private _mdcRemoveClass(value: string): void {
		const {classNames} = this.state;
		classNames.delete(value);
		this.setState({classNames: new Set(classNames)});
	}

	private _mdcSetStyle(propertyName: string, value: string): void {
		const elem = this._rootRef.current;
		if (elem) {
			elem.style.setProperty(propertyName, value);
		}
	}
}

import React from 'react';

import {cssClassName} from '../../../../util';

interface IProps {
	className?: string;
	formId?: string;
	id?: string;
}

export default function FormButton({children, className, formId, id}: React.PropsWithChildren<IProps>) {
	return (
		<button className={cssClassName('mdc-button mdc-button--raised', className)} id={id} type="submit" form={formId}>
			<span className="mdc-button__ripple"/>
			<span className="mdc-button__label">{children}</span>
		</button>
	);
}

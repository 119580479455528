import React, {PropsWithChildren} from 'react';

import ItemView from './itemview';
import {staticItem} from './static';
import {set} from '../../../tools';
import {apiService as svc} from '../../../services';
import {ItemRole, StandardButton} from '../../../constants';
import {
	Dialog,
	MenuItem,
	ChildTable,
	ChoiceList,
	FormButton,
	MaxChoices,
	AddOnSelect,
	ItemPriceList,
	ClientUserTypeSelect,
	QuickBooksItemSelect, UserSelect,
} from './components';
import {bind, isNumber} from '../../../util';

interface IProps {
	clientUserTypes: IClientUserType[];
	itemID: number | null;
	priceGroups: IPriceGroup[];
	quickBooksItems: IQuickBooksItem[];
}

type Props = PropsWithChildren<IProps>;

interface IDialogObject {
	deletedPrices: IPriceGroupItemPrice[];
	object: IItem;
	prices: IPriceGroupItemPrice[];
}

interface State {
	addedChoice: boolean;
	addedPrice: boolean;
	choices: IChoice[];
	confirmingDeleteChild: boolean;
	deletedChoices: IChoice[];
	deletedItems: IItem[];
	deletedPrices: IPriceGroupItemPrice[];
	dialogIsOpen: boolean;
	dialogObject: IDialogObject;
	exclusiveClientUserTypeIDs: number[];
	item: IItem;
	itemPricesToAdd: {itemId: number; priceGroupId: number; price?: string;}[];
	items: IItem[];
	prices: IPriceGroupItemPrice[];
	teamMembers: IUser[];
	windowInnerWidthAtMount: number;
}

export default class WTF extends React.Component<Props, State> {
	static choiceObjectCount: number = 0;
	static itemObjectCount: number = 0;
	static priceObjectCount: number = 0;
	static staticDialogObject: IDialogObject = {
		deletedPrices: [],
		object: staticItem({id: Number.NaN}),
		prices: [],
	};

	constructor(props: Props) {
		super(props);
		++WTF.itemObjectCount;
		this.state = {
			addedChoice: false,
			addedPrice: false,
			choices: [],
			confirmingDeleteChild: false,
			deletedChoices: [],
			deletedItems: [],
			deletedPrices: [],
			dialogIsOpen: false,
			dialogObject: WTF.staticDialogObject,
			exclusiveClientUserTypeIDs: [],
			item: staticItem({id: -WTF.itemObjectCount}),
			itemPricesToAdd: [],
			items: [],
			prices: [],
			teamMembers: [],
			windowInnerWidthAtMount: 0,
		};
	}

	@bind
	addChild(child?: Partial<IItem>): IItem {
		const parentId = child ?
			child.parentId :
			this.state.item.id;
		const item = child ?
			child :
			this.state.item;
		++WTF.itemObjectCount;
		const obj = staticItem({
			color: item.color,
			description: item.description,
			duration: item.duration,
			exclusive: item.exclusive,
			icon: item.icon,
			id: -WTF.itemObjectCount,
			isPublic: item.isPublic,
			limitChoiceCount: item.limitChoiceCount,
			maxChoices: item.maxChoices,
			name: item.name,
			parentId,
			placement: item.placement,
			price: item.price,
			qbitemId: item.qbitemId,
			role: ItemRole.Child,
			size: item.size,
			succeedingDuration: item.succeedingDuration,
			userExclusions: item.userExclusions,
		});
		this.setState(
			{
				items: [
					...this.state.items,
					obj,
				],
			},
			() => this.setDialogObject(
				obj,
			),
		);
		return obj;
	}

	@bind
	addChoice(): void {
		const {choices, item} = this.state;
		++WTF.choiceObjectCount;
		const obj = {
			defaultSelected: false,
			id: -WTF.choiceObjectCount,
			itemId: item.id,
			name: '',
		};
		this.setState({
			addedChoice: true,
			choices: [...choices, obj],
		});
	}

	addItemPrice(itemId: number, itemPrice: IPriceGroupItemPrice): void {
		const {
			dialogObject,
			prices,
		} = this.state;
		const state: Pick<State, 'addedPrice' | 'dialogObject' | 'prices'> = {
			addedPrice: true,
			dialogObject,
			prices,
		};
		if (itemId === dialogObject.object.id) {
			dialogObject.prices = [
				...dialogObject.prices,
				itemPrice,
			];
			state.dialogObject = {
				...dialogObject,
			};
		} else {
			state.prices = [
				...prices,
				itemPrice,
			];
		}
		this.setState({...state});
	}

	addOnItems(): IItem[] {
		const rv = this.nonDeletedItems().filter(
			obj => ((obj.role === ItemRole.Standalone) && (obj.id !== this.state.item.id)),
		);
		rv.sort(cmpByName);
		return rv;
	}

	// addPriceGroup(priceGroupID: number): void;
	// addPriceGroup(itemID: number, priceGroupID: number): void;
	// @bind
	// addPriceGroup(priceGroupIDOrItemID: number, maybePriceGroupID?: number): void {
	// 	// - If a single value is given, it is assumed the value is the ID of
	// 	//   some PriceGroup. In this case, the state's Item object's ID will
	// 	//   be used as the new price object's itemId field value.
	// 	// - If two values are given, it is assumed the first value is the ID
	// 	//   of some Item and the last value is the ID of some PriceGroup.
	// 	const itemID = isNumber(maybePriceGroupID) ?
	// 		priceGroupIDOrItemID :
	// 		this.state.item.id;
	// 	const priceGroupID = isNumber(maybePriceGroupID) ?
	// 		maybePriceGroupID :
	// 		priceGroupIDOrItemID;
	// 	++WTF.priceObjectCount;
	// 	this.addItemPrice(
	// 		itemID,
	// 		{
	// 			id: -WTF.priceObjectCount,
	// 			itemId: itemID,
	// 			price: '',
	// 			priceGroupId: priceGroupID,
	// 		},
	// 	);
	// }

	addPriceGroup(priceGroupID: number): void;
	addPriceGroup(itemID: number, priceGroupID: number): void;
	@bind
	addPriceGroup(priceGroupIDOrItemID: number, maybePriceGroupID?: number): void {
		// - If a single value is given, it is assumed the value is the ID of
		//   some PriceGroup. In this case, the state's Item object's ID will
		//   be used as the new price object's itemId field value.
		// - If two values are given, it is assumed the first value is the ID
		//   of some Item and the last value is the ID of some PriceGroup.
		const itemID = isNumber(maybePriceGroupID) ?
			priceGroupIDOrItemID :
			this.state.item.id;
		const priceGroupID = isNumber(maybePriceGroupID) ?
			maybePriceGroupID :
			priceGroupIDOrItemID;
		// ++WTF.priceObjectCount;
		// this.addItemPrice(
		// 	itemID,
		// 	{
		// 		id: -WTF.priceObjectCount,
		// 		itemId: itemID,
		// 		price: '',
		// 		priceGroupId: priceGroupID,
		// 	},
		// );
		this._addPriceGroup({
			itemId: itemID,
			priceGroupId: priceGroupID,
		});
	}

	_addPriceGroup(obj: {itemId: number; priceGroupId: number; price?: string;}): void {
		++WTF.priceObjectCount;
		this.addItemPrice(
			obj.itemId,
			{
				id: -WTF.priceObjectCount,
				itemId: obj.itemId,
				price: (obj.price === undefined) ?
					'' :
					obj.price,
				priceGroupId: obj.priceGroupId,
			},
		);
	}

	childItems(): IItem[] {
		const rv = this.nonDeletedItems().filter(
			obj => (obj.parentId === this.state.item.id),
		);
		rv.sort(cmpByName);
		return rv;
	}

	@bind
	choiceChanged(object: IChoice): void {
		const {choices} = this.state;
		const idx = choices.findIndex(obj => (obj.id === object.id));
		if (idx >= 0) {
			choices[idx] = {...object};
			this.setState({choices: [...choices]});
		} else {
			console.log(`WTF::choiceChanged object was not found at index ${idx}`);
		}
	}

	clientUserTypes(): IClientUserType[] {
		const rv = [
			...this.props.clientUserTypes,
		];
		rv.sort(cmpByName);
		return rv;
	}

	cloneChild(): void {
		const prices = [
			...this.state.dialogObject.prices,
		];
		const child = this.addChild(
			{
				...this.state.dialogObject.object,
			},
		);
		this.setState({
			itemPricesToAdd: prices.map(
				p => ({
					itemId: child.id,
					priceGroupId: p.priceGroupId,
					price: p.price,
				}),
			),
		});
	}

	closeDialog(): void {
		this.setState({
			confirmingDeleteChild: false,
			dialogObject: WTF.staticDialogObject,
			dialogIsOpen: false,
		});
	}

	componentDidMount(): void {
		const {itemID} = this.props;
		this.setState({windowInnerWidthAtMount: window.innerWidth});
		this.init(itemID);
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		const {
			choices: choicesPrev,
			prices: pricesPrev,
		} = prevState;
		const {
			addedChoice,
			addedPrice,
			choices,
			itemPricesToAdd,
			prices,
		} = this.state;
		if (addedChoice && (choices.length === choicesPrev.length)) {
			this.setState({
				addedChoice: false,
			});
		}
		if (addedPrice && (prices.length === pricesPrev.length)) {
			this.setState({
				addedPrice: false,
			});
		}
		if (itemPricesToAdd.length > 0) {
			const itemPrices = [
				...itemPricesToAdd,
			];
			this.setState(
				{
					itemPricesToAdd: [],
				},
				() => {
					for (const obj of itemPrices) {
						this._addPriceGroup(obj);
					}
				},
			);
		}
	}

	deleteChild(object: IItem): void {
		this.setState(
			{
				deletedItems: [
					...this.state.deletedItems,
					object,
				],
			},
		);
	}

	deleteChildConfirmationResult(result: StandardButton): void {
		if (result === StandardButton.Yes) {
			this.deleteChild(
				this.state.dialogObject.object,
			);
		}
		this.closeDialog();
	}

	@bind
	deleteChoice(object: IChoice): void {
		this.setState(
			{
				deletedChoices: [
					...this.state.deletedChoices,
					object,
				],
			},
		);
	}

	deletedItemPrices(itemID: number): IPriceGroupItemPrice[] {
		const {
			deletedPrices,
			dialogObject,
		} = this.state;
		if (itemID === dialogObject.object.id) {
			return dialogObject.deletedPrices;
		} else {
			return deletedPrices.filter(
				obj => (obj.itemId === itemID),
			);
		}
	}

	@bind
	deletePrice(object: IPriceGroupItemPrice): void {
		const {
			dialogObject,
			deletedPrices,
		} = this.state;
		if (object.itemId === dialogObject.object.id) {
			dialogObject.deletedPrices = [
				...dialogObject.deletedPrices,
				object,
			];
			this.setState(
				{
					dialogObject: {...dialogObject},
				},
			);
		} else {
			this.setState(
				{
					deletedPrices: [
						...deletedPrices,
						object,
					],
				},
			);
		}
	}

	@bind
	dialogMenuSelect(result: MenuItem): void {
		switch (result.text.trim().toLowerCase()) {
			case 'delete': {
				this.deleteChildConfirmationResult(
					StandardButton.Yes,
				);
				break;
			}
			case 'clone': {
				this.cloneChild();
				break;
			}
			default: {
				console.log('dialogMenuSelect: Got invalid select result:', result);
				break;
			}
		}
	}

	@bind
	dialogResult(result: StandardButton): void {
		switch (result) {
			case StandardButton.Cancel: {
				break;
			}
			case StandardButton.Save: {
				this.mergeDialogObject();
				break;
			}
		}
		this.closeDialog();
	}

	@bind
	exclusiveClientUserTypeChanged(clientUserTypeIDs: number[]): void {
		this.setState({exclusiveClientUserTypeIDs: [...clientUserTypeIDs]});
	}

	async init(itemID: number | null): Promise<void> {
		++WTF.itemObjectCount;
		let choices: IChoice[] = [];
		let exclusiveClientUserTypeIDs: number[] = [];
		let item: IItem = staticItem({id: -WTF.itemObjectCount});
		let items: IItem[] = (await svc.catalog.item.list()).objects;
		let prices: IPriceGroupItemPrice[] = [];
		const teamMembers = await svc.group.teamMember.list();
		if (isNumber(itemID)) {
			item = await svc.catalog.item.get(itemID);
			choices = await svc.catalog.itemChoice.list({item_id: item.id});
			const ex = await svc.catalog.exclusiveItem.list({item_id: item.id});
			exclusiveClientUserTypeIDs = ex.map(obj => obj.clientUserTypeId);
			prices = await svc.catalog.itemPrice.list({item_id: item.id});
			const childItems = items.filter(obj => (obj.parentId === item.id));
			for (let i = 0; i < childItems.length; ++i) {
				prices.push(...await svc.catalog.itemPrice.list({item_id: childItems[i].id}));
			}
		}
		this.setState({
			choices: choices,
			exclusiveClientUserTypeIDs: exclusiveClientUserTypeIDs,
			item: item,
			items: items,
			prices: prices,
			teamMembers: teamMembers,
		});
	}

	@bind
	itemChanged(object: IItem): void {
		const {
			dialogObject,
		} = this.state;
		if (object.id === dialogObject.object.id) {
			dialogObject.object = {
				...object,
			};
			this.setState(
				{
					dialogObject: {
						...dialogObject,
					},
				},
			);
		} else {
			this.setState(
				{
					item: {
						...object,
					},
				},
			);
		}
	}

	@bind
	itemPrices(itemID: number): IPriceGroupItemPrice[] {
		const {dialogObject} = this.state;
		if (itemID === dialogObject.object.id) {
			const deletedIDs = new set(
				dialogObject.deletedPrices.map(ob => ob.id),
			);
			return dialogObject.prices.filter(
				ob => !deletedIDs.has(ob.id),
			);
		}
		return this.nonDeletedPrices().filter(
			obj => (obj.itemId === itemID),
		);
	}

	mergeDialogObject(): void {
		const {
			deletedPrices,
			dialogObject,
			items,
			prices,
		} = this.state;
		const objID = dialogObject.object.id;
		const idx = items.findIndex(
			obj => (obj.id === objID),
		);
		if (idx < 0) {
			console.log('WTF::mergeDialogObject object not found in object collection');
			return;
		}
		// - Merge the dialog object item with the item in the items
		//   collection
		items[idx] = {
			...items[idx],
			...dialogObject.object,
		};
		// - Merge any price changes occurring during the dialog session
		for (const objPrice of dialogObject.prices) {
			for (let i = 0; i < prices.length; ++i) {
				const price = prices[i];
				if (objPrice.id === price.id) {
					prices[i] = {
						...objPrice,
					};
					break;
				}
			}
		}
		// - Merge any price objects which were created during dialog session
		const pricesIDs = new set(
			prices.map(
				ob => ob.id,
			),
		);
		// - Copy over any deleted price objects from the prices collection
		//   into the deleted prices collection
		const delPriceIDs = new set(
			dialogObject.deletedPrices.map(
				ob => ob.id,
			),
		);
		this.setState(
			{
				deletedPrices: [
					...deletedPrices,
					...prices.filter(
						ob => delPriceIDs.has(ob.id),
					),
				],
				items: [
					...items,
				],
				prices: [
					...prices,
					...dialogObject.prices.filter(
						ob => !pricesIDs.has(ob.id),
					),
				],
			},
		);
	}

	nonDeletedChoices(): IChoice[] {
		const deletedIDs = new set(
			this.state.deletedChoices.map(obj => obj.id),
		);
		return this.state.choices.filter(
			obj => !deletedIDs.has(obj.id),
		);
	}

	nonDeletedItems(): IItem[] {
		const deletedIDs = new set(
			this.state.deletedItems.map(obj => obj.id),
		);
		return this.state.items.filter(
			obj => !deletedIDs.has(obj.id),
		);
	}

	nonDeletedPrices(): IPriceGroupItemPrice[] {
		const deletedIDs = new set(
			this.state.deletedPrices.map(obj => obj.id),
		);
		return this.state.prices.filter(
			obj => !deletedIDs.has(obj.id),
		);
	}

	@bind
	partialItemChange<K extends keyof IItem>(value: IItem[K], name: K): void {
		this.itemChanged(
			{
				...this.state.item,
				...{[name]: value},
			},
		);
	}

	@bind
	priceChanged(object: IPriceGroupItemPrice): void {
		const {
			dialogObject,
			prices,
		} = this.state;
		const dialogObjPrice = object.itemId === dialogObject.object.id;
		const objs = dialogObjPrice ?
			dialogObject.prices :
			prices;
		const idx = objs.findIndex(
			obj => (obj.id === object.id),
		);
		if (idx >= 0) {
			if (dialogObjPrice) {
				dialogObject.prices[idx] = {
					...object,
				};
				this.setState(
					{
						dialogObject: {
							...dialogObject,
						},
					},
				);
			} else {
				prices[idx] = {
					...object,
				};
				this.setState(
					{
						prices: [
							...prices,
						],
					},
				);
			}
		} else {
			console.log(`WTF::priceChanged object with ID ${object.id} was not found`);
		}
	}

	render(): React.ReactNode {
		const {
			priceGroups,
			quickBooksItems,
		} = this.props;
		const {
			addedChoice,
			addedPrice,
			confirmingDeleteChild,
			dialogIsOpen,
			dialogObject,
			exclusiveClientUserTypeIDs,
			item,
			teamMembers,
			windowInnerWidthAtMount,
		} = this.state;
		const choices = this.nonDeletedChoices();
		const dialogButtons = confirmingDeleteChild ?
			StandardButton.No | StandardButton.Yes :
			undefined;
		const formId = 'id_pb-item-detail-form';
		return (
			<form action="" method="POST" onSubmit={this.submitEvent} id={formId}>
				<ItemView
					addOnSelect={
						<AddOnSelect
							onChange={this.partialItemChange}
							options={this.addOnItems()}
							value={item.addons}
						/>
					}
					choiceList={
						<React.Fragment>
							<ChoiceList
								objects={choices}
								onAdd={this.addChoice}
								onChange={this.choiceChanged}
								onDelete={this.deleteChoice}
								takeFocus={addedChoice}
							/>
							{
								(choices.length > 0) ?
									<MaxChoices
										limitChoiceCount={item.limitChoiceCount}
										onChange={this.partialItemChange}
										value={item.maxChoices}
									/> :
									null
							}
						</React.Fragment>}
					clientUserTypeSelect={
						<ClientUserTypeSelect
							options={this.clientUserTypes()}
							onChange={this.exclusiveClientUserTypeChanged}
							value={exclusiveClientUserTypeIDs}
						/>
					}
					formButton={
						<FormButton id="pb-catalog-item-detail-view-submit-btn">
							Save
						</FormButton>
					}
					object={item}
					onChange={this.itemChanged}
					pricing={
						<ItemPriceList
							objects={this.itemPrices(item.id)}
							onAdd={this.addPriceGroup}
							onChange={this.priceChanged}
							onDelete={this.deletePrice}
							priceGroups={priceGroups}
							takeFocus={!dialogIsOpen && addedPrice}
						/>
					}
					quickBooksItemSelect={
						<QuickBooksItemSelect
							onChange={this.partialItemChange}
							options={quickBooksItems}
							value={item.qbitemId}
						/>
					}
					userSelect={
						<UserSelect
							onChange={this.partialItemChange}
							options={teamMembers}
							value={item.userExclusions}
						/>
					}
					variants={
						<ChildTable
							objects={this.childItems()}
							onAdd={this.addChild}
							onClick={this.setDialogObject}
							itemPrices={this.itemPrices}
						/>
					}
				/>
				<Dialog
					buttons={dialogButtons}
					isOpen={dialogIsOpen}
					maximized={dialogIsOpen && (windowInnerWidthAtMount <= 700)}
					menuItems={
						[
							{
								icon: 'content_copy',
								text: 'Clone',
							},
							{
								icon: 'delete_outline',
								text: 'Delete',
							},
						]
					}
					onMenuItemSelect={this.dialogMenuSelect}
					onResult={this.dialogResult}
					title="Variant">
					<ItemView
						compact={true}
						object={dialogObject.object}
						onChange={this.itemChanged}
						pricing={
							<ItemPriceList
								objects={this.itemPrices(dialogObject.object.id)}
								onAdd={this.addPriceGroup.bind(this, dialogObject.object.id)}
								onChange={this.priceChanged}
								onDelete={this.deletePrice}
								priceGroups={priceGroups}
								takeFocus={dialogIsOpen && addedPrice}
							/>
						}
					/>
				</Dialog>
			</form>
		);
	}

	async save(): Promise<void> {
		const {
			exclusiveClientUserTypeIDs,
			item: unsavedItem,
		} = this.state;
		const create = !isNumber(this.props.itemID);
		const transientID = unsavedItem.id;
		const childObjs = this.childItems();
		const {
			toCreate: childObjsToCreate,
			toUpdate: childObjsToUpdate,
		} = partitionChildObjects(childObjs);
		const existingChildObjIDs = new set(childObjsToUpdate.map(obj => obj.id));
		unsavedItem.children = unsavedItem.children.filter(id => existingChildObjIDs.has(id));
		const isPublic = unsavedItem.isPublic;
		childObjsToCreate.forEach(child => (child.isPublic = isPublic));
		childObjsToUpdate.forEach(child => (child.isPublic = isPublic));
		const {
			toCreate: choicesToCreate,
			toUpdate: choicesToUpdate,
		} = partitionChoiceObjects(this.nonDeletedChoices());
		const existingChoiceObjIDs = new set(choicesToUpdate.map(obj => obj.id));
		unsavedItem.choices = unsavedItem.choices.filter(id => existingChoiceObjIDs.has(id));
		const {
			toCreate: itemPricesToCreate,
			toUpdate: itemPricesToUpdate,
		} = partitionItemPriceObjects(this.itemPrices(transientID));
		const itemPricesToDelete = this.deletedItemPrices(transientID);
		const savedItem = create ?
			await svc.catalog.item.create(unsavedItem) :
			await svc.catalog.item.update(unsavedItem.id, unsavedItem);
		// If we just created the Item (as opposed to updating an existing)
		// then all related objects here will have their itemId/parentId/etc.
		// related IDs set to the transient ID set here for our purposes. With
		// the new Item object having just been created, we have a concrete
		// database object with ID and must set all related object properties
		// to reflect.
		// Note: If we are creating an Item object, child/choices/etc
		// "toUpdate" collections will be empty; all objects here are being
		// created.
		childObjsToCreate.forEach(obj => (obj.parentId = savedItem.id));
		choicesToCreate.forEach(obj => (obj.itemId = savedItem.id));
		itemPricesToCreate.forEach(obj => (obj.itemId = savedItem.id));
		//
		// Choice objects gets created
		//
		await createChoiceObjects(choicesToCreate);
		//
		// Choice objects gets updated
		//
		await updateChoiceObjects(choicesToUpdate);
		//
		// PriceGroupItemPrice objects gets created/deleted/updated
		//
		for (let i = 0; i < itemPricesToDelete.length; ++i) {
			await svc.catalog.itemPrice.delete(itemPricesToDelete[i].id);
		}
		for (let i = 0; i < itemPricesToUpdate.length; ++i) {
			const obj = itemPricesToUpdate[i];
			if (!obj.price) {
				// - PriceGroupItemPrice objects have a constraint on price
				//   field. The value cannot be missing.
				console.log(`WTF object ${obj.id} has no value`);
				continue;
			}
			await svc.catalog.itemPrice.update(obj.id, obj);
		}
		for (let i = 0; i < itemPricesToCreate.length; ++i) {
			await svc.catalog.itemPrice.create(itemPricesToCreate[i]);
		}
		//
		// Child Item objects get created
		//     Child's PriceGroupItemPrice objects get created
		//
		for (let i = 0; i < childObjsToCreate.length; ++i) {
			const obj = childObjsToCreate[i];
			const childTransientID = obj.id;
			const child = await svc.catalog.item.create(obj);
			const permanentID = child.id;
			// - The child Item object's transient ID (not the ID from the
			//   object just returned from being created in the database) is
			//   given in the following routine as that was the ID used for
			//   this session; thus their associated price objects will only
			//   know this transient ID. The child object's permanent ID will
			//   be set to the price objects before creation in the subsequent
			//   routine.
			const {
				toCreate: childItemPricesToCreate,
			} = partitionItemPriceObjects(this.itemPrices(childTransientID));
			// - As this is a object just created, the only work left ot do is
			//   to create the new object's price objects. The new child Item
			//   object would not have any price objects to update or delete
			//   as it's never had price objects in its nascent life.
			for (let i = 0; i < childItemPricesToCreate.length; ++i) {
				const obj = childItemPricesToCreate[i];
				obj.itemId = permanentID;
				await svc.catalog.itemPrice.create(obj);
			}
		}
		//
		// Child Item objects get updated
		//     Child's PriceGroupItemPrice objects get created/deleted/updated
		//
		for (let i = 0; i < childObjsToUpdate.length; ++i) {
			const obj = childObjsToUpdate[i];
			const child = await svc.catalog.item.update(obj.id, obj);
			const childID = child.id;
			const {
				toCreate: childItemPricesToCreate,
				toUpdate: childItemPricesToUpdate,
			} = partitionItemPriceObjects(this.itemPrices(childID));
			const childItemPricesToDelete = this.deletedItemPrices(childID);
			for (let i = 0; i < childItemPricesToDelete.length; ++i) {
				await svc.catalog.itemPrice.delete(childItemPricesToDelete[i].id);
			}
			for (let i = 0; i < childItemPricesToUpdate.length; ++i) {
				const obj = childItemPricesToUpdate[i];
				await svc.catalog.itemPrice.update(obj.id, obj);
			}
			for (let i = 0; i < childItemPricesToCreate.length; ++i) {
				await svc.catalog.itemPrice.create(childItemPricesToCreate[i]);
			}
		}
		//
		// ExclusiveClientUserTypeItem objects get created/deleted
		//
		// ex:
		//        started with objects IDs (local object IDs): {1, 2, 3, 4, 5}
		//
		//        remove object ID 1
		//        remove object ID 2
		//
		//        local object IDs: {3, 4, 5}
		//
		//        add object ID -1
		//        add object ID -3
		//
		//        local object IDs: {3, 4, 5, -1, -3}
		//
		//        fetch current object IDs once again gives us (current object IDs): {1, 2, 3, 4, 5}
		//
		//        IDs of objects to DELETE: (current object IDs) - (local object IDs)
		//                                  {1, 2}
		//
		//        IDs of objects to CREATE: (local object IDs) - (current object IDs)
		//                                  {-1, -3}
		const currentExclusiveObjs = await svc.catalog.exclusiveItem.list({item_id: savedItem.id});
		const currentExclusiveObjClientUserTypeIDs = new set(currentExclusiveObjs.map(obj => obj.clientUserTypeId));
		const localClientUserTypeIDs = new set(exclusiveClientUserTypeIDs);
		const exclusiveClientUserTypeIDsToDelete = currentExclusiveObjClientUserTypeIDs.difference(localClientUserTypeIDs);
		const exclusiveObjToDelete = currentExclusiveObjs.filter(obj => exclusiveClientUserTypeIDsToDelete.has(obj.clientUserTypeId));
		for (let i = 0; i < exclusiveObjToDelete.length; ++i) {
			await svc.catalog.exclusiveItem.delete(exclusiveObjToDelete[i].id);
		}
		const exclusiveClientUserTypeIDsToCreate = (localClientUserTypeIDs.difference(currentExclusiveObjClientUserTypeIDs)).toArray();
		for (let i = 0; i < exclusiveClientUserTypeIDsToCreate.length; ++i) {
			const clientUserTypeID = exclusiveClientUserTypeIDsToCreate[i];
			await svc.catalog.exclusiveItem.create({
				clientUserTypeId: clientUserTypeID,
				id: -1,
				itemId: savedItem.id,
			});
		}
		window.location.assign(
			window.pburls.group.catalog.item.list,
		);
	}

	@bind
	setDialogObject(object: IItem): void {
		this.setState(
			{
				dialogIsOpen: true,
				dialogObject: {
					deletedPrices: [],
					prices: [
						...this.itemPrices(object.id),
					],
					object: {
						...object,
					},
				},
			},
		);
	}

	@bind
	submitEvent(event: React.FormEvent): void {
		event.preventDefault();
		this.save();
	}
}

async function createChoiceObjects(objs: IChoice[]): Promise<void> {
	for (let i = 0; i < objs.length; ++i) {
		const obj = objs[i];
		// - Choice objects have a length constraint on the name
		//   field. If the value here is empty, it was emptied within
		//   current session. If this object is sent, it will result
		//   in an error being returned. That chaos is preempted here.
		if (obj.name) {
			await svc.catalog.itemChoice.create(obj);
		}
	}
}

function partitionChildObjects(objs: IItem[]): {toCreate: IItem[]; toUpdate: IItem[];} {
	const toCreate: IItem[] = [];
	const toUpdate: IItem[] = [];
	// - If child object ID is <= 0, object is new and should not be
	//   present within the parent Item's children id set at time of
	//   update.
	// - If child object ID is not present within local child ID set, child
	//   was deleted and its ID should not be present within parent Item's
	//   children ID set at time of update.
	for (let i = 0; i < objs.length; ++i) {
		const obj = objs[i];
		if (obj.id > 0) {
			toUpdate.push(obj);
		} else {
			toCreate.push(obj);
		}
	}
	return {toCreate, toUpdate};
}

function partitionChoiceObjects(objs: IChoice[]): {toCreate: IChoice[]; toUpdate: IChoice[];} {
	// - If choice object ID is <= 0, object is new and should not be
	//   present within the Item's choices id set at time of update.
	// - If choice object ID is not present within local choice ID set,
	//   choice was deleted and its ID should not be present within Item's
	//   choice ID set at time of update.
	const toCreate: IChoice[] = [];
	const toUpdate: IChoice[] = [];
	for (let i = 0; i < objs.length; ++i) {
		const obj = objs[i];
		// - Choice objects created during current session have yet to be
		//   created in the database, thus their ID at this time is
		//   transient. All to-be-created session objects will have an ID
		//   of some integer < 0 and unique within its set of like
		//   objects.
		if (obj.id > 0) {
			toUpdate.push(obj);
		} else {
			toCreate.push(obj);
		}
	}
	return {toCreate, toUpdate};
}

function partitionItemPriceObjects(priceObjs: IPriceGroupItemPrice[]): {toCreate: IPriceGroupItemPrice[]; toUpdate: IPriceGroupItemPrice[];} {
	const toCreate: IPriceGroupItemPrice[] = [];
	const toUpdate: IPriceGroupItemPrice[] = [];
	for (let i = 0; i < priceObjs.length; ++i) {
		const obj = priceObjs[i];
		// - Like all objects here, new, soon-to-be-created objects have
		//   transient IDs as they've yet touched the database, assigning
		//   their permanent IDs. All new objects have an ID of some
		//   integer < 0, unique within their set of like objects.
		if (obj.id > 0) {
			toUpdate.push(obj);
		} else {
			toCreate.push(obj);
		}
	}
	return {toCreate, toUpdate};
}

async function updateChoiceObjects(objs: IChoice[]): Promise<void> {
	for (let i = 0; i < objs.length; ++i) {
		const obj = objs[i];
		// - Choice objects have a length constraint on the name
		//   field. If the value here is empty, it was emptied within
		//   current session. If this object is sent, it will result
		//   in an error being returned. That chaos is preempted here.
		if (obj.name) {
			await svc.catalog.itemChoice.update(obj.id, obj);
		}
	}
}

const collator = new Intl.Collator(
	// undefined,
	// {
	// 	ignorePunctuation: true,
	// },
);

function cmpByName<T extends {name: string;}>(a: T, b: T): number {
	return collator.compare(
		a.name.toLocaleLowerCase(),
		b.name.toLocaleLowerCase(),
	);
}

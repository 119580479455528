import React, {CSSProperties, PropsWithChildren} from 'react';

import {cssClassName} from '../../util';

export function Grid(props: PropsWithChildren<{align?: 'left' | 'right'; className?: string}>) {
	const {align, children, className} = props;
	const classNames: string[] = ['mdc-layout-grid'];
	if (className) {
		classNames.push(className);
	}
	if (align) {
		classNames.push(`mdc-layout-grid--align-${align}`);
	}
	return (
		<div className={cssClassName(...classNames)}>
			<GridInner>
				{children}
			</GridInner>
		</div>
	);
}

export function GridInner(props: PropsWithChildren<{}>) {
	const {children} = props;
	return (
		<div className="mdc-layout-grid__inner">
			{children}
		</div>
	);
}

interface IGridCellProps {
	alignment: GridCellAlignment;
	desktopSpan: GridCellSpan;
	orderIndex: GridCellSpan;
	phoneSpan: GridCellSpan;
	span: GridCellSpan;
	style: CSSProperties;
	tabletSpan: GridCellSpan;
}

export function GridCell(props: PropsWithChildren<Partial<IGridCellProps>>) {
	const {alignment, children, desktopSpan, orderIndex, phoneSpan, span, style, tabletSpan} = props;
	const sp = (!span && !desktopSpan && !phoneSpan && !tabletSpan) ? 12 : span;
	const classNames: string[] = [
		'mdc-layout-grid__cell',
	];
	if (sp) {
		classNames.push(`mdc-layout-grid__cell--span-${sp}`);
	}
	if (desktopSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${desktopSpan}-desktop`);
	}
	if (phoneSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${phoneSpan}-phone`);
	}
	if (tabletSpan) {
		classNames.push(`mdc-layout-grid__cell--span-${tabletSpan}-tablet`);
	}
	if (alignment) {
		classNames.push(`mdc-layout-grid__cell--align-${alignment}`);
	}
	if (orderIndex) {
		classNames.push(`mdc-layout-grid__cell--order-${orderIndex}`);
	}
	return (
		<div className={cssClassName(...classNames)} style={style}>
			{children}
		</div>
	);
}

export function GridDivider(props: Partial<Omit<IGridCellProps, 'style'>>) {
	const {span, ...gridCellProps} = props;
	if (!span || (span === 12)) {
		return <GridCell span={span} style={{borderTop: '1px solid #EAEBEB'}} {...gridCellProps}/>;
	}
	return (
		<GridCell>
			<GridInner>
				<GridCell span={span} style={{borderTop: '1px solid #EAEBEB'}} {...gridCellProps}/>
			</GridInner>
		</GridCell>
	);
}

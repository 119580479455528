import React from 'react';

import CTA from './cta';
import {Menu, ListItem, ListItemText} from '../../../components';

type MenuOption = {id: number | string; text: string;}

interface IProps<T extends MenuOption = MenuOption> {
	onSelect: (index: number) => any;
	options: T[];
	text: string;
}

interface State {
	menuIsOpen: boolean;
}

export default class ButtonCTAMenu extends React.Component<IProps, State> {
	constructor(props: IProps) {
		super(props);
		this.buttonClick = this.buttonClick.bind(this);
		this.menuClosed = this.menuClosed.bind(this);
		this.menuSelect = this.menuSelect.bind(this);
		this.state = {menuIsOpen: false};
	}

	buttonClick(): void {
		const {menuIsOpen} = this.state;
		this.setState({menuIsOpen: !menuIsOpen});
	}

	menuClosed(): void {
		const {menuIsOpen} = this.state;
		if (menuIsOpen) {
			this.setState({menuIsOpen: false});
		}
	}

	menuSelect(index: number): void {
		const {onSelect} = this.props;
		onSelect(index);
	}

	render(): React.ReactNode {
		const {options, text} = this.props;
		const {menuIsOpen} = this.state;
		return (
			<CTA className="mdc-menu-surface--anchor" onClick={this.buttonClick} text={text}>
				<Menu isOpen={menuIsOpen} onClose={this.menuClosed} onSelect={this.menuSelect}>
					{options.map((obj, idx) =>
						<ListItem key={obj.id} tabIndex={((idx === 0) ? 0 : undefined)}>
							<ListItemText primaryText={obj.text}/>
						</ListItem>)}
				</Menu>
			</CTA>
		);
	}
}

import React from 'react';

import LineItem from './lineitem';
import {set} from '../../../tools';
import {bind, isNumber} from '../../../util';
import {apiService as svc} from '../../../services';
import {
	Card,
	Icon,
	Grid,
	Header,
	GridCell,
	GridDivider,
} from '../../components';

interface State {
	initializing: boolean;
	itemPrices: IPriceGroupItemPrice[];
	items: IItem[];
	lineItems: ILineItem[];
	newObjectCount: number;
	priceGroups: IPriceGroup[];
	projectId: number;
	busy: boolean;
	users: number[];
}

export default class Invoice extends React.Component<{}, State> {
	slug: string;

	constructor(props: {}) {
		super(props);
		this.slug = '';
		this.state = {
			initializing: true,
			itemPrices: [],
			items: [],
			lineItems: [],
			newObjectCount: 0,
			priceGroups: [],
			projectId: 0,
			busy: false,
			users: [],
		};
	}

	addLineItem(obj: ILineItem): void {
		const {lineItems} = this.state;
		this.setState({lineItems: [...lineItems, obj]});
	}

	@bind
	addLineItemAction(): void {
		this.addLineItem(
			this.newLineItem(
				{id: this.nextLineItemTempID()}));
	}

	componentDidMount(): void {
		const updateURLDetail = window.pburls.invoice.update;
		if (updateURLDetail) {
			this.slug = updateURLDetail.slug;
			this.init(this.slug);
		} else {
			this.setState({initializing: false});
		}
	}

	async createLineItem(data: ILineItem): Promise<void> {
		const tempID = data.id;
		const lineItem = await svc.project.createLineItem(this.slug, data);
		this.removeLineItem(tempID);
		const {lineItems} = this.state;
		this.setState({
			lineItems: [
				...lineItems.filter(item => (item.id !== tempID)),
				lineItem,
			],
		});
	}

	@bind
	async deleteLineItem(id: number): Promise<void> {
		const obj = this.lineItem(id);
		if (obj) {
			if (obj.id > 0) {
				await svc.project.deleteLineItem(this.slug, obj.id);
			}
			this.removeLineItem(id);
		}
	}

	async init(slug: string): Promise<void> {
		const items = (await svc.catalog.item.list()).objects;
		items.sort(itemSortKey);
		const lineItems = await svc.project.lineItems(this.slug);
		const project = await svc.project.get(slug);
		this.setState({
			initializing: false,
			itemPrices: await svc.catalog.itemPrice.list(),
			items,
			lineItems,
			priceGroups: await svc.catalog.priceGroup.list(),
			projectId: project.id,
			users: project.users,
		});
	}

	async itemPrice(item: IItem): Promise<IPriceGroupItemPrice | null> {
		const {itemPrices, priceGroups, users} = this.state;
		const prices: IPriceGroupItemPrice[] = itemPrices.filter(obj => (obj.itemId === item.id));
		const priceGroupIDs = new set(prices.map(obj => obj.priceGroupId));
		const pricePriceGroups = priceGroups.filter(obj => priceGroupIDs.has(obj.id));
		if (pricePriceGroups.length > 0) {
			if (users.length === 1) {
				const clientUserId = users[0];
				const clientUser = await svc.group.client.user.get(clientUserId);
				if (isNumber(clientUser.clientUserTypeId)) {
					const clientUserType = await svc.group.client.type.get(clientUser.clientUserTypeId);
					if (isNumber(clientUserType.priceGroupId)) {
						for (const price of prices) {
							if (price.priceGroupId === clientUserType.priceGroupId) {
								return price;
							}
						}
					}
				}
			} else {
				console.log(`Got ${users.length} users. Expected exactly 1.`);
			}
			const priceGroup = pricePriceGroups[0];
			const priceGroupPrice = prices.find(obj => obj.priceGroupId === priceGroup.id);
			if (priceGroupPrice) {
				return priceGroupPrice;
			} else {
				console.log('No PriceGroupItemPrice found for PriceGroup');
			}
		}
		return null;
	}

	lineItem(id: number): ILineItem | null {
		const {lineItems} = this.state;
		for (let i = 0; i < lineItems.length; ++i) {
			const data = lineItems[i];
			if (data.id === id) {
				return data;
			}
		}
		return null;
	}

	newLineItem<K extends keyof ILineItem>(data?: Pick<ILineItem, K> | ILineItem): ILineItem {
		let {projectId} = this.state;
		const obj = {
			amount: '0.00',
			description: '',
			id: 0,
			itemId: null,
			name: '',
			projectId: projectId,
			taxable: false,
		};
		return {...obj, ...data};
	}

	nextLineItemTempID(): number {
		let {newObjectCount} = this.state;
		++newObjectCount;
		this.setState({newObjectCount});
		return -newObjectCount;
	}

	projectDetailURI(): string {
		const projectDetail = window.pburls.project.detail;
		return projectDetail ? projectDetail.uri : '';
	}

	removeLineItem(id: number): void {
		const {lineItems} = this.state;
		this.setState({lineItems: [...lineItems.filter(item => (item.id !== id))]});
	}

	render(): React.ReactNode {
		const {
			initializing,
			lineItems,
		} = this.state;
		if (initializing) {
			return null;
		}
		const haveItems = (lineItems.length > 0);
		const projectDetailURI = this.projectDetailURI();
		return (
			<Card>
				<Header primaryText="Line Items">
					{projectDetailURI ?
						<a className="font-size--12px" href={projectDetailURI}><Icon className="font-size--12px" name="arrow_back"/>Back to Project</a> :
						null}
				</Header>
				<Grid>
					{haveItems ?
						<GridCell>
							<table className="min-width--100-percent table-layout--fixed">
								<thead>
									<tr>
										<th className="font-size--12px text-align--left">Item</th>
										<th className="font-size--12px text-align--left">Name</th>
										<th className="font-size--12px text-align--left">Description</th>
										<th className="font-size--12px text-align--left">Amount</th>
										<th className="font-size--12px text-align--left">Taxable</th>
										<th className="font-size--12px text-align--center">Save</th>
										<th className="font-size--12px text-align--center color--danger">Delete</th>
									</tr>
								</thead>
								<tbody>
									{lineItems.map(lineItem =>
										<LineItem
											itemOptions={this.selectOptions()}
											key={lineItem.id}
											lineItem={lineItem}
											onChange={this.setLineItemData}
											onDelete={this.deleteLineItem}
											onSave={this.saveLineItem}/>)}
								</tbody>
							</table>
						</GridCell> :
						null}
					{haveItems ?
						<GridDivider/> :
						null}
					<GridCell>
						<button className="mdc-button padding--0-0-0-0" onClick={this.addLineItemAction} title="Add Line Item" type="button">
							<div className="display--flex justify-content--space-between align-items--center">
								<Icon name="add_circle_outline"/>
								<span className="padding-left--4">Line Item</span>
							</div>
						</button>
					</GridCell>
				</Grid>
			</Card>
		);
	}

	@bind
	saveLineItem(id: number): void {
		const obj = this.lineItem(id);
		if (obj) {
			if (id < 0) {
				this.createLineItem(obj);
			} else {
				this.updateLineItem(obj);
			}
		}
	}

	selectOptions(): {value: number, text: string;}[] {
		const {items} = this.state;
		return items.map(item => ({value: item.id, text: item.name}));
	}

	@bind
	async setLineItemData(id: number, data: Partial<ILineItem>): Promise<void> {
		const lineItem = this.lineItem(id);
		if (!lineItem) {
			return;
		}
		const {lineItems} = this.state;
		const idx = lineItems.findIndex(itm => itm.id === lineItem.id);
		if (idx >= 0) {
			if (isNumber(data.itemId) && (lineItem.id <= 0)) {
				const itm = this.state.items.find(i => (i.id === data.itemId));
				if (itm) {
					const itemPrice = await this.itemPrice(itm);
					data.name = itm.name;
					data.description = (itm.description.trim().length > 0) ? itm.description : itm.name;
					data.amount = itemPrice ? itemPrice.price : '0.00';
				}
			}
			lineItems[idx] = Object.assign({}, lineItem, data);
			this.setState({lineItems: [...lineItems]});
		} else {
			console.log('setLineItemData line item object not found in collection');
		}
	}

	updateLineItem(data: ILineItem): void {
		svc.project.updateLineItem(this.slug, data.id, data);
	}
}

function itemSortKey(a: IItem, b: IItem): number {
	const aa = a.name.toLocaleLowerCase();
	const bb = b.name.toLocaleLowerCase();
	if (aa > bb) {
		return 1;
	}
	if (aa < bb) {
		return -1;
	}
	return 0;
}

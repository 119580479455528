import {Menu, MenuOpts} from './menu';
import {Button} from './button';
import {list} from '../tools';
import {Checkbox} from './checkbox';
import {El, elOpts} from '../el';
import {List, ListGroup, ListItemSelectEvt} from './list';
import {CSS_CLASS_FULL_WIDTH} from '../constants';
import {bind} from '../util';
import {Evt} from '../evt';

export class HeaderSectionMenu extends Menu {
	private btn: Button | null;
	private checkboxes: list<Checkbox>;

	constructor(opts: Partial<MenuOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<MenuOpts>, parent?: El | null);
	constructor(opts?: Partial<MenuOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<MenuOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<MenuOpts>(a, b, c);
		opts.list = new List({classNames: 'pb-table-column-picker-list'});
		(new ListGroup()).addList(opts.list, 'Table columns');
		super(opts);
		this.checkboxes = new list<Checkbox>();
		const btnContainer = El.div(this);
		btnContainer.setStyleProperty('padding', '0 16px 8px');
		this.btn = new Button({classNames: CSS_CLASS_FULL_WIDTH, text: 'OK'}, btnContainer);
		this.btn.onEvt(this.buttonEvt);
	}

	addHeaderSection(id: number, name: string, visible: boolean): void {
		const checkbox = new Checkbox();
		this.checkboxes.append(checkbox);
		checkbox.setChecked(visible);
		this.addItem({id: String(id), leadingEl: checkbox, text: name});
	}

	@bind
	protected buttonEvt(evt: Evt): void {
		if (evt.type() === Evt.MouseButtonClick) {
			this.close();
		}
	}

	destroy(): void {
		for (const cb of this.checkboxes) {
			cb.destroy();
		}
		this.checkboxes.clear();
		if (this.btn) {
			this.btn.offEvt(this.buttonEvt);
			this.btn.destroy();
			this.btn = null;
		}
		super.destroy();
	}

	protected listItemSelectEvt(evt: ListItemSelectEvt): void {
		this.notifyEvt(evt);
	}
}

import React from 'react';

import {Input} from '../../../components';

export function CheckTable({children}: React.PropsWithChildren<{}>) {
	return (
		<table className="services-table border-radius--4 overflow--hidden">
			{children}
		</table>
	);
}

export function Body({children}: React.PropsWithChildren<{}>) {
	return (
		<tbody>
			{children}
		</tbody>
	);
}

export function Cell({children, colSpan}: React.PropsWithChildren<{colSpan?: number}>) {
	return (
		<td className="service-table-column" colSpan={colSpan}>
			{children}
		</td>
	);
}

export function Row({children}: React.PropsWithChildren<{}>) {
	return (
		<tr className="service-table-row">
			{children}
		</tr>
	);
}

interface ICheckboxProps {
	checked: boolean;
	disabled: boolean;
	id?: string;
	labelClassName?: string;
	onChange: (value: string | number, checked: boolean) => any;
	title?: string;
	value: string | number;
}

export class CheckBox extends React.Component<React.PropsWithChildren<ICheckboxProps>, {}> {
	constructor(props: React.PropsWithChildren<ICheckboxProps>) {
		super(props);
		this.changeEvent = this.changeEvent.bind(this);
	}

	changeEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		const {onChange, value} = this.props;
		const checked = event.target.checked;
		onChange(value, checked);
	}

	render(): React.ReactNode {
		const {checked, disabled, id, title, value} = this.props;
		return (
			<Input
				checked={checked}
				className="mdc-checkbox__native-control"
				disabled={disabled}
				id={id}
				onChange={this.changeEvent}
				title={title}
				type="checkbox"
				value={value}/>
		);
	}
}

export function Background() {
	return (
		<div className="mdc-checkbox__background mdc-checkbox__background__create-view checkbox-background">
			<svg version="1.1" className="mdc-checkbox__checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<path className="mdc-checkbox__checkmark__path" fill="none" stroke="white" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
			</svg>
			<div className="mdc-checkbox__mixedmark"/>
		</div>
	);
}

export function FormField({children, disabled}: React.PropsWithChildren<{disabled?: boolean;}>) {
	const parts: string[] = [
		'mdc-form-field',
		'service-checkbox-container',
	];
	if (disabled) {
		parts.push('service-checkbox-container--disabled');
	}
	const className = parts.join(' ');
	return (
		<div className={className}>{children}</div>
	);
}

export function InputContainer({children, disabled}: React.PropsWithChildren<{disabled?: boolean;}>) {
	const parts: string[] = [
		'mdc-checkbox',
		'service-checkbox',
	];
	if (disabled) {
		parts.push('mdc-checkbox--disabled', 'service-checkbox--disabled');
	}
	const className = parts.join(' ');
	return (
		<div className={className}>{children}</div>
	);
}

export function Label({children, className, htmlFor}: React.PropsWithChildren<{className?: string, htmlFor?: string}>) {
	return (
		<label className={className} htmlFor={htmlFor}>{children}</label>
	);
}

import {MDCList, MDCListActionEvent} from '@material/list';

import {El, ElOpts, elOpts} from '../el';
import {list} from '../tools';
import {bind, debounce} from '../util';
import {Evt} from '../evt';

export interface ListOpts extends ElOpts {
	items: Iterable<ListItem> | Iterable<Partial<ListItemOpts>> | Iterable<string>;
	listItemFactory: (opts?: Partial<ListItemOpts>) => ListItem;
	twoLine: boolean;
}

const defaultLIstItemFactory = (opts?: Partial<ListItemOpts>) => new ListItem(opts);

export class ListItemSelectEvt extends Evt {
	private i: number;

	constructor(index: number) {
		super(Evt.Select);
		this.i = index;
	}

	index(): number {
		return this.i;
	}
}

export class List extends El {
	static RootSelector: string = '.mdc-list';

	ctrl: MDCList | null;
	group: ListGroup | null;
	listItemFactory: (opts?: Partial<ListItemOpts>) => ListItem;
	listItems: list<ListItem>;
	listItemDividers: list<ListItemDivider>;
	private _debouncedDoLayout: () => void;

	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListOpts> | null, parent?: El | null);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListOpts>, parent?: El | null);
	constructor(opts?: Partial<ListOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ListOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ListOpts>(a, b, c);
		opts.tagName = 'ul';
		super(opts);
		this.ctrl = null;
		this.group = null;
		this.listItemFactory = opts.listItemFactory || defaultLIstItemFactory;
		this.listItems = new list<ListItem>();
		this.listItemDividers = new list<ListItemDivider>();
		this.addClass('mdc-list');
		if (opts.twoLine) {
			this.addClass('mdc-list--two-line');
		}
		if (opts.items) {
			for (const it of opts.items) {
				this.addItem(it);
			}
		}
		this.addEventListener('MDCList:action', this.event);
		this.ctrl = new MDCList(this.elem);
		this._debouncedDoLayout = debounce(this._doLayout, 42);
		this.doLayout();
	}

	addItem(data: ListItem | Partial<ListItemOpts> | string): ListItem {
		return this.insertItem(-1, data);
	}

	addItems(items: Iterable<ListItem> | Iterable<Partial<ListItemOpts>> | Iterable<string>): void {
		if (typeof items === 'string') {
			this.addItem(items);
		} else {
			for (const obj of items) {
				this.addItem(obj);
			}
		}
	}

	appendListItemDivider(): void {
		this.insertItemDivider(-1);
	}

	checkedItems(): list<ListItem> {
		const rv = new list<ListItem>();
		for (let i = 0; i < this.listItems.size(); ++i) {
			const item = this.listItems.at(i);
			if (item.isChecked()) {
				rv.append(item);
			}
		}
		return rv;
	}

	clear(): void {
		for (const obj of this.listItems) {
			obj.destroy();
		}
		this.listItems.clear();
		if (this.ctrl) {
			this.ctrl.layout();
		}
	}

	count(): number {
		return this.listItems.size();
	}

	protected ctrlAction(index: number): boolean {
		return false;
	}

	currentRow(): number {
		for (let i = 0; i < this.listItems.size(); ++i) {
			if (this.listItems.at(i).hasFocus()) {
				return i;
			}
		}
		return -1;
	}

	destroy(): void {
		for (const obj of this.listItems) {
			obj.destroy();
		}
		this.listItems.clear();
		for (const obj of this.listItemDividers) {
			obj.destroy();
		}
		this.listItemDividers.clear();
		this.group = null;
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
		super.destroy();
	}

	private doLayout(): void {
		this._debouncedDoLayout();
	}

	private _doLayout(): void {
		if (this.ctrl) {
			this.ctrl.layout();
		}
	}

	@bind
	protected event(event: Event): void {
		switch (event.type) {
			case 'MDCList:action':
				const index = (<MDCListActionEvent>event).detail.index;
				if (!this.ctrlAction(index)) {
					this.notifyEvt(new ListItemSelectEvt(index));
				}
				break;
			default:
				break;
		}
	}

	focusItemAt(index: number): void {
		const item = this.item(index);
		if (item) {
			item.focus();
		}
	}

	insertItem(index: number, item: ListItem | Partial<ListItemOpts> | string): ListItem {
		let it: ListItem;
		if (item instanceof ListItem) {
			it = item;
		} else {
			let opts: Partial<ListItemOpts> = {};
			if (typeof item === 'string') {
				opts.text = item;
			} else {
				opts = item;
			}
			it = this.listItemFactory(opts);
		}
		if (index < 0 || index > this.size()) {
			index = this.size() + this.listItemDividers.size();
		}
		this.insertChild(index, it);
		this.listItems.insert(index, it);
		this.doLayout();
		return it;
	}

	insertItemDivider(index: number, itemDivider?: ListItemDivider): void {
		if (index < 0 || index > this.size()) {
			index = this.size() + this.listItemDividers.size();
		}
		const obj = itemDivider || new ListItemDivider();
		this.insertChild(index, obj);
		this.listItemDividers.insert(index, obj);
		if (this.ctrl) {
			this.ctrl.layout();
		}
	}

	protected isTwoLine(): boolean {
		return this.hasClass('mdc-list--two-line');
	}

	isWrapping(): boolean {
		if (this.ctrl) {
			return this.ctrl.wrapFocus;
		}
		return false;
	}

	item(index: number): ListItem | null {
		if ((index >= 0) && (index < this.listItems.size())) {
			return this.listItems.at(index);
		}
		return null;
	}

	items(): list<ListItem> {
		return new list<ListItem>(this.listItems);
	}

	setWrapping(wrap: boolean): void {
		if (this.ctrl) {
			this.ctrl.wrapFocus = wrap;
		}
	}

	size(): number {
		return this.items().size();
	}
}

export interface ListItemOpts extends ElOpts {
	leadingEl: El | null;
	leadingElStyles: Iterable<[string, string]>;
	leadingIcon: string;
	text: string;
	textIsAnchor: boolean;
	textIsLabel: boolean;
	trailingEl: El | null;
	trailingElStyles: Iterable<[string, string]>;
	trailingIcon: string;
}

export class ListItem extends El {
	static selector: string = '.mdc-list-item';
	static RootSelector: string = '.mdc-list-item';

	constructor(opts: Partial<ListItemOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts> | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<ListItemOpts>, parent?: El | null);
	constructor(opts?: Partial<ListItemOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<ListItemOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<ListItemOpts>(a, b, c);
		opts.tagName = 'li';
		super(opts);
		this.addClass('mdc-list-item');
		if (!this.rippleEl()) {
			El.span({classNames: 'mdc-list-item__ripple'}, this);
		}
		let textTag: TagName = 'span';
		const textClassNames: Array<string> = ['mdc-list-item__text'];
		if (opts.textIsAnchor) {
			textTag = 'a';
			textClassNames.push('pb-list-item__anchor-text');
		} else if (opts.textIsLabel) {
			textTag = 'label';
		}
		if (!this.textEl()) {
			new El({classNames: textClassNames}, textTag, this);
		}
		if (opts.text) {
			this.setText(opts.text);
		}
		if (opts.leadingIcon !== undefined) {
			this.setLeadingIcon(opts.leadingIcon || '<blank>');
		}
		if (opts.trailingIcon !== undefined) {
			this.setTrailingIcon(opts.trailingIcon || '<blank>');
		}
		if (opts.leadingEl) {
			this.setLeadingEl(opts.leadingEl);
		}
		if (opts.trailingEl) {
			this.setTrailingEl(opts.trailingEl);
		}
		if (opts.leadingElStyles) {
			this.setLeadingElStyles(opts.leadingElStyles);
		}
		if (opts.trailingElStyles) {
			this.setTrailingElStyles(opts.trailingElStyles);
		}
	}

	protected insertOptEl(pos: 'leading' | 'trailing', el: El): boolean {
		if (!((pos === 'leading') || (pos === 'trailing'))) {
			return false;
		}
		if (pos === 'leading') {
			const ripEl = this.rippleEl();
			if (ripEl) {
				ripEl.insertAdjacentElement('afterend', el);
				return true;
			}
			const textEl = this.textEl();
			if (textEl) {
				textEl.insertAdjacentElement('beforebegin', el);
				return true;
			}
		} else {
			this.appendChild(el);
			return true;
		}
		return false;
	}

	isChecked(): boolean {
		const leading = this.leadingEl();
		if (leading) {
			const input = leading.querySelector('input[type="checkbox"]');
			if (input) {
				return input.isChecked();
			}
		}
		return false;
	}

	protected leadingEl(): El | null {
		return this.optEl('leading');
	}

	protected optEl(pos: 'leading' | 'trailing'): El | null {
		if (pos === 'leading') {
			return this.querySelector('.mdc-list-item__graphic');
		} else if (pos === 'trailing') {
			return this.querySelector('.mdc-list-item__meta');
		}
		return null;
	}

	protected rippleEl(): El | null {
		return this.querySelector('.mdc-list-item__ripple');
	}

	setLeadingEl(obj: El | null): void {
		let el = this.leadingEl();
		if (!obj) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			el = El.div({classNames: 'mdc-list-item__graphic'}, null);
			if (!this.insertOptEl('leading', el)) {
				el.destroy();
				return;
			}
		}
		el.clear();
		el.appendChild(obj);
	}

	setLeadingElStyles(property: string, value: string): void;
	setLeadingElStyles(styles: Iterable<[string, string]>): void;
	setLeadingElStyles(a: string | Iterable<[string, string]>, b?: string): void {
		const el = this.leadingEl();
		if (!el) {
			return;
		}
		const styles: Array<[string, string]> = ((typeof a === 'string') && (typeof b === 'string')) ?
			[[a, b]] :
			Array.from(<Iterable<[string, string]>>a);
		for (const [prop, val] of styles) {
			el.setStyleProperty(prop, val);
		}
	}

	setLeadingIcon(iconName: string): void {
		iconName = iconName.trim();
		let el = this.leadingEl();
		if (!iconName) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			el = El.span({classNames: ['mdc-list-item__graphic', 'material-icons']}, null);
			if (!this.insertOptEl('leading', el)) {
				el.destroy();
				return;
			}
		}
		el.setText(iconName === '<blank>' ? '' : iconName);
	}

	setText(value: string | null): void {
		const el = this.textEl();
		if (el) {
			el.setText(value);
		}
	}

	setTrailingEl(obj: El | null): void {
		let el = this.trailingEl();
		if (!obj) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			el = El.div({classNames: 'mdc-list-item__meta'}, null);
			if (!this.insertOptEl('trailing', el)) {
				el.destroy();
				return;
			}
		}
		el.clear();
		el.appendChild(obj);
	}

	setTrailingElStyles(property: string, value: string): void;
	setTrailingElStyles(styles: Iterable<[string, string]>): void;
	setTrailingElStyles(a: string | Iterable<[string, string]>, b?: string): void {
		const el = this.trailingEl();
		if (!el) {
			return;
		}
		const styles: Array<[string, string]> = ((typeof a === 'string') && (typeof b === 'string')) ?
			[[a, b]] :
			Array.from(<Iterable<[string, string]>>a);
		for (const [prop, val] of styles) {
			el.setStyleProperty(prop, val);
		}
	}

	setTrailingIcon(iconName: string): void {
		iconName = iconName.trim();
		let el = this.trailingEl();
		if (!iconName) {
			if (el) {
				el.destroy();
			}
			return;
		}
		if (!el) {
			el = El.span({classNames: ['mdc-list-item__meta', 'material-icons']}, this);
			if (!this.insertOptEl('trailing', el)) {
				el.destroy();
				return;
			}
		}
		el.setText(iconName === '<blank>' ? '' : iconName);
	}

	text(): string {
		const el = this.textEl();
		return el ? el.text() : '';
	}

	textEl(): El | null {
		return this.querySelector('.mdc-list-item__text');
	}

	protected trailingEl(): El | null {
		return this.optEl('trailing');
	}
}

export class ListDivider extends El {
	constructor(parent: El | null = null) {
		super('hr', parent);
		this.addClass('mdc-list-divider');
	}
}

export class ListItemDivider extends El {
	constructor(parent: El | null = null) {
		super('li', parent);
		this.addClass('mdc-list-divider');
		this.setAttribute('role', 'separator');
	}
}

export class ListGroup extends El {
	constructor(parent: El | null = null) {
		super('div', parent);
		this.addClass('mdc-list-group');
	}

	addList(list: List, subheader: string): void {
		const subheaderEl = new El({classNames: 'mdc-list-group__subheader'}, 'h3', this);
		subheaderEl.setText(subheader);
		this.appendChild(list);
		list.group = this;
	}
}

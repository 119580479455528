import {MDCCheckbox} from '@material/checkbox';

import {El, ElOpts, elOpts} from '../el';
import {bind} from '../util';
import {CheckState} from '../constants';
import {Evt} from '../evt';

export interface CheckboxOpts extends ElOpts {
	checked: boolean;
	disabled: boolean;
	name: string;
	static: boolean;
	value: string;
}

export class CheckboxChangeEvt extends Evt {
	private cb: Checkbox;

	constructor(checkbox: Checkbox) {
		super(Evt.Change);
		this.cb = checkbox;
	}

	checkbox(): Checkbox {
		return this.cb;
	}

	checkState(): CheckState {
		return this.cb.checkState();
	}

	isChecked(): boolean {
		return this.cb.isChecked();
	}
}

export class Checkbox extends El {
	static RootSelector: string = '.mdc-checkbox';

	buddy: El | null;
	ctrl: MDCCheckbox | null;

	constructor(opts: Partial<CheckboxOpts> | null, root: Element | null, parent?: El | null);
	constructor(opts: Partial<CheckboxOpts> | null, parent?: El | null);
	constructor(opts: Partial<CheckboxOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: El | null);
	constructor(opts: Partial<CheckboxOpts>, parent?: El | null);
	constructor(opts?: Partial<CheckboxOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: El | null);
	constructor(a?: Partial<CheckboxOpts> | El | Element | null, b?: El | Element | null, c?: El | null) {
		const opts = elOpts<CheckboxOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.buddy = null;
		this.instanceNumber = Checkbox.instanceCount;
		this.addClass('mdc-checkbox');
		const inp = El.checkbox({classNames: 'mdc-checkbox__native-control'}, this);
		const bg = El.div({classNames: 'mdc-checkbox__background'}, this);
		const svg = El.svg({classNames: 'mdc-checkbox__checkmark'}, bg);
		svg.setAttribute('viewBox', '0 0 24 24');
		const path = El.path({classNames: 'mdc-checkbox__checkmark-path'}, svg);
		path.setAttribute([['fill', 'none'], ['d', 'M1.73,12.91 8.1,19.28 22.79,4.59']]);
		El.div({classNames: 'mdc-checkbox__mixedmark'}, bg);
		El.div({classNames: 'mdc-checkbox__ripple'}, this);
		if (opts.disabled) {
			this.addClass('mdc-checkbox--disabled');
		}
		if (opts.name) {
			this.setName(opts.name);
		}
		if (opts.value) {
			this.setValue(opts.value);
		}
		if (opts.checked) {
			this.setChecked(opts.checked);
		}
		if (opts.static) {
			this.ctrl = null;
			inp.addEventListener('change', this.event);
		} else {
			this.ctrl = new MDCCheckbox(this.elem);
		}
	}

	checkState(): CheckState {
		const inp = this.inputEl();
		if (inp) {
			return inp.checkState();
		}
		if (this.ctrl) {
			if (this.ctrl.indeterminate) {
				return CheckState.PartiallyChecked;
			}
			return this.ctrl.checked ? CheckState.Checked : CheckState.Unchecked;
		}
		return CheckState.Unchecked;
	}

	destroy(): void {
		this.buddy = null;
		if (this.ctrl) {
			this.ctrl.destroy();
			this.ctrl = null;
		}
		const inp = this.inputEl();
		if (inp) {
			inp.removeEventListener('change', this.event);
		}
		super.destroy();
	}

	genericId(): string {
		return `id_pb-checkbox-${this.instanceNumber}`;
	}

	@bind
	protected event(event: Event): void {
		if (event.type === 'change') {
			this.notifyEvt(new CheckboxChangeEvt(this));
		}
	}

	protected inputEl(): El | null {
		return this.querySelector('input');
	}

	inputId(): string {
		const el = this.inputEl();
		return el ? el.id() : '';
	}

	isDisabled(): boolean {
		if (this.ctrl) {
			return this.ctrl.disabled;
		} else {
			const el = this.inputEl();
			if (el) {
				return el.isDisabled();
			}
		}
		return false;
	}

	protected labelBuddy(): El | null {
		if (this.buddy && this.buddy.tagName() === 'LABEL') {
			return this.buddy;
		}
		return null;
	}

	private maybeRemoveLabelBuddyForAttr(): void {
		const bud = this.labelBuddy();
		if (bud && bud.attribute('for') === this.inputId()) {
			bud.removeAttribute('for');
		}
	}

	name(): string {
		const el = this.inputEl();
		return el ? el.name() : '';
	}

	setBuddy(buddy?: El | null): void {
		const bud = buddy || null;
		if (bud === this.buddy) {
			return;
		}
		this.maybeRemoveLabelBuddyForAttr();
		this.buddy = bud;
		this.setLabelBuddyForAttr(this.inputId());
	}

	setCheckState(state: CheckState): void {
		if (this.ctrl) {
			if (state === CheckState.PartiallyChecked) {
				this.ctrl.indeterminate = true;
			} else {
				this.ctrl.indeterminate = false;
				this.ctrl.checked = (state === CheckState.Checked);
			}
		} else {
			const el = this.inputEl();
			if (el) {
				el.setCheckState(state);
			}
		}
	}

	setDisabled(disabled: boolean): void {
		if (this.ctrl) {
			this.ctrl.disabled = disabled;
		} else {
			const el = this.inputEl();
			if (el) {
				el.setDisabled(disabled);
			}
		}
	}

	setInputId(id: string): void {
		const el = this.inputEl();
		if (el) {
			el.setId(id);
			this.setLabelBuddyForAttr(this.inputId());
		}
	}

	private setLabelBuddyForAttr(inputId: string): void {
		if (inputId) {
			const bud = this.labelBuddy();
			if (bud) {
				bud.setAttribute('for', inputId);
			}
		}
	}

	setName(name: string): void {
		const el = this.inputEl();
		if (el) {
			el.setName(name);
		}
	}

	setValue(value: string): void {
		const el = this.inputEl();
		if (el) {
			el.setValue(value);
		}
	}

	value(): string {
		const el = this.inputEl();
		return el ? el.value() : '';
	}
}
